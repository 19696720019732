import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import Select from "react-select";

import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';

import { Line } from 'react-chartjs-2';

import { AnnualAEFIService } from '../../services/SummaryReport/AnnualAEFIService';
import { DropdownService } from '../../services/Master/AllDropdownService';

import { withTranslation } from "react-i18next";

export class AnnualAEFI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            yearType: { value: "Reported", label: "Tahun Pelaporan" },
            recordsGraph: [],
            yearTypeGraph: { value: "Reported", label: "Tahun Pelaporan" },
            caseCategory: "",
            caseCategories: [],
            regionType: ""
        }
        this.getCaseCategories();
    }

    componentDidMount() {
        this.refreshList();
        this.getDataGraph();
    }

    getCaseCategories() {
        DropdownService.MasterList(["aefiCategory"]).then((result) => {
            const listCategories = result.data.aefiCategoryList
                ?.map((item) => {
                    return { 
                        value: item.id,
                        label: item.name,
                    };
                });
            this.setState({ caseCategories: listCategories });
        });
    }

    refreshList() {
        AnnualAEFIService.Read(this.state.page, this.state.limit, this.state.query, this.state.yearType.value).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records,
            });
        });
    }

    handleYearTypeChange(item) {
        this.setState({ yearType: item }, () => {
            this.refreshList();
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }

    getDataGraph() {
        AnnualAEFIService.ReadGraph(
            this.state.yearTypeGraph.value,
            this.state.caseCategory,
            this.state.regionType
        ).then(result => {
            this.setState({
                recordsGraph: result?.data?.records,
            });
        });
    }

    handleYearTypeGraphChange(item) {
        this.setState({ yearTypeGraph: item }, () => {
            this.getDataGraph();
        });
    }

    handleCaseCategoryChange(item) {
        this.setState({ caseCategory: item ? item?.value : "" }, () => {
            this.getDataGraph();
        });
    }

    handleRegionTypeChange(item) {
        this.setState({ regionType: item ? item?.value : "" }, () => {
            this.getDataGraph();
        });
    }

    download() {
        AnnualAEFIService.Download(this.state.yearType.value)
    }

    render() {

        const { records, recordsGraph } = this.state;
        const { t } = this.props;
        const yearTypeOptions = t('year_type_option', { returnObjects: true })
        const domesticOverseasOptions = t("domestic_overseas_option", {
            returnObjects: true,
        })

        let tahun = [];
        let data_serious = [];
        let data_non_serious = []

        recordsGraph.forEach((value, index) => {
            tahun = [...tahun, value.createdYear];
            data_non_serious = [...data_non_serious, { x: value.createdYear, y: value.nonSerious }];
            data_serious = [...data_serious, { x: value.createdYear, y: value.serious }];
        });

        const data = {
            labels: tahun,
            datasets: [
                {
                    label: "KIPI Serius",
                    data: data_serious,
                    fill: false,
                    borderColor: "#E15756"
                },
                {
                    label: "KIPI Non Serius",
                    data: data_non_serious,
                    fill: false,
                    borderColor: "#00BD6B"
                }
            ],
            options: {
                scales: {
                    yAxes: [{
                        display: true
                    }],
                },
            },
        }

        const legend = {
            display: false,
        };

        const options = {
            scales: {
                yAxes: [
                    {
                        display: true
                    }
                ]
            },
            plugins: {
                datalabels: {
                    display: false,  // Hide data labels
                }
            },
        };

        return (
            <>
                <div className="card" style={{ marginBottom: 10 }}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex w-100%">
                                    <div style={{width: 180, marginRight: 10, marginBottom: 30}}>
                                        <Select
                                            placeholder="Tahun"
                                            width="100%"
                                            name="statusFilter"                            
                                            classNamePrefix="select"
                                            className="basic-single"
                                            options={yearTypeOptions}
                                            value={yearTypeOptions.filter(option => option.value === this.state.yearTypeGraph.value)}
                                            onChange={(item) => this.handleYearTypeGraphChange(item)}
                                        ></Select>
                                    </div>
                                    <div style={{width: 180, marginRight: 10, marginBottom: 30}}>
                                        <Select
                                            placeholder={t("aefi_category")}
                                            width="100%"                           
                                            classNamePrefix="select"
                                            className="basic-single"
                                            isClearable="true"
                                            options={this.state.caseCategories}
                                            value={this.state.caseCategories.filter(option => option.value === this.state.caseCategory)}
                                            onChange={(item) => this.handleCaseCategoryChange(item)}
                                        ></Select>
                                    </div>
                                    <div style={{width: 180, marginBottom: 30}}>
                                        <Select
                                            options={domesticOverseasOptions}
                                            placeholder={t("region")}
                                            width="100%"
                                            value={domesticOverseasOptions.filter((option) => option.value === this.state.regionType)}
                                            isClearable="true"
                                            classNamePrefix="select"
                                            className="basic-single"
                                            onChange={(item) => this.handleRegionTypeChange(item)}
                                        />
                                    </div>
                                </div>
                                <div style={{marginTop: 20}}>
                                    <Line data={data} legend={legend} options={options} height="100px"/>
                                </div>
                                <div>
                                    <div style={{maxWidth: 300, margin: "20px auto"}}>
                                        <div className="row">
                                            <div className="col-6">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #00BD6B", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>{t('non_serious')}</div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #E15756", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>{t('serious')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3" style={{ marginBottom: 15 }}>
                                <ButtonWithIcon
                                    text="Download"
                                    icon="fa fa-download"
                                    onClick={() => this.download()}
                                />
                            </div>
                            <div className="col-md-3" style={{ marginBottom: 15 }}>
                                <Select
                                    placeholder="Tahun"
                                    width="100%"
                                    name="statusFilter"                            
                                    classNamePrefix="select"
                                    className="basic-single"
                                    options={yearTypeOptions}
                                    value={yearTypeOptions.filter(option => option.value === this.state.yearType.value)}
                                    onChange={(item) => this.handleYearTypeChange(item)}
                                ></Select>
                            </div>
                        </div>
                        <div class="table-responsive" style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                            <Table className="pb-5" hover>
                                <thead style={{ textAlign: "center", background: "#F4F5F7" }}>
                                    <tr>
                                        <th rowspan="2" style={{ verticalAlign: "middle" }}>No</th>
                                        <th rowspan="2" style={{ verticalAlign: "middle" }}>{t('year')}</th>
                                        <th colspan="2">{t('aefi')}</th>
                                        <th rowspan="2" style={{ verticalAlign: "middle" }}>{t('aefi_cluster')}</th>
                                        <th colspan="2">{t('region')}</th>
                                        <th rowspan="2" style={{ verticalAlign: "middle" }}>Total</th>
                                    </tr>
                                    <tr>
                                        <th>{t('aefi_serious')}</th>
                                        <th>{t('aefi_non_serious')}</th>
                                        <th>{t('domestic')}</th>
                                        <th>{t('overseas')}</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    {
                                        records.map((value, index) =>
                                            <tr key={value.id}>
                                                <td>
                                                    {index + 1 + (this.state.page - 1) * this.state.limit}
                                                </td>
                                                <td>{value.createdYear}</td>
                                                <td>{value.serious}</td>
                                                <td>{value.nonSerious}</td>
                                                <td>{value.cluster}</td>
                                                <td>{value.domestic}</td>
                                                <td>{value.abroad}</td>
                                                <td>{value.total}</td>
                                            </tr>
                                        )
                                    }
                                    {
                                        records.length < 1 && (
                                            <tr>
                                                <td colSpan="3" style={{ textAlign: "center" }}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withTranslation()(AnnualAEFI);
import React from 'react';
import Select2 from '../../components/Utils/Field/Select2';
import Select2Multi from '../../components/Utils/Field/Select2Multi';
import Input from '../../components/Utils/Field/Input';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { withTranslation } from "react-i18next";

class FormClinicalTrial extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    componentDidUpdate() {

        this.countClinicalTrialDetailTotal(this.props.formik)
    }

    render() {
        const { t } = this.props;

        const { formik, ...otherProps } = this.props;

        return (
            <>
                <div>
                    <div className="form-group col-6">
                        <label className="form-label">{t('product_name')}</label>
                        <Select2
                            name="product"
                            errors={formik.errors}
                            touched={formik.touched}
                            value={formik?.values?.product}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                            options={this.props.productList}
                        />
                    </div>
                    <hr />
                </div>
                <h3 className="col-12">CLINICAL TRIAL</h3>
                <div className="col-12">
                    {this.createClinicalTrialDetailUI(formik)}
                    {this.initialClinicalTrialDetailUI(formik)}

                    <div className="row dynamic-form">

                        <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-3 col-md-9">
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-1">
                                    <label className="form-label">Total</label>
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-1">
                                    <div className="form-group">
                                        <Input
                                            disabled
                                            name="totalSubject"
                                            type="number"
                                            min={0}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`totalSubject`)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ButtonWithIcon
                        text={t('add_clinical_trial')}
                        icon="fa fa-plus"
                        onClick={this.addClinicalTrialDetailUI.bind(this, formik)}
                        width="200px"
                    />
                </div>
            </>
        )
    }


    // ClinicalTrialDetail Dynamic Table

    initialClinicalTrialDetailUI(formik) {

        const clinicalTrialDetail = this.props.clinicalTrialDetail;

        if (clinicalTrialDetail && clinicalTrialDetail.length) {

            for (var i = 0; i < clinicalTrialDetail.length; i++) {

                if (clinicalTrialDetail[i] && !clinicalTrialDetail[i].previewed) {
                    formik.setFieldValue(`clinicalTrialDetail[${i}][id]`, clinicalTrialDetail[i].id)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][trialName]`, clinicalTrialDetail[i].trialName)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][ageRange]`, clinicalTrialDetail[i].ageRange)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][purpose]`, clinicalTrialDetail[i].purpose)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][totalMale]`, clinicalTrialDetail[i].totalMale)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][totalFemale]`, clinicalTrialDetail[i].totalFemale)
                    formik.setFieldValue(`clinicalTrialDetail[${i}][total]`, clinicalTrialDetail[i].total)
                }

                this.props.clinicalTrialDetail[i].previewed = true;
            }
        }
    }

    createClinicalTrialDetailUI(formik) {
        const { t } = this.props;

        const isDisabledForm = false;

        return this.props.clinicalTrialDetail.map((element, i) =>

            <div key={i}>

                <Input
                    name={`clinicalTrialDetail[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`clinicalTrialDetail[${i}][id]`)}
                    disabled={isDisabledForm}
                />
                <div className="row dynamic-form">

                    <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Clinical Trial</label>}
                                    <Input
                                        name={`clinicalTrialDetail[${i}][trialName]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][trialName]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Age Range</label>}
                                    <Input
                                        name={`clinicalTrialDetail[${i}][ageRange]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][ageRange]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('purpose')}</label>}
                                    <Input
                                        name={`clinicalTrialDetail[${i}][purpose]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][purpose]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-1">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Male</label>}
                                    <Input
                                        name={`clinicalTrialDetail[${i}][totalMale]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][totalMale]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-1">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Female</label>}
                                    <Input
                                        name={`clinicalTrialDetail[${i}][totalFemale]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][totalFemale]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-1">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Total</label>}
                                    <Input
                                        disabled
                                        name={`clinicalTrialDetail[${i}][total]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`clinicalTrialDetail[${i}][total]`)}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeClinicalTrialDetailUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addClinicalTrialDetailUI(formik) {
        this.props.clinicalTrialDetail.push({ isNew: true, trialName: "", ageRange: "", purpose: "", totalMale: 0, totalFemale: 0, total: 0 });
        formik.setFieldValue(`clinicalTrialDetail[${this.props.clinicalTrialDetail.length - 1}][id]`, null)
    }

    removeClinicalTrialDetailUI(formik, index) {

        for (var i = index; i < this.props.clinicalTrialDetail.length; i++) {

            const clinicalTrialDetail = formik.values.clinicalTrialDetail[i + 1];

            this.props.clinicalTrialDetail[i] = this.props.clinicalTrialDetail[i + 1];

            if (clinicalTrialDetail) {
                formik.setFieldValue(`clinicalTrialDetail[${i}][id]`, clinicalTrialDetail.id)
                formik.setFieldValue(`clinicalTrialDetail[${i}][trialName]`, clinicalTrialDetail.trialName)
                formik.setFieldValue(`clinicalTrialDetail[${i}][ageRange]`, clinicalTrialDetail.ageRange)
                formik.setFieldValue(`clinicalTrialDetail[${i}][purpose]`, clinicalTrialDetail.purpose)
                formik.setFieldValue(`clinicalTrialDetail[${i}][totalMale]`, clinicalTrialDetail.totalMale)
                formik.setFieldValue(`clinicalTrialDetail[${i}][totalFemale]`, clinicalTrialDetail.totalFemale)
                formik.setFieldValue(`clinicalTrialDetail[${i}][total]`, clinicalTrialDetail.total)
            }
            else {
                formik.setFieldValue(`clinicalTrialDetail[${i}]`, null)
            }
        }

        this.props.clinicalTrialDetail.pop();
    }

    countClinicalTrialDetailTotal(formik) {

        var total = 0;

        if (formik.values.clinicalTrialDetail) {

            for (var i = 0; i < formik.values.clinicalTrialDetail.length; i++) {
                const clinicalTrialDetail = formik.values.clinicalTrialDetail[i];

                if (clinicalTrialDetail) {
                    clinicalTrialDetail.total = clinicalTrialDetail.totalMale != "" ? clinicalTrialDetail.totalMale : 0;
                    clinicalTrialDetail.total += clinicalTrialDetail.totalFemale != "" ? clinicalTrialDetail.totalFemale : 0;
                    total += parseInt(clinicalTrialDetail.total);
                }
            }
        }

        formik.values.totalSubject = total;
    }
}

export default withTranslation()(FormClinicalTrial);
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import ProductReport from "./Tab/ProductReport";
import MarketAuthorization from "./Tab/MarketAuthorization";
import ClinicalTrial from "./Tab/ClinicalTrial";
import ProductDistribution from "./Tab/ProductDistribution";
import SummaryTabulations from "./Tab/SummaryTabulations";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { PBRERService } from "../../../services/PBRER/PBRERService";
import { authenticationService } from "../../../services/AuthService";
import { Role, Status } from "../../../helpers/Constants";

import { withTranslation } from "react-i18next";

import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class DetailPBRER extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      id: id,
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      nextTab: "market-authorization",
      currentTab: "product-report",
      historyForm: [],
    };

    this.formikRef = React.createRef();
    this.getData(id);
  }

  getData(id) {
    PBRERService.PrepareForm(id).then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#product-report":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "product-report",
          backTab: "",
          nextTab: "market-authorization",
        });
        break;
      case "#market-authorization":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "market-authorization",
          backTab: "product-report",
          nextTab: "clinical-trial",
        });
        break;
      case "#clinical-trial":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "clinical-trial",
          backTab: "market-authorization",
          nextTab: "product-distribution",
        });
        break;
      case "#product-distribution":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "product-distribution",
          backTab: "clinical-trial",
          nextTab: "summary-tabulation",
        });
        break;
      case "#summary-tabulation":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "summary-tabulation",
          backTab: "product-distribution",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push(`/report-submission/pbrer`, {
      pages: this.props?.location?.state?.page,
    });
    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  render() {
    console.log(this.props?.location?.state?.page);
    const { t } = this.props;

    const currentUser = authenticationService.currentUserValue;

    const pbrer = this.state.data;

    const initial = {
      id: this.state.id,
      product: pbrer?.product,
      productName: pbrer?.productName,
      type: pbrer?.vaccine ? "vaccine" : "drugs",
      period:
        pbrer?.startDate && pbrer?.endDate
          ? moment().range(moment(pbrer?.startDate), moment(pbrer?.endDate))
          : "",
      startDate: pbrer?.startDate,
      endDate: pbrer?.endDate,
      pbrerCaseEntry: pbrer?.pbrerCaseEntry
        ? pbrer?.pbrerCaseEntry.map((item) => {
            item.countryName = item.countryNavigation?.name;
            item.datasourceName = item.datasourceNavigation?.name;
            item.routeName = item.routeNavigation?.name;
            return item;
          })
        : [],
      total: pbrer?.total,
      pbrerMarketAuthorizationCountry: pbrer?.pbrerMarketAuthorizationCountry
        ? pbrer?.pbrerMarketAuthorizationCountry.map((item) => {
            item.countryName = item.countryNavigation?.name;
            return item;
          })
        : [],
      pbrerMarketAuthorizationInstitution:
        pbrer?.pbrerMarketAuthorizationInstitution
          ? pbrer?.pbrerMarketAuthorizationInstitution.map((item) => {
              item.countryName = item.countryNavigation?.name;
              item.institutionName = item.institutionNavigation?.name;
              return item;
            })
          : [],
      pbrerClinicalTrial: pbrer?.pbrerClinicalTrial ?? [],
      pbrerProductDistribution: pbrer?.pbrerProductDistribution ?? [],
      pbrerSummaryTabulationCt: pbrer?.pbrerSummaryTabulationCt ?? [],
      pbrerSummaryTabulationMarket: pbrer?.pbrerSummaryTabulationMarket ?? [],
    };

    return (
      <>
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <ul className="nav nav-justified mb-3 menu-tab">
              <Tab
                number="1"
                showNumber={true}
                title="Product Report"
                href="#product-report"
                onClick={(event) =>
                  this.handleTabChange(event, "#product-report")
                }
                id="link-product-report"
              />
              <Tab
                number="2"
                showNumber={true}
                title="Market Authorization"
                href="#market-authorization"
                onClick={(event) =>
                  this.handleTabChange(event, "#market-authorization")
                }
                id="link-market-authorization"
              />
              <Tab
                number="3"
                showNumber={true}
                title="Clinical Trial"
                href="#clinical-trial"
                onClick={(event) =>
                  this.handleTabChange(event, "#clinical-trial")
                }
                id="link-clinical-trial"
              />
              <Tab
                number="4"
                showNumber={true}
                title="Product Distribution"
                href="#product-distribution"
                onClick={(event) =>
                  this.handleTabChange(event, "#product-distribution")
                }
                id="link-product-distribution"
              />
              <Tab
                number="5"
                showNumber={true}
                title="Summary Tabulation"
                href="#summary-tabulation"
                onClick={(event) =>
                  this.handleTabChange(event, "#summary-tabulation")
                }
                id="link-summary-tabulation"
              />
            </ul>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          initialTouched={{
            product: true,
            period: true,
          }}
          validationSchema={Yup.object().shape({
            product: Yup.string().required("Required").nullable(),
            period: Yup.string().required("Required").nullable(),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            PBRERService.Update(this.state.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/report-submission/pbrer`, {
                  pages: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <ProductReport formik={formik} formType="edit" />

                <MarketAuthorization formik={formik} />

                <ClinicalTrial formik={formik} />

                <ProductDistribution formik={formik} />

                <SummaryTabulations formik={formik} />

                {this.state.backTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleCancelClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab !== "" && this.state.backTab !== "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    {currentUser.role === Role.Staff && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          disabled={formik.isSubmitting}
                        />
                      </ToggleDisplay>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailPBRER);

import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const ProgressService = {
    Create,
    Read,
    Update,
    FollowUp,
    PrepareForm,
    Delete,
    DownloadEn,
    DownloadId,
    DownloadDocEn,
    DownloadDocId,
    Preview,
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/manufacture/submit-form/growth/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/manufacture/submit-form/growth/update`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function FollowUp(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/manufacture/submit-form/growth/create-followup`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/manufacture/read?page=${page}&limit=${limit}&query=${query}&type=PERKEMBANGAN`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}


async function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/manufacture/prepare-form/growth/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/manufacture/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function DownloadEn(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/manufacture-progress-en/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Progress Report for case number  ${casenumber}.pdf`);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadId(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/manufacture-progress-id/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Laporan Perkembangan dengan nomor kasus ${casenumber}.pdf`);
            document.body.appendChild(link);
            link.click();
        })
}

function Preview(object, type = "initial") {
    if(type == "initial"){
        return fetch('/api/v1/report-submission/pdf/export-manufacture-initial', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(object)
        }).then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                return url;
            })
        })
    } else {
        return fetch('/api/v1/report-submission/pdf/export-manufacture-perkembangan', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(object)
        }).then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                return url;
            })
        })
    }
}

async function DownloadDocEn(id, casenumber) {
    fetch('/api/v1/report-submission/doc/manufacture-progress-en/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Progress Report for case number s ${casenumber}.docx`);
            document.body.appendChild(link);
            link.click();
        })
}


async function DownloadDocId(id, casenumber) {
    fetch('/api/v1/report-submission/doc/manufacture-progress-id/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Laporan Perkembangan dengan nomor kasus ${casenumber}.docx`);
            document.body.appendChild(link);
            link.click();
        })
}
import React from 'react';
import { Link } from 'react-router-dom';

export class SidebarItem extends React.Component {
    render() {
        var isActive = this.props.history === this.props.to;
        var className = isActive ? 'sidebar-link active' : 'sidebar-link sidebar-link';

        return (
            <li className="sidebar-item">
                <a className="sidebar-link has-arrow"  aria-expanded="false" onClick={this.props.onClick}>
                    <i className={this.props.icon}></i><span className="hide-menu">{this.props.title}</span>
                </a>
                
                    {this.props.children}
                
            </li>
        );
    }
}
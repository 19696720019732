import React, { Component } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import Moment from 'moment';

const InputStyled = styled.input.attrs(props => ({ ...props }))`
    background: #F5F6FA 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    height: 37px;
    text-align: left;
    font: normal normal normal 14px/21px Nunito Sans;
    letter-spacing: 0px;
    color: #222D17;
    padding-left: 12px;
`

export default class Input extends Component {

    render() {
        const isSame =  this.props.same;

        return (
            <div className="input-wrapper" style={{width:"100%"}}>
                <InputStyled
                    {...this.props}
                    className={'form-control ' + this.props.className + (this.props.errors[this.props.name] && this.props.touched[this.props.name] ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '')}
                    value={this.props.value != null ? this.props.value : ""}
                    onChange={this.props.onChange}
                    onKeyUp={this.props.onKeyUp}
                    disabled={this.props.disabled}
                    maxLength={this.props.maxLength}
                    style={this.props.style}
                    min={this.props.min}
                    max={this.props.max}
                    data-date={this.props.type === "date" && this.props.value ? Moment(this.props.value).format('DD/MM/YYYY') : "dd/mm/yyyy"}
                />
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
            </div>
        );
    }
}
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";

import * as Utils from "../../../helpers/Utils";
import Button from "../../../components/Utils/Button/Button";
import Textarea from "../../../components/Utils/Field/Textarea";
import Input from "../../../components/Utils/Field/Input";
import Select2 from "../../../components/Utils/Field/Select2";
import Select2Multi from "../../../components/Utils/Field/Select2Multi";
import DatePicker from "../../../components/Utils/DateRange/DateRangePicker";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { Role, Status } from "../../../helpers/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import { AEFIService } from "../../../services/CaseEntry/AEFIService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { ProgressService } from "../../../services/Manufacture/ProgressService";
import { ManufactureService } from "../../../services/Master/ManufactureService";
import { authenticationService } from "../../../services/AuthService";
import { HistoryService } from "../../../services/HistoryService";
import { LetterModal } from "./LetterModal";
import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { withTranslation } from "react-i18next";

class DetailProgress extends Component {
  constructor(props) {
    super(props);

    const { id, type } = this.props.match.params;

    this.state = {
      id: id,
      type: type === "follow-up" ? true : false,
      caseCategory: "KIPI",
      snackbaropen: false,
      snackbarmsg: "",
      versionOptions: [],
      isLastVersion: true,
      historyForm: [],
      caseEntryData: [],
      caseNumberList: [],
      manufactureList: [],
      CommentModalShow: false,
      HistoryModalShow: false,
      historys: [],
      historysCaseEntry: [],
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.loadData(id);
    this.historyList(id, this.props.typecase);
    this.historyList(id);
  }

  historyList(id, type) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  loadData(id) {
    ProgressService.PrepareForm(id).then((result) => {
      AEFIService.Read(1, 0).then((result) => {
        this.setState({
          caseNumberList: result.data.records,
        });
      });

      this.setState({
        data: result.data[0],
      });
      this.historyCaseEntryList(result.data[0].caseEntry)

      var versionOptions = result.data[0].trailManufactureSubmission
        ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
        .map((trail) => {
          return {
            value: trail.followUp,
            label:
              trail.followUp === 0 ? "Inisial" : "Follow Up " + trail.followUp,
          };
        });
      versionOptions.push({
        value: result.data[0].followUp,
        label:
          result.data[0].followUp === 0
            ? "Inisial"
            : "Follow Up " + result.data[0].followUp,
      });

      if (versionOptions) {
        this.setState({
          versionOptions: versionOptions,
          selectedVersion: versionOptions[versionOptions.length - 1],
          lastVersion: versionOptions[versionOptions.length - 1],
        });
      }
    });
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        ProgressService.PrepareForm(
          this.state.id,
          this.state.selectedVersion.value
        ).then((result) => {
          if (result.data) {
            this.setState({
              data: result.data[0],
              historydata: result.data[0].historyJson
                ? result.data.historyJson
                : [],
            });
          }
        });
      }
    );
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleBackClick(event) {
    this.props.history.push(`/report-submission/manufacture`, {
      tab: "Progress",
      page: this.props?.location?.state?.page,
    });
  }

  handleCaseNumberChange(formik, value) {
    const filtered = this.state.caseNumberList.filter(
      (item) => item.caseNumber === value
    );

    formik.setFieldValue("caseNumber", value);
    formik.setFieldValue(
      "vaccine",
      filtered.map((item) =>
        item.aefiVaccine.map((idx) => idx.vaccineNavigation.name)
      )
    );
    formik.setFieldValue("caseEntry", filtered.map((item) => item.id).shift());
  }

  componentDidMount() {
    DropdownService.MasterList(["outcome"]).then((result) => {
      this.setState({ list: result.data });
    });

    ManufactureService.Read(0, 10, "").then((result) => {
      this.setState({ manufactureList: result.data.records });
    });
  }

  historyList(id) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {      
      HistoryService.Read(id, 'AEFI').then((result) => {
          this.setState({ historysCaseEntry: result.data });          
      });      
  }

  render() {
    const { t } = this.props;
    const currentUser = authenticationService.currentUserValue;
    const progress = this.state.data;

    const filtered = this.state.caseNumberList.filter(
      (item) => item.caseNumber === progress?.caseNumber
    );
    let vaccine = [];
    let batch = [];

    if (progress?.aefiCaseEntry) {
      vaccine = filtered.map((item) =>
        item.aefiVaccine.map((idx) => idx.vaccineNavigation.name)
      );
      batch = filtered.map((item) =>
        item.aefiVaccine.map((idx) => idx.vaccineNavigation.batch)
      );
    } else {
      vaccine = filtered.map((item) =>
        item.aeDrugs.map((idx) => idx.drugsNavigation.name)
      );
      batch = filtered.map((item) =>
        item.aeDrugs.map((idx) => idx.drugsNavigation.batch)
      );
    }

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };
    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    let LetterModalClose = () => {
      this.setState({
        letterShow: false,
      });
    };

    const initial = {
      id: progress?.id,
      caseEntry: progress?.caseEntry,
      caseNumber: progress?.caseNumber,
      caseEntryDate: progress?.caseEntryDate,
      reportedDate: progress?.reportedDate,
      typeCaseEntry: progress?.aeCaseEntry ? "KTD" : "KIPI",
      caseCategory: progress?.aeCaseEntry ? "KTD" : "KIPI",
      optionLanguage: progress?.optionLanguage,
      mailingPlace: progress?.mailingPlace,
      mailingOrder: progress?.mailingOrder,
      mailingMonth: progress?.mailingMonth,
      mailingYear: progress?.mailingYear,
      mailingDate: progress?.mailingDate,
      manufacture: progress?.manufacture,
      vaccine: vaccine,
      manufactureSubmissionProduct: progress?.manufactureSubmissionProduct.map(
        (item) => ({
          product: item.vaccine,
          productName: item.productName,
          batch: item.batch,
          manufacture: item.vaccineNavigation?.manufactureNavigation.name,
        })
      ),
      manufactureSubmissionAllergic:
        progress?.manufactureSubmissionAllergic.map((idx) => ({
          allergic: idx.allergic,
          date: idx.date,
          durationMinute: idx.durationMinute,
          durationHour: idx.durationHour,
          durationDay: idx.durationDay,
        })),
      manufactureSubmissionSymptoms:
        progress?.manufactureSubmissionSymptoms.map((idx) => ({
          symptoms: idx.symptomps,
          date: idx.date,
          durationMinute: idx.durationMinute,
          durationHour: idx.durationHour,
          durationDay: idx.durationDay,
        })),
      mailingSubject: progress?.mailingSubject,
      mailingBody: progress?.mailingBody,
      seriousness: progress?.seriousness,
    };

    /** Handling required form inputs begin */

    const initialTouched = {
      manufacture: true,
      mailingSubject: true,
      mailingBody: true,
    };

    const validationSchemaDict = Yup.object().shape({
      manufacture: Yup.string().required("Required").nullable(),
      mailingSubject: Yup.string().required("Required").nullable(),
      mailingBody: Yup.string().required("Required").nullable(),
    });

    /** Handling required form input ends */

    return (
      <>
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
          id={progress?.id}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          manufacture={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          type="manufacture-growth"
          t={t}
        />

        <div>
          <div>
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          initialTouched={initialTouched}
          validationSchema={validationSchemaDict}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            this.formikRef.current.validateForm().then(() => {
              if (this.formikRef.current.isValid) {
                setStatus();

                values.id = this.state.id;

                // Check follow up
                var isFollowUp = this.state.type;

                if (!isFollowUp) {
                  ProgressService.Update(values).then(
                    (result) => {
                      toastSuccess(
                        "Berhasil menambahkan data manufacture progress"
                      );
                      this.props.history.push(
                        `/report-submission/manufacture`,
                        {
                          tab: "Progress",
                          page: this.props?.location?.state?.page,
                        }
                      );
                    },
                    (error) => {
                      setSubmitting(false);
                      toastError(error);
                    }
                  );
                } else {
                  ProgressService.FollowUp(values).then(
                    (result) => {
                      toastSuccess(
                        "Berhasil menambahkan data follow up manufacture progress"
                      );
                      this.props.history.push({
                        pathname: `/report-submission/manufacture`,
                        state: { tab: "Progress" },
                      });
                    },
                    (error) => {
                      setSubmitting(false);
                      toastError(error);
                    }
                  );
                }
              }
            });
          }}
        >
          {(formik) => (
            <Form>
              <LetterModal
                show={this.state.letterShow}
                onHide={LetterModalClose}
                formik={formik}
                type="progress"
              />
              {console.log(formik.values.manufactureSubmissionProduct)}
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <input
                      disabled
                      name="caseEntry"
                      type="hidden"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("caseEntry")}
                    />
                    <div className="form-group">
                      <label className="form-label">{t("case_number")}</label>
                      <Select2
                        disabled
                        name="caseNumber"
                        options={this.state.caseNumberList?.map((item) => {
                          return {
                            value: item.caseNumber,
                            label: item.caseNumber,
                          };
                        })}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik.values?.caseNumber}
                        onChange={(name, value) => {
                          this.handleCaseNumberChange(formik, value);
                        }}
                        onBlur={formik.setFieldTouched}
                        // disabled={isDisabledForm}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("vaccine")}</label>
                      <Input
                        disabled
                        name="vaccine"
                        type="text"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("vaccine")}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Manufacturer</label>
                      <Select2
                        disabled={currentUser.role !== Role?.Staff}
                        name="manufacture"
                        value={formik.values.manufacture}
                        errors={formik.errors}
                        touched={formik.touched}
                        onChange={(name, value) => {
                          formik.setFieldValue("manufacture", value);
                          formik.setFieldValue(
                            "mailingPlace",
                            this.state.manufactureList
                              .map((item) => {
                                if (item.id === value) {
                                  return item.destination;
                                }
                                return "";
                              })
                              .join(" ")
                          );
                        }}
                        onBlur={formik.setFieldTouched}
                        options={this.state.manufactureList
                          ?.filter((manufakture) =>
                            manufakture.masterVaccine
                              .map((item) => item.id)
                              .some((item) =>
                                formik.values.manufactureSubmissionProduct
                                  ?.map((item) => item.product)
                                  .includes(item)
                              )
                          )
                          .map((item) => {
                            return { value: item.id, label: item.name };
                          })}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("language_option")}
                      </label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            disabled={currentUser.role !== Role?.Staff}
                            checked={
                              formik.values.optionLanguage == true ||
                              formik.values.optionLanguage == "true"
                            }
                            value="true"
                            name="optionLanguage"
                            onChange={formik.handleChange}
                          />
                          <label className="form-check-label">
                            {t("english")}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            disabled={currentUser.role !== Role?.Staff}
                            checked={
                              formik.values.optionLanguage == false ||
                              formik.values.optionLanguage == "false"
                            }
                            value="false"
                            name="optionLanguage"
                            onChange={formik.handleChange}
                          />
                          <label className="form-check-label">
                            {t("indonesia")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label">{t("aefi_form")}</label>
                      <Button
                        text={t("letter_preview")}
                        onClick={() => this.setState({ letterShow: true })}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("letter_address")}
                      </label>
                      <Textarea
                        rows="2"
                        name="mailingPlace"
                        placeholder={t("letter_address")}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={
                          formik.values?.mailingPlace
                            ? formik.values?.mailingPlace
                            : ""
                        }
                        {...formik.getFieldProps("mailingPlace")}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("send_date")}</label>
                      <DatePicker
                        disabled
                        name="mailingDate"
                        selectionType="single"
                        maxdate={new Date()}
                        value={formik.mailingDate}
                        errors={formik.errors}
                        touched={formik.touched}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                        {...formik.getFieldProps("mailingDate")}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Subject Email</label>
                      <Input
                        disabled={currentUser.role !== Role?.Staff}
                        name="mailingSubject"
                        type="text"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("mailingSubject")}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">Body Email</label>
                      <Textarea
                        disabled={currentUser.role !== Role?.Staff}
                        rows="6"
                        name="mailingBody"
                        placeholder="Isi Email"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("mailingBody")}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-right">
                  {this.state.type && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  {!this.state.type && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  <ToggleDisplay show={this.state.saveShow}>
                    <Button
                      type="submit"
                      text={t("save_send_email")}
                      disabled={formik.isSubmitting}
                    />
                  </ToggleDisplay>
                  {formik.status && (
                    <div className={"alert alert-danger"}>{formik.status}</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailProgress);

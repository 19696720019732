import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { withTranslation } from "react-i18next";

class MultipleChoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowSize: "",
            isMobile: false,
            title: ""
        };
    }
    
    isMobile = () => window.innerWidth <= 992;

    render() {

        const {t} = this.props;

        return (
            <>
                <div className="multiple-choice-title">
                    <div className="row">
                        <div className="col-10">
                            <p>{this.props.title}</p>
                        </div>
                        <div className="col-1">
                            <p>{this.props.number}<span style={{color:"#AAAAAA"}}>/{this.props.total}</span></p>
                        </div>
                        <div className="col-1">
                            <div className="expand-table" onClick={this.props.handlespan}>
                                <FontAwesomeIcon icon={this.props.open? faChevronUp : faChevronDown} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(MultipleChoice)

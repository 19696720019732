import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import { Table } from 'react-bootstrap';
import Iframe from 'react-iframe'
import { ErrorMessage } from 'formik';

import Button from '../../../../components/Utils/Button/Button';
import ButtonWithIcon from '../../../../components/Utils/Button/ButtonWithIcon';
import Select2 from '../../../../components/Utils/Field/Select2';
import EmptyState from '../../../../components/Utils/EmptyState/EmptyState';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { AddCausalityEksternalModal } from '../AddCausalityEksternalModal';
import { FileService } from '../../../../services/FileService';
import { Role, Status } from '../../../../helpers/Constants';

class ExternalAnalysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            causalityEksternalModal : false,
            index : '',
            productList : [],
            fileList : ""
        };
        this.fileRef = React.createRef();
    }


    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

        if(prevProps.formik.values !== this.props.formik.values){
            if(this.props.updatedisabled){
                if(this.props.formik.values.analysisBy){
                    this.props.updatedisabled("#external-analysis", false);
                }else{
                    this.props.updatedisabled("#external-analysis", true);
                }
            }
        }

        if(prevProps.formik.values.uploadedFile !== this.props.formik.values.uploadedFile){
            FileService.Show('analysis_external', this.props.formik.values.uploadedFile).then((success) => {

                this.props.formik.setFieldValue('uploadedFileUrl', `/api/v1/file/analysis_external/${this.props.formik.values.uploadedFile}` );
            
            }, (error) => {

                this.props.formik.setFieldValue('uploadedFileUrl', null );
            })
        }

        if(prevProps.caseEntry !== this.props.caseEntry){

            let causalityData = []

            if(this.props.formType === "edit" || (this.props.formType !== "edit" && this.props.isLastVersion === false)){

                this.props.causality.map((record, index) => {
                    let type = this.props.formik.values.caseType === "KIPI" ? true : false;
                    let vaccineNavigation =  [];
                    let drugsNavigation =  [];
                        vaccineNavigation.name = record.productName;
                        drugsNavigation.name = record.productName;
                    let data = type ? {vaccine : record.vaccine, vaccineNavigation } : {drugs : record.drugs, drugsNavigation }
                    causalityData = [...causalityData, data]
                })

                this.setState({productList : causalityData});

            }else{
                
                if(this.props.formik.values.caseType === "KIPI"){
                    causalityData = this.props.caseEntry?.aefiVaccine ? this.props.caseEntry?.aefiVaccine : [];
                    this.setState({productList : causalityData});
                }else{
                    causalityData = this.props.caseEntry?.aeDrugs ? this.props.caseEntry?.aeDrugs : [];
                    this.setState({productList : causalityData});
                }
            }

            // Set array for causality 
            if(!this.props.causality){
                let causality = [];

                causalityData.map((record, index) => {
                    let type = this.props.formik.values.caseType === "KIPI" ? true : false;
                    let id = type ? record.vaccine : record.drugs;
                    let product_name = type ? record.vaccineNavigation?.name : record.drugsNavigation?.name;
                    if(id !== null){
                        let array_causality = type ? (this.props.formik.values.completenessData === "Yes" ? 
                            {vaccine : id, productName: product_name, causalityName: "-", seriousness: null, expectedness: null, outcome: null, causalityExternal: null, causalityUpv: null, batch: record?.batch, causalityReasons: null, causalitiesExternal: [], causalitiesUPV: [], aefiList: [], classificationReason: null, classificationConclusion: null, evidenceNotes: null, evidenceDenialCausal: null, evidenceTimeWindow: null, evidenceOtherCauses: null, diagnosisFulfills: null, diagnosis: null, productCauses: null }
                            : {vaccine : id, productName: product_name, causalityName: "-", seriousness: null, expectedness: null, outcome: null, causalityExternal: null, causalityUpv: null, batch: record?.batch, diagnosis: null, diagnosisType: null, classifications: []  })
                            : {drugs : id, productName: product_name, causalityName: "-", seriousness: null, expectedness: null, outcome: null, causalityExternal: null, causalityUpv: null, causalityReasons: null, causalitiesExternal: [], causalitiesUPV: [] }
                        causality[index] = array_causality
                    }
                })

                this.props.handleCausality(causality);
            }else{

                let causality = [];

                causalityData.map((record, index) => {
                    let type = this.props.formik.values.caseType === "KIPI" ? true : false;
                    let id = type ? record.vaccine : record.drugs;
                    let product_name = type ? record.vaccineNavigation?.name : record.drugsNavigation?.name;
                    let ind = this.props.causality.map(function(x) {return (x.vaccine ? x.vaccine : x.drugs) ; }).indexOf(id);
                    if(id !== null){
                        if(ind > -1) {

                            let causalityName = this.props.causality[ind].causalityExternalNavigation?.name ? this.props.causality[ind].causalityExternalNavigation?.name : '-';
                            let causalityNamePV = this.props.causality[ind].causalityUpvNavigation?.name ? this.props.causality[ind].causalityUpvNavigation?.name : '-' ;
                            let causalityNameEn = this.props.causality[ind].causalityExternalNavigation?.nameEnTranslate ? this.props.causality[ind].causalityExternalNavigation?.nameEnTranslate : '-';
                            let causalityNamePVEn = this.props.causality[ind].causalityUpvNavigation?.nameEnTranslate ? this.props.causality[ind].causalityUpvNavigation?.nameEnTranslate : '-';

                            this.props.causality[ind].causalityName = causalityName;
                            this.props.causality[ind].causalityNamePV = causalityNamePV;
                            this.props.causality[ind].causalityNameEn = causalityNameEn;
                            this.props.causality[ind].causalityNamePVEn = causalityNamePVEn;

                            this.props.causality[ind].aefiList = this.props.causality[ind].analysisAefiList;
                            this.props.causality[ind].classifications = this.props.causality[ind].analysisClassification;
                            this.props.causality[ind].causalitiesExternal = this.props.causality[ind].analysisCausalityExternal;
                            this.props.causality[ind].causalitiesUPV = this.props.causality[ind].analysisCausalityUpv;
                            
                            causality[index] = this.props.causality[ind]

                        }else{
                            causality[index] = [];
                            let array_causality = type ? 
                            {vaccine : id, productName: product_name, causalityName: "-", causalityNamePV: "-", seriousness: null, expectedness: null, outcome: null, causalityExternal: null, causalityUpv: null, causalityReasons: null, batch: record?.batch, causalitiesExternal: [], causalitiesUPV: [], aefiList: [], classificationReason: null, classificationConclusion: null, evidenceNotes: null, evidenceDenialCausal: null, evidenceTimeWindow: null, evidenceOtherCauses: null, diagnosisFulfills: null, diagnosis: null, productCauses: null, diagnosisType: null, classifications: [] }
                            : {drugs : id, productName: product_name, causalityName: "-", causalityNamePV: "-", seriousness: null, expectedness: null, outcome: null, causalityExternal: null, causalityUpv: null, causalityReasons: null, causalitiesExternal: [], causalitiesUPV: [] }
                            causality[index] = array_causality
                        }
                    }
                })

                this.props.handleCausality(causality);
            }
        }
    }

    handleOpenModal(id) {
        this.setState({ causalityEksternalModal: true, index: id  })
    }

    handleChange(selectorFiles) {
        FileService.Upload(selectorFiles.files, 'analysis_external').then((success) => {
            if (success.status === 200) {
                this.setState({ fileList: success.data })
                selectorFiles.value = null;
                this.props.formik.setFieldValue('uploadedFile', success.data.fileName );
                this.props.formik.setFieldValue('uploadedFileUrl', success.data.url );
            }
        }, (error) => {
            console.log('error', error)
        })
    }

    openfileDialog = () => {
        this.fileRef.current.click();
    }

    render() {
        const { formik, typecase, historydata, caseEntry, currentUser, historyCausality, historyCaseEntry, causality, list, provinceList, t, i18next, ...otherProps } = this.props;
        const { productList } = this.state;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || 
                                (this.props.formType !== "edit" && 
                                 this.props.isLastVersion && 
                                 (   
                                  this.props.caseStatus !== Status.WaitingApproval && 
                                  this.props.caseStatus !== Status.Reviewed && 
                                  this.props.caseStatus !== Status.Approved)
                                ) || 
                                (this.props.formType === "edit"  && 
                                 this.props.isLastVersion && (
                                    this.props.caseStatus !== Status.WaitingApproval && 
                                    this.props.caseStatus !== Status.Reviewed && 
                                    this.props.caseStatus !== Status.Approved)
                                ) || 
                                (this.props.isLastVersion === undefined) || 
                                ((role === Role.Divisi && 
                                    this.props.caseStatus === Status.Reviewed && 
                                    this.props.isLastVersion) || (role === Role.Unit && 
                                        this.props.caseStatus === Status.WaitingApproval && 
                                        this.props.isLastVersion) ) ? false : this.props.isLastVersion === false || 
                                                                           this.props.caseStatus === Status.WaitingApproval || 
                                                                           this.props.caseStatus === Status.Reviewed || 
                                                                           this.props.caseStatus === Status.Approved ;

        const file = formik?.values.uploadedFile;
        const extentionFile = file ? file.substr( (file.lastIndexOf('.') + 1) ) : null; 

        const isValid = !(formik.errors['uploadedFile'] && formik.touched['uploadedFile'])

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="external-analysis">
                    <AddCausalityEksternalModal
                        show={this.state.causalityEksternalModal}
                        onhide={() => this.setState({ causalityEksternalModal: false })}
                        data_parent={formik}
                        history={historyCausality}
                        index={this.state.index}
                        list={list}
                        handleCausality={this.props.handleCausality}
                        causality={causality}
                        disabled={isDisabledForm}
                        formType={this.props.formType}
                        t={t}
                        i18next={i18next}
                    />
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label className="form-label">{t('analysis_by')} 
                                        <span style={{ color: "red" }}>*</span>
                                        </label>
                                    <Select2
                                        name="analysisBy"
                                        options={list?.analyzerList.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values?.analysisBy}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.analysisBy ? historydata.analysisBy === formik.values?.analysisBy : true}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label className="form-label">{t('province')}</label>
                                    <Select2
                                        name="analysisByProvince"
                                        options={provinceList?.map(item => { return { value: item.code, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values?.analysisByProvince}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm || formik.values?.analysisBy != '4aac8caa-e691-4557-b540-7846006651fc'}
                                        same={historydata?.province ? historydata.province === formik.values?.province : true}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="form-group">
                                <label className="form-label">{t('attachment')} 
                                    {/* <span style={{color: "red"}}>*</span> */}
                                    </label>
                                <div>
                                    <ButtonWithIcon
                                        text="Upload File"
                                        icon="fa fa-plus"
                                        onClick={this.openfileDialog}
                                        width="150px"
                                        isSubmitting={isDisabledForm} 
                                    />
                                    <input type="file" ref={this.fileRef} style={{display: "none"}} onChange={ (e) => this.handleChange(e.target) } accept="application/pdf,image/*"/>
                                </div>
                                <div style={{border: !isValid ? "solid 1px red" : "none", marginTop: 5, borderRadius: 8}}>
                                    <div className={historydata?.uploadedFile ? ((historydata?.uploadedFile === formik.values?.uploadedFile) === false ? "attachment-wrapper change-value-radio" : "attachment-wrapper" ) : "attachment-wrapper" }>
                                        <div style={{display: "flex"}}>
                                            <i className="fas fa-paperclip"></i>
                                            <p style={{fontSize: 14, marginBottom: 0, marginLeft: 10}}>{formik.values.uploadedFile ? formik.values.uploadedFile : t('no_attachment') }</p>
                                        </div>
                                        {
                                            formik.values.uploadedFile && formik.values.uploadedFileUrl && (extentionFile !== "png" && extentionFile !== "jpg" && extentionFile !== "jpeg") &&  (
                                                <div style={{marginTop : 20}}>
                                                    <Iframe 
                                                        url={formik.values.uploadedFileUrl}
                                                        width="100%"
                                                        height="400px"
                                                        id="external-iframe"
                                                        className="iframe"
                                                        display="initial"
                                                        position="relative"
                                                    />
                                                </div>
                                            )     
                                        }
                                        {
                                            formik.values.uploadedFile && formik.values.uploadedFileUrl && (extentionFile === "png" || extentionFile === "jpg" || extentionFile === "jpeg") &&  (
                                                <div style={{marginTop : 20, padding: "10px 30px", height: "400px", overflowY: "auto"}}>
                                                    <img src={formik.values.uploadedFileUrl} alt=" Image not found" className="navbar-logo"  />
                                                </div>
                                            )   
                                        }
                                        {
                                            formik.values.uploadedFileUrl === null && (
                                                <EmptyState />
                                            )
                                        }
                                    </div>
                                </div>
                                <ErrorMessage name="uploadedFile" component="div" className="invalid-feedback" style={{ display: (isValid ? 'none' : 'block') }} />
                            </div>
                            <hr />
                            <label className="form-label" style={{ fontSize: 16 }}>{t('add_causality_caps')} </label>
                            <Table className="pb-5" hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('causality')}</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { productList.map((record, index) =>
                                        {
                                            let type = formik.values.caseType === "KIPI" ? true : false;
                                            let id = type ? record.vaccine : record.drugs;
                                            let product_name = type ? record.vaccineNavigation?.name : record.drugsNavigation?.name;
                                            if(id !== null){
                                                return (
                                                    <tr key={id}>
                                                        <td>{index+1}</td>
                                                        <td>{product_name}</td>
                                                        <td>{i18next.language === 'id' || i18next.language === 'ID' ? causality[index]?.causalityName : causality[index]?.causalityNameEn }</td>
                                                        <td>
                                                            <button className="btn btn-sm" type="button" aria-expanded="false" onClick={() => this.handleOpenModal(index)}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    )}
                                    {
                                        productList.length < 1 && (
                                            <tr>
                                                <td colSpan="4" style={{textAlign:"center"}}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default ExternalAnalysis;
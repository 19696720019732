import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Tab from '../../../components/Utils/Tab/Tab';
import Button from '../../../components/Utils/Button/Button';

import CaseInformation from './Tab/CaseInformation';
import Investigation from './Tab/Investigation';
import QualityReview from './Tab/QualityReview';
import Conclusion from './Tab/Conclusion';
import { SummaryService } from '../../../services/QualityReview/Summary/SummaryService';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import { withTranslation } from "react-i18next";

export class AddSummary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backTab: "",
            nextTab: "investigation",
            historyForm: [],
            caseEntryData: [],
            caseInformationDisabled : true,
            investigationDisabled : true,
            conclusionDisabled : true,
            qualityReviewDisabled : true,
        };

        this.formikRef = React.createRef();
        // this.getCaseEntryData();
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#case-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    backTab: "", nextTab: "investigation"
                });
                break;
            case "#investigation":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    backTab: "case-information", nextTab: "quality-review",
                    externalDisabled: false
                });
                break;
            case "#quality-review":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true,
                    backTab: "investigation", nextTab: "conclusion",
                    unitDisabled: false
                });
                break;
            case "#conclusion":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true,
                    backTab: "quality-review", nextTab: "",
                    unitDisabled: false
                });
                break;
        }

        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
        this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    document.getElementById("link-" + this.state.nextTab).click();
                }
            }
        )
        event.preventDefault();
    }  

    handleCancelClick(event) {

        this.props.history.push({ pathname: `/medical-review/summary` });
        event.preventDefault();
    }


    render() {

        const {t} = this.props;

        return (
            <>
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber = {true}
                                title={t('case_information')}
                                href="#case-information"
                                onClick={(event) => this.handleTabChange(event, "#case-information")}
                                id="link-case-information"
                            />
                            <Tab
                                number="2"
                                showNumber = {true}
                                title={t('investigation')}
                                href="#investigation"
                                onClick={(event) => this.handleTabChange(event, "#investigation")}
                                id="link-investigation"
                            />
                            <Tab
                                number="3"
                                showNumber = {true}
                                title="Quality Review"
                                href="#quality-review"
                                onClick={(event) => this.handleTabChange(event, "#quality-review")}
                                id="link-quality-review"
                            />
                            <Tab
                                number="4"
                                showNumber = {true}
                                title={t('conclusion')}
                                href="#conclusion"
                                onClick={(event) => this.handleTabChange(event, "#conclusion")}
                                id="link-conclusion"
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                        caseNumber : ''
                    }}
                    validationSchema={ 
                        Yup.object().shape({
                            caseNumber : Yup.string().required("Required").nullable(),
                        })
                    }
                    onSubmit={(values, { setStatus, setSubmitting }) => {

                        SummaryService.Create(values).then(
                            (result) => {
                                toastSuccess('Berhasil menambahkan data');
                                this.props.history.push(`/medical-review/summary`);
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )

                    }}
                >
                    {formik => 
                        (
                            <Form
                            >
                                <div className="tab-content">
                                    {/* Case Information Tab */}
                                    <CaseInformation
                                        formik={formik}
                                    />

                                    {/* Investigation */}
                                    <Investigation
                                        formik={formik}
                                    />

                                    {/* Quality Review */}
                                    <QualityReview
                                        formik={formik}
                                    />

                                    {/* Conclusion */}
                                    <Conclusion
                                        formik={formik}
                                    />
                                    
                                    {
                                        this.state.backTab === "" && 
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('cancel')}
                                                    onClick={(event) => this.handleCancelClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay>
                                                <Button
                                                    text={t('next')}
                                                    onClick={(event) => this.handleNextClick(event)} />
                                            </ToggleDisplay>
                                        </div>
                                    }
                                    {
                                        this.state.nextTab !== "" && this.state.backTab !== "" && 
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay>
                                                <Button
                                                    text={t('next')}
                                                    onClick={(event) => this.handleNextClick(event)} />
                                            </ToggleDisplay>
                                        </div>
                                    }
                                    {
                                        this.state.nextTab === "" &&

                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    type="submit"
                                                    text={t('save')}
                                                    disabled={formik.isSubmitting} />
                                            </ToggleDisplay>
                                        </div>

                                    }
                                    
                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </>
        )
    }
    
}

export default withTranslation()(AddSummary)
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../components/Utils/Button/Button';
import Tab from '../../../components/Utils/Tab/Tab';
import Initial from './Tab/Initial';
import ExternalAnalysis from './Tab/ExternalAnalysis';
import UnitAnalysis from './Tab/UnitAnalysis';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { AnalysisService } from '../../../services/QualityReview/Analysis/AnalysisService';
import { DropdownService } from '../../../services/Master/AllDropdownService';

import { withTranslation } from "react-i18next";
import i18next from 'i18next';

export class AddAnalysis extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            backTab: "",
            nextTab: "external-analysis",
            currentTab: "inisiasi",
            historyForm: [],
            caseEntryData: [],
            inisiasiDisabled : true,
            externalDisabled : true,
            unitDisabled : true,
            isSubmitted: true,
        };
        this.formikRef = React.createRef();
        this.getCaseEntryData();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#inisiasi":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false, currentTab: "inisiasi",
                    backTab: "", nextTab: "external-analysis"
                });
                break;
            case "#external-analysis":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false, currentTab: "external-analysis",
                    backTab: "inisiasi", nextTab: "unit-analysis",
                    externalDisabled: false
                });
                break;
            case "#unit-analysis":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true, currentTab: "unit-analysis",
                    backTab: "external-analysis", nextTab: "",
                    unitDisabled: false
                });
                break;
        }

        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
         this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    document.getElementById("link-" + this.state.nextTab).click();
                }
            }
        )
        event.preventDefault();
    } 
    
    handleGetData(data){
        this.setState({caseEntryData : data});
    }

    handleCausality(data){
        this.setState({causality_value : data});
    }

    handleDisabledNext(tab, status){
        switch (tab) {
            case "#inisiasi":
                this.setState({
                    inisiasiDisabled: status
                });
                break;
            case "#external-analysis":
                this.setState({
                    externalDisabled: this.state.inisiasiDisabled ? true : status
               });
                break;
            case "#unit-analysis":
                this.setState({
                    unitDisabled: this.state.externalDisabled ? true : status
                });
                break;
        }
    }

    getCaseEntryData() {

        DropdownService.MasterList([
            "causalityAE", "causalityAEFIComplete", "causalityAEFIIncomplete", "analyzer",
            "causalityAEFIA", "causalityAEFIB", "causalityAEFIC", "causalityAEFID",
            "causalityAECertain", "causalityAEProbable", "causalityAEPossible", "causalityAEUnlikely",
            "causalityAEConditional", "causalityAEUnassessable", "seriousness", "expectedness", "outcome",
            "aefiClassification","aefiClassificationCoincidental","aefiClassificationProgrammaticError","aefiClassificationUnknown", 
            "aefiClassificationVaccineReaction","aefiClassificationInjectionReaction"
        ]).then(
            (result) => {
                this.setState({ list: result.data });
            })

        DropdownService.ProvinceList(62).then(
            (result) => {
                this.setState({
                    provinceList: result.data
                });
            })
    }

    render() {

        const {t} = this.props;

        const initialValues = {
            caseType: "KIPI",
            relatedCaseNumber: [],
            isSubmitted: true,
        }

        const validationSchemaDict = {
            "external-analysis": Yup.object().shape({
                analysisBy: Yup.string().required("Required").nullable(),
                // uploadedFile: Yup.string().required("Required").nullable(),
            }),
            "inisiasi": Yup.object().shape({
                caseNumber: Yup.string().required("Required").nullable(),
            })
        }

        const initialTouched = {
            caseNumber: true,
            uploadedFile: true,
            analysisBy: true,
        }

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber = {true}
                                title={t('initiation')}
                                href="#inisiasi"
                                onClick={(event) => this.handleTabChange(event, "#inisiasi")}
                                id="link-inisiasi"
                            />
                            <Tab
                                number="2"
                                showNumber = {true}
                                title={t('analysis_by_external')}
                                href="#external-analysis"
                                onClick={(event) => this.handleTabChange(event, "#external-analysis")}
                                id="link-external-analysis"
                                disabled={this.state.inisiasiDisabled}
                                
                            />
                            <Tab
                                number= "3"
                                showNumber = {true}
                                title={t('analysis_by_pv')}
                                href="#unit-analysis"
                                onClick={(event) => this.handleTabChange(event, "#unit-analysis")}
                                id="link-unit-analysis"
                                disabled={this.state.externalDisabled} 
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    initialValues={initialValues}
                    initialTouched={initialTouched}
                    validationSchema={validationSchemaDict[this.state.currentTab]}

                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        let causality = this.state.causality_value.filter(function (el) {
                            return el != null;
                        });

                        if(values.caseType === "KIPI" && values.completenessData === "Yes"){
                            values.aefiCompleteRequests = causality;
                            values.batch = causality;
                        }

                        if(values.caseType === "KIPI" && values.completenessData === "No"){
                            values.aefiIncompleteRequests = causality;
                            values.batch = causality;
                        }

                        if(values.caseType === "KTD"){
                            values.aeRequests = causality;
                        }

                        values.completenessData = values.completenessData === "No" ? false : true;
                        values.caseEntry = this.state.caseEntryData.id;
                        values.relatedCaseNumber =
                            values.relatedCaseNumber ? values.relatedCaseNumber.map((item)=>{ return item?.value })
                            : [];

                        let data = values;

                        if (values.caseType === "KIPI") {
                            data.caseType = values.completenessData === false ? "aefi-incomplete" : "aefi-complete";
                        } else {
                            data.caseType = "ae";
                        }
                        
                        AnalysisService.Create(data).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push(`/medical-review/analysis`);
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )
                    }}
                >
                    {formik => 

                        (
                            <Form>
                                <div className="tab-content">
    
                                    {/* Inisiasi Tab */}
    
                                    <Initial
                                        formik={formik}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}    
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData}
                                        updatedata={(data) => this.handleGetData(data)}
                                        disabledNext={this.state.inisiasiDisabled}
                                        updatedisabled={(tab,status) => this.handleDisabledNext(tab,status)}
                                        t={t}                                
                                    />
    
                                    {/* External Tab */}
                                    <ExternalAnalysis
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}    
                                        history={this.props.history} 
                                        caseEntry={this.state.caseEntryData}
                                        disabledNext={this.state.externalDisabled}
                                        updatedisabled={(tab,status) => this.handleDisabledNext(tab,status) }
                                        handleCausality={(data) => this.handleCausality(data)}
                                        causality={this.state.causality_value}
                                        list={this.state.list}   
                                        provinceList={this.state.provinceList}
                                        t={t}   
                                        i18next={i18next}
                                    />   
    
                                    {/* Unit Tab*/}
                                    <UnitAnalysis
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}    
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData} 
                                        disabledNext={this.state.unitDisabled}
                                        updatedisabled={(tab,status) => this.handleDisabledNext(tab,status) }  
                                        handleCausality={(data) => this.handleCausality(data)}
                                        causality={this.state.causality_value}
                                        list={this.state.list} 
                                        t={t}
                                        i18next={i18next}
                                    />      

                                    <input
                                        name="isSubmitted"
                                        type="hidden"
                                        {...formik.getFieldProps("isSubmitted")}
                                    />
    
                                    {
                                        this.state.nextTab == "" &&
    
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            {/* simpan sebagai draft */}
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    text={t('save_as_draft')}
                                                    onClick={()=> { 
                                                        formik.setFieldValue("isSubmitted", false);
                                                        formik.submitForm();
                                                    }}
                                                    disabled={formik.isSubmitting} 
                                                    />
                                            </ToggleDisplay>
                                            {/* submit  */}
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    type="submit"
                                                    text={"Submit"}
                                                    disabled={formik.isSubmitting} />
                                            </ToggleDisplay>
                                            {
                                                formik.status &&
                                                <div className={'alert alert-danger'}>{formik.status}</div>
                                            }
                                        </div>
    
                                    }
                                    
                                </div>                            
                            </Form>
                        )
                    } 
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddAnalysis)
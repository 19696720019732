import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import moment from "moment";

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import TimePicker from '../../../components/Utils/DateRange/TimePicker';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';
import Select2 from '../../../components/Utils/Field/Select2';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import Button from '../../../components/Utils/Button/Button';
import * as Utils from '../../../helpers/Utils';
import { Role,Status } from '../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class Immunization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    render() {
        const { formik, typecase, historydata, t, erpList, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion ||   this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;
        let sameVialOption = historydata?.sameVial !== null && historydata?.sameVial !== undefined ? (historydata?.sameVial < 0 ? "No" : "Yes") : "Unknown";
        let sameAdverseOption = historydata?.sameAdverse !== null && historydata?.sameAdverse !== undefined ? (historydata?.sameAdverse < 0 ? "No" : "Yes") : "Unknown";
        let diluentMedicineOption = historydata?.diluentMedicine !== null && historydata?.diluentMedicine !== undefined ? (historydata?.diluentMedicine !== '#?#' ? "Yes" : "No") : "Unknown";
        let sameSymptomps = historydata?.sameSymptomps !== null && historydata?.sameSymptomps !== undefined ? (historydata?.sameSymptomps ? "true" : "false") : "Unknown";
        let vaccineShakeBefore = historydata?.vaccineShakeBefore !== null && historydata?.vaccineShakeBefore !== undefined ? (historydata?.vaccineShakeBefore ? "true" : "false") : "Unknown";
        let vaccineFirstMultiDose = historydata?.vaccineFirstMultiDose !== null && historydata?.vaccineFirstMultiDose !== undefined ? (historydata?.vaccineFirstMultiDose ? "true" : "false") : "Unknown";
        let vaccineInjectionDevice = historydata?.vaccineInjectionDevice !== null && historydata?.vaccineInjectionDevice !== undefined ? (historydata?.vaccineInjectionDevice ? "true" : "false") : "Unknown";

        return (
            <>
                <div className="tab-pane" id="immunization">
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('vaccinator')}</label>
                                    <Select2
                                        name="vaccinator"
                                        options={this.props.vaccinatorList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.vaccinator}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.vaccinator ? historydata.vaccinator === formik?.values.vaccinator : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('name_of_vaccinator')}</label>
                                    <Input
                                        name="vaccinatorName"
                                        type="text"
                                        placeholder={t('name_of_vaccinator')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('vaccinatorName')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.vaccinatorName ? historydata.vaccinatorName === formik?.values.vaccinatorName : true }
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="form-label">{t('institution')}</label>
                                    <Select2
                                        name="institution"
                                        options={this.props.institutionList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.institution}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.id ? historydata.institution === formik?.values.institution : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('institution_name')}</label>
                                    <Input
                                        name="institutionName"
                                        type="text"
                                        placeholder={t('institution_name')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('institutionName')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.id ? historydata.institutionName === formik?.values.institutionName : true }
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('address')}</label>
                                    <Textarea
                                        rows="6"
                                        name="institutionAddress"
                                        placeholder={t('address')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.institutionAddress ? formik.values.institutionAddress : ""}
                                        {...formik.getFieldProps('institutionAddress')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.id ? historydata.institutionAddress === formik?.values.institutionAddress : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('country')}</label>
                                    <Select2
                                        name="institutionCountry"
                                        options={this.props.countryList?.map(country => { return { value: country.code, label: country.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.institutionCountry}
                                        onChange={(name, value) => {
                                            this.props.provinceList("institution", value);
                                            formik.setFieldValue("institutionCountry", value)
                                            formik.setFieldValue("institutionProvince", "");
                                            formik.setFieldValue("institutionCity", "");
                                        }}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.id ? historydata.institutionCountry === formik?.values.institutionCountry : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('province')}</label>
                                    <Select2
                                        name="institutionProvince"
                                        options={this.props.institutionProvinceList?.map(province => { return { value: province.code, label: province.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.institutionProvince}
                                        onChange={(name, value) => {
                                            this.props.cityList("institution", value);
                                            formik.setFieldValue("institutionProvince", value);
                                            formik.setFieldValue("institutionCity", "");
                                        }}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm || formik.values.institutionCountry != '62'}
                                        same={historydata?.id ? historydata.institutionProvince === formik?.values.institutionProvince : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('city')}</label>
                                    <Select2
                                        name="institutionCity"
                                        options={this.props.institutionCityList?.map(city => { return { value: city.code, label: city.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.institutionCity}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm || formik.values.institutionCountry != '62'}
                                        same={historydata?.id ? historydata.institutionCity === formik?.values.institutionCity : true }
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div>
                        <label className="form-label" style={{ fontSize: 16 }}>{t('last_immunization')}</label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('vaccine_storage')}</label>
                                    <Input
                                        name="vaccineStorage"
                                        type="text"
                                        placeholder={t('vaccine_storage')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('vaccineStorage')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.id ? historydata.vaccineStorage === formik?.values.vaccineStorage : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('shake_well_before_use')}</label>
                                    <div className={historydata?.id ? ((vaccineShakeBefore === formik?.values.vaccineShakeBefore) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="vaccineShakeBefore"
                                                checked={formik.values.vaccineShakeBefore === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div><div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="true" name="vaccineShakeBefore"
                                                checked={formik.values.vaccineShakeBefore === "true"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="false" name="vaccineShakeBefore"
                                                checked={formik.values.vaccineShakeBefore === "false"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('first_dose')}</label>
                                    <div className={historydata?.id ? ((vaccineFirstMultiDose === formik?.values.vaccineFirstMultiDose) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="vaccineFirstMultiDose"
                                                checked={formik.values.vaccineFirstMultiDose === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="true" name="vaccineFirstMultiDose"
                                                checked={formik.values.vaccineFirstMultiDose === "true"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="false" name="vaccineFirstMultiDose"
                                                checked={formik.values.vaccineFirstMultiDose === "false"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('disposable_injection_device')}</label>
                                    <div className={historydata?.id ? ((vaccineInjectionDevice === formik?.values.vaccineInjectionDevice) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="vaccineInjectionDevice"
                                                checked={formik.values.vaccineInjectionDevice === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="true" name="vaccineInjectionDevice"
                                                checked={formik.values.vaccineInjectionDevice === "true"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="false" name="vaccineInjectionDevice"
                                                checked={formik.values.vaccineInjectionDevice === "false"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div>
                        <div style={{ marginBottom: 10}}>
                            {this.createVaccinesUI(formik)}
                            {this.initialVaccinesUI(formik)}
                        </div>
                        <ButtonWithIcon
                            text={t('add_vaccine')}
                            icon="fa fa-plus"
                            onClick={this.addVaccinesUI.bind(this, formik)} 
                            isSubmitting={this.state.isDisabled}
                            width="200px"
                            disabled={isDisabledForm}
                        />
                        <hr />
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('same_vial')}</label>
                                    <div className={historydata?.id ? ((sameVialOption !== formik?.values.sameVialOption)? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="sameVialOption"
                                                checked={formik.values.sameVialOption === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Yes" name="sameVialOption"
                                                checked={formik.values.sameVialOption === "Yes"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes_there')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="No" name="sameVialOption"
                                                checked={formik.values.sameVialOption === "No"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                    {
                                        formik.values.sameVialOption === "Yes" && (
                                            <Input
                                                name="sameVial"
                                                placeholder="Orang"
                                                disabled={isDisabledForm}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('sameVial')}
                                                same={historydata?.id ? (historydata.sameVial <= 0 ? '' : historydata?.sameVial) === formik?.values.sameVial : true}
                                            />
                                        ) 
                                    }
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('same_adverse')} </label>
                                    <div className={historydata?.id ? ((sameAdverseOption === formik?.values.sameAdverseOption) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="sameAdverseOption"
                                                checked={formik.values.sameAdverseOption === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Yes" name="sameAdverseOption"
                                                checked={formik.values.sameAdverseOption === "Yes"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes_there')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="No" name="sameAdverseOption"
                                                checked={formik.values.sameAdverseOption === "No"}
                                                onChange={formik.handleChange}
                                                onClick={(event) => formik.setFieldValue("sameAdverse", "")} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                    {
                                        formik.values.sameAdverseOption === "Yes" && (
                                            <Input
                                                name="sameAdverse"
                                                placeholder="Orang"
                                                disabled={isDisabledForm}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('sameAdverse')} 
                                                same={historydata?.id ? (historydata.sameVial <= 0 ? '' : historydata?.sameVial) === formik?.values.sameVial : true}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('unvaccinated_person')}</label>
                                    <div className={historydata?.id ? ((sameSymptomps === formik?.values.sameSymptomps) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="sameSymptomps"
                                                checked={formik.values.sameSymptomps === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="true" name="sameSymptomps"
                                                checked={formik.values.sameSymptomps === "true"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes_there')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="false" name="sameSymptomps"
                                                checked={formik.values.sameSymptomps === "false"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('diluent_vaccine')}</label>
                                    <div className={historydata?.id ? ((diluentMedicineOption === formik?.values.diluentMedicineOption) === false ? "change-value-radio" : "" ) : "" }>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Unknown" name="diluentMedicineOption"
                                                checked={formik.values.diluentMedicineOption === "Unknown"}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Yes" name="diluentMedicineOption"
                                                checked={formik.values.diluentMedicineOption === "Yes"}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('yes_there')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="No" name="diluentMedicineOption"
                                                checked={formik.values.diluentMedicineOption === "No"}
                                                onChange={formik.handleChange}
                                                onClick={(event) => formik.setFieldValue("diluentMedicine", "")} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                    {
                                        formik.values.diluentMedicineOption === "Yes" && (
                                            <Input
                                                name="diluentMedicine"
                                                type="text"
                                                placeholder="Nama obat"
                                                disabled={isDisabledForm}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('diluentMedicine')} 
                                                same={historydata?.id ? (historydata.diluentMedicine !== undefined && historydata.diluentMedicine !== null ? (historydata?.diluentMedicine !== '#?#' ? historydata?.diluentMedicine : "") : "") === formik?.values.diluentMedicine : true}
                                            />

                                        )
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

    updateValue = (i, value) => {

        const vaccineList = this.props.vaccineList;
        const formik = this.props.formik;

        if (!vaccineList) {
            formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, null)
        }

        const filtered = vaccineList?.filter(x => i < formik.values.vaccines?.length && x.id === value)
        
        if (filtered.length === 0) {
            formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, null)
        }
        if(filtered[0].masterVaccineRelated.length === 1){
            filtered[0].masterVaccineRelated.map(item => {
                formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, item.itemId)
            })
        }
    }

    // Vaccines Dynamic Table

    initialVaccinesUI(formik) {

        const vaccines = this.props.vaccines;

        if (vaccines && vaccines.length) {

            for (var i = 0; i < vaccines.length; i++) {

                if (vaccines[i] && !vaccines[i].isNew && !vaccines[i].previewed) {
                    formik.setFieldValue(`vaccines[${i}][id]`, vaccines[i].id)
                    formik.setFieldValue(`vaccines[${i}][vaccine]`, vaccines[i].vaccine)
                    formik.setFieldValue(`vaccines[${i}][dose]`, vaccines[i].dose)
                    formik.setFieldValue(`vaccines[${i}][doseFor]`, vaccines[i].doseFor)
                    formik.setFieldValue(`vaccines[${i}][batch]`, vaccines[i].batch)
                    formik.setFieldValue(`vaccines[${i}][routeImmunization]`, vaccines[i].routeImmunization)
                    formik.setFieldValue(`vaccines[${i}][siteInjection]`, vaccines[i].siteInjection)
                    formik.setFieldValue(`vaccines[${i}][expiredDate]`, Utils.getDate(vaccines[i].expiredDate))
                    formik.setFieldValue(`vaccines[${i}][grantDate]`, Utils.getDate(vaccines[i].grantDate))
                    formik.setFieldValue(`vaccines[${i}][grantTime]`, Utils.getTime(vaccines[i].grantDate))
                    formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, vaccines[i].vaccineItemId)
                }

                this.props.vaccines[i].previewed = true;
            }
        }
    }

    createVaccinesUI(formik) {

        const {t, erpList} = this.props;

        const vaccineList = this.props.vaccineList;

        const immunizationRouteList = this.props.immunizationRouteList;
        const historydata = this.props.historydata;
        const historyVaccines = historydata?.trailAefiVaccine ? historydata?.trailAefiVaccine : [];
        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion ||  this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        const calculateOptions = (i) => {
            if (!vaccineList) {
                return []
            }

            const filtered = vaccineList.filter(x => i < formik.values.vaccines?.length && x.id === formik.values.vaccines[i]?.vaccine)
            if (filtered.length === 0) {
                return []
            }

            return filtered[0].masterVaccineRelated?.map(item => ({ value: item.itemId, label: item.itemName }))
        }
        
        return this.props.vaccines.map((element, i) =>

            <div key={i}>
                <Input
                    name={`vaccines[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`vaccines[${i}][id]`)} 
                    disabled={isDisabledForm}
                    same={historyVaccines[i] ? historyVaccines[i]?.id === formik.values.vaccines[i]?.id : true }
                />
                <div className="dynamic-form">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-3">
                                    <div className="form-group">
                                        {<label className="form-label">{t('vaccine')}</label>}
                                        <Select2
                                            name={`vaccines[${i}][vaccine]`}
                                            options={vaccineList?.map(item => { return { value: item.id, label: item.name }; })}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][vaccine]`)}
                                            onChange={(name, value) => {
                                                formik.setFieldValue(name, value);
                                                this.updateValue(i, value)
                                            }}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.vaccine === formik.values.vaccines[i]?.vaccine : true }
                                        >
                                        </Select2>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="form-group">
                                        {<label className="form-label">{t('dose')}</label>}
                                        <Input
                                            name={`vaccines[${i}][dose]`}
                                            type="number"
                                            step=".01"
                                            min={0} 
                                            placeholder="ml"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][dose]`)}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.dose === formik.values.vaccines[i]?.dose : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="form-group">
                                        {<label className="form-label">{t('dose_number')}</label>}
                                        <Input
                                            name={`vaccines[${i}][doseFor]`}
                                            type="number"
                                            step="1"
                                            min={0}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][doseFor]`)}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.doseFor === formik.values.vaccines[i]?.doseFor : true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('batch_number')}</label>}
                                        <Input
                                            name={`vaccines[${i}][batch]`}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][batch]`)} 
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.batch === formik.values.vaccines[i]?.batch : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('route')}</label>}
                                        <Select2
                                            name={`vaccines[${i}][routeImmunization]`}
                                            options={immunizationRouteList?.map(item => { return { value: item.id, label: item.name }; })}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][routeImmunization]`)}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.routeImmunization === formik.values.vaccines[i]?.routeImmunization : true }
                                        >
                                        </Select2>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label">{t('erp_product_name')}</label>
                                        <Select2
                                            name={`vaccines[${i}][vaccineItemId]`}
                                            options={calculateOptions(i)}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][vaccineItemId]`)}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.vaccine === formik.values.vaccines[i]?.vaccineItemId : true }
                                        >
                                        </Select2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-3"/>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('site_of_injection')}</label>}
                                        <Input
                                            name={`vaccines[${i}][siteInjection]`}
                                            type="text"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`vaccines[${i}][siteInjection]`)} 
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? historyVaccines[i]?.siteInjection === formik.values.vaccines[i]?.siteInjection : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-7 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('expired_date')}</label>}
                                        <DatePicker
                                            name={`vaccines[${i}][expiredDate]`}
                                            selectionType="single"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={element.expiredDate}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? (formik.values.vaccines[i]?.expiredDate ? moment(historyVaccines[i]?.expiredDate).format("YYYY-MM-DD") === formik.values.vaccines[i]?.expiredDate : historyVaccines[i]?.expiredDate === element.expiredDate) : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('date_of_vaccination')}</label>}
                                        <DatePicker
                                            name={`vaccines[${i}][grantDate]`}
                                            selectionType="single"
                                            maxdate={new Date()}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={element.grantDate}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? (formik.values.vaccines[i]?.grantDate ? moment(historyVaccines[i]?.grantDate).format("YYYY-MM-DD") === formik.values.vaccines[i]?.grantDate : moment(historyVaccines[i]?.grantDate).format("YYYY-MM-DD") === element.grantDate) : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('time')}</label>}
                                        <TimePicker
                                            name={`vaccines[${i}][grantTime]`}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={formik.values.vaccines && formik.values.vaccines[i]?.grantTime ? formik.values.vaccines[i]?.grantTime : element.grantDate ? moment(element.grantDate).format("HH:mm") : null}
                                            disabled={isDisabledForm}
                                            same={historyVaccines[i] ? (formik.values.vaccines[i]?.grantTime ? moment(historyVaccines[i]?.grantDate).format("HH:mm:ss") === formik.values.vaccines[i]?.grantTime : moment(historyVaccines[i]?.grantDate).format("HH:mm") === moment(element.grantDate).format("HH:mm")) : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="align-items-center button-close">
                                        {
                                            i != 0 &&

                                            <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeVaccinesUI.bind(this, formik, i)} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
                
            </div>
        )
    }

    addVaccinesUI(formik) {
        this.props.vaccines.push({ isNew: true })
        formik.setFieldValue(`vaccines[${this.props.vaccines.length - 1}][id]`, null)
    }

    removeVaccinesUI(formik, index) {

        for (var i = index; i < this.props.vaccines.length; i++) {

            const vaccines = formik.values.vaccines[i + 1];

            this.props.vaccines[i] = this.props.vaccines[i + 1];

            if (vaccines) {
                formik.setFieldValue(`vaccines[${i}][id]`, vaccines.id)
                formik.setFieldValue(`vaccines[${i}][vaccine]`, vaccines.vaccine)
                formik.setFieldValue(`vaccines[${i}][dose]`, vaccines.dose)
                formik.setFieldValue(`vaccines[${i}][doseFor]`, vaccines.doseFor)
                formik.setFieldValue(`vaccines[${i}][batch]`, vaccines.batch)
                formik.setFieldValue(`vaccines[${i}][routeImmunization]`, vaccines.routeImmunization)
                formik.setFieldValue(`vaccines[${i}][siteInjection]`, vaccines.siteInjection)
                formik.setFieldValue(`vaccines[${i}][expiredDate]`, Utils.getDate(vaccines.expiredDate))
                formik.setFieldValue(`vaccines[${i}][grantDate]`, Utils.getDate(vaccines.grantDate))
                formik.setFieldValue(`vaccines[${i}][grantTime]`, vaccines.grantTime)
                formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, vaccines.vaccineItemId)
            }
            else {
                formik.setFieldValue(`vaccines[${i}][id]`, null)
                formik.setFieldValue(`vaccines[${i}][vaccine]`, null)
                formik.setFieldValue(`vaccines[${i}][dose]`, null)
                formik.setFieldValue(`vaccines[${i}][doseFor]`, null)
                formik.setFieldValue(`vaccines[${i}][batch]`, null)
                formik.setFieldValue(`vaccines[${i}][routeImmunization]`, null)
                formik.setFieldValue(`vaccines[${i}][siteInjection]`, null)
                formik.setFieldValue(`vaccines[${i}][expiredDate]`, null)
                formik.setFieldValue(`vaccines[${i}][grantDate]`, null)
                formik.setFieldValue(`vaccines[${i}][grantTime]`, null)
                formik.setFieldValue(`vaccines[${i}][vaccineItemId]`, null)
            }
        }

        formik.values.vaccines.pop();
        this.props.vaccines.pop();
    }

}

export default withTranslation()(Immunization);
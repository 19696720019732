import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import ToggleDisplay from 'react-toggle-display';

import * as Utils from '../../../../helpers/Utils';
import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import { Role, Status } from '../../../../helpers/Constants';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { AEFIService } from '../../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../../services/CaseEntry/AEService';
import { authenticationService } from '../../../../services/AuthService';

import { LetterModal } from "../LetterModal";

import { withTranslation } from "react-i18next";

class ReportInformation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            caseCategory: this.props.caseCategory,
            snackbaropen: false,
            snackbarmsg: '',
            saveShow: false
        };
    }

    componentDidUpdate(prevProps) { }

    componentDidMount() { }

    handleBackClick(event) {
        this.props.history.push({ pathname: `/report-submission/manufacture`, state: { tab: 'Initial' } });
    }
    handleCancelClick(event) {

        this.props.history.push('/medical-review/resume');
    }

    handleSubmitClick(event) {
        if (!this.formikRef.current.isSubmitting) {
            this.formikRef.current.submitForm();
        }

        event.preventDefault();
    }

    handleReject(role) {

        if (role === Role.Unit) {
            this.setState({ RejectedModal: true, RejectedModalConfirmationModal: false });
        }
        if (role === Role.Divisi) {
            this.setState({ RejectedModal: true, RejectedModalConfirmationModal: false });
        }
    }

    handleAccept(role) {

        if (role === Role.Unit) {
            this.setState({ ForwardedModal: true, ForwardedConfirmationModal: false });
        }
        if (role === Role.Divisi) {
            this.setState({ ForwardedModal: true, ForwardedConfirmationModal: false });
        }
    }

    handleCaseCategoryChange(item) {
        if (item === "KIPI") {
            AEFIService.Read("", 1, 0).then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        } else {
            AEService.Read("", 1, 0).then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        }
    }

    handleCaseNumberChange(formik, value) {
        const filtered = this.props.caseNumberList ? this.props.caseNumberList.filter(item => item.caseNumber === value)[0] : [];
        let products = [];
        let symptomps = [];

        if (this.state.caseCategory === "KIPI") {
            products = filtered?.aefiVaccine.map(idx => ({
                product: idx.vaccineNavigation.id,
                productName: idx.vaccineNavigation.name,
                batch: idx.batch,
                manufacture: idx.vaccineNavigation.manufactureNavigation.name
            }));
        } else {
            products = filtered?.aeDrugs.map(idx => ({
                product: idx.drugsNavigation.id,
                productName: idx.drugsNavigation.name,
                batch: '',
                manufacture: ''
            }))
        }

        if (this.state.caseCategory === "KIPI") {
            symptomps = filtered?.aefiSymptomps.map(idx => idx.symptompsNavigation.name).join(", ")
        } else {

        }

        formik.setFieldValue("manufactureSubmissionProduct", products)
        formik.setFieldValue("caseNumber", value)
        formik.setFieldValue("complaintNo", value);
        formik.setFieldValue("complaintFrom", filtered?.reporterName)
        formik.setFieldValue("initialName", filtered?.patientName)
        formik.setFieldValue("patientGender", filtered?.patientGender)
        formik.setFieldValue("ageOfYear", filtered?.patientAgeYear)
        formik.setFieldValue("ageOfMonth", filtered?.patientAgeMonth)
        formik.setFieldValue("immunizationDate", Utils.getDate(filtered?.lastImmunizationDate))
        formik.setFieldValue("clinicalSymptoms", symptomps)
        formik.setFieldValue("outcome", filtered?.outcomeNavigation?.id)
        formik.setFieldValue("caseEntry", filtered?.id)
        formik.setFieldValue("symptomsDate", Utils.getDate(filtered?.onsetDate))
        formik.setFieldValue("reportedDate", Utils.getDate(filtered?.reportedDate))
    }

    render() {
        const { t } = this.props;
        const formik = this.props.formik;
        const initials = formik.values;

        const currentUser = authenticationService.currentUserValue;
        const canStaffEdit = currentUser.role === Role.Staff && (initials?.status === Status.Pending || initials?.status === Status.Draft || initials?.status === Status.NotOk || initials?.status === Status.NotApproved || this.props.isFollowUp);
        const canUnitEdit = currentUser.role === Role.Unit && (initials?.status === Status.Draft || initials?.status === Status.NotApproved);
        const canDivisiEdit = currentUser.role === Role.Divisi && (initials?.status === Status.Draft || initials?.status === Status.Reviewed);

        const filtered = this.props.caseNumberList.filter(item => item.caseNumber === initials?.caseNumber)[0];
        let products = [];
        let symptomps = [];

        if (initials) {
            if (initials?.aefiCaseEntry) {
                products = filtered?.aefiVaccine.map(idx => ({
                    product: idx.vaccineNavigation.id,
                    productName: idx.vaccineNavigation.name,
                    batch: idx.batch
                }));
            } else if (initials?.aefiCaseEntry) {
                products = filtered?.aeDrugs.map(idx => ({
                    product: idx.drugsNavigation.id,
                    productName: idx.drugsNavigation.name,
                    batch: ''
                }))
            }
        }

        if (initials?.aefiCaseEntry) {
            symptomps = filtered?.aefiCaseEntryNavigation?.aefiSymptomps.map(idx => idx.symptompsNavigation.name).join(", ")
        } else {

        }

        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        return (
            <>

                <LetterModal
                    show={this.state.letterShow}
                    onHide={LetterModalClose}
                    formik={formik}
                    type="initial"
                />
                <div className="tab-pane show tab-pane-fixed active" id="report-information">
                    <div className="row">
                        <div className="col-md-6">
                            <input
                                disabled
                                name="caseEntry"
                                type="hidden"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('caseEntry')}
                            />
                            <input
                                disabled
                                name="typeCaseEntry"
                                type="hidden"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('typeCaseEntry')}
                            />
                            <div className="form-group">
                                <label className="form-label">{t('case_category')}</label>
                                <Select2
                                    disabled
                                    name="caseCategory"
                                    options={[{ value: "KIPI", label: "KIPI" }, { value: "KTD", label: "KTD" }]}
                                    value={formik.values.caseCategory}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={(name, value) => {
                                        this.handleCaseCategoryChange(value)
                                        formik.setFieldValue("caseCategory", value)
                                        formik.setFieldValue("caseNumber", "")
                                        formik.setFieldValue("typeCaseEntry", value === "KIPI" ? "aefi" : "ae")

                                        this.setState({ caseCategory: value })
                                    }}
                                    onBlur={formik.setFieldTouched}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('case_number')}</label>
                                <Select2
                                    disabled
                                    name="caseNumber"
                                    options={this.props.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values.caseNumber}
                                    onChange={(name, value) => {
                                        this.handleCaseNumberChange(formik, value)
                                    }}
                                    onBlur={formik.setFieldTouched}
                                />
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">{t('place')}</label>
                                        <input
                                            disabled={(currentUser.role === Role.Staff && !canStaffEdit) || (currentUser.role === Role.Divisi && !canDivisiEdit)}
                                            name="mailingPlace"
                                            min={0}
                                            className="form-control"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('mailingPlace')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">{t('letter_date')}</label>
                                        <DatePicker
                                            disabled={(currentUser.role === Role.Staff && !canStaffEdit) || (currentUser.role === Role.Divisi && !canDivisiEdit)}
                                            name="mailingDate"
                                            selectionType="single"
                                            maxdate={new Date()}
                                            value={formik.values?.mailingDate}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">{t('case_created_date')}</label>
                                        <DatePicker
                                            disabled
                                            name="reportedDate"
                                            selectionType="single"
                                            maxdate={new Date()}
                                            value={formik.values?.reportedDate}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Manufacturer</label>
                                <Select2
                                    // disabled
                                    name="manufacture"
                                    value={formik.values.manufacture}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={(name, value) => {
                                        formik.setFieldValue("manufacture", value)
                                        formik.setFieldValue("target", this.props.manufactureList?.map(item => {
                                            if (item.id === value) {
                                                return item.destination
                                            }
                                            return ""
                                        }).join(" "))
                                    }}
                                    onBlur={formik.setFieldTouched}
                                    options={this.props.manufactureList?.filter(
                                        manufakture => manufakture.masterVaccine
                                            .map(item => item.id)
                                            .some(item => formik.values.manufactureSubmissionProduct?.map(item => item.product).includes(item))
                                    ).map(item => { return { value: item.id, label: item.name }; })}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('letter_address')}</label>
                                <Textarea
                                    rows="6"
                                    name="target"
                                    placeholder="Kronologi"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values?.target ? formik.values?.target : ""}
                                    {...formik.getFieldProps('target')}
                                    disabled
                                />
                            </div>
                        </div>

                    </div>

                    <hr />

                    <label className="form-label" style={{ fontSize: 16 }}>COMPLAINT INFORMATION</label>

                    <div className="row">


                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Complaint No</label>
                                <Input
                                    disabled
                                    name="complaintNo"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('complaintNo')}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-3" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div className="form-group">
                                        <label className="form-label">{t('vaccine')}</label>
                                    </div>
                                </div>
                                <div className="col-md-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                    <div className="form-group">
                                        <label className="form-label">Batch</label>
                                    </div>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group">
                                        <label className="form-label">Manufacture</label>
                                    </div>
                                </div>
                            </div>
                            {formik.values.manufactureSubmissionProduct?.map((record, index) =>
                                <div className="row">
                                    <div className="col-md-3" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <div className="form-group">
                                            <Input
                                                disabled
                                                name="vaccine"
                                                type="text"
                                                value={record.productName}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <div className="form-group">
                                            <Input
                                                disabled
                                                name="batch"
                                                type="text"
                                                value={record.batch}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-7" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                        <div className="form-group">
                                            <Input
                                                disabled
                                                name="manufacture"
                                                type="text"
                                                value={record.manufacture}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">From</label>
                                <Input
                                    disabled
                                    name="complaintFrom"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('complaintFrom')}
                                />
                            </div>

                        </div>
                    </div>

                    <hr />

                    <label className="form-label" style={{ fontSize: 16 }}>COMPLAINT DESCRIPTION</label>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('initial_name')}</label>
                                <Input
                                    disabled
                                    name="initialName"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('initialName')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('gender')}</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input"
                                            checked={formik.values.patientGender === "U"}
                                            value="U"
                                            name="patientGender"
                                            disabled
                                        />
                                        <label className="form-check-label">{t('no_data')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input"
                                            checked={formik.values.patientGender === "F"}
                                            value="F"
                                            name="patientGender"
                                            disabled
                                        />
                                        <label className="form-check-label">{t('male')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input"
                                            checked={formik.values.patientGender === "M"}
                                            value="M"
                                            name="patientGender"
                                            disabled
                                        />
                                        <label className="form-check-label">{t('female')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">{t('age')}</label>
                                        <div className="input-group">
                                            <input
                                                disabled
                                                name="ageOfYear"
                                                type="number"
                                                min={0}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('ageOfYear')}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('year')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">-</label>
                                        <div className="input-group">
                                            <input
                                                disabled
                                                name="ageOfMonth"
                                                type="number"
                                                min={0}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('ageOfMonth')}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('month')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('immunization_date')}</label>
                                <DatePicker
                                    disabled
                                    name="immunizationDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values?.immunizationDate}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('symptom_date')}</label>
                                <DatePicker
                                    disabled
                                    name="symptomsDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values?.symptomsDate}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('clinical_symptoms')}</label>
                                <Input
                                    disabled
                                    name="clinicalSymptoms"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('clinicalSymptoms')}
                                    value={formik.values?.clinicalSymptoms}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('outcome')}</label>

                                <Select2
                                    disabled
                                    name="outcome"
                                    options={this.props.list?.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                    value={formik.values.outcome}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                />
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div className="form-group">
                        <label className="form-label">{t('letter_of_notification')}</label>
                        <Button
                            text={t('letter_preview')}
                            onClick={() => this.setState({ letterShow: true })}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ReportInformation);
import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';
import { Loader } from '../../../../components/Utils/Loader/Loader';
import { DropdownService } from '../../../../services/Master/AllDropdownService';
import { PSURService } from '../../../../services/PSUR/PSURService';

import { withTranslation } from "react-i18next";

class ProductReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            loading: false,
            productList : [],
            timezone: moment(new Date()).format('Z'),
        };
    }

    componentDidMount() {

        DropdownService.MasterVaccineList("").then(
            (result) => {
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "vaccine" }; })
                        ]
                })
            })

        DropdownService.MasterDrugsList("").then(
                (result) => {
                    this.setState({
                        productList: [
                            ...this.state.productList,
                            ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "drugs" }; })
                            ]
                    })
                })
    }

    refreshPSUR = () => {

        this.handleLoading(true)

        let data = [];

        PSURService.FormData(this.props.formik.values?.product, this.state.params).then(result => {

            data = result?.data ? result?.data : [];
            
            this.props.formik.setFieldValue('caseTotal', data?.psurCaseList?.length);
            this.props.formik.setFieldValue('psurCaseList', data?.psurCaseList ?? []);
            this.props.formik.setFieldValue('psurMarketAuthorizationCountry', data?.psurMarketAuthorizationCountry ?? []);
            this.props.formik.setFieldValue('psurMarketAuthorizationInstitution', data?.psurMarketAuthorizationInstitution ?? []);
            this.props.formik.setFieldValue('psurProductDistributionRecords', data?.psurProductDistributionRecords ?? []);
            this.props.formik.setFieldValue('psurSummaryTabulations', data?.psurSummaryTabulations ?? []);
            
            this.handleLoading(false)
        });
    }

    componentDidUpdate(prevProps, prevState) {
    
        if(prevProps.formik.values?.product !== this.props.formik.values?.product || prevProps.formik.values?.startDate !== this.props.formik.values?.startDate || prevProps.formik.values?.endDate !== this.props.formik.values?.endDate){

            if(this.props.formik.values?.product && this.props.formik.values?.startDate && this.props.formik.values?.endDate && this.props.formType !== 'edit' && this.state.params){

                this.refreshPSUR();
            }

        }

        if((prevProps.formik.values?.startDate !== this.props.formik.values?.startDate || prevProps.formik.values?.endDate !== this.props.formik.values?.endDate) && this.props.formType === 'edit'){

            if(this.props.formik.values?.product && this.props.formik.values?.startDate && this.props.formik.values?.endDate && this.state.params){

                this.refreshPSUR();
            }
        }
    
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null  ? start.replaceAll("/", "-") +"Z"+ this.state.timezone : "";
        let endDate = end !== "" && end !== null  ? end.replaceAll("/", "-") +"Z"+ this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => { 
            this.props.formik.setFieldValue('startDate', start)
            this.props.formik.setFieldValue('endDate', end)
        });
        
    }

    render() {

        const { formik, typecase, disabled, list, formType, t,...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD"/>
                    )
                }
                
                <div className="tab-pane show active tab-pane-fixed" id="product-report">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('product_name')}</label>
                                {
                                    formType === "edit" ? (
                                        <Input
                                            name="productName"
                                            type="text"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps("productName")}
                                            disabled
                                        />
                                    ) : (
                                        <Select2
                                            name="product"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            value={formik?.values?.product}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            options={this.state.productList}
                                        />
                                    )
                                }
                            </div>
                            <div className="form-group">
                                <label className="form-label">Periode</label>
                                <DatePicker
                                    handlechange={(startDate, endDate, type) => this.handleDateChange(startDate, endDate, type)}
                                    type="KIPI"
                                    name="periode"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    change={formik.setFieldValue}
                                    value={formik.values.periode ?? ""}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('case_total')}</label>
                                <Input
                                    name="caseTotal"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps("caseTotal")}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">CASE LISTING</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>{t('case_number')}</th>
                                                <th>{t('country')}</th>
                                                <th>{t('data_source')}</th>
                                                <th style={{minWidth: 100}}>{t('age')}</th>
                                                <th>{t('gender')}</th>
                                                <th>{t('dose')}</th>
                                                <th>{t('rute')}</th>
                                                <th>{t('immunization_date')}</th>
                                                <th>{t('onset_date_aefi')}</th>
                                                <th>{t('treatment_date')}</th>
                                                <th>{t('diagnose')}</th>
                                                <th>Preferred Term</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.psurCaseList?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{record?.caseNumber ?? "-"}</td>
                                                    <td>{record?.patientCountryName ?? "-"}</td>
                                                    <td>{record?.dataSourceName ?? "-"}</td>
                                                    <td>{(record?.patientAgeYear ? record?.patientAgeYear + " Tahun " : "") + (record?.patientAgeMonth ? record?.patientAgeMonth + " Bulan" : "")}</td>
                                                    <td>{record?.patientGender ? (record?.patientGender === "M" ? "Laki-laki" : record?.patientGender === "F" ? "Perempuan" : "Tidak Ada Data") : "-" }</td>
                                                    <td>{record?.dose ?? "-"}</td>
                                                    <td>{record?.routeImmunizationName ?? "-"}</td>
                                                    <td>{record?.grantDate ? moment(record?.grantDate).format('DD/MM/YYYY') : "-"}</td>
                                                    <td>{record?.onsetDate ? moment(record?.onsetDate).format('DD/MM/YYYY') : "-"}</td>
                                                    <td>{record?.treatmentDate ? moment(record?.treatmentDate).format('DD/MM/YYYY') : "-"}</td>
                                                    <td>{record?.diagnosis ?? "-"}</td>
                                                    <td>{record?.preferedTermName ?? "-"}</td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.psurCaseList === undefined || formik.values?.psurCaseList === null || formik.values?.psurCaseList?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ProductReport);
import React, { Component } from "react";
import { Formik, Form } from "formik";
import moment from "moment";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import ProductReport from "./Tab/ProductReport";
import MarketAuthorization from "./Tab/MarketAuthorization";
import ProductDistribution from "./Tab/ProductDistribution";
import SummaryTabulations from "./Tab/SummaryTabulations";

import { Role, Status } from "../../../helpers/Constants";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { PSURService } from "../../../services/PSUR/PSURService";
import { authenticationService } from "../../../services/AuthService";

import { withTranslation } from "react-i18next";

export class DetailPSUR extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    const { page } = this.props?.location?.state;

    this.state = {
      id: id,
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      nextTab: "market-authorization",
      currentTab: "product-report",
      historyForm: [],
      records: [],
    };
    this.formikRef = React.createRef();

    this.prepareForm(id);
  }

  prepareForm(id) {
    PSURService.PrepareForm(id).then((result) => {
      if (result.data) {
        this.setState({
          records: result.data,
        });
      }
    });
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#product-report":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "product-report",
          backTab: "",
          nextTab: "market-authorization",
        });
        break;
      case "#market-authorization":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "market-authorization",
          backTab: "product-report",
          nextTab: "product-distribution",
        });
        break;
      case "#product-distribution":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "product-distribution",
          backTab: "market-authorization",
          nextTab: "summary-tabulation",
        });
        break;
      case "#summary-tabulation":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "summary-tabulation",
          backTab: "product-distribution",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push(`/report-submission/psur`, {
      pages: this.props?.location?.state?.page,
    });
    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  render() {
    const psur = this.state.records;
    const { t } = this.props;

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit = currentUser.role === Role.Staff;

    const initial = {
      id: psur?.id,
      product: psur?.product,
      productName: psur?.productName,
      caseTotal: psur?.caseTotal,
      startDate: psur?.startDate,
      endDate: psur?.endDate,
      periode: moment().range(moment(psur?.startDate), moment(psur?.endDate)),
      psurCaseList: psur?.psurCaseList,
      psurMarketAuthorizationCountry: psur?.psurMarketAuthorizationCountry,
      psurMarketAuthorizationInstitution:
        psur?.psurMarketAuthorizationInstitution,
      psurProductDistributionRecords: psur?.psurProductDistributionRecords,
      psurSummaryTabulations: psur?.psurSummaryTabulations,
    };

    return (
      <>
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <ul className="nav nav-justified mb-3 menu-tab">
              <Tab
                number="1"
                showNumber={true}
                title="Product Report"
                href="#product-report"
                onClick={(event) =>
                  this.handleTabChange(event, "#product-report")
                }
                id="link-product-report"
              />
              <Tab
                number="2"
                showNumber={true}
                title="Market Authorization"
                href="#market-authorization"
                onClick={(event) =>
                  this.handleTabChange(event, "#market-authorization")
                }
                id="link-market-authorization"
              />
              <Tab
                number="3"
                showNumber={true}
                title="Product Distribution"
                href="#product-distribution"
                onClick={(event) =>
                  this.handleTabChange(event, "#product-distribution")
                }
                id="link-product-distribution"
              />
              <Tab
                number="4"
                showNumber={true}
                title="Summary Tabulations"
                href="#summary-tabulation"
                onClick={(event) =>
                  this.handleTabChange(event, "#summary-tabulation")
                }
                id="link-summary-tabulation"
              />
            </ul>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          initialValues={initial}
          enableReinitialize
          validationSchema={Yup.object().shape({
            // caseNumber : Yup.string().required("Required").nullable(),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            PSURService.Update(this.props.match?.params?.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/report-submission/psur`, {
                  pages: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <ProductReport
                  formik={formik}
                  formType="edit"
                  disabled={!canStaffEdit}
                />

                <MarketAuthorization formik={formik} />

                <ProductDistribution formik={formik} />

                <SummaryTabulations formik={formik} />

                {this.state.backTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleCancelClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab !== "" && this.state.backTab !== "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay show={this.state.saveShow && canStaffEdit}>
                      <Button
                        type="submit"
                        text={t("save")}
                        disabled={formik.isSubmitting}
                      />
                    </ToggleDisplay>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailPSUR);

import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const AEFIOverseasService = {
    Create,
    Read,
    ReadAefi,
    Update,
    PrepareForm,
    submitForm,
    Delete,
    Download,
    DownloadDoc
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/kipi-luar-negeri/create/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitForm(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-luar-negeri/submit-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/kipi-luar-negeri/update/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/bpom/kipi-luar-negeri/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAefi(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/kipi-luar-negeri/read-aefi?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}


async function PrepareForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-luar-negeri/prepare-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-luar-negeri/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Download(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/bpom-kipi-luar-negeri/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Surat Laporan KIPI Luar Negeri ke BPOM dengan nomor kasus ${casenumber}`);
        document.body.appendChild(link);
        link.click();
    })
}

async function DownloadDoc(id, casenumber) {
    fetch('/api/v1/report-submission/doc/bpom-kipi-luar-negeri/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Surat Laporan KIPI Luar Negeri ke BPOM dengan nomor kasus ${casenumber}.docx`);
        document.body.appendChild(link);
        link.click();
    })
}
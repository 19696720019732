import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';

class ClassificationIncomplete extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            vaccine : ""
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    handleCancelClick(event) {

        this.props.onhide()
    }

    render() {
        const { formik, typecase, history, list, causality, index, disabled, t, ...otherProps } = this.props;
        let diagnosisType = history?.diagnosisType ? t('certainly') : t('temporary');

        let otherUnknown;
        let otherProgrammaticError;
        let otherVaccineReaction;
        let otherInjectionReaction;
        let otherCoincidental;
        let classifications = [];
        let subClassificationsProgrammaticError = [];
        let subClassificationsInjectionReaction = [];
        console.log(history)
        if(history){
            if(history?.analysisClassification?.length > 0 || history?.trailAnalysisClassification?.length > 0){
                let analisisClassification = history?.analysisClassification ? history?.analysisClassification : history?.trailAnalysisClassification
                analisisClassification.map((record, index) => {
                    if(record.classificationNavigation.group === "Programmatic Error"){
                        otherProgrammaticError = record.sidelight;
                        classifications = [...classifications, "0e100bb3-a828-4de5-9747-aab101de3467"]
                        subClassificationsProgrammaticError = [...subClassificationsProgrammaticError, record.classification]
                    }
                    if(record.classificationNavigation.group === "Injection Reaction"){
                        otherInjectionReaction = record.sidelight;
                        classifications = [...classifications, "cf45d3aa-47c7-4843-8c38-b781c3b5fd80"]
                        subClassificationsInjectionReaction = [...subClassificationsInjectionReaction, record.classification]
                    }
                    if(record.classificationNavigation.group === "Vaccine Reaction"){
                        otherVaccineReaction = record.sidelight;
                        classifications = [...classifications, "cdb973d2-dcbb-481f-9aaa-68bd5bebfc18"]
                    }
                    if(record.classificationNavigation.group === "Unknown"){
                        otherUnknown = record.sidelight;
                        classifications = [...classifications, "80034f4c-ae1e-4338-b409-ed2c33a86a94"]
                    }
                    if(record.classificationNavigation.group === "Coincidental"){
                        otherCoincidental = record.sidelight;
                        classifications = [...classifications, "2b50778a-4ba8-4fe6-a885-865c60e35ffc"]
                    }
                });
            }
        }

        return (
            <>
                <div className="tab-pane show active" id="classification_incomplete">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('vaccine')}</label>
                                <Input
                                    disabled
                                    value={causality?.productName}
                                    name="VaccineIncomplete"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('diagnosis_type')}</label>
                                <div className={history?.diagnosisType ? ((diagnosisType === formik.values?.diagnosisType) === false ? "change-value-radio" : "" ) : "" }>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Pasti" name="diagnosisType"
                                            checked={formik.values?.diagnosisType === "Pasti"}
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('certainly')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Sementara" name="diagnosisType"
                                            checked={formik.values?.diagnosisType === "Sementara"}
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('temporary')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('clinical_diagnosis')}</label>
                                <Textarea
                                    rows="6"
                                    name="diagnosis"
                                    placeholder={t('clinical_diagnosis')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values?.diagnosis ? formik.values?.diagnosis : ""}
                                    {...formik.getFieldProps('diagnosis')} 
                                    disabled={disabled}
                                    same={history?.diagnosis ? history.diagnosis === formik.values?.diagnosis : true }
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <label className="form-label">{t('classification_small')}</label>
                                {
                                    list?.aefiClassification.map((record, index) => 
                                    {   
                                        if(record.id === '0e100bb3-a828-4de5-9747-aab101de3467'){
                                            return (
                                                <>
                                                    <div>
                                                        <div className={history.length > 0 ? (((formik.values?.classifications?.indexOf(record.id) !== -1) === (classifications?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name="classifications" value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.classifications?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.values?.classifications?.indexOf("0e100bb3-a828-4de5-9747-aab101de3467") !== -1 && list?.aefiClassificationProgrammaticError.map((record, index) => {

                                                            if(record.name !== "Lain-lain"){
                                                                return (
                                                                    <div className="row" style={{marginLeft: 10}}>
                                                                        <div className="col-md-6">
                                                                            <div className={history.length > 0 ? (((formik.values?.subClassificationsProgrammaticError?.indexOf(record.id) !== -1) === (subClassificationsProgrammaticError?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                                <input type="checkbox" className="form-check-input" name="subClassificationsProgrammaticError" value={record.id}
                                                                                    onChange={formik.handleChange} 
                                                                                    disabled={disabled}
                                                                                    checked={formik.values?.subClassificationsProgrammaticError?.indexOf(record.id) !== -1}
                                                                                />
                                                                                <label className="form-check-label">{record.name}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }else{
                                                                return (
                                                                    <div className="row" style={{marginLeft: 10}}>
                                                                        <div className="col-md-6">
                                                                            <div className={history.length > 0 ? (((formik.values?.subClassificationsProgrammaticError?.indexOf(record.id) !== -1) === (subClassificationsProgrammaticError?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                                <input type="checkbox" className="form-check-input" name="subClassificationsProgrammaticError" value={record.id}
                                                                                    onChange={formik.handleChange} 
                                                                                    disabled={disabled}
                                                                                    checked={formik.values?.subClassificationsProgrammaticError?.indexOf(record.id) !== -1}
                                                                                />
                                                                                <label className="form-check-label">{record.name}</label>
                                                                            </div>
                                                                            <Input
                                                                                name="otherProgrammaticError"
                                                                                placeholder=""
                                                                                errors={formik.errors}
                                                                                touched={formik.touched}
                                                                                value={formik.values?.otherProgrammaticError ? formik.values?.otherProgrammaticError : ""}
                                                                                {...formik.getFieldProps('otherProgrammaticError')} 
                                                                                disabled={!(formik.values?.subClassificationsProgrammaticError?.indexOf("c45c8d24-1b4c-4896-8faa-86a3107ca409") !== -1)}
                                                                                same={history.length > 0 ? otherProgrammaticError === formik.values?.otherProgrammaticError : true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        })
                                                    }
                                                </>
                                            )
                                        }

                                        if(record.id === 'cf45d3aa-47c7-4843-8c38-b781c3b5fd80'){
                                            return (
                                                <>
                                                    <div>
                                                        <div className={history.length > 0 ? (((formik.values?.classifications?.indexOf(record.id) !== -1) === (classifications?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name="classifications" value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.classifications?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.values?.classifications?.indexOf("cf45d3aa-47c7-4843-8c38-b781c3b5fd80") !== -1 && list?.aefiClassificationInjectionReaction.map((record, index) => {

                                                            if(record.name !== "Lain-lain"){
                                                                return (
                                                                    <div className="row" style={{marginLeft: 10}}>
                                                                        <div className="col-md-6">
                                                                            <div className={history.length > 0 ? (((formik.values?.subClassificationsInjectionReaction?.indexOf(record.id) !== -1) === (subClassificationsInjectionReaction?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                                <input type="checkbox" className="form-check-input" name="subClassificationsInjectionReaction" value={record.id}
                                                                                    onChange={formik.handleChange} 
                                                                                    disabled={disabled}
                                                                                    checked={formik.values?.subClassificationsInjectionReaction?.indexOf(record.id) !== -1}
                                                                                />
                                                                                <label className="form-check-label">{record.name}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }else{
                                                                return (
                                                                    <div className="row" style={{marginLeft: 10}}>
                                                                        <div className="col-md-6">
                                                                            <div className={history.length > 0 ? (((formik.values?.subClassificationsInjectionReaction?.indexOf(record.id) !== -1) === (subClassificationsInjectionReaction?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                                <input type="checkbox" className="form-check-input" name="subClassificationsInjectionReaction" value={record.id}
                                                                                    onChange={formik.handleChange} 
                                                                                    disabled={disabled}
                                                                                    checked={formik.values?.subClassificationsInjectionReaction?.indexOf(record.id) !== -1}
                                                                                />
                                                                                <label className="form-check-label">{record.name}</label>
                                                                            </div>
                                                                            <Input
                                                                                name="otherInjectionReaction"
                                                                                placeholder=""
                                                                                errors={formik.errors}
                                                                                touched={formik.touched}
                                                                                value={formik.values?.otherInjectionReaction ? formik.values?.otherInjectionReaction : ""}
                                                                                {...formik.getFieldProps('otherInjectionReaction')} 
                                                                                disabled={!(formik.values?.subClassificationsInjectionReaction?.indexOf("05301e0c-1330-4959-b853-ab07cf0daa35") !== -1)}
                                                                                same={history.length > 0 ? otherInjectionReaction === formik.values?.otherInjectionReaction : true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            
                                                        })
                                                    }
                                                </>
                                            )
                                        }
                                        if(record.id === 'cdb973d2-dcbb-481f-9aaa-68bd5bebfc18'){
                                            return (
                                                <>
                                                    <div>
                                                        <div className={history.length > 0 ? (((formik.values?.classifications?.indexOf(record.id) !== -1) === (classifications?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name="classifications" value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.classifications?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.values?.classifications?.indexOf("cdb973d2-dcbb-481f-9aaa-68bd5bebfc18") !== -1 && list?.aefiClassificationVaccineReaction.map((record, index) => {

                                                            return (
                                                                <div style={{marginLeft: 10}}>
                                                                    <div className="form-group">
                                                                        <Textarea
                                                                            rows="6"
                                                                            name="otherVaccineReaction"
                                                                            placeholder=""
                                                                            errors={formik.errors}
                                                                            touched={formik.touched}
                                                                            value={formik.values?.otherVaccineReaction ? formik.values?.otherVaccineReaction : ""}
                                                                            {...formik.getFieldProps('otherVaccineReaction')} 
                                                                            disabled={disabled}
                                                                            same={history.length > 0 ? otherVaccineReaction === formik.values?.otherVaccineReaction : true }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                            
                                                        })
                                                    }
                                                </>
                                            )
                                        }
                                        if(record.id === '0200a295-d3c4-4b85-b4d0-342384f2dbb3'){
                                            return (
                                                <>
                                                    <div>
                                                        <div className={history.length > 0 ? (((formik.values?.classifications?.indexOf(record.id) !== -1) === (classifications?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name="classifications" value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.classifications?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.values?.classifications?.indexOf("0200a295-d3c4-4b85-b4d0-342384f2dbb3") !== -1 && 

                                                            (
                                                                <div style={{marginLeft: 10}}>
                                                                    <div className="form-group">
                                                                        <Textarea
                                                                            rows="6"
                                                                            name="otherCoincidental"
                                                                            placeholder=""
                                                                            errors={formik.errors}
                                                                            touched={formik.touched}
                                                                            value={formik.values?.otherCoincidental ? formik.values?.otherCoincidental : ""}
                                                                            {...formik.getFieldProps('otherCoincidental')} 
                                                                            disabled={disabled}
                                                                            same={history.length > 0 ? otherCoincidental === formik.values.otherCoincidental : true }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </>
                                            )
                                        }
                                        if(record.id === '80034f4c-ae1e-4338-b409-ed2c33a86a94'){
                                            return (
                                                <>
                                                    <div>
                                                        <div className={history.length > 0 ? (((formik.values?.classifications?.indexOf(record.id) !== -1) === (classifications?.indexOf(record.id) !== -1)) === false ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name="classifications" value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.classifications?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        formik.values?.classifications?.indexOf("80034f4c-ae1e-4338-b409-ed2c33a86a94") !== -1 && 

                                                            (
                                                                <div style={{marginLeft: 10}}>
                                                                    <div className="form-group">
                                                                        <Textarea
                                                                            rows="6"
                                                                            name="otherUnknown"
                                                                            placeholder=""
                                                                            errors={formik.errors}
                                                                            touched={formik.touched}
                                                                            value={formik.values?.otherUnknown ? formik.values?.otherUnknown : ""}
                                                                            {...formik.getFieldProps('otherUnknown')} 
                                                                            disabled={disabled}
                                                                            same={history.length > 0 ? otherUnknown === formik.values.otherUnknown : true }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                    }
                                                </>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('cancel')}
                                onClick={() => this.handleCancelClick()} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default ClassificationIncomplete;
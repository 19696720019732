import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';

import { AEFIService } from '../../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../../services/CaseEntry/AEService';

import LetterUploadFile from '../LetterUploadFile';


import { withTranslation } from "react-i18next";

class QualityInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            caseNumberList : []
        };
    }

    componentDidMount() {
        if(this.props.formType === undefined){
            Promise.all([AEFIService.Read("", 1, 0, "", "", "", "", "", "", "", "", "", "", "", "", true), AEService.Read("", 1, 0, "", "", "", "", "", "", "", "", "", "", "", "", true)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }else{
            Promise.all([AEFIService.Read("", 1, 0, ""), AEService.Read("", 1, 0, "",)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }
        
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.formik.values?.caseNumber !== this.props.formik.values?.caseNumber || prevProps.formik.values?.caseEntry !== this.props.formik.values?.caseEntry || prevProps.formik.values?.id !== this.props.formik.values?.id || prevState.caseNumberList !== this.state.caseNumberList){
            if(this.props.formik.values.caseNumber !== "" && this.state.caseNumberList){
                
                
            }
        }
    }

    render() {

        const { formik, t, disabled } = this.props;

        return (
            <div className="tab-pane tab-pane-fixed" id="quality-information">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">BPR Review</label>
                            <Textarea
                                rows="6"
                                name="bprReview"
                                type="text"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps("bprReview")}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <LetterUploadFile 
                            formik = {formik}
                            folderName = "bpr_review"
                            param="bprReviewFile"
                            value={formik?.values?.bprReviewFile}
                            disabled={disabled}
                            t={t}
                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Summary Batch Review</label>
                            <Textarea
                                rows="6"
                                name="summaryBatchReview"
                                type="text"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps("summaryBatchReview")}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <LetterUploadFile 
                            formik = {formik}
                            folderName = "summary_batch_review"
                            param="summaryBatchReviewFile"
                            value={formik?.values?.summaryBatchReviewFile}
                            disabled={disabled}
                            t={t}
                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Full Investigation Review</label>
                            <Textarea
                                rows="6"
                                name="investigationReview"
                                type="text"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps("investigationReview")}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <LetterUploadFile 
                            formik = {formik}
                            folderName = "investigation_review"
                            param="investigationReviewFile"
                            value={formik?.values?.investigationReviewFile}
                            disabled={disabled}
                            t={t}
                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('leftover_sample')}</label>
                            <Textarea
                                rows="6"
                                name="deathSamplesReview"
                                type="text"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps("deathSamplesReview")}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <LetterUploadFile 
                            formik = {formik}
                            folderName = "date_sample_review"
                            param="deathSamplesReviewFile"
                            value={formik?.values?.deathSamplesReviewFile}
                            disabled={disabled}
                            t={t}
                        />
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('distribution_data')}</label>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('batch_number')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formik.values.qualityProduct?.map((record, index) =>
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>
                                                {record?.vaccineNavigation ? record?.vaccineNavigation?.name : record?.drugsNavigation?.name}
                                            </td>
                                            <td>
                                                {record?.batch ?? "-"}
                                            </td>
                                        </tr>
                                    )}
                                    {
                                        (formik.values?.qualityProduct === undefined || formik.values?.qualityProduct === null || formik.values?.qualityProduct?.length === 0) && (
                                            <tr>
                                                <td colSpan="4" style={{textAlign:"center"}}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <LetterUploadFile 
                            formik = {formik}
                            folderName = "quality_product"
                            param="qualityProductFile"
                            value={formik?.values?.qualityProductFile}
                            disabled={disabled}
                            t={t}
                        />
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(QualityInformation);
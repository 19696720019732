import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import { authenticationService } from "../../services/AuthService";
import { AEFIService } from "../../services/CaseEntry/AEFIService";
import { AEService } from "../../services/CaseEntry/AEService";
import { DropdownService } from "../../services/Master/AllDropdownService";
import { HistoryService } from "../../services/HistoryService";
import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { RejectModal } from "../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../components/Utils/Modal/ConfirmationModal";

import * as Utils from "../../helpers/Utils";
import { Role, SeriousnessLevel, Status } from "../../helpers/Constants";

import Button from "../../components/Utils/Button/Button";
import Tab from "../../components/Utils/Tab/Tab";
import { EmailModal } from './EmailModal';
import { Loader } from "../../components/Utils/Loader/Loader";

import ReporterInformation from "./TabForm/ReporterInformation";
import PatientInformation from "./TabForm/PatientInformation";
import Manifesto from "./TabForm/Manifesto";
import Symptomps from "./TabForm/Symptomps";
import ManifestationResult from "./TabForm/ManifestationResult";
import Immunization from "./TabForm/Immunization";

import { toastSuccess, toastError } from "../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";
import ConfirmationSubmitModal from "./ConfirmationSubmitModal";

class DetailCaseEntry extends Component {
  constructor(props) {
    super(props);

    const { id, typecase } = this.props?.match?.params;
    const type = typecase?.split("-");

    this.state = {
      id: id,
      typecase: type[type?.length - 1] === "aefi" ? "KIPI" : "KTD",
      formType: type[0] === "follow" ? "follow-up" : "edit",
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      nextTab: "patient-information",
      currentTab: "reporting-information",
      saveShow: false,
      drugs: [],
      drugsConcomitantly: [],
      vaccines: [],
      symptomps: [],
      allergics: [],
      diagnosis: [],
      versionOptions: [],
      isLastVersion: true,
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      mailModal: false,
      comments: [],
      historys: [],
      historyForm: [],
      formValues: {},
      reporterDisabled: true,
      patientDisabled: true,
      immunDisabled: false,
      manifestoDisabled: false,
      symptompsDisabled: false,
      manifestationResultDisabled: false,
      loading: false,
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.getCaseEntryData(id);
    this.historyList(id, this.state.typecase);
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  getCaseEntryData(id) {
    this.handleLoading(true);

    DropdownService.MasterList([
      "aeCategory",
      "aefiCategory",
      "source",
      "department",
      "institution",
      "soc",
      "pt",
      "productsErp",
      "childbearing",
      "vaccine",
      "vaccinator",
      "drugs",
      "symptomps",
      "allergic",
      "ctPhase",
      "ctProductType",
      "immunizationRoute",
      "immunizationRisk",
      "seriousness",
      "expectedness",
      "outcome",
      "seriousnessReason",
      "patientType",
      "reporterSource"
    ]).then((result) => {
      this.setState({ list: result.data });
    });

    DropdownService.CountryList().then((result) => {
      this.setState({ countryList: result.data });
    });

    if (this.state.typecase === "KIPI") {
      AEFIService.PrepareForm(id).then(
        (result) => {
          this.handleLoading(false);
          if (result.data) {
            this.setState({
              data: result.data,
            });
            // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
            // Posiblity conflic with initialValues
            this.setState({
              vaccines: result.data?.aefiVaccine?.length
                ? result.data.aefiVaccine
                : [{ isNew: true }],
              symptomps: result.data?.aefiSymptomps?.length
                ? result.data.aefiSymptomps
                : [{ isNew: true }],
              allergics: result.data?.aefiAllergic?.length
                ? result.data.aefiAllergic
                : [{ isNew: true }],
              diagnosis: result.data?.aefiDiagnosis?.length
                ? result.data.aefiDiagnosis
                : [{ isNew: true }],
              historyForm: result.data.historyJson
                ? result.data.historyJson
                : [],
            });

            this.provinceList("patient", result.data.patientCountry);
            this.cityList("patient", result.data.patientProvince);

            this.provinceList("institution", result.data.institutionCountry);
            this.cityList("institution", result.data.institutionProvince);

            var versionOptions = result.data.trailAefiCaseEntry
              ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
              .map((trail) => {
                return {
                  value: trail.followUp,
                  label:
                    trail.followUp === 0
                      ? "Inisial"
                      : "Follow Up " + trail.followUp,
                };
              });
            versionOptions.push({
              value: result.data.followUp,
              label:
                result.data.followUp === 0
                  ? "Inisial"
                  : "Follow Up " + result.data.followUp,
            });

            if (versionOptions) {
              this.setState({
                versionOptions: versionOptions,
                selectedVersion: versionOptions[versionOptions.length - 1],
                lastVersion: versionOptions[versionOptions.length - 1],
              });
            }

            let beforeVersion =
              this.state.selectedVersion?.value === 0
                ? 0
                : this.state.selectedVersion?.value - 1;

            AEFIService.PrepareForm(id, beforeVersion).then((result) => {
              if (result.data) {
                this.setState({
                  historyForm: result.data,
                });
              }
            });
          } else {
            this.props.history.push(`/case-entry`, {
              tab: this.state.typecase,
              page: this.props?.location?.state?.page,
            });
          }
        },
        () => {
          this.handleLoading(false);
          this.props.history.push(`/case-entry`, {
            tab: this.state.typecase,
            page: this.props?.location?.state?.page,
          });
        }
      );
    }

    if (this.state.typecase === "KTD") {
      AEService.PrepareForm(id).then(
        (result) => {
          this.handleLoading(false);
          if (result.data) {
            this.setState({
              data: result.data,
            });
            // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
            // Posiblity conflic with initialValues
            this.setState({
              drugs: result.data.aeDrugs.length
                ? result.data.aeDrugs
                : [{ isNew: true }],
              drugsConcomitantly: result.data.aeDrugsConcomitantly.length
                ? result.data.aeDrugsConcomitantly
                : [{ isNew: true }],
              diagnosis: result.data?.aeDiagnosis?.length
                ? result.data.aeDiagnosis
                : [{ isNew: true }],
              historyForm: result.data.historyJson
                ? result.data.historyJson
                : [],
            });

            this.provinceList("patient", result.data.patientCountry);
            this.cityList("patient", result.data.patientProvince);

            var versionOptions = result.data.trailAeCaseEntry
              ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
              .map((trail) => {
                return {
                  value: trail.followUp,
                  label:
                    trail.followUp === 0
                      ? "Inisial"
                      : "Follow Up " + trail.followUp,
                };
              });
            versionOptions.push({
              value: result.data.followUp,
              label:
                result.data.followUp === 0
                  ? "Inisial"
                  : "Follow Up " + result.data.followUp,
            });

            if (versionOptions) {
              this.setState({
                versionOptions: versionOptions,
                selectedVersion: versionOptions[versionOptions.length - 1],
                beforeVersion:
                  versionOptions[versionOptions.length] === 0
                    ? versionOptions[versionOptions.length]
                    : versionOptions[versionOptions.length - 2],
                lastVersion: versionOptions[versionOptions.length - 1],
                isLastVersion: true,
              });
            }

            let beforeVersion =
              this.state.selectedVersion?.value === 0
                ? 0
                : this.state.selectedVersion?.value - 1;

            AEService.PrepareForm(id, beforeVersion).then((result) => {
              if (result.data) {
                this.setState({
                  historyForm: result.data,
                });
              }
            });
          } else {
            this.props.history.push(`/case-entry`, {
              tab: this.state.typecase,
              page: this.props?.location?.state?.page,
            });
          }
        },
        () => {
          this.handleLoading(false);
          this.props.history.push(`/case-entry`, {
            tab: this.state.typecase,
            page: this.props?.location?.state?.page,
          });
        }
      );
    }
  }

  handleSuccessSubmission() {
    this.props.history.push({
      pathname: `/case-entry`,
      state: { tab: this.state.typecase },
    });
  }

  handleCaseNumberGenerated(caseNumber) {
    this.setState(
      { caseNumber: caseNumber },
      this.setState({ caseNumberGeneratedModalShow: true })
    );
  }

  handleVersionChange(item) {
    this.handleLoading(true);

    this.setState(
      {
        selectedVersion: item,
        beforeVersion: item.value === 0 ? 0 : item.value - 1,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        if (this.state.typecase === "KIPI") {
          AEFIService.PrepareForm(
            this.state.id,
            this.state.selectedVersion?.value
          ).then(
            (result) => {
              this.handleLoading(false);
              if (result.data) {
                if (this.state.isLastVersion) {
                  this.setState({
                    data: result.data,
                  });
                  // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                  // Posiblity conflic with initialValues
                  this.setState({
                    vaccines: result.data.aefiVaccine.length
                      ? result.data.aefiVaccine
                      : [{ isNew: true }],
                    symptomps: result.data.aefiSymptomps.length
                      ? result.data.aefiSymptomps
                      : [{ isNew: true }],
                    allergics: result.data.aefiAllergic.length
                      ? result.data.aefiAllergic
                      : [{ isNew: true }],
                    diagnosis: result.data.aefiDiagnosis.length
                      ? result.data.aefiDiagnosis
                      : [{ isNew: true }],
                    historyForm: result.data.historyJson
                      ? result.data.historyJson
                      : [],
                  });
                } else {
                  this.setState({
                    data: result.data,
                  });
                  // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                  // Posiblity conflic with initialValues
                  this.setState({
                    vaccines: result.data.trailAefiVaccine.length
                      ? result.data.trailAefiVaccine
                      : [{ isNew: true }],
                    symptomps: result.data.trailAefiSymptomps.length
                      ? result.data.trailAefiSymptomps
                      : [{ isNew: true }],
                    allergics: result.data.trailAefiAllergic.length
                      ? result.data.trailAefiAllergic
                      : [{ isNew: true }],
                    diagnosis: result.data.trailAefiDiagnosis.length
                      ? result.data.trailAefiDiagnosis
                      : [{ isNew: true }],
                    historyForm: result.data.historyJson
                      ? result.data.historyJson
                      : [],
                  });
                }

                this.provinceList("patient", result.data.patientCountry);
                this.cityList("patient", result.data.patientProvince);

                this.provinceList(
                  "institution",
                  result.data.institutionCountry
                );
                this.cityList("institution", result.data.institutionProvince);

                AEFIService.PrepareForm(
                  this.state.id,
                  this.state.beforeVersion
                ).then((result) => {
                  if (result.data) {
                    this.setState({
                      historyForm: result.data,
                    });
                  }
                });
              } else {
                this.props.history.push({
                  pathname: `/case-entry`,
                  state: { tab: this.state.typecase },
                });
              }
            },
            (error) => {
              this.handleLoading(false);
              this.props.history.push({
                pathname: `/case-entry`,
                state: { tab: this.state.typecase },
              });
            }
          );
        }

        if (this.state.typecase === "KTD") {
          AEService.PrepareForm(
            this.state.id,
            this.state.selectedVersion?.value
          ).then(
            (result) => {
              this.handleLoading(false);
              if (result.data) {
                if (this.state.isLastVersion) {
                  this.setState({
                    data: result.data,
                  });
                  // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                  // Posiblity conflic with initialValues
                  this.setState({
                    drugs: result.data.aeDrugs.length
                      ? result.data.aeDrugs
                      : [{ isNew: true }],
                    drugsConcomitantly: result.data.aeDrugsConcomitantly.length
                      ? result.data.aeDrugsConcomitantly
                      : [{ isNew: true }],
                    diagnosis: result.data.aeDiagnosis.length
                      ? result.data.aeDiagnosis
                      : [{ isNew: true }],
                    historyForm: result.data.historyJson
                      ? result.data.historyJson
                      : [],
                  });
                } else {
                  this.setState({
                    data: result.data,
                  });
                  // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                  // Posiblity conflic with initialValues
                  this.setState({
                    drugs: result.data.trailAeDrugs.length
                      ? result.data.trailAeDrugs
                      : [{ isNew: true }],
                    drugsConcomitantly: result.data.trailAeDrugsConcomitantly
                      .length
                      ? result.data.trailAeDrugsConcomitantly
                      : [{ isNew: true }],
                    diagnosis: result.data.trailAeDiagnosis.length
                      ? result.data.trailAeDiagnosis
                      : [{ isNew: true }],
                    historyForm: result.data.historyJson
                      ? result.data.historyJson
                      : [],
                  });
                }

                this.provinceList("patient", result.data.patientCountry);
                this.cityList("patient", result.data.patientProvince);

                AEService.PrepareForm(
                  this.state.id,
                  this.state.beforeVersion
                ).then((result) => {
                  if (result.data) {
                    this.setState({
                      historyForm: result.data,
                    });
                  }
                });
              } else {
                this.props.history.push({
                  pathname: `/case-entry`,
                  state: { tab: this.state.typecase },
                });
              }
            },
            (error) => {
              this.handleLoading(false);
              this.props.history.push({
                pathname: `/case-entry`,
                state: { tab: this.state.typecase },
              });
            }
          );
        }
      }
    );
  }

  provinceList(field, country) {
    if (country) {
      DropdownService.ProvinceList(country).then((result) => {
        if (field === "patient") {
          this.setState({ patientProvinceList: result.data });
        }
        if (field === "institution") {
          this.setState({ institutionProvinceList: result.data });
        }
      });
    }
  }

  cityList(field, province) {
    if (province) {
      DropdownService.CityList(province).then((result) => {
        if (field === "patient") {
          this.setState({ patientCityList: result.data });
        }
        if (field === "institution") {
          this.setState({ institutionCityList: result.data });
        }
      });
    }
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#reporting-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "reporting-information",
          backTab: "",
          nextTab: "patient-information",
        });
        break;
      case "#patient-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "patient-information",
          backTab: "reporting-information",
          nextTab:
            this.state.typecase === "KIPI" ? "immunization" : "manifesto",
        });
        break;
      case "#manifesto":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "manifesto",
          backTab: "patient-information",
          nextTab: "manifestation-result",
          manifestoDisabled: false,
        });
        break;
      case "#immunization":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "immunization",
          backTab: "patient-information",
          nextTab: "symptomps",
          immunDisabled: false,
        });
        break;
      case "#symptomps":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "symptomps",
          backTab: "immunization",
          nextTab: "manifestation-result",
          symptompsDisabled: false,
        });
        break;
      case "#manifestation-result":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: false,
          saveShow: true,
          currentTab: "manifestation-result",
          backTab: this.state.typecase === "KIPI" ? "symptomps" : "manifesto",
          nextTab: "",
          manifestationResultDisabled: false,
        });
        break;
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleReject(role) {
    if (role === Role.Unit) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
    if (role === Role.Manager) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
  }

  handleAccept(role) {
    if (role === Role.Unit) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
    if (role === Role.Manager) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
  }

  historyList(id, type) {
    HistoryService.Read(id, type).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  handleDisabledNext(tab, status) {
    switch (tab) {
      case "#reporting-information":
        this.setState({
          reporterDisabled: status,
        });
        break;
      case "#patient-information":
        this.setState({
          patientDisabled: this.state.reporterDisabled ? true : status,
        });
        break;
      case "#manifesto":
        this.setState({
          manifestoDisabled: this.state.patientDisabled ? true : status,
        });
        break;
      case "#immunization":
        this.setState({
          immunDisabled: this.state.patientDisabled ? true : status,
        });
        break;
      case "#symptomps":
        this.setState({
          symptompsDisabled: this.state.immunDisabled ? true : status,
        });
        break;
      case "#manifestation-result":
        this.setState({
          manifestationResultDisabled:
            this.state.typecase === "KIPI"
              ? this.state.symptompsDisabled
                ? true
                : status
              : this.state.manifestoDisabled
              ? true
              : status,
        });
        break;
    }
  }

  moveBack() {
    this.props.history.push({
      pathname: `/case-entry`,
      state: { tab: this.state.typecase },
    });
  }

  labellingButtonApprove = (currentUser, seriousnessNavigation) => {
    if (seriousnessNavigation === SeriousnessLevel.NonSerius) {
      if (currentUser?.role === Role.Unit) {
        return "Approve"
      } else if (currentUser.role === Role.Divisi) {
        return "Approve"
      }
      return "Forward"
    } else {
      if (currentUser?.role === Role.Unit) {
        return "Forward"
      }
      return "Approve"
    }
  }

  labellingRejectModal = (currentUser, seriousnessNavigation) => {
    if (seriousnessNavigation === SeriousnessLevel.NonSerius) {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_reject_success_unit_non_serious")
      } else if (currentUser.role === Role.Divisi) {
        return this.props.t("confirmation_reject_success_non_unit")
      }
      return this.props.t("confirmation_reject_success_manager")
    } else {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_reject_success_unit")
      }
      return this.props.t("confirmation_reject_success_non_unit")
    }
  }

  labellingForwardConfirmation = (currentUser, seriousnessNavigation) => {
    if (seriousnessNavigation === SeriousnessLevel.NonSerius) {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_unit_non_serious")
      } else if (currentUser.role === Role.Divisi) {
        return this.props.t("confirmation_non_unit")
      }
      return this.props.t("confirmation_manager")
    } else {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_unit")
      }
      return this.props.t("confirmation_non_unit")
    }
  }

  labellingForwarded = (currentUser, seriousnessNavigation) => {
    if (seriousnessNavigation === SeriousnessLevel.NonSerius) {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_success_unit_serious")
      } else if (currentUser.role === Role.Divisi) {
        return this.props.t("confirmation_success_non_unit")
      }
      return this.props.t("confirmation_success_manager")
    } else {
      if (currentUser?.role === Role.Unit) {
        return this.props.t("confirmation_success_unit")
      }
      return this.props.t("confirmation_success_non_unit")
    }
  }

  render() {
    const { t } = this.props;

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };
    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const caseEntry = this.state.data;

    const caseCategoryList =
      this.state.typecase === "KIPI"
        ? this.state.list?.aefiCategoryList
        : this.state.list?.aeCategoryList;
    const formType = this.state.formType;

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (caseEntry?.status === Status.Pending ||
        caseEntry?.status === Status.Draft ||
        // caseEntry?.status === Status.WaitingApproval ||
        caseEntry?.status === Status.NotOk ||
        caseEntry?.status === Status.NotApproved ||
        (caseEntry?.status === Status.Approved &&
          formType === "follow-up" &&
          this.state.isLastVersion) ||
        (caseEntry?.status === Status.Reviewed &&
          formType === "follow-up" &&
          this.state.isLastVersion) ||
        caseEntry?.status.includes(Status.Bridging) ||
        this.state.type);
    const canManagerEdit =
      currentUser.role === Role.Manager &&
      (caseEntry?.seriousnessNavigation?.name === SeriousnessLevel.NonSerius ? 
        (caseEntry?.status === Status.WaitingApproval ||
          caseEntry?.status === Status.NotApproved)
        :
        false
      );
    const canUnitEdit =
      currentUser.role === Role.Unit && 
      (caseEntry?.seriousnessNavigation?.name === SeriousnessLevel.NonSerius ? 
        (caseEntry?.status === Status.WaitingApproval ||
          caseEntry?.status === Status.Reviewed)
        : 
        (caseEntry?.status === Status.WaitingApproval ||
          caseEntry?.status === Status.NotApproved)
      );
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (caseEntry?.seriousnessNavigation?.name === SeriousnessLevel.NonSerius ? 
        false
        :
        (caseEntry?.status === Status.WaitingApproval ||
          caseEntry?.status === Status.Reviewed)
      );

    const sourceList = this.state.list?.sourceList;
    const reporterList = this.state.list?.reporterSourceList;
    const socList = this.state.list?.socList;
    const ptList = this.state.list?.ptList;
    const erpList = this.state.list?.productsErpList;
    const ctPhaseList = this.state.list?.ctPhaseList;
    const ctProductTypeList = this.state.list?.ctProductTypeList;
    const outcomeList = this.state.list?.outcomeList;
    const allergicList = this.state.list?.allergicList;
    const symptompsList = this.state.list?.symptompsList;
    const departmentList = this.state.list?.departmentList;
    const seriousnessList = this.state.list?.seriousnessList;
    const patientTypeList = this.state.list?.patientTypeList;
    const expectednessList = this.state.list?.expectednessList;
    const childbearingList = this.state.list?.childbearingList;
    const seriousnessReasonList = this.state.list?.seriousnessReasonList;
    const immunizationRouteList = this.state.list?.immunizationRouteList;
    const countryList = this.state.countryList;
    const patientProvinceList = this.state.patientProvinceList;
    const patientCityList = this.state.patientCityList;

    let drugsList = [];
    let vaccineList = [];
    let vaccinatorList = [];
    let institutionList = [];
    let immunizationRiskList = [];
    let institutionProvinceList = [];
    let institutionCityList = [];
    let historyForm = this.state.historyForm ? this.state.historyForm : [];

    if (this.state.typecase === "KIPI") {
      vaccineList = this.state.list?.vaccineList;
      vaccinatorList = this.state.list?.vaccinatorList;
      institutionList = this.state.list?.institutionList;
      immunizationRiskList = this.state.list?.immunizationRiskList;
      institutionProvinceList = this.state.institutionProvinceList;
      institutionCityList = this.state.institutionCityList;
    }
    if (this.state.typecase === "KTD") {
      drugsList = this.state.list?.drugsList;
    }

    const initial =
      this.state.typecase === "KIPI"
        ? {
            // 1. Repoting Information
            caseCategory: caseEntry?.caseCategory,
            caseNumber: caseEntry?.caseNumber,
            ctPhase: caseEntry?.ctPhase,
            ctProductType: caseEntry?.ctProductType,
            manufactureCaseNumber: caseEntry?.manufactureCaseNumber,
            reporterName: caseEntry?.reporterName,
            datasource: caseEntry?.datasource,
            solicitCategory: caseEntry?.solicitCategory,
            solicitCategoryName: caseEntry?.solicitCategoryNavigation?.name,
            reportedDate: Utils.getDate(caseEntry?.reportedDate),
            reportedTime: Utils.getTime(caseEntry?.reportedDate),
            reporterAddress: caseEntry?.reporterAddress,
            // 2 Patient Information
            patientName: caseEntry?.patientName,
            patientGender: caseEntry?.patientGender,
            parentName: caseEntry?.parentName,
            patientAddress: caseEntry?.patientAddress,
            patientCountry: caseEntry?.patientCountry,
            patientProvince: caseEntry?.patientProvince,
            patientCity: caseEntry?.patientCity,
            patientPhone: caseEntry?.patientPhone,
            patientAgeYear: caseEntry?.patientAgeYear,
            patientAgeMonth: caseEntry?.patientAgeMonth,
            patientAgeDay: caseEntry?.patientAgeDay,
            patientChildbearing: caseEntry?.patientChildbearing,
            lastImmunizationDate: Utils.getDate(
              caseEntry?.lastImmunizationDate
            ),
            historyAllergicOption:
              caseEntry?.historyAllergic === null ||
              caseEntry?.historyAllergic === undefined
                ? "Unknown"
                : caseEntry?.historyAllergic !== "#?#"
                ? "Yes"
                : "No",
            historyAllergic:
              caseEntry?.historyAllergic === null ||
              caseEntry?.historyAllergic === undefined
                ? ""
                : caseEntry?.historyAllergic !== "#?#"
                ? caseEntry?.historyAllergic
                : "",
            historyDiseaseOption:
              caseEntry?.historyDisease === null ||
              caseEntry?.historyDisease === undefined
                ? "Unknown"
                : caseEntry?.historyDisease !== "#?#"
                ? "Not Healthy"
                : "Healthy",
            historyDisease:
              caseEntry?.historyDisease === null ||
              caseEntry?.historyDisease === undefined
                ? ""
                : caseEntry?.historyDisease !== "#?#"
                ? caseEntry?.historyDisease
                : "",
            historyRiskOption: caseEntry?.historyRisk ? "Yes" : "No",
            historyRisk: caseEntry?.historyRisk,
            historyOther: caseEntry?.historyOther,
            // 3. Immunization
            onsetDate: Utils.getDate(caseEntry?.onsetDate),
            onsetTime: Utils.getTime(caseEntry?.onsetDate),
            vaccinator: caseEntry?.vaccinator,
            vaccinatorName: caseEntry?.vaccinatorName,
            institution: caseEntry?.institution,
            institutionName: caseEntry?.institutionName,
            institutionAddress: caseEntry?.institutionAddress,
            institutionCountry: caseEntry?.institutionCountry,
            institutionProvince: caseEntry?.institutionProvince,
            institutionCity: caseEntry?.institutionCity,
            vaccineStorage: caseEntry?.vaccineStorage,
            vaccineShakeBefore:
              caseEntry?.vaccineShakeBefore !== null &&
              caseEntry?.vaccineShakeBefore !== undefined
                ? caseEntry?.vaccineShakeBefore
                  ? "true"
                  : "false"
                : "Unknown",
            vaccineFirstMultiDose:
              caseEntry?.vaccineFirstMultiDose !== null &&
              caseEntry?.vaccineFirstMultiDose !== undefined
                ? caseEntry?.vaccineFirstMultiDose
                  ? "true"
                  : "false"
                : "Unknown",
            vaccineInjectionDevice:
              caseEntry?.vaccineInjectionDevice !== null &&
              caseEntry?.vaccineInjectionDevice !== undefined
                ? caseEntry?.vaccineInjectionDevice
                  ? "true"
                  : "false"
                : "Unknown",
            sameVialOption:
              caseEntry?.sameVial !== null && caseEntry?.sameVial !== undefined
                ? caseEntry?.sameVial < 0
                  ? "No"
                  : "Yes"
                : "Unknown",
            sameVial: caseEntry?.sameVial <= 0 ? "" : caseEntry?.sameVial,
            sameAdverseOption:
              caseEntry?.sameAdverse !== null &&
              caseEntry?.sameAdverse !== undefined
                ? caseEntry?.sameAdverse < 0
                  ? "No"
                  : "Yes"
                : "Unknown",
            sameAdverse:
              caseEntry?.sameAdverse <= 0 ? "" : caseEntry?.sameAdverse,
            sameSymptomps:
              caseEntry?.sameSymptomps !== null &&
              caseEntry?.sameSymptomps !== undefined
                ? caseEntry?.sameSymptomps
                  ? "true"
                  : "false"
                : "Unknown",
            diluentMedicineOption:
              caseEntry?.diluentMedicine !== undefined &&
              caseEntry?.diluentMedicine !== null
                ? caseEntry?.diluentMedicine !== "#?#"
                  ? "Yes"
                  : "No"
                : "Unknown",
            diluentMedicine:
              caseEntry?.diluentMedicine !== undefined &&
              caseEntry?.diluentMedicine != null
                ? caseEntry?.diluentMedicine !== "#?#"
                  ? caseEntry?.diluentMedicine
                  : ""
                : "",
            // 5. Manifestation Result
            resultChronology: caseEntry?.resultChronology,
            resultPhysic: caseEntry?.resultPhysic,
            resultLaboratory: caseEntry?.resultLaboratory,
            resultTreatment: caseEntry?.resultTreatment,
            resultConclusion: caseEntry?.resultConclusion,
            treatmentDate: Utils.getDate(caseEntry?.treatmentDate),
            adverseDate: Utils.getDate(caseEntry?.adverseDate),
            adverseTime: Utils.getTime(caseEntry?.adverseDate),
            adverseName: caseEntry?.adverseName,
            organizationCode: caseEntry?.organizationCode,
            adversePvDate: Utils.getDate(caseEntry?.adversePvDate),
            adversePvTime: Utils.getTime(caseEntry?.adversePvDate),
            adversePvName: caseEntry?.adversePvName,
            seriousness: caseEntry?.seriousness,
            expectedness: caseEntry?.expectedness,
            outcome: caseEntry?.outcome,
            deathDate: Utils.getDate(caseEntry?.deathDate),
            additionalRequest: caseEntry?.additionalRequest,
            additionalRequestMethod: caseEntry?.additionalRequestMethod,
            distributionDataRequest: caseEntry?.distributionDataRequest || false,
            extCaseNumber: caseEntry?.extCaseNumber,
            seriousnessReason: caseEntry?.seriousnessReason,
            patientType: caseEntry?.patientType,
            qualityReviewNeeded: caseEntry?.qualityReviewNeeded,
            disabledQRNeeded: caseEntry?.qualityReviewNeeded ? true : false,
            adrOnlineNeeded: caseEntry?.adrOnlineNeeded,
            disabledEmeso: caseEntry?.adrOnlineNeeded ? true : false,
            isSubmitted: true
          }
        : {
            // 1. Repoting Information
            caseCategory: caseEntry?.caseCategory,
            caseNumber: caseEntry?.caseNumber,
            ctPhase: caseEntry?.ctPhase,
            ctProductType: caseEntry?.ctProductType,
            manufactureCaseNumber: caseEntry?.manufactureCaseNumber,
            reporterName: caseEntry?.reporterName,
            datasource: caseEntry?.datasource,
            solicitCategory: caseEntry?.solicitCategory,
            solicitCategoryName: caseEntry?.solicitCategoryNavigation?.name,
            reportedDate: Utils.getDate(caseEntry?.reportedDate),
            reportedTime: Utils.getTime(caseEntry?.reportedDate),
            reporterAddress: caseEntry?.reporterAddress,
            // 2. Patient Information
            patientName: caseEntry?.patientName,
            patientGender: caseEntry?.patientGender,
            parentName: caseEntry?.parentName,
            patientAddress: caseEntry?.patientAddress,
            patientCountry: caseEntry?.patientCountry,
            patientProvince: caseEntry?.patientProvince,
            patientCity: caseEntry?.patientCity,
            patientPhone: caseEntry?.patientPhone,
            patientAgeYear: caseEntry?.patientAgeYear,
            patientAgeMonth: caseEntry?.patientAgeMonth,
            patientAgeDay: caseEntry?.patientAgeDay,
            patientChildbearing: caseEntry?.patientChildbearing,
            productUseDate: Utils.getDate(caseEntry?.productUseDate),
            diseaseName: caseEntry?.diseaseName,
            diseaseRenal: caseEntry?.diseaseRenal,
            diseaseLiver: caseEntry?.diseaseLiver,
            diseaseAllergy: caseEntry?.diseaseAllergy,
            diseaseOtherOption: caseEntry?.diseaseOther ? true : false,
            diseaseOther: caseEntry?.diseaseOther,
            // 3. Manifesto
            reaction: caseEntry?.reaction,
            onsetDate: Utils.getDate(caseEntry?.onsetDate),
            onsetTime: Utils.getTime(caseEntry?.onsetDate),
            reactionDied: caseEntry?.reactionDied,
            reactionHospitalization: caseEntry?.reactionHospitalization,
            reactionDisability: caseEntry?.reactionDisability,
            reactionLife: caseEntry?.reactionLife,
            reactionEvent: caseEntry?.reactionEvent,
            reactionDefect: caseEntry?.reactionDefect,
            // 4. Manifestation Result
            resultChronology: caseEntry?.resultChronology,
            resultPhysic: caseEntry?.resultPhysic,
            resultLaboratory: caseEntry?.resultLaboratory,
            resultTreatment: caseEntry?.resultTreatment,
            resultConclusion: caseEntry?.resultConclusion,
            patientChildbearing: caseEntry?.patientChildbearing,
            treatmentDate: Utils.getDate(caseEntry?.treatmentDate),
            adverseDate: Utils.getDate(caseEntry?.adverseDate),
            adverseTime: Utils.getTime(caseEntry?.adverseDate),
            adverseName: caseEntry?.adverseName,
            organizationCode: caseEntry?.organizationCode,
            adversePvDate: Utils.getDate(caseEntry?.adversePvDate),
            adversePvTime: Utils.getTime(caseEntry?.adversePvDate),
            adversePvName: caseEntry?.adversePvName,
            seriousness: caseEntry?.seriousness,
            expectedness: caseEntry?.expectedness,
            outcome: caseEntry?.outcome,
            deathDate: Utils.getDate(caseEntry?.deathDate),
            additionalRequest: caseEntry?.additionalRequest,
            additionalRequestMethod: caseEntry?.additionalRequestMethod,
            distributionDataRequest: caseEntry?.distributionDataRequest,
            patientType: caseEntry?.patientType,
            seriousnessReason: caseEntry?.seriousnessReason,
            qualityReviewNeeded: caseEntry?.qualityReviewNeeded || false,
            disabledQRNeeded: caseEntry?.qualityReviewNeeded ? true : false,
            adrOnlineNeeded: caseEntry?.adrOnlineNeeded,
            disabledEmeso: caseEntry?.adrOnlineNeeded ? true : false,
            isSubmitted: true
          };

    const validationSchemaDict =
      this.state.typecase === "KIPI"
        ? {
            "reporting-information": Yup.object().shape({
              caseCategory: Yup.string().required("Required").nullable(),
              ctPhase:
                this.formikRef.current?.values?.caseCategory?.toUpperCase() ==
                "6B2E5829-9A00-4E02-A681-F780BE13D487"
                  ? Yup.string().required("Required").nullable()
                  : "",
              ctProductType:
                this.formikRef.current?.values?.caseCategory?.toUpperCase() ==
                "6B2E5829-9A00-4E02-A681-F780BE13D487"
                  ? Yup.string().required("Required").nullable()
                  : "",
              reporterName: Yup.string().required("Required").nullable(),
            }),
            "patient-information": Yup.object().shape({
              patientName: Yup.string().required("Required").nullable(),
              patientType: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "reporting-information": Yup.object().shape({
              caseCategory: Yup.string().required("Required").nullable(),
              ctPhase:
                this.formikRef.current?.values?.caseCategory?.toUpperCase() ==
                "30D1E6DA-1F81-4B72-B666-9C4A3F562332"
                  ? Yup.string().required("Required").nullable()
                  : "",
              ctProductType:
                this.formikRef.current?.values?.caseCategory?.toUpperCase() ==
                "30D1E6DA-1F81-4B72-B666-9C4A3F562332"
                  ? Yup.string().required("Required").nullable()
                  : "",
              reporterName: Yup.string().required("Required").nullable(),
            }),
            "patient-information": Yup.object().shape({
              patientName: Yup.string().required("Required").nullable(),
              patientType: Yup.string().required("Required").nullable(),
            }),
          };

    const initialTouched =
      this.state.typecase === "KIPI"
        ? {
            caseCategory: caseEntry?.caseCategory ? false : true,
            ctPhase: caseEntry?.ctPhase ? false : true,
            ctProductType: caseEntry?.ctProductType ? false : true,
            caseNumber: caseEntry?.caseNumber ? false : true,
            reporterName: caseEntry?.reporterName ? false : true,
            patientName: caseEntry?.patientName ? false : true,
            historyAllergic: caseEntry?.historyAllergic ? false : true,
            historyDisease: caseEntry?.historyDisease ? false : true,
            sameVial: caseEntry?.sameVial ? false : true,
            sameAdverse: caseEntry?.sameAdverse ? false : true,
            diluentMedicine: caseEntry?.diluentMedicine ? false : true,
            patientType: caseEntry?.patientType ? false : true,
          }
        : {
            caseCategory: caseEntry?.caseCategory ? false : true,
            ctPhase: caseEntry?.ctPhase ? false : true,
            ctProductType: caseEntry?.ctProductType ? false : true,
            caseNumber: caseEntry?.caseNumber ? false : true,
            reporterName: caseEntry?.reporterName ? false : true,
            patientName: caseEntry?.patientName ? false : true,
            onsetDate: caseEntry?.onsetDate ? false : true,
            onsetTime: caseEntry?.onsetTime ? false : true,
            patientType: caseEntry?.patientType ? false : true,
          };

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}
        {/** Case number modal */}
        <ConfirmationModal
          show={this.state.caseNumberGeneratedModalShow}
          onhide={() => this.handleSuccessSubmission()}
          confirm={() => this.handleSuccessSubmission()}
          title="Case Entry"
          caseNumber={this.state.caseNumber}
        />

        <ConfirmationSubmitModal
          show={this.state.confirmSubmitModalShow}
          onHide={() => this.setState({ confirmSubmitModalShow: false })}
          confirmsubmit={() => this.formikRef.current.submitForm()}
          t={t}
        />

        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          title={t("cases_history")}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          caseEntry={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            this.labellingForwardConfirmation(currentUser, caseEntry?.seriousnessNavigation?.name)
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => {
            this.handleSubmitClick(event);
          }}
          showhide={false}
          text={
            this.labellingForwarded(currentUser, caseEntry?.seriousnessNavigation?.name)
          }
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            this.labellingRejectModal(currentUser, caseEntry?.seriousnessNavigation?.name)
          }
          menu="caseEntry"
          t={t}
        />

        {/* Mail */}
        <EmailModal
          show={this.state.mailModal}
          onhide={() => this.setState({ mailModal: false })}
          typecase={this.state.typecase}
          id={this.state.id}
          values={this.state.formValues}
          isFollowUp={this.state.formType === "follow-up" ? true : false}
          history={this.props.history}
          t={t}
        />

        <div className="nav-fixed-wrapper">
          <div className="nav-fixed w-100" style={{ paddingRight: '25vw' }}>
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  {currentUser.role !== Role.RegulasiMutuSistem && (
                    <>
                      <span
                        className="nav-icon-fitur"
                        onClick={() =>
                          this.setState({ HistoryModalShow: true })
                        }
                      >
                        <FontAwesomeIcon icon={faHistory} />
                      </span>
                      <span
                        className="nav-icon-fitur"
                        onClick={() =>
                          this.setState({ CommentModalShow: true })
                        }
                      >
                        <FontAwesomeIcon icon={faComments} />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("report_information")}
                  href="#reporting-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#reporting-information")
                  }
                  id="link-reporting-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("patient_information")}
                  href="#patient-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#patient-information")
                  }
                  id="link-patient-information"
                  disabled={this.state.reporterDisabled}
                />
                {this.state.typecase === "KIPI" && (
                  <>
                    <Tab
                      number="3"
                      showNumber={true}
                      title={t("immunization_implementation")}
                      href="#immunization"
                      onClick={(event) =>
                        this.handleTabChange(event, "#immunization")
                      }
                      id="link-immunization"
                      disabled={this.state.patientDisabled}
                    />
                    <Tab
                      number="4"
                      showNumber={true}
                      title={t("clinical_symptoms")}
                      href="#symptomps"
                      onClick={(event) =>
                        this.handleTabChange(event, "#symptomps")
                      }
                      id="link-symptomps"
                      disabled={this.state.immunDisabled}
                    />
                  </>
                )}
                {this.state.typecase === "KTD" && (
                  <>
                    <Tab
                      number="3"
                      showNumber={true}
                      title={t("ae_adr")}
                      href="#manifesto"
                      onClick={(event) =>
                        this.handleTabChange(event, "#manifesto")
                      }
                      id="link-manifesto"
                      disabled={this.state.patientDisabled}
                    />
                  </>
                )}

                <Tab
                  number={this.state.typecase === "KTD" ? "4" : "5"}
                  showNumber={true}
                  title={t("manifestation_result")}
                  href="#manifestation-result"
                  onClick={(event) =>
                    this.handleTabChange(event, "#manifestation-result")
                  }
                  id="link-manifestation-result"
                  disabled={
                    this.state.typecase === "KIPI"
                      ? this.state.symptompsDisabled
                      : this.state.manifestoDisabled
                  }
                />
              </ul>
            </div>
          </div>
        </div>

        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          initialTouched={initialTouched}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();
            this.handleLoading(true);

            // Merge date & time
            values.reportedDate = Utils.getDateTime(
              values.reportedDate,
              values.reportedTime
            );
            values.adverseDate = Utils.getDateTime(
              values.adverseDate,
              values.adverseTime
            );
            values.adversePvDate = Utils.getDateTime(
              values.adversePvDate,
              values.adversePvTime
            );
            values.onsetDate = Utils.getDateTime(
              values.onsetDate,
              values.onsetTime
            );

            // Convert value
            values.historyAllergic =
              values.historyAllergicOption === "Unknown"
                ? null
                : values.historyAllergicOption === "No"
                ? "#?#"
                : values.historyAllergic
                ? values.historyAllergic
                : "";
            values.historyDisease =
              values.historyDiseaseOption === "Unknown"
                ? null
                : values.historyDiseaseOption === "Healthy"
                ? "#?#"
                : values.historyDisease
                ? values.historyDisease
                : "";
            values.diluentMedicine =
              values.diluentMedicineOption === "Unknown"
                ? null
                : values.diluentMedicineOption === "No"
                ? "#?#"
                : values.diluentMedicine
                ? values.diluentMedicine
                : "";

            values.sameSymptomps =
              values.sameSymptomps === "Unknown"
                ? null
                : values.sameSymptomps === "true"
                ? true
                : false;
            values.vaccineShakeBefore =
              values.vaccineShakeBefore === "Unknown"
                ? null
                : values.vaccineShakeBefore === "true"
                ? true
                : false;
            values.vaccineFirstMultiDose =
              values.vaccineFirstMultiDose === "Unknown"
                ? null
                : values.vaccineFirstMultiDose === "true"
                ? true
                : false;
            values.vaccineInjectionDevice =
              values.vaccineInjectionDevice === "Unknown"
                ? null
                : values.vaccineInjectionDevice === "true"
                ? true
                : false;

            values.sameVial =
              values.sameVialOption === "Unknown"
                ? null
                : values.sameVialOption === "No"
                ? -1
                : values.sameVial
                ? values.sameVial
                : 0;
            values.sameAdverse =
              values.sameAdverseOption === "Unknown"
                ? null
                : values.sameAdverseOption === "No"
                ? -1
                : values.sameAdverse
                ? values.sameAdverse
                : 0;

            // Check follow up
            values.isFollowUp = formType === "follow-up" ? true : "";

            var role = currentUser.role;

            if (this.state.typecase === "KIPI") {
              values.aefiDiagnosis = values.diagnosis;

              AEFIService.SubmitForm(this.state.id, values).then(
                (result) => {
                  this.handleLoading(false);

                  /** Show case number modal if caseNumber before is null and then filled */
                  if (
                    values?.caseNumber === null &&
                    result?.data?.caseNumber !== null
                  ) {
                    this.handleCaseNumberGenerated(result?.data?.caseNumber);
                  } else {
                    this.handleSuccessSubmission();
                  }

                  toastSuccess(t("success_edit_message"));
                },
                (error) => {
                  this.handleLoading(false);
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }

            if (this.state.typecase === "KTD") {
              values.aeDiagnosis = values.diagnosis;
              
              AEService.SubmitForm(this.state.id, values).then(
                (result) => {
                  this.handleLoading(false);

                  /** Show case number modal if caseNumber before is null and then filled */
                  if (
                    values?.caseNumber === null &&
                    result?.data?.caseNumber !== null
                  ) {
                    this.handleCaseNumberGenerated(result?.data?.caseNumber);
                  } else {
                    this.handleSuccessSubmission();
                  }

                  toastSuccess(t("success_edit_message"));
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                  this.handleLoading(false);
                }
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content tab-pane-fixed">
                {/* Reporting Information Tab */}

                <ReporterInformation
                  formik={formik}
                  page={this.props?.location?.state?.page}
                  typecase={this.state.typecase}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  caseCategoryList={caseCategoryList}
                  sourceList={sourceList}
                  reporterList={reporterList}
                  ctPhaseList={ctPhaseList}
                  ctProductTypeList={ctProductTypeList}
                  isLastVersion={this.state.isLastVersion}
                  historydata={historyForm}
                  history={this.props.history}
                  caseStatus={caseEntry?.status}
                  formType={formType}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  disabledNext={this.state.reporterDisabled}
                  currentUser={currentUser}
                />

                {/* Patient Information Tab */}

                <PatientInformation
                  formik={formik}
                  typecase={this.state.typecase}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  vaccineList={vaccineList}
                  drugsList={this.state.typecase === "KTD" ? drugsList : null}
                  childbearingList={childbearingList}
                  immunizationRiskList={immunizationRiskList}
                  countryList={countryList}
                  patientProvinceList={patientProvinceList}
                  patientCityList={patientCityList}
                  patientTypeList={patientTypeList}
                  provinceList={(field, country) =>
                    this.provinceList(field, country)
                  }
                  cityList={(field, country) => this.cityList(field, country)}
                  isLastVersion={this.state.isLastVersion}
                  historydata={historyForm}
                  caseStatus={caseEntry?.status}
                  formType={formType}
                  currentUser={currentUser}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                />

                {/* Immunization Tab */}

                {this.state.typecase === "KIPI" && (
                  <Immunization
                    formik={formik}
                    typecase={this.state.typecase}
                    handleBackClick={(event) => this.handleBackClick(event)}
                    handleNextClick={(event) => this.handleNextClick(event)}
                    isLastVersion={this.state.isLastVersion}
                    vaccines={this.state.vaccines}
                    vaccineList={vaccineList}
                    vaccinatorList={vaccinatorList}
                    institutionList={institutionList}
                    immunizationRouteList={immunizationRouteList}
                    countryList={countryList}
                    institutionProvinceList={institutionProvinceList}
                    institutionCityList={institutionCityList}
                    erpList={erpList}
                    provinceList={(field, country) =>
                      this.provinceList(field, country)
                    }
                    cityList={(field, country) => this.cityList(field, country)}
                    historydata={historyForm}
                    caseStatus={caseEntry?.status}
                    formType={formType}
                    currentUser={currentUser}
                  />
                )}

                {/* Symptomps Tab */}

                {this.state.typecase === "KIPI" && (
                  <Symptomps
                    formik={formik}
                    typecase={this.state.typecase}
                    handleBackClick={(event) => this.handleBackClick(event)}
                    handleNextClick={(event) => this.handleNextClick(event)}
                    symptomps={this.state.symptomps}
                    symptompsList={symptompsList}
                    socList={socList}
                    ptList={ptList}
                    allergics={this.state.allergics}
                    allergicList={allergicList}
                    isLastVersion={this.state.isLastVersion}
                    historydata={historyForm}
                    caseStatus={caseEntry?.status}
                    formType={formType}
                    currentUser={currentUser}
                  />
                )}

                {/* Information AE/ADR Manifesto Tab */}

                {this.state.typecase === "KTD" && (
                  <Manifesto
                    formik={formik}
                    typecase={this.state.typecase}
                    handleBackClick={(event) => this.handleBackClick(event)}
                    handleNextClick={(event) => this.handleNextClick(event)}
                    isLastVersion={this.state.isLastVersion}
                    drugs={this.state.drugs}
                    drugsConcomitantly={this.state.drugsConcomitantly}
                    drugsList={drugsList}
                    erpList={erpList}
                    immunizationRouteList={immunizationRouteList}
                    historydata={historyForm}
                    caseStatus={caseEntry?.status}
                    formType={formType}
                    currentUser={currentUser}
                  />
                )}

                {/* Manifestation Result Tab*/}
                <ManifestationResult
                  formik={formik}
                  typecase={this.state.typecase}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  isLastVersion={this.state.isLastVersion}
                  outcomeList={outcomeList}
                  expectednessList={expectednessList}
                  seriousnessList={seriousnessList}
                  departmentList={departmentList}
                  seriousnessReasonList={seriousnessReasonList}
                  socList={socList}
                  ptList={ptList}
                  diagnosis={this.state.diagnosis}
                  historydata={historyForm}
                  canStaffEdit={canStaffEdit}
                  isCheckboxDisabled={
                    caseEntry?.additionalRequest &&
                    caseEntry?.status === Status.Pending
                      ? true
                      : false
                  }
                  caseStatus={caseEntry?.status}
                  formType={formType}
                  currentUser={currentUser}
                  showMailForm={(mailPurpose) => {
                    this.setState({
                      mailModal: true,
                      formValues: {
                        ...formik.values,
                        mailPurpose: mailPurpose
                      }
                    });
                  }}
                />

                {this.state.nextTab === "" && (
                  <div className="text-right my-2">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    {/*Simpan sebagai Draft*/}
                     {canStaffEdit &&
                      (formType === "edit" && caseEntry?.status === Status.Pending) || caseEntry?.status === Status.Draft && (
                        <ToggleDisplay show={this.state.saveShow}>
                          <Button
                            onClick={()=> { 
                                formik.setFieldValue("isSubmitted", false); 
                                formik.submitForm();
                             }}
                            text={t("save_as_draft")} 
                            isSubmitting={
                              !this.state.isLastVersion || formik.isSubmitting
                            }
                          />
                        </ToggleDisplay>
                      )}
                    {/* Simpan */}
                    {canStaffEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                            type="button"
                            text={"Submit"}
                            disabled={formik.isSubmitting}
                            onClick={() => this.setState({ confirmSubmitModalShow: true })}
                        />
                      </ToggleDisplay>
                     )}
                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />
                    <input
                      name="isReview"
                      type="hidden"
                      {...formik.getFieldProps("isReview")}
                    />
                    <input
                      name="isSubmitted"
                      type="hidden"
                       {...formik.getFieldProps("isSubmitted")}
                    />
                    {/* Reject */}
                    {(canUnitEdit || canDivisiEdit || canManagerEdit) && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            if (canUnitEdit) {
                              if (caseEntry?.seriousnessNavigation?.name === SeriousnessLevel.NonSerius) {
                                formik.setFieldValue("isApprove", false);
                              } else {
                                formik.setFieldValue("isReview", false);
                              }
                            }
                            if (canDivisiEdit) {
                              formik.setFieldValue("isApprove", false);
                            }
                            if (canManagerEdit) {
                              formik.setFieldValue("isReview", false);
                            }
                            this.setState({ RejectedModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {(canUnitEdit || canDivisiEdit || canManagerEdit) && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            this.labellingButtonApprove(currentUser, caseEntry?.seriousnessNavigation?.name)
                          }
                          onClick={() => {
                            if (canUnitEdit) {
                              if (caseEntry?.seriousnessNavigation?.name === SeriousnessLevel.NonSerius) {
                                formik.setFieldValue("isApprove", true);
                              } else {
                                formik.setFieldValue("isReview", true);
                              }
                            }
                            if (canDivisiEdit) {
                              formik.setFieldValue("isApprove", true);
                            }
                            if (canManagerEdit) {
                              formik.setFieldValue("isReview", true);
                            }
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}

                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailCaseEntry);

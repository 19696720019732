import React, { Component } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import Iframe from 'react-iframe'
import * as Yup from 'yup';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import Select2Multi from '../../../components/Utils/Field/Select2Multi';
import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import EmptyState from '../../../components/Utils/EmptyState/EmptyState';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import * as Utils from '../../../helpers/Utils';
import { AEFIService } from '../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../services/CaseEntry/AEService';
import { FileService } from '../../../services/FileService';
import { ADROnlineService } from '../../../services/BPOM/ADROnlineService';

export class DetailADROnlineModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caseNumberList: [],
        };

        this.state = {};

        this.fileRef = React.createRef();
        this.formikRef = React.createRef();

        Promise.all([AEFIService.Read("", 1, 0, ""), AEService.Read("", 1, 0, "")])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data.records, ...ktd.data.records],
                });
            });
    }

    prepareForm() {
        ADROnlineService.PrepareForm(this.props.id)
            .then((result) => {

                var caseEntry = this.state.caseNumberList.find(obj => { return obj.caseNumber === result.data.caseNumber })

                var vaccine = caseEntry?.aefiVaccine?.filter((item, index) => item.vaccine != null && caseEntry.aefiVaccine.findIndex(x => x.vaccine === item.vaccine) === index)
                    .map(item => { return { value: item.vaccineNavigation.id, label: item.vaccineNavigation.name }; });
                var drugs = caseEntry?.aeDrugs?.filter((item, index) => item.drugs != null && caseEntry.aeDrugs.findIndex(x => x.drugs === item.drugs) === index)
                    .map(item => { return { value: item.drugsNavigation.id, label: item.drugsNavigation.name }; })

                this.setState({
                    data: result.data,
                    vaccine: vaccine,
                    drugs: drugs,
                });
            });
    }

    handleChange(formik, selectorFiles) {
        FileService.Upload(selectorFiles.files, 'adr_online').then((success) => {
            if (success.status === 200) {
                selectorFiles.value = null;
                formik.setFieldValue('uploadedFile', success.data.fileName);
                formik.setFieldValue('uploadedFileUrl', success.data.url);
            }
        }, (error) => {
            console.log('error', error)
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show != this.props.show && this.props.case_type == "ADROnline") {

            this.prepareForm();
        }

        if(prevState.data !== this.state.data){
            FileService.Show('adr_online', this.state.data?.uploadedFile).then((success) => {

                if(this.formikRef?.current?.setFieldValue){
                    this.formikRef.current.setFieldValue('uploadedFileUrl', `/api/v1/file/adr_online/${this.state.data?.uploadedFile}` );
                }
            
            }, (error) => {
                if(this.formikRef?.current?.setFieldValue){
                    this.formikRef.current.setFieldValue('uploadedFileUrl', null );
                }
            })
        }
    }

    handleSubmit(data){

    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    openfileDialog = () => {
        this.fileRef.current.click();
    }

    render() {
        const { ...otherProps } = this.props;

        const adrOnline = this.state.data;

        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalHeader>
                        <div className="modal-title">Detail ADR Online</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <Formik
                            innerRef={this.formikRef}
                            enableReinitialize
                            initialValues={{
                                caseEntryId: adrOnline?.adrOnline,
                                CaseEntryFollowUp: adrOnline?.CaseEntryFollowUp,
                                caseNumber: adrOnline?.caseNumber,
                                entryDate: Utils.getDate(adrOnline?.entryDate),
                                adronlineNumber: adrOnline?.adronlineNumber,
                                uploadedFile: adrOnline?.uploadedFile,
                                vaccines: adrOnline?.adrOnlineVaccine.map(item => { return item.vaccine }),
                                drugs: adrOnline?.adrOnlineDrugs.map(item => { return item.drugs }),
                            }}
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();

                                values.vaccines = values.vaccines?.map(item => { return { vaccine: item.value }; })
                                values.drugs = values.drugs?.map(item => { return { drugs: item.value }; })

                                ADROnlineService.Update(this.props.id, values).then(
                                    (result) => {
                                        toastSuccess('Data Berhasil diubah');

                                        this.props.onhide()
                                    },
                                    (error) => {
                                        setSubmitting(false);
                                        toastError(error);
                                    })
                            }}
                        >
                        {formik => (
                                <Form>

                                    <Input
                                        disabled
                                        name="caseEntryId"
                                        type="hidden"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('caseEntryId')}
                                    />

                                    <Input
                                        disabled
                                        name="CaseEntryFollowUp"
                                        type="hidden"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('CaseEntryFollowUp')}
                                    />


                                    <div className="form-group">
                                        <label className="form-label">No Kasus</label>
                                        <Select2
                                            disabled
                                            options={this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                            name="caseNumber"
                                            value={formik.values.caseNumber}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                        />
                                    </div>

                                    {
                                        this.state.vaccine &&
                                        <div className="form-group">
                                            <label className="form-label">Produk</label>
                                            <Select2Multi
                                                disabled
                                                options={this.state.vaccine}
                                                value={this.state.vaccine?.map(item => item.value)}
                                                name="vaccines"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    }
                                    {
                                        this.state.drugs &&
                                        <div className="form-group">
                                            <label className="form-label">Produk</label>
                                            <Select2Multi
                                                disabled
                                                options={this.state.drugs}
                                                value={formik.values.drugs?.map(item => item.value)}
                                                name="drugs"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    }
                                    {
                                        (!this.state.vaccine && !this.state.drugs) &&
                                        <div className="form-group">
                                            <label className="form-label">Produk</label>
                                            <Select2Multi
                                                disabled
                                                name="product"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    }

                                    <div className="form-group">
                                        <label className="form-label">Tanggal Pengisian</label>
                                        <DatePicker
                                            name="entryDate"
                                            selectionType="single"
                                            maxdate={new Date()}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={formik.values.entryDate}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">ADR Online</label>
                                        <Input
                                            name="adronlineNumber"
                                            type="text"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('adronlineNumber')} />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Lampiran</label>
                                        <div>
                                            <ButtonWithIcon
                                                text="Upload File"
                                                icon="fa fa-plus"
                                                onClick={this.openfileDialog}
                                                width="150px"
                                            />
                                            <input type="file" ref={this.fileRef} style={{ display: "none" }} onChange={(e) => this.handleChange(formik, e.target)} accept="application/pdf,image/*"/>
                                        </div>
                                        <div className="attachment-wrapper">
                                            <div style={{ display: "flex" }}>
                                                <i class="fas fa-paperclip"></i>
                                                <p style={{ fontSize: 14, marginBottom: 0, marginLeft: 10 }}>{formik.values.uploadedFile ? formik.values.uploadedFile : "Tidak ada lampiran"}</p>
                                            </div>
                                            {
                                                formik.values.uploadedFile && formik.values.uploadedFileUrl && ((formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) !== "png" && (formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) !== "jpg" && (formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) !== "jpeg") &&  (
                                                    <div style={{marginTop : 20}}>
                                                        <Iframe 
                                                            url={formik.values.uploadedFileUrl}
                                                            width="100%"
                                                            height="400px"
                                                            id="external-iframe"
                                                            className="iframe"
                                                            display="initial"
                                                            position="relative"
                                                        />
                                                    </div>
                                                )     
                                            }
                                            {
                                                formik.values.uploadedFile && formik.values.uploadedFileUrl && ((formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) === "png" || (formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) === "jpg" || (formik?.values.uploadedFile).substr( ((formik?.values.uploadedFile).lastIndexOf('.') +1) ) === "jpeg") &&  (
                                                    <div style={{marginTop : 20, padding: "10px 30px", height: "400px", overflowY: "auto"}}>
                                                        <img src={formik.values.uploadedFileUrl} alt="homepage" className="navbar-logo"  />
                                                    </div>
                                                )   
                                            }
                                            {
                                                formik.values.uploadedFileUrl === null && (
                                                    <EmptyState />
                                                )
                                            }
                                        </div>
                                    </div>

                                    <hr />
                                    <div className='text-right'>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text="Batal"
                                            onClick={this.props.onhide}
                                        />
                                        <Button
                                            type="submit"
                                            text="Simpan"
                                        />
                                    </div>
                                </Form>
                        )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../../components/Utils/Button/Button';
import MultipleChoice from '../../../../components/Utils/Form/MultipleChoice';
import Select2 from '../../../../components/Utils/Field/Select2';
import Textarea from '../../../../components/Utils/Field/Textarea';

class AefiList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

        if(prevProps.formik.values.evidenceTimeWindow !== this.props.formik.values?.evidenceTimeWindow){
            this.props.formik.setFieldValue("evidenceSubTimeWindow", "")
        }
    }

    componentDidMount(){
        this.setState({causalityRelation : this.props.list?.causalityAEFIComplete})
    }

    render() {
        const { formik, typecase, history, disabled, t, i18next } = this.props;
        const { causalityRelation } = this.state;

        let evidenceDenialCausal = history?.evidenceDenialCausal !== undefined ? (history?.evidenceDenialCausal ? "Yes" : "No") : null;
        let evidenceTimeWindow = history?.evidenceTimeWindow !== undefined ? (history?.evidenceTimeWindow ? "Yes" : "No") : null;
        let evidenceSubTimeWindow = history?.evidenceSubTimeWindow !== undefined ? (history?.evidenceSubTimeWindow ? "Yes" : "No") : null;
        let evidenceOtherCauses = history?.evidenceOtherCauses !== undefined ? (history?.evidenceOtherCauses ? "Yes" : "No") : null;

        return (
            <>
                <div className="tab-pane" id="algorithm">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className={evidenceOtherCauses !== null ? ((evidenceOtherCauses === formik.values?.evidenceOtherCauses) === false ? "multiple-choice-wrapper change-value-radio" : "multiple-choice-wrapper") : "multiple-choice-wrapper"}>
                                <label className="form-label">A. {t('another_evidence')}</label>
                                <MultipleChoice
                                    question={"I. " + t('another_causes')}
                                    showchoice="not"
                                />
                                <MultipleChoice
                                    question={"I A. " + t('inconsistent_causal_immunization')}
                                    name="evidenceOtherCauses"
                                    type="choice"
                                    value={formik.values?.evidenceOtherCauses}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled={disabled}
                                />
                            </div>
                            <div className={evidenceTimeWindow !== null ? (((evidenceTimeWindow === formik.values?.evidenceTimeWindow) === false) || ((evidenceSubTimeWindow === formik.values?.evidenceSubTimeWindow) === false)  ? "multiple-choice-wrapper change-value-radio" : "multiple-choice-wrapper") : "multiple-choice-wrapper"}>
                                <label className="form-label">B. {t('know_causal_parent')}</label>
                                <MultipleChoice
                                    question={"II. " + t('known_causal')}
                                    showchoice="not"
                                />
                                <MultipleChoice
                                    question={`II. ${t('time')}. ${t('risk_increase')}`}
                                    name="evidenceTimeWindow"
                                    type="choice"
                                    value={formik.values?.evidenceTimeWindow}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled={disabled}
                                />

                                {
                                    formik.values?.evidenceTimeWindow === "Yes" && (
                                        <MultipleChoice
                                            question={"II. A" + t('consistent_causal')}
                                            name="evidenceSubTimeWindow"
                                            type="choice"
                                            value={formik.values?.evidenceSubTimeWindow}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={disabled}
                                        />
                                    )
                                }
                                
                            </div>
                            <div className={evidenceDenialCausal !== null ? (((evidenceDenialCausal === formik.values?.evidenceDenialCausal) === false) ? "multiple-choice-wrapper change-value-radio" : "multiple-choice-wrapper") : "multiple-choice-wrapper"}>
                                <label className="form-label">C. {t('strong_evidence_to_refute')}</label>
                                <MultipleChoice
                                    question={"III. " + t('strong_evidence')}
                                    showchoice="not"
                                />
                                <MultipleChoice
                                    question={"III A. " + t('inconsistent_causal_relationship')}
                                    name="evidenceDenialCausal"
                                    type="choice"
                                    value={formik.values?.evidenceDenialCausal}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled={disabled}
                                />
                            </div>
                            <div className="multiple-choice-wrapper">
                                <label className="form-label">D. {t('causality_factor_review')}</label>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('aefi_classification')}</label>
                                            <Select2
                                                name="causalityRelation"
                                                options={causalityRelation?.map(item => { return { value: item.id, label: "IV " + (i18next.language === 'id' || i18next.language === 'ID' ? item.name : item.nameEnTranslate) }; })}
                                                value={formik.values?.causalityRelation}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                disabled={disabled}
                                                same={history?.causalityUpv ? history.causalityUpv === formik.values?.causalityRelation : true }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label">{t('notes_for_step_3')}</label>
                                            <Textarea
                                                rows="6"
                                                name="evidenceNotes"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.evidenceNotes ? formik.values?.evidenceNotes : ""}
                                                disabled={disabled}
                                                {...formik.getFieldProps('evidenceNotes')} 
                                                same={history?.evidenceNotes ? history.evidenceNotes === formik.values?.evidenceNotes : true }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default AefiList;
import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const BpomProgressCommentService = {
    Write,
    Read,
};

async function Write(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`/api/v1/bpom-perkembangan-comments/create`, requestOptions);
    data = await handleResponse(data);
    return data;
}

async function Read(idBpomProgress) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    let data = await fetch(`/api/v1/bpom-perkembangan-comments/read?Id=` + idBpomProgress, requestOptions);
    data = await handleResponse(data);
    return data;
}

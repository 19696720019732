import React, { Component } from "react";
import ToggleDisplay from "react-toggle-display";
import Async, { useAsync } from 'react-select/async';
import AsyncSelect from 'react-select/async';

import Button from "../../../../components/Utils/Button/Button";
import Textarea from "../../../../components/Utils/Field/Textarea";
import Input from "../../../../components/Utils/Field/Input";
import Select2 from "../../../../components/Utils/Field/Select2";
import Select2Multi from "../../../../components/Utils/Field/Select2Multi";
import DatePicker from "../../../../components/Utils/DateRange/DateRangePicker";
import { AEFIService } from '../../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../../services/CaseEntry/AEService';

import { CaseEntryType } from "../../../../helpers/Constants";

import { withTranslation } from "react-i18next";

class ReportInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      selectedData: [],
        isComplete: "",        
    };
  }

    handleChange = item => {
        // this is going to call setFieldValue and manually update values.topcis        
        
        //

    };

  componentDidUpdate(prevProps) {}

  componentDidMount() {}
    

  handleCancelClick(event) {
    // this.props.history.push('/report-submission/bpom');

    this.props.history.push(`/report-submission/bpom`, {
      tab: this.props.tabType,
      page: this.props.page,
    });
    event.preventDefault();
  }

  render() {
   
      const { formik, typecase, historydata, t, ...otherProps } = this.props;      

    return (
      <>
        <Input
          disabled
          name="aefiCaseEntry"
          type="hidden"
          errors={formik.errors}
          touched={formik.touched}
          {...formik.getFieldProps("aefiCaseEntry")}
        />

        <Input
          disabled
          name="aeCaseEntry"
          type="hidden"
          errors={formik.errors}
          touched={formik.touched}
          {...formik.getFieldProps("aeCaseEntry")}
        />

        <div
          className="tab-pane show active tab-pane-fixed"
          id="report-information"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("case_number")}</label>
                <AsyncSelect                                 
                  cacheOptions={false}
                  defaultOptions={this.props.caseNumberList?.map((item) => {
                    return { value: item.caseNumber, label: item.caseNumber, id: item.id };
                  })}
                  loadOptions={this.props.promiseOptions}
                  errors={formik.errors}
                  touched={formik.touched}                  
                  onChange={(name, value) => {                    
                    var caseEntry = this.props.caseNumberList.find((obj) => {
                       return obj.id === name.id;
                   });                                                       
                    var analysis = this.props.analysisList.find((obj) => {
                        return obj.caseEntry === name.id;
                    });
                    var adrOnline = this.props.adrOnlineList.find((obj) => {
                        return obj.caseEntryId === name.id;
                    });
                    var aefiOverseas = this.props.aefiOverseasList.find(
                        (obj) => {
                            return obj.caseEntry === name.id;
                        }
                    );

                    let caseType = caseEntry?.aefiVaccine ? "KIPI" : "KTD";
                    let caseSeriousness = caseEntry?.caseNumber
                        ? caseEntry?.caseNumber.includes("AEFI")
                            ? "KIPI"
                            : caseEntry?.caseNumber.includes("PV")
                                ? "PV Data"
                                : "KTD"
                        : "KTD";
                    let seriousness =
                        caseType === "KIPI"
                            ? caseEntry?.seriousnessNavigation
                                ? caseEntry?.seriousnessNavigation.name.includes(
                                    "Non"
                                )
                                    ? "Tidak Serius"
                                    : "Serius"
                                : "Tidak Serius"
                              : "";                      
                    formik.setFieldValue("caseEntry", caseEntry?.id);
                    formik.setFieldValue("caseNumber", caseEntry?.caseNumber);
                    formik.setFieldValue(
                        "CaseEntryFollowUp",
                        caseEntry?.followUp
                    );            

                    formik.setFieldValue(
                        "bpomCaseCategoryText",
                        caseSeriousness + " " + seriousness
                    );

                    formik.setFieldValue("adrOnline", adrOnline?.id);
                    formik.setFieldValue(
                        "adrOnlineNumber",
                        adrOnline?.adronlineNumber
                    );
                    formik.setFieldValue("adrOnlineDate", adrOnline?.entryDate);

                    formik.setFieldValue(
                        "patientName",
                        caseEntry?.patientName ?? ""
                    );
                    formik.setFieldValue(
                        "patientAgeYear",
                        caseEntry?.patientAgeYear ?? ""
                    );
                    formik.setFieldValue(
                        "patientAgeMonth",
                        caseEntry?.patientAgeMonth ?? ""
                    );
                    formik.setFieldValue(
                        "patientGender",
                        caseEntry?.patientGender ?? ""
                    );

                    formik.setFieldValue(
                        "productUseDate",
                        caseEntry?.lastImmunizationDate ??
                        caseEntry?.productUseDate ??
                        ""
                    );
                    formik.setFieldValue(
                        "onsetDate",
                        caseEntry?.onsetDate ?? ""
                    );
                    formik.setFieldValue("outcome", caseEntry?.outcome ?? "");
                    formik.setFieldValue(
                        "diagnosis",
                        caseEntry?.resultConclusion ?? ""
                    );
                    formik.setFieldValue(
                        "seriousness",
                        caseEntry?.seriousness ?? ""
                    );
                    if (caseEntry?.patientCountry === 62) {
                        formik.setFieldValue("isOverseas", false);
                    } else {
                        formik.setFieldValue("isOverseas", true);
                        formik.setFieldValue(
                            "letterNumberOverseas",
                            aefiOverseas?.letterNumber
                        );
                        formik.setFieldValue(
                            "letterOverseasDate",
                            aefiOverseas?.letterDate
                        );
                    }
                    if (caseEntry?.aefiVaccine) {
                        formik.setFieldValue("aefiCaseEntry", caseEntry?.id);
                        formik.setFieldValue(
                            "resumeProduct",
                            caseEntry?.aefiVaccine
                                ?.filter((item, index) => item.vaccine != null)
                                .map((item) => {
                                    return {
                                        productName: item.vaccineNavigation?.name,
                                        batch: item.batch,
                                    };
                                })
                        );
                    }
                    if (caseEntry?.aeDrugs) {
                        formik.setFieldValue("aeCaseEntry", caseEntry?.id);
                        formik.setFieldValue(
                            "resumeProduct",
                            caseEntry?.aeDrugs
                                ?.filter((item, index) => item.drugs != null)
                                .map((item) => {
                                    return { productName: item.drugsNavigation?.name };
                                })
                        );
                    }
                    formik.setFieldValue("analysisBy", analysis?.analysisBy);
                    formik.setFieldValue(
                        "analyzerProvince",
                        analysis?.analysisByProvince
                    );

                    formik.setFieldValue(
                        "analysisResumeNeeded",
                        analysis?.resumeNeeded
                    );

                    formik.setFieldValue(
                        "analysisResult",
                        analysis?.analysisProduct?.map((item, index) => {
                            return {
                                productName: item.productName,
                                fieldAnalysisUpv: item.causalityUpvNavigation?.id,
                                fieldAnalysisUpvText:
                                    item.causalityUpvNavigation?.name,
                                fieldAnalysisExt:
                                    item.causalityExternalNavigation?.id,
                                fieldAnalysisExtText:
                                    item.causalityExternalNavigation?.name,

                                causalityAnalysisUpv: item.analysisCausalityUpv.map(
                                    (causality, index) => {
                                        return {
                                            causalityAnalysis:
                                                causality.causalityNavigation?.id,
                                            causalityAnalysisText:
                                                causality.causalityNavigation?.name,
                                        };
                                    }
                                ),

                                causalityAnalysisExternal:
                                    item.analysisCausalityExternal.map(
                                        (causality, index) => {
                                            return {
                                                causalityAnalysis:
                                                    causality.causalityNavigation?.id,
                                                causalityAnalysisText:
                                                    causality.causalityNavigation?.name,
                                            };
                                        }
                                    ),
                            };
                        })
                    );

                    var vaccine = caseEntry?.aefiVaccine
                        ?.filter(
                            (item, index) =>
                                item.vaccine != null &&
                                caseEntry.aefiVaccine.findIndex(
                                    (x) => x.vaccine === item.vaccine
                                ) === index
                        )
                        .map((item) => {
                            return { vaccine: item.vaccine, batch: item.batch };
                        });
                    var drugs = caseEntry?.aeDrugs
                        ?.filter(
                            (item, index) =>
                                item.drugs != null &&
                                caseEntry.aeDrugs.findIndex(
                                    (x) => x.drugs === item.drugs
                                ) === index
                        )
                        .map((item) => {
                            return { drugs: item.drugs };
                        });

                    formik.setFieldValue("vaccines", vaccine);
                    formik.setFieldValue("drugs", drugs);
                    formik.setFieldValue(
                        "vaccinesKeys",
                        vaccine?.map((v) => {
                            return v.vaccine;
                        })
                    );
                    formik.setFieldValue(
                        "drugsKeys",
                        drugs?.map((d) => {
                            return d.drugs;
                        })
                    );
                    if (analysis?.caseType?.toLowerCase() === "ae") {
                        formik.setFieldValue("caseType", CaseEntryType.AE);
                    } else {
                        formik.setFieldValue("caseType", CaseEntryType.AEFI);
                    }
                }
        }
                  onBlur={formik.setFieldTouched}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("aefi_type")}</label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="true"
                      name="isOverseas"
                      checked={
                        formik.values.isOverseas === "true" ||
                        formik.values.isOverseas === true
                      }
                      readOnly
                    />
                    <label className="form-check-label">{t("overseas")}</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="false"
                      name="isOverseas"
                      checked={
                        formik.values.isOverseas === "false" ||
                        formik.values.isOverseas === false
                      }
                      readOnly
                    />
                    <label className="form-check-label">Dalam Negeri</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">{t("adr_online_date")}</label>
                    <DatePicker
                      disabled
                      name="adrOnlineDate"
                      selectionType="single"
                      maxdate={new Date()}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={formik.setFieldValue}
                      onBlur={formik.setFieldTouched}
                      value={formik.values.adrOnlineDate}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      {t("adr_online_number")}
                    </label>
                    <Input
                      disabled
                      name="adrOnlineNumber"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("adrOnlineNumber")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t("overseas_aefi_letter_number")}
                </label>
                <Input
                  disabled
                  name="adrOnlineNumber"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("letterNumberOverseas")}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("case_category")}</label>
                <Input
                  disabled
                  name="bpomCaseCategoryText"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("bpomCaseCategoryText")}
                />
              </div>
            </div>
            <div className="col-md-6">
              {formik.values.vaccines && (
                <div className="form-group">
                  <label className="form-label">{t("product_name")}</label>
                  <Select2Multi
                    disabled
                    options={this.props.vaccineList}
                    value={formik.values.vaccinesKeys}
                    name="vaccines"
                    errors={formik.errors}
                    touched={formik.touched}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                </div>
              )}
              {formik.values.drugs && (
                <div className="form-group">
                  <label className="form-label">{t("product_name")}</label>
                  <Select2Multi
                    disabled
                    options={this.props.drugsList}
                    value={formik.values.drugsKeys}
                    name="drugs"
                    errors={formik.errors}
                    touched={formik.touched}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                </div>
              )}
              {!formik.values.vaccines && !formik.values.drugs && (
                <div className="form-group">
                  <label className="form-label">{t("product_name")}</label>
                  <Select2Multi
                    disabled
                    name="product"
                    errors={formik.errors}
                    touched={formik.touched}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                  />
                </div>
              )}
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">{t("place")}</label>
                    <Input
                      name="letterPlace"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("letterPlace")}
                      same={
                        historydata.letterPlace
                          ? historydata.letterPlace ===
                            formik.values.letterPlace
                          : true
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("date")}</label>
                    <DatePicker
                      name="letterDate"
                      selectionType="single"
                      maxdate={new Date()}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={formik.setFieldValue}
                      onBlur={formik.setFieldTouched}
                      value={formik.values.letterDate}
                      same={
                        historydata?.letterDate
                          ? historydata?.letterDate ===
                            formik.values?.letterDate
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">{t("letter_address")}</label>
                <Textarea
                  name="letterAddress"
                  rows="6"
                  name="target"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("letterAddress")}
                  same={
                    historydata?.letterAddress
                      ? historydata.letterAddress ===
                        formik.values?.letterAddress
                      : true
                  }
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">
                      {t("number_of_attachments")}
                    </label>
                    <div
                      className={
                        historydata?.letterAttachment
                          ? "input-group " +
                            ((historydata.letterAttachment ===
                              formik.values.letterAttachment) ===
                            false
                              ? "change-value-wrapper"
                              : "")
                          : "input-group"
                      }
                    >
                      <input
                        name="letterAttachment"
                        type="number"
                        min={0}
                        className="form-control"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("letterAttachment")}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {t("attachment_hal")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <ToggleDisplay>
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t("cancel")}
                onClick={(event) => this.handleCancelClick(event)}
              />
            </ToggleDisplay>
            <ToggleDisplay>
              <Button
                text={t("next")}
                onClick={this.props.handleNextClick}
                isSubmitting={this.state.isDisableNext}
              />
            </ToggleDisplay>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReportInformation);

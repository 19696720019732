import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const CaseCommentReviewService = {
    Write,
    Read,
};

async function Write(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/comments/medical-review/create`, requestOptions);
    data = await handleResponse(data);
    return data;
}

async function Read(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    let data = await fetch(`api/v1/comments/medical-review/read?id=` + id, requestOptions);
    data = await handleResponse(data);
    return data;
}

import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import { Table } from "react-bootstrap";
import Select from "react-select";

import * as Utils from "../../../helpers/Utils";
import Button from "../../../components/Utils/Button/Button";
import Select2 from "../../../components/Utils/Field/Select2";
import Input from "../../../components/Utils/Field/Input";
import Textarea from "../../../components/Utils/Field/Textarea";
import { Role, Status } from "../../../helpers/Constants";

import { authenticationService } from "../../../services/AuthService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { AnalysisService } from "../../../services/QualityReview/Analysis/AnalysisService";
import { ResumeService } from "../../../services/QualityReview/Resume/ResumeService";
import { HistoryService } from "../../../services/HistoryService";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";

class DetailResume extends Component {
  constructor(props) {
    super(props);

    const { id, type } = this.props.match.params;

    this.state = {
      id: id,
      type: type,
      snackbaropen: false,
      snackbarmsg: "",
      versionOptions: [],
      isLastVersion: true,
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      comments: [],
      historys: [],
      historydata: [],
      historysCaseEntry: [],
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.prepareForm(id);
    this.historyList(id);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  historyList(id) {
    HistoryService.ReadQualityReview(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {       
    HistoryService.Read(id,'AEFI').then((result) => {
        this.setState({ historysCaseEntry: result.data });        
    });
  }

  prepareForm(id) {
    Promise.all([
      AnalysisService.Read('',1, 0, ""),
      DropdownService.MasterList(["outcome"]),
      ResumeService.PrepareForm(id),
    ]).then(
      ([kipi, dropdown, resume]) => {
        // get dropdown data
        this.setState({
          caseNumberList: kipi.data.records,
          outcomeList: dropdown.data.outcomeList,
        });

        // get resume data
        if (resume.data) {
          var analysis = kipi.data.records.find((obj) => {
            return obj.caseNumber === resume.data.caseNumber;
          });
          
          this.historyCaseEntryList(analysis.caseEntry)

          var caseEntry;

          if (analysis.aeCaseEntry) {
            caseEntry = analysis.aeCaseEntryNavigation;
          }
          if (analysis.aefiCaseEntry) {
            caseEntry = analysis.aefiCaseEntryNavigation;
          }

          resume.data.patientName = caseEntry?.patientName;
          resume.data.patientAgeYear = caseEntry?.patientAgeYear;
          resume.data.patientAgeMonth = caseEntry?.patientAgeMonth;
          resume.data.patientGender = caseEntry?.patientGender;

          resume.data.chronology = caseEntry?.resultChronology;
          resume.data.resultLaboratory = caseEntry?.resultLaboratory;
          resume.data.resultPhysic = caseEntry?.resultPhysic;
          resume.data.diagnosis = caseEntry?.resultConclusion;
          resume.data.outcome = caseEntry?.outcome;

          resume.data.resumeProduct = analysis.analysisProduct.map((item) => {
            return { productName: item.productName, batch: item.batch };
          });
          //}

          this.setState({
            data: resume.data,
            historydata: resume.data.historyJson ? resume.data.historyJson : [],
          });

          var versionOptions = resume.data.trailResume
            ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
            .map((trail) => {
              return {
                value: trail.followUp,
                label:
                  trail.followUp === 0
                    ? "Inisial"
                    : "Follow Up " + trail.followUp,
              };
            });
          versionOptions.push({
            value: resume.data.followUp,
            label:
              resume.data.followUp === 0
                ? "Inisial"
                : "Follow Up " + resume.data.followUp,
          });

          if (versionOptions) {
            this.setState({
              versionOptions: versionOptions,
              selectedVersion: versionOptions[versionOptions.length - 1],
              lastVersion: versionOptions[versionOptions.length - 1],
              isLastVersion: true,
            });
          }
        } else {
          this.props.history.push(`/medical-review/resume`);
        }
      },
      () => {
        this.props.history.push(`/medical-review/resume`);
      }
    );
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        ResumeService.PrepareForm(
          this.state.id,
          this.state.selectedVersion.value
        ).then(
          (result) => {
            if (result.data) {
              this.setState({
                data: result.data,
                historydata: result.data.historyJson
                  ? result.data.historyJson
                  : [],
              });
            } else {
              this.props.history.push(`/medical-review/resume`);
            }
          },
          (error) => {
            this.props.history.push(`/medical-review/resume`);
          }
        );
      }
    );
  }

  handleCancelClick(event) {
    this.props.history.push("/medical-review/resume", {
      pages: this.props?.location?.state?.page,
    });
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleReject(role) {
    if (role === Role.Unit) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
  }

  handleAccept(role) {
    if (role === Role.Unit) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
  }

  render() {
    const { t } = this.props;

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };
    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const resume = this.state.data;
    const historydata = this.state.historydata;
    const isLastVersion = this.state.isLastVersion;

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (resume?.status === Status.Pending ||
        resume?.status === Status.Draft ||
        resume?.status === Status.NotOk ||
        resume?.status === Status.NotApproved ||
        this.state.type === "follow-up");
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (resume?.status === Status.Draft ||
        resume?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (resume?.status === Status.Draft || resume?.status === Status.Reviewed);

    const role = currentUser ? currentUser.role : "";

    const isDisabledForm =
      (this.state.type === "follow-up" && isLastVersion) ||
      (role === Role.Divisi &&
        resume?.status === Status.Reviewed &&
        isLastVersion)
        ? false
        : !this.state.isLastVersion ||
          resume?.status === Status.Reviewed ||
          resume?.status === Status.Approved;

    const initial = {
      caseNumber: resume?.caseNumber,

      patientName: resume?.patientName,
      patientGender: resume?.patientGender,
      parentName: resume?.parentName,
      patientAgeYear: resume?.patientAgeYear,
      patientAgeMonth: resume?.patientAgeMonth,

      chronology: resume?.chronology,
      resultPhysic: resume?.resultPhysic,
      resultLaboratory: resume?.resultLaboratory,
      diagnosis: resume?.diagnosis,
      outcome: resume?.outcome,
      resultAnalysis: resume?.resultAnalysis,

      resumeProduct: resume?.resumeProduct ?? resume?.trailresumeProduct,
    };

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />

        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
          title={t("cases_history")}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          resume={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          menu="medicalReview"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          menu="medicalReview"
          t={t}
        />

        <div>
          <div>
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          validationSchema={Yup.object().shape({
            resultAnalysis: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            // Check follow up
            var isFollowUp = this.state.type === "follow-up" ? true : false;

            if (!isFollowUp) {
              ResumeService.Update(this.state.id, values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/medical-review/resume`);
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            } else {
              ResumeService.FollowUp(this.state.id, values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/medical-review/resume`);
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("case_number")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select2
                        disabled
                        name="caseNumber"
                        options={this.state.caseNumberList?.map((item) => {
                          return {
                            value: item.caseNumber,
                            label: item.caseNumber,
                          };
                        })}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik.values?.caseNumber}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className={
                        "form-group " +
                        (historydata?.trailResumeProduct
                          ? "change-value-radio"
                          : "")
                      }
                    >
                      <Table>
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>{t("product_name")}</th>
                            <th>{t("batch_number")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formik.values.resumeProduct?.map((record, index) => (
                            <tr>
                              <td>1</td>
                              <td>
                                <Input
                                  disabled
                                  name={`resumeProduct[${index}][productName]`}
                                  type="text"
                                  errors={formik.errors}
                                  touched={formik.touched}
                                  {...formik.getFieldProps(
                                    `resumeProduct[${index}][productName]`
                                  )}
                                />
                              </td>
                              <td>
                                <Input
                                  disabled
                                  name={`resumeProduct[${index}][batch]`}
                                  type="text"
                                  errors={formik.errors}
                                  touched={formik.touched}
                                  {...formik.getFieldProps(
                                    `resumeProduct[${index}][batch]`
                                  )}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <div>
                <label className="form-label" style={{ fontSize: 16 }}>
                  {t("identity")}
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">{t("name")}</label>
                      <Input
                        disabled
                        name="patientName"
                        type="text"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("patientName")}
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("gender")}</label>
                      <div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            value="U"
                            name="patientGender"
                            disabled
                            checked={formik.values.patientGender === "U"}
                            onChange={formik.handleChange}
                            onClick={() =>
                              formik.setFieldValue("patientChildbearing", "")
                            }
                          />
                          <label className="form-check-label">
                            {t("no_data")}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            value="M"
                            name="patientGender"
                            disabled
                            checked={formik.values.patientGender === "M"}
                            onChange={formik.handleChange}
                          />
                          <label className="form-check-label">
                            {t("male")}
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            type="radio"
                            className="form-check-input"
                            value="F"
                            name="patientGender"
                            disabled
                            checked={formik.values.patientGender === "F"}
                            onChange={formik.handleChange}
                          />
                          <label className="form-check-label">
                            {t("female")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("age")}</label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              disabled
                              name="patientAgeYear"
                              type="number"
                              min={0}
                              placeholder={t("year")}
                              className="form-control"
                              errors={formik.errors}
                              touched={formik.touched}
                              value={
                                formik.values.patientAgeYear
                                  ? formik.values.patientAgeYear
                                  : ""
                              }
                              {...formik.getFieldProps("patientAgeYear")}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {t("year")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              disabled
                              name="patientAgeMonth"
                              type="number"
                              min={0}
                              max={11}
                              placeholder={t("month")}
                              className="form-control"
                              errors={formik.errors}
                              touched={formik.touched}
                              {...formik.getFieldProps("patientAgeMonth")}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {t("month")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <div>
                <label className="form-label" style={{ fontSize: 16 }}>
                  {t("manifestation_result_caps")}
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">{t("chronological")}</label>
                      <Textarea
                        disabled
                        rows="6"
                        name="chronology"
                        placeholder={t("chronological")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("chronology")}
                        same={
                          historydata?.chronology
                            ? historydata.chronology.before ===
                              historydata.chronology.after
                            : true
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {t("laboratory_result")}
                      </label>
                      <Textarea
                        disabled
                        rows="6"
                        name="resultLaboratory"
                        placeholder={t("laboratory_result")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("resultLaboratory")}
                        same={
                          historydata?.resultLaboratory
                            ? historydata.resultLaboratory.before ===
                              historydata.resultLaboratory.after
                            : true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t("physical_examination")}
                      </label>
                      <Textarea
                        disabled
                        rows="6"
                        name="resultPhysic"
                        placeholder={t("physical_examination")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("resultPhysic")}
                        same={
                          historydata?.resultPhysic
                            ? historydata.resultPhysic.before ===
                              historydata.resultPhysic.after
                            : true
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label">{t("diagnose")}</label>
                      <Textarea
                        disabled
                        rows="6"
                        name="diagnosis"
                        placeholder={t("diagnose")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("diagnosis")}
                        same={
                          historydata?.diagnosis
                            ? historydata.diagnosis.before ===
                              historydata.diagnosis.after
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
                <hr />
              </div>

              <div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">{t("outcome")}</label>
                      <Select2
                        disabled
                        name="outcome"
                        options={this.state.outcomeList?.map((item) => {
                          return { value: item.id, label: item.name };
                        })}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik.values.outcome}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                        same={
                          historydata?.outcome
                            ? historydata.outcome.before ===
                              historydata.outcome.after
                            : true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        {t("analysis_result")}
                      </label>
                      <Textarea
                        disabled={isDisabledForm}
                        rows="6"
                        name="resultAnalysis"
                        placeholder={t("analysis_result")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("resultAnalysis")}
                        same={
                          historydata?.resultAnalysis
                            ? historydata.resultAnalysis.before ===
                              historydata.resultAnalysis.after
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-right">
                <ToggleDisplay>
                  <Button
                    color="#495057"
                    background="#FFFFFF 0% 0% no-repeat padding-box"
                    border="1px solid #495057"
                    text={t("cancel")}
                    onClick={() => this.handleCancelClick()}
                  />
                </ToggleDisplay>

                {/* Simpan */}
                {canStaffEdit && (
                  <ToggleDisplay show={this.state.saveShow}>
                    <Button
                      type="submit"
                      text={t("save")}
                      isSubmitting={
                        !this.state.isLastVersion || formik.isSubmitting
                      }
                    />
                  </ToggleDisplay>
                )}

                <input
                  name="isApprove"
                  type="hidden"
                  {...formik.getFieldProps("isApprove")}
                />
                <input
                  name="isReview"
                  type="hidden"
                  {...formik.getFieldProps("isReview")}
                />

                {/* Reject */}
                {(canUnitEdit || canDivisiEdit) && (
                  <ToggleDisplay show={this.state.saveShow}>
                    <Button
                      background="#e15756 0% 0% no-repeat padding-box"
                      text="Reject"
                      onClick={() => {
                        if (canUnitEdit) {
                          formik.setFieldValue("isReview", false);
                        }
                        if (canDivisiEdit) {
                          formik.setFieldValue("isApprove", false);
                        }
                        this.setState({ RejectedConfirmationModal: true });
                      }}
                    />
                  </ToggleDisplay>
                )}
                {/* Forward/Approve */}
                {(canUnitEdit || canDivisiEdit) && (
                  <ToggleDisplay show={this.state.saveShow}>
                    <Button
                      text={
                        currentUser.role === Role.Unit ? "Forward" : "Approve"
                      }
                      onClick={() => {
                        if (canUnitEdit) {
                          formik.setFieldValue("isReview", true);
                        }
                        if (canDivisiEdit) {
                          formik.setFieldValue("isApprove", true);
                        }
                        this.setState({ ForwardedConfirmationModal: true });
                      }}
                    />
                  </ToggleDisplay>
                )}
                {formik.status && (
                  <div className={"alert alert-danger"}>{formik.status}</div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailResume);

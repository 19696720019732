import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal as ModalBootstrap } from 'react-bootstrap';

const ModalStyled = styled.div.attrs(props => ({ ...props }))`
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px -2px 22px #0000000A;
    border-radius: 14px;
    opacity: 1;
    border: 0;
`

export default class Modal extends Component {

    render() {
        return (
            <ModalBootstrap {...this.props}>
                <ModalStyled className="modal-content">
                    {this.props.children}
                </ModalStyled>
            </ModalBootstrap>
        );
    }
}

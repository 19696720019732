import React from 'react';
import Select2 from '../../components/Utils/Field/Select2';
import Input from '../../components/Utils/Field/Input';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import YearPicker from '../../components/Utils/DateRange/YearPicker';
import { ProductDistributionService } from '../../services/ProductDistribution/ProductDistributionService';

export class FormProductDistribution extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            loading_data: false
        };
    }

    componentDidUpdate() {

        this.countProductDistributionRecordsTotal(this.props.formik)
    }

    handleRefreshERP = () => {
        
        ProductDistributionService.SyncData(this.props.formik.values.product).then(result => {

            let value = result?.data?.length > 0 ? result.data : [{year: null, dose: null, domesticVial: 0, exportVial: 0}];

            this.props.formik.setFieldValue('productDistributionRecords', value)
            this.props.productUpdate(value)
            console.log(value)
            this.setState({loading_data: false})
        });
    }

    render() {

        const { formik, t, ...otherProps } = this.props;

        return (
            <>
                <div>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label">{t('product_name')}</label>
                            <div className="row">
                                <div className="col-6">
                                    <Select2
                                        name="product"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik?.values?.product}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        options={this.props.productList}
                                    />
                                </div>
                                <div className="col-6">
                                    <ButtonWithIcon
                                        text={'Synchronization'}
                                        icon="fa fa-sync-alt"
                                        onClick={() => this.setState({loading_data : true}, () => {this.handleRefreshERP()})}
                                        isSubmitting={!formik?.values?.product}
                                    />
                                </div>  
                            </div>
                        </div>
                    </div>
                    {
                        this.state.loading_data && 
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <p data-text={t('retrieving_data')} className="loading-menu" style={{fontSize: 14, marginTop: 5, paddingLeft: 20}}>{t('retrieving_data')}</p>
                                </div>
                            </div>
                        </div>
                    }
                    <hr/>
                </div>
                <h3 className="col-12">{t('product_distribution_caps')}</h3>
                <div className="col-12">
                    {this.createProductDistributionRecordsUI(formik)}
                    {this.initialProductDistributionRecordsUI(formik)}

                    <div className="row dynamic-form">

                        <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-3 col-md-7">
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-1">
                                    <label className="form-label">Total</label>
                                </div>
                                <div className="col-xs-6 col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <Input
                                            disabled
                                            name="totalVial"
                                            type="number"
                                            min={0}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`totalVial`)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ButtonWithIcon
                        text={t('add_distribution')}
                        icon="fa fa-plus"
                        onClick={this.addProductDistributionRecordsUI.bind(this, formik)}
                        width="200px"
                    />
                </div>
            </>
        )
    }


    // ProductDistributionRecords Dynamic Table

    initialProductDistributionRecordsUI(formik) {

        const { t } = this.props;

        const productDistributionRecords = this.props.productDistributionRecords;

        if (productDistributionRecords && productDistributionRecords.length) {

            for (var i = 0; i < productDistributionRecords.length; i++) {

                if (productDistributionRecords[i] && !productDistributionRecords[i].previewed) {
                    formik.setFieldValue(`productDistributionRecords[${i}][id]`, productDistributionRecords[i].id)
                    formik.setFieldValue(`productDistributionRecords[${i}][year]`, productDistributionRecords[i].year)
                    formik.setFieldValue(`productDistributionRecords[${i}][dose]`, productDistributionRecords[i].dose)
                    formik.setFieldValue(`productDistributionRecords[${i}][domesticVial]`, productDistributionRecords[i].domesticVial)
                    formik.setFieldValue(`productDistributionRecords[${i}][exportVial]`, productDistributionRecords[i].exportVial)
                    formik.setFieldValue(`productDistributionRecords[${i}][totalVial]`, productDistributionRecords[i].totalVial)
                }

                this.props.productDistributionRecords[i].previewed = true;
            }
        }
    }

    createProductDistributionRecordsUI(formik) {

        const { t } = this.props;

        const isDisabledForm = false;

        return this.props.productDistributionRecords?.map((element, i) =>

            <div key={i}>

                <Input
                    name={`productDistributionRecords[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`productDistributionRecords[${i}][id]`)}
                    disabled={isDisabledForm}
                />
                <div className="row dynamic-form">
                    
                    <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('year')}</label>}
                                    
                                    { formik.values?.productDistributionRecords != null &&
                                        <YearPicker
                                            name={`productDistributionRecords[${i}][year]`}
                                            value={element?.value ?? formik.values.productDistributionRecords[i]?.year ?? new Date().getFullYear()}
                                            handlechange={(year) => formik.setFieldValue(`productDistributionRecords[${i}][year]`, year)}
                                            className="form-control" />                                        
                                    }
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('dose')}</label>}
                                    <Input
                                        name={`productDistributionRecords[${i}][dose]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`productDistributionRecords[${i}][dose]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Indonesia</label>}
                                    <Input
                                        name={`productDistributionRecords[${i}][domesticVial]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`productDistributionRecords[${i}][domesticVial]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>  
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Export</label>}
                                    <Input
                                        name={`productDistributionRecords[${i}][exportVial]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`productDistributionRecords[${i}][exportVial]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Total</label>}
                                    <Input
                                        disabled
                                        name={`productDistributionRecords[${i}][totalVial]`}
                                        type="number"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`productDistributionRecords[${i}][totalVial]`)}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeProductDistributionRecordsUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addProductDistributionRecordsUI(formik) {
        this.props.productDistributionRecords.push({ isNew: true, year: new Date().getFullYear(), dose: "", domesticVial: 0, exportVial: 0, totalVial: 0 });
        formik.setFieldValue(`productDistributionRecords[${this.props.productDistributionRecords.length - 1}][id]`, null)
    }

    removeProductDistributionRecordsUI(formik, index) {

        for (var i = index; i < this.props.productDistributionRecords.length; i++) {

            const productDistributionRecords = formik.values.productDistributionRecords[i + 1];

            this.props.productDistributionRecords[i] = this.props.productDistributionRecords[i + 1];

            if (productDistributionRecords) {
                formik.setFieldValue(`productDistributionRecords[${i}][id]`, productDistributionRecords.id)
                formik.setFieldValue(`productDistributionRecords[${i}][year]`, productDistributionRecords.year)
                formik.setFieldValue(`productDistributionRecords[${i}][dose]`, productDistributionRecords.dose)
                formik.setFieldValue(`productDistributionRecords[${i}][domesticVial]`, productDistributionRecords.domesticVial)
                formik.setFieldValue(`productDistributionRecords[${i}][exportVial]`, productDistributionRecords.exportVial)
                formik.setFieldValue(`productDistributionRecords[${i}][totalVial]`, productDistributionRecords.totalVial)
            }
            else {
                formik.setFieldValue(`productDistributionRecords[${i}]`, null)
            }
        }

        this.props.productDistributionRecords.pop();
    }

    countProductDistributionRecordsTotal(formik) {

        var total = 0;

        if (formik.values.productDistributionRecords) {

            for (var i = 0; i < formik.values.productDistributionRecords.length; i++) {
                const productDistributionRecords = formik.values.productDistributionRecords[i];

                if (productDistributionRecords) {
                    productDistributionRecords.totalVial = productDistributionRecords.domesticVial != "" ? productDistributionRecords.domesticVial : 0;
                    productDistributionRecords.totalVial += productDistributionRecords.exportVial != "" ? productDistributionRecords.exportVial : 0;
                    total += parseInt(productDistributionRecords.totalVial);
                }
            }
        }

        formik.values.totalVial = total;
    }
}
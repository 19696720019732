import React, { Component } from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button.attrs(props => ({ ...props }))`
    background: ${props => props.background ? props.background : "#00ABBD 0% 0% no-repeat padding-box"};
    border-radius: 6px;
    opacity: 1;
    text-align: ${props => props.align ? props.align : "center"};
    font: normal normal 16px/16px Montserrat;
    letter-spacing: 0px;
    min-width : 100px;
    color: ${props => props.color ? props.color : "#fff"};
    width: ${props => props.width ? props.width : "fit-content"};
    height: ${props => props.height ? props.height : "fit-content"};
    border: ${props => props.border ? props.border : "0"};
    padding: ${props => props.padding ? props.padding : "10px 15px"};
    margin: ${props => props.margin ? props.margin : "0.5rem"};
    box-shadow: ${props => props.shadow ? props.shadow : ""};
`

export default class Button extends Component {

    render() {
        return (
            <ButtonStyled
                disabled={this.props.isSubmitting}
                type={this.props.type ? this.props.type : 'button'}
                {...this.props} 
            >
                {this.props.text}
            </ButtonStyled>
        );
    }
}

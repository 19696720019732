import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

function BarChart(props) {
    const { data, label } = props;

    const barChartColor = [
        "#E15756",
        "#A646EF",
        "#F2B44D",
        "#00ABBD",
        "#2CCD8966",
        "#EB4A8A",
        "#6D8E92",
        "#FF6F61",
        "#6B5B95",
        "#88B04B"
    ];

    let labelChart = []
    let valueChart = []
    let maxTotalYAxis = 0;  // To keep track of the maximum value

    const legend_product = {
        display: false,
        position: 'bottom',
        align: 'start',
        fullWidth: true,
        fullSize: true,
        maxWidth: 100,
    };

    data.forEach((item) => {
        labelChart.push(item.name)
        valueChart.push(item.total || 0)
        if (item.total > maxTotalYAxis) {
            maxTotalYAxis = item.total;  // Update maxTotalYAxis if current value is greater
        }
    })

    const dataChart = {
        labels: labelChart,
        datasets: [
            {
                label: label,
                data: valueChart,
                backgroundColor: barChartColor,
                borderColor: barChartColor,
                borderWidth: 1,
                minBarLength: 1
            },
        ],
        options: {
            scales: {
                yAxes: [{
                    display: true
                }],
                xAxes: [{
                    display: false
                }],
            }
        },
    };

    const optionBarChart = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                anchor: 'end',      // Positions the label at the top of the bar
                align: 'end',       // Aligns it at the end of the bar
                formatter: function(value) {
                    return value;
                },
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                suggestedMin: 0
            },
            yAxes: [
                {
                    display: true,
                    ticks: {
                        beginAtZero: true,
                        suggestedMax: maxTotalYAxis > 5 ? maxTotalYAxis + (maxTotalYAxis * (1/8)) : maxTotalYAxis + 5
                    }
                }
            ],
            xAxes: [
                {
                    display: false
                }
            ]
        }
    };

    return (
        <div className='row mb-5'>
            <div className='col-md-9'>
                <Bar
                    data={dataChart}
                    options={optionBarChart}
                    legend={legend_product}
                    plugins={[ChartDataLabels]}
                />
            </div>
            <div className='col-md-3'>
                <div style={{ display: 'inline-grid', justifyItems: 'left', width: '100%' }}>
                    {dataChart?.labels?.map((label, indexLabel) => (
                        <div className='row w-100' key={indexLabel} style={{ marginBottom: '10px' }}>
                            <div className='col-md-2 col-sm-2 mr-2'>
                                <span
                                    style={{
                                        display: 'inline-block',
                                        width: '40px',
                                        height: '10px',
                                        backgroundColor: dataChart?.datasets[0].backgroundColor[indexLabel],
                                        marginRight: '10px',
                                    }}
                                ></span>
                            </div>
                            <div className='col-md col-sm'>
                                <span className="legend-chart">{label}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BarChart;

import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import Select2Multi from '../../../../components/Utils/Field/Select2Multi';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class SummaryTabulations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {

        const { formik, typecase, historydata, list, t, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";

        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;
        const disabled = this.props.isLastVersion === undefined  ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;

        let clinicalTotal = 0
        let literatureTotal = 0
        let spontanousTotal = 0

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="summary-tabulation">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">SUMMARY TABULATIONS</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th style={{minWidth: 200}}>System Organ Class</th>
                                                <th style={{minWidth: 200}}>Preffered Term</th>
                                                <th style={{minWidth: 100}}>Spontaneous</th>
                                                <th style={{minWidth: 100}}>Clinical Trials</th>
                                                <th style={{minWidth: 100}}>Literature</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.psurSummaryTabulations?.map((record, index) =>
                                            {
                                                clinicalTotal = clinicalTotal + record?.clinicalTrialTotal
                                                spontanousTotal = spontanousTotal + record?.spontanousTotal
                                                literatureTotal = literatureTotal + record?.literatureTotal

                                                return (
                                                    <>
                                                    {
                                                        record.psurSummaryTabulationPt.map((item, i) =>
                                                        {
                                                            return (
                                                                <tr key={i}>
                                                                    {
                                                                        i === 0 &&
                                                                        <>
                                                                            <td rowSpan={record?.psurSummaryTabulationPt?.length === 0 ? 1 : record?.psurSummaryTabulationPt?.length} style={{verticalAlign:"middle"}}>{index + 1}</td>
                                                                            <td rowSpan={record?.psurSummaryTabulationPt?.length === 0 ? 1 : record?.psurSummaryTabulationPt?.length} style={{verticalAlign:"middle"}}>{record?.soc ?? '-'}</td>
                                                                        </>
                                                                    }
                                                                    <td>{item?.pt ?? '-'}</td>
                                                                    <td>{item?.spontanous ?? '-'}</td>
                                                                    <td>{item?.clinicalTrial ?? '-'}</td>
                                                                    <td>{item?.literature ?? '-'}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            }
                                            )}
                                            {
                                                (formik.values?.psurSummaryTabulations === undefined || formik.values?.psurSummaryTabulations === null || formik.values?.psurSummaryTabulations?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="6" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                        <thead>
                                            <tr>
                                                <th/>
                                                <th colSpan="2">Total</th>
                                                <th style={{minWidth: 200}}>{spontanousTotal}</th>
                                                <th style={{minWidth: 100}}>{clinicalTotal}</th>
                                                <th style={{minWidth: 100}}>{literatureTotal}</th>
                                            </tr>
                                        </thead>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(SummaryTabulations);
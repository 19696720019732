import React, { Component } from 'react';

export class AccessDenied extends Component {

    componentDidMount() {
        document.title = "Access Denied"
    }

    render() {
        return (
            <div>
                403 Access Denied
            </div>
        );
    }
}

import React, { Component } from 'react';

import Input from '../../../components/Utils/Field/Input';
import { withTranslation } from "react-i18next";


class Reporter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {
        const { formik } = this.props;
        const { t } = this.props;

        return (
            <>
                <div className="tab-pane show active tab-pane-fixed" id="report-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('case_number')}</label>
                                <Input
                                    disabled
                                    name="caseNumber"
                                    type="text"
                                    placeholder={t('case_number')}
                                    value={formik?.values?.caseNumber}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('caseNumber')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('unit_pv_date')}</label>
                                <Input
                                    disabled
                                    name="adversePvDate"
                                    type="text"
                                    placeholder={t('unit_pv_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.adversePvDate}
                                    {...formik.getFieldProps('adversePvDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('followup_report_date')}</label>
                                <Input
                                    disabled
                                    name="followUpDate"
                                    type="text"
                                    placeholder={t('followup_report_date')}
                                    value={formik?.values?.followUpDate}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('followUpDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('adr_online_number')}</label>
                                <Input
                                    disabled
                                    name="adrOnlineNumber"
                                    type="text"
                                    placeholder={t('adr_online_number')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.adrOnlineNumber}
                                    {...formik.getFieldProps('adrOnlineNumber')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('adr_online_reporting_date')}</label>
                                <Input
                                    disabled
                                    name="adrOnlineDate"
                                    type="text"
                                    placeholder={t('adr_online_reporting_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.adrOnlineDate}
                                    {...formik.getFieldProps('adrOnlineDate')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('manufacture_case_number')}</label>
                                <Input
                                    disabled
                                    name="manufactureNumber"
                                    type="text"
                                    placeholder={t('manufacture_case_number')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.manufactureNumber}
                                    {...formik.getFieldProps('manufactureNumber')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('manufacture_initial_date')}</label>
                                <Input
                                    disabled
                                    name="manufactureInitialDate"
                                    type="text"
                                    placeholder={t('manufacture_initial_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.manufactureInitialDate}
                                    {...formik.getFieldProps('manufactureInitialDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('sender')}</label>
                                <Input
                                    disabled
                                    name="reporterName"
                                    type="text"
                                    placeholder={t('sender')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.reporterName}
                                    {...formik.getFieldProps('reporterName')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('data_source')}</label>
                                <Input
                                    disabled
                                    name="dataSource"
                                    type="text"
                                    placeholder={t('data_source')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.dataSource}
                                    {...formik.getFieldProps('dataSource')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Reporter);
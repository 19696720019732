import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { MSDService } from '../../../services/Reconciliation/MSDService';
import YearPicker from '../../../components/Utils/DateRange/YearPicker';

export class MSD extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            maxPage: 0,
            limit: 0,
            query: "",
            year: new Date().getFullYear()
        }
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {

        MSDService.Read(this.state.page, this.state.limit, this.state.query, this.state.params, this.state.year).then(result => {

            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records
            });
        });
    }

    download() {
        var download = MSDService.Download(this.state.params, this.state.year)

        download.then((data) => {
            if (data?.status == 400) {
                toastError(data.message);
            }
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleYearChange(year) {
        this.setState({
            year: year
        }, () => {
            this.refreshList();
        });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }


    render() {

        const { records } = this.state;

        return (
            <>

                <div className="row">
                    <div className="col-md-3" style={{ marginBottom: 15 }}>
                        <ButtonWithIcon
                            text="Download"
                            icon="fa fa-download"
                            onClick={() => this.download()}
                        />
                    </div>
                    <div className="col-md-3">

                    </div>
                    <div className="col-md-3">
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div className="right">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.query}
                                            onChange={(event) => this.handleSearchChange(event)}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <YearPicker
                                value={this.state.year}
                                handlechange={(year) => this.handleYearChange(year)}
                                className="form-control" />
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                    <Table className="pb-5" hover>
                        <thead style={{ textAlign: "center", background: "#F4F5F7" }}>
                            <tr>
                                <th rowspan="3" style={{ verticalAlign: "middle" }}>No</th>
                                <th rowspan="2" style={{ verticalAlign: "middle" }}>Produk</th>
                                <th colspan="12">Month ({this.state.year ? this.state.year : new Date().getFullYear()})</th>
                            </tr>
                            <tr>
                                <th>Jan</th>
                                <th>Feb</th>
                                <th>Mar</th>
                                <th>Apr</th>
                                <th>Mei</th>
                                <th>Jun</th>
                                <th>Jul</th>
                                <th>Agu</th>
                                <th>Sep</th>
                                <th>Okt</th>
                                <th>Nov</th>
                                <th>Des</th>
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {
                                records?.map((record, index) =>

                                    record.vaccineNavigation.name.toLowerCase().includes(this.state.query.toLowerCase()) &&

                                    <tr key={record.id}>
                                        <td>
                                            {index + 1 + (this.state.page - 1) * this.state.limit}
                                        </td>
                                        <td>
                                            {record.vaccineNavigation.name}
                                        </td>
                                        <td>
                                            {record.january}
                                        </td>
                                        <td>
                                            {record.february}
                                        </td>
                                        <td>
                                            {record.march}
                                        </td>
                                        <td>
                                            {record.april}
                                        </td>
                                        <td>
                                            {record.may}
                                        </td>
                                        <td>
                                            {record.june}
                                        </td>
                                        <td>
                                            {record.july}
                                        </td>
                                        <td>
                                            {record.august}
                                        </td>
                                        <td>
                                            {record.september}
                                        </td>
                                        <td>
                                            {record.october}
                                        </td>
                                        <td>
                                            {record.november}
                                        </td>
                                        <td>
                                            {record.december}
                                        </td>
                                        
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

}

import * as XLSX from "xlsx";

export async function convertExcelToArray(file) {
    if (file) {
        let array = [];
        var promise = new Promise(getBuffer(file));

        await promise
            .then(function (data) {
                array = data;

            })
            .catch(function (err) {
                console.log("Error: ", err);
            });
        console.log(array)
        return array;
    } else {
        return null;
    }
}

function getBuffer(file) {
    return function (resolve, reject) {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
            const bufferArray = e.target.result;

            const wb = XLSX.read(bufferArray, { type: "buffer", dateNF: 'mm/dd/yyyy;@', cellDates: true, raw: false, sheetStubs: true });

            const sheetsData = {};
            let listSheet = [1,2,3,4,5,6];
            listSheet.map((item) => {
                let wsname = wb.SheetNames[item];
                const ws = wb.Sheets[wsname];
                sheetsData[wsname] = XLSX.utils.sheet_to_json(ws, { blankRows: true, defval: "" });
            })

            resolve(sheetsData);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    };
}
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import { Loader } from '../../../components/Utils/Loader/Loader';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import Select from 'react-select';

import { KomnasKIPIService } from '../../../services/Reconciliation/KomnasKIPIService';


export class KomnasKIPI extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            params: {},
            loading: false,
            timezone: moment(new Date()).format('Z'),
        }

    }

    componentDidMount() {
        this.refreshList();
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    refreshList() {
        this.handleLoading(true);

        KomnasKIPIService.Read(this.state.page, this.state.limit, this.state.query, this.state.params).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records,
            });
            this.handleLoading(false);
        });
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null ? start.replaceAll("/", "-") + "Z" + this.state.timezone : "";
        let endDate = end !== "" && end !== null ? end.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }    
    
    handleImmunizationDateChange(name, value) {
        let immnunizationDate = value !== "" && value !== null ? value.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, immnunizationDate };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }
    handleOnsetDateChange(name, value) {
        let onsetDate = value !== "" && value !== null ? value.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, onsetDate };
        this.setState({ params: data }, () => {
            this.refreshList();
        });

    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    }

    async download() {
        var download = KomnasKIPIService.Download(this.state.params)

        download.then((data) => {
            if (data?.status == 400) {
                toastError(data.message);
            }
        });
    }

    render() {

        const { records } = this.state;

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }

                {/* Modal List */}


                {/* Content */}

                <div className="tab-content">

                    <div className="row" style={{ marginTop: 15 }}>
                        <div className="col-xs-6 col-sm-6 col-md-2">
                            <div style={{ marginBottom: 15 }}>
                                <ButtonWithIcon
                                    text={`Download`}
                                    icon="fa fa-download"
                                    onClick={() => this.download()}
                                />
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-5">

                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-3">
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2">
                            <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                                <ButtonWithIcon
                                    text="Filter"
                                    icon="fas fa-bars"
                                    onClick={() => this.setState({ filterShow: !this.state.filterShow })}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.filterShow && (
                            <div className="row filter-wrapper" style={{ marginTop: 10, marginBottom: 10 }}>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-md-3" style={{ marginBottom: 10 }}>
                                                <label className="form-label">Pencarian</label>
                                                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control"
                                                                value={this.state.query}
                                                                onChange={(event) => this.handleSearchChange(event)}
                                                                placeholder="Masukkan Nomor Kasus"
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3" style={{ marginBottom: 10 }}>
                                                <label className="form-label">Tanggal Imunisasi</label>

                                                <DatePicker
                                                    name="immunizationDate"
                                                    selectionType="single"
                                                    maxdate={new Date()}
                                                    onChange={(name, value) => this.handleImmunizationDateChange(name, value)}
                                                />
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3" style={{ marginBottom: 10 }}>
                                                <label className="form-label">Tanggal Keluhan</label>

                                                <DatePicker
                                                    name="onsetDate"
                                                    selectionType="single"
                                                    maxdate={new Date()}
                                                    onChange={(name, value) => this.handleOnsetDateChange(name, value)}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        )
                    }

                    <div className="tab-pane show active">
                        <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                            <Table className="pb-5" hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nomor Kasus</th>
                                        <th>Informasi Pasien</th>
                                        <th>Tanggal Imunisasi</th>
                                        <th>Tanggal Keluhan</th>
                                        <th>Keluhan</th>
                                        <th>Vaksin</th>
                                        <th>Kondisi AKhir</th>
                                        <th>Provinsi</th>
                                        <th>Hasil Analisis</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((record, index) =>
                                        <>
                                            <tr key={record.id}>
                                                <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                                <td>{record.caseNumber}</td>
                                                <td>{record.aefiCaseEntryNavigation.patientName + " - " + record.aefiCaseEntryNavigation.patientAgeYear + 'yo' + record.aefiCaseEntryNavigation.patientAgeMonth + 'mo' + " - " + record.aefiCaseEntryNavigation.patientGender}</td>
                                                <td>{record.aefiCaseEntryNavigation.lastImmunizationDate ? moment(record.aefiCaseEntryNavigation.lastImmunizationDate).format('DD/MM/yyyy') : "-"}</td>
                                                <td>{record.aefiCaseEntryNavigation.onsetDate ? moment(record.aefiCaseEntryNavigation.onsetDate).format('DD/MM/yyyy') : "-"}</td>
                                                <td>
                                                    {(record.aefiCaseEntryNavigation.aefiSymptomps?.length > 0 ? record.aefiCaseEntryNavigation.aefiSymptomps.filter(x => x.symptomps != null).map(x => x.symptompsNavigation?.name).join(", ") : "") + " "}
                                                    {(record.aefiCaseEntryNavigation.aefiAllergic?.length > 0 ? record.aefiCaseEntryNavigation.aefiAllergic.filter(x => x.allergic != null).map(x => x.allergicNavigation?.name).join(", ") : "") + " "}
                                                </td>
                                                <td>{record.analysisProduct.filter(x => x.vaccine != null).length > 0 ? record.analysisProduct.filter(x => x.vaccine != null).map(x => { return x.vaccineNavigation.name + "/" + (x.batch ?? " - ") }).join(", ") : "-"}</td>
                                                <td>{record.aefiCaseEntryNavigation.outcomeNavigation?.name ?? "-"}</td>
                                                <td>{record.analysisByProvinceNavigation?.name ?? "-"}</td>
                                                <td>{(record.analysisProduct.filter(x => x.causalityExternal != null).length > 0 ? record.analysisProduct.filter(x => x.causalityExternal != null).map(x => x.causalityExternalNavigation?.name).join(", ") : "") + " "}</td>
                                            </tr>
                                        </>
                                    )}
                                    {
                                        records.length < 1 && (
                                            <tr>
                                                <td colSpan="12" style={{ textAlign: "center" }}>
                                                    Data tidak ditemukan
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <nav>
                            <ReactPaginate
                                previousLabel={(<i class="fas fa-arrow-left"></i>)}
                                previousLinkClassName={"page-link"}
                                nextLabel={(<i class="fas fa-arrow-right"></i>)}
                                nextLinkClassName={"page-link"}
                                pageCount={this.state.maxPage}
                                containerClassName={"pagination justify-content-end"}
                                pageClassName={"page-item"}
                                breakClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                onPageChange={(event) => this.handlePageClick(event)}
                            />
                        </nav>
                    </div>
                </div>
            </>
        );
    }
}
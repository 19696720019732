import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";

import ReportInformation from "./Tab/ReportInformation";
import PatientInformation from "./Tab/PatientInformation";
import AnalysisResult from "./Tab/AnalysisResult";
import LetterInformation from "./Tab/LetterInformation";

import { Role, Status } from "../../../helpers/Constants";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { authenticationService } from "../../../services/AuthService";
import { ProgressReportService } from "../../../services/BPOM/ProgressReportService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { HistoryService } from "../../../services/HistoryService";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";
import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { CaseEntryType } from "../../../helpers/Constants";

import { withTranslation } from "react-i18next";

class DetailProgressReport extends Component {
  constructor(props) {
    super(props);

    const { id, type } = this.props.match.params;

    this.state = {
      id: id,
      type: type === "follow-up" ? true : false,
      snackbaropen: false,
      snackbarmsg: "",
      versionOptions: [],
      isLastVersion: true,
      backTab: "",
      nextTab: "patient-information",
      currentTab: "report-information",
      historyData: [],
      comments: [],
      historys: [],
      historysCaseEntry: [],
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    if (this.state.type) {
      this.prepareFormFollowUp(id);
    } else {
      this.prepareForm(id);
    }
    this.historyList(id, this.props.typecase);
  }

  prepareForm(id) {
    Promise.all([
      DropdownService.MasterList([
        "vaccine",
        "drugs",
        "outcome",
        "letter",
        "bpomProgressCategory",
      ]),
      DropdownService.ProvinceList(62),
      ProgressReportService.PrepareForm(id),
    ]).then(([dropdown, provinceList, bpomProgress]) => {
      this.setState({
        bpomProgressCategoryList: dropdown.data.bpomProgressCategoryList,
        outcomeList: dropdown.data.outcomeList,
        vaccineList: dropdown.data.vaccineList.map((item) => {
          return { value: item.id, label: item.name };
        }),
        drugsList: dropdown.data.drugsList.map((item) => {
          return { value: item.id, label: item.name };
        }),
        provinceList: provinceList.data,
        letterList: dropdown.data.letterList[0],
      });        
        this.historyCaseEntryList(bpomProgress.data.aefiCaseEntry ? bpomProgress.data.aefiCaseEntry : bpomProgress.data.aeCaseEntry)
            
      // get resume data
      if (bpomProgress.data) {          
        if (bpomProgress.data.bpomProgressVaccine.length != 0) {
          bpomProgress.data.vaccines = bpomProgress.data.bpomProgressVaccine;
          bpomProgress.data.resumeProduct =
            bpomProgress.data.bpomProgressVaccine.length != 0
              ? bpomProgress.data.bpomProgressVaccine.map((item) => {
                  return {
                    productName: item.vaccineNavigation?.name,
                    batch: item.batch,
                  };
                })
              : null;
          bpomProgress.data.vaccinesKeys =
            bpomProgress.data.bpomProgressVaccine.length != 0
              ? bpomProgress.data.bpomProgressVaccine.map((item) => {
                  return item.vaccineNavigation?.id;
                })
              : null;
          bpomProgress.data.caseEntry = bpomProgress.data.aefiCaseEntry;
        }
        if (bpomProgress.data.bpomProgressDrugs.length != 0) {
          bpomProgress.data.drugs = bpomProgress.data.bpomProgressDrugs;
          bpomProgress.data.resumeProduct =
            bpomProgress.data.bpomProgressDrugs.length != 0
              ? bpomProgress.data.bpomProgressDrugs.map((item) => {
                  return { productName: item.drugsNavigation?.name };
                })
              : null;
          bpomProgress.data.drugsKeys =
            bpomProgress.data.bpomProgressDrugs.length != 0
              ? bpomProgress.data.bpomProgressDrugs.map((item) => {
                  return item.drugsNavigation?.id;
                })
              : null;
          bpomProgress.data.caseEntry = bpomProgress.data.aeCaseEntry;
        }
        //bpomProgress.data.analysisResult = bpomProgress?.data.bpomProgressAnalysis;
        bpomProgress.data.analysisResult =
          bpomProgress?.data.bpomProgressAnalysis.map((item, index) => {
            return {
              productName: item.productName,
              fieldAnalysisUpv: item.fieldAnalysisUpv,
              fieldAnalysisUpvText: item.fieldAnalysisUpvText,
              fieldAnalysisExt: item.fieldAnalysisExt,
              fieldAnalysisExtText: item.fieldAnalysisExtText,

              causalityAnalysisUpv: item.bpomProgressCausalityAnalysisUpv.map(
                (causality, index) => {
                  return {
                    causalityAnalysis: causality.causalityAnalysis,
                    causalityAnalysisText: causality.causalityAnalysisText,
                  };
                }
              ),

              causalityAnalysisExternal:
                item.bpomProgressCausalityAnalysisExternal.map(
                  (causality, index) => {
                    return {
                      causalityAnalysis: causality.causalityAnalysis,
                      causalityAnalysisText: causality.causalityAnalysisText,
                    };
                  }
                ),
            };
          });

        this.setState({
          caseNumberList: [
            {
              caseNumber: bpomProgress.data.caseNumber,
              id: bpomProgress.data.caseEntry,
            },
          ],
          data: bpomProgress.data,
        });

        var versionOptions = bpomProgress.data.trailBpomProgress
          ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
          .map((trail) => {
            return {
              value: trail.followUp,
              label:
                trail.followUp === 0
                  ? "Inisial"
                  : "Follow Up " + trail.followUp,
            };
          });
        versionOptions.push({
          value: bpomProgress.data.followUp,
          label:
            bpomProgress.data.followUp === 0
              ? "Inisial"
              : "Follow Up " + bpomProgress.data.followUp,
        });

        if (versionOptions) {
          this.setState({
            versionOptions: versionOptions,
            selectedVersion: versionOptions[versionOptions.length - 1],
            lastVersion: versionOptions[versionOptions.length - 1],
            isLastVersion: true,
          });
        }

        let beforeVersion =
          this.state.selectedVersion?.value === 0
            ? 0
            : this.state.selectedVersion?.value - 1;
        ProgressReportService.PrepareForm(id, beforeVersion).then((result) => {
          if (result.data) {
            this.setState({
              historyData: result.data,
            });
          }
        });
      } //bpomProgress.data
      else {
        this.props.history.push({
          pathname: `/report-submission/bpom`,
          state: { tab: "ProgressReport" },
        });
      }
    });
  }

  prepareFormFollowUp(id) {
    Promise.all([
      DropdownService.MasterList([
        "vaccine",
        "drugs",
        "outcome",
        "letter",
        "bpomProgressCategory",
      ]),
      DropdownService.ProvinceList(62),
      ProgressReportService.PrepareForm(id),
    ])
      .then(([dropdown, provinceList, bpomProgress]) => {
        this.setState({
          bpomProgressCategoryList: dropdown.data.bpomProgressCategoryList,
          outcomeList: dropdown.data.outcomeList,
          vaccineList: dropdown.data.vaccineList.map((item) => {
            return { value: item.id, label: item.name };
          }),
          drugsList: dropdown.data.drugsList.map((item) => {
            return { value: item.id, label: item.name };
          }),
          provinceList: provinceList.data,
          letterList: dropdown.data.letterList[0],
          data: bpomProgress.data,
        });
        return Promise.all([
          ProgressReportService.ReadAeFu(1, 0, bpomProgress.data.caseNumber),
          ProgressReportService.ReadAefiFu(1, 0, bpomProgress.data.caseNumber),
          ProgressReportService.ReadAnalysis(
            1,
            0,
            bpomProgress.data.caseNumber
          ),
          ProgressReportService.ReadAefiOverseas(1, 0, ""),
          ProgressReportService.ReadAdrOnline(
            1,
            0,
            bpomProgress.data.caseNumber
          ),
        ]);
      })
      .then(([aeList, aefiList, analysisList, aefiOverseas, adrList]) => {
        var bpData = this.state.data;
        var aefiData = aefiList?.data?.records;
        var aeData = aeList?.data?.records;
        var analysisData = analysisList?.data?.records;
        var aefiOverseasData = aefiOverseas?.data?.records;
        var adrData = adrList?.data?.records;
        var caseEntry;

        if (aefiData.length > 0) {
          caseEntry = aefiData[0];
          bpData.resumeProduct = caseEntry?.aefiVaccine
            ?.filter((item, index) => item.vaccine != null)
            .map((item) => {
              return {
                productName: item.vaccineNavigation?.name,
                batch: item.batch,
              };
            });
          bpData.vaccines = caseEntry?.aefiVaccine
            ?.filter(
              (item, index) =>
                item.vaccine != null &&
                caseEntry.aefiVaccine.findIndex(
                  (x) => x.vaccine === item.vaccine
                ) === index
            )
            .map((item) => {
              return { vaccine: item.vaccine, batch: item.batch };
            });
          bpData.vaccinesKeys = caseEntry?.aefiVaccine
            ?.filter(
              (item, index) =>
                item.vaccine != null &&
                caseEntry.aefiVaccine.findIndex(
                  (x) => x.vaccine === item.vaccine
                ) === index
            )
            .map((item) => {
              return item.vaccine;
            });
          //bpData.vaccines = caseEntry?.aefiVaccine?.filter((item, index) => item.vaccine != null).map(item => { return {vaccine:item.vaccine, batch:item.batch}; });
          //bpData.vaccines = caseEntry?.aefiVaccine;
          bpData.caseType = CaseEntryType.AEFI;
        } else if (aeData.length > 0) {
          caseEntry = aeData[0];
          bpData.resumeProduct = caseEntry?.aeDrugs
            ?.filter((item, index) => item.drugs != null)
            .map((item) => {
              return { productName: item.drugsNavigation?.name };
            });
          bpData.drugs = caseEntry?.aeDrugs
            ?.filter(
              (item, index) =>
                item.drugs != null &&
                caseEntry.aeDrugs.findIndex((x) => x.drugs === item.drugs) ===
                  index
            )
            .map((item) => {
              return item.drugs;
            });
          bpData.drugsKeys = caseEntry?.aeDrugs
            ?.filter(
              (item, index) =>
                item.drugs != null &&
                caseEntry.aeDrugs.findIndex((x) => x.drugs === item.drugs) ===
                  index
            )
            .map((item) => {
              return item.drugs;
            });
          //bpData.drugs = caseEntry?.aeDrugs;
          bpData.caseType = CaseEntryType.AE;
        }
        if (adrData.length > 0) {
          var adrOnline = adrData[0];
          bpData.adrOnline = adrOnline.id;
          bpData.adrOnlineNumber = adrOnline.adronlineNumber;
          bpData.adrOnlineDate = adrOnline.entryDate;
        }
        if (aefiOverseasData.length > 0) {
          var aefiOv = aefiOverseasData[0];
          bpData.letterNumberOverseas = aefiOv.letterNumber;
          bpData.letterOverseasDate = aefiOv.letterDate;
        }
        // replace data with latest caseentry
        bpData.caseEntry = caseEntry?.id;
        bpData.patientName = caseEntry?.patientName;
        bpData.patientAgeYear = caseEntry?.patientAgeYear;
        bpData.patientAgeMonth = caseEntry?.patientAgeMonth;
        bpData.patientGender = caseEntry?.patientGender;

        bpData.productUseDate =
          caseEntry?.lastImmunizationDate ?? caseEntry?.productUseDate ?? "";
        bpData.onsetDate = caseEntry?.onsetDate;
        bpData.outcome = caseEntry?.outcome;
        bpData.diagnosis = caseEntry?.resultConclusion;
        bpData.seriousness = caseEntry?.seriousness;
        if (caseEntry?.patientCountry === 62) {
          bpData.isOverseas = false;
        } else {
          bpData.isOverseas = true;
        }
        if (analysisData.length > 0) {
          var analysis = analysisData[0];
          bpData.analysisBy = analysis?.analysisBy;
          bpData.analyzerProvince = analysis?.analysisByProvince;
          bpData.analysisResumeNeeeded = analysis?.resumeNeeded;

          bpData.analysisResult = analysis?.analysisProduct?.map(
            (item, index) => {
              return {
                productName: item.productName,
                fieldAnalysisUpv: item.causalityUpvNavigation?.id,
                fieldAnalysisUpvText: item.causalityUpvNavigation?.name,
                fieldAnalysisExt: item.causalityExternalNavigation?.id,
                fieldAnalysisExtText: item.causalityExternalNavigation?.name,

                causalityAnalysisUpv: item.analysisCausalityUpv.map(
                  (causality, index) => {
                    return {
                      causalityAnalysis: causality.causalityNavigation?.id,
                      causalityAnalysisText:
                        causality.causalityNavigation?.name,
                    };
                  }
                ),

                causalityAnalysisExternal: item.analysisCausalityExternal.map(
                  (causality, index) => {
                    return {
                      causalityAnalysis: causality.causalityNavigation?.id,
                      causalityAnalysisText:
                        causality.causalityNavigation?.name,
                    };
                  }
                ),
              };
            }
          );
        }
        this.setState({
          caseNumberList: [
            { id: caseEntry?.id, caseNumber: caseEntry?.caseNumber },
          ],
          aefi: aefiData,
          ae: aeData,
          data: bpData,
          analysis: analysisData,
        });
      });
  }

  historyList(id, type) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {      
      HistoryService.Read(id, 'AEFI').then((result) => {
          this.setState({ historysCaseEntry: result.data });          
      });      
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleTabChange(event, tab) {
    const disabledLetter =
      this.state.type ||
      (!(this.state.data?.status === Status.Approved) &&
        !(this.state.data?.status === Status.Closed))
        ? true
        : false;

    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "patient-information",
        });
        break;
      case "#patient-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "patient-information",
          backTab: "report-information",
          nextTab: "analysis-result",
        });
        break;
      case "#analysis-result":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "analysis-result",
          backTab: "patient-information",
          nextTab: disabledLetter ? "" : "letter-information",
        });
        break;
      case "#letter-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "letter-information",
          backTab: "analysis-result",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleReject(role) {
    if (role === Role.Unit) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
  }

  handleAccept(role) {
    if (role === Role.Unit) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        beforeVersion: item.value === 0 ? 0 : item.value - 1,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        ProgressReportService.PrepareForm(
          this.state.id,
          this.state.selectedVersion.value
        ).then(
          (result) => {
            if (result.data) {
              if (this.state.isLastVersion) {
                if (result.data.bpomProgressVaccine.length != 0) {
                  result.data.vaccines = result.data.bpomProgressVaccine;
                  result.data.resumeProduct =
                    result.data.bpomProgressVaccine.length != 0
                      ? result.data.bpomProgressVaccine.map((item) => {
                          return {
                            productName: item.vaccineNavigation?.name,
                            batch: item.batch,
                          };
                        })
                      : null;
                }
                if (result.data.bpomProgressDrugs.length != 0) {
                  result.data.drugs = result.data.bpomProgressDrugs;
                  result.data.resumeProduct =
                    result.data.bpomProgressDrugs.length != 0
                      ? result.data.bpomProgressDrugs.map((item) => {
                          return { productName: item.drugsNavigation?.name };
                        })
                      : null;
                }
                result.data.analysisResult = result.data.bpomProgressAnalysis;

                this.setState({
                  data: result.data,
                });
              } else {
                if (result.data.trailBpomProgressVaccine.length != 0) {
                  result.data.vaccines = result.data.trailBpomProgressVaccine;
                  result.data.resumeProduct =
                    result.data.trailBpomProgressVaccine.length != 0
                      ? result.data.trailBpomProgressVaccine.map((item) => {
                          return {
                            productName: item.vaccineNavigation?.name,
                            batch: item.batch,
                          };
                        })
                      : null;
                }
                if (result.data.trailBpomProgressDrugs.length != 0) {
                  result.data.drugs = result.data.trailBpomProgressDrugs;
                  result.data.resumeProduct =
                    result.data.trailBpomProgressDrugs.length != 0
                      ? result.data.trailBpomProgressDrugs.map((item) => {
                          return { productName: item.drugsNavigation?.name };
                        })
                      : null;
                }
                result.data.analysisResult =
                  result.data.trailBpomProgressAnalysis;
                this.setState({
                  data: result.data,
                });
              }
              ProgressReportService.PrepareForm(
                this.state.id,
                this.state.beforeVersion
              ).then((result) => {
                if (result.data) {
                  this.setState({
                    historyData: result.data,
                  });
                }
              });
            } else {
              this.props.history.push({
                pathname: `/report-submission/bpom`,
                state: { tab: "ProgressReport" },
              });
            }
          },
          (error) => {
            this.props.history.push({
              pathname: `/report-submission/bpom`,
              state: { tab: "ProgressReport" },
            });
          }
        ); //then
      }
    ); //setState
  }

  render() {
    const { t } = this.props;

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };
    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const progress = this.state.data;
    const historydata = this.state.historydata;

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (progress?.status === Status.Pending ||
        progress?.status === Status.WaitingApproval ||
        progress?.status === Status.NotOk ||
        progress?.status === Status.NotApproved ||
        progress?.status === Status.Approved ||
        this.state.type);
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (progress?.status === Status.WaitingApproval ||
        progress?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (progress?.status === Status.WaitingApproval ||
        progress?.status === Status.Reviewed);

    const isDisabledForm =
      this.state.type && this.state.isLastVersion
        ? false
        : !this.state.isLastVersion ||
          progress?.status === Status.Reviewed ||
          progress?.status === Status.Approved;

    const l2 = progress?.letterNumber?.split("/")[1];
    const l4 = progress?.letterNumber?.split("/")[3];
    const l5 = progress?.letterNumber?.split("/")[4];
    let caseSeriousness = progress?.caseNumber
      ? progress?.caseNumber.includes("AEFI")
        ? "KIPI"
        : progress?.caseNumber.includes("PV")
        ? "PV Data"
        : "KTD"
      : "KTD";
    let seriousness =
      caseSeriousness === "KIPI"
        ? progress?.seriousnessNavigation
          ? progress?.seriousnessNavigation.name.includes("Serious")
            ? "Serius"
            : "Tidak Serius"
          : "Tidak Serius"
        : "";

    const initial = {
      caseEntry: progress?.caseEntry,
      aefiCaseEntry: progress?.aefiCaseEntry,
      aeCaseEntry: progress?.aeCaseEntry,
      caseNumber: progress?.caseNumber,
      caseType: progress?.caseType,
      adrOnline: progress?.adrOnline ?? "",
      adrOnlineDate: progress?.adrOnlineDate ?? "",
      adrOnlineNumber: progress?.adrOnlineNumber,
      bpomCaseCategory: progress?.bpomCaseCategory,
      vaccines: progress?.vaccines,
      drugs: progress?.drugs,
      vaccinesKeys: progress?.vaccinesKeys,
      drugsKeys: progress?.drugsKeys,

      isOverseas: progress?.isOverseas,

      letterPlace: progress?.letterPlace,
      letterDate: progress?.letterDate ?? "",
      letterAddress: progress?.letterAddress,
      letterAttachment: progress?.letterAttachment,

      patientName: progress?.patientName,
      patientGender: progress?.patientGender,
      patientAgeYear: progress?.patientAgeYear,
      patientAgeMonth: progress?.patientAgeMonth,

      resumeProduct: progress?.resumeProduct,
      productUseDate: progress?.productUseDate ?? "",
      onsetDate: progress?.onsetDate ?? "",
      keluhan: progress?.keluhan,
      outcome: progress?.outcome,
      seriousness: progress?.seriousness,

      analyzerProvince: progress?.analyzerProvince,
      diagnosis: progress?.diagnosis,
      analysisResult: progress?.analysisResult,
      analysisBy: progress?.analysisBy,
      analysisResumeNeeded: progress?.analysisResumeNeeded,

      letterNumber1: this.state.letterList?.code,
      letterNumber2: progress?.letterNumber?.split("/")[1] ?? "",
      letterNumber3: this.state.letterList?.shortName,
      letterNumber4: progress?.letterNumber?.split("/")[3] ?? "",
      letterNumber5: progress?.letterNumber?.split("/")[4] ?? "",

      letterNumberOverseas: progress?.letterNumberOverseas ?? "",
      letterOverseasDate: progress?.letterOverseasDate ?? "",
      sendDate: progress?.sendDate ?? "",
      status: progress?.status,
      bpomCaseCategoryText: caseSeriousness + " " + seriousness,
      originalRecapLetter: progress?.originalRecapLetterArr ?? [],
    };

    const initialTouched = {
      letterNumber2: true,
      letterNumber4: true,
      letterNumber5: true,
      sendDate: true,
      period: true,
      letterPlace: true,
      letterDate: true,
      letterAddress: true,
      caseNumber: true,
    };

    const validationSchemaDict =
      progress?.status !== Status.Approved
        ? {
            "report-information": Yup.object().shape({
              letterPlace: Yup.string().required("Required").nullable(),
              letterDate: Yup.string().required("Required").nullable(),
              letterAddress: Yup.string().required("Required").nullable(),
              caseNumber: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "report-information": Yup.object().shape({
              letterPlace: Yup.string().required("Required").nullable(),
              letterDate: Yup.string().required("Required").nullable(),
              letterAddress: Yup.string().required("Required").nullable(),
              caseNumber: Yup.string().required("Required").nullable(),
            }),
            "letter-information": Yup.object().shape({
              letterNumber2: Yup.string().required("Required").nullable(),
              letterNumber4: Yup.string().required("Required").nullable(),
              letterNumber5: Yup.string().required("Required").nullable(),
              sendDate: Yup.string().required("Required").nullable(),
            }),
          };

    const disabledLetter =
      !this.state.type &&
      (initial?.status === Status.Approved || initial?.status === Status.Closed)
        ? false
        : true;

    return (
      <>
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}  
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          resume={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          menu="bpomProgress"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          menu="bpomProgress"
          t={t}
        />

        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("report_information")}
                  href="#report-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#report-information")
                  }
                  id="link-report-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("patient_information")}
                  href="#patient-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#patient-information")
                  }
                  id="link-patient-information"
                />
                <Tab
                  number="3"
                  showNumber={true}
                  title={t("analysa_result")}
                  href="#analysis-result"
                  onClick={(event) =>
                    this.handleTabChange(event, "#analysis-result")
                  }
                  id="link-analysis-result"
                />
                {disabledLetter && (
                  <Tab
                    number="4"
                    showNumber={true}
                    title={t("letter_information")}
                    href="#letter-information"
                    onClick={(event) =>
                      this.handleTabChange(event, "#letter-information")
                    }
                    id="link-letter-information"
                    tooltip={t("filled_by_staff")}
                    disabled={disabledLetter}
                  />
                )}
                {!disabledLetter && (
                  <Tab
                    number="4"
                    showNumber={true}
                    title={t("letter_information")}
                    href="#letter-information"
                    onClick={(event) =>
                      this.handleTabChange(event, "#letter-information")
                    }
                    id="link-letter-information"
                    disabled={disabledLetter}
                  />
                )}
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          initialTouched={initialTouched}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            values.isFollowUp = this.state.type;

            if (
              values.letterNumber1 != "" &&
              values.letterNumber2 != "" &&
              values.letterNumber3 != "" &&
              values.letterNumber4 != "" &&
              values.letterNumber5 != ""
            ) {
              values.letterNumber =
                values.letterNumber1 +
                "/" +
                values.letterNumber2 +
                "/" +
                values.letterNumber3 +
                "/" +
                values.letterNumber4 +
                "/" +
                values.letterNumber5;
            } else {
              values.letterNumber = "";
            }
            ProgressReportService.Update(this.state.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/report-submission/bpom`, {
                  tab: "ProgressReport",
                  page: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                {/* Report Information Tab */}

                <ReportInformation
                  formik={formik}
                  page={this.props?.location?.state?.page}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  historydata={this.state.historyData}
                  history={this.props.history}
                  disabledNext={this.state.inisiasiDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  caseNumberList={this.state.caseNumberList}
                  caseCategoryList={this.state.caseCategoryList}
                  bpomProgressCategoryList={this.state.bpomProgressCategoryList}
                  vaccineList={this.state.vaccineList}
                  drugsList={this.state.drugsList}
                  tabType="ProgressReport"
                />

                {/* Patient Information Tab */}

                <PatientInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  historydata={this.state.historyData}
                  history={this.props.history}
                  disabledNext={this.state.inisiasiDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  outcomeList={this.state.outcomeList}
                />

                {/* Analysis Result Tab */}

                <AnalysisResult
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  historydata={this.state.historyData}
                  history={this.props.history}
                  disabledNext={this.state.inisiasiDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  provinceList={this.state.provinceList}
                  causalityAEFIA={this.state.causalityAEFIA}
                  causalityAEFIB={this.state.causalityAEFIB}
                  causalityAEFIC={this.state.causalityAEFIC}
                  causalityAEFID={this.state.causalityAEFID}
                  nextTab={this.state.nextTab}
                />

                {/* Letter Information Tab */}

                <LetterInformation
                  formik={formik}
                  historydata={this.state.historyData}
                  history={this.props.history}
                  disabledNext={this.state.inisiasiDisabled}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                />

                {this.state.nextTab == "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>

                    {/* Simpan */}
                    {canStaffEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          isSubmitting={
                            !this.state.isLastVersion || formik.isSubmitting
                          }
                        />
                      </ToggleDisplay>
                    )}

                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />
                    <input
                      name="isReview"
                      type="hidden"
                      {...formik.getFieldProps("isReview")}
                    />

                    {/* Reject */}
                    {(canUnitEdit || canDivisiEdit) && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            if (canUnitEdit) {
                              formik.setFieldValue("isReview", false);
                            }
                            if (canDivisiEdit) {
                              formik.setFieldValue("isApprove", false);
                            }
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {(canUnitEdit || canDivisiEdit) && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            currentUser.role === Role.Unit
                              ? "Forward"
                              : "Approve"
                          }
                          onClick={() => {
                            if (canUnitEdit) {
                              formik.setFieldValue("isReview", true);
                            }
                            if (canDivisiEdit) {
                              formik.setFieldValue("isApprove", true);
                            }
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailProgressReport);

import React, { Component } from 'react';

import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import Textarea from '../../../../components/Utils/Field/Textarea';

import { AEFIService } from '../../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../../services/CaseEntry/AEService';
import { QualityReviewService } from '../../../../services/QualityReview/QualityReview/QualityReviewService';

import { withTranslation } from "react-i18next";

class CaseInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            caseNumberList : []
        };
    }

    componentDidMount() {
        if(this.props.formType === undefined){
            Promise.all([AEFIService.Read("", 1, 0, "", "", "", "", "", "", "", true), AEService.Read("", 1, 0, "", "", "", "", "", "", "", true)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }else{
            Promise.all([AEFIService.Read("", 1, 0, ""), AEService.Read("", 1, 0, "",)])
            .then(([kipi, ktd]) => {

                this.setState({
                    caseNumberList: [...kipi.data?.records, ...ktd.data?.records],
                });
            });
        }
        
    }

    componentDidUpdate(prevProps, prevState) {

        const { formik, loading } = this.props;

        if(prevProps.formik.values?.caseNumber !== this.props.formik.values?.caseNumber || prevProps.formik.values?.caseEntry !== this.props.formik.values?.caseEntry || prevProps.formik.values?.id !== this.props.formik.values?.id || prevState.caseNumberList !== this.state.caseNumberList){

            if(this.props.formik.values.caseNumber !== "" && this.state.caseNumberList){
                if(this.state.caseNumberList && this.props.formType !== 'edit'){
                    
                    loading(true);

                    let data = [];

                    let index = this.state.caseNumberList.map(function(x) {return x.caseNumber; }).indexOf(this.props.formik.values.caseNumber);
                        data =  this.state.caseNumberList[index];

                    let type = data?.aefiVaccine ? 'aefi' : 'ae';

                    if (data?.id){
                        QualityReviewService.Suggestion(data?.id, type).then(
                            (result) => {
                                
                                let suggestion = result?.data;

                                formik.setFieldValue('bprReview', suggestion?.bprReview ?? '')
                                formik.setFieldValue('bprReviewFile', suggestion?.bprReviewFileArr ?? [])
                                formik.setFieldValue('summaryBatchReview', suggestion?.summaryBatchReview ?? '')
                                formik.setFieldValue('summaryBatchReviewFile', suggestion?.summaryBatchReviewFileArr ?? [])
                                formik.setFieldValue('deathSamplesReview', suggestion?.deathSamplesReview ?? '')
                                formik.setFieldValue('deathSamplesReviewFile', suggestion?.deathSamplesReviewFileArr ?? [])
                                formik.setFieldValue('investigationReview', suggestion?.investigationReview ?? '')
                                formik.setFieldValue('investigationReviewFile', suggestion?.investigationReviewFileArr ?? [])
                                formik.setFieldValue('qualityProductFile', suggestion?.qualityProductFileArr ?? [])

                                loading(false);
                            },
                            (error) => {
                                loading(false);
                            })
                    }else{
                        loading(false);
                    }

                    console.log(data)

                    formik.setFieldValue('caseEntry', data?.id)
                    formik.setFieldValue('patientName', data?.patientName)
                    formik.setFieldValue('patientGender', data?.patientGender)
                    formik.setFieldValue('patientGenderLabel', data?.patientGender === 'F' ? 'Perempuan' : data?.patientGender === 'M' ? 'Laki-laki' : 'Tidak ada data' )
                    formik.setFieldValue('patientAgeYear', data?.patientAgeYear)
                    formik.setFieldValue('patientAgeMonth', data?.patientAgeMonth)
                    formik.setFieldValue('resultChronology', data?.resultChronology)
                    formik.setFieldValue('patientAddress', data?.patientAddress)
                    formik.setFieldValue('patientCountry', data?.patientCountry)
                    formik.setFieldValue('patientCountryLabel', data?.patientCountryNavigation?.name)
                    formik.setFieldValue('patientProvince', data?.patientProvince)
                    formik.setFieldValue('patientProvinceLabel', data?.patientProvinceNavigation?.name)
                    formik.setFieldValue('patientCity', data?.patientCity)
                    formik.setFieldValue('patientCityLabel', data?.patientCityNavigation?.name)
                    formik.setFieldValue('seriousness', data?.seriousness)
                    formik.setFieldValue('seriousnessLabel', data?.seriousnessNavigation?.alias)
                    formik.setFieldValue('seriousnessReason', data?.seriousnessReason)
                    formik.setFieldValue('seriousnessReasonLabel', data?.seriousnessReasonNavigation?.name)
                    formik.setFieldValue('outcome', data?.outcome)
                    formik.setFieldValue('outcomeLabel', data?.outcomeNavigation?.name)
                    formik.setFieldValue('expectedness', data?.expectedness)
                    formik.setFieldValue('expectednessLabel', data?.expectednessNavigation?.name)
                    formik.setFieldValue('qualityProduct', data?.aefiVaccine ?? data?.aeDrugs ?? [])
                }
            }
        }
    }

    render() {

        const { formik, t } = this.props;

        const disabled = this.props.isLastVersion === undefined  ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;
        const isNew = this.props.isLastVersion === undefined ? true : false;

        let filteredCaseNumber = this.state.caseNumberList.reduce(function (filtered, item) {
            if (item.caseNumber) {
                var someNewValue = { value: item.caseNumber, label: item.caseNumber }
                filtered.push(someNewValue);
            }
            return filtered;
        }, []);

        return (
            <div className="tab-pane show active tab-pane-fixed" id="case-information">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('case_number')} <span style={{color: "red"}}>*</span></label>
                                {
                                    this.props.formType === undefined ? (
                                        <Select2
                                            name="caseNumber"
                                            options={isNew ? filteredCaseNumber : this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                            errors={formik?.errors}
                                            touched={formik?.touched}
                                            value={formik?.values?.caseNumber}
                                            onChange={formik?.setFieldValue}
                                            onBlur={formik?.setFieldTouched}
                                            disabled={disabled}
                                        />
                                    ) : (
                                        <Input
                                            disabled
                                            value={formik?.values?.caseNumber}
                                            name="caseNumber"
                                            type="text"
                                            errors={formik?.errors}
                                            touched={formik?.touched}
                                        />
                                    )
                                }
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('patient_name')}</label>
                            <Input
                                name="patientName"
                                type="text"
                                placeholder={t('patient_name')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.patientName}
                                {...formik.getFieldProps('patientName')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('gender')}</label>
                            <Input
                                name="patientGenderLabel"
                                type="text"
                                placeholder={t('gender')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.patientGenderLabel}
                                {...formik.getFieldProps('patientGenderLabel')}
                                disabled
                            />
                        </div>
                        <div>
                            <label className="form-label">{t('age')}</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                name="patientAgeYear"
                                                type="number"
                                                min={0}
                                                placeholder={t('year')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.patientAgeYear ? formik.values?.patientAgeYear : "" }
                                                {...formik.getFieldProps('patientAgeYear')}
                                                disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('year')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                name="patientAgeMonth"
                                                type="number"
                                                min={0}
                                                max={11}
                                                placeholder={t('month')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.patientAgeMonth ? formik.values?.patientAgeMonth : "" }
                                                {...formik.getFieldProps('patientAgeMonth')}
                                                disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('month')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('chronological')}</label>
                            <Textarea
                                disabled
                                rows="8"
                                name="resultChronology"
                                placeholder={t('chronological')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik.values.resultChronology ? formik.values.resultChronology : ""}
                                {...formik.getFieldProps('resultChronology')}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">{t('address')}</label>
                            <Input
                                name="patientAddress"
                                type="text"
                                placeholder={t('address')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('patientAddress')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('country')}</label>
                            <Input
                                name="patientCountryLabel"
                                type="text"
                                placeholder={t('country')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('patientCountryLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('province')}</label>
                            <Input
                                name="patientProvinceLabel"
                                type="text"
                                placeholder={t('province')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('patientProvinceLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('city')}</label>
                            <Input
                                name="patientCityLabel"
                                type="text"
                                placeholder={t('city')}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik.values.patientCityLabel ? formik.values.patientCityLabel : ""}
                                {...formik.getFieldProps('patientCityLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('seriousness')}</label>
                            <Input
                                name="seriousnessLabel"
                                type="text"
                                placeholder={t('seriousness')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('seriousnessLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label"> {t('seriousness_reason')}</label>
                            <Input
                                name="seriousnessReasonLabel"
                                type="text"
                                placeholder={t('seriousness_reason')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('seriousnessReasonLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('expectedness')}</label>
                            <Input
                                name="expectednessLabel"
                                type="text"
                                placeholder={t('expectedness')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('expectednessLabel')}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('outcome')}</label>
                            <Input
                                name="outcomeLabel"
                                type="text"
                                placeholder={t('outcome')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('outcomeLabel')}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(CaseInformation);
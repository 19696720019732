import React, { Component } from 'react';

import Input from '../../../../components/Utils/Field/Input';
import Textarea from '../../../../components/Utils/Field/Textarea';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class QualityReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            causalityEksternalModal : false,
            index : '',
            productList : [],
            fileList : ""
        };
    }

    render() {

        const { formik, t, historydata, currentUser } = this.props;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed && this.props.isLastVersion) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;

        const isNew = this.props.isLastVersion === undefined ? true : false;

        return (
            <div className="tab-pane tab-pane-fixed" id="quality-review">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        <div className="form-group">
                            <label className="form-label">Review BPR</label>
                            <Textarea
                                rows="6"
                                name="Review BPR"
                                placeholder="Revie BPR"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`bprReview`)}
                                disabled
                                same={historydata?.bprReview ? historydata.bprReview === formik.values?.bprReview : true }
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Summary Batch Review</label>
                            <Textarea
                                rows="6"
                                name="summaryBatchReview"
                                placeholder="Summary Batch Review"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`summaryBatchReview`)}
                                disabled
                                same={historydata?.summaryBatchReview ? historydata.summaryBatchReview === formik.values?.summaryBatchReview : true }
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Full Investigation Review</label>
                            <Textarea
                                rows="6"
                                name="investigationReview"
                                placeholder="Full Investigation Review"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`investigationReview`)}
                                disabled
                                same={historydata?.investigationReview ? historydata.investigationReview === formik.values?.investigationReview : true }
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <label className="form-label">{t('distribution_data')}</label>
                        {
                            formik.values?.summaryProductQualityReview?.map((record, index) => 
                                <>
                                    <div className="row" key={index} style={{padding : "5px 1px"}}>
                                        <div className="col-1">
                                                {
                                                    index === 0 && 
                                                    <>
                                                        <label className="form-label" style={{color:"white"}}>No</label>
                                                        <br/>
                                                    </>
                                                }
                                                <span style={{fontSize: 14, paddingTop: 5 }}>{index+1}</span>
                                        </div>
                                        <div className="col-6" style={{padding: "5px 1px"}}>
                                                {
                                                    index === 0 && 
                                                    <label className="form-label">{t('product_name')}</label>
                                                }
                                                <Input
                                                    disabled
                                                    name="productSubmission"
                                                    value={record.productName}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                />
                                            
                                        </div>
                                        <div className="col-5" style={{padding : "5px 1px"}}>
                                            
                                                {
                                                    index === 0 && 
                                                    <label className="form-label">{t('batch_number')}</label>
                                                }
                                                <Input
                                                    disabled
                                                    name="productBacth"
                                                    value={record.batch}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                />
                                            
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            (formik.values?.summaryProductQualityReview === undefined || formik.values?.summaryProductQualityReview === null || formik.values?.summaryProductQualityReview?.length === 0) && (
                                <div style={{textAlign: "center"}}>
                                    {t('data_not_found')}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(QualityReview);
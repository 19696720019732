import React, { Component } from 'react';
import Select from 'react-select'
import { Line, Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { ConfirmationModal } from './ConfirmationModal'
import { ProgressBar } from './Progressbar'
import { authenticationService } from '../../services/AuthService';
import { DashboardService } from '../../services/Dashboard/DashboardService';
import { DropdownService } from "../../services/Master/AllDropdownService";

import { Loader } from '../../components/Utils/Loader/Loader';

import { withTranslation } from "react-i18next";
import moment from 'moment';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { FilterTotalGraphModal } from './FilterTotalGraphModal';
import CustomTooltip from '../../components/Utils/Tooltip/Tooltip';
import { Role } from '../../helpers/Constants';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            signatureConfirmModal : (this.props.location?.state?.new) ? true : false,
            elementSpan : '',
            graphType : 'AEFI',
            periodStatus: 'week',
            typeProduct : 'vaccine',
            caseType: 'AEFI',
            caseCategory: '',
            caseCategoryName: '',
            report : '',
            graph : [],
            graphv2 : [],
            status : '',
            causalities : [],
            product : [],
            paramGraph: {
                caseType : 'AEFI',
                YearType: {value: "Reported", label: "Tahun Pelaporan"},
                startDate: '',
                endDate: '',
                RegionType: {value: "", label: ""},
                CaseCategory: {value:'', label:''},
                Product: {value:'', label:''}
            },
            currentParamGraph: {
                caseType: "AEFI",
                YearType: { value: "Reported", label: "Tahun Pelaporan" },
                startDate: "",
                endDate: "",
                RegionType: { value: "", label: "" },
                CaseCategory: { value: "", label: "" },
                Product: { value: "", label: "" },
              },
            timezone: moment(new Date()).format("Z"),
            filterShow: false,
            caseCategories: [],
            mostReport: [],
            loading: true,
            loadingCausalities: true,
            loadingMostReported: true,
        }
        
        this.refreshList();
        this.getCausalities();
        this.getCaseCategories("aefiCategory");
        this.getMasterList();
        this.getMostReported();
    }

    componentDidMount() {
        document.title = "Dashboard"

        this.setState({signatureConfirmModal : (this.props.location?.state?.signature) ? true : false})
    }

    handleSignature = () => {
        this.props.history.push({pathname: `/profile`, state: { new : true }})
    }

    handleGraphChange(item) {
        this.setState({ paramGraph: { ...this.state.paramGraph, caseType: item? item.value : "AEFI", CaseCategory: {value: "", label: ""}} }, () => {
            this.refreshList();
        });
    }

    handleCaseCategoryChange(item) {

        this.setState({
            caseCategory: item.value ?? "",
            caseCategoryName: item?.label
        }, () => {
            this.getCausalities();
        });
    }

    handleCaseTypeChange(item) {
        
        this.setState({
            caseType: item?.value
        }, () => {
            this.getCaseCategories(item?.value === "AE" ? "aeCategory" : "aefiCategory");
        });
    }

    handleTimeChange(item) {        
        this.setState({ periodStatus: item.value ?? "" }, () => {
            this.refreshList();
        });
    }

    handleProductChange(item) {
        this.setState({ typeProduct: item.value ?? "" }, () => {
            this.getMostReported();
        });
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    handleSpanClick = (id, event) => {
        if (this.state.elementSpan === id) {
            //If collapsiable is already visible and clicked on same then this will hide it
            this.setState({ elementSpan: '' })
        } else {
            //To show collapsiable
            this.setState({ elementSpan: id })
        }
    }

    getMasterList(){
        DropdownService.MasterList(["aeCategory","aefiCategory","vaccine","drugs"]).then(
            (result)=> {
                this.setState({ list: result.data });
            }
        )
    }   

    refreshList = () => {

        this.handleLoading(true);
        Promise.all([
            DashboardService.ReadReport(),
            DashboardService.ReadGraph(this.state.graphType),
            DashboardService.ReadGraphV2(this.state.paramGraph),
            DashboardService.ReadTotalCase(),
        ]).then(([report, graph, graphv2, totalCase]) => {
                this.setState({
                    report : report?.data,
                    graph : graph?.data,
                    graphv2: graphv2?.data,
                    totalCase: totalCase?.data,
                });
                this.handleLoading(false);
            }
        )

    }

    getCausalities = () => {
        this.setState({ loadingCausalities: true });
        DashboardService.ReadCausalities(
            { caseType: this.state.caseType, caseCategory: this.state.caseCategory }
        ).then((causalities) => {
                this.setState({
                    causalities : causalities?.data,
                });
                this.setState({ loadingCausalities: false });
            }
        );
    }

    getMostReported = () => {
        this.setState({ loadingMostReported: true });
        DashboardService.ReadProduct(
            this.state.typeProduct
        ).then((product) => {
                this.setState({
                    product : product?.data,
                });
                this.setState({ loadingMostReported: false });
            }
        );
    }

    getCaseCategories(type) {
    
        DropdownService.MasterList([type]).then((result) => {
            let listCategories = [];
            let defaultCategory = {};
            
            if (type === "aeCategory") {
                listCategories = result.data.aeCategoryList
                    ?.filter((item) => item?.precode !== "PV")
                    ?.map((item) => {
                        return { 
                            value: item.id,
                            label: item.name,
                            precode: item.precode
                        };
                    });
                defaultCategory = listCategories?.find((item) => item?.label === "AE");
            } else {
                listCategories = result.data.aefiCategoryList
                    ?.filter((item) => item?.precode !== "PV")
                    ?.map((item) => {
                        return { 
                            value: item.id,
                            label: item.name,
                            precode: item.precode
                        };
                    });
                defaultCategory = listCategories?.find((item) => item?.label === "AEFI");
            };


            this.setState({
                caseCategories: listCategories,
                caseCategory: defaultCategory?.value,
                caseCategoryName: defaultCategory?.label,
            });

            this.getCausalities();
        });
    }

    handleColor = (index) => {
        switch (index) {
            case 0:
                return '#A646EF';
                break;
            case 1:
                return '#F2B44D';
                break;
            case 2:
                return '#538AC9';
                break;
            case 3:
                return '#EB4A8A';
                break;
            case 4:
                return '#00abbd';
                break;
            case 5:
                return '#abebd0';
                break;
            default:
                return '#A646EF'
        }
    }
    goToOpen = () => {
        this.props.history.push(`/case-entry?filter=Open`)
    }

    goToDraf = () =>{
        this.props.history.push(`/case-entry?filter=Draft`)
    }

    goToSedangDianalisis = () => {
        this.props.history.push(`/medical-review/analysis?filter=sedang-dianalisis`)
    }

    goToSudahDianalisis = () => {
        this.props.history.push(`/medical-review/analysis?filter=sudah-dianalisis`)
    }

    goToClosed = () => {
        this.props.history.push(`/report-submission/bpom?filter=closed`)
    }

    render() {

        const { report, graph, status, causalities, product, graphv2, totalCase } = this.state;

        const { t, i18n } = this.props;

        const currentUser = authenticationService.currentUserValue;

        const graphOption = t('graph_option', { returnObjects: true })

        const periodOption = t('period_option', { returnObjects: true })

        const productOption = t('product_option', { returnObjects: true })
        
        const caseCategoryList = this.state.graphType === 'AEFI'? 
        this.state.list?.aefiCategoryList?.map((item)=> {
            return { value: item.id, label: item.name}
        }) : this.state.list?.aeCategoryList?.map((item)=> {
            return { value: item.id, label: item.name}
        })

        const productList = this.state.graphType === 'AEFI'?
        this.state.list?.vaccineList?.map((item)=>{
            return { value: item.id, label: item.name}
        }): this.state.list?.drugsList?.map((item)=>{
            return { value: item.id, label: item.name }
        })

        let total = 0;

        causalities.map((value, index) => {
            total = total + value.dataCount;
        })

        // Data for Graphic seriousnes KIPI and KTD

        let tahun = [];
        let data_serious = [];
        let data_non_serious = []
        let data_pv = [];
        let data_cluster = [];

        graphv2.map((value, index) => {
            tahun = [...tahun, value.year];
            data_non_serious = [...data_non_serious, {x : value.year, y : value.nonSeriousCount}];
            data_serious = [...data_serious, {x : value.year, y : value.seriousCount }];
            data_pv = [...data_pv, {x: value.year, y: value.pvDataCount }];
            data_cluster = [...data_cluster, {x: value.year, y: value.clusterDataCount}];
        });

        const data = {
            labels: tahun,
            datasets: [
              {
                label: "Serious",
                data: data_serious,
                fill: false,
                borderColor: "#E15756"
              },
              {
                label: "Non Serious",
                data: data_non_serious,
                fill: false,
                borderColor: "#00BD6B"
              },
              {
                label: "Cluster Data",
                data: data_cluster,
                fill: false,
                borderColor: "#F2B44D"
              },
              {
                label: "PV Data",
                data: data_pv,
                fill: false,
                borderColor: "#02367B"
              }
            ],
            options: {
                scales: {
                  yAxes: [{
                    display: true
                  }],
                },
                plugins: {
                    datalabels: {
                        display: false,  // Hide data labels
                    }
                },
            },
        }
    
        const legend = {
            display: false,
        };
    
        const options = {
            scales: {
              yAxes: [
                {
                  display: true
                }
              ]
            },
            plugins: {
                datalabels: {
                    display: false,  // Hide data labels
                }
            },
        };
        // Data for most used product 

        let product_label = [];
        let total_product = [];

        const chartColors = [
            "#E15756",
            "#A646EF",
            "#F2B44D",
            "#00ABBD",
            "#2CCD8966",
            "#EB4A8A",
            "#6D8E92",
            "#FF6F61",
            "#6B5B95",
            "#88B04B" 
        ];

        product.map((value, index) => {
            product_label = [...product_label, value.name];
            total_product = [...total_product, value.dataCount];
        });

        const data_product = {
            labels: product_label,
            datasets: [
                {
                    data: total_product,
                    backgroundColor: chartColors,
                }
            ]
        }

        const options_product = {
            plugins: {
                datalabels: {
                    display: false,  // Hide data labels
                }
            },
            cutoutPercentage: 80,
            rotation: 120,
            legendCallback: (chart) => {
                const renderLabels = (chart) => {
                  const { data } = chart;
                  return data.datasets[0].data
                    .map(
                      (_, i) =>
                        `<li>
                            <div id="legend-${i}-item" class="legend-item">
                              <span style="background-color:
                                ${data.datasets[0].backgroundColor[i]}">
                                &nbsp;&nbsp;&nbsp;&nbsp;
                              </span>
                              ${
                                data.labels[i] &&
                                `<span class="label">${data.labels[i]}: $${data.datasets[0].data[i]}</span>`
                              }
                            </div>
                        </li>
                      `
                    )
                    .join("");
                };
                return `
                  <ul class="chartjs-legend">
                    ${renderLabels(chart)}
                  </ul>`;
              },
              responsive: true
        };

        const legend_product = {
            display: false,
            position: 'bottom',
            align: 'start',
            fullWidth: true,
            fullSize: true,
            maxWidth: 100,
        };

        const circle = {
            background:"#009CAC4D", 
            borderRadius: "50%", 
            width:30, 
            height:30,
            alignItems: "center",
            textAlign: "center",
            paddingTop: 2
        }

        const titleCard = {
            fontSize: 18, 
            color: "#495057", 
            fontWeight: "bolder", 
            marginBottom: 15,
            marginTop: 15
        }

        const titleReport = {
            fontSize: 14, 
            color: "#495057", 
            fontWeight: "bolder", 
            marginBottom: 2
        }

        const subTitleReport = {
            fontSize: 20, 
            color: "#00ABBD", 
            fontWeight: "bolder", 
            marginBottom: 0
        }

        let caseTypeString = graphOption.find((option) => option.value === this.state.paramGraph.caseType).label
        let caseCategoryString = this.state.paramGraph.CaseCategory.label != "" ? (this.state.paramGraph?.CaseCategory.label.toLowerCase() !== 'pv data' ? " "+this.state.paramGraph?.CaseCategory.label.split(" ").slice(1).join(" ") : " "+this.state.paramGraph?.CaseCategory.label+" ") : ""
        let productString = this.state.paramGraph.Product.label != ""? " pada "+ (this.state.paramGraph.caseType == 'AEFI' ? "Vaksin " + this.state.paramGraph.Product.label : "Obat " + this.state.paramGraph.Product.label) : ""
        let yearTypeString = " berdasarkan "+ this.state.paramGraph.YearType.label
        let regionString = this.state.paramGraph.RegionType.label != ""? " yang terjadi di "+ this.state.paramGraph.RegionType.label : ""
        let dateRangeString = this.state.paramGraph.startDate !== ""? " di Periode "+ moment(this.state.paramGraph.startDate).format("DD/MM/YYYY") + " - " + moment(this.state.paramGraph.endDate).format("DD/MM/YYYY") : ""
        let stringLabelGraphTotal = caseTypeString+caseCategoryString+productString+yearTypeString+dateRangeString+regionString

        return (
            <>  
                {
                    this.state.loading && (
                        <Loader color="#00ABBD"/>
                    )
                }
                {
                    this.state.loadingCausalities && (
                        <Loader color="#00ABBD"/>
                    )
                }
                {
                    this.state.loadingMostReported && (
                        <Loader color="#00ABBD"/>
                    )
                }
                <ConfirmationModal
                    show={this.state.signatureConfirmModal}
                    onhide={() => this.setState({signatureConfirmModal: false})}
                    confirm={this.handleSignature}
                    t={t}
                />
               <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <div className="card" style={{marginBottom: 30, marginTop: 80, borderRadius: 15}}>
                            <div className="card-body" style={{padding: "15px 20px"}}>
                                <div className="row">
                                    <div className="col-8" style={{paddingRight: 5}}>
                                        <p style={{fontSize: 16, color: "#495057", fontWeight: "bolder", marginTop: 20}}>Halo, { currentUser?.name }</p>
                                        <p style={{fontSize: 14, color: "#ADB5BD"}}>{t('welcome')}</p>
                                    </div>
                                    <div className="col-4" style={{paddingLeft: 5}}>
                                        <img src='../assets/images/dashboard/Dashboard.png' alt="user" style={{marginTop: "-60px", width: "205px"}} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 30}}>
                            <div className="col-md-4">
                                <div className="card" style={{marginBottom: 10, borderRadius: 15}}>
                                    <div className="card-body" style={{padding: "15px 20px"}}>
                                        <div className="row">
                                            <div className="col-3">
                                                <div style={circle}>
                                                    <img src='../assets/images/dashboard/File.png' alt="user" style={{width: "13px"}} />
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <p style={titleReport}>
                                                    {`${t('aefi_report')} ${moment().format("YYYY")}`}
                                                </p>
                                                <p style={subTitleReport}>{report?.aefiCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{marginBottom: 10, borderRadius: 15}}>
                                    <div className="card-body" style={{padding: "15px 20px"}}>
                                        <div className="row">
                                            <div className="col-3">
                                                <div style={circle}>
                                                    <img src='../assets/images/dashboard/File.png' alt="user" style={{width: "13px"}} />
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <p style={titleReport}>
                                                    {`${t('ae_report')} ${moment().format("YYYY")}`}
                                                </p>
                                                <p style={subTitleReport}>{report?.aeCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card" style={{marginBottom: 10, borderRadius: 15}}>
                                    <div className="card-body" style={{padding: "15px 20px"}}>
                                        <div className="row">
                                            <div className="col-3">
                                                <div style={circle}>
                                                    <img src='../assets/images/dashboard/File.png' alt="user" style={{width: "13px"}} />
                                                </div>
                                            </div>
                                            <div className="col-9">
                                                <p style={titleReport}>
                                                    {`PV DATA ${moment().format("YYYY")}`}
                                                </p>
                                                <p style={subTitleReport}>{report?.pvDataCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card" style={{marginBottom: 25}}>
                            <div className="card-body" style={{padding: "15px 20px"}}>
                                <div style={{ display:"flex"}}>
                                    <p style={titleCard}>{t('graph_total')}</p>
                                    <div
                                        className="col-xs-6 col-sm-6 col-md-2"
                                        style={{ marginLeft: "auto" }}>
                                        <div style={{ width: "100%", marginBottom: 15 }}>
                                            <ButtonWithIcon
                                            text="Filter"
                                            icon="fas fa-bars"
                                            onClick={() =>
                                                {
                                                    this.setState({ 
                                                        filterShow: !this.state.filterShow 
                                                    })
                                                    this.setState({ currentParamGraph: this.state.paramGraph })
                                                }
                                            }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: "flex", width:"100%"}}>
                                    <p>{stringLabelGraphTotal}</p>
                                </div>
                                <div style={{marginTop: 20}}>
                                    <Line data={data} legend={legend} options={options} height="100px"/>
                                </div>
                                <div>
                                    <div style={{maxWidth: "70%", margin: "20px auto"}}>
                                        <div className="row">
                                            <div className="col-3">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #00BD6B", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>Non Serious</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #E15756", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>Serious</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #F2B44D", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>Cluster Data</div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div style={{display:"flex"}}>
                                                    <div style={{width: 15, height: 15, borderRadius: "50%", border: "solid 3px #02367B", marginTop: 4, marginRight: 5}}/>
                                                    <div style={{fontSize: 14}}>PV Data</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p style={titleCard}>
                                {`${t('causality')} ${this?.state?.caseCategoryName}`}
                            </p>
                            <div className="d-flex justify-content-end mb-3">
                                <div className="mx-2" style={{minWidth: 180}}>
                                    <Select
                                        options={graphOption}
                                        placeholder={t('case_category')}
                                        width='100%'
                                        name="caseType"
                                        value={graphOption.filter(option => option.value === this.state.caseType)}
                                        classNamePrefix="select"
                                        className="basic-single"
                                        onChange={(item) => this.handleCaseTypeChange(item)}
                                    />
                                </div>
                                <div className="mx-2" style={{minWidth: 180}}>
                                    <Select
                                        options={this?.state?.caseCategories}
                                        placeholder='Kategori'
                                        width='100%'
                                        name="caseCategory"
                                        value={this?.state?.caseCategories?.filter(option => option.value === this.state.caseCategory)}
                                        classNamePrefix="select"
                                        className="basic-single"
                                        onChange={(item) => this.handleCaseCategoryChange(item)}
                                    />
                                </div>
                            </div>

                            {causalities?.map((value, index) => 
                                <div className="card" style={{marginBottom: 10}}>
                                    <div className="card-body" style={{padding: "15px 20px"}}>
                                        <div className="row">
                                            <div className="col-9">
                                                <p style={titleReport}>{value?.name}</p>
                                            </div>
                                            <div className="col-3">
                                                <div style={{float: "right", display: "flex"}}>
                                                    <div className="icon-progress-bar" style={{background: this.handleColor(index)}}>{value?.dataCount}</div>
                                                    {
                                                        value.child?.length > 0 ? (
                                                            <div style={{cursor: "pointer", marginLeft: 15}} onClick={(e) => this.handleSpanClick(index, e)}>
                                                                <FontAwesomeIcon icon={this.state.elementSpan === index ? faChevronUp : faChevronDown} />
                                                            </div>
                                                        ) : (
                                                            <div style={{marginLeft: 25}}/>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.caseType === "AEFI" && this.state.elementSpan === index ? (
                                                <>
                                                    {
                                                        value?.child?.map((item, i) => 
                                                            <>
                                                                <ProgressBar 
                                                                    title = {item.name}
                                                                    value = {item.dataCount}
                                                                    percent = {`${(item.dataCount !== 0 && total !== 0 ? ((item.dataCount/value?.dataCount)*100) : 0)}%`}
                                                                    color = {this.handleColor(index)}
                                                                />
                                                            </>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <ProgressBar 
                                                        title = " "
                                                        value = " "
                                                        percent = {`${(value?.dataCount !== 0 && total !== 0 ? ((value?.dataCount/total) * 100) : 0)}%`}
                                                        color = {this.handleColor(index)}
                                                    />
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className="card" style={{marginBottom: 30, marginTop: 80, borderRadius: 15}}>
                            <div className="card-body" style={{padding: "15px 20px"}}>
                                <p style={titleCard}>{t('case_total')}</p>
                                <div
                                    className="row"
                                    onClick={currentUser.role === Role.Staff && this.goToDraf}
                                    style={{cursor : "pointer"}}
                                >
                                    <div className="col-8">
                                        <div style={{display:"flex"}}>
                                            <CustomTooltip title="Draf" message={t('draf_description')} src="../assets/images/dashboard/Pending.png">
                                                <div className="icon-progress-bar" style={{background: '#7180964D', width: 40, height: 40, borderRadius: 15 }}>
                                                    <img src='../assets/images/dashboard/Pending.png' alt="user" style={{width: "22px", marginTop: 3}} />
                                                </div>
                                            </CustomTooltip>
                                            <div style={{marginLeft : 10, marginTop: 10}}>Draf</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div style={{float: "right"}}>
                                            <p style={subTitleReport}>{totalCase?.draft}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row" onClick={this.goToOpen} style={{cursor : "pointer"}}>
                                    <div className="col-8">
                                        <div style={{display:"flex"}}>
                                            <CustomTooltip title="Open" message={t('open_description')} src="../assets/images/dashboard/Open.png">
                                                <div className="icon-progress-bar" style={{background: '#00ABBD4D', width: 40, height: 40, borderRadius: 15 }}>
                                                    <img src='../assets/images/dashboard/Open.png' alt="user" style={{width: "22px", marginTop: 3}} />
                                                </div>
                                            </CustomTooltip>
                                            <div style={{marginLeft : 10, marginTop: 10}}>Open</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div style={{float: "right"}}>
                                            <p style={subTitleReport}>{totalCase?.open}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row" onClick={this.goToSedangDianalisis} style={{cursor : "pointer"}}>
                                    <div className="col-8">
                                        <div style={{display:"flex"}}>
                                            <CustomTooltip title={t('analyzing')} message={t('analyzing_description')} src="../assets/images/dashboard/Analisis.png">
                                                <div className="icon-progress-bar" style={{background: '#F2B44D33', width: 40, height: 40, borderRadius: 15 }}>
                                                    <img src='../assets/images/dashboard/Analisis.png' alt="user" style={{width: "22px", marginTop: 3}} />
                                                </div>
                                            </CustomTooltip>
                                            <div style={{marginLeft : 10, marginTop: 10}}>{t('analyzing')}</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div style={{float: "right"}}>
                                            <p style={subTitleReport}>{totalCase?.analyzing}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row" onClick={this.goToSudahDianalisis} style={{cursor : "pointer"}}>
                                    <div className="col-8">
                                        <div style={{display:"flex"}}>
                                            <CustomTooltip title={t('analyzed')} message={t('analyzed_description')} src="../assets/images/dashboard/Analyzed.png">
                                                <div className="icon-progress-bar" style={{background: '#00BD6B4D', width: 40, height: 40, borderRadius: 15 }}>
                                                    <img src='../assets/images/dashboard/Analyzed.png' alt="user" style={{width: "22px", marginTop: 3}} />
                                                </div>
                                            </CustomTooltip>
                                            <div style={{marginLeft : 10, marginTop: 10}}>{t('analyzed')}</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div style={{float: "right"}}>
                                            <p style={subTitleReport}>{totalCase?.analyzed}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="row" onClick={this.goToClosed} style={{cursor : "pointer"}}>
                                    <div className="col-8">
                                        <div style={{display:"flex"}}>
                                            <CustomTooltip title="Closed" message={t('closed_description')} src="../assets/images/dashboard/Closed.png">
                                                <div className="icon-progress-bar" style={{background: '#003FBD4D', width: 40, height: 40, borderRadius: 15 }}>
                                                    <img src='../assets/images/dashboard/Closed.png' alt="user" style={{width: "22px", marginTop: 3}} />
                                                </div>
                                            </CustomTooltip>
                                            <div style={{marginLeft : 10, marginTop: 10}}>Closed</div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div style={{float: "right"}}>
                                            <p style={subTitleReport}>{totalCase?.closed}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card" style={{marginBottom: 30, marginTop: 80, borderRadius: 15}}>
                            <div className="card-body" style={{padding: "15px 20px"}}>
                                <p style={titleCard}>{t('most_reported')}</p>
                                <div className="chart-wrapper" style={{width:"100%"}}>
                                    <div style={{maxWidth: 140}}>
                                        <Select
                                            options={productOption}
                                            placeholder=''
                                            width='100%'
                                            name="typeProduct"
                                            value={productOption.filter(option => option.value === this.state.typeProduct)}
                                            isClearable="true"
                                            classNamePrefix="select"
                                            className="basic-single"
                                            onChange={(item) => this.handleProductChange(item)}
                                        />
                                    </div>
                                    <div className="chart-wrapper" style={{width:"100%", padding: "15px 20px"}}>
                                        <Doughnut 
                                            className="chartjs-legend"
                                            data={data_product} 
                                            legend={legend_product}
                                            options= {options_product}
                                            width={120}
                                        />

                                        <div style={{display: 'inline-grid', justifyItems: 'left'}}>
                                            {data_product?.labels?.map((label, indexLabel) => (
                                                <div className='row w-100' key={indexLabel} style={{ marginBottom: '10px'}}>
                                                    <div className='col-md-2 col-sm-2' style={{minWidth: '50px'}}>
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '40px',
                                                                height: '10px',
                                                                backgroundColor: data_product?.datasets[0].backgroundColor[indexLabel],
                                                                marginRight: '10px',
                                                            }}
                                                        ></span>
                                                    </div>
                                                    <div className='col-md col-sm'>
                                                        <span>{label}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
               <FilterTotalGraphModal
                 show={this.state.filterShow}
                 t={t}
                 onHide={()=> this.setState({ filterShow: false})}
                 caseCategoryList={caseCategoryList}
                 productList={productList}
                 onChangeCaseType={(e)=>{this.setState({ graphType: e? e.value: 'AEFI' })}}
                 onConfirm={(e)=>{
                    this.setState({ paramGraph: e}, () => {
                        this.refreshList();
                    })
                }}
                currentParamGraph={this.state.currentParamGraph}
                onSetCurrentParamGraph={(e)=>{this.setState({currentParamGraph: e})}}
               />
            </>
        );
    }
}

export default withTranslation()(Dashboard);

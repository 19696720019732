import React, {Component} from 'react';
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css';
import { ErrorMessage } from 'formik';

class List extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mrange: {from: {year: null, month: null}, to: {year: null, month: null}},
        }

        this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
        this.handleAMonthChange = this.handleAMonthChange.bind(this)
        this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            mrange: nextProps.value !== "" && nextProps.value !== null && nextProps.value !== undefined ? nextProps.value : {from: {year: null, month: null}, to: {year: null, month: null}},
        })
    }

    render() {

        const pickerLang = {
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            from: 'From', to: 'To',
        }
        const mrange = this.state.mrange

        const makeText = m => {
            if (m && m.year && m.month) return (pickerLang.months[m.month-1] + '. ' + m.year)
            if (m && m.from?.month && m.to?.month) return (pickerLang.months[(m.from.month)-1] + ' ' + m.from?.year + ' - ' + pickerLang.months[(m.to.month)-1] + ' ' + m.to?.year )
            return ''
        }

        const error = this.props.errors && this.props.name ? this.props.errors[this.props.name] : "";
        const touched = this.props.touched && this.props.name ? this.props.touched[this.props.name] : "";

        return (
            <div className="form-group" style={{position:"relative"}}>
                <input 
                    className={'form-control ' + this.props.className + ( error && touched ? ' is-invalid' : '')}
                    value={makeText(mrange)} 
                    onClick={this.props.disabled ? "" : this.handleClickMonthBox}
                    disabled={this.props.disabled}
                />
                {
                    touched && error && (
                        <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" style={{textAlign:"left"}} />
                    )
                }
                <div className="edit">
                    <Picker
                        ref="monthPick"
                        value={mrange}
                        lang={pickerLang.months}
                        onChange={this.handleAMonthChange}
                        onDismiss={this.handleAMonthDissmis}
                        
                    >
                    </Picker>
                </div>
            </div>
        )
    }

    handleClickMonthBox(e) {
        this.refs.monthPick.show()
    }
    handleAMonthChange(value, text) {

        // 
    }
    handleAMonthDissmis(value) {

        let monthBegin =  value?.from.month < 10 ? ("0" + value?.from.month) : value?.from.month;
        let monthEnd = value?.to.month < 10 ? ("0" + value?.to.month) : value?.to.month;
        
        // Get last day for a month
        let dayEnd = new Date(value?.to.year, monthEnd, 0).getDate();

        this.props.onChange(this.props.name+"Begin", value?.from.year + "/"+ monthBegin + "/" +"01" ?? "");
        this.props.onChange(this.props.name+"End", value?.to.year + "/" + monthEnd + "/" + dayEnd ?? "");
        this.props.onChange(this.props.name, value ?? "");
        this.setState({mrange: value})
    }
}


export default List;

import React, { Component } from 'react';

import { withTranslation } from "react-i18next";

class MultipleChoice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowSize: "",
            isMobile: false,
            title: ""
        };
    }
    
    isMobile = () => window.innerWidth <= 992;

    handleChange(e) {
        
        this.props.onChange(this.props.name, e.target.value);
    }

    render() {

        const type = this.props.type === "choice" ? this.props.type : "multiple"
        const showchoice = this.props.showchoice ? this.props.showchoice : "show"
        const isSame = this.props.same;

        const {t} = this.props;

        return (
            <>
                {
                    this.props.title && (
                        <div className="multiple-choice-form">
                            <p style={{fontWeight:"bold", marginBottom: 0}}>{this.props.title}</p>
                        </div>
                    )
                }
                <div className="multiple-choice-form">
                    <div className={isSame !== undefined ? (isSame === false ? "row change-value-radio" : "row") : "row"}>
                        <div className="col-sm-7 col-md-10">
                            <p style={{marginBottom: 0}}>{this.props.question}</p>
                        </div>
                        <div className="col-sm-5 col-md-2">
                            {
                                type === "multiple" && showchoice === "show" && (
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Yes" name={this.props.name}
                                                checked={this.props.value === "Yes"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="No" name={this.props.name}
                                                checked={this.props.value === "No"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="TD" name={this.props.name}
                                                checked={this.props.value === "TD"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="NA" name={this.props.name}
                                                checked={this.props.value === "NA"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                        </div>
                                    </div>
                                ) 
                            }

                            {
                                type === "choice" && showchoice === "show" &&  (
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="Yes" name={this.props.name}
                                                checked={this.props.value === "Yes"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                            <label className="form-check-label">{t('yes')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="No" name={this.props.name}
                                                checked={this.props.value === "No"}
                                                onChange={(e)=>this.handleChange(e)} 
                                                disabled={this.props.disabled}
                                            />
                                            <label className="form-check-label">{t('no')}</label>
                                        </div>
                                    </div>
                                )
                            }
                            
                        </div>
                    </div>
                    {
                        this.props.children && (
                            <div className="row" style={{marginTop: 10}}>
                                <div className="col-6">
                                    {this.props.children}
                                </div>
                            </div>
                        )
                    }
                </div>
            </>
        );
    }
}

export default withTranslation()(MultipleChoice)

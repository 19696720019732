import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import moment from "moment";

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import TimePicker from '../../../components/Utils/DateRange/TimePicker';
import Input from '../../../components/Utils/Field/Input';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import Textarea from '../../../components/Utils/Field/Textarea';
import Select2 from '../../../components/Utils/Field/Select2';
import { Role,Status } from '../../../helpers/Constants';
import { withTranslation } from "react-i18next";

class ManifestationResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;
        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;
        const enableSendMail = this.props.caseStatus === Status.Pending || this.props.caseStatus === Status.Draft || this.props.caseStatus === Status.NotOk || this.props.caseStatus === Status.NotApproved;

        return (
            <>
                <div className="tab-pane" id="manifestation-result">

                    {/* 4.1 Manifestation Result*/}

                    <div>
                        <label className="form-label" style={{ fontSize: 16 }}>{t('manifestation_result_caps')}</label>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('chronological')}</label>
                                    <Textarea
                                        disabled={isDisabledForm}
                                        rows="6"
                                        name="resultChronology"
                                        placeholder={t('chronological')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.resultChronology ? formik.values.resultChronology : ""}
                                        {...formik.getFieldProps('resultChronology')}
                                        same={historydata?.resultChronology ? historydata.resultChronology === formik?.values.resultChronology : true } 
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('physical_examination')}</label>
                                    <Textarea
                                        rows="6"
                                        name="resultPhysic"
                                        placeholder={t('physical_examination')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.resultPhysic ? formik.values.resultPhysic : ""}
                                        {...formik.getFieldProps('resultPhysic')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.resultPhysic ? historydata.resultPhysic === formik?.values.resultPhysic : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label" style={{ height: 35 }}>{t('treatment_date')}</label>
                                    <DatePicker
                                        name="treatmentDate"
                                        selectionType="single"
                                        maxdate={new Date()}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={formik.values?.treatmentDate}
                                        disabled={isDisabledForm}
                                        same={historydata?.treatmentDate ? moment(historydata.treatmentDate).format("YYYY-MM-DD") === formik?.values.treatmentDate : true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('treatment')}</label>
                                    <Textarea
                                        disabled={isDisabledForm}
                                        rows="6"
                                        name="resultTreatment"
                                        placeholder={t('treatment')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.resultTreatment ? formik.values.resultTreatment : ""}
                                        {...formik.getFieldProps('resultTreatment')} 
                                        same={historydata?.resultTreatment ? historydata.resultTreatment === formik?.values.resultTreatment : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('laboratory_result')}</label>
                                    <Textarea
                                        disabled={isDisabledForm}
                                        rows="6"
                                        name="resultLaboratory"
                                        placeholder={t('laboratory_result')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.resultLaboratory ? formik.values.resultLaboratory : ""}
                                        {...formik.getFieldProps('resultLaboratory')}
                                        same={historydata?.resultLaboratory ? historydata.resultLaboratory === formik?.values.resultLaboratory : true}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                {this.createAefiDiagnosisUI(formik)}
                                {this.initialAefiDiagnosisUI(formik)}
                                <ButtonWithIcon
                                    text={t('add_diagnose')}
                                    icon="fa fa-plus"
                                    onClick={this.addAefiDiagnosisUI.bind(this, formik)}
                                    width="200px"
                                    disabled={isDisabledForm}
                                />
                            </div>
                        </div>
                        <hr />
                    </div>

                    {/* 4.2 Date Adverse */}

                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" style={{ height: 35 }}>{t('adverse_event_received_date')}</label>
                                            <DatePicker
                                                name="adverseDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.adverseDate}
                                                disabled={isDisabledForm}
                                                same={historydata?.adverseDate ? moment(historydata.adverseDate).format("YYYY-MM-DD") === formik?.values.adverseDate : true }
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" style={{ height: 35 }}>{t('time')}</label>
                                            <TimePicker
                                                name="adverseTime"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.adverseTime}
                                                disabled={isDisabledForm}
                                                same={historydata?.adverseDate ? moment(historydata.adverseDate).format("HH:mm:ss") === formik.values?.adverseTime : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-group">
                                        <label className="form-label" style={{height: 35}}>{t('name')}</label>
                                        <Input
                                            disabled={isDisabledForm}
                                            name="adverseName"
                                            type="text"
                                            placeholder={t('name')}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('adverseName')} 
                                            same={historydata?.adverseName ? historydata.adverseName === formik?.values.adverseName : true }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label" style={{height: 35}}>{t('department')} <span style={{fontSize: 12, opacity:"0.5"}}> ( {t('department_required')} ) </span></label>
                                <Select2
                                    disabled={isDisabledForm}
                                    name="organizationCode"
                                    options={this.props.departmentList?.map(item => { return { value: item.organizationCode, label: item.organizationName }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values.organizationCode}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    same={historydata?.organizationCode ? historydata.organizationCode === formik?.values.organizationCode : true }
                                />
                            </div>
                        </div>
                        <hr />
                    </div>

                    {/* 4.3 Date Adverse in Pv Unit*/}

                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" style={{ height: 35 }}>{t('adverse_event_received_date')} di Unit Pv</label>
                                            <DatePicker
                                                name="adversePvDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.adversePvDate}
                                                disabled={isDisabledForm}
                                                same={historydata?.adversePvDate ? moment(historydata.adversePvDate).format("YYYY-MM-DD") === formik?.values.adversePvDate : true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label" style={{ height: 35 }}>{t('time')}</label>
                                            <TimePicker
                                                name="adversePvTime"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.adversePvTime}
                                                disabled={isDisabledForm}
                                                same={historydata?.adversePvDate ? moment(historydata.adversePvDate).format("HH:mm:ss") === formik.values?.adversePvTime : true }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label" style={{height: 35}}>{t('name')}</label>
                                    <Input
                                        disabled={isDisabledForm}
                                        name="adversePvName"
                                        type="text"
                                        placeholder={t('name')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('adversePvName')}
                                        same={historydata?.adversePvName ? historydata.adversePvName === formik?.values.adversePvName : true } 
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>

                    {/* 4.4 Classification*/}

                    <div>
                        <label className="form-label" style={{ fontSize: 16 }}>{t('classification')}</label>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('seriousness_level')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="seriousness"
                                        options={this.props.seriousnessList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.seriousness}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.seriousness ? historydata.seriousness === formik?.values.seriousness : true } 
                                    />
                                </div>
                                {
                                    (formik.values.seriousness === "fd2f5778-8d49-4c15-9734-8769c5dfe04d" || formik.values.seriousness === "9d87afa6-5605-4fe0-9f8d-792eecf9d6ec") // Id Outcome 'Meninggal'
                                        && (
                                        <div className="form-group">
                                            <label className="form-label">{t('seriousness_reason')}</label>
                                            <Select2
                                                disabled={isDisabledForm}
                                                name="seriousnessReason"
                                                options={this.props.seriousnessReasonList?.map(item => { return { value: item.id, label: item.name }; })}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values.seriousnessReason}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                same={historydata?.seriousnessReason ? historydata.seriousnessReason === formik?.values.seriousnessReason : true } 
                                            />
                                        </div>
                                    ) 
                                }
                                <div className="form-group">
                                    <label className="form-label">{t('expectedness')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="expectedness"
                                        options={this.props.expectednessList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.expectedness}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.expectedness ? historydata.expectedness === formik?.values.expectedness : true}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('outcome')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="outcome"
                                        options={this.props.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.outcome}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.outcome ? historydata.outcome === formik?.values.outcome : true}
                                    />
                                </div>
                                {
                                    formik.values.outcome === "4b78e73a-a322-4116-83ff-f672c92202ba" // Id Outcome 'Meninggal'
                                        && (
                                        <div className="form-group">
                                            <label className="form-label">{t('date_of_death')}</label>
                                            <DatePicker
                                                name="deathDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.deathDate}
                                                disabled={isDisabledForm}
                                            />
                                        </div>
                                    ) 
                                }
                                <hr />
                                {this.props.canStaffEdit && (
                                        <div className='row mx-0 justify-content-between align-items-center'>
                                            <div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="additionalRequest"
                                                    value={formik.values.additionalRequest}
                                                    checked={formik.values.additionalRequest === true}
                                                    onChange={() => {
                                                        if (formik.values.additionalRequest) {
                                                            formik?.setFieldValue("additionalRequest", false)
                                                            formik?.setFieldValue("additionalRequestMethod", null)
                                                        } else {
                                                            formik?.setFieldValue("additionalRequest", true)
                                                            formik?.setFieldValue("additionalRequestMethod", "email")
                                                        }
                                                    }}
                                                    disabled={isDisabledForm || !enableSendMail}
                                                />
                                                <label className="form-check-label">{t('additional_data')}</label>
                                            </div>
                                            <br/>
                                            {formik.values.additionalRequest && (
                                                <fieldset className="ml-3 mb-2">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            checked={formik.values.additionalRequestMethod === "email"}
                                                            value="email"
                                                            onChange={formik.handleChange}
                                                            name="additionalRequestMethod"
                                                            disabled={isDisabledForm || !enableSendMail}
                                                        />
                                                        <label className="form-check-label">
                                                            Email
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            checked={formik.values.additionalRequestMethod === "other"}
                                                            value="other"
                                                            onChange={formik.handleChange}
                                                            name="additionalRequestMethod"
                                                            disabled={isDisabledForm || !enableSendMail}
                                                        />
                                                        <label className="form-check-label">
                                                            {t('other')}
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            )}
                                            </div>
                                            {formik.values.additionalRequestMethod === "email" && enableSendMail && (
                                                <div className='ml-auto'>
                                                    <button
                                                        type="button"
                                                        className="link-mail"
                                                        onClick={() => this?.props?.showMailForm("additional")}
                                                    >
                                                        {t("send_email")}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                {this.props.canStaffEdit &&
                                    <div className="row mx-0 form-check form-check-inline">
                                        <input type="checkbox" className="form-check-input" name="adrOnlineNeeded" value="true"
                                            checked={formik.values.adrOnlineNeeded === true}
                                            onChange={formik.handleChange}
                                            disabled={isDisabledForm || formik.values.disabledEmeso}
                                        />
                                        <label className="form-check-label">{t('sending_emeso')}</label>
                                    </div>
                                }
                                <br/>
                                {this.props.canStaffEdit &&
                                    <div className='row mx-0 justify-content-between align-items-center'>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" className="form-check-input" name="qualityReviewNeeded" value="true"
                                                checked={formik.values.qualityReviewNeeded === true}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm || !enableSendMail}
                                            />
                                            <label className="form-check-label">{t('quality_review_request')}</label>
                                        </div>
                                        {formik.values.qualityReviewNeeded && enableSendMail && (
                                            <div className='ml-auto'>
                                                <button
                                                    type="button"
                                                    className="link-mail"
                                                    onClick={() => this?.props?.showMailForm("qualityReview")}
                                                >
                                                    {t("send_email")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                }
                                {this.props.canStaffEdit &&
                                    <div className='row mx-0 justify-content-between align-items-center'>
                                        <div className="form-check form-check-inline">
                                            <input type="checkbox" className="form-check-input" name="distributionDataRequest" value="true"
                                                checked={formik.values.distributionDataRequest === true}
                                                onChange={formik.handleChange}
                                                disabled={isDisabledForm || !enableSendMail}
                                            />
                                            <label className="form-check-label">
                                                {t('distibution_data_request')}
                                            </label>
                                        </div>
                                        {formik.values.distributionDataRequest && enableSendMail && (
                                            <div className='ml-auto'>
                                                <button
                                                    type="button"
                                                    className="link-mail"
                                                    onClick={() => this?.props?.showMailForm("distribution")}
                                                >
                                                    {t("send_email")}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>                    
                </div>
            </>
        )
    }

    // AefiDiagnosis Dynamic Table

    initialAefiDiagnosisUI(formik) {

        const diagnosis = this.props.diagnosis;

        if (diagnosis && diagnosis.length) {

            for (var i = 0; i < diagnosis.length; i++) {

                if (diagnosis[i] && !diagnosis[i].isNew && !diagnosis[i].previewed) {
                    formik.setFieldValue(`diagnosis[${i}][id]`, diagnosis[i].id)
                    formik.setFieldValue(`diagnosis[${i}][diagnosis]`, diagnosis[i].diagnosis)
                    formik.setFieldValue(`diagnosis[${i}][systemOrganClass]`, diagnosis[i].systemOrganClass)
                    formik.setFieldValue(`diagnosis[${i}][preferredTerm]`, diagnosis[i].preferredTerm)
                }

                this.props.diagnosis[i].previewed = true;
            }
        }
    }

    createAefiDiagnosisUI(formik) {

        const role = this.props.currentUser ? this.props.currentUser.role : "";

        const {t} = this.props;

        const socList = this.props.socList;
        const ptList = this.props.ptList;

        const historydata = this.props.historydata;
        const historyDiagnosis = historydata?.trailAefiDiagnosis?.length > 0 ? historydata?.trailAefiDiagnosis : [];

        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return this.props.diagnosis?.map((element, i) =>

            <div key={i} className={historydata?.trailAefiDiagnosis?.length > 0 ? (historyDiagnosis[i] ? "" : "change-value-radio") : ""}>

                <Input
                    name={`diagnosis[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`diagnosis[${i}][id]`)}
                    disabled={isDisabledForm}
                    same={historydata?.trailAefiDiagnosis?.length > 0 ? historyDiagnosis[i]?.id === formik.values.diagnosis[i]?.id : true}
                />
                <div className="row dynamic-form">
                    <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-3">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('diagnose')}</label>}
                                    <Input
                                        name={`diagnosis[${i}][diagnosis]`}
                                        placeholder={t('diagnose')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`diagnosis[${i}][diagnosis]`)}
                                        disabled={isDisabledForm}
                                        same={historydata?.trailAefiDiagnosis?.length > 0 ? (historyDiagnosis[i]?.diagnosis === formik.values.diagnosis[i]?.diagnosis) : true}
                                    />
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-6 col-md-4">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">System Organ Class</label>}
                                    <Select2
                                        name={`diagnosis[${i}][systemOrganClass]`}
                                        options={socList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`diagnosis[${i}][systemOrganClass]`)}
                                        onChange={(name, value) => {
                                            formik.setFieldValue(name, value);
                                            formik.setFieldValue(`diagnosis[${i}][preferredTerm]`, "");
                                        }}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.trailAefiDiagnosis?.length > 0 ? (historyDiagnosis[i]?.systemOrganClass === formik.values.diagnosis[i]?.systemOrganClass) : true}
                                    />
                                </div>
                            </div>

                            <div className="col-xs-6 col-sm-6 col-md-4">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Preferred Term</label>}
                                    <Select2
                                        name={`diagnosis[${i}][preferredTerm]`}
                                        options={ptList?.filter(x => i < formik.values.diagnosis?.length && x.systemOrganClass == formik.values.diagnosis[i]?.systemOrganClass).map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`diagnosis[${i}][preferredTerm]`)}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historydata?.trailAefiDiagnosis?.length > 0 ? (historyDiagnosis[i]?.preferredTerm === formik.values.diagnosis[i]?.preferredTerm) : true}
                                    />
                                </div>
                            </div>
                           
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeAefiDiagnosisUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addAefiDiagnosisUI(formik) {
        this.props.diagnosis.push({ isNew: true });
        formik.setFieldValue(`diagnosis[${this.props.diagnosis.length - 1}][id]`, null)
    }

    removeAefiDiagnosisUI(formik, index) {

        for (var i = index; i < this.props.diagnosis.length; i++) {

            const diagnosis = formik.values.diagnosis[i + 1];

            this.props.diagnosis[i] = this.props.diagnosis[i + 1];

            if (diagnosis) {
                formik.setFieldValue(`diagnosis[${i}][id]`, diagnosis.id)
                formik.setFieldValue(`diagnosis[${i}][diagnosis]`, diagnosis.diagnosis)
                formik.setFieldValue(`diagnosis[${i}][systemOrganClass]`, diagnosis.systemOrganClass)
                formik.setFieldValue(`diagnosis[${i}][preferredTerm]`, diagnosis.preferredTerm)
            }
            else {
                formik.setFieldValue(`diagnosis[${i}][id]`, null)
                formik.setFieldValue(`diagnosis[${i}][diagnosis]`, null)
                formik.setFieldValue(`diagnosis[${i}][systemOrganClass]`, null)
                formik.setFieldValue(`diagnosis[${i}][preferredTerm]`, null)
            }
        }

        formik.values.diagnosis.pop();
        this.props.diagnosis.pop();
    }

}

export default withTranslation()(ManifestationResult);
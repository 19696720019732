import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ProductDistributionService } from '../../services/ProductDistribution/ProductDistributionService';

import { FormProductDistribution } from './FormProductDistribution';

import { withTranslation } from "react-i18next";

export class AddProductDistribution extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            productDistributionRecords: [],
        }
        this.getData();
    }

    getData() {
        this.state.productDistributionRecords = [{ isNew: true, year: new Date().getFullYear(), dose: "", domesticVial: 0, exportVial: 0, totalVial: 0 }];

        ProductDistributionService.Products().then(result => {
            this.setState({
                productList: result.data.map(item => { return { value: item.id, label: item.name, type: item.type }; })
            });
        });
    }

    productUpdate = (value) => {
        this.setState({productDistributionRecords : value})
    }

    cancel = () => {
        this.props.history.push(`/product-distribution`);
    }

    render() {

        const { t } = this.props;

        return (
            <>
                <div>
                    <Formik
                        enableReinitialize 
                        initialValues={{
                            product: "",
                        }}

                        validationSchema={
                            Yup.object().shape({
                                product: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {

                            values.productDistributionRecords = values.productDistributionRecords?.filter(obj => { return obj != null });

                            ProductDistributionService.Create(values).then(

                                (result) => {
                                    toastSuccess(t('success_add_message'));
                                    this.props.history.push(`/product-distribution`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                            (
                                <Form>
                                    <div className="tab-content">
                                        <FormProductDistribution
                                            formik={formik}
                                            productDistributionRecords={this.state.productDistributionRecords}
                                            productList={this.state.productList}
                                            productUpdate={(value) => this.productUpdate(value)}
                                            t={t}
                                        />
                                    </div>
                                    <div className='text-right'>
                                        <ToggleDisplay>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={() => this.cancel()} 
                                            />
                                        </ToggleDisplay>
                                        <ToggleDisplay>
                                            <Button
                                                type="submit"
                                                text={t('save')}
                                                disabled={formik.isSubmitting} 
                                            />
                                        </ToggleDisplay>
                                        {
                                            formik.status &&
                                            <div className={'alert alert-danger'}>{formik.status}</div>
                                        }
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </>
        )
    }
    
}

export default withTranslation()(AddProductDistribution)
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import { ProductDistributionService } from '../../services/ProductDistribution/ProductDistributionService';
import {DeleteModal} from '../../components/Utils/Modal/DeleteModal';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import Select from "react-select";
import YearPicker from '../../components/Utils/DateRange/YearPicker';
import { authenticationService } from '../../services/AuthService';
import { Role } from '../../helpers/Constants';

import { withTranslation } from "react-i18next";
import { ConfirmationModal } from './ConfirmationModal';

class ProductDistribution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            productList: [],
            addModalShow: false,
            editModalShow: [],
            deleteModalShow : false,
            reminderModalShow: false,
            filterShow: false,
            period: null,
            product: null,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: ""
        }
    }

    componentDidMount() {
        this.refreshList();
        this.getProductList();
    }

    deleteRecord(id) {

        ProductDistributionService.Delete(id).then(
            (result) => {
                toastSuccess('Berhasil menghapus data');
                this.refreshList();
                this.setState({ deleteModalShow: false })
            },
            (error) => {
                toastError(error);
            })
    }

    refreshList() {
        const param = {
            page: this.state.page,
            limit: this.state.limit,
            query: this.state.query,
            year: this.state.period,
            product: this.state.product?.value
        };

        ProductDistributionService.Read(param).then(result => {
            this.setState({
                maxPage: result.data.maxPage,
                records: result.data.records,
                editModalShow: new Array(result.data.length).fill(false)
            });
        });
    }

    getProductList() {
        ProductDistributionService.Products().then(result => {
            this.setState({
                productList: result.data.map(item => { return { value: item.id, label: item.name, type: item.type }; })
            });
        });
    }

    handlePageReset(){
        this.setState({page : 1}, () => {
            this.refreshList();
        })
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.handlePageReset();
        event.preventDefault();
    }

    handleProductChange(item) {
        this.setState({ product: item }, () => {
            this.handlePageReset();
        });
    }

    handleYearChange(item) {
        this.setState({ period: item }, () => {
            this.handlePageReset();
        });
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    };

    handleDelete(id) {
        this.setState({
            record_id : id,
            deleteModalShow : true
        })
    }

    handleDetail = (id) => {
        this.props.history.push(`/product-distribution/detail/${id}`);
    }

    add = () => {
        this.props.history.push(`/product-distribution/add`);
    }

    handleReminder = () => {
        ProductDistributionService.SendReminder().then(
            (result) => {
                toastSuccess('Berhasil mengirim notifikasi reminder');
                this.refreshList();
                this.setState({ reminderModalShow: false })
            },
            (error) => {
                toastError(error);
            })

    }

    render() {

        const { records } = this.state;

        const { t } = this.props;

        const currentUser = authenticationService.currentUserValue;

        return (
            <>
                <DeleteModal
                    show={this.state.deleteModalShow}
                    onHide={() => this.setState({ deleteModalShow: false })} 
                    confirmdelete = {() => this.deleteRecord(this.state.record_id)}
                    message={t('delete_confirmation')}
                />
                <ConfirmationModal
                    show={this.state.reminderModalShow}
                    onhide={() => this.setState({reminderModalShow: false})}
                    confirm={this.handleReminder}
                    t={t}
                />
                <div className="row">
                    {currentUser?.role == Role.Distribusi && (
                    <div className="col-md-3" style={{marginBottom : 15}}>
                            <ButtonWithIcon
                                text={t('add_product_distribution')}
                                icon="fa fa-plus"
                                onClick={() => this.add()}
                            />
                    </div>
                     )}
                     {currentUser?.role != Role.Distribusi && (
                    <div className="col-md-4" style={{marginBottom : 15}}>
                            <ButtonWithIcon
                                text={"Reminder Input Data Distribusi Tahunan"}
                                icon="fa fa-plus"
                                onClick={() => this.setState({reminderModalShow: true})}
                            />
                    </div>
                    )}
                    <div className={currentUser?.role != Role.Distribusi ? "col-md-5": "col-md-6" }>

                    </div>
                    <div className="col-md-3">
                        <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                            <ButtonWithIcon
                                text="Filter"
                                icon="fas fa-bars"
                                onClick={() =>
                                    this.setState({ filterShow: !this.state.filterShow })
                                }
                            />
                        </div>
                    </div>
                </div>
                {this.state.filterShow && (
                    <div
                        className="row filter-wrapper"
                        style={{ marginTop: 10, marginBottom: 10 }}
                    >
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-4 col-md-4">
                                        <div className="form-group">
                                            <label className="form-label"> {t("search")}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control"
                                                    value={this.state.query}
                                                    onChange={(event) => this.handleSearchChange(event)} 
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search"/></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="col-xs-12 col-sm-4 col-md-4"
                                        style={{ marginBottom: 10 }}
                                    >
                                        <label className="form-label"> {t('product_name')}</label>
                                        <Select
                                            placeholder={t('product_name')}
                                            width="100%"
                                            name="product"
                                            isClearable="true"
                                            classNamePrefix="select"
                                            className="basic-single"
                                            options={this.state.productList}
                                            value={this.state.product}
                                            onChange={(item) => this.handleProductChange(item)}
                                        ></Select>
                                    </div>
                                    <div
                                        className="col-xs-12 col-sm-3 col-md-3"
                                        style={{ marginBottom: 10 }}
                                    >
                                        <label className="form-label">{t('distribution_period')}</label>
                                        <YearPicker
                                            name='period'
                                            placeholder={t('distribution_period')}
                                            value={this.state.period}
                                            handlechange={(year) => this.handleYearChange(year)}
                                            handleClearYear={() => this.handleYearChange(null)}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                    <Table className="pb-5" hover>
                        <thead>
                            <tr>
                                <th width="5%">No</th>
                                <th>{t('product_name')}</th>
                                <th>{t('distribution_period')}</th>
                                <th>Total Vial</th>
                                <th width="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((record, index) =>
                                <tr key={record.id}>
                                    <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                    <td>{record.productName}</td>
                                    <td>{record.period}</td>
                                    <td>{record.total}</td>
                                    {currentUser?.role == Role.Distribusi && (
                                        <td>
                                            <div className="btn-group">
                                                <button className="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                </button>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" onClick={() => this.handleDetail(record.id)}
                                                    >
                                                        Edit
                                                    </a>
                                                    <a className="dropdown-item" onClick={() => this.handleDelete(record.id)}>
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            )}
                            {
                                records.length < 1 && (
                                    <tr>
                                        <td colSpan="6" style={{ textAlign: "center" }}>
                                            {t('data_not_found')}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
                <nav>
                    <ReactPaginate
                        previousLabel={(<i class="fas fa-arrow-left"></i>)}
                        previousLinkClassName={"page-link"}
                        nextLabel={(<i class="fas fa-arrow-right"></i>)}
                        nextLinkClassName={"page-link"}
                        pageCount={this.state.maxPage}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        breakClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        onPageChange={(event) => this.handlePageClick(event)}
                        forceSelected={(this.state.page)-1}
                        forcePage={(this.state.page)-1}
                        initialPage={(this.state.page)-1}
                    />
                </nav>

            </>
        )
    }

}

export default withTranslation()(ProductDistribution)

import { handleResponse, authHeader } from '../../../helpers/RequestHelper';

export const AnalysisService = {
    PrepareForm,
    Create,
    Read,
    Update,
    Delete
};

function PrepareForm(id, type = "", followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/medical-review/analysis/prepare-form/` + type + "/" + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/medical-review/analysis/submit-form/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(filter = "",page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/medical-review/analysis/read?filter=${filter}&page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);
    data = await handleResponse(data)
    return data;
}

function Update(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/medical-review/analysis/submit-form/update`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/medical-review/analysis/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import moment from "moment";

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import TimePicker from '../../../components/Utils/DateRange/TimePicker';
import Button from '../../../components/Utils/Button/Button';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import Select2 from '../../../components/Utils/Field/Select2';
import * as Utils from '../../../helpers/Utils';
import { Role,Status } from '../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class Manifesto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return (
            <>
                <div className="tab-pane" id="manifesto">
                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('describe_reaction')}</label>
                                    <Textarea
                                        rows="6"
                                        name="reaction"
                                        placeholder={t('describe_reaction')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.reaction ? formik.values.reaction : ""}
                                        {...formik.getFieldProps('reaction')}
                                        disabled={isDisabledForm} 
                                        same={historydata?.reaction ? historydata.reaction === formik?.values.reaction : true }
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('onset_date_ae')}</label>
                                            <DatePicker
                                                name="onsetDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.onsetDate}
                                                disabled={isDisabledForm}
                                                same={historydata?.onsetDate ? moment(historydata.onsetDate).format("YYYY-MM-DD") === formik?.values.onsetDate : true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('time')}</label>
                                            <TimePicker
                                                name="onsetTime"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.onsetTime}
                                                disabled={isDisabledForm}
                                                same={historydata.onsetDate ? moment(historydata.onsetDate).format("HH:mm:ss") === formik?.values.onsetTime : true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('side_effect')}</label>
                                    <div>
                                        <div className={historydata.reactionDied ? "form-check form-check-inline " + ((historydata.reactionDied === formik?.values.reactionDied) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionDied" value="true"
                                                checked={formik.values.reactionDied === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('died')}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={historydata.reactionHospitalization ? "form-check form-check-inline " + ((historydata.reactionHospitalization === formik?.values.reactionHospitalization) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionHospitalization" value="true"
                                                checked={formik.values.reactionHospitalization === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('involved_inpatient')}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={historydata.reactionDisability ? "form-check form-check-inline " + ((historydata.reactionDisability === formik?.values.reactionDisability) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionDisability" value="true"
                                                checked={formik.values.reactionDisability === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('involved_persistent')}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={historydata.reactionLife ? "form-check form-check-inline " + ((historydata.reactionLife === formik?.values.reactionLife) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionLife" value="true"
                                                checked={formik.values.reactionLife === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('life_threatening')}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={historydata.reactionEvent ? "form-check form-check-inline " + ((historydata.reactionEvent === formik?.values.reactionEvent) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionEvent" value="true"
                                                checked={formik.values.reactionEvent === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('important_medical_event')}</label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={historydata.reactionDefect ? "form-check form-check-inline " + ((historydata.reactionDefect === formik?.values.reactionDefect) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                            <input type="checkbox" className="form-check-input" name="reactionDefect" value="true"
                                                checked={formik.values.reactionDefect === true}
                                                onChange={formik.handleChange} 
                                                disabled={isDisabledForm}
                                            />
                                            <label className="form-check-label">{t('birth_defect')}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />
                    </div>

                    <div>
                        {this.createDrugsUI(formik)}
                        {this.initialDrugsUI(formik)}
                        <ButtonWithIcon
                            text={t('add_drug')}
                            icon="fa fa-plus"
                            onClick={this.addDrugsUI.bind(this, formik)} 
                            isSubmitting={isDisabledForm}
                            width="200px"
                        />

                        <hr />
                    </div>

                    <div>
                        <label className="form-label" style={{ fontSize: 16 }}>{t('drugs_taken')}</label>

                        {this.createDrugsConcomitantlyUI(formik)}
                        {this.initialDrugsConcomitantlyUI(formik)}
                        <ButtonWithIcon
                            text={t('add_drug')}
                            icon="fa fa-plus"
                            onClick={this.addDrugsConcomitantlyUI.bind(this, formik)} 
                            isSubmitting={isDisabledForm}
                            width="200px"
                        />
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} 
                            />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

    // Drugs dynamic table 

    initialDrugsUI(formik) {

        const drugs = this.props.drugs;

        if (drugs && drugs.length) {

            for (var i = 0; i < drugs.length; i++) {

                if (drugs[i] && !drugs[i].isNew && !drugs[i].previewed) {
                    formik.setFieldValue(`drugs[${i}][id]`, drugs[i].id)
                    formik.setFieldValue(`drugs[${i}][drugs]`, drugs[i].drugs)
                    formik.setFieldValue(`drugs[${i}][dose]`, drugs[i].dose)
                    formik.setFieldValue(`drugs[${i}][doseFor]`, drugs[i].doseFor)
                    formik.setFieldValue(`drugs[${i}][batch]`, drugs[i].batch)
                    formik.setFieldValue(`drugs[${i}][frequency]`, drugs[i].frequency)
                    formik.setFieldValue(`drugs[${i}][route]`, drugs[i].route)
                    formik.setFieldValue(`drugs[${i}][startDate]`, Utils.getDate(drugs[i].startDate))
                    formik.setFieldValue(`drugs[${i}][stopDate]`, Utils.getDate(drugs[i].stopDate))
                    formik.setFieldValue(`drugs[${i}][indication]`, drugs[i].indication)
                    formik.setFieldValue(`drugs[${i}][drugsItemId]`, drugs[i].drugsItemId)
                }

                this.props.drugs[i].previewed = true;
            }
        }
    }

    updateValue = (i, value) => {

        const drugsList = this.props.drugsList;
        const formik = this.props.formik;
        console.log(drugsList)
        console.log(value)
        if (!drugsList) {
            formik.setFieldValue(`drugs[${i}][drugsItemId]`, null)
        }

        const filtered = drugsList?.filter(x => i < formik.values.drugs?.length && x.id === value)
        
        if (filtered.length === 0) {
            formik.setFieldValue(`drugs[${i}][drugsItemId]`, null)
        }

        if(filtered[0].masterDrugsRelated.length === 1){
            filtered[0].masterDrugsRelated.map(item => {
                formik.setFieldValue(`drugs[${i}][drugsItemId]`, item.itemId)
            })
        }
    }

    createDrugsUI(formik) {

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const {t, erpList} = this.props;
        const drugsList = this.props.drugsList;
        const immunizationRouteList = this.props.immunizationRouteList;
        const historydata = this.props.historydata;
        const historyDrugs = historydata?.trailAeDrugs ? historydata?.trailAeDrugs : [];
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        const calculateOptions = (i) => {
            if (!drugsList) {
                return []
            }

            const filtered = drugsList.filter(x => i < formik.values.drugs?.length && x.id === formik.values.drugs[i]?.drugs)
            if (filtered.length === 0) {
                return []
            }


            return filtered[0].masterDrugsRelated.map(item => ({ value: item.itemId, label: item.itemName }))
        }

        return this.props.drugs.map((element, i) =>

            <div key={i}>

                <Input
                    name={`drugs[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`drugs[${i}][id]`)} 
                    disabled={isDisabledForm} 
                    same={historyDrugs[i] ? historyDrugs[i]?.id === formik.values.drugs[i]?.id : true }
                />
                <div className="dynamic-form">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-3">
                                    <div className="form-group">
                                        {<label className="form-label">{t('suspect_drugs')}</label>}
                                        <Select2
                                            name={`drugs[${i}][drugs]`}
                                            options={drugsList?.map(item => { return { value: item.id, label: item.name }; })}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][drugs]`)}
                                            onChange={(name, value) => {
                                                formik.setFieldValue(name, value);
                                                this.updateValue(i, value)
                                            }}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm} 
                                            same={historyDrugs[i] ? historyDrugs[i]?.drugs === formik.values.drugs[i]?.drugs : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="form-group">
                                        {<label className="form-label">{t('dose')}</label>}
                                        <Input
                                            name={`drugs[${i}][dose]`}
                                            type="number"
                                            step=".01"
                                            min={0}
                                            placeholder="ml"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][dose]`)}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? historyDrugs[i]?.dose === formik.values.drugs[i]?.dose : true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="form-group">
                                        {<label className="form-label">{t('dose_number')}</label>}
                                        <Input
                                            name={`drugs[${i}][doseFor]`}
                                            type="number"
                                            step="1"
                                            min={0}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][doseFor]`)}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? historyDrugs[i]?.doseFor === formik.values.drugs[i]?.doseFor : true}
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('batch_number')}</label>}
                                        <Input
                                            name={`drugs[${i}][batch]`}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][batch]`)}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? historyDrugs[i]?.batch === formik.values.drugs[i]?.batch : true}
                                        />
                                    </div>
                                </div> 
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="form-group">
                                        {<label className="form-label">{t('frequency')}</label>}
                                        <Input
                                            name={`drugs[${i}][frequency]`}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][frequency]`)} 
                                            disabled={isDisabledForm} 
                                            same={historyDrugs[i] ? historyDrugs[i]?.frequency === formik.values.drugs[i]?.frequency : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-3">
                                    <div className="form-group">
                                        {<label className="form-label">{t('erp_product_name')}</label>}
                                        <Select2
                                            name={`drugs[${i}][drugsItemId]`}
                                            options={calculateOptions(i)}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][drugsItemId]`)}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm} 
                                            same={historyDrugs[i] ? historyDrugs[i]?.drugs === formik.values.drugs[i]?.drugsItemId : true }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                            <div className="row">
                                <div className="col-xs-6 col-sm-6 col-md-3"/>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('rute')}</label>}
                                        <Select2
                                            name={`drugs[${i}][route]`}
                                            options={immunizationRouteList?.map(item => { return { value: item.id, label: item.name }; })}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][route]`)}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? historyDrugs[i]?.route === formik.values.drugs[i]?.route : true}
                                        />
                                    </div>
                                </div>  
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('start_date')}</label>}
                                        <DatePicker
                                            name={`drugs[${i}][startDate]`}
                                            selectionType="single"
                                            maxdate={new Date()}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={element.startDate}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? moment(historyDrugs[i]?.startDate).format("YYYY-MM-DD") === formik.values.drugs[i]?.startDate : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('end_date')}</label>}
                                        <DatePicker
                                            name={`drugs[${i}][stopDate]`}
                                            selectionType="single"
                                            maxdate={new Date()}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={element.stopDate}
                                            disabled={isDisabledForm}
                                            same={historyDrugs[i] ? moment(historyDrugs[i]?.stopDate).format("YYYY-MM-DD") === formik.values.drugs[i]?.stopDate : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-2">
                                    <div className="form-group">
                                        {<label className="form-label">{t('indication')}</label>}
                                        <Input
                                            name={`drugs[${i}][indication]`}
                                            type="text"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps(`drugs[${i}][indication]`)} 
                                            disabled={isDisabledForm} 
                                            same={historyDrugs[i] ? historyDrugs[i]?.indication === formik.values.drugs[i]?.indication : true }
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-6 col-md-1">
                                    <div className="align-items-center button-close">
                                        {
                                            i != 0 &&

                                            <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeDrugsUI.bind(this, formik, i)} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
        )
    }

    addDrugsUI(formik) {
        this.props.drugs.push({ isNew: true })
        formik.setFieldValue(`drugs[${this.props.drugs.length - 1}][id]`, null)
    }

    removeDrugsUI(formik, index) {

        for (var i = index; i < this.props.drugs.length; i++) {

            const drugs = formik.values.drugs[i + 1];

            this.props.drugs[i] = this.props.drugs[i + 1];

            if (drugs) {
                formik.setFieldValue(`drugs[${i}][id]`, drugs.id)
                formik.setFieldValue(`drugs[${i}][drugs]`, drugs.drugs)
                formik.setFieldValue(`drugs[${i}][dose]`, drugs.dose)
                formik.setFieldValue(`drugs[${i}][doseFor]`, drugs.doseFor)
                formik.setFieldValue(`drugs[${i}][batch]`, drugs.batch)
                formik.setFieldValue(`drugs[${i}][frequency]`, drugs.frequency)
                formik.setFieldValue(`drugs[${i}][route]`, drugs.route)
                formik.setFieldValue(`drugs[${i}][startDate]`, Utils.getDate(drugs.startDate))
                formik.setFieldValue(`drugs[${i}][stopDate]`, Utils.getDate(drugs.stopDate))
                formik.setFieldValue(`drugs[${i}][indication]`, drugs.indication)
                formik.setFieldValue(`drugs[${i}][drugsItemId]`, drugs.drugsItemId)
            }
            else {
                formik.setFieldValue(`drugs[${i}][id]`, null)
                formik.setFieldValue(`drugs[${i}][drugs]`, null)
                formik.setFieldValue(`drugs[${i}][dose]`, null)
                formik.setFieldValue(`drugs[${i}][doseFor]`, null)
                formik.setFieldValue(`drugs[${i}][frequency]`, null)
                formik.setFieldValue(`drugs[${i}][route]`, null)
                formik.setFieldValue(`drugs[${i}][startDate]`, null)
                formik.setFieldValue(`drugs[${i}][stopDate]`, null)
                formik.setFieldValue(`drugs[${i}][indication]`, null)
                formik.setFieldValue(`drugs[${i}][drugsItemId]`, null)
            }
        }

        formik.values.drugs.pop();
        this.props.drugs.pop();
    }

    // Drugs Concomitantly dynamic table

    initialDrugsConcomitantlyUI(formik) {

        const drugsConcomitantly = this.props.drugsConcomitantly;
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion)  || this.props.isLastVersion === undefined || (this.props.formType === "edit" && this.props.isLastVersion)  ? false : !this.props.isLastVersion  || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved;

        if (drugsConcomitantly && drugsConcomitantly.length) {

            for (var i = 0; i < drugsConcomitantly.length; i++) {

                if (drugsConcomitantly[i] && !drugsConcomitantly[i].isNew && !drugsConcomitantly[i].previewed) {
                    formik.setFieldValue(`drugsConcomitantly[${i}][id]`, drugsConcomitantly[i].id)
                    formik.setFieldValue(`drugsConcomitantly[${i}][drugs]`, drugsConcomitantly[i].drugs)
                    formik.setFieldValue(`drugsConcomitantly[${i}][dose]`, drugsConcomitantly[i].dose)
                    formik.setFieldValue(`drugsConcomitantly[${i}][doseFor]`, drugsConcomitantly[i].doseFor)
                    formik.setFieldValue(`drugsConcomitantly[${i}][frequency]`, drugsConcomitantly[i].frequency)
                    formik.setFieldValue(`drugsConcomitantly[${i}][route]`, drugsConcomitantly[i].route)
                    formik.setFieldValue(`drugsConcomitantly[${i}][startDate]`, Utils.getDate(drugsConcomitantly[i].startDate))
                    formik.setFieldValue(`drugsConcomitantly[${i}][stopDate]`, Utils.getDate(drugsConcomitantly[i].stopDate))
                    formik.setFieldValue(`drugsConcomitantly[${i}][indication]`, drugsConcomitantly[i].indication)
                }

                this.props.drugsConcomitantly[i].previewed = true;
            }
        }
    }

    createDrugsConcomitantlyUI(formik) {

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const {t} = this.props;
        const immunizationRouteList = this.props.immunizationRouteList;
        const historydata = this.props.historydata;
        const historyAeDrugsConcomitantly = historydata?.trailAeDrugsConcomitantly ? historydata?.trailAeDrugsConcomitantly : [];
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return this.props.drugsConcomitantly.map((element, i) =>

            <div key={i}>

                <Input
                    name={`drugsConcomitantly[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`drugsConcomitantly[${i}][id]`)} 
                    disabled={isDisabledForm} 
                    same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.id === formik.values.drugsConcomitantly[i]?.id : true }
                    
                />
                <div className="row dynamic-form">
                    <div className="col-xs-6 col-sm-6 col-md-6 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-4">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('suspect_drugs')}</label>}
                                    <Input
                                        name={`drugsConcomitantly[${i}][drugs]`}
                                        type="text"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][drugs]`)}
                                        disabled={isDisabledForm} 
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.drugs === formik.values.drugsConcomitantly[i]?.drugs : true }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-2">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('dose')}</label>}
                                    <Input
                                        name={`drugsConcomitantly[${i}][dose]`}
                                        type="number"
                                        step=".01"
                                        min={0}
                                        placeholder="ml"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][dose]`)} 
                                        disabled={isDisabledForm} 
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.dose === formik.values.drugsConcomitantly[i]?.dose : true }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-3">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{ height: 43 }}>{t('dose_number')}</label>}
                                    <Input
                                        name={`drugsConcomitantly[${i}][doseFor]`}
                                        type="number"
                                        step="1"
                                        min={0}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][doseFor]`)}
                                        disabled={isDisabledForm}
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.doseFor === formik.values.drugsConcomitantly[i]?.doseFor : true}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-3">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('frequency')}</label>}
                                    <Input
                                        name={`drugsConcomitantly[${i}][frequency]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][frequency]`)} 
                                        disabled={isDisabledForm} 
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.frequency === formik.values.drugsConcomitantly[i]?.frequency : true }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-3">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{ height: 43 }}>{t('rute')}</label>}
                                    <Select2
                                        name={`drugsConcomitantly[${i}][route]`}
                                        options={immunizationRouteList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][route]`)}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        disabled={isDisabledForm}
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.route === formik.values.drugsConcomitantly[i]?.route : true}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-3">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('start_date')}</label>}
                                    <DatePicker
                                        name={`drugsConcomitantly[${i}][startDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={element.startDate}
                                        disabled={isDisabledForm}
                                        same={historyAeDrugsConcomitantly[i] ? moment(historyAeDrugsConcomitantly[i]?.startDate).format("YYYY-MM-DD") === formik.values.drugsConcomitantly[i]?.startDate : true }
                                    />
                                </div>
                            </div>
                            <div className="colcol-xs-6 col-sm-6 col-md-3">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('end_date')}</label>}
                                    <DatePicker
                                        name={`drugsConcomitantly[${i}][stopDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={element.stopDate}
                                        disabled={isDisabledForm}
                                        same={historyAeDrugsConcomitantly[i] ? moment(historyAeDrugsConcomitantly[i]?.stopDate).format("YYYY-MM-DD") === formik.values.drugsConcomitantly[i]?.stopDate : true }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-2">
                                <div className="form-group">
                                    {i == 0 && <label className="form-label" style={{height: 43}}>{t('indication')}</label>}
                                    <Input
                                        name={`drugsConcomitantly[${i}][indication]`}
                                        type="text"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`drugsConcomitantly[${i}][indication]`)} 
                                        disabled={isDisabledForm}
                                        same={historyAeDrugsConcomitantly[i] ? historyAeDrugsConcomitantly[i]?.indication === formik.values.drugsConcomitantly[i]?.indication : true }
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i != 0 &&

                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={isDisabledForm ? null : this.removeDrugsConcomitantlyUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addDrugsConcomitantlyUI(formik) {
        this.props.drugsConcomitantly.push({ isNew: true })
        formik.setFieldValue(`drugsConcomitantly[${this.props.drugsConcomitantly.length - 1}][id]`, null)
    }

    removeDrugsConcomitantlyUI(formik, index) {

        for (var i = index; i < this.props.drugsConcomitantly.length; i++) {

            const drugsConcomitantly = formik.values.drugsConcomitantly[i + 1];

            this.props.drugsConcomitantly[i] = this.props.drugsConcomitantly[i + 1];

            if (drugsConcomitantly) {
                formik.setFieldValue(`drugsConcomitantly[${i}][id]`, drugsConcomitantly.id)
                formik.setFieldValue(`drugsConcomitantly[${i}][drugs]`, drugsConcomitantly.drugs)
                formik.setFieldValue(`drugsConcomitantly[${i}][dose]`, drugsConcomitantly.dose)
                formik.setFieldValue(`drugsConcomitantly[${i}][doseFor]`, drugsConcomitantly.doseFor)
                formik.setFieldValue(`drugsConcomitantly[${i}][frequency]`, drugsConcomitantly.frequency)
                formik.setFieldValue(`drugsConcomitantly[${i}][route]`, drugsConcomitantly.route)
                formik.setFieldValue(`drugsConcomitantly[${i}][startDate]`, Utils.getDate(drugsConcomitantly.startDate))
                formik.setFieldValue(`drugsConcomitantly[${i}][stopDate]`, Utils.getDate(drugsConcomitantly.stopDate))
                formik.setFieldValue(`drugsConcomitantly[${i}][indication]`, drugsConcomitantly.indication)
            }
            else {
                formik.setFieldValue(`drugsConcomitantly[${i}][id]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][drugs]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][dose]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][doseFor]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][frequency]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][route]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][startDate]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][stopDate]`, null)
                formik.setFieldValue(`drugsConcomitantly[${i}][indication]`, null)
            }
        }

        formik.values.drugsConcomitantly.pop();
        this.props.drugsConcomitantly.pop();
    }

}

export default withTranslation()(Manifesto);
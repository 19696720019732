import React from 'react';
import { Link } from 'react-router-dom';

export class MenuItem extends React.Component {
    render() {
        var isActive = this.props.history === this.props.to;
        var className = isActive ? 'sidebar-link active' : 'sidebar-link sidebar-link';

        return (
            <>
                <li className="sidebar-item" style={{display:"flex"}}>
                    <div className="sidebar-link" {...this.props}>
                        {this.props.children}
                    </div>
                </li>
            </>
        );
    }
}
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastError = (msg) => {
  toast(msg, {
    position: "top-right",
    closeOnClick: false,
    hideProgressBar: true,
    autoClose: 3500,
    closeButton: false,
    hideProgressBar: true,
    className: "toast-error",
  });
};

export const toastSuccess = (msg) => {
  toast(msg, {
    position: "top-right",
    closeOnClick: false,
    hideProgressBar: true,
    autoClose: 3500,
    closeButton: false,
    hideProgressBar: true,
    className: "toast-success",
  });
};

import React, { Component } from 'react';

export class Logo extends Component {

    render() {
        return (
            <div className="navbar-header" data-logobg="skin6">
                <a className="nav-toggler waves-effect waves-light d-block d-md-none">
                    <i className="ti-menu ti-close"></i>
                </a>
                <div className="navbar-brand">
                    <b className="logo-icon" style={{margin: "auto"}}>
                        <img src="../assets/images/login/logo.png" alt="homepage" className="navbar-logo" />
                    </b>
                </div>
                <a className="topbartoggler d-block d-md-none waves-effect waves-light"
                    data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="ti-more"></i>
                </a>
            </div>
        );
    }
}

import React from "react";
import { useTranslation } from "react-i18next";

function LanguageMenu() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState("en");
  
  function changeLanguage(e) {

    const code = e.target.value;
    
    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      setLanguage(code);
    }
  }

  return (
    <li className="nav-item d-none d-md-block">
        <a className="nav-link">
              <div className="customize-input">
                <select
                  className="custom-select form-control bg-white custom-radius custom-shadow border-0"
                  onChange = {changeLanguage}
                  style={{padding: "10px 43px 10px 28px"}}
                >
                    <option defaultValue="id">ID</option>
                    <option value="en">EN</option>
                </select>
            </div>
        </a>
    </li>
  );
}

export default LanguageMenu;
import React, { Component } from 'react';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';


export class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };

    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        return (
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">Konfirmasi</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>Apakah Anda yakin akan menghapus data ini?</div>
                        <div className='text-right'>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text="Batal"
                                onClick={this.props.onHide} 
                            />
                            <Button 
                                background="#E15756"
                                color="white"
                                type="submit" 
                                text="Ya"
                                onClick={this.props.confirmdelete}
                            />
                        </div>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import DownloadTemplate from "../../components/Utils/ImportDataModal/DownloadTemplate";

import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import ButtonWithIcon from "../../components/Utils/Button/ButtonWithIcon";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ProgressBar from "react-bootstrap/ProgressBar";

import { AEFIService } from "../../services/CaseEntry/AEFIService";
import moment from "moment";

import Modal from "../../components/Utils/Modal/Modal";
import ModalHeader from "../../components/Utils/Modal/ModalHeader";
import ModalContent from "../../components/Utils/Modal/ModalBody";
import Button from "../../components/Utils/Button/Button";

import { seriousness } from "../../helpers/Constants";

import { convertExcelToArray } from "../../components/Utils/ConvertExcel";

import { withTranslation } from "react-i18next";

import ImportStatus from "./ImportStatus";
import { toastError } from '../../components/Utils/Toast/Toast';

class ImportDataModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileChoosen: { name: "Import Excel", detail: "Pilih berkas xls" },
      isImported: false,
      isDone: localStorage.getItem("dataImported")
        ? JSON.parse(localStorage.getItem("dataImported"))["Book In"].filter(function (
          item
        ) {
          return item?.status === undefined;
        }).length === 0
        : false,
      onProgress: localStorage.getItem("dataImported") ? true : false,
      onHold: localStorage.getItem("dataImported") ? true : false,
      itemsExcel: "",
      arrayExport: JSON.parse(localStorage.getItem("dataImported")) ?? [],
      sumData: {
        fail: localStorage.getItem("dataImported")
          ? JSON.parse(localStorage.getItem("dataImported"))["Book In"].filter(function (
            item
          ) {
            return item?.status === 0;
          }).length
          : 0,
        success: localStorage.getItem("dataImported")
          ? JSON.parse(localStorage.getItem("dataImported"))["Book In"].filter(function (
            item
          ) {
            return item?.status === 1;
          }).length
          : 0,
        current: 0,
        total: localStorage.getItem("dataImported")
          ? JSON.parse(localStorage.getItem("dataImported"))["Book In"].length
          : 0,
      },
      dataFailImported: localStorage.getItem("dataImported")
        ? JSON.parse(localStorage.getItem("dataImported"))
        : [],
      idCaseNumber: [],
    };

    this.uploadRef = React.createRef();
  }

  // ageValidation = (value) => {
  //   let age;
  //   if (value) {
  //     const ageRaw = value;
  //     age = ageRaw.match(/\d+/g);

  //     return age;
  //   } else {
  //     return 0;
  //   }
  // };

  getGender = (gender) => {
    const male = "laki-laki";
    const female = "perempuan";

    if (gender) {
      let genders = gender.toLowerCase();

      if (genders === male) {
        gender = "M";
      } else if (genders === female) {
        gender = "F";
      } else {
        gender = "U";
      }
    } else {
      gender = "U";
    }
    return gender;
  };

  limitNameFile = (file) => {
    if (file === undefined) return;
    let nameFile = file;
    let result = `${nameFile.substring(0, 40)}`;
    if (result.endsWith(" ")) {
      result = result.slice(0, result.length - 1);
      result = `${result}...`;
    }
    return result;
  };

  handleCancelImport = () => {
    localStorage.removeItem("dataImported");
    this.setState({
      onProgress: false,
      isImported: false,
      onHold: false,
      sumData: {
        fail: 0,
        success: 0,
        current: 0,
        total: 0,
      },
      arrayExport: [],
      dataFailImported: [],
    });
    if (this.uploadRef.current) {
      this.uploadRef.current.value = "";
    }
  };

  handleImportExcel = async () => {
    this.setState({
      onProgress: true,
      fileChoosen: {
        name: "Import Excel",
        detail: "Pilih berkas xls",
      },
    });

    const file = this.state.isImported;
    let array = this.state.arrayExport;
    
    let dataImport;
    let dataFail = [];
    let idCaseNumber = [];
    let counter = {
      success: this.state.sumData.success ?? 0,
      fail: this.state.sumData.fail ?? 0,
      current:
        Number(this.state.sumData.total) -
        (Number(this.state.sumData.success) + Number(this.state.sumData.fail)),
    };

    if (array?.length < 1 && !localStorage.getItem("dataImported")) {
      const aefi = await convertExcelToArray(file).then(async (res) => {
        array = res;
        await this.setState({
          arrayExport: array,
          sumData: { total: array["Book In"]?.length, current: 0, success: 0, fail: 0 },
        });
      });

      localStorage.setItem("dataImported", JSON.stringify(array));
    }

    const bookInLength = array["Book In"].length;
    const informasiPelaporanLength = array["Informasi Pelaporan"].length;
    const informasiPasienLength = array["Informasi Pasien"].length;
    const pelaksanaanImunisasiLength = array["Pelaksanaan Imunisasi"].length;
    const gejalaKlinisLength = array["Gejala Klinis"].length;
    const hasilManifestasiLength = array["Hasil Manifestasi"].length;

    if (
      bookInLength == informasiPelaporanLength &&
      bookInLength == informasiPasienLength &&
      bookInLength == pelaksanaanImunisasiLength &&
      bookInLength == gejalaKlinisLength &&
      bookInLength == hasilManifestasiLength
    ) {
      array["Book In"].map(async (item, index) => {
        // add delay 1s each row to handle concurrency
        await new Promise(res => setTimeout(res, 1000 * index));
        
        const immunDate = array["Book In"][index]["Tanggal Imunisasi"]
          ? moment(array["Book In"][index]["Tanggal Imunisasi"]).add(1, "day")
          : null;
        const complaintDate = array["Book In"][index]["Tanggal Keluhan"]
          ? moment(array["Book In"][index]["Tanggal Keluhan"]).add(1, "day")
          : null;
        const reportDate = array["Informasi Pelaporan"][index]["Tanggal Pelaporan"]
          ? moment(array["Informasi Pelaporan"][index]["Tanggal Pelaporan"]).add(1, "day")
          : null;
          
        const age_year = array["Informasi Pasien"][index]["Umur (tahun)"];
        const age_month = array["Informasi Pasien"][index]["Umur (bulan)"];
        const age_day = array["Informasi Pasien"][index]["Umur (hari)"];
  
        const symptompDate = array["Gejala Klinis"][index]["Tanggal Muncul"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul"]).add(1, "day")
            : null;
        const symptompDate_1 = array["Gejala Klinis"][index]["Tanggal Muncul_1"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_1"]).add(1, "day")
            : null;
        const symptompDate_2 = array["Gejala Klinis"][index]["Tanggal Muncul_2"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_2"]).add(1, "day")
            : null;
        const symptompDate_3 = array["Gejala Klinis"][index]["Tanggal Muncul_3"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_3"]).add(1, "day")
            : null;
        const symptompDate_4 = array["Gejala Klinis"][index]["Tanggal Muncul_4"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_4"]).add(1, "day")
            : null;
        const symptompDate_5 = array["Gejala Klinis"][index]["Tanggal Muncul_5"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_5"]).add(1, "day")
            : null;
        const symptompDate_6 = array["Gejala Klinis"][index]["Tanggal Muncul_6"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_6"]).add(1, "day")
            : null;
        const symptompDate_7 = array["Gejala Klinis"][index]["Tanggal Muncul_7"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_7"]).add(1, "day")
            : null;
        const symptompDate_8 = array["Gejala Klinis"][index]["Tanggal Muncul_8"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_8"]).add(1, "day")
            : null;
        const symptompDate_9 = array["Gejala Klinis"][index]["Tanggal Muncul_9"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_9"]).add(1, "day")
            : null;
        const allergyDate = array["Gejala Klinis"][index]["Tanggal Muncul_10"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_10"]).add(1, "day")
            : null;
        const allergyDate_1 = array["Gejala Klinis"][index]["Tanggal Muncul_11"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_11"]).add(1, "day")
            : null;
        const allergyDate_2 = array["Gejala Klinis"][index]["Tanggal Muncul_12"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_12"]).add(1, "day")
            : null;
        const allergyDate_3 = array["Gejala Klinis"][index]["Tanggal Muncul_13"]
            ? moment(array["Gejala Klinis"][index]["Tanggal Muncul_13"]).add(1, "day")
            : null;
  
        const treatingDate = array["Hasil Manifestasi"][index]["Tanggal Pengobatan"]
            ? moment(array["Hasil Manifestasi"][index]["Tanggal Pengobatan"]).add(1, "day")
            : null;
        const receiptDate = array["Hasil Manifestasi"][index]["Tanggal Penerimaan"]
            ? moment(array["Hasil Manifestasi"][index]["Tanggal Penerimaan"]).add(1, "day")
            : null;
  
        dataImport = {
          // sheet Book In
          patientName: array["Book In"][index]["Nama Pasien"] ? array["Book In"][index]["Nama Pasien"] : "",
          patientGender: this.getGender(array["Book In"][index]["Jenis Kelamin"]),
          patientCountry: array["Book In"][index]["Negara"] ? array["Book In"][index]["Negara"] : "",
          patientProvince: array["Book In"][index]["Provinsi"] ? array["Book In"][index]["Provinsi"] : "",
          patientCity: array["Book In"][index]["Kota/Kabupaten"] ? array["Book In"][index]["Kota/Kabupaten"] : "",
          immunizationDate: immunDate
            ? moment(immunDate, "DD-MM-YYYY").format("YYYY/MM/DD")
            : null,
          complaintsDate: complaintDate
            ? moment(complaintDate, "DD-MM-YYYY").format("YYYY/MM/DD")
            : null,
  
          // sheet Informasi Pelaporan
          caseCategory: array["Informasi Pelaporan"][index]["Kategori KIPI"] ? array["Informasi Pelaporan"][index]["Kategori KIPI"] : null,
          reportedName: array["Informasi Pelaporan"][index]["Pengirim"] ? array["Informasi Pelaporan"][index]["Pengirim"] : null,
          dataSource: array["Informasi Pelaporan"][index]["Sumber Data"] ? array["Informasi Pelaporan"][index]["Sumber Data"] : null,
          reportingDate: reportDate
            ? moment(reportDate, "DD-MM-YYYY").format("YYYY/MM/DD")
            : null,
  
          // sheet Informasi Pasien
          patientType: array["Informasi Pasien"][index]["Jenis Pasien"] ? array["Informasi Pasien"][index]["Jenis Pasien"] : null,
          patientAgeYear: age_year ? Number(age_year) : 0,
          patientAgeMonth: age_month ? Number(age_month) : 0,
          patientAgeDay: age_day ? Number(age_day) : 0,
  
          // sheet Pelaksanaan Imunisasi
          vaccinator: array["Pelaksanaan Imunisasi"][index]["Pemberi Imunisasi"] ? array["Pelaksanaan Imunisasi"][index]["Pemberi Imunisasi"] : null,
          institution: array["Pelaksanaan Imunisasi"][index]["Tempat Imunisasi"]
            ? array["Pelaksanaan Imunisasi"][index]["Tempat Imunisasi"]
            : null,
          immunizationCountry: array["Pelaksanaan Imunisasi"][index]["Negara"] ? array["Pelaksanaan Imunisasi"][index]["Negara"] : null,
          vaccinesList: [
            {
              vaccines: array["Pelaksanaan Imunisasi"][index]["Vaksin 1"] ? array["Pelaksanaan Imunisasi"][index]["Vaksin 1"] : null,
              doseFor: array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 1 ke"] ? array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 1 ke"] : 0,
              batch: array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 1"] ? array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 1"] : null,
              administrationDate: array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 1"] ? moment(array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 1"], "DD-MM-YYYY").add(1, "day").format("YYYY/MM/DD") : null,
            },
            {
              vaccines: array["Pelaksanaan Imunisasi"][index]["Vaksin 2"] ? array["Pelaksanaan Imunisasi"][index]["Vaksin 2"] : null,
              doseFor: array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 2 ke"] ? array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 2 ke"] : 0,
              batch: array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 2"] ? array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 2"] : null,
              administrationDate: array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 2"] ? moment(array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 2"], "DD-MM-YYYY").add(1, "day").format("YYYY/MM/DD") : null,
            },
            {
              vaccines: array["Pelaksanaan Imunisasi"][index]["Vaksin 3"] ? array["Pelaksanaan Imunisasi"][index]["Vaksin 3"] : null,
              doseFor: array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 3 ke"] ? array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 3 ke"] : 0,
              batch: array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 3"] ? array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 3"] : null,
              administrationDate: array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 3"] ? moment(array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 3"], "DD-MM-YYYY").add(1, "day").format("YYYY/MM/DD") : null,
            },
            {
              vaccines: array["Pelaksanaan Imunisasi"][index]["Vaksin 4"] ? array["Pelaksanaan Imunisasi"][index]["Vaksin 4"] : null,
              doseFor: array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 4 ke"] ? array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 4 ke"] : 0,
              batch: array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 4"] ? array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 4"] : null,
              administrationDate: array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 4"] ? moment(array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 4"], "DD-MM-YYYY").add(1, "day").format("YYYY/MM/DD") : null,
            },
            {
              vaccines: array["Pelaksanaan Imunisasi"][index]["Vaksin 5"] ? array["Pelaksanaan Imunisasi"][index]["Vaksin 5"] : null,
              doseFor: array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 5 ke"] ? array["Pelaksanaan Imunisasi"][index]["Dosis Vaksin 5 ke"] : 0,
              batch: array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 5"] ? array["Pelaksanaan Imunisasi"][index]["No. Batch Vaksin 5"] : null,
              administrationDate: array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 5"] ? moment(array["Pelaksanaan Imunisasi"][index]["Tanggal Pemberian Vaksin 5"], "DD-MM-YYYY").add(1, "day").format("YYYY/MM/DD") : null,
            },
          ],
  
          // sheet Gejala Klinis
          symptompList: [
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 1"]
                ? array["Gejala Klinis"][index]["Gejala 1"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT"]
                ? array["Gejala Klinis"][index]["PT"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC"]
                ? array["Gejala Klinis"][index]["SOC"]
                : null,
              date: symptompDate
                ? moment(symptompDate, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu"]
                ? moment(array["Gejala Klinis"][index]["Waktu"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 2"]
                ? array["Gejala Klinis"][index]["Gejala 2"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_1"]
                ? array["Gejala Klinis"][index]["PT_1"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_1"]
                ? array["Gejala Klinis"][index]["SOC_1"]
                : null,
              date: symptompDate_1
                ? moment(symptompDate_1, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_1"]
                ? moment(array["Gejala Klinis"][index]["Waktu_1"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_1"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_1"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_1"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_1"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_1"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_1"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 3"]
                ? array["Gejala Klinis"][index]["Gejala 3"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_2"]
                ? array["Gejala Klinis"][index]["PT_2"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_2"]
                ? array["Gejala Klinis"][index]["SOC_2"]
                : null,
              date: symptompDate_2
                ? moment(symptompDate_2, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_2"]
                ? moment(array["Gejala Klinis"][index]["Waktu_2"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_2"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_2"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_2"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_2"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_2"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_2"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 4"]
                ? array["Gejala Klinis"][index]["Gejala 4"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_3"]
                ? array["Gejala Klinis"][index]["PT_3"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_3"]
                ? array["Gejala Klinis"][index]["SOC_3"]
                : null,
              date: symptompDate_3
                ? moment(symptompDate_3, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_3"]
                ? moment(array["Gejala Klinis"][index]["Waktu_3"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_3"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_3"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_3"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_3"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_3"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_3"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 5"]
                ? array["Gejala Klinis"][index]["Gejala 5"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_4"]
                ? array["Gejala Klinis"][index]["PT_4"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_4"]
                ? array["Gejala Klinis"][index]["SOC_4"]
                : null,
              date: symptompDate_4
                ? moment(symptompDate_4, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_4"]
                ? moment(array["Gejala Klinis"][index]["Waktu_4"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_4"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_4"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_4"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_4"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_4"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_4"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 6"]
                ? array["Gejala Klinis"][index]["Gejala 6"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_5"]
                ? array["Gejala Klinis"][index]["PT_5"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_5"]
                ? array["Gejala Klinis"][index]["SOC_5"]
                : null,
              date: symptompDate_5
                ? moment(symptompDate_5, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_5"]
                ? moment(array["Gejala Klinis"][index]["Waktu_5"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_5"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_5"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_5"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_5"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_5"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_5"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 7"]
                ? array["Gejala Klinis"][index]["Gejala 7"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_6"]
                ? array["Gejala Klinis"][index]["PT_6"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_6"]
                ? array["Gejala Klinis"][index]["SOC_6"]
                : null,
              date: symptompDate_6
                ? moment(symptompDate_6, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_6"]
                ? moment(array["Gejala Klinis"][index]["Waktu_6"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_6"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_6"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_6"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_6"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_6"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_6"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 8"]
                ? array["Gejala Klinis"][index]["Gejala 8"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_7"]
                ? array["Gejala Klinis"][index]["PT_7"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_7"]
                ? array["Gejala Klinis"][index]["SOC_7"]
                : null,
              date: symptompDate_7
                ? moment(symptompDate_7, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_7"]
                ? moment(array["Gejala Klinis"][index]["Waktu_7"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_7"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_7"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_7"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_7"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_7"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_7"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 9"]
                ? array["Gejala Klinis"][index]["Gejala 9"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_8"]
                ? array["Gejala Klinis"][index]["PT_8"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_8"]
                ? array["Gejala Klinis"][index]["SOC_8"]
                : null,
              date: symptompDate_8
                ? moment(symptompDate_8, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_8"]
                ? moment(array["Gejala Klinis"][index]["Waktu_8"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_8"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_8"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_8"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_8"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_8"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_8"])
                : null,
            },
            {
              symptomp: array["Gejala Klinis"][index]["Gejala 10"]
                ? array["Gejala Klinis"][index]["Gejala 10"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_9"]
                ? array["Gejala Klinis"][index]["PT_9"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_9"]
                ? array["Gejala Klinis"][index]["SOC_9"]
                : null,
              date: symptompDate_9
                ? moment(symptompDate_9, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_9"]
                ? moment(array["Gejala Klinis"][index]["Waktu_9"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_9"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_9"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_9"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_9"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_9"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_9"])
                : null,
            },
          ],
          allergyList: [
            {
              allergicReaction: array["Gejala Klinis"][index]["Reaksi Alergi 1"]
                ? array["Gejala Klinis"][index]["Reaksi Alergi 1"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_10"]
                ? array["Gejala Klinis"][index]["PT_10"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_10"]
                ? array["Gejala Klinis"][index]["SOC_10"]
                : null,
              date: allergyDate
                ? moment(allergyDate, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_10"]
                ? moment(array["Gejala Klinis"][index]["Waktu_10"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_10"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_10"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_10"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_10"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_10"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_10"])
                : null,
            },
            {
              allergicReaction: array["Gejala Klinis"][index]["Reaksi Alergi 2"]
                ? array["Gejala Klinis"][index]["Reaksi Alergi 2"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_11"]
                ? array["Gejala Klinis"][index]["PT_11"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_11"]
                ? array["Gejala Klinis"][index]["SOC_11"]
                : null,
              date: allergyDate_1
                ? moment(allergyDate_1, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_11"]
                ? moment(array["Gejala Klinis"][index]["Waktu_11"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_11"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_11"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_11"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_11"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_11"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_11"])
                : null,
            },
            {
              allergicReaction: array["Gejala Klinis"][index]["Reaksi Alergi 3"]
                ? array["Gejala Klinis"][index]["Reaksi Alergi 3"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_12"]
                ? array["Gejala Klinis"][index]["PT_12"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_12"]
                ? array["Gejala Klinis"][index]["SOC_12"]
                : null,
              date: allergyDate_2
                ? moment(allergyDate_2, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_12"]
                ? moment(array["Gejala Klinis"][index]["Waktu_12"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_12"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_12"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_12"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_12"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_12"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_12"])
                : null,
            },
            {
              allergicReaction: array["Gejala Klinis"][index]["Reaksi Alergi 4"]
                ? array["Gejala Klinis"][index]["Reaksi Alergi 4"]
                : null,
              preferredTerm: array["Gejala Klinis"][index]["PT_13"]
                ? array["Gejala Klinis"][index]["PT_13"]
                : null,
              systemOrganClass: array["Gejala Klinis"][index]["SOC_13"]
                ? array["Gejala Klinis"][index]["SOC_13"]
                : null,
              date: allergyDate_3
                ? moment(allergyDate_3, "DD-MM-YYYY").format("YYYY/MM/DD")
                : null,
              time: array["Gejala Klinis"][index]["Waktu_13"]
                ? moment(array["Gejala Klinis"][index]["Waktu_13"]).format("hh:mm")
                : null,
              durationMinute: array["Gejala Klinis"][index]["Durasi (Menit)_13"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Menit)_13"])
                : null,
              durationHour: array["Gejala Klinis"][index]["Durasi (Jam)_13"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Jam)_13"])
                : null,
              durationDay: array["Gejala Klinis"][index]["Durasi (Hari)_13"]
                ? Number(array["Gejala Klinis"][index]["Durasi (Hari)_13"])
                : null,
            },
          ],
  
          // sheet Hasil Manifestasi
          resultChronology: array["Hasil Manifestasi"][index]["Kronologi"] ? array["Hasil Manifestasi"][index]["Kronologi"] : null,
          treatmentDate: treatingDate
            ? moment(treatingDate, "DD-MM-YYYY").format("YYYY/MM/DD")
            : null,
          diagnosisList: [
            {
              diagnosis: array["Hasil Manifestasi"][index]["Diagnosa"] ? array["Hasil Manifestasi"][index]["Diagnosa"] : null,
              resultPreferredTerm: array["Hasil Manifestasi"][index]["PT"] ? array["Hasil Manifestasi"][index]["PT"] : null,
              resultSystemOrganClass: array["Hasil Manifestasi"][index]["SOC"] ? array["Hasil Manifestasi"][index]["SOC"] : null,
            }
          ],
          receiptedDate: receiptDate
            ? moment(receiptDate, "DD-MM-YYYY").format("YYYY/MM/DD")
            : null,
          resultSeriousLevel: array["Hasil Manifestasi"][index]["Tingkat Keseriusan"] ? array["Hasil Manifestasi"][index]["Tingkat Keseriusan"] : null,
          resultSeriousReasons: array["Hasil Manifestasi"][index]["Alasan Keseriusan"] ? array["Hasil Manifestasi"][index]["Alasan Keseriusan"] : null,
          expectednessCategory: array["Hasil Manifestasi"][index]["Kategori Expectedness"] ? array["Hasil Manifestasi"][index]["Kategori Expectedness"] : null,
          outcome: array["Hasil Manifestasi"][index]["Hasil Akhir"] ? array["Hasil Manifestasi"][index]["Hasil Akhir"] : null,
        }
  
        if (dataFail?.length < 1) {
          if (item?.status !== 0 || item?.status !== 1) {
            await AEFIService.ImportData(dataImport).then(
              async (result) => {
                array["Book In"][index].date = moment().format("dddd MMMM YYYY");
                array["Book In"][index].status = 1;
                counter.success += 1;
                counter.current += 1;
                localStorage.setItem("dataImported", JSON.stringify(array));
    
                await this.incrementImport(true, index);
    
                this.setState({ idCaseNumber: idCaseNumber });
              },
              async (error) => {
                await this.addToFailData(dataFail, array, index, counter, error);
              }
            );
          }
        } else {
          await this.addToFailData(dataFail, array, index, counter);
        }
      })
  
      //set to default if processing has done
      this.setState({
        dataFailImported: dataFail,
        isImported: false,
        isDone: true,
        onHold: false,
      });
    } else {
      toastError("Terdapat jumlah data yang berbeda antar sheet excel!");
    }

    
  };

  addToFailData = async (dataFail, array, index, counter, error) => {
    if (error?.includes("Failed to fetch")) {
      array["Book In"][index].date = moment().format("dddd MMMM YYYY");
    } else {
      array["Book In"][index].status = 0;
      array["Book In"][index].date = moment().format("dddd MMMM YYYY");
      array["Book In"][index].errorMessage = error ? error : null;
    }
    let listData = {
      "status": 0,
      "Book In": array["Book In"][index],
      "Informasi Pelaporan": array["Informasi Pelaporan"][index],
      "Informasi Pasien": array["Informasi Pasien"][index],
      "Pelaksanaan Imunisasi": array["Pelaksanaan Imunisasi"][index],
      "Gejala Klinis": array["Gejala Klinis"][index],
      "Hasil Manifestasi": array["Hasil Manifestasi"][index],
    }
    dataFail.push(listData);
    counter.fail += 1;
    counter.current += 1;
    await this.incrementImport(false, index);
    localStorage.setItem("dataImported", JSON.stringify(array));
  }

  incrementImport = async (success, index) => {
    let incSuccess = success ? 1 : 0;
    let incFailed = success ? 0 : 1;
    let array = this.state.arrayExport;
    let done = this.state.sumData.current == this.state.sumData.total - 1;

    await this.setState({
      arrayExport: array,
      sumData: {
        total: this.state.sumData.total,
        fail: this.state.sumData.fail + incFailed,
        success: this.state.sumData.success + incSuccess,
        current: this.state.sumData.current + 1,
      },
      isDone: done,
      onProgress: !done,
    });

    let data = JSON.parse(localStorage.getItem("dataImported"));
  };

  closeImportModal = () => {
    localStorage.removeItem("dataImported");
    this.setState(
      {
        fileChoosen: { name: "Import Excel", detail: "Pilih berkas xls" },
        isImported: false,
        onProgress: false,
        isDone: false,
        onHold: false,
        itemsExcel: "",
        arrayExport: [],
        sumData: { fail: 0, success: 0, current: 0, total: 0 },
        dataFailImported: [],
      },
      this.props.refresh()
    );
    if (this.uploadRef.current) {
      this.uploadRef.current.value = "";
    }
    this.props.onHide();
  };

  readExcel = (file) => {
    this.setState({
      fileChoosen: { name: file?.name, detail: "Berkas dipilih" },
      isImported: file,
      sumData: {
        fail: 0,
        success: 0,
        current: 0,
        total: 0,
      },
      arrayExport: [],
      dataFailImported: [],
    });
    
    localStorage.removeItem("dataImported");
  };

  exportExcelFail = (listDataFail) => {
    const headerBookIn = ["No", "Nama Pasien",	"Jenis Kelamin", "Negara", "Provinsi", "Kota/Kabupaten",	
      "Tanggal Imunisasi","Tanggal Keluhan"];
    const headerInformasiPelaporan = ["No", "Kategori KIPI",	"Pengirim",	"Sumber Data",	"Tanggal Pelaporan"];
    const headerInformasiPasien = ["No",	"Jenis Pasien",	"Umur (tahun)",	"Umur (bulan)",	"Umur (hari)"];
    const headerPelaksanaanImunisasi = ["No", "Pemberi Imunisasi", "Tempat Imunisasi", "Negara", "Vaksin 1",	"Dosis Vaksin 1 ke",	"No. Batch Vaksin 1",	"Tanggal Pemberian Vaksin 1",	
      "Vaksin 2",	"Dosis Vaksin 2 ke", "No. Batch Vaksin 2", "Tanggal Pemberian Vaksin 2", 
      "Vaksin 3",	"Dosis Vaksin 3 ke", "No. Batch Vaksin 3",	"Tanggal Pemberian Vaksin 3",
      "Vaksin 4",	"Dosis Vaksin 4 ke", "No. Batch Vaksin 4",	"Tanggal Pemberian Vaksin 4",	
      "Vaksin 5",	"Dosis Vaksin 5 ke", "No. Batch Vaksin 5",	"Tanggal Pemberian Vaksin 5"
    ];
    const headerGejalaKlinis = ["No", 
      "Gejala 1", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 2", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 3", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 4", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 5", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 6", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 7", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 8", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 9", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Gejala 10", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Reaksi Alergi 1", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Reaksi Alergi 2", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Reaksi Alergi 3", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
      "Reaksi Alergi 4", "PT", "SOC", "Tanggal Muncul", "Waktu", "Durasi (Menit)", "Durasi (Jam)", "Durasi (Hari)",
    ];
    const headerHasilManifestasi = ["No",	"Kronologi",	"Tanggal Pengobatan",	"Diagnosa",	"PT",	"SOC",	"Tanggal Penerimaan",	"Tingkat Keseriusan",	"Alasan Keseriusan",	"Kategori Expectedness",	"Hasil Akhir"];

    const headerError = ["No", "error message"];

    var wb = XLSX.utils.book_new();
    var ws;

    let listDataRowBookIn = [];
    let listDataRowInformasiPelaporan = [];
    let listDataRowInformasiPasien = [];
    let listDataRowPelaksanaanImunisasi = [];
    let listDataRowGejalaKlinis = [];
    let listDataRowHasilManifestasi = [];

    let listMessageError = [];

    let dataFail=[];
    if (!listDataFail.length) {
      const dataConvert = listDataFail["Book In"].map((bookInItem, index) => {
        return {
          "Book In": bookInItem,
          "Informasi Pelaporan": listDataFail["Informasi Pelaporan"][index],
          "Informasi Pasien": listDataFail["Informasi Pasien"][index],
          "Pelaksanaan Imunisasi": listDataFail["Pelaksanaan Imunisasi"][index],
          "Hasil Manifestasi": listDataFail["Hasil Manifestasi"][index],
          "Gejala Klinis": listDataFail["Gejala Klinis"][index]
        };
      });
      dataFail = dataConvert
    } else {
      dataFail = listDataFail
    }

    dataFail.map((rowData, indexData) => {
      const newObjData = {};
      const messageError = {};
      for (const key in rowData) {
        const { errorMessage, status, date, ...dataDeleted } = rowData[key];
        messageError[key] = errorMessage;
        newObjData[key] = dataDeleted;
      }
      if (messageError["Book In"]) {
        listMessageError.push([[ newObjData["Book In"].No ],[messageError["Book In"]]]);
      }

      listDataRowBookIn.push(Object.values(newObjData["Book In"]).map(value => value));
      listDataRowInformasiPelaporan.push(Object.values(newObjData["Informasi Pelaporan"]).map(value => value));
      listDataRowInformasiPasien.push(Object.values(newObjData["Informasi Pasien"]).map(value => value));
      listDataRowPelaksanaanImunisasi.push(Object.values(newObjData["Pelaksanaan Imunisasi"]).map(value => value));
      listDataRowGejalaKlinis.push(Object.values(newObjData["Gejala Klinis"]).map(value => value));
      listDataRowHasilManifestasi.push(Object.values(newObjData["Hasil Manifestasi"]).map(value => value));
    });

    ws = XLSX.utils.aoa_to_sheet( [headerError, ...listMessageError], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Error");

    ws = XLSX.utils.aoa_to_sheet( [headerBookIn, ...listDataRowBookIn], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Book In");

    ws = XLSX.utils.aoa_to_sheet( [headerInformasiPelaporan, ...listDataRowInformasiPelaporan], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Informasi Pelaporan");

    ws = XLSX.utils.aoa_to_sheet( [headerInformasiPasien, ...listDataRowInformasiPasien], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Informasi Pasien");
    
    ws = XLSX.utils.aoa_to_sheet( [headerPelaksanaanImunisasi, ...listDataRowPelaksanaanImunisasi], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Pelaksanaan Imunisasi");
    
    ws = XLSX.utils.aoa_to_sheet( [headerGejalaKlinis, ...listDataRowGejalaKlinis], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Gejala Klinis");
    
    ws = XLSX.utils.aoa_to_sheet( [headerHasilManifestasi, ...listDataRowHasilManifestasi], {
      sheetStubs: true,
      cellStyles: true,
    }); 
    ws["!cols"] = XLSX.utils.book_append_sheet(wb, ws, "Hasil Manifestasi");

    /* write workbook (use type 'binary') */
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }

    FileSaver.saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "PV_Fail-Imported.xlsx"
    );
  };

  render() {
    const { t } = this.props;
    const {
      fileChoosen,
      isImported,
      onProgress,
      idCaseNumber,
      isDone,
      sumData,
      onHold,
    } = this.state;
    const isData = JSON.parse(localStorage.getItem("dataImported"));
    const succesImport = isData
      ? isData["Book In"].filter(function (item) {
        return item?.status === 1;
      })
      : [];
    const lastCaseNumber = idCaseNumber.length - 1;
    const textSuccess = `${t("successfully_import")}`;

    const current_total =
      Number(this.state.sumData.total) -
      (Number(this.state.sumData.success) + Number(this.state.sumData.fail));

    return (
      <div className="container">
        <Modal {...this.props} size="lg">
          <ModalHeader closeButton className="row align-items-center">
            <div className="modal-title col-6">{t("import_data")}</div>
            <div
              className="icon-wrapper col-1 offset-md-5"
              onClick={() => this.closeImportModal()}
            >
              <IconButton
                style={{ marginTop: "-20px" }}
                key="close"
                arial-label="Close"
                color="#495057"
                size="small"
                ref={onHold && current_total > 0 ? "" : this.ref}
              >
                {" "}
                X{" "}
              </IconButton>
            </div>
          </ModalHeader>
          <hr />
          <ModalContent>
            <>
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-12">
                  <DownloadTemplate />
                  <div
                    className="import-data row"
                    style={{
                      background: "#F6F6F6",
                      borderRadius: 6,
                      padding: "5%",
                      margin: 0,
                      border: onHold ? "solid 1px #EA4F2D" : "none",
                    }}
                  >
                    <div className="file-logo col-2">
                      {onHold ? (
                        <ErrorOutlineIcon
                          style={{ fontSize: 50, color: "#EA4F2D" }}
                        />
                      ) : (
                        <InsertDriveFileIcon
                          style={{ fontSize: 50, color: "#AAAAAA" }}
                        />
                      )}
                    </div>
                    <div className="file-info col-8">
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          color: onHold ? "#EA4F2D" : "#495057",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            color: onHold ? "#EA4F2D" : "#495057",
                          }}
                        >
                          <div>
                            {onHold
                              ? this.limitNameFile(
                                `File data ${isData?.length > 0 ? isData[0]?.date : ""
                                }`
                              )
                              : this.limitNameFile(fileChoosen.name)}
                          </div>
                          {onProgress && (
                            <div>
                              {(
                                ((Number(this.state.sumData.success) +
                                  Number(this.state.sumData.fail)) /
                                  Number(this.state.sumData.total)) *
                                100
                              ).toFixed(2) + " %"}
                            </div>
                          )}
                        </div>
                        {onHold && (
                          <div style={{ fontWeight: 500, fontSize: 12 }}>
                            {t("resume_information")}
                          </div>
                        )}
                      </span>
                      <br />
                      {onProgress ? (
                        <span
                          style={{ fontSize: "16px", color: "#495057" }}
                        ></span>
                      ) : (
                        <span style={{ fontSize: "16px", color: "#495057" }}>
                          {fileChoosen.detail}
                        </span>
                      )}

                      <br />
                      {(onHold || onProgress) && (
                        <ProgressBar
                          now={
                            ((Number(this.state.sumData.success) +
                              Number(this.state.sumData.fail)) /
                              Number(this.state.sumData.total)) *
                            100
                          }
                          variant={onHold ? "danger" : ""}
                          style={{
                            height: "0.5rem",
                            color: onHold ? "#EA4F2D" : "",
                          }}
                        />
                      )}
                    </div>
                    <div
                      className="import-file col-2 align-self-center"
                      style={{
                        height: "50px",
                        borderLeft: "1px solid #D2D2D2",
                      }}
                    >
                      <ButtonWithIcon
                        style={{
                          paddingTop: "1em",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        text={onProgress ? t("cancel") : t("import")}
                        background="transparent"
                        padding="0"
                        color={
                          onHold
                            ? "#EA4F2D"
                            : !isImported
                              ? "#D2D2D2"
                              : this.state.arrayExport
                                ? "#495057"
                                : "#EA4F2D"
                        }
                        icon={
                          !onHold
                            ? "fa fa-download"
                            : onProgress
                              ? "fa fa-times"
                              : "fa fa-download"
                        }
                        onClick={
                          onHold
                            ? () => this.handleCancelImport()
                            : !onProgress
                              ? () => this.handleImportExcel()
                              : () => this.handleCancelImport()
                        }
                        isSubmitting={!onHold && !isImported}
                      />
                    </div>
                    <input
                      id="input-file"
                      ref={onHold ? "" : this.uploadRef}
                      type="file"
                      style={{
                        cursor: onHold ? "" : "pointer",
                        fontSize: "25px",
                        zIndex: 1,
                        lineHeight: "60px",
                        marginTop: "-60px",
                        opacity: 0,
                      }}
                      onChange={
                        onHold
                          ? ""
                          : (e) => {
                            const file = e.target.files[0];
                            this.readExcel(file);
                          }
                      }
                      disabled={onHold}
                    />
                  </div>

                  {(onProgress || isDone) && (
                    <div
                      className="upload-status"
                      style={{
                        marginTop: "2em",
                      }}
                    >
                      {onHold && current_total > 0 && (
                        <ImportStatus
                          info
                          total={current_total}
                          information={t("unprocessed")}
                          onHold={onHold}
                          continueLabel={t("continue")}
                          toggle={() => this.handleImportExcel()}
                        />
                      )}
                      <ImportStatus
                        success
                        total={Number(this.state.sumData.success)}
                        information={textSuccess}
                      />

                      <ImportStatus
                        failed
                        total={Number(this.state.sumData.fail)}
                        information={t("failed_import")}
                        toggle={() =>
                          this.exportExcelFail(this.state.dataFailImported)
                        }
                        download={t("download_file")}
                        done={isDone}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="text-right">
                <Button
                  color="#495057"
                  background="#FFFFFF 0% 0% no-repeat padding-box"
                  border="1px solid #DFDFDF"
                  text={t("close")}
                  margin="100px 0px 0px 0px"
                  onClick={() => this.closeImportModal()}
                />
              </div>
            </>
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ImportDataModal));

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../components/Utils/Button/Button';
import Tab from '../../../components/Utils/Tab/Tab';
import ReportInformation from './Tab/ReportInformation';
import ComplaintInformation from './Tab/ComplaintInformation';
import AnalysisResult from './Tab/AnalysisResult';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { DropdownService } from '../../../services/Master/AllDropdownService';
import { PartnerService } from '../../../services/Partner/PartnerService';

import { withTranslation } from "react-i18next";

class AddPartner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            backTab: "",
            nextTab: "complaint-information",
            currentTab: "report-information",
            historyForm: [],
            reporterDisabled: true,
            complaintDisabled: true,
            analysisDisabled: true
        };

        this.formikRef = React.createRef();
        this.getCaseEntryData();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#report-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "report-information", backTab: "", nextTab: "complaint-information"
                });
                break;
            case "#complaint-information":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "complaint-information", backTab: "report-information", nextTab: "analysis-result"
                });
                break;
            case "#analysis-result":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true,
                    currentTab: "analysis-result", backTab: "complaint-information", nextTab: ""
                });
                break;
        }

        event.preventDefault();
    }

    handleDisabledNext(tab, status) {
        switch (tab) {
            case "#report-information":
                this.setState({
                    reporterDisabled: status
                });
                break;
            case "#complaint-information":
                this.setState({
                    complaintDisabled: this.state.reporterDisabled ? true : status
                });
                break;
            case "#analysis-result":
                this.setState({
                    analysisDisabled: this.state.reporterDisabled ? true : status
                });
                break;
        }
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
        this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    document.getElementById("link-" + this.state.nextTab).click();
                }
            }
        )
        event.preventDefault();
    }  

    handleGetData(data) {
        this.setState({ caseEntryData: data });
    }

    handleCausality(data) {
        this.setState({ causality_value: data });
    }

    getCaseEntryData() {

        DropdownService.MasterList([
            "aeCategory", "aefiCategory", "vaccine", "vaccinator", "drugs", "partner",
            "seriousness", "expectedness", "outcome",
        ]).then(
            (result) => {
                this.setState({ list: result.data });
            })
    }

    render() {

        const { t } = this.props;

        const initialValues = {
            partner: "",
            caseNumber: "",
        }

        const initialTouched = {
            partner: true,
            caseNumber: true,
        }

        const validationSchemaDict = {
            "report-information": 
            Yup.object().shape({
                partner: Yup.string().required("Required").nullable(),
                caseNumber : Yup.string().required("Required").nullable(),
            })
        }

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber={true}
                                title={t('report_information')}
                                href="#report-information"
                                onClick={(event) => this.handleTabChange(event, "#report-information")}
                                id="link-report-information"
                            />
                            <Tab
                                number="2"
                                showNumber={true}
                                title={t('complaint_information')}
                                href="#complaint-information"
                                onClick={(event) => this.handleTabChange(event, "#complaint-information")}
                                id="link-complaint-information"
                                disabled={this.state.reporterDisabled}
                            />
                            <Tab
                                number="3"
                                showNumber={true}
                                title={t('analysa_result')}
                                href="#analysis-result"
                                onClick={(event) => this.handleTabChange(event, "#analysis-result")}
                                id="link-analysis-result"
                                disabled={this.state.reporterDisabled}
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    initialValues={initialValues}
                    initialTouched={initialTouched}
                    validationSchema={validationSchemaDict[this.state.currentTab]}

                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        values.typeCaseEntry = values.caseType === "KIPI" ? "AEFI" : "AE"

                        PartnerService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push(`/report-submission/partner`);
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )
                    }}
                >
                    {formik =>

                        (
                            <Form>
                                <div className="tab-content">

                                    <ReportInformation
                                        formik={formik}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        updatedata={(data) => this.handleGetData(data)}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                        list={this.state.list}
                                        history={this.props.history}
                                        disabled={this.state.reporterDisabled}
                                    />

                                    <ComplaintInformation
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                        list={this.state.list}
                                        disabled={this.state.complaintDisabled}
                                    />

                                    <AnalysisResult
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                        list={this.state.list}
                                        disabled={this.state.analysisDisabled}
                                    />

                                    {
                                        this.state.nextTab !== "" && this.state.backTab !== "" && 
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay>
                                                <Button
                                                    text={t('next')}
                                                    onClick={(event) => this.handleNextClick(event)} />
                                            </ToggleDisplay>
                                        </div>
                                    }

                                    {
                                        this.state.nextTab == "" &&

                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text={t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    type="submit"
                                                    text={t('save')}
                                                    disabled={formik.isSubmitting} />
                                            </ToggleDisplay>
                                            {
                                                formik.status &&
                                                <div className={'alert alert-danger'}>{formik.status}</div>
                                            }
                                        </div>

                                    }

                                </div>
                            </Form>
                        )
                    }
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddPartner)
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import moment from "moment";

import { Role, Status } from "../../../helpers/Constants";
import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";

import CaseInformation from "./Tab/CaseInformation";
import Investigation from "./Tab/Investigation";
import QualityReview from "./Tab/QualityReview";
import Conclusion from "./Tab/Conclusion";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { HistoryService } from "../../../services/HistoryService";
import { SummaryService } from "../../../services/QualityReview/Summary/SummaryService";
import { authenticationService } from "../../../services/AuthService";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";

export class DetailSummary extends Component {
  constructor(props) {
    super(props);

    const { type, id } = this.props.match.params;
    const { page } = this.props?.location?.state;

    this.state = {
      id: id,
      type: type,
      backTab: "",
      nextTab: "investigation",
      historyForm: [],
      caseEntryData: [],
      caseInformationDisabled: true,
      investigationDisabled: true,
      conclusionDisabled: true,
      qualityReviewDisabled: true,
      data: [],
      historys: [],
      historydata: [],
      historysCaseEntry: [],
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      versionOptions: [],
      isLastVersion: true,
      page: page,
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.prepareForm(id);
    this.historyList(id);
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#case-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          backTab: "",
          nextTab: "investigation",
        });
        break;
      case "#investigation":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          backTab: "case-information",
          nextTab: "quality-review",
          externalDisabled: false,
        });
        break;
      case "#quality-review":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          backTab: "investigation",
          nextTab: "conclusion",
          unitDisabled: false,
        });
        break;
      case "#conclusion":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          backTab: "quality-review",
          nextTab: "",
          unitDisabled: false,
        });
        break;
    }

    event.preventDefault();
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push(`/medical-review/summary`, {
      pages: this.state.page,
    });
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  prepareForm(id) {
    SummaryService.PrepareForm(id).then((result) => {
      if (result.data) {
        this.setState({
          data: result.data,
          caseEntryData:
            result.data.aefiCaseEntryNavigation !== null
              ? result.data.aefiCaseEntryNavigation
              : result.data.aeCaseEntryNavigation,
        });        
        this.historyCaseEntryList(result.data.caseEntry)
        var versionOptions = result.data.trailSummary
          ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
          .map((trail) => {
            return {
              value: trail.followUp === null ? 0 : trail.followUp,
              label:
                trail.followUp === 0 || trail.followUp === null
                  ? "Inisial"
                  : "Follow Up " + trail.followUp,
            };
          });
        versionOptions.push({
          value: result.data.followUp,
          label:
            result.data.followUp === 0
              ? "Inisial"
              : "Follow Up " + result.data.followUp,
        });

        if (versionOptions) {
          this.setState({
            versionOptions: versionOptions,
            selectedVersion: versionOptions[versionOptions.length - 1],
            lastVersion: versionOptions[versionOptions.length - 1],
            isLastVersion: true,
          });
        }

        let beforeVersion =
          this.state.selectedVersion?.value === 0
            ? 0
            : this.state.selectedVersion?.value - 1;
        SummaryService.PrepareForm(id, beforeVersion).then((result) => {
          if (result.data) {
            this.setState({
              historyData: result.data,
            });
          }
        });
      }
    });
  }

  historyList(id) {
    HistoryService.ReadQualityReview(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {       
    HistoryService.Read(id,'AEFI').then((result) => {
        this.setState({ historysCaseEntry: result.data });        
    });
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        beforeVersion: item.value === 0 ? 0 : item.value - 1,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        const { id } = this.props.match.params;

        SummaryService.PrepareForm(id, this.state.selectedVersion.value).then(
          (result) => {
            if (result.data) {
              this.setState({
                data: result.data,
              });
            }
          }
        );

        SummaryService.PrepareForm(id, this.state.beforeVersion).then(
          (result) => {
            if (result.data) {
              this.setState({
                historyData: result.data,
              });
            }
          }
        );
      }
    );
  }

  render() {
    const { data, isLastVersion } = this.state;

    const historyData = this.state.historyData ? this.state.historyData : [];
    const historyCaseEntry = this.state.historyCaseEntryData
      ? this.state.historyCaseEntryData
      : [];

    const formType = this.state.type ? this.state.type : "edit";

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (data?.status === Status.Pending ||
        data?.status === Status.Draft ||
        data?.status === Status.NotOk ||
        data?.status === Status.NotApproved ||
        this.state.type === "follow-up" ||
        data?.status === Status.Approved ||
        (formType !== "edit" &&
          data?.status === Status.Closed &&
          isLastVersion));
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (data?.status === Status.Draft || data?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (data?.status === Status.Draft || data?.status === Status.Reviewed);

    const initialValues = {
      id: data?.id,
      caseEntry: data?.aefiCaseEntry ? data?.aefiCaseEntry : data?.aeCaseEntry,
      caseNumber: data?.caseNumber,
      caseType: data?.caseType,
      reporterName: data?.reporterName,
      chronology: data?.chronology,
      complaint: data?.complaint,
      adversePvDate: data?.adversePvDate
        ? moment(data?.adversePvDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      patientName: data?.patientName,
      patientAgeOfYear: data?.patientAgeOfYear,
      patientAgeOfMonth: data?.patientAgeOfMonth,
      diagnosis: data?.diagnosis,
      correction: data?.correction,
      correctiveAction: data?.correctiveAction,
      preventiveAction: data?.preventiveAction,
      reviewOfDay: data?.reviewOfDay,
      bprReview: data?.bprReview,
      investigationReview: data?.investigationReview,
      summaryBatchReview: data?.summaryBatchReview,
      analysisBy: data?.analysisBy,
      analysisByLabel: data?.analysisByNavigation?.name,
      analysisByProvince: data?.analysisByProvince,
      analysisByProvinceLabel: data?.analysisByProvinceNavigation?.name,
      attachmentAe: data?.attachmentAe,
      attachmentAefi: data?.attachmentAefi,
      attachmentAefiNonSerious: data?.attachmentAefiNonSerious,
      attachmentAefiOverseas: data?.attachmentAefiOverseas,
      attachmentAnalysisAe: data?.attachmentAnalysisAe,
      attachmentAnalysisAefiComplete: data?.attachmentAnalysisAefiComplete,
      attachmentAnalysisAefiIncomplete: data?.attachmentAnalysisAefiIncomplete,
      attachmentBpom: data?.attachmentBpom,
      attachmentManufactureGrowth: data?.attachmentManufactureGrowth,
      attachmentManufactureInitial: data?.attachmentManufactureInitial,
      attachmentPartner: data?.attachmentPartner,
      attachmentReporter: data?.attachmentReporter,
      attachmentResume: data?.attachmentResume,
      summaryProduct: data?.summaryProduct ?? data?.trailSummaryProduct,
      summaryProductAnalysis:
        data?.summaryProductAnalysis ?? data?.trailSummaryProductAnalysis,
      summaryProductQualityReview:
        data?.summaryProductQualityReview ??
        data?.trailSummaryProductQualityReview,
      summarySameBatchProduct:
        data?.summarySameBatchProduct ?? data?.trailSummarySameBatchProduct,
      seriousness: data?.seriousness,
      seriousnessLabel: data?.seriousnessNavigation?.name ?? "",
    };

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };

    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const { t } = this.props;

    return (
      <>
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
          id={data?.id}
          title={t("cases_history")}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          id={data?.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />
        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          menu="medicalReview"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          menu="medicalReview"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />
                  {currentUser.role !== Role.RegulasiMutuSistem && (
                    <>
                      <span
                        className="nav-icon-fitur"
                        onClick={() =>
                          this.setState({ HistoryModalShow: true })
                        }
                      >
                        <FontAwesomeIcon icon={faHistory} />
                      </span>
                      <span
                        className="nav-icon-fitur"
                        onClick={() =>
                          this.setState({ CommentModalShow: true })
                        }
                      >
                        <FontAwesomeIcon icon={faComments} />
                      </span>
                    </>
                  )}
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("case_information")}
                  href="#case-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#case-information")
                  }
                  id="link-case-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("investigation")}
                  href="#investigation"
                  onClick={(event) =>
                    this.handleTabChange(event, "#investigation")
                  }
                  id="link-investigation"
                />
                <Tab
                  number="3"
                  showNumber={true}
                  title="Quality Review"
                  href="#quality-review"
                  onClick={(event) =>
                    this.handleTabChange(event, "#quality-review")
                  }
                  id="link-quality-review"
                />
                <Tab
                  number="4"
                  showNumber={true}
                  title={t("conclusion")}
                  href="#conclusion"
                  onClick={(event) =>
                    this.handleTabChange(event, "#conclusion")
                  }
                  id="link-conclusion"
                />
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            // caseNumber : Yup.string().required("Required").nullable(),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            if (formType !== "edit" && isLastVersion) {
              values.isFollowup = true;
            }

            values.caseEntryType = values.caseType;

            SummaryService.Update(values.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/medical-review/summary`);
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                {/* Case Information Tab */}
                <CaseInformation
                  formik={formik}
                  formType={formType}
                  currentUser={currentUser}
                  historydata={historyData}
                  isLastVersion={isLastVersion}
                />

                {/* Investigation */}
                <Investigation
                  formik={formik}
                  formType={formType}
                  currentUser={currentUser}
                  historydata={historyData}
                  isLastVersion={isLastVersion}
                />

                {/* Quality Review */}
                <QualityReview
                  formik={formik}
                  formType={formType}
                  currentUser={currentUser}
                  historydata={historyData}
                  isLastVersion={isLastVersion}
                />

                {/* Conclusion */}
                <Conclusion
                  formik={formik}
                  formType={formType}
                  currentUser={currentUser}
                  historydata={historyData}
                  isLastVersion={isLastVersion}
                />
                {this.state.backTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleCancelClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab !== "" && this.state.backTab !== "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    {/* Simpan */}
                    {canStaffEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          isSubmitting={
                            !this.state.isLastVersion || formik.isSubmitting
                          }
                        />
                      </ToggleDisplay>
                    )}

                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />

                    {/* Reject */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isApprove", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isReview", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            currentUser.role === Role.Unit
                              ? "Forward"
                              : "Approve"
                          }
                          onClick={() => {
                            formik.setFieldValue("isApprove", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={"Forward"}
                          onClick={() => {
                            formik.setFieldValue("isReview", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailSummary);

import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import { Table } from 'react-bootstrap';

import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { withTranslation } from "react-i18next";

class PatientInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            selectedData : [],
            isComplete : "",
        };
    }

    componentDidUpdate(prevProps) {

    

    }

    componentDidMount() {

    }
    

    handleCancelClick(event) {

        this.props.history.push('/report-submission/bpom');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="patient-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('initial_name')}</label>
                                <Input
                                    disabled
                                    name="patienName"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('patientName')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('gender')}</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="M" name="patientGender"
                                            disabled
                                            checked={formik.values.patientGender === "M"}
                                            onChange={formik.handleChange} />
                                        <label className="form-check-label">{t('male')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="F" name="patientGender"
                                            disabled
                                            checked={formik.values.patientGender === "F"}
                                            onChange={formik.handleChange} />
                                        <label className="form-check-label">{t('female')}</label>
                                    </div>
                                </div>
                            </div>
                            <label className="form-label">{t('age')}</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <input
                                                name="patientAgeYear"
                                                type="number"
                                                min={0}
                                                placeholder={t('year')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values.patientAgeYear ? formik.values.patientAgeYear : "" }
                                                {...formik.getFieldProps('patientAgeYear')}
                                                disabled   
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('year')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                disabled
                                                name="patientAgeMonth"
                                                type="number"
                                                min={0}
                                                max={11}
                                                placeholder={t('month')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('patientAgeMonth')}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('month')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product_name')}</th>
                                            <th>{t('batch_number')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values.resumeProduct?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`resumeProduct[${index}][productName]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`resumeProduct[${index}][productName]`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`resumeProduct[${index}][batch]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`resumeProduct[${index}][batch]`)}
                                                    />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">    
                                <label className="form-label">{t('immunization_date')}</label>
                                <DatePicker
                                    disabled
                                    name="productUseDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.productUseDate}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('onset_date')}</label>
                                <DatePicker
                                    disabled
                                    name="onsetDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.onsetDate}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('complaint')}</label>
                                <Textarea
                                    rows="6"
                                    name="keluhan"
                                    placeholder={t('complaint')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`keluhan`)}
                                    same={historydata?.keluhan ? historydata.keluhan === formik.values?.keluhan : true }
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('outcome')}</label>
                                <Select2
                                    disabled
                                    name="outcome"
                                    options={this.props.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values.outcome}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick}
                            />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default withTranslation()(PatientInformation);

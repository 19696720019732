import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import { Loader } from "../../../components/Utils/Loader/Loader";

import { QualityReviewService } from "../../../services/QualityReview/QualityReview/QualityReviewService";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { authenticationService } from "../../../services/AuthService";
import { Role, Status } from "../../../helpers/Constants";

import CaseInformation from "./Tab/CaseInformation";
import QualityInformation from "./Tab/QualityInformation";

import { withTranslation } from "react-i18next";

class DetailQualityReview extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      id: id,
      loading: false,
      backTab: "",
      nextTab: "quality-information",
      currentTab: "case-information",
    };

    this.formikRef = React.createRef();
    this.prepareForm(id);
  }

  prepareForm(id) {
    this.handleLoading(true);

    QualityReviewService.PrepareForm(id).then((result) => {
      this.setState({
        data: result.data,
      });
      this.handleLoading(false);
    });
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#case-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "case-information",
          backTab: "",
          nextTab: "quality-information",
        });
        break;
      case "#quality-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "quality-information",
          backTab: "case-information",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  handleNextClick(event) {
    document.getElementById("link-" + this.state.nextTab).click();
    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push("/quality-review", {
      pages: this.props?.location?.state?.page,
    });
  }

  render() {
    const { t } = this.props;

    let currentUser = authenticationService.currentUserValue;
    let disableForm = currentUser.role != Role.RegulasiMutuSistem;

    const review = this.state.data;
    const case_entry =
      review?.aefiCaseEntryNavigation ?? review?.aeCaseEntryNavigation;

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <ul className="nav nav-justified mb-3 menu-tab">
              <Tab
                number="1"
                showNumber={true}
                title={t("case_information")}
                href="#case-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#case-information")
                }
                id="link-case-information"
              />
              <Tab
                number="2"
                showNumber={true}
                title="Quality Review"
                href="#quality-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#quality-information")
                }
                id="link-quality-information"
              />
            </ul>
          </div>
        </div>
        <Formik
          enableReinitialize
          innerRef={this.formikRef}
          initialValues={{
            caseEntry: review?.caseEntry,
            caseEntryType: review?.caseNumber.toLowerCase().includes("aefi")
              ? "aefi"
              : "ae",
            caseNumber: review?.caseNumber,
            patientName: case_entry?.patientName,
            patientGender: case_entry?.patientGender,
            patientGenderLabel:
              case_entry?.patientGender === "M"
                ? "Laki-laki"
                : review?.patientGender === "F"
                ? "Perempuan"
                : "Tidak ada data",
            patientAgeYear: case_entry?.patientAgeYear,
            patientAgeMonth: case_entry?.patientAgeMonth,
            resultChronology: case_entry?.resultChronology,
            patientAddress: case_entry?.patientAddress,
            patientCountry: case_entry?.patientCountry,
            patientCountryLabel: case_entry?.patientCountryNavigation?.name,
            patientProvince: case_entry?.patientProvince,
            patientProvinceLabel: case_entry?.patientProvinceNavigation?.name,
            patientCity: case_entry?.patientCity,
            patientCityLabel: case_entry?.patientCityNavigation?.name,
            seriousness: case_entry?.seriousness,
            seriousnessLabel: case_entry?.seriousnessNavigation?.alias,
            seriousnessReason: case_entry?.seriousnessReason,
            seriousnessReasonLabel:
              case_entry?.seriousnessReasonNavigation?.name,
            outcome: case_entry?.outcome,
            outcomeLabel: case_entry?.outcomeNavigation?.name,
            expectedness: case_entry?.expectedness,
            expectednessLabel: case_entry?.expectednessNavigation?.name,
            bprReview: review?.bprReview,
            bprReviewFile: review?.bprReviewFileArr,
            summaryBatchReview: review?.summaryBatchReview,
            summaryBatchReviewFile: review?.summaryBatchReviewFileArr,
            investigationReview: review?.investigationReview,
            investigationReviewFile: review?.investigationReviewFileArr,
            deathSamplesReview: review?.deathSamplesReview,
            deathSamplesReviewFile: review?.deathSamplesReviewFileArr,
            qualityProduct:
              case_entry?.aefiVaccine ?? case_entry?.aeDrugs ?? [],
            qualityProductFile: review?.qualityProductFileArr,
          }}
          validationSchema={Yup.object().shape({
            caseNumber: Yup.string().required("Required"),
            bprReview: Yup.string().required("Required"),
            summaryBatchReview: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            QualityReviewService.Update(this.state.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/quality-review`);
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <CaseInformation formik={formik} formType="edit" />
                <QualityInformation
                  formik={formik}
                  disabled={currentUser.role !== Role.RegulasiMutuSistem}
                />
              </div>

              {this.state.backTab === "" && (
                <div className="text-right">
                  <ToggleDisplay>
                    <Button
                      color="#495057"
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      border="1px solid #495057"
                      text={t("cancel")}
                      onClick={(event) => this.handleCancelClick(event)}
                    />
                  </ToggleDisplay>
                  <ToggleDisplay>
                    <Button
                      text={t("next")}
                      onClick={(event) => this.handleNextClick(event)}
                    />
                  </ToggleDisplay>
                </div>
              )}
              {this.state.nextTab === "" && (
                <div className="text-right">
                  <ToggleDisplay>
                    <Button
                      color="#495057"
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      border="1px solid #495057"
                      text={t("back")}
                      onClick={(event) => this.handleBackClick(event)}
                    />
                  </ToggleDisplay>
                  {currentUser.role === Role.RegulasiMutuSistem && (
                    <ToggleDisplay>
                      <Button
                        type="submit"
                        text={t("save")}
                        disabled={formik.isSubmitting}
                      />
                    </ToggleDisplay>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailQualityReview);

import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const ProgressReportService = {
    Create,
    Read,
    ReadAe,
    ReadAefi,
    ReadAeFu,
    ReadAefiFu,
    ReadAnalysis,
    ReadAdrOnline,
    ReadAefiOverseas,
    Update,
    PrepareForm,
    PrepareInitialForm,
    Preview,
    Download,
    DownloadWord,
    DownloadDoc,
    Delete
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/progress/create/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/progress/submit-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}


async function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/report-submission/bpom/progress/prepare-form/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function PrepareInitialForm() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/report-submission/bpom/progress/prepare-initial-form/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function MoreAnalysisInfo(caseEntryId) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/report-submission/bpom/progress/more-analysis-info/` + caseEntryId, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/report-submission/bpom/progress/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

// Read info from other repository
async function ReadAe(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-ae?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAefi(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-aefi?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAeFu(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-ae-follow-up?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAefiFu(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-aefi-follow-up?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAnalysis(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-analysis?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAdrOnline(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-adr-online?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAefiOverseas(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/report-submission/bpom/progress/read-aefi-overseas?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function Download(id, overseas, casenumber) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    if (overseas) {
        var url = '/api/v1/report-submission/pdf/bpom-perkembangan-luar-negeri-pdf/';
    } else {
        var url = '/api/v1/report-submission/pdf/bpom-perkembangan-idn-pdf/';
    }
    fetch(url + id, requestOptions)
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Surat Laporan Perkembangan ke BPOM dengan nomor kasus ${casenumber}`);
            document.body.appendChild(link);
            link.click();
        });
}

async function DownloadWord(id, overseas, casenumber) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    // if (overseas) {
    //     var url = '/api/v1/report-submission/doc/bpom-perkembangan-luar-negeri-doc/';
    // } else {
    //     var url = '/api/v1/report-submission/doc/bpom-perkembangan-idn-doc/';
    // }
    // fetch(url + id, requestOptions)
    //     .then(response => { return response.blob() })
    //     .then(result => {
    //         const url = window.URL.createObjectURL(result);
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `Surat Laporan Perkembangan ke BPOM dengan nomor kasus ${casenumber}`);
    //         document.body.appendChild(link);
    //         link.click();
    //     });

    //dummy
    fetch('/api/v1/report-submission/doc/test-doc', requestOptions)
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Surat Laporan Perkembangan ke BPOM dengan nomor kasus ${casenumber}`);
            document.body.appendChild(link);
            link.click();
        });
}

async function DownloadDoc(id, casenumber, overseas) {
    if (overseas) {
        var url = '/api/v1/report-submission/doc/bpom-perkembangan-luar-negeri-doc/';
    } else {
        var url = '/api/v1/report-submission/doc/bpom-perkembangan-idn-doc/';
    }

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    fetch(url + id, requestOptions)
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Surat Laporan Perkembangan ke BPOM dengan nomor kasus ${casenumber}.docx`);
            document.body.appendChild(link);
            link.click();
        });
}


function Preview(object) {
    if (object.isOverseas) {
        var url = '/api/v1/report-submission/pdf-preview/preview-bpom-perkembangan-luar-negeri-pdf/';
    } else {
        var url = '/api/v1/report-submission/pdf-preview/preview-bpom-perkembangan-idn-pdf/';
    }

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    })
        .then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                return url;
            })
        })
}

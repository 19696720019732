import React, { Component } from 'react';
import Select from 'react-select';
import { Formik, Form } from 'formik';

import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';
import Select2 from '../../components/Utils/Field/Select2';

import { CaseArchivalService } from '../../services/SummaryReport/CaseArchivalService';
import { withTranslation } from "react-i18next";

class DownloadCaseArchival extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            snackbaropen: false,
            snackbarmsg: ''
        };
    }

    handleClickAll = (formik) => {
        if (formik?.values?.checkAll === true) {
            formik.setFieldValue('checkAll', false)
        }
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <Modal {...this.props} size="xl">
                    <ModalHeader>
                        <div className="modal-title">Download Case Archival</div>
                        <hr />
                    </ModalHeader>
                    <ModalContent>
                        <div style={{ marginTop: 15, marginBottom: 25, fontSize: 14, color: "#485867" }}>{this.props.text}</div>

                        <Formik

                            initialValues={{
                            }}

                            onSubmit={(values, { setStatus, setSubmitting }) => {

                                values.language = values.language ?? 'ID';

                                CaseArchivalService.Download(values, values.language)

                            }}
                        >
                            {props => (
                                <Form>
                                    <div className="row">
                                        <div className="col-xs-12 col-md-12">
                                            <div className="row">
                                                <div className="col-xs-8 col-md-8">
                                                    <div className="form-group">
                                                        <label className="form-label">{t('select_column_download')}</label>
                                                        <br />
                                                        <div className="form-check form-check-inline">
                                                            <input type="checkbox" className="form-check-input" name="checkAll" value={true}
                                                                checked={props.values?.checkAll === true}
                                                                onChange={(event) => {
                                                                    props.handleChange(event)
                                                                    props.setFieldValue('caseNumber', true)
                                                                    props.setFieldValue('adversePvDate', true)
                                                                    props.setFieldValue('followUp', true)
                                                                    props.setFieldValue('adrOnlineNumber', true)
                                                                    props.setFieldValue('adrOnlineDate', true)
                                                                    props.setFieldValue('manufactureCaseNumber', true)
                                                                    props.setFieldValue('manufactureSentDate', true)
                                                                    props.setFieldValue('reporter', true)
                                                                    props.setFieldValue('product', true)
                                                                    props.setFieldValue('immunizationDate', true)
                                                                    props.setFieldValue('onsetDate', true)
                                                                    props.setFieldValue('treatmentDate', true)
                                                                    props.setFieldValue('eventDescription', true)
                                                                    props.setFieldValue('patientName', true)
                                                                    props.setFieldValue('age', true)
                                                                    props.setFieldValue('gender', true)
                                                                    props.setFieldValue('doseFor', true)
                                                                    props.setFieldValue('dose', true)
                                                                    props.setFieldValue('route', true)
                                                                    props.setFieldValue('diagnosis', true)
                                                                    props.setFieldValue('preferredTerm', true)
                                                                    props.setFieldValue('systemOrganClass', true)
                                                                    props.setFieldValue('seriousness', true)
                                                                    props.setFieldValue('expectedness', true)
                                                                    props.setFieldValue('outcome', true)
                                                                    props.setFieldValue('analysisDate', true)
                                                                    props.setFieldValue('analysisDays', true)
                                                                    props.setFieldValue('analysisPv', true)
                                                                    props.setFieldValue('analysisAefi', true)
                                                                    props.setFieldValue('closedDate', true)
                                                                    props.setFieldValue('summaryDate', true)
                                                                    props.setFieldValue('summaryDays', true)
                                                                    props.setFieldValue('status', true)
                                                                }}
                                                            />
                                                            <label className="form-check-label">{t('select_all')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xs-4 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">{t('language')}</label>
                                                        <br />
                                                        <Select2
                                                            width='100%'
                                                            name="language"
                                                            options={[{ value: 'ID', label: 'Indonesia' }, { value: 'EN', label: 'Inggris' }]}
                                                            errors={props.errors}
                                                            touched={props.touched}
                                                            value={props.values.language}
                                                            onChange={props.setFieldValue}
                                                            onBlur={props.setFieldTouched}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <br />
                                        </div>
                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="caseNumber" value={true}
                                                        checked={props.values?.caseNumber === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">Nomor Kasus</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="adversePvDate" value={true}
                                                        checked={props.values?.adversePvDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('unit_pv_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="followUp" value={true}
                                                        checked={props.values?.followUp === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('followup_report_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="adrOnlineNumber" value={true}
                                                        checked={props.values?.adrOnlineNumber === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('adr_online_number')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="adrOnlineDate" value={true}
                                                        checked={props.values?.adrOnlineDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('adr_online_reporting_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="manufactureCaseNumber" value={true}
                                                        checked={props.values?.manufactureCaseNumber === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('manufacture_case_number')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="manufactureSentDate" value={true}
                                                        checked={props.values?.manufactureSentDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('manufacture_initial_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="reporter" value={true}
                                                        checked={props.values?.reporter === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">Reporter</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="product" value={true}
                                                        checked={props.values?.product === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('product_data')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="immunizationDate" value={true}
                                                        checked={props.values?.immunizationDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">Tanggal Imunisasi</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="onsetDate" value={true}
                                                        checked={props.values?.onsetDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('onset_date_aefi')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="treatmentDate" value={true}
                                                        checked={props.values?.treatmentDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('treatment_date')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="eventDescription" value={true}
                                                        checked={props.values?.eventDescription === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('complaint')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="patientName" value={true}
                                                        checked={props.values?.patientName === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('patient_name')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="age" value={true}
                                                        checked={props.values?.age === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('age')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="gender" value={true}
                                                        checked={props.values?.gender === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('gender')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="doseFor" value={true}
                                                        checked={props.values?.doseFor === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('dosefor')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="dose" value={true}
                                                        checked={props.values?.dose === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('dose')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="route" value={true}
                                                        checked={props.values?.route === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('rute')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="diagnosis" value={true}
                                                        checked={props.values?.diagnosis === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('diagnosis')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="preferredTerm" value={true}
                                                        checked={props.values?.preferredTerm === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('pt')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="systemOrganClass" value={true}
                                                        checked={props.values?.systemOrganClass === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('soc')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-md-4">
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="seriousness" value={true}
                                                        checked={props.values?.seriousness === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('seriousness_level')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="expectedness" value={true}
                                                        checked={props.values?.expectedness === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('expectedness')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="outcome" value={true}
                                                        checked={props.values?.outcome === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('outcome')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="analysisDate" value={true}
                                                        checked={props.values?.analysisDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('analysis_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="analysisDays" value={true}
                                                        checked={props.values?.analysisDays === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('analysis_time')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="analysisPv" value={true}
                                                        checked={props.values?.analysisPv === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('unit_pv_analysis_result')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="analysisAefi" value={true}
                                                        checked={props.values?.analysisAefi === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('analysis_by_aefi_committee')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="closedDate" value={true}
                                                        checked={props.values?.closedDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('case_closed_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="summaryDate" value={true}
                                                        checked={props.values?.summaryDate === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('summary_created_date')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="summaryDays" value={true}
                                                        checked={props.values?.summaryDays === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">{t('summary_time')}</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check form-check-inline">
                                                    <input type="checkbox" className="form-check-input" name="status" value={true}
                                                        checked={props.values?.status === true}
                                                        onChange={(event) => {
                                                            props.handleChange(event)
                                                            this.handleClickAll(props)
                                                        }}
                                                    />
                                                    <label className="form-check-label">Status</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-right'>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={this.props.onHide}
                                        />
                                        <Button
                                            background="#00ABBD"
                                            color="white"
                                            type="submit"
                                            text="Download"
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}

export default withTranslation()(DownloadCaseArchival);
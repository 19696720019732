import React from 'react';
import { ErrorMessage } from 'formik';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { VaccineService } from '../../../services/Master/VaccineService';
import { ManufactureService } from '../../../services/Master/ManufactureService';
import { DropdownService } from '../../../services/Master/AllDropdownService';

import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';

class FormVaccine extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading_data : false };
        this.refreshList();
    }

    refreshList() {

        this.state.pharmaceuticalList = DropdownService.PharmaceuticalList();

        DropdownService.MasterList([
            "productType"
        ]).then(
            (result) => {
                this.setState({ productTypeList: result.data.productTypeList });
            })

        ManufactureService.Read(1, 0, "").then(result => {
            this.setState({
                manufactureList: result.data.records,
            });
        });

        VaccineService.PrepareForm().then(result => {
            this.setState({
                vaccineList: result.data.masterVaccineRelated,
            });
        });
    }

    handleRefreshERP = () => {
        
        VaccineService.SyncProduct().then(result => {
            this.setState({
                vaccineList: result.data,
                loading_data: false,
            });
        });
    }

    render() {

        const { t, formik } = this.props; 

        return (
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">{t('name')}</label>
                        <Input
                            name="name"
                            type="text"
                            placeholder="Name"
                            errors={formik.errors}
                            touched={formik.touched}
                            {...formik.getFieldProps('name')} />
                    </div>
                    <div className="form-group">
                        {this.createERPUI(formik)}
                        {this.initialERPUI(formik)}
                        {
                            this.state.loading_data && 
                            <div className="row">
                                <div className="col-12">
                                    <p data-text={t('retrieving_data')} className="loading-menu" style={{fontSize: 14, marginTop: 5}}>{t('retrieving_data')}</p>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-xs-12 col-md-12 col-xl-7" style={{marginBottom: 10}}>
                                <ButtonWithIcon
                                    text={t('add_erp_product')}
                                    icon="fa fa-plus"
                                    onClick={this.addERPUI.bind(this, formik)}
                                />
                            </div>
                            <div className="col-xs-12 col-md-12 col-xl-3" style={{padding: 0}}>
                                <ButtonWithIcon
                                    text={'Refresh'}
                                    icon="fa fa-sync-alt"
                                    onClick={() => this.setState({loading_data : true}, () => {this.handleRefreshERP()})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">Manufacturer</label>
                        <Select2
                            name="manufacture"
                            options={this.state.manufactureList?.map(item => { return { value: item.id, label: item.name }; })}
                            value={formik.values.manufacture}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">{t('type')}</label>
                        <Select2
                            name="type"
                            options={this.state.productTypeList?.map(item => { return { value: item.id, label: item.name }; })}
                            value={formik.values.type}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Pharmaceutical Form</label>
                        <Select2
                            name="pharmaceutical"
                            options={this.state.pharmaceuticalList}
                            value={formik.values.pharmaceutical}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Indication</label>
                        <Input
                            name="indication"
                            type="text"
                            placeholder="Indication"
                            errors={formik.errors}
                            touched={formik.touched}
                            {...formik.getFieldProps('indication')} />
                    </div>
                    <div className="form-group">
                        <label className="form-label">{t('category')}</label>
                        <div>
                            <div className="form-check form-check-inline">
                                <input type="checkbox" className="form-check-input" value={true} 
                                    name="categoryBacteria"
                                    checked={formik.values.categoryBacteria === true}
                                    onChange={formik.handleChange} 
                                    {...formik.getFieldProps('categoryBacteria')}
                                />
                                <label className="form-check-label">{t('bacteria')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input type="checkbox" className="form-check-input" value={true} 
                                    name="categoryVirus"
                                    checked={formik.values.categoryVirus === true}
                                    onChange={formik.categoryVirus} 
                                    {...formik.getFieldProps('categoryVirus')}
                                />
                                <label className="form-check-label">Virus</label>
                            </div>
                        </div>
                        <ErrorMessage name="category" component="div" className="invalid-feedback" />
                    </div>
                </div>
            </div>
        )
    }

    // AefimasterVaccineRelated Dynamic Table

    initialERPUI(formik) {

        const masterVaccineRelated = this.props.masterVaccineRelated;

        if (masterVaccineRelated && masterVaccineRelated.length) {

            for (var i = 0; i < masterVaccineRelated.length; i++) {

                if (masterVaccineRelated[i] && !masterVaccineRelated[i].isNew && !masterVaccineRelated[i].previewed) {
                    formik.setFieldValue(`masterVaccineRelated[${i}][id]`, masterVaccineRelated[i].id)
                    formik.setFieldValue(`masterVaccineRelated[${i}][itemId]`, masterVaccineRelated[i].itemId)
                    formik.setFieldValue(`masterVaccineRelated[${i}][itemName]`, masterVaccineRelated[i].itemName)
                    formik.setFieldValue(`masterVaccineRelated[${i}][dose]`, masterVaccineRelated[i].dose)
                    formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupId]`, masterVaccineRelated[i].itemGroupId)
                    formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupName]`, masterVaccineRelated[i].itemGroupName)
                    formik.setFieldValue(`masterVaccineRelated[${i}][mainAccountNum]`, masterVaccineRelated[i].mainAccountNum ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][priceDate]`, masterVaccineRelated[i].priceDate ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][priceInvent]`, masterVaccineRelated[i].priceInvent ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][pricePurch]`, masterVaccineRelated[i].pricePurch ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][priceSales]`, masterVaccineRelated[i].priceSales ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][purchId]`, masterVaccineRelated[i].purchId ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][styleId]`, masterVaccineRelated[i].styleId ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][styleName]`, masterVaccineRelated[i].styleName ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][unitInvent]`, masterVaccineRelated[i].unitInvent ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][unitPurch]`, masterVaccineRelated[i].unitPurch ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][unitSales]`, masterVaccineRelated[i].unitSales ?? "");
                    formik.setFieldValue(`masterVaccineRelated[${i}][createdDate]`, masterVaccineRelated[i].createdDate ?? "");
                }

                this.props.masterVaccineRelated[i].previewed = true;
            }
        }
    }

    addERPUI(formik) {
        this.props.masterVaccineRelated.push({ isNew: true });
        formik.setFieldValue(`masterVaccineRelated[${this.props.masterVaccineRelated.length - 1}][id]`, null)
    }

    createERPUI(formik) {

        const {t} = this.props;

        return this.props.masterVaccineRelated?.map((element, i) =>

            <div key={i}>

                <Input
                    name={`masterVaccineRelated[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`masterVaccineRelated[${i}][id]`)}
                    
                />
                <div className="row dynamic-form">
                    <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-7">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('erp_product_name')}</label>}
                                    <Select2
                                        name={`masterVaccineRelated[${i}][itemId]`}
                                        options={this.state.vaccineList?.map(item => { return { value: item.itemId, label: item.itemName }})}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`masterVaccineRelated[${i}][itemId]`)}
                                        onChange={(name, value) => {

                                            let vaccine = [];

                                            if(this.state.vaccineList){
                                                let index = this.state.vaccineList?.map(function(x) {return (x.itemId) ; }).indexOf(value);
                                                vaccine = this.state.vaccineList ? [this.state.vaccineList[index]] : [];
                                            }

                                            formik.setFieldValue(name, value);
                                            formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupId]`, vaccine[0]?.itemGroupId ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupName]`, vaccine[0]?.itemGroupName ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][itemName]`, vaccine[0]?.itemName ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][mainAccountNum]`, vaccine[0]?.mainAccountNum ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][priceDate]`, vaccine[0]?.priceDate ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][priceInvent]`, vaccine[0]?.priceInvent ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][pricePurch]`, vaccine[0]?.pricePurch ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][priceSales]`, vaccine[0]?.priceSales ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][purchId]`, vaccine[0]?.purchId ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][styleId]`, vaccine[0]?.styleId ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][styleName]`, vaccine[0]?.styleName ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][unitInvent]`, vaccine[0]?.unitInvent ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][unitPurch]`, vaccine[0]?.unitPurch ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][unitSales]`, vaccine[0]?.unitSales ?? "");
                                            formik.setFieldValue(`masterVaccineRelated[${i}][createdDate]`, vaccine[0]?.createdDate ?? "");

                                        }}
                                        onBlur={formik.setFieldTouched}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('dose')}</label>}
                                    <Input
                                        name={`masterVaccineRelated[${i}][dose]`}
                                        type="number"
                                        step=".01"
                                        min={0} 
                                        placeholder={t('dose')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`masterVaccineRelated[${i}][dose]`)}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa text-danger mb-4" aria-hidden="true" onClick={this.removeERPUI.bind(this, formik, i)} style={{cursor:"pointer"}}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    removeERPUI(formik, index) {

        for (var i = index; i < this.props.masterVaccineRelated.length; i++) {

            const masterVaccineRelated = formik.values.masterVaccineRelated[i + 1];

            this.props.masterVaccineRelated[i] = this.props.masterVaccineRelated[i + 1];

            if (masterVaccineRelated) {
                formik.setFieldValue(`masterVaccineRelated[${i}][id]`, masterVaccineRelated.id)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemId]`, masterVaccineRelated.itemId)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemName]`, masterVaccineRelated.itemName)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupId]`, masterVaccineRelated.itemGroupId)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupName]`, masterVaccineRelated.itemGroupName)
                formik.setFieldValue(`masterVaccineRelated[${i}][mainAccountNum]`, masterVaccineRelated.mainAccountNum ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][priceDate]`, masterVaccineRelated.priceDate ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][priceInvent]`, masterVaccineRelated.priceInvent ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][pricePurch]`, masterVaccineRelated.pricePurch ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][priceSales]`, masterVaccineRelated.priceSales ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][purchId]`, masterVaccineRelated.purchId ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][styleId]`, masterVaccineRelated.styleId ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][styleName]`, masterVaccineRelated.styleName ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][unitInvent]`, masterVaccineRelated.unitInvent ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][unitPurch]`, masterVaccineRelated.unitPurch ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][unitSales]`, masterVaccineRelated.unitSales ?? "");
                formik.setFieldValue(`masterVaccineRelated[${i}][createdDate]`, masterVaccineRelated.createdDate ?? "");
            }
            else {
                formik.setFieldValue(`masterVaccineRelated[${i}][id]`, null)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemId]`, null)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemName]`, null)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupId]`, null)
                formik.setFieldValue(`masterVaccineRelated[${i}][itemGroupName]`, null)
                formik.setFieldValue(`masterVaccineRelated[${i}][mainAccountNum]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][priceDate]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][priceInvent]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][pricePurch]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][priceSales]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][purchId]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][styleId]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][styleName]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][unitInvent]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][unitPurch]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][unitSales]`, null);
                formik.setFieldValue(`masterVaccineRelated[${i}][createdDate]`, null);
            }
        }

        formik.values.masterVaccineRelated.pop();
        this.props.masterVaccineRelated.pop();
    }

}

export default FormVaccine
import React, { Component } from 'react';

import { Logo } from './Logo/Logo';
import { Notification } from './Notification/Notification';
import { ProfileMenu } from './ProfileMenu/ProfileMenu';
import LanguageMenu from './LanguageMenu/LanguageMenu';

export class Header extends Component {

    render() {
        return (
            <header className="topbar" data-navbarbg="skin6">
                <nav className="navbar top-navbar navbar-expand-md">

                    <Logo></Logo>

                    <div className="navbar-collapse collapse" id="navbarSupportedContent">

                        <ul className="navbar-nav float-left mr-auto ml-3 pl-1">
                            <li><p className="navbar-title-top">{this.props.title ? this.props.title : "Title"}</p></li>
                        </ul>

                        <ul className="navbar-nav float-right">
                            <Notification/>
                            <ProfileMenu/>
                            <LanguageMenu/>
                        </ul>

                    </div>
                </nav>

                
            </header>
        );
    }
}
import React, { Component } from 'react';
import { ErrorMessage } from 'formik';
import Select from 'react-select'

export default class Select2 extends Component {

    handleChange = item => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange(this.props.name, item?.value ?? "");
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur(this.props.name, true);
    };

    render() {

        const isValid = !(this.props.errors[this.props.name] && this.props.touched[this.props.name])
        const isSame =  this.props.same;

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // state.isFocused can display different borderColor if you need it
                borderColor: state.isFocused ? '#ddd' : !isValid ? 'red' : isSame === false ? '#00ABBD' : '#ddd'  ,
                // overwrittes hover style
                '&:hover': {
                    borderColor: state.isFocused ? '#ddd' : !isValid ? 'red' : isSame === false ? '#00ABBD' : '#ddd'
                }
            })
        }

        return (
            <div>
                <Select
                    {...this.props}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    styles={customStyles}
                    value={this.props.options?.filter(obj => { return obj.value === this.props.value })[0] ?? { label: 'Select...', key: '' }}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    options={this.props.options}
                    isClearable="true"
                    classNamePrefix="select"
                    className={'basic-single ' + this.props.className}
                    onInputChange={this.props.onInputChange}
                >
                </Select>
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" style={{ display: (isValid ? 'none' : 'block') }} />
            </div>
        );
    }
}
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import Input from '../../../../components/Utils/Field/Input';
import Textarea from '../../../../components/Utils/Field/Textarea';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class Investigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {
        const { formik, t, historydata, currentUser } = this.props;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed && this.props.isLastVersion) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved;

        const disabled = this.props.isLastVersion === undefined ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;
        const isNew = this.props.isLastVersion === undefined ? true : false;

        return (
            <div className="tab-pane tab-pane-fixed" id="investigation">
                <div>
                    <label className="form-label" style={{ fontSize: 16 }}>{t('external_study')}</label>
                    <div className="row">
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('chronological')}</label>
                                <Textarea
                                    rows="9"
                                    name="chronology"
                                    placeholder={t('chronological')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`chronology`)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('analysis_by')}</label>
                                <Input
                                    name="analysisByLabel"
                                    type="text"
                                    placeholder={t('analysis_by')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisByLabel')}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('province')}</label>
                                <Input
                                    name="analysisByProvinceLabel"
                                    type="text"
                                    placeholder={t('province')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisByProvinceLabel')}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('study_time')}</label>
                                <Input
                                    disabled={isDisabledForm || role === Role.RegulasiMutuSistem}
                                    name="reviewOfDay"
                                    type="text"
                                    placeholder={t('study_time')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('reviewOfDay')}
                                    same={historydata?.reviewOfDay ? historydata.reviewOfDay === formik.values?.reviewOfDay : true}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            {/* Kajian Eksternal */}
                            <label className="form-label">{t('analysis_by_external')}</label>

                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product_name')}</th>
                                            <th>{t('field_study')}</th>
                                            <th>{t('causality_study')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values?.summaryProductAnalysis?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {record?.productName}
                                                </td>
                                                <td>
                                                    {record?.fieldAnalysisExtText ?? record?.causalityExternalNavigation?.name}
                                                </td>
                                                <td>
                                                    {record.summaryProductAnalysisExternal?.length > 0
                                                        && record.summaryProductAnalysisExternal?.map((record2, index2) =>
                                                            <span>{record2?.causalityAnalysisText ?? record2.causalityNavigation?.name}, </span>
                                                        )}
                                                    {record.trailSummaryProductAnalysisExternal?.length > 0
                                                        && record.trailSummaryProductAnalysisExternal?.map((record2, index2) =>
                                                            <span>{record2?.causalityAnalysisText ?? record2.causalityNavigation?.name}, </span>
                                                        )}
                                                </td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values?.summaryProductAnalysis === undefined || formik.values?.summaryProductAnalysis === null || formik.values?.summaryProductAnalysis?.length === 0) && (
                                                <tr>
                                                    <td colSpan="4" style={{ textAlign: "center" }}>
                                                        {t('data_not_found')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>

                            {/* Unit PV */}
                            <label className="form-label">{t('analysis_by_pv')}</label>

                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product_name')}</th>
                                            <th>{t('field_study')}</th>
                                            <th>{t('causality_study')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values?.summaryProductAnalysis?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {record?.productName}
                                                </td>
                                                <td>
                                                    {record?.fieldAnalysisUpvText ?? record?.causalityUpvNavigation?.alias ?? record?.causalityUpvNavigation?.name}
                                                </td>
                                                <td>
                                                    {record.summaryProductAnalysisUpv?.length > 0
                                                        && record.summaryProductAnalysisUpv?.map((record2, index2) =>
                                                            <span>{record2?.causalityAnalysisText ?? record2.causalityNavigation?.name}, </span>
                                                        )}
                                                    {record.trailSummaryProductAnalysisUpv?.length > 0
                                                        && record.trailSummaryProductAnalysisUpv?.map((record2, index2) =>
                                                            <span>{record2?.causalityAnalysisText ?? record2.causalityNavigation?.name}, </span>
                                                        )}
                                                </td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values?.summaryProductAnalysis === undefined || formik.values?.summaryProductAnalysis === null || formik.values?.summaryProductAnalysis?.length === 0) && (
                                                <tr>
                                                    <td colSpan="4" style={{ textAlign: "center" }}>
                                                        {t('data_not_found')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>

                            {/* Laporan Dengan Nomor Batch yang sama */}
                            <label className="form-label">{t('report_with_the_same_batch_number')}</label>

                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('case_number')}</th>
                                            <th>{t('vaccine')}</th>
                                            <th>{t('patient_name')}</th>
                                            <th>{t('complaint')}</th>
                                            <th>{t('result_analysis')}</th>
                                            <th>{t('reason')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values?.summarySameBatchProduct?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {record?.caseNumber}
                                                </td>
                                                <td>
                                                    {record?.productName}
                                                </td>
                                                <td>
                                                    {record?.patientName}
                                                </td>
                                                <td>
                                                    {record?.complaint}
                                                </td>
                                                <td>
                                                    {record?.analysisResult}
                                                </td>
                                                <td>
                                                    {record?.analysisReason}
                                                </td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values?.summarySameBatchProduct === undefined || formik.values?.summarySameBatchProduct === null || formik.values?.summarySameBatchProduct?.length === 0) && (
                                                <tr>
                                                    <td colSpan="7" style={{ textAlign: "center" }}>
                                                        {t('data_not_found')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(Investigation);
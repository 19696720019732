import React from 'react';
import Select2 from '../../components/Utils/Field/Select2';
import Input from '../../components/Utils/Field/Input';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import DatePicker from '../../components/Utils/DateRange/DateRangePicker';

import { DropdownService } from '../../services/Master/AllDropdownService';
import { OrganizationService } from '../../services/Master/OrganizationService';
import { withTranslation } from "react-i18next";

export class FormMarketAut extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            productList: [],
            countryList: [],
            marketAuthorizationCountry: [{ country: null, actionDate: null, launchDate: null, tradeName: null, }],
            marketAuthorizationInstitution: [{ institution: null, country: null, actionDate: null, launchDate: null, tradeName: null, comment: null }],
            list: []
        };
    }

    componentDidUpdate(prevProps) {

        if (prevProps?.formik?.values?.product !== this.props.formik?.values?.product) {

            var product = this.state.productList?.filter(obj => { return obj.value === this.props.formik?.values?.product })[0];

            if (product) {
                this.props.formik.setFieldValue('type', product?.type);
                this.props.formik.setFieldValue('productName', product?.label);
            }
        }

        if (prevProps?.marketAuthorizationInstitution !== this.props.marketAuthorizationInstitution) {

            this.setState({ marketAuthorizationInstitution: this.props.marketAuthorizationInstitution })
        }

        if (prevProps?.marketAuthorizationCountry !== this.props.marketAuthorizationCountry) {
            this.setState({ marketAuthorizationCountry: this.props.marketAuthorizationCountry })
        }
    }

    componentDidMount() {
        DropdownService.MasterVaccineList("", true).then(
            (result) => {
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "vaccine" }; })
                    ]
                })
            })

        DropdownService.MasterDrugsList("", true).then(
            (result) => {
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "drugs" }; })
                    ]
                })
            })

        DropdownService.CountryList().then(
            (result) => {
                this.setState({ countryList: result.data });
            })


        OrganizationService.Read(1, 0, "").then(result => {
            this.setState({
                organizationList: result.data.records
            });
        });

    }

    render() {

        const { formik, formType, t } = this.props;

        return (
            <>
                <div className="row">
                    <div className="col-6">
                        <label className="form-label">{t('product_name')}</label>
                        {
                            formType === "edit" ? (
                                <Input
                                    name="productName"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps("productName")}
                                    disabled
                                />
                            ) : (
                                <Select2
                                    name="product"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik?.values?.product}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    options={this.state.productList}
                                />
                            )
                        }
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12">
                        <h4 style={{ marginBottom: 15 }}>COUNTRY</h4>
                        <div>
                            {this.createMarketAuthorizationCountryUI(formik)}
                            {this.initialMarketAuthorizationCountryUI(formik)}

                            <ButtonWithIcon
                                text={t("add_country")}
                                icon="fa fa-plus"
                                onClick={this.addMarketAuthorizationCountryUI.bind(this, formik)}
                                width="200px"
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-12">
                        <h4 style={{ marginBottom: 15 }}>INSTITUTION</h4>
                        <div>
                            {this.createMarketAuthorizationInstitutionUI(formik)}
                            {this.initialMarketAuthorizationInstitutionUI(formik)}

                            <ButtonWithIcon
                                text={t("add_institution")}
                                icon="fa fa-plus"
                                onClick={this.addMarketAuthorizationInstitutionUI.bind(this, formik)}
                                width="200px"
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }


    // MarketAuthorizationCountry Dynamic Table

    initialMarketAuthorizationCountryUI(formik) {

        const marketAuthorizationCountry = this.state.marketAuthorizationCountry ?? [];

        if (marketAuthorizationCountry && marketAuthorizationCountry.length) {

            for (var i = 0; i < marketAuthorizationCountry.length; i++) {

                if (marketAuthorizationCountry[i] && !marketAuthorizationCountry[i].isNew && !marketAuthorizationCountry[i].previewed) {
                    formik.setFieldValue(`marketAuthorizationCountry[${i}][id]`, marketAuthorizationCountry[i].id)
                    formik.setFieldValue(`marketAuthorizationCountry[${i}][country]`, marketAuthorizationCountry[i].country)
                    formik.setFieldValue(`marketAuthorizationCountry[${i}][actionDate]`, marketAuthorizationCountry[i].actionDate)
                    formik.setFieldValue(`marketAuthorizationCountry[${i}][launchDate]`, marketAuthorizationCountry[i].launchDate)
                    formik.setFieldValue(`marketAuthorizationCountry[${i}][tradeName]`, marketAuthorizationCountry[i].tradeName)
                }

                marketAuthorizationCountry[i].previewed = true;
            }
        }
    }

    createMarketAuthorizationCountryUI(formik) {

        const isDisabledForm = false;

        const marketAuthorizationCountry = this.state.marketAuthorizationCountry ?? formik.values?.marketAuthorizationCountry ?? [];

        return marketAuthorizationCountry?.map((element, i) =>

            <div key={i}>

                <Input
                    name={`marketAuthorizationCountry[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`marketAuthorizationCountry[${i}][id]`)}
                    disabled={isDisabledForm}
                />
                <div className="row dynamic-form">

                    <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Country</label>}
                                    <Select2
                                        name={`marketAuthorizationCountry[${i}][country]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationCountry[${i}][country]`)}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        options={this.state.countryList?.map(country => { return { value: country.code, label: country.name }; })}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Action Date</label>}
                                    <DatePicker
                                        name={`marketAuthorizationCountry[${i}][actionDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        placeholder="Action Date"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={marketAuthorizationCountry[i]?.actionDate}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Launch Date</label>}
                                    <DatePicker
                                        name={`marketAuthorizationCountry[${i}][launchDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        placeholder="Launch Date"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={marketAuthorizationCountry[i]?.launchDate}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Trade Name</label>}
                                    <Input
                                        name={`marketAuthorizationCountry[${i}][tradeName]`}
                                        type="text"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationCountry[${i}][tradeName]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" style={{ cursor: "pointer" }} onClick={this.removeMarketAuthorizationCountryUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addMarketAuthorizationCountryUI(formik) {

        // if(this.state.marketAuthorizationCountry){

        this.state.marketAuthorizationCountry.push({ country: null, actionDate: null, launchDate: null, tradeName: null, });
        formik.setFieldValue(`marketAuthorizationCountry[${this.state.marketAuthorizationCountry.length - 1}][id]`, null)
        // }
    }

    removeMarketAuthorizationCountryUI(formik, index) {

        let marketAuthorizationCountry = [...formik?.values?.marketAuthorizationCountry];

        marketAuthorizationCountry.splice(index, 1);

        formik.setFieldValue('marketAuthorizationCountry', marketAuthorizationCountry)
        this.setState({ marketAuthorizationCountry: marketAuthorizationCountry })
    }


    // marketAuthorizationInstitution Dynamic Table

    initialMarketAuthorizationInstitutionUI = (formik) => {

        const marketAuthorizationInstitution = this.state.marketAuthorizationInstitution ?? [];

        if (marketAuthorizationInstitution && marketAuthorizationInstitution.length) {

            for (var i = 0; i < marketAuthorizationInstitution.length; i++) {

                if (marketAuthorizationInstitution[i] && !marketAuthorizationInstitution[i].isNew && !marketAuthorizationInstitution[i].previewed) {
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][id]`, marketAuthorizationInstitution[i].id)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][institution]`, marketAuthorizationInstitution[i].institution)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][country]`, marketAuthorizationInstitution[i].country)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][actionDate]`, marketAuthorizationInstitution[i].actionDate)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][launchDate]`, marketAuthorizationInstitution[i].launchDate)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][tradeName]`, marketAuthorizationInstitution[i].tradeName)
                    formik.setFieldValue(`marketAuthorizationInstitution[${i}][comment]`, marketAuthorizationInstitution[i].comment)
                }

                marketAuthorizationInstitution[i].previewed = true;
            }
        }
    }

    createMarketAuthorizationInstitutionUI(formik) {

        const isDisabledForm = false;

        const { list } = this.state;

        const institutionList = list?.institutionList ?? [];

        const marketAuthorizationInstitution = this.state.marketAuthorizationInstitution ?? this.props.formik?.values?.marketAuthorizationInstitution ?? [];

        return marketAuthorizationInstitution.map((element, i) =>

            <div key={i}>

                <Input
                    name={`marketAuthorizationInstitution[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`marketAuthorizationInstitution[${i}][id]`)}
                    disabled={isDisabledForm}
                />
                <div className="row dynamic-form">

                    <div className="col-xs-6 col-sm-6 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Institution</label>}
                                    <Select2
                                        name={`marketAuthorizationInstitution[${i}][institution]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationInstitution[${i}][institution]`)}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        options={this.state.organizationList?.map(item => { return { value: item.id, label: item.name }; })}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Country</label>}
                                    <Select2
                                        name={`marketAuthorizationInstitution[${i}][country]`}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationInstitution[${i}][country]`)}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        options={this.state.countryList?.map(country => { return { value: country.code, label: country.name }; })}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Action Date</label>}
                                    <DatePicker
                                        name={`marketAuthorizationInstitution[${i}][actionDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        placeholder="Launch Date"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={marketAuthorizationInstitution[i]?.actionDate}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Launch Date</label>}
                                    <DatePicker
                                        name={`marketAuthorizationInstitution[${i}][launchDate]`}
                                        selectionType="single"
                                        maxdate={new Date()}
                                        placeholder="Launch Date"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        value={marketAuthorizationInstitution[i]?.launchDate}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-2">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Trade Name</label>}
                                    <Input
                                        name={`marketAuthorizationInstitution[${i}][tradeName]`}
                                        type="text"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationInstitution[${i}][tradeName]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-3 col-md-1">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">Comment</label>}
                                    <Input
                                        name={`marketAuthorizationInstitution[${i}][comment]`}
                                        type="text"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`marketAuthorizationInstitution[${i}][comment]`)}
                                        disabled={isDisabledForm}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" style={{ cursor: "pointer" }} onClick={this.removeMarketAuthorizationInstitutionUI.bind(this, formik, i)} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addMarketAuthorizationInstitutionUI(formik) {
        this.state.marketAuthorizationInstitution.push({ institution: null, actionDate: null, launchDate: null, tradeName: null, });
        formik.setFieldValue(`marketAuthorizationInstitution[${this.state.marketAuthorizationInstitution.length - 1}][id]`, null)
    }

    removeMarketAuthorizationInstitutionUI = (formik, index) => {

        let marketAuthorizationInstitution = [...formik?.values?.marketAuthorizationInstitution];


        marketAuthorizationInstitution.splice(index, 1);

        formik.setFieldValue('marketAuthorizationInstitution', marketAuthorizationInstitution)
        this.setState({ marketAuthorizationInstitution: marketAuthorizationInstitution })

    }

}

export default withTranslation()(FormMarketAut);
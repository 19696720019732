import React, { Component } from 'react';

import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';


export class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };

    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const {t} = this.props;

        return (
            <div className="container">
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">{t ? t('confirmation') : "Konfirmasi"}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>{t ? t('digital_signature_checked') : "Silahkan buat digital signature terlebih dahulu pada menu Profil"}</div>
                        <div className='text-right'>
                            <Button 
                                type="submit" 
                                text="OK"
                                onClick={this.props.confirm}
                            />
                        </div>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
import React, { Component } from "react";
import Modal from "../../components/Utils/Modal/Modal";
import ModalHeader from "../../components/Utils/Modal/ModalHeader";
import ModalContent from "../../components/Utils/Modal/ModalBody";
import Button from "../../components/Utils/Button/Button";
import DateRange from "../../components/Utils/DateRange/DateRangePicker";
import Select from "react-select";
import moment from "moment";

export class FilterTotalGraphModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentParamGraph: {
        caseType: "AEFI",
        YearType: { value: "Reported", label: "Tahun Pelaporan" },
        startDate: "",
        endDate: "",
        RegionType: { value: "domestic", label: "Dalam Negeri" },
        CaseCategory: { value: "", label: "" },
        Product: { value: "", label: "" },
      },
      timezone: moment(new Date()).format("Z"),
    };
  }
  handleGraphChange(item) {
    this.props.onChangeCaseType(item)
    this.props.onSetCurrentParamGraph(
       {
          ...this.props.currentParamGraph,
          caseType: item ? item.value : "AEFI",
          CaseCategory: { value: "", label: "" },
        }
    );
  }

  handleYearTypeChange(item) {
    this.props.onSetCurrentParamGraph(
      {  ...this.props.currentParamGraph, YearType: item }
    );
  }
  handleDateChange(start, end) {
    let startDate =
      start !== null && start !== ""
        ? (start + "Z" + this.state.timezone).replaceAll("/", "-")
        : "";
    let endDate =
      end !== null && start !== ""
        ? (end + "Z" + this.state.timezone).replaceAll("/", "-")
        : "";
    this.props.onSetCurrentParamGraph(
      {
          ...this.props.currentParamGraph,
          startDate: startDate,
          endDate: endDate,
      }
    );
  }

  handleRegionTypeChange(item) {
    this.props.onSetCurrentParamGraph(
      {
          ...this.props.currentParamGraph,
          RegionType: item ?? { value: "", label: "" },
      },
    );
  }

  handleCaseCategoryChange(item) {
    this.props.onSetCurrentParamGraph(
      {
          ...this.props.currentParamGraph,
          CaseCategory: item ?? { value: "", label: "" },
      },
    );
  }

  handleProductChange(item) {
    this.props.onSetCurrentParamGraph(
      {
          ...this.props.currentParamGraph,
          Product: item ?? { value: "", label: "" },
      },
    );
  }

  handleApplyFilter(){
    this.props.onConfirm(this.props.currentParamGraph);
    this.props.onHide()
  }

  render() {
    const { t } = this.props;
    const graphOption = t("graph_option", { returnObjects: true });

    const yearTypeOptions = t("year_type_option", { returnObjects: true });

    const domesticOverseasOptions = t("domestic_overseas_option", {
      returnObjects: true,
    });

    return (
      <div className="container">
        <Modal
          size="md"
          {...this.props}
          //   onHide={() => this.setState({ filterShow: false })}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <>
            <ModalHeader>
              <div className="modal-title">Filter</div>
            </ModalHeader>
            <ModalContent>
              <div style={{ width: "100%" }}>
                <label className="form-label">{t("case_type")}</label>
                <Select
                  options={graphOption}
                  placeholder="Jenis Grafik"
                  width="100%"
                  name="graphType"
                  value={graphOption.filter(
                    (option) =>
                      option.value === this.props.currentParamGraph.caseType
                  )}
                  isClearable="true"
                  classNamePrefix="select"
                  className="basic-single"
                  onChange={(item) => this.handleGraphChange(item)}
                />
              </div>
              <div style={{ width: "100%" }}>
                <label className="form-label">{t("case_category")}</label>
                <Select
                  options={this.props.caseCategoryList}
                  placeholder={
                    this.props.currentParamGraph?.caseType === "AEFI"
                      ? t("aefi_category")
                      : t("ae_category")
                  }
                  width="100%"
                  name="caseCategory"
                  value={this.props.caseCategoryList?.filter(
                    (option) =>
                      option.value ===
                      this.props.currentParamGraph?.CaseCategory.value
                  )}
                  isClearable="true"
                  classNamePrefix="select"
                  className="basic-single"
                  onChange={(item) => this.handleCaseCategoryChange(item)}
                />
              </div>
              <div style={{ width: "100%" }}>
                <label className="form-label">{t("product")}</label>
                <Select
                  options={this.props.productList}
                  placeholder={
                    this.props.currentParamGraph?.caseType === "AEFI"
                      ? t("vaccine")
                      : t("drugs")
                  }
                  width="100%"
                  name="Product"
                  value={this.props.productList?.filter(
                    (option) =>
                      option.value ===
                      this.props.currentParamGraph?.Product.value
                  )}
                  isClearable="true"
                  classNamePrefix="select"
                  className="basic-single"
                  onChange={(item) => this.handleProductChange(item)}
                />
              </div>

              <div style={{ width: "100%" }}>
                <label className="form-label">{t("type_year")}</label>
                <Select
                  placeholder="Tahun"
                  width="100%"
                  name="statusFilter"
                  classNamePrefix="select"
                  className="basic-single"
                  options={yearTypeOptions}
                  value={yearTypeOptions.filter(
                    (option) =>
                      option.value ===
                      this.props.currentParamGraph?.YearType.value
                  )}
                  onChange={(item) => this.handleYearTypeChange(item)}
                ></Select>
              </div>
              <div style={{marginBottom:"-15px"}}>
                <label className="form-label">Periode</label>
                <DateRange
                  handlechange={(startDate, endDate) =>
                    this.handleDateChange(startDate, endDate)
                  }
                  filterUntil={true}
                  startDate={this.props.currentParamGraph.startDate}
                  endDate={this.props.currentParamGraph.endDate}
                />
              </div>
              <div style={{ width: "100%" }}>
                <label className="form-label">{t("region")}</label>
                <Select
                  options={domesticOverseasOptions}
                  placeholder={t("region")}
                  width="100%"
                  name="domesticOverseas"
                  value={domesticOverseasOptions.filter(
                    (option) =>
                      option.value ===
                      this.props.currentParamGraph?.RegionType.value
                  )}
                  isClearable="true"
                  classNamePrefix="select"
                  className="basic-single"
                  onChange={(item) => this.handleRegionTypeChange(item)}
                />
              </div>
              <div className="text-right" style={{marginTop: '10px'}}>
                <Button
                  color="#495057"
                  background="#FFFFFF 0% 0% no-repeat padding-box"
                  border="1px solid #495057"
                  text={t("cancel")}
                  onClick={() => this.props.onHide()}
                />
                <Button
                  background="#00ABBD"
                  color="white"
                  type="submit"
                  text={t("apply")}
                  onClick={()=>this.handleApplyFilter()}
                />
              </div>
            </ModalContent>
          </>
        </Modal>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { authenticationService } from '../../services/AuthService';

export class LoginCTI extends Component {
    
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.title = "Login CTI"

        authenticationService.loginCTI(new URLSearchParams(this.props.location.search).get("token"))
        .then(
            result => {
                if ((result?.data?.role === 'DIVISI' || result?.data?.role === 'UNIT') && !result?.data?.signatureIsSet) {
                    this.props.history.push({ pathname: `/dashboard`, state: { signature: true } })
                } else {
                    this.props.history.push({ pathname: `/dashboard`, state: { signature: false } })
                }

            },
            error => {
                this.props.history.push(`/login`);
            }
        );
    }

    render() {
        return (
            <div>
                
            </div>
        )
    }
}

import React, { Component } from 'react';

import Textarea from '../../../../components/Utils/Field/Textarea';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class Conclusion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    render() {
        const { formik, t, historydata, currentUser } = this.props;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed && this.props.isLastVersion) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;

        const disabled = this.props.isLastVersion === undefined  ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;
        const isNew = this.props.isLastVersion === undefined ? true : false;


        return (
            <div className="tab-pane tab-pane-fixed" id="conclusion">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                    <div className="form-group">
                            <label className="form-label">Correction</label>
                            <Textarea
                                rows="6"
                                name="correction"
                                placeholder="Correction"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`correction`)}
                                disabled={isDisabledForm || role === Role.RegulasiMutuSistem}
                                same={historydata?.correction ? historydata?.correction === formik.values?.correction : true }
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Corrective Action</label>
                            <Textarea
                                rows="6"
                                name="correctiveAction"
                                placeholder="Corrective Action"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`correctiveAction`)}
                                disabled={isDisabledForm || role === Role.RegulasiMutuSistem}
                                same={historydata?.correctiveAction ? historydata?.correctiveAction === formik.values?.correctiveAction : true }
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">Preventive Action</label>
                            <Textarea
                                rows="6"
                                name="preventiveAction"
                                placeholder="Preventive Action"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`preventiveAction`)}
                                disabled={isDisabledForm || role === Role.RegulasiMutuSistem}
                                same={historydata?.preventiveAction ? historydata?.preventiveAction === formik.values?.preventiveAction : true }
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <label className="form-label">{t('attachment')}</label>
                        <div className="row">
                            <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                    <div className={historydata?.attachmentAefi ? "form-check form-check-inline " + ((historydata?.attachmentAefi === formik?.values?.attachmentAefi) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAefi" value={true}
                                            checked={formik.values?.attachmentAefi === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('aefi_form')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentAe ? "form-check form-check-inline " + ((historydata?.attachmentAe === formik?.values?.attachmentAe) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAe" value={true}
                                            checked={formik.values?.attachmentAe === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('ae_form')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentAnalysisAefiComplete ? "form-check form-check-inline " + ((historydata?.attachmentAnalysisAefiComplete === formik?.values?.attachmentAnalysisAefiComplete) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAnalysisAefiComplete" value={true}
                                            checked={formik.values?.attachmentAnalysisAefiComplete === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('complete_aefi_analysis_form')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentAnalysisAefiIncomplete ? "form-check form-check-inline " + ((historydata?.attachmentAnalysisAefiIncomplete === formik?.values?.attachmentAnalysisAefiIncomplete) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAnalysisAefiIncomplete" value={true}
                                            checked={formik.values?.attachmentAnalysisAefiIncomplete === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('uncomplete_aefi_analysis_form')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentAnalysisAe ? "form-check form-check-inline " + ((historydata?.attachmentAnalysisAe === formik?.values?.attachmentAnalysisAe) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAnalysisAe" value={true}
                                            checked={formik.values?.attachmentAnalysisAe === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('ae_analysis_form')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentResume ? "form-check form-check-inline " + ((historydata?.attachmentResume === formik?.values?.attachmentResume) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentResume" value={true}
                                            checked={formik.values?.attachmentResume === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">Resume</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentBpom ? "form-check form-check-inline " + ((historydata?.attachmentBpom === formik?.values?.attachmentBpom) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentBpom" value={true}
                                            checked={formik.values?.attachmentBpom === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('progress_report_letter_to_bpom')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="form-group">
                                    <div className={historydata?.attachmentAefiOverseas ? "form-check form-check-inline " + ((historydata?.attachmentAefiOverseas === formik?.values?.attachmentAefiOverseas) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAefi" value={true}
                                            checked={formik.values?.attachmentAefiOverseas === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('overseas_aefi_report')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentAefiNonSerious ? "form-check form-check-inline " + ((historydata?.attachmentAefiNonSerious === formik?.values?.attachmentAefiNonSerious) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentAe" value={true}
                                            checked={formik.values?.attachmentAefiNonSerious === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('non_serious_aefi_report')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentManufactureInitial ? "form-check form-check-inline " + ((historydata?.attachmentManufactureInitial === formik?.values?.attachmentManufactureInitial) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentManufactureInitial" value={true}
                                            checked={formik.values?.attachmentManufactureInitial === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('notification_letter_to_manufacturer')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentManufactureGrowth ? "form-check form-check-inline " + ((historydata?.attachmentManufactureGrowth === formik?.values?.attachmentManufactureGrowth) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentManufactureGrowth" value={true}
                                            checked={formik.values?.attachmentManufactureGrowth === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('progress_report_letter_to_manufacturer')}</label>
                                    </div>
                                    <br/>
                                    <div className={historydata?.attachmentReporter ? "form-check form-check-inline " + ((historydata?.attachmentReporter === formik?.values?.attachmentReporter) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentReporter" value={true}
                                            checked={formik.values?.attachmentReporter === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('response_letter_to_reporter')}</label>
                                    </div>
                                    <div className={historydata?.attachmentPartner ? "form-check form-check-inline " + ((historydata?.attachmentPartner === formik?.values?.attachmentPartner) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                        <input type="checkbox" className="form-check-input" name="attachmentPartner" value={true}
                                            checked={formik.values?.attachmentPartner === true}
                                            readOnly
                                        />
                                        <label className="form-check-label">{t('response_letter_to_partner')}</label>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(Conclusion);
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";

import { ClinicalTrialService } from "../../services/ClinicalTrial/ClinicalTrialService";
import { DeleteModal } from "../../components/Utils/Modal/DeleteModal";
import ButtonWithIcon from "../../components/Utils/Button/ButtonWithIcon";
import { toastSuccess, toastError } from "../../components/Utils/Toast/Toast";
import { authenticationService } from "../../services/AuthService";
import { Role } from "../../helpers/Constants";
import { withTranslation } from "react-i18next";

export class ClinicalTrial extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      records: [],
      addModalShow: false,
      editModalShow: [],
      deleteModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      pages: pages,
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  deleteRecord(id) {
    const { t } = this.props;
    ClinicalTrialService.Delete(id).then(
      (result) => {
        toastSuccess(t("success_delete_message"));
        this.refreshList();
        this.setState({ deleteModalShow: false });
      },
      (error) => {
        toastError(error);
      }
    );
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  refreshList() {
    ClinicalTrialService.Read(
      this.state.page,
      this.state.limit,
      this.state.query
    ).then((result) => {
      this.setState({
        maxPage: result.data.maxPage,
        records: result.data.records,
        editModalShow: new Array(result.data.length).fill(false),
      });
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageClick(event) {
    if (this.state.pages !== undefined) {
      this.setState({ page: this.state.pages, pages: undefined }, () => {
        this.refreshList();
      });
    } else if (isNaN(event.selected)) {
      this.setState({ page: 1 }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  handleDetail = (id) => {
    this.props.history.push(`/clinical-trial/detail/${id}`, {
      page: this.state.page,
    });
  };

  add = () => {
    this.props.history.push(`/clinical-trial/add`);
  };

  render() {
    const { records } = this.state;
    const { t } = this.props;

    let currentUser = authenticationService.currentUserValue;

    return (
      <>
        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          message={t("delete_confirmation")}
        />
        <div className="row">
          {currentUser.role === Role.Staff && (
            <div className="col-md-3" style={{ marginBottom: 15 }}>
              <ButtonWithIcon
                text={t("add_clinical_trial")}
                icon="fa fa-plus"
                onClick={() => this.add()}
              />
            </div>
          )}
          {currentUser.role !== Role.Staff && (
            <div className="col-xs-6 col-sm-6 col-md-3"></div>
          )}
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
              <div className="right">
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.query}
                      onChange={(event) => this.handleSearchChange(event)}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
          <Table className="pb-5" hover>
            <thead>
              <tr>
                <th width="5%">No</th>
                <th>{t("product")}</th>
                <th>Total Subject</th>
                <th width="10%"></th>
              </tr>
            </thead>
            <tbody>
              {records.map((record, index) => (
                <tr key={record.id}>
                  <td>
                    {index + 1 + (this.state.page - 1) * this.state.limit}
                  </td>
                  <td>{record.productName}</td>
                  <td>{record.totalSubject}</td>
                  <td>
                    <div className="btn-group">
                      <button
                        className="btn btn-sm dropdown-toggle"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                      </button>
                      <div className="dropdown-menu">
                        <a
                          className="dropdown-item"
                          onClick={() => this.handleDetail(record.id)}
                        >
                          Edit
                        </a>
                        <a
                          className="dropdown-item"
                          onClick={() => this.handleDelete(record.id)}
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {records.length < 1 && (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    {t("data_not_found")}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <nav>
          <ReactPaginate
            previousLabel={<i class="fas fa-arrow-left"></i>}
            previousLinkClassName={"page-link"}
            nextLabel={<i class="fas fa-arrow-right"></i>}
            nextLinkClassName={"page-link"}
            pageCount={this.state.maxPage}
            containerClassName={"pagination justify-content-end"}
            pageClassName={"page-item"}
            breakClassName={"page-item"}
            pageLinkClassName={"page-link"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
            onPageChange={(event) => this.handlePageClick(event)}
            initialPage={this.state.pages - 1}
          />
        </nav>
      </>
    );
  }
}

export default withTranslation()(ClinicalTrial);

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import * as Utils from '../../../helpers/Utils';
import Button from '../../../components/Utils/Button/Button';
import Textarea from '../../../components/Utils/Field/Textarea';
import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import Select2Multi from '../../../components/Utils/Field/Select2Multi';
import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import { AEFIService } from '../../../services/CaseEntry/AEFIService';
import { DropdownService } from '../../../services/Master/AllDropdownService';
import { ProgressService } from '../../../services/Manufacture/ProgressService';
import { ManufactureService } from '../../../services/Master/ManufactureService';
import { LetterModal } from "./LetterModal";

import { withTranslation } from 'react-i18next';

class AddProgress extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            historyForm: [],
            caseEntryData: [],
            manufactureList: []
        };

        this.formikRef = React.createRef();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleBackClick(event) {
        this.props.history.push({ pathname: `/report-submission/manufacture`, state: { tab: 'Progress' } });
    }

    handleCaseNumberChange(formik, value) {
        const filtered = this.state.caseNumberList.filter(item => item.caseNumber === value)[0];

        console.log(filtered)

        formik.setFieldValue("caseNumber", value)
        formik.setFieldValue("vaccine", filtered.aefiVaccine.map(idx => idx.vaccineNavigation.name))
        formik.setFieldValue("manufactureSubmissionProduct", filtered.aefiVaccine.map(item => ({
            product: item.vaccineNavigation.id,
            productName: item.vaccineNavigation.name,
            batch: item.vaccineNavigation.batch,
            manufacture: item.vaccineNavigation.manufactureNavigation.name
        })))
        formik.setFieldValue("manufactureSubmissionSymptoms", filtered.aefiSymptomps.filter(item => item.symptomps != null).map(item => ({
            symptoms: item.symptomps,
            date: item.date,
            durationMinute: item.durationMinute,
            durationHour: item.durationHour,
            durationDay: item.durationDay
        })))
        formik.setFieldValue("manufactureSubmissionAllergic", filtered.aefiAllergic.filter(item => item.allergic != null).map(item => ({
            allergic: item.allergic,
            date: item.date,
            durationMinute: item.durationMinute,
            durationHour: item.durationHour,
            durationDay: item.durationDay
        })))
        formik.setFieldValue("seriousness", filtered.seriousness)
        formik.setFieldValue("reportedDate", filtered.reportedDate)
        formik.setFieldValue("caseEntry", filtered.id)
        formik.setFieldValue("caseEntryDate", Utils.getDate(filtered.onsetDate))
    }

    componentDidMount(){
        AEFIService.ReadForManufactureProgress(true).then(result => {
            this.setState({
                caseNumberList: result.data.records
            });
        });

        DropdownService.MasterList(["manufacture", "outcome"]).then((result) => {
            this.setState({ list: result.data });
        })

        ManufactureService.Read(0, 10, "").then((result) => {
            this.setState({ manufactureList: result.data.records })
        })
    }
    
    render() {

        const { t } = this.props;

        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        /** Handling required form inputs begin */

        const initialTouched = {
            manufacture: true,
            mailingSubject: true,
            mailingBody: true,
        }

        const validationSchemaDict = Yup.object().shape({
                manufacture: Yup.string().required("Required").nullable(),
                mailingSubject: Yup.string().required("Required").nullable(),
                mailingBody: Yup.string().required("Required").nullable(),
            });

        /** Handling required form input ends */

        return (
            <>
             
                <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                        caseNumber: "",
                        manufacture: "",
                        mailingDate: new Date(),
                        mailingSubject: "",
                        mailingBody: "",
                        optionLanguage: true
                    }}
                    initialTouched={initialTouched}
                    validationSchema={validationSchemaDict}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        this.formikRef.current.validateForm().then(
                            () => {
                                if (this.formikRef.current.isValid) {
                                    setStatus();
                                    ProgressService.Create(values).then(
                                        (result) => {
                                            toastSuccess(t('success_add_message'));
                                            this.props.history.push({ pathname: `/report-submission/manufacture`, state: { tab: 'Progress' } });
                                        },
                                        (error) => {
                                            setSubmitting(false);
                                            toastError(error);
                                        }
                                    )
                                }
                            }
                        )
                    }}
                >
                    {formik => 

                        (
                            <Form>
                                <LetterModal
                                    show={this.state.letterShow}
                                    onHide={LetterModalClose}
                                    formik={formik}
                                    type="progress"
                                    t={t}
                                />
                                <div id="report-information">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                disabled
                                                name="caseEntry"
                                                type="hidden"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('caseEntry')}
                                            />
                                            <div className="form-group">
                                                <label className="form-label">{t('case_number')}</label>
                                                <Select2
                                                    // disabled
                                                    name="caseNumber"
                                                    options={this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    value={formik.values?.caseNumber}
                                                    onChange={(name, value) => {
                                                        this.handleCaseNumberChange(formik, value)
                                                    }}
                                                    onBlur={formik.setFieldTouched}
                                                    // disabled={isDisabledForm}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('vaccine')}</label>
                                                <Input
                                                    disabled
                                                    name="vaccine"
                                                    type="text"
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('vaccine')}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Manufacturer</label>
                                                <Select2
                                                    // disabled
                                                    name="manufacture"
                                                    value={formik.values.manufacture}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    onChange={(name, value) => {
                                                        formik.setFieldValue("manufacture", value)
                                                        formik.setFieldValue("mailingPlace", this.state.manufactureList.map(item => {
                                                            if (item.id === value) {
                                                                return item.destination
                                                            }
                                                            return ""
                                                        }).join(" "))
                                                    }}
                                                    onBlur={formik.setFieldTouched}
                                                    options={this.state.manufactureList?.filter(
                                                        manufakture => manufakture.masterVaccine
                                                            .map(item => item.id)
                                                            .some(item => formik.values.manufactureSubmissionProduct?.map(item => item.product).includes(item))
                                                    ).map(item => { return { value: item.id, label: item.name }; })}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('language_option')}</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input"
                                                            checked={formik.values.optionLanguage == "true"}
                                                            value="true"
                                                            name="optionLanguage"
                                                            onChange={formik.handleChange} 
                                                        />
                                                        <label className="form-check-label">{t('english')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input"
                                                            checked={formik.values.optionLanguage == "false"}
                                                            value="false"
                                                            name="optionLanguage"
                                                            onChange={formik.handleChange} 
                                                        />
                                                        <label className="form-check-label">{t('indonesia')}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="form-label">{t('aefi_form')}</label>
                                                <Button
                                                    text="Preview Surat"
                                                    onClick={() => this.setState({ letterShow: true })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{t('letter_address')}</label>
                                                <Textarea
                                                    rows="2"
                                                    name="mailingPlace"
                                                    placeholder={t('letter_address')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    value={formik.values?.mailingPlace ? formik.values?.mailingPlace : ""}
                                                    {...formik.getFieldProps('mailingPlace')} 
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('send_date')}</label>
                                                <DatePicker
                                                    disabled
                                                    name="mailingDate"
                                                    selectionType="single"
                                                    maxdate={new Date()}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    onChange={formik.setFieldValue}
                                                    onBlur={formik.setFieldTouched}
                                                    {...formik.getFieldProps('mailingDate')} 
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Subject Email</label>
                                                <Input
                                                    name="mailingSubject"
                                                    type="text"
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('mailingSubject')}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Body Email</label>
                                                <Textarea
                                                    rows="6"
                                                    name="mailingBody"
                                                    placeholder="Isi Email"
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('mailingBody')}
                                                />
                                            </div>
                                        </div>
                                    </div>    
    
                                    <div className='text-right'>
                                        <ToggleDisplay>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={(event) => this.handleBackClick(event)} />
                                        </ToggleDisplay>
                                        <ToggleDisplay show={this.state.saveShow}>
                                            <Button
                                                type="submit"
                                                text={t('save_send_email')}
                                                disabled={formik.isSubmitting} />
                                        </ToggleDisplay>
                                        {
                                            formik.status &&
                                            <div className={'alert alert-danger'}>{formik.status}</div>
                                        }
                                    </div>
                                    
                                </div>                            
                            </Form>
                        )
                    } 
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddProgress)
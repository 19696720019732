import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ClinicalTrialService } from '../../services/ClinicalTrial/ClinicalTrialService';
import { DropdownService } from '../../services/Master/AllDropdownService';

import FormClinicalTrial from './FormClinicalTrial';
import { withTranslation } from "react-i18next";

class AddClinicalTrial extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            clinicalTrialDetail: [],
            productList: []
        }
        this.getData();
    }

    getData() {
        this.state.clinicalTrialDetail = [{ isNew: true, trialName: "", ageRange: "", purpose: "", totalMale: 0, totalFemale: 0, total: 0 }];

        let masterVaccineList = DropdownService.MasterVaccineList(true).then(
            (result) => {
                console.log(result)
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "vaccine" }; })
                    ]
                })
            })

        let masterDrugsList = DropdownService.MasterDrugsList(true).then(
            (result) => {
                console.log(result)
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "drugs" }; })
                    ]
                })
            })
    }
    w

    cancel = () => {
        this.props.history.push(`/clinical-trial`);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            product: "",
                        }}

                        validationSchema={
                            Yup.object().shape({
                                product: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {

                            var product = this.state.productList?.filter(obj => { return obj.value === values.product })[0];

                            values.type = product.type;
                            values.product = product.value;
                            values.productName = product.label;

                            values.clinicalTrialDetail = values.clinicalTrialDetail?.filter(obj => { return obj != null });

                            ClinicalTrialService.Create(values).then(

                                (result) => {
                                    toastSuccess('Berhasil menambahkan data');
                                    this.props.history.push(`/clinical-trial`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                        (
                            <Form>
                                <div className="tab-content">
                                    <FormClinicalTrial
                                        formik={formik}
                                        clinicalTrialDetail={this.state.clinicalTrialDetail}
                                        productList={this.state.productList}
                                    />
                                </div>
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t("cancel")}
                                            onClick={() => this.cancel()}
                                        />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t("save")}
                                            disabled={formik.isSubmitting}
                                        />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>
                            </Form>
                        )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(AddClinicalTrial);
import { handleResponse, authHeader } from '../../helpers/RequestHelper';
import { buildQueryParams } from '../../helpers/Utils';

export const ProductDistributionService = {
    PrepareForm,
    Products,
    Create,
    Read,
    Update,
    Delete,
    SyncData,
    SendReminder,
};


function PrepareForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/product-distribution/prepare-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Products() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/product-distribution/available-products/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`api/v1/product-distribution/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

// async function Read(page = 1, limit = 10, query, period, id = "" ) {
async function Read(param) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    const queryParams = buildQueryParams(param)
    let data = await fetch(`api/v1/product-distribution/read?${queryParams}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

function Update(id, value) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`api/v1/product-distribution/update/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/product-distribution/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function SyncData(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/product-distribution/get-suggestion/${id}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function SendReminder(){
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/product-distribution/send-reminder`, requestOptions);
    data = await handleResponse(data)
    return data;
}

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../components/Utils/Button/Button';

import ReportInformation from './TabKIPINonSerious/ReporterInformation';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { AEFINonSeriousService } from '../../../services/BPOM/AEFINonSeriousService';

import { withTranslation } from "react-i18next";

class AddKIPINonSerious extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            historyForm: [],
            caseEntryData: [],
        };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }
    
    handleCancelClick(event) {

        this.props.history.push({ pathname: `/report-submission/bpom`, state: { tab: "KIPINonSerious" } });
        event.preventDefault();
    }

    render() {

        const { t } = this.props;

        const initialValues = {
            period : "",
            letterPlace : "",
            letterDate : "",
            letterAddress : "",
        }

        const initialTouched = {
            period : true,
            letterPlace : true,
            letterDate : true,
            letterAddress : true,
        }

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
             
                <Formik
                    initialValues={initialValues}
                    initialTouched={initialTouched}
                    validationSchema={
                        Yup.object().shape({
                            period : Yup.string().required("Required").nullable(),
                            letterPlace : Yup.string().required("Required").nullable(),
                            letterDate : Yup.string().required("Required").nullable(),
                            letterAddress : Yup.string().required("Required").nullable(),
                        })
                    }

                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        AEFINonSeriousService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push({pathname: `/report-submission/bpom`, state: {tab : "KIPINonSerious"}});
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )

                    }}
                >
                    {formik => 

                        (
                            <Form>
                                <div className="tab-content"> 
                                    <ReportInformation
                                        formik={formik}
                                        type="add"
                                    />
                                    <div className='text-right'>
                                        <ToggleDisplay>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={(event) => this.handleCancelClick(event)} />
                                        </ToggleDisplay>
                                        <ToggleDisplay show={this.state.saveShow}>
                                            <Button
                                                type="submit"
                                                text={t('save')}
                                                disabled={formik.isSubmitting} />
                                        </ToggleDisplay>
                                        {
                                            formik.status &&
                                            <div className={'alert alert-danger'}>{formik.status}</div>
                                        }
                                    </div>
                                    
                                </div>                            
                            </Form>
                        )
                    } 
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddKIPINonSerious)
import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const ReporterService = {
    PrepareForm,
    Create,
    Read,
    Update,
    Delete,
    FollowUp,
    Download,
    DownloadDoc
};

function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/report-submission/reporter/prepare-form/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/reporter/submit-form/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function FollowUp(value) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/reporter/submit-form/create-followup`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/reporter/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

function Update(value) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/reporter/submit-form/update`, requestOptions)
    .then(handleResponse)
    .then(response => {
        return response;
    });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/reporter/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function ReviewForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/report-submission/reporter/review/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ApproveForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/report-submission/reporter/approve/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function Download(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/reporter-pdf/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Surat Balasan Pelapor dengan nomor kasus ${casenumber}`);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadDoc(id, casenumber) {
    fetch('/api/v1/report-submission/doc/reporter-doc/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Surat Balasan Pelapor dengan nomor kasus ${casenumber}.docx`);
        document.body.appendChild(link);
        link.click();
    })
}


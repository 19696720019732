import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { ErrorMessage } from 'formik';
const moment = extendMoment(originalMoment);

class Example extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isOpen: false,
      isOpenStart: false,
      isOpenEnd: false,
      value: this.props.selectionType === "single" ? null : null,
      startDate : null,
      endDate: null,
      isFilterUntilPresent: this.props.filterUntil !== undefined && this.props.filterUntil !== null,
    };
  }

  componentDidMount(){
    if(this.state.isFilterUntilPresent){
      this.setState({startDate: this.props.startDate ? moment(this.props.startDate): ""})
      this.setState({endDate: this.props.endDate ? moment(this.props.endDate): ""})
    }else{
      if(this.props.selectionType === "single"){
        this.setState({value : this.props.value ? moment(this.props.value) : "" });
      }else{
        this.setState({value : this.props.value ?? "" });
      }
    }
    
  }

  componentDidUpdate(prevProps){
    if(prevProps.value !== this.props.value){
      if(this.props.selectionType === "single"){
        this.setState({value : moment(this.props.value, "YYYY/MM/DD")})
      }else{
        this.setState({value : this.props.value ?? (this.props.startDate && this.props.endDate ? moment().range(moment(this.props.startDate), moment(this.props.endDate)) : "") ?? "" });
      }
      
    }
  }

  handleBlur = () => {
  };

  onClear = () => {
    this.setState({ value : "" });
    this.setState({ startDate: ""})
    this.setState({ endDate: ""})
    if(this.props.selectionType !== "single") {
      this.props.handlechange("", "", this.props.type ? this.props.type : null );
    }
  }
  onClearStart = () => {
    this.setState({ startDate: ""})
    if(this.props.selectionType !== "single") {
      this.props.handlechange("", "", this.props.type ? this.props.type : null );
    }
  }
  onClearEnd = () => {
    this.setState({ endDate: ""})
    if(this.props.selectionType !== "single") {
      this.props.handlechange("", "", this.props.type ? this.props.type : null );
    }
  }

  onSelect = (value, states) => {
    this.setState({ value, states });
    if(this.props.selectionType !== "single") {

        this.props.handlechange((this.state.value?.start ? moment(this.state.value?.start).format("YYYY/MM/DD") : ""), this.state.value?.end ? moment(this.state.value?.end).format("YYYY/MM/DD") : "", this.props.type ? this.props.type : null );

        if(this.props.name && this.props.change) {
          this.props.change(this.props.name, this.state.value)
        }
    }

    if(this.props.onChange){
      this.props.onChange(this.props.name, moment(this.state.value).format("YYYY/MM/DD") ?? "");
    }

    this.setState({ isOpen: !this.state.isOpen });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  onToggleStart = () => {
    if (this.state.isOpenEnd){
      this.setState({ isOpenEnd : !this.state.isOpenEnd})
    }
    this.setState({ isOpenStart: !this.state.isOpenStart });
  };
  onToggleEnd = () => {
    if (this.state.isOpenStart){
      this.setState({ isOpenStart : !this.state.isOpenStart})
    }
    this.setState({ isOpenEnd: !this.state.isOpenEnd });
  };
  
  onSelectStart = (values, states)=> {
    this.setState({ startDate : values, states})
    if(this.state.startDate != "" && this.state.endDate != ""){
      this.props.handlechange((moment(this.state.startDate).format("YYYY/MM/DD")), (moment(this.state.endDate).format("YYYY/MM/DD")), this.props.type ? this.props.type : null)
    }
    this.setState({ isOpenStart : !this.state.isOpenStart})

  }

  onSelectEnd = (values, states) =>{
    this.setState({ endDate : values, states})
    if(this.state.startDate != "" && this.state.endDate != ""){
      this.props.handlechange((moment(this.state.startDate).format("YYYY/MM/DD")), (moment(this.state.endDate).format("YYYY/MM/DD")), this.props.type ? this.props.type : null)
    }
    this.setState({ isOpenEnd : !this.state.isOpenEnd})
  }

  renderSelectionValue = () => {
    const inputValue = this.props.selectionType === "single" ? ( this.state.value ? `${moment(this.state.value).format("DD/MM/YYYY")}` : "dd/mm/yyyy") : ( this.state.value ? `${moment(this.state.value?.start).format("DD/MM/YYYY")} - ${moment(this.state.value?.end).format("DD/MM/YYYY")}` : "dd/mm/yyyy - dd/mm/yyyy");
    const error = this.props.errors && this.props.name ? this.props.errors[this.props.name] : "";
    const touched = this.props.touched && this.props.name ? this.props.touched[this.props.name] : "";
    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center"
    };

    const isSame =  this.props.same;
    return (
      <div style={styles}>
        {!this.state.isFilterUntilPresent && 
          <div className="form-group" style={{position:"relative", display:"flex", alignItems:"center"}} onClick={this.props.disabled ? null : this.onToggle}>
            <input
                {...this.props}
                value={inputValue}
                className={'form-control ' + this.props.className + ( error && touched ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '')}
                readOnly={true}
                style={{fontSize: 14, minHeight: 40, paddingRight: 35, display: "inline-block"}}
                onBlur={this.handleBlur}
                disabled={this.props.disabled}
                placeholder={this.props.selectionType === "single" ? "dd/mm/yyyy" : "dd/mm/yyyy - dd/mm/yyyy"}
                name={this.props.name}
            >
              
            </input>
            <div className="end-icon" style={{display:"flex",position: "absolute", top: 10, height: 20, color: "#495057"}}>
              {
                this.state.value && !this.props.disabled &&
                <i class="fas fa-times" aria-hidden="true" style={{marginRight: 10, cursor:"pointer"}} onClick={this.onClear}></i>
              } 
              <i className="far fa-calendar" aria-hidden="true"></i>
            </div>
              {
                touched && error && (
                  <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" style={{textAlign:"left"}} />
                )
              }
          </div>
        }
        {this.state.isFilterUntilPresent && 
          <div className="form-group" style={{position:"relative", display:"flex", alignItems:"center"}}>
            <div onClick={this.onToggleStart}>
              <input
                  {...this.props}
                  value={this.state.startDate != "" ? moment(this.state.startDate).format("DD/MM/YYYY") : "dd/mm/yyyy"}
                  className={'form-control ' + this.props.className + ( error && touched ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '')}
                  readOnly={true}
                  style={{fontSize: 14, minHeight: 40, paddingRight: 35, display: "inline-block"}}
                  onBlur={this.handleBlur}
                  disabled={this.props.disabled}
                  placeholder={"dd/mm/yyyy"}
                  name={this.props.name}
              >
                
              </input>
              <div className="end-icon" style={{display:"flex",position: "absolute", top: 10, height: 20, color: "#495057", right:"55%"}}>
                {
                  (this.state.startDate != "") &&
                  <i class="fas fa-times" aria-hidden="true" style={{marginRight: 10, cursor:"pointer"}} onClick={this.onClearStart}></i>
                }
                
                <i className="far fa-calendar" aria-hidden="true"></i>
              </div>
            </div>
            <h2 style={{padding : "0 5px", fontSize:"20px"}}>-</h2>
            <div id ="end" onClick={this.onToggleEnd}>
              <input
                  {...this.props}
                  value={this.state.endDate != "" ? moment(this.state.endDate).format("DD/MM/YYYY") : "dd/mm/yyyy"}
                  className={'form-control ' + this.props.className + ( error && touched ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '')}
                  readOnly={true}
                  style={{fontSize: 14, minHeight: 40, paddingRight: 35, display: "inline-block"}}
                  onBlur={this.handleBlur}
                  disabled={this.props.disabled}
                  placeholder={"dd/mm/yyyy"}
                  name={this.props.name}
                  onClick={this.props.disabled ? null : this.onToggleEnd}
              >
                
              </input>
              <div className="end-icon" style={{display:"flex",position: "absolute", top: 10, height: 20, color: "#495057", right:"3%"}}>
                {
                  this.state.endDate != "" &&
                  <i class="fas fa-times" aria-hidden="true" style={{marginRight: 10, cursor:"pointer"}} onClick={this.onClearEnd}></i>
                }
                
                <i className="far fa-calendar" aria-hidden="true"></i>
              </div>
            </div>
              {
                touched && error && (
                  <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" style={{textAlign:"left"}} />
                )
              }
          </div>
        }
      { this.state.isOpen && this.state.isFilterUntilPresent == false && (
        <DateRangePicker
            value={this.state.value}
            onSelect={this.onSelect}
            singleDateRange={true}
            maximumDate={this.props.maxdate ? moment(this.props.maxdate) : null }
            minimumDate={this.props.mindate ? moment(this.props.mindate) : null }
            selectionType={this.props.selectionType? this.props.selectionType : "range" }
            onBlur={()=>this.setState({isOpen: false})}
            className={this.props.placementTop ? 'placement-top' : ''}
        />
        )}
      { this.state.isOpenStart && (
        <DateRangePicker
            value={this.state.startDate}
            onSelect={this.onSelectStart}
            singleDateRange={true}
            maximumDate={this.props.maxdate ? moment(this.props.maxdate) : null }
            minimumDate={this.props.mindate ? moment(this.props.mindate) : null }
            selectionType={"single"}
            onBlur={()=>this.setState({isOpenStart: false})}
            className={this.props.placementTop ? 'placement-top' : ''}
        />
        )}
      { this.state.isOpenEnd && (
        <DateRangePicker
            value={this.state.endDate}
            onSelect={this.onSelectEnd}
            singleDateRange={true}
            maximumDate={this.props.maxdate ? moment(this.props.maxdate) : null }
            minimumDate={this.props.mindate ? moment(this.props.mindate) : null }
            selectionType={"single"}
            onBlur={()=>this.setState({isOpenEnd: false})}
            className={`custom-daterange-picker ${this.props.placementTop ? 'placement-top' : ''}`}
        />
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>{this.renderSelectionValue()}</div>
      </div>
    );
  }
}

export default Example;

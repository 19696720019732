import React, { Component } from 'react';
import './style.css'

export default class EmptyState extends Component {

    render() {
        return (
            <div className="empty-wrapper">
                <i className="fas fa-image" style={{marginTop: 2}}></i>
                <p style={{fontSize: 14}}>Lampiran tidak ditemukan</p>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import * as Utils from '../../../helpers/Utils';
import Button from '../../../components/Utils/Button/Button';
import Textarea from '../../../components/Utils/Field/Textarea';
import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import { AEFIService } from '../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../services/CaseEntry/AEService';
import { DropdownService } from '../../../services/Master/AllDropdownService';
import { InitialService } from '../../../services/Manufacture/InitialService';
import { ManufactureService } from '../../../services/Master/ManufactureService';
import { LetterModal } from "./LetterModal"

import { withTranslation } from "react-i18next";

class AddInitial extends Component {

    constructor(props) {
        super(props);

        this.state = {
            caseCategory: "KIPI",
            snackbaropen: false,
            snackbarmsg: '',
            historyForm: [],
            caseEntryData: [],
            caseNumberList: [],
            manufactureList: [],
        };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    componentDidMount() {
        if (this.state.caseCategory === "KIPI") {
            AEFIService.Read("", 1, 0, "", "", "", "", "", "", "", true, "", "", "", "").then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        } else {
            AEService.Read("", 1, 0, "", "", "", "", "", "", "", true, "", "", "", "").then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        }

        DropdownService.MasterList(["outcome"]).then((result) => {
            this.setState({ list: result.data });
        })

        ManufactureService.Read(0, 10, "").then((result) => {
            this.setState({ manufactureList: result.data.records })
        })
    }

    handleCaseCategoryChange(item) {
        if (item === "KIPI") {
            AEFIService.Read("", 1, 0, "", "", "", "", "", "", "", true, "", "", "", "").then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        } else {
            AEService.Read("", 1, 0, "", "", "", "", "", "", "", true, "", "", "", "").then(result => {
                this.setState({
                    caseNumberList: result.data.records
                });
            });
        }
    }

    handleBackClick(event) {
        this.props.history.push({ pathname: `/report-submission/manufacture`, state: { tab: 'Initial' } });
    }

    handleCaseNumberChange(formik, value) {
        const filtered = this.state.caseNumberList.filter(item => item.caseNumber === value)[0];
        let products = [];
        let allergic = [];
        let symptoms = [];
        let symptomps = "";

        if (this.state.caseCategory === "KIPI") {
            if (filtered.aefiVaccine != null) {
                products = filtered.aefiVaccine.map(idx => ({
                    product: idx.vaccineNavigation.id,
                    productName: idx.vaccineNavigation.name,
                    batch: idx.batch,
                    manufacture: idx.vaccineNavigation.manufactureNavigation.name
                }));
            }

            if (filtered.aefiAllergic != null) {
                allergic = filtered.aefiAllergic.map(idx => ({
                    allergic: idx.allergic,
                    date: idx.date,
                    durationMinute: idx.durationMinute,
                    durationHour: idx.durationHour,
                    durationDay: idx.durationDay
                }));
            }

            if (filtered.aefiSymptomps != null) {
                symptoms = filtered.aefiSymptomps.map(idx => ({
                    symptoms: idx.symptomps,
                    date: idx.date,
                    durationMinute: idx.durationMinute,
                    durationHour: idx.durationHour,
                    durationDay: idx.durationDay
                }));
            }
        } else {
            if (filtered.aeDrugs != null) {
                products = filtered.aeDrugs.map(idx => ({
                    product: idx.drugsNavigation.id,
                    productName: idx.drugsNavigation.name,
                    batch: '',
                    manufacture: ''
                }))
            }
        }

        if (this.state.caseCategory === "KIPI") {
            symptomps = filtered.aefiSymptomps?.map(idx => idx.symptompsNavigation?.name)?.join(", ") 

            symptomps += ", " + filtered.aefiAllergic?.map(idx => idx.allergicNavigation?.name)?.join(", ") 
        } else {

        }

        formik.setFieldValue("manufactureSubmissionProduct", products)
        formik.setFieldValue("manufactureSubmissionSymptoms", symptoms)
        formik.setFieldValue("manufactureSubmissionAllergic", allergic)
        formik.setFieldValue("seriousness", filtered.seriousness)
        formik.setFieldValue("caseNumber", value)
        formik.setFieldValue("complaintNo", value);
        formik.setFieldValue("complaintFrom", filtered.reporterName)
        formik.setFieldValue("initialName", filtered.patientName)
        formik.setFieldValue("patientGender", filtered.patientGender)
        formik.setFieldValue("gender", filtered.patientGender)
        formik.setFieldValue("ageOfYear", filtered.patientAgeYear)
        formik.setFieldValue("ageOfMonth", filtered.patientAgeMonth)
        formik.setFieldValue("immunizationDate", Utils.getDate(filtered.lastImmunizationDate))
        formik.setFieldValue("clinicalSymptoms", symptomps)
        formik.setFieldValue("outcome", filtered.outcomeNavigation?.id)
        formik.setFieldValue("caseEntry", filtered.id)
        formik.setFieldValue("caseEntryDate", Utils.getDate(filtered.onsetDate))
        formik.setFieldValue("symptomsDate", Utils.getDate(filtered.aefiSymptomps ? filtered.aefiSymptomps[0].date : filtered.onsetDate))
        formik.setFieldValue("reportedDate", Utils.getDate(filtered.reportedDate))
        formik.setFieldValue("reporterName", Utils.getDate(filtered.reporterName))
    }

    render() {

        const { t } = this.props;

        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        return (
            <>

                <Formik
                    initialValues={{
                        caseEntry: '',
                        caseCategory: '',
                        caseNumber: '',
                        typeCaseEntry: '',
                        manufacture: '',
                        status: '',
                        mailingPlace: "",
                        mailingOrder: 0,
                        mailingDate: "",
                        mailingMonth: "",
                        mailingYear: 0,
                        isApprove: false,
                        isReview: false
                    }}
                    initialTouched={{
                        caseCategory: true,
                        caseNumber: true,
                        manufacture: true,
                        mailingPlace: true,
                        mailingDate: true,
                    }}
                    validationSchema={
                        Yup.object().shape({
                            caseCategory: Yup.string().required("Required").nullable(),
                            caseNumber: Yup.string().required("Required").nullable(),
                            manufacture: Yup.string().required("Required").nullable(),
                            mailingPlace: Yup.string().required("Required").nullable(),
                            mailingDate: Yup.string().required("Required").nullable(),
                        })
                    }
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        let data = {
                            ...values,
                            mailingMonth: new Date(values.onsetDate).getMonth().toString(),
                            mailingYear: new Date(values.onsetDate).getFullYear()
                        }

                        InitialService.Create(data).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push({ pathname: `/report-submission/manufacture`, state: { tab: 'Initial' } });
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )
                    }}
                >
                    {formik =>

                    (
                        <Form>
                            <LetterModal
                                show={this.state.letterShow}
                                onHide={LetterModalClose}
                                formik={formik}
                                typr="initial"
                            />
                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            disabled
                                            name="caseEntry"
                                            type="hidden"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('caseEntry')}
                                        />
                                        <input
                                            disabled
                                            name="typeCaseEntry"
                                            type="hidden"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('typeCaseEntry')}
                                        />
                                        <div className="form-group">
                                            <label className="form-label">{t('case_category')}</label>
                                            <Select2
                                                // disabled
                                                name="caseCategory"
                                                options={[{ value: "KIPI", label: "KIPI" }, { value: "KTD", label: "KTD" }]}
                                                value={formik.values.caseCategory}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={(name, value) => {
                                                    this.handleCaseCategoryChange(value)
                                                    formik.setFieldValue("caseCategory", value)
                                                    formik.setFieldValue("caseNumber", "")
                                                    formik.setFieldValue("typeCaseEntry", value === "KIPI" ? "aefi" : "ae")

                                                    this.setState({ caseCategory: value })
                                                }}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('case_number')}</label>
                                            <Select2
                                                // disabled
                                                name="caseNumber"
                                                options={this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.caseNumber}
                                                onChange={(name, value) => {
                                                    this.handleCaseNumberChange(formik, value)
                                                }}
                                                onBlur={formik.setFieldTouched}
                                            // disabled={isDisabledForm}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">{t('place')}</label>
                                                    <Input
                                                        name="mailingPlace"
                                                        min={0}
                                                        className="form-control"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps('mailingPlace')}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">{t('letter_date')}</label>
                                                    <DatePicker
                                                        name="mailingDate"
                                                        selectionType="single"
                                                        maxdate={new Date()}
                                                        value={formik.values?.mailingDate}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">{t('case_created_date')}</label>
                                                    <DatePicker
                                                        disabled
                                                        name="reportedDate"
                                                        selectionType="single"
                                                        maxdate={new Date()}
                                                        value={formik.values?.reportedDate}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Manufacturer</label>
                                            <Select2
                                                // disabled
                                                name="manufacture"
                                                value={formik.values.manufacture}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={(name, value) => {
                                                    formik.setFieldValue("manufacture", value)
                                                    formik.setFieldValue("target", this.state.manufactureList?.map(item => {
                                                        if (item.id === value) {
                                                            return item.destination
                                                        }
                                                        return ""
                                                    }).join(""))
                                                }}
                                                onBlur={formik.setFieldTouched}
                                                    options={this.state.manufactureList?.filter(
                                                        manufakture => manufakture.masterVaccine
                                                            .map(item => item.id)
                                                            .some(item => formik.values.manufactureSubmissionProduct?.map(item => item.product).includes(item))
                                                    ).map(item => { return { value: item.id, label: item.name }; })}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('letter_address')}</label>
                                            <Textarea
                                                rows="6"
                                                name="target"
                                                placeholder={t('letter_address')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.target ? formik.values?.target : ""}
                                                {...formik.getFieldProps('target')}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                </div>

                                <hr />

                                <label className="form-label" style={{ fontSize: 16 }}>COMPLAINT INFORMATION</label>

                                <div className="row">


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Complaint No</label>
                                            <Input
                                                disabled
                                                name="complaintNo"
                                                type="text"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('complaintNo')}
                                            />
                                        </div>

                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <label className="form-label">{t('vaccine')}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <label className="form-label">Batch</label>
                                                </div>
                                            </div>
                                            <div className="col-md-7">
                                                <div className="form-group" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <label className="form-label">Manufacture</label>
                                                </div>
                                            </div>
                                        </div>
                                        {formik.values.manufactureSubmissionProduct?.map((record, index) =>
                                            <div className="row">
                                                <div className="col-md-3" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <div className="form-group">
                                                        <Input
                                                            disabled
                                                            name="vaccine"
                                                            type="text"
                                                            value={record.productName}
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <div className="form-group">
                                                        <Input
                                                            disabled
                                                            name="batch"
                                                            type="text"
                                                            value={record.batch}
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-7" style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <div className="form-group">
                                                        <Input
                                                            disabled
                                                            name="manufacture"
                                                            type="text"
                                                            value={record.manufacture}
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">From</label>
                                            <Input
                                                disabled
                                                name="complaintFrom"
                                                type="text"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('complaintFrom')}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <hr />

                                <label className="form-label" style={{ fontSize: 16 }}>COMPLAINT DESCRIPTION</label>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('initial_name')}</label>
                                            <Input
                                                disabled
                                                name="initialName"
                                                type="text"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('initialName')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('gender')}</label>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input"
                                                        checked={formik.values.patientGender === "F"}
                                                        value="F"
                                                        name="patientGender"
                                                        disabled
                                                    />
                                                    <label className="form-check-label">{t('male')}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input"
                                                        checked={formik.values.patientGender === "M"}
                                                        value="M"
                                                        name="patientGender"
                                                        disabled
                                                    />
                                                    <label className="form-check-label">{t('female')}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input"
                                                        checked={formik.values.patientGender === "U"}
                                                        value="U"
                                                        name="patientGender"
                                                        disabled
                                                    />
                                                    <label className="form-check-label">{t('no_data')}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label className="form-label">{t('age')}</label>
                                                    <div className="input-group">
                                                        <input
                                                            disabled
                                                            name="ageOfYear"
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps('ageOfYear')}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{t('year')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="form-label">-</label>
                                                    <div className="input-group">
                                                        <input
                                                            disabled
                                                            name="ageOfMonth"
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps('ageOfMonth')}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{t('month')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('immunization_date')}</label>
                                            <DatePicker
                                                disabled
                                                name="immunizationDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.immunizationDate == null? "" : formik.values?.immunizationDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('symptom_date')}</label>
                                            <DatePicker
                                                disabled
                                                name="symptomsDate"
                                                selectionType="single"
                                                maxdate={new Date()}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.symptomsDate}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('clinical_symptoms')}</label>
                                            <Input
                                                disabled
                                                name="clinicalSymptoms"
                                                type="text"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('clinicalSymptoms')}
                                                    value={formik.values?.clinicalSymptoms}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('outcome')}</label>

                                            <Select2
                                                disabled
                                                name="outcome"
                                                options={this.state.list?.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                                value={formik.values.outcome}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <div className="form-group">
                                    <label className="form-label">{t('letter_of_notification')}</label>
                                    <Button
                                        text={t('letter_preview')}
                                        onClick={() => this.setState({ letterShow: true })}
                                    />
                                </div>

                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={(event) => this.handleBackClick(event)} />
                                    </ToggleDisplay>
                                    <ToggleDisplay show={this.state.saveShow}>
                                        <Button
                                            type="submit"
                                            text={t('save')}
                                            disabled={formik.isSubmitting} />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>

                            </div>
                        </Form>
                    )
                    }
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddInitial)

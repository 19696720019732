import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { CausalityService } from '../../services/SummaryReport/CausalityService';
import { withTranslation } from "react-i18next";
export class Causality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            years: [],
            totals: [],
            page: 1,
            maxPage: 0,
            limit: 0,
            query: ""
        }
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {

        CausalityService.Read(this.state.page, this.state.limit, this.state.query, this.state.params).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records?.data ?? [],
                years: result?.data?.records?.listYear,
                totals: result?.data?.records?.totalPerYear,
            });
        });
    }

    download() {
        CausalityService.Download()
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }


    render() {

        const { records, years, totals } = this.state;
        const { t } = this.props;

        return (
            <>

                <div className="row">
                    <div className="col-md-3" style={{ marginBottom: 15 }}>
                        <ButtonWithIcon
                            text="Download"
                            icon="fa fa-download"
                            onClick={() => this.download()}
                        />
                    </div>
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-3">
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div className="right">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.query}
                                            onChange={(event) => this.handleSearchChange(event)}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="table-responsive" style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                    <Table className="pb-5" hover>
                        <thead style={{ textAlign: "center", background: "#F4F5F7" }}>
                            <tr>
                                <th rowspan="3" style={{ verticalAlign: "middle" }}>No</th>
                                <th rowspan="2" style={{ verticalAlign: "middle" }}>{t('causality')}</th>
                                <th colspan="10">{t('year')}</th>
                            </tr>
                            <tr>
                                {
                                    years?.map((record, index) =>
                                        <th>{record}</th>
                                    )
                                }
                            </tr>
                            <tr style={{ background: "#D8F1FF99" }}>
                                <th>Total</th>
                                {
                                    totals?.map((record, index) =>
                                        <th>{record}</th>
                                    )
                                }
                            </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                            {
                                records?.map((record, index) =>

                                    record.Causalities.toLowerCase().includes(this.state.query.toLowerCase()) &&

                                    <tr key={record.id}>
                                        <td>
                                            {index + 1 + (this.state.page - 1) * this.state.limit}
                                        </td>
                                        <td style={{ textAlign: "left" }}>
                                            {record.Causalities}
                                        </td>

                                        {
                                            record?.TotalArr?.map((total, index) =>

                                                <td>{total}</td>

                                            )
                                        }
                                    </tr>
                                )
                            }
                            {
                                records.length < 1 && (
                                    <tr>
                                        <td colSpan="3" style={{ textAlign: "center" }}>
                                            {t('data_not_found')}
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </>
        )
    }

}

export default withTranslation()(Causality);
import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import Select2Multi from '../../../../components/Utils/Field/Select2Multi';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class ProductDistribution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {

        const { formik, formType, t, ...otherProps } = this.props;

        let total = 0;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="product-distribution">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">{t('product_distribution_caps')}</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>{t('year')}</th>
                                                <th>{t('dose')}</th>
                                                <th>Indonesia</th>
                                                <th>Export</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.pbrerProductDistribution?.map((record, index) => {
                                                    total = total + record?.totalVial

                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{record?.year ?? '-'}</td>
                                                            <td>{record?.dose + ' ml' ?? '-'}</td>
                                                            <td>{record?.domesticVial + ' vial' ?? '-'}</td>
                                                            <td>{record?.exportVial + ' vial' ?? '-'}</td>
                                                            <td>{record?.totalVial + ' vial' ?? '-'}</td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                            {
                                                (formik.values?.pbrerProductDistribution === undefined || formik.values?.pbrerProductDistribution === null || formik.values?.pbrerProductDistribution?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    <hr />
                                    <div style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                                        <div style={{ float: "right" }}>
                                            <span style={{ marginRight: 20 }}><span style={{ fontWeight: "bolder", color: "black", marginRight: 20 }}>Total : </span>{total} vial</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ProductDistribution);
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { MarketAutService } from '../../services/MarketAut/MarketAutService';

import FormMarketAut from './FormMarketAut';
import { withTranslation } from "react-i18next";

export class DetailMarketAut extends Component {

    constructor(props) {
        super(props);

        const { id } = this.props.match.params;

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            marketAuthorizationCountry: [],
            marketAuthorizationInstitution: [],
            id: id,
        }

        this.getData(id);
    }

    getData(id) {

        MarketAutService.PrepareForm(id).then(result => {

            this.setState({
                data: result.data,
            });
        });
    }


    cancel = () => {
        this.props.history.push(`/market-authorization`);
    }

    render() {
        const { t } = this.props;

        const market = this.state.data;

        const initial = {
            id: this.state.id,
            product: market?.product,
            productName: market?.productName,
            type: market?.vaccine ? 'vaccine' : 'drugs',
            marketAuthorizationCountry: market?.marketAuthorizationCountry,
            marketAuthorizationInstitution: market?.marketAuthorizationInstitution,
        }

        return (
            <>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={initial}

                        validationSchema={
                            Yup.object().shape({
                                product: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            values.marketAuthorizationCountry = values.marketAuthorizationCountry?.filter(obj => { return obj != null });

                            MarketAutService.Update(values.id, values).then(

                                (result) => {
                                    toastSuccess('Berhasil mengubah data');
                                    this.props.history.push(`/market-authorization`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                        (
                            <Form>
                                <div className="tab-content">
                                    <FormMarketAut
                                        formik={formik}
                                        formType={"edit"}
                                        marketAuthorizationCountry={market?.marketAuthorizationCountry}
                                        marketAuthorizationInstitution={market?.marketAuthorizationInstitution}
                                    />
                                </div>
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={() => this.cancel()}
                                        />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t('save')}
                                            disabled={formik.isSubmitting}
                                        />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>
                            </Form>
                        )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(DetailMarketAut);
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";

import ButtonWithIcon from "../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../components/Utils/DateRange/DateRangePicker";
import TableStatus from "../../components/Utils/Status/TableStatus";

import { Loader } from "../../components/Utils/Loader/Loader";
import { toastSuccess, toastError } from "../../components/Utils/Toast/Toast";
import Select from "react-select";

import { CaseArchivalService } from "../../services/SummaryReport/CaseArchivalService";
import { DropdownService } from "../../services/Master/AllDropdownService";

import DownloadCaseArchival from "./DownloadCaseArchival";
import { withTranslation } from "react-i18next";

export class CaseArchival extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      records: [],
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      loading: false,
      timezone: moment(new Date()).format("Z"),
      pages: pages,
    };
  }

  componentDidMount() {
    this.refreshList();

    DropdownService.MasterList(["seriousness"]).then((result) => {
      this.setState({ list: result.data });
    });
  }

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  record(id, type) {
    this.props.history.push(
      `/summary-report/case-archival/detail/${id}/${type}`,
      { page: this.state.page }
    );
  }

  handleStatusChange(item) {
    let data = this.state.params;
    data = { ...data, status: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  refreshList() {
    this.handleLoading(true);

    if (this.state.type?.value === "KIPI") {
      CaseArchivalService.ReadAEFI(
        this.state.page,
        this.state.limit,
        this.state.query,
        this.state.params
      ).then((result) => {
        this.setState({
          maxPage: result?.data?.maxPage,
          records: result?.data?.records,
        });
        this.handleLoading(false);
      });
    } else if (this.state.type?.value === "KTD") {
      CaseArchivalService.ReadAE(
        this.state.page,
        this.state.limit,
        this.state.query,
        this.state.params
      ).then((result) => {
        this.setState({
          maxPage: result?.data?.maxPage,
          records: result?.data?.records,
        });
        this.handleLoading(false);
      });
    } else {
      Promise.all([
        CaseArchivalService.ReadAEFI(
          this.state.page,
          this.state.limit,
          this.state.query,
          this.state.params
        ),
        CaseArchivalService.ReadAE(
          this.state.page,
          this.state.limit,
          this.state.query,
          this.state.params
        ),
      ]).then(([kipi, ktd]) => {
        this.setState({
          maxPage:
            kipi?.data?.maxPage > ktd?.data?.maxPage
              ? kipi?.data?.maxPage
              : ktd?.data?.maxPage,
          records: [...kipi?.data?.records, ...ktd?.data?.records],
        });
        this.handleLoading(false);
      });
    }
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleTypeChange(item) {
    this.setState({ type: item }, () => {
      this.handlePageReset();
    });
  }

  handleSeriousnessChange(item) {
    let data = this.state.params;
    data = { ...data, seriousness: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  handlePageClick(event) {
    if (this.state.pages !== undefined) {
      this.setState({ page: this.state.pages, pages: undefined }, () => {
        this.refreshList();
      });
    } else if (isNaN(event.selected)) {
      this.setState({ page: 1 }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  async download(lang) {
    var download = CaseArchivalService.Download(this.state.params, lang);

    download.then((data) => {
      if (data?.status == 400) {
        toastError(data.message);
      }
    });
  }

  render() {
    const { records } = this.state;
    const { t } = this.props;

    const statusOptions = [
      { value: "Open", label: "Open" },
      { value: "Pending", label: "Pending" },
      { value: "Closed", label: "Closed" },
    ];

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DownloadCaseArchival
          show={this.state.downloadModal}
          onHide={() => this.setState({ downloadModal: false })}
        />

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-xs-6 col-sm-6 col-md-3">
              <div style={{ marginBottom: 15 }}>
                <ButtonWithIcon
                  text={`Download`}
                  icon="fa fa-download"
                  onClick={() => this.setState({ downloadModal: true })}
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                  <div className="row">
                    <div
                      className="col-xs-12 col-sm-6 col-md-3"
                      style={{ marginBottom: 10 }}
                    >
                      <label className="form-label">Kategori Kasus</label>
                      <Select
                        placeholder="Semua Jenis"
                        width="244px"
                        name="typeFilter"
                        isClearable="true"
                        classNamePrefix="select"
                        className="basic-single"
                        options={[
                          { value: "KIPI", label: "KIPI" },
                          { value: "KTD", label: "KTD" },
                        ]}
                        value={this.state.type}
                        onChange={(item) => this.handleTypeChange(item)}
                      ></Select>
                    </div>
                    <div
                      className="col-xs-12 col-sm-6 col-md-3"
                      style={{ marginBottom: 10 }}
                    >
                      <label className="form-label">Tanggal Penerimaan</label>
                      <DateRange
                        handlechange={(startDate, endDate, type) =>
                          this.handleDateChange(startDate, endDate, type)
                        }
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-3">
                      <label className="form-label">Tingkat Keseriusan</label>
                      <Select
                        placeholder="Semua Tingkat"
                        width="100%"
                        name="seriousnessFilter"
                        isClearable="true"
                        classNamePrefix="select"
                        className="basic-single"
                        options={this.state.list?.seriousnessList.map((x) => {
                          return { value: x.id, label: x.name };
                        })}
                        value={this.state.seriousness}
                        onChange={(item) => this.handleSeriousnessChange(item)}
                      ></Select>
                    </div>
                    <div
                      className="col-xs-12 col-sm-6 col-md-3"
                      style={{ marginBottom: 10 }}
                    >
                      <label className="form-label">Status</label>
                      <Select
                        placeholder="Status"
                        width="100%"
                        name="statusFilter"
                        isClearable="true"
                        classNamePrefix="select"
                        className="basic-single"
                        options={statusOptions}
                        value={this.state.status}
                        onChange={(item) => this.handleStatusChange(item)}
                      ></Select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("product")}</th>
                    <th>{t("unit_pv_date")}</th>
                    <th>{t("immunization_date")}</th>
                    <th>{t("outcome")}</th>
                    <th>{t("seriousness")}</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          {index + 1 + (this.state.page - 1) * this.state.limit}
                        </td>
                        <td>{record.caseNumber}</td>
                        {record.aefiVaccine && (
                          <td>
                            {record.aefiVaccine?.length > 0
                              ? record.aefiVaccine
                                  .map((x) => {
                                    return x.vaccineNavigation?.name;
                                  })
                                  .join(", ")
                              : "-"}
                          </td>
                        )}
                        {record.aeDrugs && (
                          <td>
                            {record.aeDrugs?.length > 0
                              ? record.aeDrugs
                                  .map((x) => {
                                    return x.drugsNavigation?.name;
                                  })
                                  .join(", ")
                              : "-"}
                          </td>
                        )}
                        <td>
                          {record.adversePvDate
                            ? moment(record.adversePvDate).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>
                          {record.lastImmunizationDate
                            ? moment(record.lastImmunizationDate).format(
                                "DD/MM/yyyy"
                              )
                            : record?.productUseDate
                            ? moment(record.productUseDate).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>{record.outcomeNavigation?.name ?? "-"}</td>
                        <td>{record.seriousnessNavigation?.alias ?? "-"}</td>
                        <td>
                          <TableStatus status={record.statusCaseArchival} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.record(
                                    record.id,
                                    record.aefiVaccine ? "AEFI" : "AE"
                                  )
                                }
                              >
                                Detail
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  {records.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                initialPage={this.state.pages - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CaseArchival);

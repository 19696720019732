import React, { Component } from 'react';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Formik, Form } from 'formik';
import ToggleDisplay from 'react-toggle-display';
import * as Yup from 'yup';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import Tab from '../../../components/Utils/Tab/Tab';
import Appropriateness from './TabCausalityUnit/Appropriateness';
import AefiList from './TabCausalityUnit/AefiList';
import Algorithm from './TabCausalityUnit/Algorithm';
import Classification from './TabCausalityUnit/Classification';
import ClassificationIncomplete from './TabCausalityUnit/ClassificationIncomplete';
import Causality from './TabCausalityUnit/Causality';

export class AddCausalityUnitAEFIModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '', causality_selected: [], nextTab : this.props.data_parent?.values.completenessData === "Yes" ? "aefi-list" : "causality"};

    }

    componentDidMount(){
        this.setState({nextTab : this.props.data_parent?.values.completenessData === "Yes" ? "aefi-list" : "causality"})  
    }

    componentDidUpdate(prevProps) {

        if(prevProps.index !== this.props.index ){
            this.setState({nextTab : this.props.data_parent?.values?.completenessData === "Yes" ? "aefi-list" : "causality"})  
        }
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {

        document.getElementById("link-" + this.state.nextTab).click();
        event.preventDefault();
    } 

    handleTabChange(event, tab) {
        switch (tab) {
            case "#appropriateness":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "appropriateness", backTab: "", nextTab: "aefi-list"
                });
                break;
            case "#aefi-list":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "aefi-list", backTab: "appropriateness", nextTab: this.props.typecase === "KIPI" ? "immunization" : "algorithm",
                    patientDisabled: false
                });
                break;
            case "#algorithm":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "algorithm", backTab: "aefi-list", nextTab: "classification",
                    algorithmDisabled : false
                });
                break;
            case "#classification":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: false, saveShow: true,
                    currentTab: "classification", backTab: this.props.typecase === "KIPI" ? "symptomps" : "algorithm", nextTab: "",
                    manifestationResultDisabled: false
                });
                break;
            case "#classification_incomplete":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: false, saveShow: true,
                    currentTab: "classification_incomplete", backTab: "", nextTab: "causality",
                    manifestationResultDisabled: false
                });
                break;
            case "#causality":
            this.setState({
                cancelShow: false, backShow: true, nextShow: false, saveShow: true,
                currentTab: "causality", backTab: "classification_incomplete", nextTab: "",
                manifestationResultDisabled: false
            });
            break;
        }

        event.preventDefault();
    }

    render() {
        const { data_parent, history, list, causality, index, caseEntry, product, disabled, t, i18next,  ...otherProps } = this.props;
        const causalityRelation = this.props.data_parent.values.completenessData === "Yes" ? this.props.list?.causalityAEFIComplete : this.props.list?.causalityAEFIIncomplete;
        //  Get selected product
        let causal = causality ? causality[index] : [];
        let causalities = [];

        let causalHistory = [];

        if(history !== undefined && causal){
            history.map((record, index) => {
                if(record.productName === causal.productName){
                    causalHistory = history[index];
                }
            })
        }

        let question1;
        let question2;
        let question3;
        let question4;
        let question5;
        let question6;
        let question7;
        let question8;
        let question9;
        let question10;
        let question11;
        let question12;
        let question13;
        let question14;
        let question15;
        let question16;
        let question17;
        let question18;
        let question19;
        let question20;
        
        let question1Sidelight;
        let question2Sidelight;
        let question3Sidelight;
        let question4Sidelight;
        let question5Sidelight;
        let question6Sidelight;
        let question7Sidelight;
        let question8Sidelight;
        let question9Sidelight;
        let question10Sidelight;
        let question11Sidelight;
        let question12Sidelight;
        let question13Sidelight;
        let question14Sidelight;
        let question15Sidelight;
        let question16Sidelight;
        let question17Sidelight;
        let question18Sidelight;
        let question19Sidelight;
        let question20Sidelight;

        let otherUnknown;
        let otherProgrammaticError;
        let otherVaccineReaction;
        let otherInjectionReaction;
        let otherCoincidental;
        let classifications = [];
        let subClassificationsProgrammaticError = [];
        let subClassificationsInjectionReaction = [];

        if(causal?.analysisClassification?.length > 0 || causal?.trailAnalysisClassification?.length > 0){
            let analisisClassification = causal?.analysisClassification ? causal?.analysisClassification : causal?.trailAnalysisClassification
            analisisClassification.map((record, index) => {
                if(record.classificationNavigation.group === "Programmatic Error"){
                    otherProgrammaticError = record.sidelight;
                    classifications = [...classifications, "0e100bb3-a828-4de5-9747-aab101de3467"]
                    subClassificationsProgrammaticError = [...subClassificationsProgrammaticError, record.classification]
                }
                if(record.classificationNavigation.group === "Injection Reaction"){
                    otherInjectionReaction = record.sidelight;
                    classifications = [...classifications, "cf45d3aa-47c7-4843-8c38-b781c3b5fd80"]
                    subClassificationsInjectionReaction = [...subClassificationsInjectionReaction, record.classification]
                }
                if(record.classificationNavigation.group === "Vaccine Reaction"){
                    otherVaccineReaction = record.sidelight;
                    classifications = [...classifications, "cdb973d2-dcbb-481f-9aaa-68bd5bebfc18"]
                }
                if(record.classificationNavigation.group === "Unknown"){
                    otherUnknown = record.sidelight;
                    classifications = [...classifications, "80034f4c-ae1e-4338-b409-ed2c33a86a94"]
                }
                if(record.classificationNavigation.group === "Coincidental"){
                    otherCoincidental = record.sidelight;
                    classifications = [...classifications, "2b50778a-4ba8-4fe6-a885-865c60e35ffc"]
                }
            });
        }

        if(causal?.analysisCausalityUpv?.length > 0 || causal?.trailAnalysisCausalityUpv){
            let causalUpv = causal?.analysisCausalityUpv ? causal?.analysisCausalityUpv : causal?.trailAnalysisCausalityUpv
            causalUpv.map((record, index) => {
                causalities = [...causalities, record.causality]
            })
        }


        if(causal?.analysisAefiList?.length > 0 || causal?.trailAnalysisAefiList?.length > 0){
            let aefiListcausality = causal.analysisAefiList ? causal.analysisAefiList : causal.trailAnalysisAefiList ;
            aefiListcausality.map((record, index) => {
                if (record.question === "question1"){
                    question1 = record.answer !== null ? record.answer : "";
                    question1Sidelight = record.sidelight ? record.sidelight : "";
                }
    
                if (record.question === "question2"){
                    question2 = record.answer !== null ? record.answer : "";
                    question2Sidelight = record.sidelight ? record.sidelight : "";
                }
    
                if (record.question === "question3"){
                    question3 = record.answer !== null ? record.answer : "";
                    question3Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question4"){
                    question4 = record.answer !== null ? record.answer : "";
                    question4Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question5"){
                    question5 = record.answer !== null ? record.answer : "";
                    question5Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question6"){
                    question6 = record.answer !== null ? record.answer : "";
                    question6Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question7"){
                    question7 = record.answer !== null ? record.answer : "";
                    question7Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question8"){
                    question8 = record.answer !== null ? record.answer : "";
                    question8Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question9"){
                    question9 = record.answer !== null ? record.answer : "";
                    question9Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question10"){
                    question10 = record.answer !== null ? record.answer : "";
                    question10Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question11"){
                    question11 = record.answer !== null ? record.answer : "";
                    question11Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question12"){
                    question12 = record.answer !== null ? record.answer : "";
                    question12Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question13"){
                    question13 = record.answer !== null ? record.answer : "";
                    question13Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question14"){
                    question14 = record.answer !== null ? record.answer : "";
                    question14Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question15"){
                    question15 = record.answer !== null ? record.answer : "";
                    question15Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question16"){
                    question16 = record.answer !== null ? record.answer : "";
                    question16Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question17"){
                    question17 = record.answer !== null ? record.answer : "";
                    question17Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question18"){
                    question18 = record.answer !== null ? record.answer : "";
                    question18Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question19"){
                    question19 = record.answer !== null ? record.answer : "";
                    question19Sidelight = record.sidelight ? record.sidelight : "";
                }
                if (record.question === "question20"){
                    question20 = record.answer !== null ? record.answer : "";
                    question20Sidelight = record.sidelight ? record.sidelight : "";
                }
            })
        }

        return (
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props} size="xl">
                    <ModalHeader>
                        <div className="modal-title">{t('add') +" "+ t('causality')}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <Formik
                            initialValues={{
                                classifications: "",
                                subClassifications: "",
                                diagnosisType: causal?.diagnosisType !== null ? (causal?.diagnosisType ? "Pasti" : "Sementara") : "" ,
                                diagnosisFulfills : causal?.diagnosisFulfills !== null ? ( causal?.diagnosisFulfills ? "Yes" : "No") : "",
                                diagnosis : causal?.diagnosis ? causal?.diagnosis : "", 
                                classificationConclusion : causal?.classificationConclusion ? causal?.classificationConclusion : "",
                                classificationReason : causal?.classificationReason ? causal?.classificationReason : "",
                                classificationConcluded : causal?.classificationConcluded !== null ? (causal?.classificationConcluded ? "Yes" : "No") : "", 
                                causalityRelation: causal?.causalityUpv ? causal?.causalityUpv : "",
                                causalityReasons : causal?.causalityReasons ? causal?.causalityReasons : "",
                                productCauses : causal?.productCauses ? causal?.productCauses : "",
                                relatedVaccine: product?.map(record => { return record.vaccine }),
                                evidenceNotes : causal?.evidenceNotes ? causal.evidenceNotes : "",
                                evidenceDenialCausal : causal?.evidenceDenialCausal !== null ? (causal?.evidenceDenialCausal ? "Yes" : "No") : "",
                                evidenceTimeWindow : causal?.evidenceTimeWindow !== null ? (causal?.evidenceTimeWindow ? "Yes" : "No") : "",
                                evidenceSubTimeWindow : causal?.evidenceSubTimeWindow !== null ? (causal?.evidenceSubTimeWindow ? "Yes" : "No") : "",
                                evidenceOtherCauses : causal?.evidenceOtherCauses !== null ? (causal?.evidenceOtherCauses ? "Yes" : "No") : "",
                                question1 : question1,
                                question2 : question2,
                                question3 : question3,
                                question4 : question4,
                                question5 : question5,
                                question6 : question6,
                                question7 : question7,
                                question8 : question8,
                                question9 : question9,
                                question10 : question10,
                                question11 : question11,
                                question12 : question12,
                                question13 : question13,
                                question14 : question14,
                                question15 : question15,
                                question16 : question16,
                                question17 : question17,
                                question18 : question18,
                                question19 : question19,
                                question20 : question20,
                                question1Sidelight : question1Sidelight,
                                question2Sidelight : question2Sidelight,
                                question3Sidelight : question3Sidelight,
                                question4Sidelight : question4Sidelight,
                                question5Sidelight : question5Sidelight,
                                question6Sidelight : question6Sidelight,
                                question7Sidelight : question7Sidelight,
                                question8Sidelight : question8Sidelight,
                                question9Sidelight : question9Sidelight,
                                question10Sidelight : question10Sidelight,
                                question11Sidelight : question11Sidelight,
                                question12Sidelight : question12Sidelight,
                                question13Sidelight : question13Sidelight,
                                question14Sidelight : question14Sidelight,
                                question15Sidelight : question15Sidelight,
                                question16Sidelight : question16Sidelight,
                                question17Sidelight : question17Sidelight,
                                question18Sidelight : question18Sidelight,
                                question19Sidelight : question19Sidelight,
                                question20Sidelight : question20Sidelight,
                                causalities : causalities,
                                otherUnknown : otherUnknown,
                                otherInjectionReaction : otherInjectionReaction,
                                otherVaccineReaction : otherVaccineReaction,
                                otherProgrammaticError : otherProgrammaticError,
                                otherCoincidental:otherCoincidental,
                                subClassificationsProgrammaticError : subClassificationsProgrammaticError,
                                subClassificationsInjectionReaction : subClassificationsInjectionReaction,
                                classifications : classifications
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    causalityRelation: Yup.string().required('Required'),
                                })
                            }
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();

                                let causal = [...causality]
                                let i = causalityRelation?.map(function(x) {return x.id; }).indexOf(values.causalityRelation);
                                causal[this.props.index].causalityNamePV = causalityRelation[i].name;
                                causal[this.props.index].causalityNamePVEn = causalityRelation[i].nameEnTranslate;
                                causal[this.props.index].causalityUpv = values.causalityRelation;
                                causal[this.props.index].causalityReasons = values.causalityReasons;
                                causal[this.props.index].outcome = caseEntry?.outcome;
                                causal[this.props.index].seriousness = caseEntry?.seriousness;
                                causal[this.props.index].expectedness = caseEntry?.expectedness;
                                causal[this.props.index].diagnosis = values.diagnosis;

                                if(data_parent.values.completenessData === "No"){

                                    let subClassificationsProgrammaticError = values?.subClassificationsProgrammaticError ? values?.subClassificationsProgrammaticError : []; 
                                    let subClassificationsInjectionReaction = values?.subClassificationsInjectionReaction ? values?.subClassificationsInjectionReaction : []; 
                                    let classifications = [];
                                    subClassificationsProgrammaticError.map((record, index) =>
                                    {
                                        let classificationNavigation = [];
                                            classificationNavigation.group = "Programmatic Error";
                                        if(record === "c45c8d24-1b4c-4896-8faa-86a3107ca409"){
                                            
                                            classifications = [...classifications, { classification : record, sidelight : values.otherProgrammaticError, classificationNavigation}];
                                        }else {
                                            
                                            classifications = [...classifications, { classification : record, classificationNavigation}];
                                        }
                                        
                                    })
                                    subClassificationsInjectionReaction.map((record, index) =>
                                    {
                                        let classificationNavigation = [];
                                            classificationNavigation.group = "Injection Reaction";

                                        if(record === "c45c8d24-1b4c-4896-8faa-86a3107ca409"){
                                            
                                            classifications = [...classifications, { classification : record, sidelight : values.otherInjectionReaction, classificationNavigation}];
                                        }else{
                                            
                                            classifications = [...classifications, { classification : record, classificationNavigation}];
                                        }
                                        
                                    })

                                    if(values.otherVaccineReaction){
                                        let classificationNavigation = [];
                                            classificationNavigation.group = "Vaccine Reaction";
                                        classifications = [...classifications, { classification : "23ff9f4c-d14b-456d-b956-eb2e3c8a4723", sidelight : values.otherVaccineReaction, classificationNavigation }];
                                    }
                                    
                                    if(values.otherCoincidental){
                                        let classificationNavigation = [];
                                            classificationNavigation.group = "Coincidental";
                                        classifications = [...classifications, { classification : "2b50778a-4ba8-4fe6-a885-865c60e35ffc", sidelight : values.otherCoincidental, classificationNavigation}];
                                    }
                                    
                                    if(values.otherUnknown){
                                        let classificationNavigation = [];
                                            classificationNavigation.group = "Unknown";
                                        classifications = [...classifications, { classification : "3096eeb0-8aae-4a7e-a56c-76c9d7527c98", sidelight : values.otherUnknown, classificationNavigation}];
                                    }
                                    
                                    causal[this.props.index].classifications = classifications;
                                    causal[this.props.index].analysisClassification = classifications;
                                    causal[this.props.index].diagnosisType = values.diagnosisType === "Pasti" ? true : false;
                                    
                                }else{
                                    causal[this.props.index].diagnosisFulfills = values.diagnosisFulfills === "Yes" ? true : false;
                                    causal[this.props.index].evidenceOtherCauses = values.evidenceOtherCauses === "Yes" ? true : false;
                                    causal[this.props.index].evidenceTimeWindow = values.evidenceTimeWindow === "Yes" ? true : false;
                                    causal[this.props.index].evidenceSubTimeWindow = values.evidenceSubTimeWindow === "Yes" ? true : false;
                                    causal[this.props.index].evidenceDenialCausal = values.evidenceDenialCausal === "Yes" ? true : false;
                                    causal[this.props.index].evidenceNotes = values.evidenceNotes
                                    causal[this.props.index].classificationConcluded = values.classificationConcluded === "Yes" ? true : false;
                                    causal[this.props.index].classificationConclusion = values.classificationConclusion
                                    causal[this.props.index].classificationReason = values.classificationReason
                                    causal[this.props.index].productCauses = values.productCauses
                                    causal[this.props.index].aefiList = [ 
                                    
                                        { question : "question1" , answer: values.question1, sidelight: values.question1Sidelight },
                                        { question : "question2" , answer: values.question2, sidelight: values.question2Sidelight },
                                        { question : "question3" , answer: values.question3, sidelight: values.question3Sidelight },
                                        { question : "question4" , answer: values.question4, sidelight: values.question4Sidelight },
                                        { question : "question5" , answer: values.question5, sidelight: values.question5Sidelight },
                                        { question : "question6" , answer: values.question6, sidelight: values.question6Sidelight },
                                        { question : "question7" , answer: values.question7, sidelight: values.question7Sidelight },
                                        { question : "question8" , answer: values.question8, sidelight: values.question8Sidelight },
                                        { question : "question9" , answer: values.question9, sidelight: values.question9Sidelight },
                                        { question : "question10" , answer: values.question10, sidelight: values.question10Sidelight },
                                        { question : "question11" , answer: values.question11, sidelight: values.question11Sidelight },
                                        { question : "question12" , answer: values.question12, sidelight: values.question12Sidelight },
                                        { question : "question13" , answer: values.question13, sidelight: values.question13Sidelight },
                                        { question : "question14" , answer: values.question14, sidelight: values.question14Sidelight },
                                        { question : "question15" , answer: values.question15, sidelight: values.question15Sidelight },
                                        { question : "question16" , answer: values.question16, sidelight: values.question16Sidelight },
                                        { question : "question17" , answer: values.question17, sidelight: values.question17Sidelight },
                                        { question : "question18" , answer: values.question18, sidelight: values.question18Sidelight },
                                        { question : "question19" , answer: values.question19, sidelight: values.question19Sidelight },
                                        { question : "question20" , answer: values.question20, sidelight: values.question20Sidelight },
                                    ]

                                    causal[this.props.index].analysisAefiList = causal[this.props.index].aefiList;


                                    let causalities = values?.causalities ? values?.causalities : []; 
                                    let causalitiesUPV = [];

                                    if(values?.causalityRelation === "ac918a14-7812-4119-a955-71017c5c549d"){
                                        causalities = [...causalities, "a4bab67b-fd61-4f97-8167-e5b5816e05b7"]
                                    }

                                    causalities.map((record, index) =>
                                    {
                                        causalitiesUPV = [...causalitiesUPV, { causality : record }]; 
                                    })
                                    
                                    causal[this.props.index].causalitiesUPV = causalitiesUPV;
                                    causal[this.props.index].analysisCausalityUpv = causalitiesUPV;
                                    
                                }

                                this.props.handleCausality(causal)
                                this.props.onhide()
                                this.setState({
                                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                                    currentTab: "appropriateness", backTab: "", nextTab: "aefi-list"
                                });
                            }}
                        >
                            {formik => (
                                <Form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="nav" style={{width: "100%"}}>
                                                <ul className="nav nav-justified mb-3 menu-tab" style={{width: "100%"}}>
                                                    {
                                                        data_parent.values.completenessData === "Yes" ? (
                                                            <>
                                                                <Tab
                                                                    number="1"
                                                                    showNumber = {true}
                                                                    title={t('appropriateness')}
                                                                    href="#appropriateness"
                                                                    onClick={(event) => this.handleTabChange(event, "#appropriateness")}
                                                                    id="link-appropriateness"
                                                                    
                                                                />
                                                                <Tab
                                                                    number="2"
                                                                    showNumber = {true}
                                                                    title={t('list_aefi')}
                                                                    href="#aefi-list"
                                                                    onClick={(event) => this.handleTabChange(event, "#aefi-list")}
                                                                    id="link-aefi-list"
                                                                />
                                                                <Tab
                                                                    number= "3"
                                                                    showNumber = {true}
                                                                    title={t('algorithm')}
                                                                    href="#algorithm"
                                                                    onClick={(event) => this.handleTabChange(event, "#algorithm")}
                                                                    id="link-algorithm"
                                                                />
                                                                <Tab
                                                                    number= "4"
                                                                    showNumber = {true}
                                                                    title={t('classification_small')}
                                                                    href="#classification"
                                                                    onClick={(event) => this.handleTabChange(event, "#classification")}
                                                                    id="link-classification"
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Tab
                                                                    number="1"
                                                                    showNumber = {true}
                                                                    title={t('classification_small')}
                                                                    href="#classification_incomplete"
                                                                    onClick={(event) => this.handleTabChange(event, "#classification_incomplete")}
                                                                    id="link-classification_incomplete"
                                                                />
                                                                <Tab
                                                                    number="2"
                                                                    showNumber = {true}
                                                                    title={t('causality_relation')}
                                                                    href="#causality"
                                                                    onClick={(event) => this.handleTabChange(event, "#causality")}
                                                                    id="link-causality"
                                                                />
                                                            </>
                                                        )
                                                    }
                                                    
                                                </ul>
                                            </div>
                                            <div className="tab-content">
                                            {
                                                data_parent.values.completenessData === "Yes" ? (
                                                    <>
                                                        <Appropriateness
                                                            formik={formik}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            list={list}  
                                                            onhide={this.props.onhide}  
                                                            product={product}
                                                            index={index}  
                                                            causality={causal} 
                                                            data={data_parent}
                                                            caseEntry={caseEntry}
                                                            disabled={disabled} 
                                                            t={t}
                                                        />
                                                        <AefiList
                                                            formik={formik}
                                                            handleBackClick={(event) => this.handleBackClick(event)}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            list={list}   
                                                            index={index}  
                                                            causality={causal}  
                                                            data={data_parent}
                                                            caseEntry={caseEntry} 
                                                            disabled={disabled}
                                                            t={t}
                                                        />
                                                        <Algorithm
                                                            formik={formik}
                                                            handleBackClick={(event) => this.handleBackClick(event)}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            list={list}    
                                                            index={index}  
                                                            causality={causal} 
                                                            data={data_parent}
                                                            caseEntry={caseEntry} 
                                                            disabled={disabled}
                                                            t={t}
                                                            i18next={i18next}
                                                        />
                                                        <Classification
                                                            formik={formik}
                                                            handleBackClick={(event) => this.handleBackClick(event)}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            data={data_parent}
                                                            list={list}
                                                            causality={causal}
                                                            caseEntry={caseEntry}
                                                            index={index}  
                                                            disabled={disabled}     
                                                            t={t}
                                                            i18next={i18next}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <ClassificationIncomplete
                                                            formik={formik}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            list={list}
                                                            index={index}  
                                                            causality={causal} 
                                                            onhide={this.props.onhide}   
                                                            disabled={disabled}
                                                            t={t}
                                                            i18next={i18next}
                                                        />
                                                        <Causality
                                                            formik={formik}
                                                            handleBackClick={(event) => this.handleBackClick(event)}
                                                            handleNextClick={(event) => this.handleNextClick(event)}
                                                            history={causalHistory}
                                                            list={list}  
                                                            index={index}  
                                                            causality={causal}
                                                            caseEntry={caseEntry}
                                                            disabled={disabled}  
                                                            t={t} 
                                                            i18next={i18next}
                                                        />
                                                    </>
                                                )
                                            }
                                            {
                                                this.state.nextTab == "" &&
            
                                                <div className='text-right'>
                                                    <ToggleDisplay>
                                                        <Button
                                                            color="#495057"
                                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                                            border="1px solid #495057"
                                                            text={t('back')}
                                                            onClick={(event) => this.handleBackClick(event)} />
                                                    </ToggleDisplay>
                                                    {
                                                        disabled === false && (
                                                            <ToggleDisplay show={this.state.saveShow}>
                                                                <Button
                                                                    type="submit"
                                                                    text={t('save')}
                                                                    disabled={formik.isSubmitting} />
                                                            </ToggleDisplay>
                                                        )
                                                    }
                                                    {
                                                        formik.status &&
                                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                                    }
                                                </div>
            
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
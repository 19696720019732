import React, { Component } from 'react';
import JSONPretty from 'react-json-pretty';

import Modal from '../../components/Utils/Modal/Modal';
import ModalContent from '../../components/Utils/Modal/ModalBody';

import { AuditTrailService } from '../../services/AuditTrail/AuditTrailService';


export class DetailModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.refreshList();
        }
    }

    refreshList = () => {
        AuditTrailService.PrepareForm(this.props.id).then(result => {
            this.setState({
                records: result.data,
            });
        });
    }


    render() {

        const { records } = this.state;

        const { data } = this.props;

        const rowStyled = {
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px"
        }

        const col3Styled = {
            flex: "0 0 25%",
            maxWidth: "25%",
            paddingRight: 0
        }

        const col7Styled = {
            flex: "0 0 58.33333%",
            maxWidth: "58.33333%",
            paddingRight: 0
        }

        const col5Styled = {
            flex: "0 0 41.66667%",
            maxWidth: "41.66667%",
        }

        const col12Styled = {
            flex: "0 0 100%",
            maxWidth: "100%"
        }

        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalContent>
                        <div style={{ padding: "50px 20px" }}>
                            <div style={rowStyled}>
                                <div style={col12Styled}>
                                    <table style={{ width: "100%" }}>
                                        <tr style={{ verticalAlign: "top" }}>
                                            <td style={{ width: "20%" }}>End Point</td>
                                            <td>: </td>
                                            <td style={{ width: "75%" }}>{data?.endpoint ? data?.endpoint : "-"}</td>
                                        </tr>
                                        <tr style={{ verticalAlign: "top" }}>
                                            <td>Request</td>
                                            <td>: </td>
                                            <td>
                                                <div style={{
                                                    width: '500px',                                                                                                        
                                                    wordWrap: "break-word",
                                                }}>
                                                    <JSONPretty data={records?.request ?? {}} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ verticalAlign: "top" }}>
                                            <td>Previous Data</td>
                                            <td>: </td>
                                            <td style={{ wordBreak: "break-all" }}>{records?.previousData ?? "-"}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
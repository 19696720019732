import React, { Component } from 'react'; 
import moment from 'moment';

import { DigitalSignatureService } from '../../../services/Profile/DigitalSignatureService';

export class SignatureForm extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            regexp : /^[0-9\b]+$/,
            signatureCheck : null,
            waitingDate : '',
            seconds: 0,
            minutes : 0,

        };
    }

    componentDidMount() {

        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
              this.setState({ seconds : seconds - 1 })
            }
            if (seconds === 0 ) {
                
                if(minutes > 0){
                    this.setState({ minutes : minutes - 1, seconds: 59 })
                }
            }
          }, 1000)
      }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.formik.values !== this.props.formik.values ){

            if(this.props.check){

                let values = this.props.formik.values;

                if(values.signature1 && values.signature2 && values.signature3 && values.signature4 && values.signature5 && values.signature6){

                    values.signature = values.signature1 + values.signature2 + values.signature3 + values.signature4 + values.signature5 + values.signature6;
                    values.referenceId = this.props.id;

                    const {t} = this.props;

                    DigitalSignatureService.ApprovalSignature(values).then(
                        (result) => {
                            if (result?.status === 200) {
                                this.setState({signatureCheck : true, signatureMessage : `Digital signature ${ t ? t('match') : "sesuai"}`})
                                this.props.formik.setFieldValue('verified', 'verified');
                                this.props.disabledButton(false)
                            }else{
                                if(result?.data !== null){
                                    this.setState({waitingDate : result?.data?.attemptAt ? moment(result?.data?.attemptAt).format('MMMM DD, YYYY hh:mm:ss') : '' });
                                    this.setState({signatureMessage : 'Waiting'})
                                }else{
                                    this.setState({signatureMessage : `Digital signature ${ t ? t('doesnt_match') : "tidak sesuai"}`})
                                }
                                this.setState({signatureCheck : false})
                                this.props.formik.setFieldValue('verified', '');
                            }
                            
                        }
                    )
                }
            }
        }

        if(prevState.waitingDate !== this.state.waitingDate){
            let time_end = new Date(this.state.waitingDate);
            let time_start = new Date();

            let diff = Math.abs(Math.floor((time_end - time_start) / 1000));
            
            let minutes = Math.abs(Math.floor(diff / 60) % 60);

            let second = (Math.abs(diff - (minutes * 60)) % 60);

            this.setState({
                minutes: minutes,
                seconds: second,
            })
        }
    }

    handleKeyup = (event, formik) => {
        if (event.keyCode === 8) {
            switch (event.target.name) {
                case "signature2":
                    document.getElementById("signature1").focus();
                    break;
                case "signature3":
                    document.getElementById("signature2").focus();
                    break;
                case "signature4":
                    document.getElementById("signature3").focus();
                    break;
                case "signature5":
                    document.getElementById("signature4").focus();
                    break;
                case "signature6":
                    document.getElementById("signature5").focus();
                    break;
            }
        }
    }

    changeNext = (event, formik) => {
        if (this.state.regexp.test(event.target.value)) {
            formik(event.target.name, event.target.value)
            if(event.target.value.length === 1) {
                switch (event.target.name) {
                    case "signature1":
                        document.getElementById("signature2").focus();
                        break;
                    case "signature2":
                        document.getElementById("signature3").focus();
                        break;
                    case "signature3":
                        document.getElementById("signature4").focus();
                        break;
                    case "signature4":
                        document.getElementById("signature5").focus();
                        break;
                    case "signature5":
                        document.getElementById("signature6").focus();
                        break;
                }
            }

        } else {

            formik(event.target.name, '')
        }
    }

    render() {
 
        const {formik, t} = this.props;

        return (
            <>
                <div className="signature-wrapper" style={{display:"flex"}}>
                    <input
                        id="signature1"
                        name="signature1"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature1'] ? ' is-invalid' : '')}
                        value={formik.values?.signature1}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                    <input
                        id="signature2"
                        name="signature2"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature2'] ? ' is-invalid' : '')}
                        value={formik.values?.signature2}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                    <input
                        id="signature3"
                        name="signature3"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature3'] ? ' is-invalid' : '')}
                        value={formik.values?.signature3}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                    <input
                        id="signature4"
                        name="signature4"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature4'] ? ' is-invalid' : '')}
                        value={formik.values?.signature4}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                    <input
                        id="signature5"
                        name="signature5"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature5'] ? ' is-invalid' : '')}
                        value={formik.values?.signature5}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                    <input
                        id="signature6"
                        name="signature6"
                        type="password"
                        className={'form-control fit-content ' + (formik.errors['signature6'] ? ' is-invalid' : '')}
                        value={formik.values?.signature6}
                        onChange={(e) => {
                            this.changeNext(e, formik.setFieldValue)
                        }}
                        onKeyUp={(e) => {
                            this.handleKeyup(e, formik.setFieldValue)
                        }}
                        maxLength={1}
                    />
                </div>
                {
                    this.props.check && (
                        <>
                            <div style={{width: "100%", marginBottom: 50}}>
                                <div style={{float:"right"}}>
                                    <span style={{fontSize: 12, opacity:"0.5"}}>{this.state.signatureCheck === null ? '' : (this.state.signatureMessage  !== 'Waiting' ? this.state.signatureMessage : `${ t ? t('please_wait') : "Silakan menunggu"} ${this.state.minutes} : ${this.state.seconds} ${ t ? t('digital_signature_verif') : "untuk verifikasi digital signature"}` )}</span>
                                </div>
                            </div>
                        </>
                    )
                }
            </>
        )
    }
}
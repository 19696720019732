import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalContent from '../../../components/Utils/Modal/ModalBody';

import moment from "moment";
import localization from 'moment/locale/id';


export class DataKIPINonSeriousModal extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    handleInitialName(name) {

        let initialName = "";

        let arrayInitial = name ? name.split(" ") : [];

        arrayInitial.map((record, index) => {

            initialName += record.charAt(0).toUpperCase();
        })

        return initialName;
    }

    render() {

        const { formik, data } = this.props;

        console.log(data)

        const rowStyled = {
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px"
        }

        const col3Styled = {
            flex: "0 0 25%",
            maxWidth: "25%",
            paddingRight: 0
        }

        const col7Styled = {
            flex: "0 0 58.33333%",
            maxWidth: "58.33333%",
            paddingRight: 0
        }

        const col5Styled = {
            flex: "0 0 41.66667%",
            maxWidth: "41.66667%",
        }

        const col12Styled = {
            flex: "0 0 100%",
            maxWidth: "100%"
        }

        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalContent>
                        <div style={rowStyled}>
                            <div style={col12Styled}>
                                <div style={{ textAlign: "center" }}>
                                    <p style={{ marginTop: 5, marginBottom: 5, fontWeight: "bold" }}>Tabel Pelaporan</p>
                                    <p style={{ marginTop: 5, marginBottom: 5, fontWeight: "bold" }}>KIPI Non-Serius yang terjadi di Indonesia</p>
                                </div>
                            </div>
                        </div>
                        <div style={rowStyled}>
                            <div style={col12Styled}>
                                <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                                    <Table className="pb-5" hover>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Nomor Kasus</th>
                                                <th>Inisial Pasien</th>
                                                <th>Umur</th>
                                                <th>Jenis Kelamin</th>
                                                <th>Jenis Vaksin</th>
                                                <th>No. Bets</th>
                                                <th>Tanggal Imunisasi</th>
                                                <th>Tanggal mulai terjadinya KIPI</th>
                                                <th>Manifesatsi KIPI *(sama dengan keluhan)</th>
                                                <th>Kesudahan KIPI *(sama dengan outcome)</th>
                                                <th>Pelapor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((record, index) =>
                                                <>
                                                    <tr key={record.id}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{record.caseNumber ? record.caseNumber : "-"}</td>
                                                        <td>{record.patientName ? record.patientName : ""}</td>
                                                        <td>{(record.patientAgeYear ? record.patientAgeYear + " Tahun " : "") + (record.patientAgeMonth ? record.patientAgeMonth + " Bulan" : "")}</td>
                                                        <td>{record.patientGender ? (record.patientGender === "M" ? "Laki-laki" : record.patientGender === "F" ? "Perempuan" : "Tidak Ada Data") : "-"}</td>
                                                        <td>{record.aefiVaccine ?
                                                            (record.aefiVaccine?.filter((item, index) => item.vaccine != null && record.aefiVaccine.findIndex(x => x.vaccine === item.vaccine) === index)
                                                                .map(item => { return item.vaccineNavigation?.name; })
                                                                .join(", ")) :
                                                            (record.aefiNonSeriousDataVaccine ? record.aefiNonSeriousDataVaccine.filter((item, index) => item.vaccine != null && record.aefiNonSeriousDataVaccine.findIndex(x => x.vaccine === item.vaccine) === index)
                                                                .map(item => { return item.vaccineName; })
                                                                .join(", ") : "-")
                                                        }
                                                        </td>
                                                        <td>{record.aefiVaccine ?
                                                            (record.aefiVaccine?.filter((item, index) => item.batch !== null)
                                                                .map(item => { return item.batch; })
                                                                .join(", ")) :
                                                            (record.aefiNonSeriousDataVaccine ? record.aefiNonSeriousDataVaccine?.filter((item, index) => item.batch !== null)
                                                                .map(item => { return item.batch; })
                                                                .join(", ") : "-")
                                                        }
                                                        </td>
                                                        <td>{record.lastImmunizationDate ? moment(record.lastImmunizationDate ?? record.lastImmunizationDate).format('DD/MM/yyyy') : ""}</td>
                                                        <td>{record.onsetDate ? moment(record.onsetDate ?? record.onsetDate).format('DD/MM/yyyy') : ""}</td>
                                                        <td>{
                                                            record.aefiSymptomps || record.aefiAllergic ? (record.aefiSymptomps?.map(item => { return item.symptompsNavigation?.name }).join(", ") + ", " + (record.aefiAllergic?.length > 0 ? record.aefiAllergic?.map(item => { return item.allergicNavigation?.name }).join(", ") : "")) : (record?.keluhan ? record?.keluhan : "-")
                                                        }
                                                        </td>
                                                        <td>{record.outcomeNavigation ? record.outcomeNavigation.name : (record.outcome ? record.outcome : "-")}</td>
                                                        <td>{(record.reporterName ? record.reporterName : "") + ", " + (record.datasourceNavigation ? record.datasourceNavigation?.name : "-")}</td>
                                                    </tr>
                                                </>
                                            )}
                                            {
                                                data.length < 1 && (
                                                    <tr>
                                                        <td colSpan="12" style={{ textAlign: "center" }}>
                                                            Data tidak ditemukan
                                                    </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
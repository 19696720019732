import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';

import * as Utils from '../../../helpers/Utils';
import Button from '../../../components/Utils/Button/Button';
import Tab from '../../../components/Utils/Tab/Tab';

import ReportInformation from './TabKIPIOverseas/ReporterInformation';
import PatientInformation from './TabKIPIOverseas/PatientInformation';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { AEFIOverseasService } from '../../../services/BPOM/AEFIOverseasService';
import { DropdownService } from '../../../services/Master/AllDropdownService';

import { withTranslation } from "react-i18next";

class AddKIPIOverseas extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            currentTab: "report-information",
            backTab: "",
            nextTab: "patient-information",
            historyForm: [],
            caseEntryData: [],
        };

        this.formikRef = React.createRef();
        this.prepareForm()
    }

    prepareForm() {
        Promise.all([
            AEFIOverseasService.ReadAefi(1, 0, ""),
            DropdownService.MasterList(["vaccine", "drugs", "aefiCategory", "aeCategory", "bpomProgressCategory", "outcome", "causalityAEFIA", "causalityAEFIB", "causalityAEFIC", "causalityAEFID"]),
            DropdownService.CountryList()])
            .then(([kipi, dropdown, countryList]) => {

                this.setState({
                    caseNumberList: [...kipi.data.records],
                    caseCategoryList: [...dropdown.data.aefiCategoryList, ...dropdown.data.aeCategoryList],
                    list: dropdown.data,
                    countryList: countryList.data,
                });
            });
    }

    handleGetData(data) {
        this.setState({ caseEntryData: data });
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#report-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "report-information", backTab: "", nextTab: "patient-information"
                });
                break;
            case "#patient-information":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true,
                    currentTab: "patient-information", backTab: "report-information", nextTab: "",
                    externalDisabled: false
                });
                break;
        }

        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
        this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    if(this.state.nextTab != null){
                        document.getElementById("link-" + this.state.nextTab).click();
                    }
                }
            }
        )
        event.preventDefault();
    } 
    
    render() {

        const { t } = this.props;

        /** Handling required form inputs begin */

        const initialTouched = {
            caseNumber: true,
            vaccineName: true,
            patientCountry: true,
            letterPlace: true,
            letterDate: true,
            letterAddress: true,
        }

        const validationSchemaDict = {
            "report-information":
                Yup.object().shape({
                    caseNumber: Yup.string().required("Required").nullable(),
                    letterPlace: Yup.string().required("Required").nullable(),
                    letterDate: Yup.string().required("Required").nullable(),
                    letterAddress: Yup.string().required("Required").nullable(),
                })
        }

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber = {true}
                                title={t('report_information')}
                                href="#report-information"
                                onClick={(event) => this.handleTabChange(event, "#report-information")}
                                id="link-report-information"
                            />
                            <Tab
                                number="2"
                                showNumber = {true}
                                title={t('patient_information')}
                                href="#patient-information"
                                onClick={(event) => this.handleTabChange(event, "#patient-information")}
                                id="link-patient-information"
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                        caseNumber: ''
                    }}
                    initialTouched={initialTouched}
                    validationSchema={validationSchemaDict[this.state.currentTab]}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        values.typeCaseEntry = values.caseType === "KIPI" ? "AEFI" : "AE"

                        AEFIOverseasService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push({pathname: `/report-submission/bpom`, state: {tab : "KIPIOverseas"}});
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            }
                        )

                    }}
                >
                    {formik => 

                        (
                            <Form>
                                <div className="tab-content">

                                    {/* Report Information Tab */}

                                    <ReportInformation
                                        formik={formik}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData}
                                        disabledNext={this.state.inisiasiDisabled}
                                        updatedata={(data) => this.handleGetData(data)}
                                        updatedisabled={(tab,status) => this.handleDisabledNext(tab,status) }
                                        tabType="KIPIOverseas"
                                        caseNumberList={this.state.caseNumberList}
                                        caseCategoryList={this.state.caseCategoryList}
                                        list={this.state.list}
                                        countryList={this.state.countryList}
                                    />

                                    {/* Patient Information Tab */}

                                    <PatientInformation
                                        formik={formik}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        historydata={this.state.historyForm}
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData}
                                        disabledNext={this.state.inisiasiDisabled}
                                        list={this.state.list}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                    />
    
                                    {
                                        this.state.nextTab === "" &&
    
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text= {t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    type="submit"
                                                    text={t('save')}
                                                    disabled={formik.isSubmitting} />
                                            </ToggleDisplay>
                                            {
                                                formik.status &&
                                                <div className={'alert alert-danger'}>{formik.status}</div>
                                            }
                                        </div>
                                    }
                                </div>                            
                            </Form>
                        )
                    } 
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddKIPIOverseas)
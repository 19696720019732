import React from 'react';

import Button from '../../components/Utils/Button/Button';
import { SignatureModal } from './SignatureModal';
import { authenticationService } from '../../services/AuthService';

import { withTranslation } from "react-i18next";

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            count: 0,
            limit: 0,
            query: "",
            authModalShow: false,
        }
    }

    componentDidMount() {

        this.setState({new : (this.props.location?.state?.new) ? true : false})
    }

    render() {

        const {t} = this.props;

        const tableRow = {
            verticalAlign : "top"
        }
        const param = {
            color : "#8E8E8E",
            fontSize:  14,
            width: "35%"
        }

        const value = {
            color : "#212121",
            width : "70%",
            fontSize :  14,
            textAlign : "right" 
        }

        const currentUser = authenticationService?.currentUserValue;
        const imageUrl = currentUser?.photo ? `data:image/png;base64,${currentUser?.photo}`: '../assets/images/users/user.svg';

        return (
            <>
                <SignatureModal
                    show={this.state.authModalShow}
                    onhide={() => this.setState({authModalShow : false})} 
                    type="auth"
                    message={t('insert_password')}
                />
                <div className="card" style={{marginBottom: 10}}>
                    <div className="card-body" style={{padding: 0}}>
                        <div className="row">
                            <div className="col-xs-12 col-md-4" style={{background:"#F8F9FB99"}}>
                                <div style={{textAlign:"center", padding: 30}}>
                                    <img src={imageUrl} alt="user" className="rounded-circle" width="147" style={{objectFit: 'cover', width: '147px', height: '147px'}} />
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-8">
                                <div style={{padding: 30}}>
                                    <p style={{fontSize: 20, color: "#212121", fontWeight: 900}}>{t('personal_data')}</p>

                                    <p style={{fontSize: 12, color: "#5B5B5D", fontWeight: 900}}>{t('general_information')}</p>
                                    <table style={{width:"100%", borderCollapse:"separate", borderSpacing: "0 1.5em"}}>
                                        <tr style={tableRow}>
                                            <td style={param}>{t('name')}</td>
                                            <td style={value}>{currentUser?.name ?? "-"}</td>
                                        </tr>
                                        <tr style={tableRow}>
                                            <td style={param}>{t('position')}</td>
                                            <td style={value}>{currentUser?.positionName ?? "-"}</td>
                                        </tr>
                                        <tr style={tableRow}>
                                            <td style={param}>{t('roleName')}</td>
                                            <td style={value}>{currentUser?.roleName ?? "-"}</td>
                                        </tr>
                                        <tr style={tableRow}>
                                            <td style={param}>Email</td>
                                            <td style={value}>{currentUser?.email ?? "-"}</td>
                                        </tr>
                                        {
                                            (currentUser?.role === 'DIVISI' || currentUser?.role === 'UNIT' || currentUser?.role === 'MANAGER') && 
                                            <tr style={tableRow}>
                                                <td style={param}>Digital Signature</td>
                                                <td style={value}>
                                                    <Button
                                                        color="#00ABBD"
                                                        background="#FFFFFF"
                                                        border="1px solid #00ABBD"
                                                        text={this.state.new ? "Buat" : "Ubah"}
                                                        onClick={() => this.setState({authModalShow : true})}
                                                    />
                                                </td>
                                            </tr>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Profile);
import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import moment from "moment";

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import TimePicker from '../../../components/Utils/DateRange/TimePicker';
import Button from '../../../components/Utils/Button/Button';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import * as Utils from '../../../helpers/Utils';
import { Role,Status } from '../../../helpers/Constants';
import { withTranslation } from "react-i18next";

class Symptomps extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    render() {
        const { formik, typecase, t, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion ||   this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return (
            <>
                <div className="tab-pane" id="symptomps">
                    <div className='wrapper-dynamic-form'>
                        <div>
                            {this.createSymptompsUI(formik)}
                            {this.initialSymptompsUI(formik)}
                            <ButtonWithIcon
                                text={t('add_symptoms')}
                                icon="fa fa-plus"
                                onClick={this.addSymptompsUI.bind(this, formik)} 
                                width="200px"
                                disabled={isDisabledForm}
                            />
                            <hr />
                        </div>

                        <div>
                            <label className="form-label" style={{ fontSize: 16 }}>{t('allergic_reaction_caps')}</label>

                            {this.createAllergicsUI(formik)}
                            {this.initialAllergicsUI(formik)}
                            <ButtonWithIcon
                                text={t('add_allergic')}
                                icon="fa fa-plus"
                                onClick={this.addAllergicsUI.bind(this, formik)} 
                                width="200px"
                                disabled={isDisabledForm}
                            />
                        </div>
                    </div>
                    
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

    // Symptomps Dynamic Table

    initialSymptompsUI(formik) {

        const symptomps = this.props.symptomps;

        if (symptomps && symptomps.length) {

            for (var i = 0; i < symptomps.length; i++) {

                if (symptomps[i] && !symptomps[i].isNew && !symptomps[i].previewed) {
                    formik.setFieldValue(`symptomps[${i}][id]`, symptomps[i].id)
                    formik.setFieldValue(`symptomps[${i}][symptomps]`, symptomps[i].symptomps)
                    formik.setFieldValue(`symptomps[${i}][systemOrganClass]`, symptomps[i].systemOrganClass)
                    formik.setFieldValue(`symptomps[${i}][preferredTerm]`, symptomps[i].preferredTerm)
                    formik.setFieldValue(`symptomps[${i}][date]`, Utils.getDate(symptomps[i].date))
                    formik.setFieldValue(`symptomps[${i}][time]`, Utils.getTime(symptomps[i].date))
                    formik.setFieldValue(`symptomps[${i}][durationMinute]`, symptomps[i].durationMinute)
                    formik.setFieldValue(`symptomps[${i}][durationHour]`, symptomps[i].durationHour)
                    formik.setFieldValue(`symptomps[${i}][durationDay]`, symptomps[i].durationDay)
                }

                this.props.symptomps[i].previewed = true;
            }
        }
    }

    createSymptompsUI(formik) {
        const role = this.props.currentUser ? this.props.currentUser.role : "";

        const {t} = this.props;

        const symptompsList = this.props.symptompsList;
        const socList = this.props.socList;
        const ptList = this.props.ptList;
        const historydata = this.props.historydata;
        const historySymptomps = historydata?.trailAefiSymptomps?.length > 0 ? historydata?.trailAefiSymptomps : [];

        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion ||   this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return this.props.symptomps.map((element, i) =>

            <div key={i} className={historydata?.trailAefiSymptomps?.length > 0 ? (historySymptomps[i] ? "" : "change-value-radio-fit") : "" }>
                <Input
                    name={`symptomps[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`symptomps[${i}][id]`)} 
                    disabled={isDisabledForm}
                    same={historydata?.trailAefiSymptomps?.length > 0 ? historySymptomps[i]?.id === formik.values.symptomps[i]?.id : true }
                />
                <div className='row flex-nowrap dynamic-form'>
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('symptoms')}</label>}
                            <Select2
                                name={`symptomps[${i}][symptomps]`}
                                options={symptompsList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][symptomps]`)}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? (historySymptomps[i]?.symptomps === formik.values.symptomps[i]?.symptomps) : true }
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">System Organ Class</label>}
                            <Select2
                                name={`symptomps[${i}][systemOrganClass]`}
                                options={socList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][systemOrganClass]`)}
                                onChange={(name, value) => {
                                    formik.setFieldValue(name, value);
                                    formik.setFieldValue(`symptomps[${i}][preferredTerm]`, "");
                                }}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? (historySymptomps[i]?.systemOrganClass === formik.values.symptomps[i]?.systemOrganClass) : true}
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">Preferred Term</label>}
                            <Select2
                                name={`symptomps[${i}][preferredTerm]`}
                                options={ptList?.filter(x => i < formik.values.symptomps?.length && x.systemOrganClass == formik.values.symptomps[i]?.systemOrganClass).map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][preferredTerm]`)}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? (historySymptomps[i]?.preferredTerm === formik.values.symptomps[i]?.preferredTerm) : true}
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2 position-relative" style={{ minWidth: '150px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('date')}</label>}
                            <DatePicker
                                name={`symptomps[${i}][date]`}
                                selectionType="single"
                                maxdate={new Date()}
                                errors={formik.errors}
                                touched={formik.touched}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                value={element.date}
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? (historySymptomps[i] ? moment(historySymptomps[i]?.date).format("YYYY-MM-DD") === formik.values.symptomps[i]?.date : true) : true }
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2 position-relative" style={{ minWidth: '150px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('time')}</label>}
                            <TimePicker
                                name={`symptomps[${i}][time]`}
                                customStyle={true}
                                errors={formik.errors}
                                touched={formik.touched}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                value={formik.values.symptomps ? formik.values.symptomps[i]?.time : element.date ? moment(element.date).format("HH:mm") : null}
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? (formik.values.symptomps[i]?.time ? moment(historySymptomps[i]?.date).format("HH:mm:ss") === formik.values.symptomps[i]?.time : moment(historySymptomps[i]?.date).format("HH:mm") === moment(element.date).format("HH:mm")) : true }
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('duration')}</label>}
                            <Input
                                name={`symptomps[${i}][durationMinute]`}
                                type="number"
                                min={0}
                                max={59}
                                placeholder="Menit"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][durationMinute]`)} 
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? historySymptomps[i]?.durationMinute === formik.values.symptomps[i]?.durationMinute : true }
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">&nbsp;</label>}
                            <Input
                                name={`symptomps[${i}][durationHour]`}
                                type="number"
                                min={0}
                                max={23}
                                placeholder={t('time')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][durationHour]`)} 
                                disabled={isDisabledForm}
                                same={historydata?.trailAefiSymptomps?.length > 0 ? historySymptomps[i]?.durationHour === formik.values.symptomps[i]?.durationHour : true }
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">&nbsp;</label>}
                            <Input
                                name={`symptomps[${i}][durationDay]`}
                                type="number"
                                min={0}
                                placeholder="Hari"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`symptomps[${i}][durationDay]`)}
                                disabled={isDisabledForm} 
                                same={historydata?.trailAefiSymptomps?.length > 0 ? historySymptomps[i]?.durationDay === formik.values.symptomps[i]?.durationDay : true }
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '30px' }}>
                        <div className="align-items-center button-close">
                            {
                                i !== 0 &&
                                    <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeSymptompsUI.bind(this, formik, i)} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addSymptompsUI(formik) {
        this.props.symptomps.push({ isNew: true });
        formik.setFieldValue(`symptomps[${this.props.symptomps.length - 1}][id]`, null)
    }

    removeSymptompsUI(formik, index) {

        for (var i = index; i < this.props.symptomps.length; i++) {

            const symptomps = formik.values.symptomps[i + 1];

            this.props.symptomps[i] = this.props.symptomps[i + 1];

            if (symptomps) {
                formik.setFieldValue(`symptomps[${i}][id]`, symptomps.id)
                formik.setFieldValue(`symptomps[${i}][symptomps]`, symptomps.symptomps)
                formik.setFieldValue(`symptomps[${i}][systemOrganClass]`, symptomps.systemOrganClass)
                formik.setFieldValue(`symptomps[${i}][preferredTerm]`, symptomps.preferredTerm)
                formik.setFieldValue(`symptomps[${i}][date]`, Utils.getDate(symptomps.date))
                formik.setFieldValue(`symptomps[${i}][time]`, symptomps.time)
                formik.setFieldValue(`symptomps[${i}][durationMinute]`, symptomps.durationMinute)
                formik.setFieldValue(`symptomps[${i}][durationHour]`, symptomps.durationHour)
                formik.setFieldValue(`symptomps[${i}][durationDay]`, symptomps.durationDay)
            }
            else {
                formik.setFieldValue(`symptomps[${i}][id]`, null)
                formik.setFieldValue(`symptomps[${i}][symptomps]`, null)
                formik.setFieldValue(`symptomps[${i}][systemOrganClass]`, null)
                formik.setFieldValue(`symptomps[${i}][preferredTerm]`, null)
                formik.setFieldValue(`symptomps[${i}][date]`, null)
                formik.setFieldValue(`symptomps[${i}][time]`, null)
                formik.setFieldValue(`symptomps[${i}][durationMinute]`, null)
                formik.setFieldValue(`symptomps[${i}][durationHour]`, null)
                formik.setFieldValue(`symptomps[${i}][durationDay]`, null)
            }
        }

        formik.values.symptomps.pop();
        this.props.symptomps.pop();
    }

    // Allergics Dynamic Table

    initialAllergicsUI(formik) {

        const allergics = this.props.allergics;

        const {t} = this.props;

        if (allergics && allergics.length) {

            for (var i = 0; i < allergics.length; i++) {

                if (allergics[i] && !allergics[i].isNew && !allergics[i].previewed) {
                    formik.setFieldValue(`allergics[${i}][id]`, allergics[i].id)
                    formik.setFieldValue(`allergics[${i}][allergic]`, allergics[i].allergic)
                    formik.setFieldValue(`allergics[${i}][systemOrganClass]`, allergics[i].systemOrganClass)
                    formik.setFieldValue(`allergics[${i}][preferredTerm]`, allergics[i].preferredTerm)
                    formik.setFieldValue(`allergics[${i}][date]`, Utils.getDate(allergics[i].date))
                    formik.setFieldValue(`allergics[${i}][time]`, Utils.getTime(allergics[i].date))
                    formik.setFieldValue(`allergics[${i}][durationMinute]`, allergics[i].durationMinute)
                    formik.setFieldValue(`allergics[${i}][durationHour]`, allergics[i].durationHour)
                    formik.setFieldValue(`allergics[${i}][durationDay]`, allergics[i].durationDay)
                }

                this.props.allergics[i].previewed = true;
            }
        }
    }

    createAllergicsUI(formik) {

        const role = this.props.currentUser ? this.props.currentUser.role : "";

        const {t} = this.props;

        const allergicList = this.props.allergicList;
        const socList = this.props.socList;
        const ptList = this.props.ptList;
        const historydata = this.props.historydata;
        const historyAllergic = historydata?.trailAefiAllergic ? historydata?.trailAefiAllergic : []
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion || this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        return this.props.allergics.map((element, i) =>

            <div key={i} className={historydata?.trailAefiAllergic ? (historyAllergic[i] ? "" : "change-value-radio-fit") : "" }>

                <Input
                    name={`allergics[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`allergics[${i}][id]`)} 
                    disabled={isDisabledForm}
                    same={historyAllergic[i] ? historyAllergic[i]?.id === formik.values.allergics[i]?.id : true }
                />

                <div className="row flex-nowrap dynamic-form">
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('allergic_reaction')}</label>}
                            <Select2
                                name={`allergics[${i}][allergic]`}
                                options={allergicList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][allergic]`)}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historyAllergic[i] ? historyAllergic[i]?.allergic === formik.values.allergics[i]?.allergic : true }   
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">System Organ Class</label>}
                            <Select2
                                name={`allergics[${i}][systemOrganClass]`}
                                options={socList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][systemOrganClass]`)}
                                onChange={(name, value) => {
                                    formik.setFieldValue(name, value);
                                    formik.setFieldValue(`allergics[${i}][preferredTerm]`, "");
                                }}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historyAllergic[i] ? historyAllergic[i]?.allergic === formik.values.allergics[i]?.systemOrganClass : true }   
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '200px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">Preferred Term</label>}
                            <Select2
                                name={`allergics[${i}][preferredTerm]`}
                                options={ptList?.filter(x => i < formik.values.allergics?.length && x.systemOrganClass == formik.values.allergics[i]?.systemOrganClass).map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][preferredTerm]`)}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled={isDisabledForm}
                                same={historyAllergic[i] ? historyAllergic[i]?.allergic === formik.values.allergics[i]?.preferredTerm : true }   
                                menuPosition="fixed"
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '150px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('date')}</label>}
                            <div className="position-relative">
                                <DatePicker
                                    placementTop={true}
                                    name={`allergics[${i}][date]`}
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={element.date}
                                    disabled={isDisabledForm}
                                    same={historyAllergic[i] ? moment(historyAllergic[i]?.date).format("YYYY-MM-DD") === formik.values.allergics[i]?.date : true }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '150px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('time')}</label>}
                            <div className="position-relative">
                                <TimePicker
                                    placementTop={true}
                                    customStyle={true}
                                    name={`allergics[${i}][time]`}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.allergics ? formik.values.allergics[i]?.time : element.date ? moment(element.date).format("HH:mm") : null}
                                    disabled={isDisabledForm}
                                    same={historyAllergic[i] ? (formik.values.allergics[i]?.time ? moment(historyAllergic[i]?.date).format("HH:mm:ss") === formik.values.allergics[i]?.time : moment(historyAllergic[i]?.date).format("HH:mm") === moment(element.date).format("HH:mm")) : true }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">{t('duration')}</label>}
                            <Input
                                name={`allergics[${i}][durationMinute]`}
                                type="number"
                                min={0}
                                max={59}
                                placeholder="Menit"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][durationMinute]`)} 
                                disabled={isDisabledForm}
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">&nbsp;</label>}
                            <Input
                                name={`allergics[${i}][durationHour]`}
                                type="number"
                                min={0}
                                max={23}
                                placeholder={t('time')}
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][durationHour]`)} 
                                disabled={isDisabledForm}
                                same={historyAllergic[i] ? historyAllergic[i]?.durationHour === formik.values.allergics[i]?.durationHour : true }   
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '100px' }}>
                        <div className="form-group">
                            {i === 0 && <label className="form-label">&nbsp;</label>}
                            <Input
                                name={`allergics[${i}][durationDay]`}
                                type="number"
                                min={0}
                                placeholder="Hari"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps(`allergics[${i}][durationDay]`)} 
                                disabled={isDisabledForm}
                                same={historyAllergic[i] ? historyAllergic[i]?.durationDay === formik.values.allergics[i]?.durationDay : true }  
                            />
                        </div>
                    </div>
                    <div className="w-100 mx-2" style={{ minWidth: '30px' }}>
                        <div className="align-items-center button-close">
                            {
                                i !== 0 &&

                                <i className="fa fa-times-circle fa-2x text-danger mb-4" aria-hidden="true" onClick={this.removeAllergicsUI.bind(this, formik, i)} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addAllergicsUI(formik) {
        this.props.allergics.push({ isNew: true })
        formik.setFieldValue(`allergics[${this.props.allergics.length - 1}][id]`, null)
    }

    removeAllergicsUI(formik, index) {

        for (var i = index; i < this.props.allergics.length; i++) {

            const allergics = formik.values.allergics[i + 1];

            this.props.allergics[i] = this.props.allergics[i + 1];

            if (allergics) {
                formik.setFieldValue(`allergics[${i}][id]`, allergics.id)
                formik.setFieldValue(`allergics[${i}][allergic]`, allergics.allergic)
                formik.setFieldValue(`allergics[${i}][systemOrganClass]`, allergics.systemOrganClass)
                formik.setFieldValue(`allergics[${i}][preferredTerm]`, allergics.preferredTerm)
                formik.setFieldValue(`allergics[${i}][date]`, Utils.getDate(allergics.date))
                formik.setFieldValue(`allergics[${i}][time]`, allergics.time)
                formik.setFieldValue(`allergics[${i}][durationMinute]`, allergics.durationMinute)
                formik.setFieldValue(`allergics[${i}][durationHour]`, allergics.durationHour)
                formik.setFieldValue(`allergics[${i}][durationDay]`, allergics.durationDay)
            }
            else {
                formik.setFieldValue(`allergics[${i}][id]`, null)
                formik.setFieldValue(`allergics[${i}][allergic]`, null)
                formik.setFieldValue(`allergics[${i}][systemOrganClass]`, null)
                formik.setFieldValue(`allergics[${i}][preferredTerm]`, null)
                formik.setFieldValue(`allergics[${i}][date]`, null)
                formik.setFieldValue(`allergics[${i}][time]`, null)
                formik.setFieldValue(`allergics[${i}][durationMinute]`, null)
                formik.setFieldValue(`allergics[${i}][durationHour]`, null)
                formik.setFieldValue(`allergics[${i}][durationDay]`, null)
            }
        }

        formik.values.allergics.pop();
        this.props.allergics.pop();
    }
}

export default withTranslation()(Symptomps);
import { handleResponse, authHeader } from '../helpers/RequestHelper';

export const HistoryService = {
    Read,
    ReadQualityReview,
    ReadReportSubmission
};

async function Read(id = "", type = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/history/read?CaseEntry=${id}&Type=${type}`, requestOptions);
    data = await handleResponse(data)
    return data;
}



async function ReadQualityReview(id = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/history/medical-review/read?Id=${id}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadReportSubmission(id = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/history/report-submission/read?Id=${id}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

import React, { Component } from "react";

import Modal from "../../components/Utils/Modal/Modal";
import ModalHeader from "../../components/Utils/Modal/ModalHeader";
import ModalContent from "../../components/Utils/Modal/ModalBody";
import Button from "../../components/Utils/Button/Button";

export class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { snackbaropen: false, snackbarmsg: "" };
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  render() {
    const { t } = this.props;

    return (
      <div className="container">
        <Modal {...this.props} size="md">
          <ModalHeader>
            <div className="modal-title">
              {t ? t("confirmation") : "Konfirmasi"}
            </div>
            <hr />
          </ModalHeader>
          <ModalContent>
            <div
              style={{
                marginTop: 15,
                marginBottom: 25,
                fontSize: 14,
                color: "#485867",
              }}
            >
              {
                "Kirimkan Notifikasi Permintaan Input Data Distribusi Tahunan pada Aplikasi Farmakovigilans?"
              }
            </div>
            {/* <div className='text-right'>
                            <Button 
                                type="submit" 
                                text="OK"
                                onClick={this.props.confirm}
                            />
                        </div> */}
            <div className="text-right">
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t ? t("cancel") : "Batal"}
                onClick={this.props.onhide}
              />

              <Button
                background="#00ABBD"
                color="white"
                type="submit"
                text={t ? t("yes") : "Ya"}
                onClick={this.props.confirm}
              />
            </div>
          </ModalContent>
        </Modal>
      </div>
    );
  }
}

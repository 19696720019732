import React, { Component } from 'react';

import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';
import Input from '../../../../components/Utils/Field/Input';

import { Role } from '../../../../helpers/Constants';
import { authenticationService } from '../../../../services/AuthService';

import LetterUploadFile from '../../LetterUploadFile';

import { withTranslation } from "react-i18next";

class LetterInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            selectedData : [],
            isComplete : "",
        };
    }

    componentDidUpdate(prevProps) {

    

    }

    componentDidMount() {

    }
    

    handleCancelClick(event) {

        this.props.history.push('/report-submission/bpom');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;
        const currentUser = authenticationService.currentUserValue;
        const disabled = currentUser.role === Role.Staff && formik.values.status === 'Approved' ? false : true;

        return (
            <>
                <div className="tab-pane show tab-pane-fixed" id="letter-information">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                                <label className="form-label">{t('letter_number')}</label>
                                <div style={{display:"flex"}}>
                                    <Input
                                        disabled
                                        name="letterNumber1"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.letterNumber1}
                                        {...formik.getFieldProps('letterNumber1')}
                                    />
                                    -
                                    <Input
                                        disabled={disabled}
                                        name="letterNumber2"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('letterNumber2')}
                                        same={historydata?.mailingOrder ? historydata.mailingOrder === formik.values?.mailingOrder : true }
                                    />
                                    /
                                    <Input
                                        disabled
                                        name="letterNumber3"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.letterNumber3}
                                        {...formik.getFieldProps('letterNumber3')}
                                    />
                                    /
                                    <Input
                                        disabled={disabled}
                                        name="letterNumber4"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('letterNumber4')}
                                        same={historydata?.mailingMonth ? historydata.mailingMonth === formik.values?.mailingMonth : true }
                                    />
                                    /
                                    <Input
                                        disabled={disabled}
                                        name="letterNumber5"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('letterNumber5')}
                                        same={historydata?.mailingYear ? historydata.mailingYear === formik.values?.mailingYear : true }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('send_date')}</label>
                                <DatePicker
                                    disabled={formik.values.status != 'Approved'}
                                    name="sendDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.sendDate}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Komponen untuk upload file di tab surat */}
                    <LetterUploadFile 
                        formik = {formik}
                        folderName = "bpom_progress_attachment"
                        disabled={disabled}
                    />
                </div>
            </>
        )
    }

}

export default withTranslation()(LetterInformation);

import React, { Component } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';
import Input from '../../components/Utils/Field/Input';
import { SignatureForm } from '../../components/Utils/Signature/SignatureForm';
import { authenticationService } from '../../services/AuthService';
import { DigitalSignatureService } from '../../services/Profile/DigitalSignatureService'

import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';

export class SignatureModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            snackbaropen: false, 
            snackbarmsg: '',
            step: 0,
            authPassword : false, 
            initialValues : {
                signature1 : "",
                signature2 : "",
                signature3 : "",
                signature4 : "",
                signature5 : "",
                signature6 : "",
                password : ""
            }
            
        };

        this.refreshInitialState()
    }

    refreshInitialState() {

        const initialValues = 
            {
                signature1 : "",
                signature2 : "",
                signature3 : "",
                signature4 : "",
                signature5 : "",
                signature6 : "",
            }

        this.setState({initialValues : initialValues, step: 2})
    }

    handleCancel = () => {
        this.setState({
            step : 0,
            authPassword : false,
            initialValues : {
                signature1 : "",
                signature2 : "",
                signature3 : "",
                signature4 : "",
                signature5 : "",
                signature6 : "",
                password : ""
            }
        }, this.props.onhide)
    }

    render() {

        const currentUser = authenticationService.currentUserValue;
        const initialValues = this.state.initialValues;
        
        return (

            <div className="container">
                <Modal {...this.props}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={
                            this.state.step !== 0 ? 
                            Yup.object().shape({
                                signature1: Yup.string().required('Required'),
                                signature2: Yup.string().required('Required'),
                                signature3: Yup.string().required('Required'),
                                signature4: Yup.string().required('Required'),
                                signature5: Yup.string().required('Required'),
                                signature6: Yup.string().required('Required'),
                            }) : 
                            Yup.object().shape({
                                password: Yup.string().required('Required'),
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {

                            if(this.state.step === 0) {
                                
                                DigitalSignatureService.CheckPassword(values).then(
                                    (result) => {
                                        toastSuccess('Verifikasi berhasil');
                                        this.setState({authPassword : true, step : 1})
                                    },
                                    (error) => {
                                        
                                        toastError(error);
                                    })

                                setSubmitting(false);
                            }

                            if(this.state.step === 1 && this.state.authPassword) {

                                let signature = values.signature1 + values.signature2 + values.signature3 + values.signature4 + values.signature5 + values.signature6;

                                
                                this.refreshInitialState()

                                this.setState({pin : signature}, values = null)
                                
                                setSubmitting(false);
                            }

                            if(this.state.step === 2 && this.state.pin && values) {
                                values.signature = values.signature1 + values.signature2 + values.signature3 + values.signature4 + values.signature5 + values.signature6;
                                if (this.state.pin === values.signature ){
                                    DigitalSignatureService.SetSignature(values).then(
                                        (result) => {
                                            toastSuccess('Berhasil memperbarui digital signature');
                                            this.setState({authPassword : false, step : 0, pin : null})
                                            this.props.onhide()
                                        },
                                        (error) => {
                                            
                                            toastError(error);
                                        })
                                }else{
                                    toastError("PIN dan PIN Konfirmasi tidak sesuai");
                                }
                                
                                setSubmitting(false);
                            }
                        }}
                    >
                        {props => (
                            <Form>
                                <ModalHeader>
                                    <div className="modal-title">Digital Signature</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="form-group">
                                        <div style={{textAlign:"center"}}>
                                            <label className="form-label" style={{textAlign:"center"}}>Halo, { currentUser?.name }</label>
                                            <label className="form-label" style={{textAlign:"center", fontWeight:500}}> 
                                                {this.state.step === 0 ? "Silahkan masukkan password untuk verifikasi akun Anda, sebelum melakukan perubahan digital signature" : (this.state.step === 1 ? "Silakan masukan 6 digit PIN baru Anda" : "Konfirmasi 6 digit PIN baru Anda") }
                                            </label>
                                            <div style={{width: 50, height: 4, borderRadius: "6px", background: "#00ABBD", margin:"5px auto 20px"}} />
                                        </div>
                                        {
                                            this.state.step === 0 && (
                                                <>
                                                    <div className="form-group">
                                                        <Input
                                                            name="password"
                                                            type="password"
                                                            placeholder="Password"
                                                            errors={props.errors}
                                                            touched={props.touched}
                                                            {...props.getFieldProps('password')} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            (this.state.step === 1 || this.state.step === 2 ) && (
                                                <SignatureForm
                                                    formik = {props}
                                                />
                                            )
                                        }
                                    </div>
                                    <div className="form-group">
                                        <div className='d-flex' style={{marginTop: 50}}>
                                            <Button
                                                color="#49505780"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #49505780"
                                                text="Batal"
                                                onClick={this.handleCancel} 
                                                width="50%"
                                            />
                                            <Button type="submit" text = {this.state.step === 0 ? "Verifikasi" : "Simpan"} width="50%" disabled={props.isSubmitting} />
                                            {
                                                props.status &&
                                                <div className={'alert alert-danger'}>{props.status}</div>
                                            }
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}




import React, { Component } from 'react';
import { Formik, Form, connect } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ToastContainer } from "react-toastify";

import { authenticationService } from '../../services/AuthService';
import Input from '../../components/Utils/Field/Input';
import { Row, Col } from 'react-bootstrap';
import ValidationError from '../../components/Utils/Form/ValidationError';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';


const Container = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
`

const H2 = styled.h2`
    text-align: left;
    font: normal normal 600 37px/50px Montserrat;
    letter-spacing: 0px;
    color: #777777;
    opacity: 1;
    padding-bottom: 10px;
    font-size: 25px;
    line-height: 1.5em;
`

const H3 = styled.h3`
    text-align: left;
    font: normal normal 600 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #777777;
    opacity: 1;
    padding-bottom: 20px;
    font-size: 16px;
`

const Artboard = styled.div`
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    max-height: 100vh;
    height: 100%;
    min-height: 40vh;
    padding-top: 20px;
`

const Logo = styled.div`
    right: 2%;
    top: 1%;
    position: absolute!important;
`

const LoginForm = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 50px 10px;
    height: 100%;
`

const LoginButton = styled.button`
    background: #00ABBD 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    width: 100%;
    text-align: center;
    font: normal normal bold 16px/35px Montserrat;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: 0;
    padding: 10px;
`

const ForgotPassLink = styled.p`
    text-align: left;
    font: normal normal 600 14px/22px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    display: block;
    text-align: right;
    padding-bottom: 10px;
`

export class Login extends Component {
    constructor(props) {
        super(props);

        // redirect to home if already logged in
        if (authenticationService.currentUserValue) {
            this.props.history.push(`/dashboard`);
            
        }
    }

    componentDidMount() {
        document.title = "Login"
    }

    emptyValue = () => {
        toastError("Masukkan username dan password yang telah terdaftar");
    }

    render() {

        return (
            <div>
                <ToastContainer
                    autoClose={3000}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Container>
                    <Logo className="p-2 position-absolute">
                        <img className="img-fluid" style={{width: "15vw", maxWidth:"150px"}} src="/assets/images/login/logo.png" alt="logo" />
                    </Logo>
                    <Row style={{height: "100%"}}>
                        <Col lg={6} md={6} sm={12} xs={12} style={{boxShadow: "0 9px 0px 0px white, 0 -9px 0px 0px white, 12px 0 10px -4px rgba(0, 0, 0, 0.1), -12px 0 10px -4px rgba(0, 0, 0, 0.1)"}}>
                            <Artboard>
                                <img className="img-fluid m-auto" style={{width: "80%"}} src="/assets/images/login/login-artboard.png" alt="artboard" />
                            </Artboard>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <LoginForm>
                                <div style={{width: "70%", marginLeft: "15%", marginRight: "15%" }} >
                                    <H2>Welcome back to Pharmacovigilance</H2>
                                    <H3>Please login to your account.</H3>
                                    <Formik
                                        initialValues={{
                                            username: '',
                                            password: ''
                                        }}
                                        validationSchema={
                                            Yup.object().shape({
                                                username: Yup.string().required('Required'),
                                                password: Yup.string().required('Required')
                                            })
                                        }
                                        
                                        onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
                                            setStatus();
                                            authenticationService.login(username, password)
                                            .then(
                                                result => {
                                                    if((result?.data?.role === 'DIVISI' || result?.data?.role === 'UNIT') && !result?.data?.signatureIsSet ){
                                                        this.props.history.push({pathname: `/dashboard`, state: {signature : true }})
                                                    }else{
                                                        this.props.history.push({pathname: `/dashboard`, state: {signature : false }})
                                                    }
                                                    
                                                },
                                                error => {
                                                    setSubmitting(false);
                                                    toastError("Username dan password tidak sesuai");
                                                }
                                            );
                                        }
                                    }
                                    >
                                        
                                        {props => (
                                            <Form>
                                                <ValidationError formik={props} handleError={() => this.emptyValue()}/>
                                                <div className="form-group">
                                                    <Input
                                                        name="username"
                                                        type="text"
                                                        placeholder="Username"
                                                        errors={props.errors}
                                                        touched={props.touched}
                                                        {...props.getFieldProps('username')} />
                                                </div>
                                                <div className="form-group">
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Password"
                                                        errors={props.errors}
                                                        touched={props.touched}
                                                        {...props.getFieldProps('password')} />
                                                </div>
                                                <div className="form-group">
                                                    <LoginButton type="submit" disabled={props.isSubmitting}>Login</LoginButton>
                                                    {
                                                        props.isSubmitting &&
                                                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                                    }
                                                </div>
                                                {
                                                    props.status &&
                                                    <div className={'alert alert-danger'}>{props.status}</div>
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </LoginForm>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './CustomTooltip.css';

class CustomTooltip extends Component {
    renderTooltip = (props) => {
        return (
            <Tooltip id="custom-tooltip" {...props} className="custom-tooltip">
                <div className="container">
                    <div className="row d-flex justify-content-center" style={{ gap: '5px' }}>
                        <div>
                            {this.props?.src && (
                                <img src={this.props.src} style={{ width: "22px", marginRight: 5 }} />
                            )}
                            {this.props?.icon && (
                                <i className={this.props?.icon} style={{ fontSize: "16px", color: "red" , marginRight: 5 }}></i>
                            )}
                            <span>{this.props.title}</span>
                        </div>
                        <span>{this.props.message}</span>
                    </div>
                </div>
            </Tooltip>
        );
    };

    render() {
        return (
            <OverlayTrigger placement="bottom" overlay={this.renderTooltip}>
                {this.props.children}
            </OverlayTrigger>
        );
    }
}

export default CustomTooltip;
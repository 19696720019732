import React, { Component } from 'react';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';


export class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const {t} = this.props;

        return (
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">{this.props.title ? this.props.title : t('confirmation')}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        {
                            this.props.caseNumber ? (
                                <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>{t ? t('success_add_case_entry') : "Berhasil menambahkan case entry dengan nomor"} <span style={{fontWeight:"bold"}}>{this.props.caseNumber}</span></div>
                            ) : (
                                <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>{this.props.text}</div>
                            )
                        }
                        <div className='text-right'>
                            {this.props.showhide &&
                                <Button
                                    color="#495057"
                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                    border="1px solid #495057"
                                    text={t ? t('cancel') : "Batal"}
                                    onClick={this.props.hide} 
                                />
                            }
                            <Button 
                                background="#00ABBD"
                                color="white"
                                type="submit" 
                                text={this.props.caseNumber ? "OK" : t ? t('yes') : "Ya"}
                                onClick={this.props.confirm}
                            />
                        </div>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
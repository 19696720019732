import React, { Component } from 'react';

import Textarea from '../../../../components/Utils/Field/Textarea';
import Select2 from '../../../../components/Utils/Field/Select2';

class Causality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            vaccine : "",
            causalityRelation : []
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    componentDidMount(){
        this.setState({causalityRelation : this.props.list?.causalityAEFIIncomplete})
    }

    handleCancelClick(event) {

        this.props.history.push('/medical-review/analysis');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, history, list, causality, caseEntry, disabled, t, i18next, ...otherProps } = this.props;
        const { causalityRelation } = this.state;

        return (
            <>
                <div className="tab-pane" id="causality">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('add_causality')}</label>
                                <Select2
                                    name="causalityRelation"
                                    options={causalityRelation?.map(item => { return { value: item.id, label: i18next.language === 'id' || i18next.language === 'ID' ? item.name : item.nameEnTranslate }; })}
                                    value={formik.values.causalityRelation}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled={disabled}
                                    same={history?.causalityUpv ? history.causalityUpv === formik.values?.causalityRelation : true }
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('explanation_causality')}</label>
                                <Textarea
                                    rows="6"
                                    name="causalityReasons"
                                    placeholder=""
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values?.causalityReasons ? formik.values?.causalityReasons : ""}
                                    {...formik.getFieldProps('causalityReasons')} 
                                    disabled={disabled}
                                    same={history?.causalityReasons ? history.causalityReasons === formik.values?.causalityReasons : true }
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('seriousness')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="seriousness"
                                    options={list?.seriousnessList?.map(item => { return { value: item.id, label: item.name };})}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.seriousness}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                               />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('expectedness')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="expectedness"
                                    options={list?.expectednessList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.expectedness}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('outcome')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="outcome"
                                    options={list?.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.outcome}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
            </>
        )
    }

}

export default Causality;
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";

import { DeleteModal } from "../../../components/Utils/Modal/DeleteModal";

import { authenticationService } from "../../../services/AuthService";
import { Role } from "../../../helpers/Constants";
import { Loader } from "../../../components/Utils/Loader/Loader";

import { PSURService } from "../../../services/PSUR/PSURService";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";

class PSUR extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      filterShow: false,
      records: [],
      addModalShow: false,
      deleteModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      loading: false,
      timezone: moment(new Date()).format("Z"),
      initPage: pages,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  record(id) {
    this.props.history.push(`/report-submission/psur/detail/${id}`, {
      formType: "edit",
      page: this.state.page,
    });
  }

  add = () => {
    this.props.history.push(`/report-submission/psur/add`);
  };

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageClick(event) {
    if (this.state.initPage !== undefined) {
      this.setState({ page: this.state.initPage, initPage: undefined }, () => {
        this.refreshList();
      });
    } else if (isNaN(event.selected)) {
      this.setState({ page: 1 }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  deleteRecord(id) {
    this.handleLoading(true);
    PSURService.Delete(id).then(
      (result) => {
        toastSuccess("Berhasil menghapus data");
        this.refreshList();
        this.setState({ deleteModalShow: false });
        this.handleLoading(false);
      },
      (error) => {
        toastSuccess(error);
        this.handleLoading(false);
      }
    );
  }

  refreshList() {
    this.handleLoading(true);

    PSURService.Read(
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState(
        {
          maxPage: result?.data?.maxPage,
          records: result?.data?.records,
          editModalShow: new Array(result?.data?.length).fill(false),
        },
        this.handleLoading(false)
      );
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  render() {
    let currentUser = authenticationService.currentUserValue;
    const { records } = this.state;

    const { t } = this.props;

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          message={t("delete_confirmation")}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
        />

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_psur")}
                    icon="fa fa-plus"
                    onClick={() => this.add()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {this.state.filterShow && (
          <div
            className="row filter-wrapper"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <div className="col-xs-12 col-sm-12 col-md-12">
              <div className="row">
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                    <div className="form-group">
                      <label className="form-label">{t("search")}</label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.query}
                          onChange={(event) => this.handleSearchChange(event)}
                          placeholder={t("keyword")}
                        />
                        <div className="input-group-append">
                          <button
                            className="btn btn-outline-secondary"
                            type="submit"
                          >
                            <i className="fa fa-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4">
                  <label className="form-label">{t("reported_date")}</label>
                  <DateRange
                    handlechange={(startDate, endDate, type) =>
                      this.handleDateChange(startDate, endDate, type)
                    }
                    type="KIPI"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="tab-pane show active">
          <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
            <Table className="pb-5" hover>
              <thead>
                <tr>
                  <th>No</th>
                  <th>{t("product_name")}</th>
                  <th>Periode</th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, index) => (
                  <>
                    <tr key={record.id}>
                      <td>
                        <div className="expand-table">
                          <span className="expand-number">
                            {index +
                              1 +
                              (this.state.page - 1) * this.state.limit}
                          </span>
                        </div>
                      </td>
                      <td>{record.productName}</td>
                      <td>
                        {(record?.startDate
                          ? moment(record.startDate).format("DD/MM/yyyy")
                          : "") +
                          (record?.endDate
                            ? " - " +
                              moment(record.endDate).format("DD/MM/yyyy")
                            : "")}
                      </td>
                      <td>
                        {record.reporterSubmissionProduct
                          ?.map((item) => {
                            return item.productName;
                          })
                          .join(", ")}
                      </td>
                      <td>
                        <div className="btn-group">
                          <button
                            className="btn btn-sm dropdown-toggle"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              className="fa fa-ellipsis-h"
                              aria-hidden="true"
                            ></i>
                          </button>
                          <div className="dropdown-menu">
                            <a
                              className="dropdown-item"
                              onClick={() => this.record(record.id)}
                            >
                              Detail
                            </a>
                            {currentUser.role === Role.Staff && (
                              <a
                                className="dropdown-item"
                                onClick={() => this.handleDelete(record.id)}
                              >
                                Delete
                              </a>
                            )}
                            <a
                              className="dropdown-item"
                              onClick={() =>
                                PSURService.Download(
                                  record.id,
                                  record.productName,
                                  (record?.startDate
                                    ? moment(record.startDate).format(
                                        "DD/MM/yyyy"
                                      )
                                    : "") +
                                    (record?.endDate
                                      ? " - " +
                                        moment(record.endDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : "")
                                )
                              }
                            >
                              Download Doc
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
                {records.length < 1 && (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {t("data_not_found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <nav>
            <ReactPaginate
              previousLabel={<i className="fas fa-arrow-left"></i>}
              previousLinkClassName={"page-link"}
              nextLabel={<i className="fas fa-arrow-right"></i>}
              nextLinkClassName={"page-link"}
              pageCount={this.state.maxPage}
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item"}
              breakClassName={"page-item"}
              pageLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              onPageChange={(event) => this.handlePageClick(event)}
              initialPage={this.state.initPage - 1}
            />
          </nav>
        </div>
      </>
    );
  }
}

export default withTranslation()(PSUR);

import React, { Component } from "react";
import ToggleDisplay from "react-toggle-display";

import Button from "../../../../components/Utils/Button/Button";
import Textarea from "../../../../components/Utils/Field/Textarea";
import Input from "../../../../components/Utils/Field/Input";
import Select2 from "../../../../components/Utils/Field/Select2";
import Select2Multi from "../../../../components/Utils/Field/Select2Multi";
import DatePicker from "../../../../components/Utils/DateRange/DateRangePicker";

import { AEFIOverseasService } from "../../../../services/BPOM/AEFIOverseasService";
import moment from "moment";
import { withTranslation } from "react-i18next";

class ReportInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      selectedData: [],
      isComplete: "",
      caseNumberList: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLastVersion !== this.props.isLastVersion) {
      this.setState({ isDisabled: !this.props.isLastVersion ? true : false });
    }

    if (
      prevProps.formik.values.caseNumber !==
        this.props.formik.values.caseNumber ||
      prevState.caseNumberList !== this.state.caseNumberList ||
      prevProps.formik.values.id !== this.props.formik.values.id
    ) {
      if (this.props.formik.values.caseNumber !== "") {
        if (this.state.caseNumberList) {
          let data = [];

          let index = this.state.caseNumberList
            .map(function (x) {
              return x.caseNumber;
            })
            .indexOf(this.props.formik.values.caseNumber);
          data = this.state.caseNumberList[index];
          this.props.updatedata(data);
          this.setState({ selectedData: data });

          let dataProduct = [];
          let productName = [];
          let vaccineProduct = [];
          let caseType = data?.aefiVaccine ? "KIPI" : "KTD";
          let caseSeriousness = data?.caseNumber
            ? data?.caseNumber.includes("AEFI")
              ? "KIPI"
              : data?.caseNumber.includes("PV")
              ? "PV Unit"
              : "KTD"
            : "KTD";
          let seriousness =
            caseType === "KIPI"
              ? data?.seriousnessNavigation
                ? data?.seriousnessNavigation.name.includes("Non")
                  ? "Tidak Serius"
                  : "Serius"
                : ""
              : "";
          let productCaseEntry = data?.aefiVaccine
            ? data?.aefiVaccine
            : data?.aeDrugs;
          if (productCaseEntry) {
            productCaseEntry.map((record, index) => {
              let id = record.vaccine ? record.vaccine : record.drugs;
              let name = record.vaccineNavigation
                ? record.vaccineNavigation?.name
                : record.drugsNavigation?.name;
              let letterProduct =
                name + "/" + (record.batch ? record.batch : "-");
              dataProduct = [...dataProduct, id];
              vaccineProduct = [
                ...vaccineProduct,
                {
                  id: id,
                  vaccine: id,
                  name: name,
                  batch: record.batch ? record.batch : "",
                },
              ];
              productName = [...productName, letterProduct];
            });
          }

          this.props.formik.setFieldValue("caseType", caseType);
          this.props.formik.setFieldValue(
            "caseEntry",
            data?.id ? data?.id : ""
          );
          this.props.formik.setFieldValue(
            "caseCategory",
            caseSeriousness + " " + seriousness
          );
          this.props.formik.setFieldValue(
            "vaccineName",
            dataProduct ? dataProduct : ""
          );
          this.props.formik.setFieldValue(
            "productName",
            productName ? productName : ""
          );
          this.props.formik.setFieldValue(
            "patientName",
            data?.patientName ? data?.patientName : ""
          );
          this.props.formik.setFieldValue(
            "patientGender",
            data?.patientGender ? data?.patientGender : ""
          );
          this.props.formik.setFieldValue(
            "patientAgeYear",
            data?.patientAgeYear ? data?.patientAgeYear : ""
          );
          this.props.formik.setFieldValue(
            "patientAgeMonth",
            data?.patientAgeMonth ? data?.patientAgeMonth : ""
          );
          this.props.formik.setFieldValue(
            "outcome",
            data?.outcome ? data?.outcome : ""
          );
          this.props.formik.setFieldValue(
            "outcomeLabel",
            data?.outcomeNavigation ? data?.outcomeNavigation?.name : ""
          );
          this.props.formik.setFieldValue(
            "immunizationDate",
            data?.lastImmunizationDate
              ? moment(data?.lastImmunizationDate, "YYYY-MM-DD").format(
                  "YYYY/MM/DD"
                )
              : ""
          );
          this.props.formik.setFieldValue(
            "productUseDate",
            data?.lastImmunizationDate
              ? moment(data?.lastImmunizationDate, "YYYY-MM-DD").format(
                  "YYYY/MM/DD"
                )
              : ""
          );
          this.props.formik.setFieldValue(
            "onsetDate",
            data?.onsetDate
              ? moment(data?.onsetDate, "YYYY-MM-DD").format("YYYY/MM/DD")
              : ""
          );
          this.props.formik.setFieldValue("vaccines", vaccineProduct);
          this.props.formik.setFieldValue(
            "patientCountry",
            data?.patientCountry ? data?.patientCountry : ""
          );
          this.props.formik.setFieldValue(
            "reporter",
            data?.reporterName ? data?.reporterName : ""
          );
          this.props.formik.setFieldValue(
            "reporterName",
            data?.reporterName ? data?.reporterName : ""
          );
          this.props.formik.setFieldValue(
            "reportedDate",
            data?.reportedDate
              ? moment(data?.reportedDate, "YYYY-MM-DD").format("YYYY/MM/DD")
              : ""
          );
        }
      }
    }
  }

  componentDidMount() {
    AEFIOverseasService.ReadAefi(1, 0, "").then((result) => {
      this.setState({
        caseNumberList: [...result.data.records],
      });
    });
  }

  handleCancelClick(event) {
    this.props.history.push(`/report-submission/bpom`, {
      tab: "KIPIOverseas",
      page: this.props.page,
    });
    event.preventDefault();
  }

  render() {
    const {
      formik,
      typecase,
      historydata,
      list,
      t,
      caseNumberList,
      countryList,
      formType,
      caseCategoryList,
      ...otherProps
    } = this.props;

    let caseNumber = this.state.caseNumberList ? this.state.caseNumberList : [];
    let filteredCaseNumber = caseNumber.reduce(function (filtered, item) {
      if (item.caseNumber && item.patientCountry !== "62") {
        var someNewValue = item;
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    return (
      <>
        <div
          className="tab-pane show active tab-pane-fixed"
          id="report-information"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("case_number")}</label>
                <Select2
                  name="caseNumber"
                  options={filteredCaseNumber?.map((item) => {
                    return { value: item.caseNumber, label: item.caseNumber };
                  })}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.caseNumber}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled={formType !== undefined}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("case_category")}</label>
                <Input
                  name="caseCategory"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.caseCategory}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("vaccine")}</label>
                <Select2Multi
                  disabled
                  name="vaccineName"
                  errors={formik.errors}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  value={formik.values.vaccineName}
                  options={list?.vaccineList.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("country")}</label>
                <Select2
                  name="patientCountry"
                  options={countryList?.map((country) => {
                    return { value: country.code, label: country.name };
                  })}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.patientCountry}
                  onBlur={formik.setFieldTouched}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">{t("place")}</label>
                    <Input
                      name="letterPlace"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("letterPlace")}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("date")}</label>
                    <DatePicker
                      name="letterDate"
                      selectionType="single"
                      maxdate={new Date()}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={formik.setFieldValue}
                      onBlur={formik.setFieldTouched}
                      value={formik.values.letterDate}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">{t("letter_address")}</label>
                <Textarea
                  name="letterAddress"
                  rows="6"
                  name="target"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("letterAddress")}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t("number_of_attachments")}
                </label>
                <div className="input-group">
                  <input
                    name="letterAttachment"
                    type="number"
                    min={0}
                    className="form-control"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("letterAttachment")}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      {t("attachment_hal")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <ToggleDisplay>
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t("cancel")}
                onClick={(event) => this.handleCancelClick(event)}
              />
            </ToggleDisplay>
            <ToggleDisplay>
              <Button
                text={t("next")}
                onClick={this.props.handleNextClick}
                isSubmitting={this.state.isDisableNext}
              />
            </ToggleDisplay>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReportInformation);

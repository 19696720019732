import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { DrugsService } from "../../../services/Master/DrugsService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import Modal from "../../../components/Utils/Modal/Modal";
import ModalHeader from "../../../components/Utils/Modal/ModalHeader";
import ModalContent from "../../../components/Utils/Modal/ModalBody";
import Button from "../../../components/Utils/Button/Button";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import FormDrugs from "./FormDrugs";

export class AddDrugsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drugList: [],
      loading_data: false,
      masterDrugsRelated: [{ itemId: "", dose: "" }],
    };
    this.refreshList();
  }

  refreshList() {
    this.state.pharmaceuticalList = DropdownService.PharmaceuticalList();

    DropdownService.MasterList(["productType"]).then((result) => {
      this.setState({ productTypeList: result.data.productTypeList });
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="container">
        <Modal {...this.props} size="xl">
          <Formik
            initialValues={{
              name: "",
              manufacture: "",
              type: "",
              pharmaceutical: "",
              indication: "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required").nullable(),
              manufacture: Yup.string().required("Required").nullable(),
              type: Yup.string().required("Required").nullable(),
              pharmaceutical: Yup.string().required("Required").nullable(),
              indication: Yup.string().required("Required").nullable(),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus();

              values.PharmaceuticalId = this.state.pharmaceuticalList?.filter(
                (obj) => {
                  return obj.value === values.pharmaceutical;
                }
              )[0].value;
              values.PharmaceuticalName = this.state.pharmaceuticalList?.filter(
                (obj) => {
                  return obj.value === values.pharmaceutical;
                }
              )[0].label;

              if (
                values.masterDrugsRelated.length === 1 &&
                values.masterDrugsRelated[0]?.itemId === ""
              ) {
                values.masterDrugsRelated = [];
              }

              DrugsService.Create(values).then(
                (result) => {
                  toastSuccess(t("success_add_message"));
                  this.props.onHide();
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }}
          >
            {(props) => (
              <Form>
                <ModalHeader>
                  <div className="modal-title">{t("add")} Drugs</div>
                  <hr></hr>
                </ModalHeader>
                <ModalContent>
                  <FormDrugs
                    t={t}
                    formik={props}
                    masterDrugsRelated={this.state.masterDrugsRelated}
                  />
                  <div className="form-group">
                    <div className="text-right">
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={this.props.onHide}
                      />
                      <Button
                        type="submit"
                        text={t("save")}
                        disabled={props.isSubmitting}
                      />
                      {props.status && (
                        <div className={"alert alert-danger"}>
                          {props.status}
                        </div>
                      )}
                    </div>
                  </div>
                </ModalContent>
              </Form>
            )}
          </Formik>
        </Modal>
      </div>
    );
  }
}

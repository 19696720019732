import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import {AddCausalityUnitAEFIModal} from '../AddCausalityUnitAEFIModal';
import {AddCausalityUnitAEModal} from '../AddCausalityUnitAEModal';
import { noAuto } from '@fortawesome/fontawesome-svg-core';

import { Role, Status } from '../../../../helpers/Constants';

class UnitAnalysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            causalityUnitModal: false,
            productList : [],
        };
    }


    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

        if(prevProps.caseEntry !== this.props.caseEntry){

            if(this.props.formType === "edit" ){

                let causalityData = [];

                this.props.causality.map((record, index) => {
                    let type = this.props.formik.values.caseType === "KIPI" ? true : false;
                    let vaccineNavigation =  [];
                    let drugsNavigation =  [];
                        vaccineNavigation.name = record.productName;
                        drugsNavigation.name = record.productName;
                    let data = type ? {vaccine : record.vaccine, vaccineNavigation } : {drugs : record.drugs, drugsNavigation }
                    causalityData = [...causalityData, data]
                })

                this.setState({productList : causalityData});

            }else{
            
                if(this.props.formik.values.caseType === "KIPI"){
                    this.setState({productList : this.props.caseEntry?.aefiVaccine ? this.props.caseEntry?.aefiVaccine : []});
                }else{
                    this.setState({productList : this.props.caseEntry?.aeDrugs ? this.props.caseEntry?.aeDrugs : []});
                }
            }
        }
    }

    handleOpenModal(id) {
        this.setState({ causalityUnitModal: true, index : id  })
    }

    render() {
        const { formik, typecase, caseEntry, causality, historyCausality, list, currentUser, t, i18next, ...otherProps } = this.props;
        const { productList, index } = this.state;

        const role = currentUser ? currentUser.role : "";
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion) || 
                            (this.props.formType !== "edit" &&
                                this.props.isLastVersion && 
                                (this.props.caseStatus !== Status.WaitingApproval && 
                                    this.props.caseStatus !== Status.Reviewed && 
                                    this.props.caseStatus !== Status.Approved
                                )) || 
                                ( this.props.formType === "edit" && 
                                    this.props.isLastVersion && 
                                (
                                    this.props.caseStatus !== Status.WaitingApproval && 
                                    this.props.caseStatus !== Status.Reviewed && 
                                    this.props.caseStatus !== Status.Approved)
                                )  || 
                                (this.props.isLastVersion === undefined ) || 
                                ((role === Role.Divisi && 
                                    this.props.caseStatus === Status.Reviewed && 
                                    this.props.isLastVersion)|| (role === Role.Unit && 
                                        this.props.caseStatus === Status.WaitingApproval && 
                                        this.props.isLastVersion)) ? false : this.props.isLastVersion === false || 
                                                                        this.props.caseStatus === Status.WaitingApproval || 
                                                                        this.props.caseStatus === Status.Reviewed || 
                                                                        this.props.caseStatus === Status.Approved ;
        
        return (
            <>
                {
                    formik.values.caseType === "KIPI" ? (
                        <AddCausalityUnitAEFIModal
                            show={this.state.causalityUnitModal}
                            onhide={() => this.setState({ causalityUnitModal: false })}
                            data_parent={formik}
                            historydata={null}
                            index={index}
                            list={list}
                            handleCausality={this.props.handleCausality}
                            causality={causality}
                            caseEntry={caseEntry}
                            product={productList}
                            disabled={isDisabledForm}
                            history={historyCausality}
                            formType={this.props.formType}
                            t={t}
                            i18next={i18next}
                        />
                    ):(
                        <AddCausalityUnitAEModal
                            show={this.state.causalityUnitModal}
                            onhide={() => this.setState({ causalityUnitModal: false })}
                            data_parent={formik}
                            historydata={null}
                            index={index}
                            list={list}
                            handleCausality={this.props.handleCausality}
                            causality={causality}
                            caseEntry={caseEntry}
                            disabled={isDisabledForm}
                            history={historyCausality}
                            formType={this.props.formType}
                            t={t}
                            i18next={i18next}
                        />
                    )
                }
                    
                <div className="tab-pane tab-pane-fixed" id="unit-analysis">
                    <div className="row">
                        <div className="col-md-12">
                            <Table className="pb-5" hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{t('product_name')}</th>
                                        <th>{t('causality')}</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { productList.map((record, index) =>
                                        {
                                            let type = formik.values.caseType === "KIPI" ? true : false;
                                            let id = type ? record.vaccine : record.drugs;
                                            let product_name = type ? record.vaccineNavigation?.name : record.drugsNavigation?.name;
                                            if(id !== null){
                                                return (
                                                    <tr key={id}>
                                                        <td>{index+1}</td>
                                                        <td>{product_name}</td>
                                                        <td>{ i18next.language === 'id' || i18next.language === 'ID' ? causality[index]?.causalityNamePV : causality[index]?.causalityNamePVEn}</td>
                                                        <td>
                                                            <button className="btn btn-sm" type="button" aria-expanded="false" onClick={() => this.handleOpenModal(index)}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }
                                    )}
                                    {
                                        productList.length < 1 && (
                                            <tr>
                                                <td colSpan="4" style={{textAlign:"center"}}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default UnitAnalysis;
import React, { Component } from 'react';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import { PartnerService } from '../../../services/Partner/PartnerService';


export class LetterModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pdf: ""
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.show != this.props.show) {

            if (this.props.show) {
                PartnerService.Preview(this.props.formik.values, this.props.language).then(url =>
                    this.setState({
                        pdf: url
                    })
                )
            }
            else {
                this.setState({
                    pdf: ""
                })
            }
        }
    }

    render() {

        const { formik } = this.props;

        const rowStyled = {
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px"
        }

        const col3Styled = {
            flex: "0 0 25%",
            maxWidth: "25%",
            paddingRight: 0
        }

        const col7Styled = {
            flex: "0 0 58.33333%",
            maxWidth: "58.33333%",
            paddingRight: 0
        }

        const col5Styled =  {
            flex: "0 0 41.66667%",
            maxWidth: "41.66667%",
        }

        const col12Styled =  {
            flex: "0 0 100%",
            maxWidth: "100%"
        }
        
        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalContent>
                        <iframe id="iframepdf" height="750" width="750" src={this.state.pdf}></iframe>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
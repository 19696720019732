import React, { Component } from 'react';

import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';
import MonthPicker from '../../../../components/Utils/DateRange/MonthPicker';
import { Loader } from '../../../../components/Utils/Loader/Loader';

import { AEFINonSeriousService } from '../../../../services/BPOM/AEFINonSeriousService';
import moment from "moment";

import {LetterKIPINonSeriousModal} from "../LetterKIPINonSeriousModal"
import {DataKIPINonSeriousModal} from "../DataKIPINonSeriousModal"

import { withTranslation } from "react-i18next";

class ReportInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            selectedData : [],
            isComplete : "",
            timezone: moment(new Date()).format('Z'),
            AEFIRecords: [],
            loading : false
        };
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.formik?.values.period !== this.props.formik?.values.period){
            if(this.props.formik.values.periodBegin && this.props.formik.values.periodEnd && this.props.formType === undefined){
                let startDate = this.props.formik.values.periodBegin +"Z"+ this.state.timezone;
                let endDate = this.props.formik.values.periodEnd +"Z"+ this.state.timezone;
                this.handleLoading(true);
                AEFINonSeriousService.ReadAefi(1, 0, "", "", "", startDate.replaceAll("/", "-"), endDate.replaceAll("/", "-")).then(
                    (result) => {
                        if(result.data.records){

                            this.setState({
                                AEFIRecords: result.data.records
                            });
        
                            this.props.formik.setFieldValue('caseTotal', result.data.records?.length );
                        }
                        this.handleLoading(false);
                    },
                    (error) => {
                        this.handleLoading(false);
                    }
                );
            }
        }

        if(prevProps.caseentry !== this.props.caseentry){
            this.setState({
                AEFIRecords: this.props.caseentry
            });
        }
    }

    componentDidMount() {

    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    render() {
        const { formik, type, formType, t } = this.props;

        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };
        let DataModalClose = () => {
            this.setState({
                dataShow: false
            })
        };

        return (
            
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD"/>
                    )
                }
                <LetterKIPINonSeriousModal
                    show={this.state.letterShow}
                    onHide={LetterModalClose}
                    formik={formik}
                />

                <DataKIPINonSeriousModal
                    show={this.state.dataShow}
                    onHide={DataModalClose}
                    formik={formik}
                    data={this.state.AEFIRecords}
                />
                <div className={type === "add" ? " " : "tab-pane show active tab-pane-fixed"} id="report-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('early_periode')}</label>
                                <MonthPicker
                                    name="period"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.period}
                                    disabled={formType !== undefined}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('number_of_cases')}</label>
                                <div className="input-group">
                                    <input
                                        name="caseTotal"
                                        type="number"
                                        min={0}
                                        className="form-control"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.caseTotal}
                                        disabled
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{t('cases')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-6">
                                        <label className="form-label">{t('place')}</label>
                                        <Input
                                            name="letterPlace"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps('letterPlace')}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">{t('date')}</label>
                                        <DatePicker
                                            name="letterDate"
                                            selectionType="single"
                                            maxdate={new Date()}
                                            value={formik?.values.letterDate}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="form-group">
                                <label className="form-label">{t('aefi_non_serious')}</label>
                                <br />
                                <Button
                                    text={t('data_preview')}
                                    onClick={()=>this.setState({ dataShow : true })}
                                />
                                <Button
                                    text={t('letter_preview')}
                                    onClick={()=>this.setState({ letterShow : true })}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('letter_address')}</label>
                                <Textarea
                                    name="letterAddress"
                                    rows="6"
                                    name="target"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('letterAddress')}
                                />
                            </div>
                        </div>
                    </div> 
                </div>
            </>
        )
    }

}

export default withTranslation()(ReportInformation);

import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import TableStatus from "../../../components/Utils/Status/TableStatus";
import { Loader } from "../../../components/Utils/Loader/Loader";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { authenticationService } from "../../../services/AuthService";
import { CaseEntryAnalysisDeadline, Role } from "../../../helpers/Constants";

import { DeleteModal } from "../../../components/Utils/Modal/DeleteModal";

import { AnalysisService } from "../../../services/QualityReview/Analysis/AnalysisService";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isMoreThanXDaysAgo } from "../../../helpers/Utils";

export class Analysis extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    const filterValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("filter")
      : null;
    const periodValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("period")
      : null;
    let data = {}
    if(filterValue != null){
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      let daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
      const currentDate = new Date();
      const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
      const daysUntilStartOfWeek = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
      const firstDateOfWeek = new Date(currentDate);
      firstDateOfWeek.setDate(currentDate.getDate() - daysUntilStartOfWeek);
      const lastDateOfWeek = new Date(firstDateOfWeek);
      lastDateOfWeek.setDate(firstDateOfWeek.getDate() + 6);
      const firstWeek = firstDateOfWeek.getDate()
      const endWeek = lastDateOfWeek.getDate()
      if(periodValue == "week"){
        let startDate = `${currentYear}-${currentMonth}-${firstWeek}Z+07:00`
        let endDate = `${currentYear}-${firstWeek > endWeek ? currentMonth+1 : currentMonth}-${endWeek}Z+07:00`
        data = { ...data, status: filterValue == "sudah-dianalisis" ? "Approved" : "", startDate, endDate };
      }
      else if(periodValue == "month"){
        let startDate = `${currentYear}-${currentMonth}-01Z+07:00`
        let endDate = `${currentYear}-${currentMonth}-${daysInMonth}Z+07:00`
        data = { ...data, status: filterValue == "sudah-dianalisis" ? "Approved" : "", startDate, endDate };
      }else if(periodValue == "year"){
        let startDate = `${currentYear}-01-01Z+07:00`
        let endDate = `${currentYear}-12-31Z+07:00`
        data = { ...data, status: filterValue == "sudah-dianalisis" ? "Approved" : "", startDate, endDate };
      } else if (!periodValue){
        let startDate = ""
        let endDate = ""
        data = { ...data, status: filterValue == "sudah-dianalisis" ? "Approved" : "", startDate, endDate };
      }
    }

    this.state = {
      filter : filterValue,
      period : periodValue,
      records: [],
      filterShow: false,
      deleteModalShow: false,
      addModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: data,
      elementSpan: "",
      timezone: moment(new Date()).format("Z"),
      loading: false,
      pages: pages,
    };
  }

  componentDidMount() {
    this.refreshList();
  }

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  handleClearFilters = () => {
    this.setState({ filter : null})
    this.setState({ params : {}});
    this.handlePageReset();
    this.props.history.push(`/medical-review/analysis`)
  }

  deleteRecord(id) {
    this.handleLoading(true);

    AnalysisService.Delete(id).then(
      (result) => {
        this.refreshList();
        this.setState({ deleteModalShow: false });
        this.handleLoading(false);
        toastSuccess("Data berhasil dihapus");
      },
      (error) => {
        this.handleLoading(false);
        toastError(error);
      }
    );
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  refreshList() {
    this.handleLoading(true);
    AnalysisService.Read(
      this.state.filter,
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState({
        maxPage: result.data.maxPage,
        records: result.data.records,
        editModalShow: new Array(result.data.length).fill(false),
      });
      this.handleLoading(false);
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageClick(event) {
    if (this.state.pages !== undefined) {
      this.setState({ page: this.state.pages, pages: undefined }, () => {
        this.refreshList();
      });
    } else if (isNaN(event.selected)) {
      this.setState({ page: 1 }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  handleTambah = () => {
    this.props.history.push(`/medical-review/analysis/add-analysis`);
  };

  handleDelete = (id) => {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  };

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  handleStatusChange(item) {
    let data = this.state.params;
    data = { ...data, status: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  followUp(id, caseType) {
    this.props.history.push(
      `/medical-review/analysis/${caseType}/follow-up/${id}`
    );
  }

  detailrecord(id, caseType) {
    this.props.history.push(
      `/medical-review/analysis/${caseType}/detail/${id}`,
      { formType: "edit", page: this.state.page }
    );
  }

  render() {
    const { t } = this.props;
    const { records } = this.state;
    let currentUser = authenticationService.currentUserValue;
    const rolesWithAction = currentUser.role === Role.Staff || currentUser.role === Role.Manager;

    const statusOptions = [
      { value: "Draft", label: "Draft" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not+Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
    ];

    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
    };
 
    const handleCheckExpired = (analysis) => {
      let expiredDetail = { outcome: false, desc: '' };

      if (analysis?.completenessData) {
        expiredDetail.outcome = isMoreThanXDaysAgo(
          analysis?.reportDate,
          CaseEntryAnalysisDeadline.CompleteDeadline
        );
        expiredDetail.desc = t("expired_30_analysis_date");
      } else {
        expiredDetail.outcome = isMoreThanXDaysAgo(
          analysis?.reportDate,
          CaseEntryAnalysisDeadline.InCompleteClose + CaseEntryAnalysisDeadline.InCompleteDeadline
        );
        expiredDetail.desc = t("expired_44_analysis_date");
      }

      return expiredDetail;
    }

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DeleteModal
          message={t("delete_confirmation")}
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        {/* Content */}

        <div className="tab-content">
          {this.state.filter != null &&
            <div className="col-xs-6 col-sm-6 col-md-12">
              <div className="aler alert-warning" style={{ width: "100%", marginBottom: 15, border: "0.5px solid", borderRadius: "6px", padding: "8px 15px" }}>
                Ini adalah hasil filter dari Dashboard
                <button
                  type="button"
                  className="close"
                  onClick={this.handleClearFilters}
                >
                &times;
                </button>
              </div>
            </div>
          }
          <div className="row" style={{ marginTop: 15 }}>
            {rolesWithAction && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_analysis")}
                    icon="fa fa-plus"
                    onClick={() => this.handleTambah()}
                  />
                </div>
              </div>
            )}
            {!rolesWithAction && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            {this.state.filter == null && 
              <div className="col-xs-6 col-sm-6 col-md-2">
                <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                  <ButtonWithIcon
                    text="Filter"
                    icon="fas fa-bars"
                    onClick={() =>
                      this.setState({ filterShow: !this.state.filterShow })
                    }
                  />
                </div>
              </div>
            }
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                      <div className="form-group">
                        <label className="form-label">{t("search")}</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.Query}
                            onChange={(event) => this.handleSearchChange(event)}
                            placeholder={t("keyword")}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="submit"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <label className="form-label">{t("analysis_date")}</label>
                    <DateRange
                      handlechange={(startDate, endDate, type) =>
                        this.handleDateChange(startDate, endDate, type)
                      }
                      type="KIPI"
                      filterUntil = {true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">Status</label>
                    <Select
                      placeholder="Status"
                      width="100%"
                      name="statusFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={statusOptions}
                      value={this.state.status}
                      onChange={(item) => this.handleStatusChange(item)}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* ANALYSIS TAB */}
          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("analysis_date")}</th>
                    <th>{t("causality")}</th>
                    <th>{t("outcome")}</th>
                    <th width="15%">Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.page - 1) * this.state.limit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(
                            record.analysisDate ?? record.createDate
                          ).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.analysisProduct
                            ?.filter(
                              (item, index) => item.causalityUpv !== null
                            )
                            .map((item) => {
                              return item.causalityUpvNavigation.name;
                            })
                            .join(", ")}
                        </td>
                        <td>
                          {record.resumeNeeded ? t("different") : t("same")}
                        </td>
                        <td>
                          <TableStatus
                            status={record.status}
                            expired={handleCheckExpired(record)}
                          />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.detailrecord(record.id, record.caseType)
                                }
                              >
                                Detail
                              </a>
                              {rolesWithAction && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="7">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>{t("report_type")}</th>
                                  <th>{t("date")}</th>
                                  <th>{t("causality")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailAnalysis
                                  .sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail) => {
                                    return (
                                      <tr>
                                        <td>
                                          {trail.followUp === 0
                                            ? "Inisial"
                                            : "Follow Up " + trail.followUp}
                                        </td>
                                        <td>
                                          {moment(
                                            trail.followUpDate ??
                                              trail.createDate
                                          ).format("DD/MM/yyyy")}
                                        </td>
                                        <td>
                                          {trail.trailAnalysisProduct
                                            ?.filter(
                                              (item, index) =>
                                                item.causalityUpv !== null
                                            )
                                            .map((item) => {
                                              return item.causalityUpvNavigation
                                                .name;
                                            })
                                            .join(", ")}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {moment(
                                      record.followUpDate ?? record.createDate
                                    ).format("DD/MM/yyyy")}
                                  </td>
                                  <td>
                                    {record.analysisProduct
                                      ?.filter(
                                        (item, index) =>
                                          item.causalityUpv !== null
                                      )
                                      .map((item) => {
                                        return item.causalityUpvNavigation.name;
                                      })
                                      .join(", ")}
                                  </td>
                                </tr>
                                {rolesWithAction && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text={t("add") + " Follow Up"}
                                          margin="0"
                                          onClick={() =>
                                            this.followUp(
                                              record.id,
                                              record.caseType
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {this.state.records.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i className="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i className="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                initialPage={this.state.pages - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Analysis));

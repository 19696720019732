import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import { AppExternalService } from '../../services/AppExternal/AppExternalService';
import Input from '../../components/Utils/Field/Input';
import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';

import { withTranslation } from "react-i18next";
class EditAppExternalModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    generateKey(length, chars) {
        var mask = '';
        if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
        if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (chars.indexOf('#') > -1) mask += '0123456789';
        if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
        var result = '';
        for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
        return result;
    }

    render() {
        const { t } = this.props;
        return (

            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            appLabel: this.props.selectedRecord?.appLabel,
                            appCode: this.props.selectedRecord?.appCode,
                            apiKey: this.props.selectedRecord?.apiKey,
                            username: this.props.selectedRecord?.internalUserNavigation.username,
                        }}
                        validationSchema={
                            Yup.object().shape({
                                appLabel: Yup.string().required('Required').nullable(),
                                appCode: Yup.string().required('Required').nullable(),
                                apiKey: Yup.string().required('Required').nullable(),
                                username: Yup.string().required('Required').nullable(),
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            values.id = this.props.id;

                            AppExternalService.Update(this.props.id, values).then(
                                (result) => {
                                    // this.setState({ snackbaropen: true, snackbarmsg: "Record edited" });
                                    toastSuccess(t('success_add_message'));
                                    this.props.onHide();
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                })
                        }}
                    >
                        {props => (
                            <Form>
                                <ModalHeader>
                                    <div className="modal-title">Edit App External</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="form-group">
                                        <label>{t('app_name')}</label>
                                        <Input
                                            name="appLabel"
                                            type="text"
                                            maxLength="100"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('appLabel')} />
                                    </div>
                                    <div className="form-group">
                                        <label>{t('app_code')}</label>
                                        <Input
                                            name="appCode"
                                            type="text"
                                            maxLength="4"
                                            pattern="[a-zA-Z0-9]+"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('appCode')} />
                                    </div>
                                    <div className="form-group">
                                        <label>API key</label>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <Input
                                                    disabled
                                                    name="apiKey"
                                                    type="text"
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    {...props.getFieldProps('apiKey')} />
                                            </div>
                                            <div className="col-md-3 pl-0">
                                                <Button text="Generate" className="m-0" onClick={() => props.setFieldValue('apiKey', this.generateKey(16, "aA#"))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Username</label>
                                        <Input
                                            name="username"
                                            type="text"
                                            maxLength="25"
                                            pattern="[a-zA-Z0-9]+"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('username')} />
                                    </div>
                                    <div className="form-group">
                                        <div className='d-flex'>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={this.props.onHide}
                                            />
                                            <Button type="submit" text={t('save')} disabled={props.isSubmitting} />
                                            {
                                                props.status &&
                                                <div className={'alert alert-danger'}>{props.status}</div>
                                            }
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(EditAppExternalModal);
import React from 'react';
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

export class Loader extends React.Component {
    constructor(props, context) {
        super(props);
    
        this.state = {
          
        };
    }

    render() {

        const { color, loading } = this.props; 

        return (
            <div style={{ position: "absolute", width: "100%", height:"100%", top: 0, left: 0, zIndex: 1042,  background:"#00000005" }}>
                <div style={{marginLeft:"45%", marginTop:"30%"}}>
                    <SyncLoader color={color} loading={loading} />
                </div>
            </div>
        )
    }
}


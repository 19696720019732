import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';

import * as Utils from '../../../helpers/Utils';
import Button from '../../../components/Utils/Button/Button';
import Tab from '../../../components/Utils/Tab/Tab';

import ReportInformation from './Tab/ReportInformation';
import PatientInformation from './Tab/PatientInformation';
import AnalysisResult from './Tab/AnalysisResult';

import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { AEFIService } from '../../../services/CaseEntry/AEFIService';
import { AEService } from '../../../services/CaseEntry/AEService';
import { AnalysisService } from '../../../services/QualityReview/Analysis/AnalysisService';
import { ProgressReportService } from '../../../services/BPOM/ProgressReportService';
import { DropdownService } from '../../../services/Master/AllDropdownService';

import { withTranslation } from "react-i18next";

class AddProgressReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            backTab: "",
            nextTab: "patient-information",
            currentTab: "report-information",
            historyForm: [],
            caseEntryData: [],
        };

        this.prepareForm()
        this.formikRef = React.createRef();
    }

    handleSearch = async (inputValue) => {
        try {
            const [kipi, ktd] = await Promise.all([
                AEFIService.ReadForBpomProgress(true, inputValue),
                AEService.ReadForBpomProgress(true, inputValue),
            ]);

            this.setState({
                caseNumberList: [...kipi.data.records, ...ktd.data.records],
            });
        } catch (error) {
            console.error("Error fetching data", error);
        }
    }

    debounce = (func, delay) => {
        let inDebounce;
        return (...args) => {
            return new Promise(resolve => {
                clearTimeout(inDebounce);
                inDebounce = setTimeout(() => resolve(func(...args)), delay);
            });
        };
    };

    promiseOptions = this.debounce(async (inputValue) => {
        try {
            const [kipi, ktd] = await Promise.all([
                AEFIService.ReadForBpomProgress(true, inputValue),
                AEService.ReadForBpomProgress(true, inputValue),
            ]);

            const combinedData = [...kipi.data.records, ...ktd.data.records].map((item) => {
                return { value: item.caseNumber, label: item.caseNumber, id: item.id };
            });
            this.setState({ caseNumberList: [...kipi.data.records, ...ktd.data.records] })

            return combinedData;
        } catch (error) {
            console.error("Error fetching data", error);
            return [];
        }
    }, 500);



    prepareForm() {
        Promise.all([
            AEFIService.ReadForBpomProgress(true, ''),
            AEService.ReadForBpomProgress(true, ''),
            // ProgressReportService.ReadAefi(1, 0, ""),
            // ProgressReportService.ReadAe(1, 0, ""),
            ProgressReportService.ReadAnalysis(1, 0, ""),
            ProgressReportService.ReadAdrOnline(1, 0, ""),
            ProgressReportService.ReadAefiOverseas(1, 0, ""),
            DropdownService.MasterList(["vaccine", "drugs", "aefiCategory", "aeCategory", "bpomProgressCategory", "outcome", "causalityAEFIA", "causalityAEFIB", "causalityAEFIC", "causalityAEFID", "letter"]),
            DropdownService.ProvinceList(62)])
            .then(([kipi, ktd, analysis, adrOnline, aefiOverseas, dropdown, provinceList]) => {

                this.setState({
                    caseNumberList: [...kipi.data.records, ...ktd.data.records],
                    caseCategoryList: [...dropdown.data.aefiCategoryList, ...dropdown.data.aeCategoryList],
                    bpomProgressCategoryList: dropdown.data.bpomProgressCategoryList,
                    outcomeList: dropdown.data.outcomeList,
                    vaccineList: dropdown.data.vaccineList.map(item => { return { value: item.id, label: item.name }; }),                                            
                    drugsList: dropdown.data.drugsList.map(item => { return { value: item.id, label: item.name }; }),
                    provinceList: provinceList.data,
                    causalityAEFIA: dropdown.data.causalityAEFIA,
                    causalityAEFIB: dropdown.data.causalityAEFIB,
                    causalityAEFIC: dropdown.data.causalityAEFIC,
                    causalityAEFID: dropdown.data.causalityAEFID,
                    analysisList: analysis.data.records,
                    adrOnlineList: adrOnline.data.records,
                    aefiOverseasList: aefiOverseas.data.records,
                    letterList: dropdown.data.letterList[0],
                });
            });
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#report-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "report-information", backTab: "", nextTab: "patient-information"
                });
                break;
            case "#patient-information":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "patient-information", backTab: "report-information", nextTab: "analysis-result",
                    externalDisabled: false
                });
                break;
            case "#analysis-result":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: true,
                    currentTab: "analysis-result", backTab: "patient-information", nextTab: "",
                    unitDisabled: false
                });
                break;
        }

        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
        this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    document.getElementById("link-" + this.state.nextTab).click();
                }
            }
        )
        event.preventDefault();
    }
    
    render() {

        const { t } = this.props;

        const validationSchemaDict = {
            "report-information": 
            Yup.object().shape({
                letterPlace : Yup.string().required("Required").nullable(),
                letterDate : Yup.string().required("Required").nullable(),
                letterAddress : Yup.string().required("Required").nullable(),
                caseNumber : Yup.string().required("Required").nullable(),
            })
        }

        const initialTouched = {
            letterPlace : true,
            letterDate : true,
            letterAddress : true,
            caseNumber : true
        }

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber={true}
                                title={t('report_information')}
                                href="#report-information"
                                onClick={(event) => this.handleTabChange(event, "#report-information")}
                                id="link-report-information"
                            />
                            <Tab
                                number="2"
                                showNumber = {true}
                                title={t('patient_information')}
                                href="#patient-information"
                                onClick={(event) => this.handleTabChange(event, "#patient-information")}
                                id="link-patient-information"                           
                            />
                            <Tab
                                number= "3"
                                showNumber = {true}
                                title={t('analysa_result')}
                                href="#analysis-result"
                                onClick={(event) => this.handleTabChange(event, "#analysis-result")}
                                id="link-analysis-result"
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    enableReinitialize
                    initialTouched={initialTouched}
                    initialValues={{
                        caseNumber: '',
                        
                        patientName: '',
                        patientAgeYear: '',
                        patientAgeMonth: '',
                        patientGender: '',

                        letterNumberOverseas1: this.state.letterList?.code,
                        letterNumberOverseas3: this.state.letterList?.shortName,

                        resumeProduct: [],
                    }}
                    validationSchema={validationSchemaDict[this.state.currentTab]}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        
                        ProgressReportService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push({ pathname: `/report-submission/bpom`, state: { tab: 'ProgressReport' } });
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            })
                    }}
                >
                    {formik => 

                        (
                            <Form>
                                <div className="tab-content">

                                    {/* Report Information Tab */}

                                    <ReportInformation
                                    formik={formik}
                                    handleNextClick={(event) => this.handleNextClick(event)}
                                    historydata={this.state.historyForm}
                                    history={this.props.history}
                                    caseEntry={this.state.caseEntryData}
                                    disabledNext={this.state.inisiasiDisabled}
                                    updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                    caseNumberList={this.state.caseNumberList}
                                    caseCategoryList={this.state.caseCategoryList}
                                    bpomProgressCategoryList={this.state.bpomProgressCategoryList}
                                    vaccineList={this.state.vaccineList}
                                    drugsList={this.state.drugsList}
                                    analysisList={this.state.analysisList}
                                    adrOnlineList={this.state.adrOnlineList}
                                    aefiOverseasList={this.state.aefiOverseasList}
                                    tabType="ProgressReport"
                                    promiseOptions={this.promiseOptions}
                                    />

                                    {/* Patient Information Tab */}

                                    <PatientInformation
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData}
                                        disabledNext={this.state.inisiasiDisabled}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                        outcomeList={this.state.outcomeList}     
                                    />

                                    {/* Analysis Result Tab */}

                                    <AnalysisResult
                                        formik={formik}
                                        handleBackClick={(event) => this.handleBackClick(event)}
                                        handleNextClick={(event) => this.handleNextClick(event)}
                                        historydata={this.state.historyForm}
                                        history={this.props.history}
                                        caseEntry={this.state.caseEntryData}
                                        disabledNext={this.state.inisiasiDisabled}
                                        updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                        provinceList={this.state.provinceList}
                                        causalityAEFIA={this.state.causalityAEFIA}
                                        causalityAEFIB={this.state.causalityAEFIB}
                                        causalityAEFIC={this.state.causalityAEFIC}
                                        causalityAEFID={this.state.causalityAEFID}
                                        nextTab={this.state.nextTab}                                    
                                    />
    
                                    {
                                        this.state.nextTab == "" &&
    
                                        <div className='text-right'>
                                            <ToggleDisplay>
                                                <Button
                                                    color="#495057"
                                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                                    border="1px solid #495057"
                                                    text= {t('back')}
                                                    onClick={(event) => this.handleBackClick(event)} />
                                            </ToggleDisplay>
                                            <ToggleDisplay show={this.state.saveShow}>
                                                <Button
                                                    type="submit"
                                                    text={t('save')}
                                                    disabled={formik.isSubmitting} />
                                            </ToggleDisplay>
                                            {
                                                formik.status &&
                                                <div className={'alert alert-danger'}>{formik.status}</div>
                                            }
                                        </div>
    
                                    }
                                    
                                </div>                            
                            </Form>
                        )
                    } 
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddProgressReport)

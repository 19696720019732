import React, { Component } from 'react';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalContent from '../../../components/Utils/Modal/ModalBody';

import * as Utils from '../../../helpers/Utils';

import moment from "moment";
import localization from 'moment/locale/id';

import { DivisionService } from '../../../services/DivisionService'

export class LetterKIPINonSeriousModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            divisi : ""
        }
    }

    componentDidMount(){
        DivisionService.Read().then(
            (result) => {
                this.setState({divisi: result?.data})
            },
            (error) => {
            }
        );
    }
    

    render() {

        const { formik } = this.props;

        const { divisi } = this.state;

        const rowStyled = {
            display: "flex",
            flexWrap: "wrap",
            marginRight: "-15px",
            marginLeft: "-15px"
        }

        const col3Styled = {
            flex: "0 0 25%",
            maxWidth: "25%",
            paddingRight: 0
        }

        const col7Styled = {
            flex: "0 0 58.33333%",
            maxWidth: "58.33333%",
            paddingRight: 0
        }

        const col5Styled =  {
            flex: "0 0 41.66667%",
            maxWidth: "41.66667%",
        }

        const col12Styled =  {
            flex: "0 0 100%",
            maxWidth: "100%"
        }
        
        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalContent>
                        <div style={rowStyled}>
                           <div style={col3Styled}>
                                <div>
                                    <img src="../assets/images/biofarma.png" style={{maxWidth: "100%", marginBottom: 40}} />
                                </div>
                            </div> 
                        </div>
                        <div style={rowStyled}>
                            <div style={col7Styled}>
                                <table style={{width:"100%"}}>
                                    <tr>
                                        <td style={{width:"30%"}}>No. Surat</td>
                                        <td>:</td>
                                        <td style={{width:"65%"}}></td>
                                    </tr>
                                    <tr>
                                        <td>Lampiran</td>
                                        <td>:</td>
                                        <td>{formik?.values.totalAppendix ? formik?.values.totalAppendix + " hal" : "-"}</td>
                                    </tr>
                                    <tr style={{verticalAlign:"top"}}>
                                        <td>Hal</td>
                                        <td>:</td>
                                        <td>Pelaporan Spontan KIPI Non-Serius Periode {Utils.spellPeriod(formik?.values.period)}</td>
                                    </tr>
                                </table>
                            </div>
                            <div style={col5Styled}>
                                <span style={{ fontSize: 14, float: "right" }}>{(formik?.values.letterPlace ? formik?.values.letterPlace : "") + ", " + moment(formik?.values.letterDate).locale('id', localization).format("DD MMMM YYYY")}</span>
                            </div>
                        </div>
                        <hr/>
                        <div style={rowStyled}>
                            <div style={col5Styled}>
                                <p style={{fontSize : 14}}>Kepada Yth.</p>
                                <p style={{fontSize : 14}}>{formik?.values.letterAddress}</p>
                            </div>
                            <div style={col12Styled}>
                                <p style={{ fontSize: 14 }}>Dengan Hormat,</p>
                                <p style={{ fontSize: 14 }}>Sesuai peraturan Kepala Badan POM RI Nomor HK.03.1.23.12.11.10690 Tahun 2011 tentang Penerapan Farmakovigilans Bagi Industri Farmasi, bahwa setiap industri farmasi wajib melaporkan KIPI non-serius dari pelaporan spontan di dalam negeri yang tidak dapat diperkirakan (unexpected) setiap 6 bulan sekali (Januari dan Juli), maka berikut kami sampaikan {formik?.values.caseTotal} pelaporan spontan KIPI Non-Serius dari dalam negeri, periode {Utils.spellPeriod(formik?.values.period)}. Detail kasus terlampir.</p>
                                <br/>
                                <p style={{fontSize : 14}}>Demikian informasi yang dapat kami sampaikan. Atas perhatian dan kerjasamanya, kami mengucapkan terima kasih.</p>
                                <br/>
                                <p style={{fontSize : 14, marginTop: 40}}>Hormat kami, </p>
                                <p style={{fontSize : 14}}>PT Biofarma (Persero)</p>
                                <br/>
                                <br/>
                                <p style={{fontSize : 14}}>{divisi?.nama ?? 'Novilia Sjafri Bachtiar'}</p>
                                <p style={{fontSize : 14}}>{divisi?.posisi ?? 'Kepala Divisi Surveilans dan Uji Klinis'}</p>
                            </div>
                        </div>
                        <hr/>
                        <div style={rowStyled}>
                            <div style={col12Styled}>
                                <h4 style={{fontWeight: "bolder", color:"black"}}>PT BIOFARMA (PERSERO)</h4>
                                <p style={{fontSize : 14, color: "#707070", marginBottom: 0}}>Jl. Pasteur No.28, Bandung 40161, Indonesia, P.O. Box 1136, Telp. 022-2033755, Fax. 022 2041306</p>
                                <p style={{fontSize : 14, color: "#707070"}}>email: mail@biofarma.co.id - http://www.biofarma.co.id</p>
                            </div>
                        </div>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
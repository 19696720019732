import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ProductDistributionService } from '../../services/ProductDistribution/ProductDistributionService';

import { FormProductDistribution } from './FormProductDistribution';

import { withTranslation } from "react-i18next";

class DetailProductDistribution extends Component {

    constructor(props) {
        super(props);

        const { id } = this.props.match.params;

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            productDistributionRecords: [],
            id: id,
        }

        this.getData(id);
    }

    getData(id) {
        this.state.productDistributionRecords = [{ trialName: "", ageRange: "", purpose: "", totalMale: 0, totalFemale: 0, total: 0 }];


        ProductDistributionService.PrepareForm(id).then(result => {

            const product = result.data.vaccineNavigation ?? result.data.drugNavigation

            this.setState({
                data: result.data,
                productDistributionRecords: result.data.productDistributionRecords,
                productList: [{ value: product.id, label: product.name }]
            });
        });
    }

    productUpdate = (value) => {
        this.setState({productDistributionRecords : value})
    }

    cancel = () => {
        this.props.history.push(`/product-distribution`);
    }

    render() {

        const { t } = this.props;

        const distribution = this.state.data;

        const initial = {
            id: this.state.id,
            product: distribution?.vaccine ?? distribution?.drug,
            productDistributionRecords: distribution?.productDistributionRecords,
        }

        return (
            <>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={initial}

                        validationSchema={
                            Yup.object().shape({
                                product: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            values.productDistributionRecords = values.productDistributionRecords?.filter(obj => { return obj != null });

                            ProductDistributionService.Update(values.id, values).then(
                                
                                (result) => {
                                    toastSuccess(t('success_edit_message'));
                                    this.props.history.push(`/product-distribution`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                            (
                                <Form>
                                    <div className="tab-content">
                                        <FormProductDistribution
                                            formik={formik}
                                            productDistributionRecords={this.state.productDistributionRecords}
                                            productList={this.state.productList}
                                            productUpdate={(value) => this.productUpdate(value)}
                                            t={t}
                                        />
                                    </div>
                                    <div className='text-right'>
                                        <ToggleDisplay>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={() => this.cancel()} 
                                            />
                                        </ToggleDisplay>
                                        <ToggleDisplay>
                                            <Button
                                                type="submit"
                                                text={t('save')}
                                                disabled={formik.isSubmitting} 
                                            />
                                        </ToggleDisplay>
                                        {
                                            formik.status &&
                                            <div className={'alert alert-danger'}>{formik.status}</div>
                                        }
                                    </div>
                                </Form>
                            )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(DetailProductDistribution)
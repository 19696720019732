import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import TableStatus from "../../../components/Utils/Status/TableStatus";

import { DeleteModal } from "./DeleteModal";

import { DropdownService } from "../../../services/Master/AllDropdownService";
import { authenticationService } from "../../../services/AuthService";
import { Role, Status } from "../../../helpers/Constants";

import { ReporterService } from "../../../services/Reporter/ReporterService";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";

class Reporter extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      filterShow: false,
      records: [],
      addModalShow: false,
      deleteModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      elementSpan: "",
      pages: pages,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.refreshList();

    DropdownService.MasterList(["vaccine", "drugs"]).then((result) => {
      this.setState({ list: result.data });
    });
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  refreshList() {
    ReporterService.Read(
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState({
        maxPage: result?.data?.maxPage,
        records: result?.data?.records,
        editModalShow: new Array(result?.data?.length).fill(false),
      });
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handleVaccineChange(item) {
    let data = this.state.params;
    data = { ...data, vaccine: item?.value ?? "" };

    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDrugsChange(item) {
    let data = this.state.params;
    data = { ...data, drugs: item?.value ?? "" };

    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handlePageClick(event) {
    if (this.state.pages !== undefined) {
      this.setState({ page: this.state.pages, pages: undefined }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected ? event.selected + 1 : this.state.page }, () => {
        this.refreshList();
      });
    }
  }

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  handleStatusChange(item) {
    let data = this.state.params;
    data = { ...data, status: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  record(id) {
    this.props.history.push(`/report-submission/reporter/detail/${id}`, {
      formType: "edit",
      page: this.state.page,
    });
  }

  followUp(id) {
    this.props.history.push(`/report-submission/reporter/follow-up/${id}`);
  }

  add = () => {
    this.props.history.push(`/report-submission/reporter/add-reporter`);
  };

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  deleteRecord(id) {
    const { t } = this.props;
    ReporterService.Delete(id).then(
      (result) => {
        toastSuccess(t("success_delete_message"));
        this.refreshList();
        this.setState({ deleteModalShow: false });
      },
      (error) => {
        toastSuccess(error);
      }
    );
  }

  render() {
    let currentUser = authenticationService.currentUserValue;

    const { records } = this.state;

    const { t } = this.props;

    const statusOptions = [
      { value: "Draft", label: "Draft" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not+Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
      { value: "Closed", label: "Closed" },
    ];

    const drugsList = this.state.list?.drugsList;
    const vaccineList = this.state.list?.vaccineList;

    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
    };

    return (
      <>
        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
        />

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_reporter")}
                    icon="fa fa-plus"
                    onClick={() => this.add()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                      <div className="form-group">
                        <label className="form-label">{t("search")}</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.query}
                            onChange={(event) => this.handleSearchChange(event)}
                            placeholder={t("keyword")}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="submit"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6 col-md-2"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="form-label">{t("vaccine")}</label>
                    <Select
                      placeholder={t("vaccine")}
                      width="100%"
                      name="vaccineFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={vaccineList?.map((vaccine) => {
                        return { value: vaccine.id, label: vaccine.name };
                      })}
                      value={this.state.vaccine}
                      onChange={(item) => this.handleVaccineChange(item)}
                    ></Select>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6 col-md-2"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="form-label">{t("drug")}</label>
                    <Select
                      placeholder={t("drug")}
                      width="100%"
                      name="drugsFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={drugsList?.map((drugs) => {
                        return { value: drugs.id, label: drugs.name };
                      })}
                      value={this.state.drugs}
                      onChange={(item) => this.handleDrugsChange(item)}
                    ></Select>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">Status</label>
                    <Select
                      placeholder="Status"
                      width="100%"
                      name="statusFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={statusOptions}
                      value={this.state.status}
                      onChange={(item) => this.handleStatusChange(item)}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("created_date")}</th>
                    <th>{t("product_name")}</th>
                    <th>{t("letter_number")}</th>
                    <th>{t("send_date")}</th>
                    <th width="15%">Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.page - 1) * this.state.limit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(
                            record.modifiedDate ?? record.createDate
                          ).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.reporterSubmissionProduct
                            ?.map((item) => {
                              return item.productName;
                            })
                            .join(", ")}
                        </td>
                        <td>{record.mailingNumber}</td>
                        <td>
                          {record.mailingSentDate
                            ? moment(record.mailingSentDate).format(
                                "DD/MM/yyyy"
                              )
                            : "-"}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      ReporterService.Download(
                                        record.id,
                                        record.caseNumber
                                      )
                                    }
                                  >
                                    Download PDF
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      ReporterService.DownloadDoc(
                                        record.id,
                                        record.caseNumber
                                      )
                                    }
                                  >
                                    Download Doc
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="8">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>{t("report_type")}</th>
                                  <th>{t("created_date")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailReporterSubmission
                                  ?.sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail, index) => (
                                    <tr>
                                      <td>
                                        {trail.followUp === 0 ||
                                        trail.followUp === null
                                          ? "Inisial"
                                          : "Follow Up " + trail.followUp}
                                      </td>
                                      <td>
                                        {moment(
                                          trail.followUpDate ?? trail.createDate
                                        ).format("DD/MM/yyyy")}
                                      </td>
                                    </tr>
                                  ))}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {moment(
                                      record.followUpDate ?? record.createDate
                                    ).format("DD/MM/yyyy")}
                                  </td>
                                </tr>
                                {currentUser.role === Role.Staff && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text="Tambah Follow Up"
                                          margin="0"
                                          onClick={() =>
                                            this.followUp(record.id)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {records.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i className="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i className="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                initialPage={this.state.pages - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Reporter);

import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal as ModalBootstrap } from 'react-bootstrap';

const ModalHeaderStyled = styled(ModalBootstrap.Header).attrs(props => ({ ...props }))`
    text-align: left;
    font: normal normal bold 32px/51px Montserrat;
    letter-spacing: 0px;
    color: #00ABBD;
    opacity: 1;
    padding-top: 48px;
    padding-left: 48px;
    padding-right: 48px;
`

export default class ModalHeader extends Component {

    render() {
        return (
            <ModalHeaderStyled>
                {this.props.children}
            </ModalHeaderStyled>
        );
    }
}

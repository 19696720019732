import React, { Component } from 'react';

import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalBody';
import Button from '../Button/Button';
import Input from '../Field/Input';
import { SignatureForm } from '../Signature/SignatureForm';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { CaseCommentReportService } from '../../../services/CaseCommentReportService';
import { BpomProgressCommentService } from '../../../services/BPOM/BpomProgressCommentService';
import { CaseCommentReviewService } from '../../../services/QualityReview/CaseCommentReviewService';
import { CaseCommentService } from '../../../services/CaseEntry/CaseCommentService';

export class RejectModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            disabled: true,
        };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    disabledButton = (value) => {

        this.setState({disabled : value})
    }

    render() {

        const {t} = this.props;

        return (
            <div className="container">
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">{ t ? t('confirmation') : "Konfirmasi"}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <div style={{ marginTop: 15, marginBottom: 25, fontSize: 14, color: "#485867" }}>{this.props.text}</div>

                        <Formik
                            enableReinitialize
                            initialValues={{
                                comment: '',
                                verified: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    comment: Yup.string().required("Required").nullable(),
                                    verified: Yup.string().required("Required").nullable(),
                                })
                            }
                            onSubmit={(values, { setStatus, setSubmitting }) => {

                                
                                if (this.props.menu === 'bpomProgress') {

                                    BpomProgressCommentService.Write({ id: this.props.id, comment: values.comment });

                                }else if(this.props.menu === 'medicalReview'){

                                    CaseCommentReviewService.Write({ id: this.props.id, comment: values.comment });

                                }else if(this.props.menu === 'caseEntry'){

                                    CaseCommentService.Write({ caseEntry: this.props.id, comment: values.comment });
                                
                                }else{

                                    CaseCommentReportService.Write(this.props.type, { id: this.props.id, comment: values.comment });
                                }
                                this.props.confirm()
                            }}
                        >
                            {props => (
                                <Form>
                                    <div className="form-group">
                                        <label className="form-label">{ t ? t('comment') : "Komentar"}</label>
                                        <Input
                                            name="comment"
                                            type="text"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('comment')} />
                                    </div>

                                    <div className="form-group">
                                        <label className="form-label">Digital Signature</label>
                                        <SignatureForm
                                            formik = {props}
                                            check = {true}
                                            id = {this.props.id}
                                            disabledButton = {() => this.disabledButton()}
                                        />
                                    </div>

                                    <div className='text-right'>
                                        {this.props.showhide &&
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={ t ? t('cancel') : "Batal"}
                                                onClick={this.props.hide} 
                                            />
                                        }
                                        <Button 
                                            background="#00ABBD"
                                            color="white"
                                            type="submit" 
                                            text={ t ? t('yes') : "Ya"}
                                            isSubmitting={this.state.disabled}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
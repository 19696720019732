import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const DashboardService = {
    ReadReport,
    ReadGraph, 
    ReadGraphV2,
    ReadStatus,
    ReadSeriousness,
    ReadCausalities,
    ReadProduct,
    ReadTotalCase
};

async function ReadReport() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/case-entry-reports`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadGraph(caseType) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/case-seriousness?caseType=${caseType}`, requestOptions);
    data = await handleResponse(data)
    return data;
}
async function ReadGraphV2(param) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/case-seriousness-v2?caseType=${param.caseType}&YearType=${param.YearType.value}&startDate=${param.startDate}&endDate=${param.endDate}&RegionType=${param.RegionType.value}&CaseCategory=${param.CaseCategory.value}&product=${param.Product.value}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadStatus(period) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/case-by-status?period=${period}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadTotalCase(period) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/case-total`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadSeriousness() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/aefi-serious-causalities`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadCausalities(param) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/causalities?caseType=${param.caseType}&caseCategory=${param.caseCategory}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadProduct(type) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/dashboard/most-reported-products?type=${type}`, requestOptions);
    data = await handleResponse(data)
    return data;
}


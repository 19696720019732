import React, { Component } from "react";
import { Formik, Form } from "formik";
import ToggleDisplay from "react-toggle-display";
import moment from "moment";

import Button from "../../components/Utils/Button/Button";
import Tab from "../../components/Utils/Tab/Tab";
import Reporter from "./TabCaseArchival/Reporter";
import Product from "./TabCaseArchival/Product";
import Case from "./TabCaseArchival/Case";
import Analysis from "./TabCaseArchival/Analysis";

import { CaseArchivalService } from "../../services/SummaryReport/CaseArchivalService";
import { CaseEntryType } from "../../helpers/Constants";
import { withTranslation } from "react-i18next";
export class DetailCaseArchival extends Component {
  constructor(props) {
    super(props);

    const { id, type } = this.props.match.params;

    this.state = {
      id: id,
      backTab: "",
      currentTab: "report-information",
      nextTab: "product-information",
      records: [],
    };

    this.prepareForm(id, type);
  }

  prepareForm(id, type) {
    CaseArchivalService.PrepareForm(id, type).then((result) => {
      if (result.data) {
        this.setState({
          records: result.data,
        });
      }
    });
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "product-information",
        });
        break;
      case "#product-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "product-information",
          backTab: "report-information",
          nextTab: "case-information",
        });
        break;
      case "#case-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "case-information",
          backTab: "product-information",
          nextTab: "analysis-information",
        });
        break;
      case "#analysis-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "analysis-information",
          backTab: "case-information",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push(`/summary-report/case-archival`, {
      pages: this.props?.location?.state?.page,
    });
    event.preventDefault();
  }

  handleNextClick(event) {
    document.getElementById("link-" + this.state.nextTab).click();
    event.preventDefault();
  }

  render() {
    const { records } = this.state;
    const caseEntry = records?.caseEntry;
    const adrOnline = records?.adrOnline;
    const analysis = records?.analysis;
    const summary = records?.summary;
    const manufacture = records?.manufactureInitial;
    const { t } = this.props;

    const product = caseEntry?.aefiVaccine ?? caseEntry?.aeDrugs ?? [];
    const diagnosis = caseEntry?.aefiDiagnosis ?? caseEntry?.aeDiagnosis ?? [];

    const adversePvDate =
      caseEntry?.followUp === 0
        ? caseEntry?.adversePvDate
        : caseEntry?.trailAefiCaseEntry
        ? caseEntry?.trailAefiCaseEntry
            .filter((value) => value.followUp === 0)
            .map((x) => {
              return x.adversePvDate;
            })
        : caseEntry?.trailAeCaseEntry
            .filter((value) => value.followUp === 0)
            .map((x) => {
              return x.adversePvDate;
            });

    const initialValues = {
      caseNumber: caseEntry?.caseNumber,
      adversePvDate: adversePvDate
        ? typeof adversePvDate === "string"
          ? moment(adversePvDate).format("DD/MM/yyyy")
          : moment(adversePvDate[0]).format("DD/MM/yyyy")
        : "-",
      followUpDate:
        caseEntry?.followUp !== 0 && caseEntry?.reportedDate
          ? moment(caseEntry?.reportedDate).format("DD/MM/yyyy")
          : "",
      reporterName: caseEntry?.reporterName,
      dataSource: caseEntry?.datasourceNavigation?.name,
      diagnosis: caseEntry?.resultConclusion,
      complaint:
        caseEntry?.aefiAllergic || caseEntry?.aefiSymptomps
          ? caseEntry?.aefiSymptomps
              ?.map((item) => {
                return item.symptompsNavigation?.name;
              })
              .join(", ") +
            (caseEntry?.aefiAllergic?.length > 0
              ? ", " +
                caseEntry?.aefiAllergic
                  ?.map((item) => {
                    return item.allergicNavigation?.name;
                  })
                  .join(", ")
              : "")
          : caseEntry?.reaction
          ? caseEntry?.reaction
          : "-",
      immunizationDate: caseEntry?.lastImmunizationDate
        ? moment(caseEntry?.lastImmunizationDate).format("DD/MM/yyyy")
        : caseEntry?.productUseDate
        ? moment(caseEntry?.productUseDate).format("DD/MM/yyyy")
        : "-",
      onsetDate: caseEntry?.onsetDate
        ? moment(caseEntry?.onsetDate).format("DD/MM/yyyy")
        : "-",
      seriousness: caseEntry?.seriousnessNavigation?.name,
      outcome: caseEntry?.outcomeNavigation?.name,
      expectedness: caseEntry?.expectednessNavigation?.name,
      treatmentDate: caseEntry?.treatmentDate
        ? moment(caseEntry?.treatmentDate).format("DD/MM/yyyy")
        : "",
      dose: product
        .map((item) => {
          return item.dose;
        })
        .join(", "),
      doseFor: product
        .map((item) => {
          return item.doseFor;
        })
        .join(", "),
      route: product
        .map((item) => {
          return item.routeImmunizationNavigation?.name;
        })
        .join(", "),
      systemOrganClass: diagnosis
        .map((item) => {
          return item.systemOrganClassNavigation?.name;
        })
        .join(", "),
      preferredTerm: diagnosis
        .map((item) => {
          return item.preferredTermNavigation?.name;
        })
        .join(", "),
      patientName: caseEntry?.patientName,
      patientAgeOfYear: caseEntry?.patientAgeYear,
      patientAgeOfMonth: caseEntry?.patientAgeMonth,
      patientGender: caseEntry?.patientGender
        ? caseEntry?.patientGender === "M"
          ? "Laki-laki"
          : caseEntry?.patientGender
          ? "Perempuan"
          : "Tidak Ada Data"
        : "-",
      caseProduct: product,
      adrOnlineNumber: adrOnline?.adronlineNumber,
      adrOnlineDate: adrOnline?.createdDate
        ? moment(adrOnline?.createdDate).format("DD/MM/yyyy")
        : "-",
      manufactureNumber: caseEntry?.manufactureCaseNumber,
      manufactureInitialDate: manufacture?.mailingSentDate
        ? moment(manufacture?.mailingSentDate).format("DD/MM/yyyy")
        : "-",
      analysisDate: analysis?.analysisDate
        ? moment(analysis?.analysisDate).format("DD/MM/yyyy")
        : "-",
      analysisTime:
        (caseEntry?.followUpDate || adversePvDate) && analysis?.analysisDate
          ? Math.abs(
              moment(
                caseEntry?.followUpDate ??
                  (typeof adversePvDate === "string"
                    ? adversePvDate
                    : adversePvDate[0])
              )
                .startOf("day")
                .diff(moment(analysis?.analysisDate).startOf("day"), "days")
            ) === 0
            ? 1
            : Math.abs(
                moment(
                  caseEntry?.followUpDate ??
                    (typeof adversePvDate === "string"
                      ? adversePvDate
                      : adversePvDate[0])
                )
                  .startOf("day")
                  .diff(moment(analysis?.analysisDate).startOf("day"), "days")
              )
          : "-",
      analysisExternal: analysis?.analysisProduct
        ? analysis?.analysisProduct
            ?.map((item) => {
              return item.causalityExternalNavigation?.nameId;
            })
            .join(", ")
        : "-",
      analysisPV: analysis?.analysisProduct
        ? analysis?.analysisProduct
            ?.map((item) => {
              return item.causalityUpvNavigation?.nameId;
            })
            .join(", ")
        : "-",
      summaryDate: summary?.createdDate
        ? moment(summary?.createdDate).format("DD/MM/yyyy")
        : "-",
      summaryTime:
        summary?.createdDate && records?.bpomProgress?.sendDate
          ? Math.abs(
              moment(summary?.createdDate)
                .startOf("day")
                .diff(
                  moment(records?.bpomProgress?.sendDate).startOf("day"),
                  "days"
                )
            ) === 0
            ? 1
            : Math.abs(
                moment(summary?.createdDate)
                  .startOf("day")
                  .diff(
                    moment(records?.bpomProgress?.sendDate).startOf("day"),
                    "days"
                  )
              )
          : "-",
      caseClosedDate: records?.bpomProgress?.sendDate
        ? moment(records?.bpomProgress?.sendDate).format("DD/MM/yyyy")
        : "",
      status: caseEntry?.statusCaseArchival,
    };

    return (
      <>
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <ul className="nav nav-justified mb-3 menu-tab">
              <Tab
                number="1"
                showNumber={true}
                title={t("report_information")}
                href="#report-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#report-information")
                }
                id="link-report-information"
              />
              <Tab
                number="2"
                showNumber={true}
                title={t("product_information")}
                href="#product-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#product-information")
                }
                id="link-product-information"
              />
              <Tab
                number="3"
                showNumber={true}
                title={t("case_information")}
                href="#case-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#case-information")
                }
                id="link-case-information"
              />
              <Tab
                number="4"
                showNumber={true}
                title={t("analysis_information")}
                href="#analysis-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#analysis-information")
                }
                id="link-analysis-information"
              />
            </ul>
          </div>
        </div>
        <Formik initialValues={initialValues} enableReinitialize>
          {(formik) => (
            <Form>
              <div className="tab-content">
                <Reporter formik={formik} />
                <Product formik={formik} />
                <Case formik={formik} />
                <Analysis formik={formik} />

                <div className="text-right">
                  {this.state.backTab === "" && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text="Batal"
                        onClick={(event) => this.handleCancelClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  {this.state.backTab !== "" && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text="Kembali"
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  {this.state.nextTab !== "" && (
                    <ToggleDisplay>
                      <Button
                        text="Lanjut"
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailCaseArchival);

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ReminderService } from '../../services/Reminder/ReminderService';
import { DropdownService } from '../../services/Master/AllDropdownService';
import { withTranslation } from "react-i18next";

import FormReminder from './FormReminder';

export class DetailReminder extends Component {

    constructor(props) {
        super(props);

        const { id } = this.props.match.params;

        console.log(this.props.match.params)

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            id: id
        }

        this.getData(id);
    }

    getData(id) {

        Promise.all([DropdownService.MasterList(["vaccine", "vaccinator", "drugs", "seriousness", "expectedness", "outcome",]), ReminderService.Read(1, 1, "", id)])
            .then(([dropdown, data]) => {

                // get dropdown data
                this.setState({
                    list: dropdown.data
                });

                // get data

                if (data.data) {
                    this.setState({
                        data: data.data.records[0]
                    });
                }
                else {
                    this.props.history.push(`/reminder`);
                }
            },
                () => {
                    this.props.history.push(`/reminder`);
                });
    }


    cancel = () => {
        this.props.history.push(`/reminder`);
    }

    render() {

        const { list, data } = this.state;
        const { t } = this.props;

        let product = data?.reminderDrugs?.length > 0 ? data?.reminderDrugs : data?.reminderVaccines;

        let product_array = [];

        if (product) {
            product.map((item) =>
                product_array = [...product_array, item.drug ? item.drug : item.vaccine]
            )
        }

        let instance = null;
        if (data?.manufacture != null) {
            instance = data?.manufacture;
        } else if (data?.organization != null) {
            instance = data?.organization;
        }

        const initial = {
            id: data?.id,
            instance: instance,
            caseType: `${data?.caseType}`,
            seriousness: data?.seriousness,
            expectedness: data?.expectedness,
            isOverseas: `${data?.isOverseas}`,
            products: product_array,
            dueAmount: data?.dueAmount,
            dueType: data?.dueType,
        }


        return (
            <>
                <div>
                    <Formik

                        enableReinitialize
                        initialValues={initial}

                        validationSchema={
                            Yup.object().shape({
                                instance: Yup.string().required("Required").nullable(),
                                caseType: Yup.string().required("Required").nullable(),
                                seriousness: Yup.string().required("Required").nullable(),
                                expectedness: Yup.string().required("Required").nullable(),
                                dueAmount: Yup.string().required("Required").nullable(),
                                dueType: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            values.instance = (values.instance === "BPOM") ? null : values.instance

                            ReminderService.Update(values.id, values).then(

                                (result) => {
                                    toastSuccess('Berhasil mengubah data');
                                    this.props.history.push(`/reminder`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                        (
                            <Form>
                                <div className="tab-content">
                                    <FormReminder
                                        formik={formik}
                                        list={list}
                                    />
                                </div>
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t("cancel")}
                                            onClick={() => this.cancel()}
                                        />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t("save")}
                                            disabled={formik.isSubmitting}
                                        />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>
                            </Form>
                        )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(DetailReminder);
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Select from "react-select";

import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { VirusVaccineService } from '../../services/SummaryReport/VirusVaccineService';
import { withTranslation } from "react-i18next";
import BarChart from '../../components/Utils/Chart/Bar';
import { debounce } from '../../helpers/Utils';
export class VirusVaccineAEFI extends Component {

    constructor(props) {
        super(props);
        this.debouncedRefreshList = debounce(this.refreshList.bind(this), 500);
        this.state = {
            records: [],
            years: [],
            totals: [],
            page: 1,
            maxPage: 0,
            limit: 0,
            query: "",
            yearType: { value: "Reported", label: "Tahun Pelaporan" },
            listYearOptions: [],
            yearMostProduct: {
                "value": new Date().getFullYear(),
                "label": new Date().getFullYear()
            },
            yearTypeMostProduct: { value: "Reported", label: "Tahun Pelaporan" },
            recordMostProduct: [],
            disabledDownload: false,
        }
    }

    componentDidMount() {        
        this.refreshList();
        this.getMostProduct();
    }

    refreshList() {

        VirusVaccineService.Read(this.state.page, this.state.limit, this.state.query, this.state.params, this.state.yearType.value).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records?.data ?? [],
                years: result?.data?.records?.listYear,
                totals: result?.data?.records?.totalPerYear,
            });

            if (!result?.data?.count) {
                this.setState({
                    disabledDownload: true
                })
            } else {
                this.setState({
                    disabledDownload: false
                })
            }
        });
    }

    getMostProduct = () => {
        VirusVaccineService.ReadMostProduct(
            this.state.yearMostProduct.value,
            this.state.yearTypeMostProduct.value,
        ).then((product) => {
                let listYear = []
                product?.data?.listYear?.map((item) => {
                    listYear.push({
                        "value": item,
                        "label": item
                    })
                })
                this.setState({
                    recordMostProduct: product?.data?.data,
                    listYearOptions: listYear
                });
            }
        );
    }

    download() {
        VirusVaccineService.Download(this.state.yearType.value, this.state.query)
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value }, () => {
            this.debouncedRefreshList(); 
        });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }

    handleYearTypeChange(item) {        
        this.setState({ yearType: item }, () => {
            this.refreshList();
        });
    }

    handleYearTypeMostProductChange(item) {        
        this.setState({ yearTypeMostProduct: item }, () => {
            this.getMostProduct();
        });
    }

    handleListYearMostProductChange(item) {        
        this.setState({ yearMostProduct: item }, () => {
            this.getMostProduct();
        });
    }


    render() {

        const { records, years, totals, recordMostProduct, listYearOptions } = this.state;
        const { t } = this.props;
        const yearTypeOptions = t('year_type_option', { returnObjects: true })

        return (
            <>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="d-flex justify-content-start mb-3 align-items-center">
                        <p className="titleCard">
                            {`${t('most_product_virus_vaccine')}`}
                        </p>
                        <div className='ml-auto d-flex'>
                            <div className="mx-2" style={{minWidth: 180}}>
                                <Select
                                    placeholder="Tahun"
                                    width="100%"
                                    name="statusFilterMostProduct"                            
                                    classNamePrefix="select"
                                    className="basic-single"
                                    options={yearTypeOptions}
                                    value={yearTypeOptions.filter(option => option.value === this.state.yearTypeMostProduct.value)}
                                    onChange={(item) => this.handleYearTypeMostProductChange(item)}
                                ></Select>
                            </div>
                            <div className="mx-2" style={{minWidth: 180}}>
                                <Select
                                    placeholder="Tahun"
                                    width="100%"
                                    name="yearFilterMostProduct"
                                    classNamePrefix="select"
                                    className="basic-single"
                                    options={listYearOptions}
                                    value={listYearOptions.filter(option => option.value === this.state.yearMostProduct.value)}
                                    onChange={(item) => this.handleListYearMostProductChange(item)}
                                ></Select>
                            </div>

                        </div>
                    </div>

                    <BarChart 
                        label="Total"
                        data={recordMostProduct}
                    />
                </div>
            </div>
            
            <div className="card">
                <div className="card-body">    
                    <div className="row">
                        <div className="col-md-3" style={{ marginBottom: 15 }}>
                            <ButtonWithIcon
                                text="Download"
                                icon="fa fa-download"
                                onClick={() => this.download()}
                                isSubmitting={this.state.disabledDownload}
                            />
                        </div>
                        <div className="col-md-3">                        
                            <Select
                                placeholder="Tahun"
                                width="100%"
                                name="statusFilter"                            
                                classNamePrefix="select"
                                className="basic-single"
                                options={yearTypeOptions}
                                value={yearTypeOptions.filter(option => option.value === this.state.yearType.value)}
                                onChange={(item) => this.handleYearTypeChange(item)}
                            ></Select>
                        </div>
                        <div className="col-md-3 ml-auto">
                            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                <div className="right">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input type="text" className="form-control"
                                                value={this.state.query}
                                                onChange={(event) => this.handleSearchChange(event)}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="table-responsive" style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                        <Table className="pb-5" hover>
                            <thead style={{ textAlign: "center", background: "#F4F5F7" }}>
                                <tr>
                                    <th rowspan="3" style={{ verticalAlign: "middle" }}>No</th>
                                    <th rowspan="2" style={{ verticalAlign: "middle" }}>{t('vaccine')}</th>
                                    <th colspan="10">{yearTypeOptions.find(option => option.value === this.state.yearType.value).label}</th>
                                </tr>
                                <tr>
                                    {
                                        years?.map((record, index) =>
                                            <th>{record == 0 ? 'Tidak diketahui' : record}</th>
                                        )
                                    }
                                </tr>
                                <tr style={{ background: "#D8F1FF99" }}>
                                    <th>Total</th>
                                    {
                                        totals?.map((record, index) =>
                                            <th>{record}</th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                {
                                    records?.map((record, index) =>

                                        record.Vaccines &&

                                        <tr key={record.id}>
                                            <td>
                                                {index + 1 + (this.state.page - 1) * this.state.limit}
                                            </td>
                                            <td style={{ textAlign: "left" }}>
                                                {record.Vaccines}
                                            </td>

                                            {
                                                record?.TotalArr?.map((total, index) =>

                                                    <td>{total}</td>

                                                )
                                            }
                                        </tr>
                                    )
                                }
                                {
                                    records.length < 1 && (
                                        <tr>
                                            <td colSpan="3" style={{ textAlign: "center" }}>
                                                Data tidak ditemukan
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                    
                </div>
            </div>
            </>
        )
    }

}

export default withTranslation()(VirusVaccineAEFI);
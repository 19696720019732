import React, { Component } from "react";
import ToggleDisplay from "react-toggle-display";

import * as Utils from "../../../../helpers/Utils";
import Button from "../../../../components/Utils/Button/Button";
import Textarea from "../../../../components/Utils/Field/Textarea";
import Input from "../../../../components/Utils/Field/Input";
import Select2 from "../../../../components/Utils/Field/Select2";
import Select2Multi from "../../../../components/Utils/Field/Select2Multi";
import DatePicker from "../../../../components/Utils/DateRange/DateRangePicker";

import { AEFIService } from "../../../../services/CaseEntry/AEFIService";
import { AEService } from "../../../../services/CaseEntry/AEService";
import { Status } from "../../../../helpers/Constants";

import moment from "moment";

import { withTranslation } from "react-i18next";

class ReportInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      selectedData: [],
      caseNumberList: [],
      partnerList: [],
      isComplete: "",
      records: [],
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isLastVersion !== this.props.isLastVersion) {
      this.setState({ isDisabled: !this.props.isLastVersion ? true : false });
    }

    if (
      prevProps.formik.values.caseNumber !==
        this.props.formik.values.caseNumber ||
      prevProps.formik.values.caseEntry !==
        this.props.formik.values.caseEntry ||
      prevState.caseNumberList !== this.state.caseNumberList ||
      prevProps.formik.values.id !== this.props.formik.values.id
    ) {
      if (this.props.formik.values.caseNumber !== "") {
        if (this.state.caseNumberList) {
          let data = [];

          if (
            this.props.formType === undefined ||
            (this.props.formType !== "edit" &&
              this.props.isLastVersion === null) ||
            (this.props.formType !== "edit" &&
              this.props.isLastVersion &&
              this.state.caseNumberList?.length > 0)
          ) {
            let index = this.state.caseNumberList
              .map(function (x) {
                return x.caseNumber;
              })
              .indexOf(this.props.formik.values.caseNumber);
            data = this.state.caseNumberList[index];
            this.props.updatedata(data);
            this.setState({ selectedData: data });
          } else {
            data = this.props.caseEntry;
          }
          let dataProduct = [];
          let productName = [];
          let partnerSubmissionProduct = [];
          let caseType = data?.aefiVaccine ? "KIPI" : "KTD";
          let caseSeriousness = data?.caseNumber
            ? data?.caseNumber.includes("AEFI")
              ? "KIPI"
              : data?.caseNumber.includes("PV")
              ? "PV Unit"
              : "KTD"
            : "KTD";
          let seriousness =
            caseType === "KIPI"
              ? data?.seriousnessNavigation
                ? data?.seriousnessNavigation.name.includes("Non")
                  ? "Tidak Serius"
                  : "Serius"
                : ""
              : "";
          let productCaseEntry = data?.aefiVaccine
            ? data?.aefiVaccine
            : data?.aeDrugs;
          if (productCaseEntry) {
            productCaseEntry.map((record, index) => {
              let id = record.vaccine ? record.vaccine : record.drugs;
              let name = record.vaccineNavigation
                ? record.vaccineNavigation?.name
                : record.drugsNavigation?.name;
              dataProduct = [...dataProduct, id];
              partnerSubmissionProduct = [
                ...partnerSubmissionProduct,
                {
                  product: id,
                  productName: name,
                  batch: record.batch ? record.batch : "",
                },
              ];
              productName = [...productName, name];
            });
          }
          let initialName = data?.patientName;

          this.props.formik.setFieldValue("caseType", caseType);
          this.props.formik.setFieldValue(
            "typeCaseEntry",
            caseType === "KIPI" ? "AEFI" : "AE"
          );
          this.props.formik.setFieldValue(
            "caseEntry",
            data?.id ? data?.id : ""
          );
          this.props.formik.setFieldValue(
            "typeCase",
            caseSeriousness + " " + seriousness
          );
          this.props.formik.setFieldValue(
            "product",
            dataProduct ? dataProduct : ""
          );
          this.props.formik.setFieldValue(
            "productName",
            productName ? productName : ""
          );
          this.props.formik.setFieldValue(
            "initialName",
            initialName ? initialName : ""
          );
          this.props.formik.setFieldValue(
            "gender",
            data?.patientGender ? data?.patientGender : ""
          );
          this.props.formik.setFieldValue(
            "ageOfYear",
            data?.patientAgeYear ? data?.patientAgeYear : ""
          );
          this.props.formik.setFieldValue(
            "ageOfMonth",
            data?.patientAgeMonth ? data?.patientAgeMonth : ""
          );
          this.props.formik.setFieldValue(
            "outcome",
            data?.outcome ? data?.outcome : ""
          );
          this.props.formik.setFieldValue(
            "outcomeLabel",
            data?.outcomeNavigation ? data?.outcomeNavigation?.name : ""
          );
          this.props.formik.setFieldValue(
            "immunizationDate",
            data?.lastImmunizationDate
              ? moment(data?.lastImmunizationDate, "YYYY-MM-DD").format(
                  "YYYY/MM/DD"
                )
              : ""
          );
          this.props.formik.setFieldValue(
            "complaintDate",
            data?.onsetDate
              ? moment(data?.onsetDate, "YYYY-MM-DD").format("YYYY/MM/DD")
              : ""
          );
          this.props.formik.setFieldValue(
            "partnerSubmissionProduct",
            partnerSubmissionProduct
          );
          this.props.formik.setFieldValue("seriousness", data?.seriousness);
        }
      }
    }

    if (prevProps.formik.values !== this.props.formik.values) {
      if (this.props.updatedisabled) {
        if (
          this.props.formik.values.caseNumber &&
          this.props.formik.values.partner
        ) {
          this.props.updatedisabled("#report-information", false);
        } else {
          this.props.updatedisabled("#report-information", true);
        }
      }
    }

    if (
      prevProps.formik.values.partner !== this.props.formik.values.partner ||
      prevProps.list?.partnerList !== this.props.list?.partnerList
    ) {
      let index = this.props.list?.partnerList
        .map(function (x) {
          return x.id;
        })
        .indexOf(this.props.formik.values.partner);
      let data = this.props.list?.partnerList[index] ?? [];
      this.props.formik.setFieldValue("mailingDestination", data?.destination);
    }
  }

  componentDidMount() {
    Promise.all([
      AEFIService.Read(
        "",
        1,
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true,
        "",
        ""
      ),
      AEService.Read(
        "",
        1,
        0,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true,
        "",
        ""
      ),
    ]).then(([kipi, ktd]) => {
      this.setState({
        caseNumberList: [...kipi.data.records, ...ktd.data.records],
      });
    });
  }

  handleCancelClick(event) {
    this.props.history.push("/report-submission/partner", {
      pages: this.props.page,
    });
    event.preventDefault();
  }

  render() {
    const { formik, typecase, historydata, list, formType, t } = this.props;
    const isDisabledForm =
      (this.props.formType !== "edit" &&
        this.props.isLastVersion &&
        this.props.caseStatus !== Status.Reviewed &&
        this.props.caseStatus !== Status.Approved) ||
      (this.props.formType === "edit" &&
        this.props.isLastVersion &&
        this.props.caseStatus !== Status.Reviewed &&
        this.props.caseStatus !== Status.Approved) ||
      this.props.isLastVersion === undefined
        ? false
        : this.props.isLastVersion === false ||
          this.props.caseStatus === Status.Reviewed ||
          this.props.caseStatus === Status.Approved;

    let caseNumber = this.state.caseNumberList;
    let filteredCaseNumber = caseNumber.reduce(function (filtered, item) {
      if (item.caseNumber) {
        var someNewValue = { value: item.caseNumber, label: item.caseNumber };
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    let productList =
      formik?.values.caseType === "KIPI" ? list?.vaccineList : list?.drugsList;

    return (
      <>
        <div
          className="tab-pane show active tab-pane-fixed"
          id="report-information"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  {t("case_number")} <span style={{ color: "red" }}>*</span>
                </label>
                {this.props.formType !== "edit" &&
                this.props.formType !== "follow-up" ? (
                  <Select2
                    name="caseNumber"
                    options={filteredCaseNumber}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.caseNumber}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    disabled={formType !== undefined}
                  />
                ) : (
                  <Input
                    name="caseNumber"
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.caseNumber}
                    onChange={formik.setFieldValue}
                    onBlur={formik.setFieldTouched}
                    disabled={formType !== undefined}
                  />
                )}
              </div>
              <div className="form-group">
                <label className="form-label">{t("case_category")}</label>
                <Input
                  name="typeCase"
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.typeCase}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("product_name")}</label>
                <Select2Multi
                  options={productList?.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                  value={formik.values.product}
                  name="product"
                  errors={formik.errors}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">{t("place")}</label>
                    <Input
                      name="mailingPlace"
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("mailingPlace")}
                      disabled={isDisabledForm}
                      same={
                        historydata?.mailingPlace
                          ? historydata.mailingPlace ===
                            formik.values?.mailingPlace
                          : true
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">{t("date")}</label>
                    <DatePicker
                      name="mailingDate"
                      selectionType="single"
                      maxdate={new Date()}
                      errors={formik.errors}
                      touched={formik.touched}
                      onChange={formik.setFieldValue}
                      onBlur={formik.setFieldTouched}
                      value={formik.values.mailingDate}
                      disabled={isDisabledForm}
                      same={
                        historydata?.mailingDate
                          ? moment(
                              historydata?.mailingDate,
                              "YYYY-MM-DD"
                            ).format("DD/MM/yyyy") ===
                            formik.values?.mailingDate
                          : true
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t("case_reference_number")}
                </label>
                <Input
                  name="referenceCaseNumber"
                  rows="6"
                  name="target"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("referenceCaseNumber")}
                  same={
                    historydata?.referenceCaseNumber
                      ? historydata.referenceCaseNumber ===
                        formik.values?.referenceCaseNumber
                      : true
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">
                  Partner <span style={{ color: "red" }}>*</span>
                </label>
                <Select2
                  name="partner"
                  options={list?.partnerList?.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={formik.values.partner}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("letter_address")}</label>
                <Textarea
                  name="mailingDestination"
                  rows="6"
                  name="target"
                  errors={formik.errors}
                  touched={formik.touched}
                  {...formik.getFieldProps("mailingDestination")}
                  disabled
                  same={
                    historydata?.mailingDestination
                      ? historydata.mailingDestination ===
                        formik.values?.mailingDestination
                      : true
                  }
                />
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label className="form-label">
                      {t("number_of_attachments")}
                    </label>
                    <div className="input-group">
                      <input
                        name="totalAppendix"
                        type="number"
                        min={0}
                        className="form-control"
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("totalAppendix")}
                        disabled={isDisabledForm}
                        same={
                          historydata?.totalAppendix
                            ? historydata.totalAppendix ===
                              formik.values?.totalAppendix
                            : true
                        }
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {t("attachment_hal")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-right">
            <ToggleDisplay>
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t("cancel")}
                onClick={(event) => this.handleCancelClick(event)}
              />
            </ToggleDisplay>
            <ToggleDisplay>
              <Button text={t("next")} onClick={this.props.handleNextClick} />
            </ToggleDisplay>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReportInformation);

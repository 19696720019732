import React from 'react';
import { connect } from 'formik';

function ValidationError(props) {
    const { callback, handleError,  formik } = props;
    
    const effect = () => {

        if (formik.submitCount > 0 && formik.isValid === false) {
            
            handleError();
        }
    };
    React.useEffect(effect, [formik.submitCount, formik.isSubmitting]);

    return null;
}
export default connect(ValidationError);
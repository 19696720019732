import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";

import * as Utils from "../../../helpers/Utils";
import Button from "../../../components/Utils/Button/Button";
import { Role, Status } from "../../../helpers/Constants";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Tab from "../../../components/Utils/Tab/Tab";

import { AEFIService } from "../../../services/CaseEntry/AEFIService";
import { AEService } from "../../../services/CaseEntry/AEService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { InitialService } from "../../../services/Manufacture/InitialService";
import { ManufactureService } from "../../../services/Master/ManufactureService";
import { authenticationService } from "../../../services/AuthService";
import { HistoryService } from "../../../services/HistoryService";
import { LetterService } from "../../../services/Master/LetterService";

import ReportInformation from "./Tab/ReportInformation";
import LetterInformation from "./Tab/LetterInformation";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";
import { LetterModal } from "./LetterModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { withTranslation } from "react-i18next";

class DetailInitial extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;
    // const { page } = this.props?.location?.state
    // ? this.props?.location?.state
    // : 1;

    this.state = {
      id: id,
      caseCategory: "KIPI",
      snackbaropen: false,
      snackbarmsg: "",
      historyForm: [],
      caseEntryData: [],
      caseNumberList: [],
      manufactureList: [],
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      historys: [],
      historysCaseEntry: [],
      currentTab: "report-information",
      nextTab: "letter-information",
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.loadData(id);
    this.historyList(id, this.props.typecase);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  historyList(id, type) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {      
      HistoryService.Read(id, 'AEFI').then((result) => {
          this.setState({ historysCaseEntry: result.data });          
      });      
  }

  loadData(id) {
    InitialService.PrepareForm(id).then((result) => {
      if (result.data[0].aeCaseEntry) {
        AEService.Read("", 1, 0, "", "", "", "", "", result.data[0].caseEntry).then(
          (result) => {
            this.setState({
              caseNumberList: result.data.records,
            });
          }
        );
      } else {
        AEFIService.Read(
          "",
          1,
          0,
          "",
          "",
          "",
          "",
          "",
          result.data[0].caseEntry
        ).then((result) => {
          this.setState({
            caseNumberList: result.data.records,
          });
        });
      }
      this.setState({
        data: result.data[0],
      });      
      this.historyCaseEntryList(result.data[0].caseEntry)
    });

    LetterService.Read(
      this.state.page,
      this.state.limit,
      this.state.query == undefined ? "" : this.state.query
    ).then((result) => {
      this.setState({
        letter: {
          code: result?.data.records[0].code,
          shortName: result?.data.records[0].shortName,
        },
      });
    });
  }

  componentDidMount() {
    DropdownService.MasterList(["outcome"]).then((result) => {
      this.setState({ list: result.data });
    });

    ManufactureService.Read(0, 10, "").then((result) => {
      this.setState({ manufactureList: result.data.records });
    });
  }

  handleBackClick(event) {
    if (this.state.currentTab == "report-information") {
      this.props.history.push(`/report-submission/manufacture`, {
        tab: "Initial",
        page: this.props?.location?.state?.page,
      });
    } else {
      document.getElementById("link-" + this.state.backTab).click();
    }
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push("/medical-review/resume", {
      page: this.props?.location?.state?.page,
    });
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleReject(role) {
    if (role === Role.Unit) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        RejectedModal: true,
        RejectedModalConfirmationModal: false,
      });
    }
  }

  handleAccept(role) {
    if (role === Role.Unit) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
    if (role === Role.Divisi) {
      this.setState({
        ForwardedModal: true,
        ForwardedConfirmationModal: false,
      });
    }
  }

  handleCaseCategoryChange(item) {
    if (item === "KIPI") {
      AEFIService.Read("", 1, 0).then((result) => {
        this.setState({
          caseNumberList: result.data.records,
        });
      });
    } else {
      AEService.Read("", 1, 0).then((result) => {
        this.setState({
          caseNumberList: result.data.records,
        });
      });
    }
  }

  handleCaseNumberChange(formik, value) {
    const filtered = this.state.caseNumberList.filter(
      (item) => item.caseNumber === value
    )[0];
    let products = [];
    let symptomps = "";

    if (this.state.caseCategory === "KIPI") {
      if (filtered?.aefiVaccine != null) {
        products = filtered?.aefiVaccine.map((idx) => ({
          product: idx.vaccineNavigation.id,
          productName: idx.vaccineNavigation?.name,
          batch: idx.batch,
        }));
      }
    } else {
      if (filtered?.aeDrugs != null) {
        products = filtered?.aeDrugs.map((idx) => ({
          product: idx.drugsNavigation.id,
          productName: idx.drugsNavigation?.name,
          batch: "",
        }));
      }
    }

    if (this.state.caseCategory === "KIPI") {
      symptomps = filtered?.aefiSymptomps
        .map((idx) => idx.symptompsNavigation?.name)
        .join(", ");

      symptomps +=
        ", " +
        filtered?.aefiAllergic
          ?.map((idx) => idx.allergicNavigation?.name)
          ?.join(", ");
    } else {
    }

    formik.setFieldValue("manufactureSubmissionProduct", products);
    formik.setFieldValue("caseNumber", value);
    formik.setFieldValue("complaintNo", value);
    formik.setFieldValue("complaintFrom", filtered?.reporterName);
    formik.setFieldValue("initialName", filtered?.patientName);
    formik.setFieldValue("patientGender", filtered?.patientGender);
    formik.setFieldValue("ageOfYear", filtered?.patientAgeYear);
    formik.setFieldValue("ageOfMonth", filtered?.patientAgeMonth);
    formik.setFieldValue(
      "immunizationDate",
      Utils.getDate(filtered?.lastImmunizationDate)
    );
    formik.setFieldValue("clinicalSymptoms", symptomps);
    formik.setFieldValue("outcome", filtered?.outcomeNavigation?.id);
    formik.setFieldValue("caseEntry", filtered?.id);
    formik.setFieldValue("onsetDate", Utils.getDate(filtered?.onsetDate));
    formik.setFieldValue("reportedDate", Utils.getDate(filtered?.reportedDate));
    formik.setFieldValue("mailingDate", Utils.getDate(filtered?.mailingDate));
  }

  handleTabChange(event, tab) {
    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "letter-information",
        });
        break;
      case "#letter-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "letter-information",
          backTab: "report-information",
          nextTab: "",
          externalDisabled: false,
        });
        break;
    }

    event.preventDefault();
  }

  render() {
    const initials = this.state.data;

    const { t } = this.props;
    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (initials?.status === Status.Pending ||
        initials?.status === Status.Draft ||
        initials?.status === Status.NotOk ||
        initials?.status === Status.NotApproved ||
        initials?.status == Status.Approved ||
        this.props.isFollowUp);
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (initials?.status === Status.Draft ||
        initials?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (initials?.status === Status.Draft ||
        initials?.status === Status.Reviewed);

    const filtered = this.state.caseNumberList.filter(
      (item) => item.caseNumber === initials?.caseNumber
    )[0];
    let products = [];
    let allergic = [];
    let symptoms = [];
    let symptomps = "";

    if (initials) {
      if (initials?.aefiCaseEntry) {
        products = filtered?.aefiVaccine.map((idx) => ({
          product: idx.vaccineNavigation.id,
          productName: idx.vaccineNavigation?.name,
          batch: idx.batch,
          manufacture: idx.vaccineNavigation?.manufactureNavigation.name,
        }));

        if (initials?.aefiAllergic != null) {
          allergic = filtered?.aefiAllergic.map((idx) => ({
            allergic: idx.allergic,
            date: idx.date,
            durationMinute: idx.durationMinute,
            durationHour: idx.durationHour,
            durationDay: idx.durationDay,
          }));
        }

        if (initials?.aefiSymptomps != null) {
          symptoms = filtered?.aefiSymptomps.map((idx) => ({
            symptoms: idx.symptomps,
            date: idx.date,
            durationMinute: idx.durationMinute,
            durationHour: idx.durationHour,
            durationDay: idx.durationDay,
          }));
        }
      } else if (initials?.aeCaseEntry) {
        products = filtered?.aeDrugs.map((idx) => ({
          product: idx.drugsNavigation.id,
          productName: idx.drugsNavigation?.name,
          batch: "",
          manufacture: "",
        }));
      }
    }

    if (initials?.aefiCaseEntry) {
      if (
        Array.isArray(filtered?.aefiSymptomps) &&
        filtered?.aefiSymptomps.length > 0
      ) {
        symptomps = filtered?.aefiSymptomps
          .map((idx) => idx.symptompsNavigation?.name)
          .join(", ");

        symptomps +=
          ", " +
          filtered?.aefiAllergic
            ?.map((idx) => idx.allergicNavigation?.name)
            ?.join(", ");
      }
    } else {
    }

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };
    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };
    let LetterModalClose = () => {
      this.setState({
        letterShow: false,
      });
    };

    const initial = {
      id: initials?.id,
      caseEntry: initials?.caseEntry,
      caseNumber: initials?.caseNumber,
      caseEntryDate: initials?.caseEntryDate,
      reportedDate: initials?.reportedDate,
      typeCaseEntry: initials?.aeCaseEntry ? "KTD" : "KIPI",
      caseCategory: initials?.aeCaseEntry ? "KTD" : "KIPI",
      status: initials?.status,
      mailingPlace: initials?.mailingPlace,
      mailingOrder: initials?.mailingOrder,
      mailingMonth: initials?.mailingMonth,
      mailingYear: initials?.mailingYear,
      mailingDate: initials?.mailingDate,
      manufacture: initials?.manufacture,
      target: this.state.manufactureList
        ?.filter((item) => item.id === initials?.manufacture)
        .map((item) => item.destination),
      complaintNo: initials?.caseNumber,
      complaintFrom: filtered?.reporterName,
      initialName: filtered?.patientName,
      patientGender: filtered?.patientGender,
      ageOfYear: filtered?.patientAgeYear,
      ageOfMonth: filtered?.patientAgeMonth,
      caseEntryDate: Utils.getDate(filtered?.onsetDate),
      symptomsDate: Utils.getDate(
        filtered?.aefiSymptomps && filtered.aefiSymptomps.length > 0
          ? filtered?.aefiSymptomps[0].date
          : filtered?.onsetDate
      ),
      reportedDate: Utils.getDate(filtered?.reportedDate),
      immunizationDate: Utils.getDate(initials?.immunizationDate),
      clinicalSymptoms: symptomps,
      outcome: filtered?.outcomeNavigation?.id,
      caseEntry: filtered?.id,
      manufactureSubmissionProduct: products,
      manufactureSubmissionAllergic: allergic,
      manufactureSubmissionSymptoms: symptoms,
      letterNumber1: this.state.letter?.code,
      letterNumber3: this.state.letter?.shortName,
      mailingSentDate: initials?.mailingSentDate,
      seriousness: filtered?.seriousness,
      originalRecapLetter: initials?.originalRecapLetterArr ?? [],
    };

    const disabledLetter =
      initial?.status === Status.Approved || initial?.status === Status.Closed
        ? false
        : true;

    /** Handling required form inputs begin */

    const initialTouched = {
      caseCategory: true,
      caseNumber: true,
      manufacture: true,
      mailingPlace: true,
      mailingDate: true,
    };

    const validationSchemaDict =
      initials?.status === Status.Approved
        ? {
            "report-information": Yup.object().shape({
              caseCategory: Yup.string().required("Required").nullable(),
              caseNumber: Yup.string().required("Required").nullable(),
              manufacture: Yup.string().required("Required").nullable(),
              mailingPlace: Yup.string().required("Required").nullable(),
              mailingDate: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "letter-information": Yup.object().shape({}),
          };

    /** Handling required form input ends */

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />

        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          manufacture={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          type="manufacture-initial"
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          type="manufacture-initial"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          type="manufacture-initial"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  {/* <Select
                                        name="version"
                                        options={this.state.versionOptions}
                                        value={this.state.selectedVersion}
                                        onChange={(item) => this.handleVersionChange(item)}
                                        classNamePrefix="select"
                                        className='basic-single'
                                    /> */}

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
            </div>
            <ul className="nav nav-justified mb-3 menu-tab">
              <Tab
                number="1"
                showNumber={true}
                title={t("case_information")}
                href="#report-information"
                onClick={(event) =>
                  this.handleTabChange(event, "#report-information")
                }
                id="link-report-information"
              />
              {disabledLetter && (
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("letter_information")}
                  href="#letter-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#letter-information")
                  }
                  id="link-letter-information"
                  tooltip="Data Informasi Surat baru bisa diinputkan oleh Staff ketika sudah di approve"
                  disabled={disabledLetter}
                />
              )}
              {!disabledLetter && (
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("letter_information")}
                  href="#letter-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#letter-information")
                  }
                  id="link-letter-information"
                  disabled={disabledLetter}
                />
              )}
            </ul>
          </div>
        </div>

        <Formik
          innerRef={this.formikRef}
          enableReinitialize
          initialValues={initial}
          initialTouched={initialTouched}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            InitialService.Update(this.state.id, values).then(
              (result) => {
                toastSuccess(t("success_add_message"));
                this.props.history.push(`/report-submission/manufacture`, {
                  tab: "Initial",
                  page: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <LetterModal
                  show={this.state.letterShow}
                  onHide={LetterModalClose}
                  formik={formik}
                  type="initial"
                  t={t}
                />

                <ReportInformation
                  formik={formik}
                  historydata={this.state.historyForm}
                  caseCategory={this.state.caseCategory}
                  caseEntry={this.state.caseEntryData}
                  caseNumberList={this.state.caseNumberList}
                  manufactureList={this.state.manufactureList}
                  caseCategoryList={this.state.caseCategoryList}
                  letterShow={this.state.letterShow}
                  list={this.state.list}
                  history={this.props.history}
                  isFollowUp={this.props.isFollowUp}
                />

                <LetterInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                />

                <div className="text-right">
                  <ToggleDisplay>
                    <Button
                      color="#495057"
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      border="1px solid #495057"
                      text={
                        this.state.currentTab == "report-information"
                          ? t("cancel")
                          : t("back")
                      }
                      onClick={(event) => this.handleBackClick(event)}
                    />
                  </ToggleDisplay>

                  {this.state.currentTab == "report-information" &&
                    !disabledLetter && (
                      <ToggleDisplay>
                        <Button
                          text={t("next")}
                          onClick={(event) => this.handleNextClick(event)}
                        />
                      </ToggleDisplay>
                    )}

                  {/* Simpan */}
                  {canStaffEdit &&
                    (this.state.currentTab == "letter-information" ||
                      (this.state.currentTab == "report-information" &&
                        disabledLetter)) && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          isSubmitting={formik.isSubmitting}
                        />
                      </ToggleDisplay>
                    )}

                  <input
                    name="isApprove"
                    type="hidden"
                    {...formik.getFieldProps("isApprove")}
                  />
                  <input
                    name="isReview"
                    type="hidden"
                    {...formik.getFieldProps("isReview")}
                  />

                  {/* Reject */}
                  {(canUnitEdit || canDivisiEdit) && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        background="#e15756 0% 0% no-repeat padding-box"
                        text="Reject"
                        onClick={() => {
                          if (canUnitEdit) {
                            formik.setFieldValue("isReview", false);
                          }
                          if (canDivisiEdit) {
                            formik.setFieldValue("isApprove", false);
                          }
                          this.setState({ RejectedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {/* Forward/Approve */}
                  {(canUnitEdit || canDivisiEdit) && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        text={
                          currentUser.role === Role.Unit ? "Forward" : "Approve"
                        }
                        onClick={() => {
                          if (canUnitEdit) {
                            formik.setFieldValue("isReview", true);
                          }
                          if (canDivisiEdit) {
                            formik.setFieldValue("isApprove", true);
                          }
                          this.setState({ ForwardedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {formik.status && (
                    <div className={"alert alert-danger"}>{formik.status}</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailInitial);

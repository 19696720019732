import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal as ModalBootstrap } from 'react-bootstrap';

const ModalBodyStyled = styled(ModalBootstrap.Body).attrs(props => ({ ...props }))`
    text-align: left;
    font: normal normal normal 20px/24px Montserrat;
    letter-spacing: 0px;
    color: #495057;
    opacity: 1;
    padding-left: 48px;
    padding-right: 48px;
`

export default class ModalBody extends Component {

    render() {
        return (
            <ModalBodyStyled>
                {this.props.children}
            </ModalBodyStyled>
        );
    }
}

import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const AEService = {
    Read,
    ReadForQualityReview,
    ReadForBpomProgress,
    PrepareBook,
    SubmitBook,
    PrepareForm,
    SubmitForm,
    SendMail,
    ReviewForm,
    ApproveForm,
    Delete,
    CheckDuplicate,
    CheckSimilar,
    Download,
    DownloadDoc,
    DownloadCIOMS
};

async function Read(
    filter = "",
    page = 1,
    limit = 10,
    query = "",
    drugs = "",
    status = "",
    startDate = "",
    endDate = "",
    id = "",
    isAnalysisAvailable = "",
    isQualityReviewAvailable = "",
    isManufactureInitialAvailable = "",
    isManufactureGrowthAvailable = "",
    isPartnerAvailable = "",
    isReporterAvailable = "",
    isSummaryAvailable = "", 
    readyToAnalise = false,
) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/case-entry/ae/read?filter=${filter}&page=${page}&limit=${limit}&query=${query}&drugs=${drugs}&status=${status}&startDate=${startDate}&endDate=${endDate}&id=${id}&isAnalysisAvailable=${isAnalysisAvailable}&isQualityReviewAvailable=${isQualityReviewAvailable}&isManufactureInitialAvailable=${isManufactureInitialAvailable}&isManufactureGrowthAvailable=${isManufactureGrowthAvailable}&isPartnerAvailable=${isPartnerAvailable}&isReporterAvailable=${isReporterAvailable}&isSummaryAvailable=${isSummaryAvailable}&readyToAnalise=${readyToAnalise}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadForQualityReview(isQualityReviewAvailable = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/case-entry/ae/read?isQualityReviewAvailable=${isQualityReviewAvailable}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadForBpomProgress(isBpomProgressAvailable = "",caseNumber = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/case-entry/ae/read?limit=10&page=1&isBpomProgressAvailable=${isBpomProgressAvailable}&query=${caseNumber}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

function PrepareBook() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`api/v1/case-entry/ae/prepare-book`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function SubmitBook(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/submit-book`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`api/v1/case-entry/ae/prepare-form/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function SubmitForm(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/submit-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function SendMail(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/send-mail/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function ReviewForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/review/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function ApproveForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/approve/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`api/v1/case-entry/ae/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function CheckDuplicate(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/check-duplicate-field/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function CheckSimilar(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/case-entry/ae/check-similar-field/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Download(id, casenumber, type) {
    
    let url = type === 'ID' ? '/api/v1/case-entry/ae/download/pdf/' : '/api/v1/case-entry/ae/download/pdf-en/'
    let name = type === 'ID' ? `KTD dengan nomor kasus ${casenumber}.pdf` :  `AE with case number ${casenumber}.pdf`

    fetch(url + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadDoc(id, casenumber, type) {

    let url = type === 'ID' ? '/api/v1/case-entry/ae/download/doc/' : '/api/v1/case-entry/ae/download/doc-en/'
    let name = type === 'ID' ? `KTD dengan nomor kasus ${casenumber}.docx` :  `AE with case number ${casenumber}.docx`

    fetch(url + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadCIOMS(id, casenumber, type) {

    let url = type === 'DOC' ? '/api/v1/cioms/doc/' : '/api/v1/cioms/pdf/'
    let name = type === 'DOC' ? `CIOMS ${casenumber}.docx` :  `CIOMS ${casenumber}.pdf`

    fetch(url + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
        })
}
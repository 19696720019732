import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const LetterService = {
    Read,
    Update,
};

async function Read(page = 1, limit = 10, query) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master-letter/read?page=${page}&limit=${limit}&query=${query}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

function Update(value) {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`api/v1/master-letter/update/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { MarketAutService } from '../../services/MarketAut/MarketAutService';

import FormMarketAut from './FormMarketAut';
import { withTranslation } from "react-i18next";

export class AddMarketAut extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
            marketAuthorizationCountry: [],
            marketAuthorizationInstitution: [],
        }
        // this.getData();
    }

    getData() {
        this.state.marketAuthorizationCountry = [{ country: null, actionDate: null, launchDate: null, tradeName: null }];
        this.state.marketAuthorizationInstitution = [{ institution: null, country: null, actionDate: null, launchDate: null, tradeName: null, comment: null }];

        MarketAutService.Products().then(result => {
            this.setState({
                productList: result.data.map(item => { return { value: item.id, label: item.name, type: item.type }; })
            });
        });
    }

    cancel = () => {
        this.props.history.push(`/market-authorization`);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            product: null,
                        }}

                        validationSchema={
                            Yup.object().shape({
                                product: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {

                            values.marketAuthorizationCountry = values.marketAuthorizationCountry?.filter(obj => { return obj != null });

                            MarketAutService.Create(values).then(

                                (result) => {
                                    toastSuccess('Berhasil menambahkan data');
                                    this.props.history.push(`/market-authorization`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                        (
                            <Form>
                                <div className="tab-content">
                                    <FormMarketAut
                                        formik={formik}
                                        marketAuthorizationCountry={this.state.marketAuthorizationCountry}
                                        marketAuthorizationInstitution={this.state.marketAuthorizationInstitution}
                                        productList={this.state.productList}
                                    />
                                </div>
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={() => this.cancel()}
                                        />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t('save')}
                                            disabled={formik.isSubmitting}
                                        />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>
                            </Form>
                        )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(AddMarketAut);
import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import { Role, Status } from '../../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class MarketAuthorization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {

        const { formik, typecase, historydata, list, t, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";

        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;
        const disabled = this.props.isLastVersion === undefined  ? false : this.props.formType === "edit" || this.props.isLastVersion !== undefined;
        
        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="market-authorization">
                    <div className="row">
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">COUNTRY</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Country</th>
                                                <th>Action Date</th>
                                                <th>Launch Date</th>
                                                <th>Trade Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.psurMarketAuthorizationCountry?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{record?.countryNavigation?.name}</td>
                                                    <td>{record?.actionDate ? moment(record?.actionDate).format('DD/MM/yyyy') : "-"}</td>
                                                    <td>{record?.launchDate ? moment(record?.launchDate).format('DD/MM/yyyy') : '-'}</td>
                                                    <td>{record?.tradeName ?? '-'}</td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.psurMarketAuthorizationCountry === undefined || formik.values?.psurMarketAuthorizationCountry === null || formik.values?.psurMarketAuthorizationCountry?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">INSTITUTION</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Institution</th>
                                                <th>Country</th>
                                                <th>Action Date</th>
                                                <th>Launch Date</th>
                                                <th>Trade Name</th>
                                                <th>Comment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.psurMarketAuthorizationInstitution?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{record?.institutionNavigation?.name}</td>
                                                    <td>{record?.countryNavigation?.name}</td>
                                                    <td>{record?.actionDate ? moment(record?.actionDate).format('DD/MM/yyyy') : "-"}</td>
                                                    <td>{record?.launchDate ? moment(record?.launchDate).format('DD/MM/yyyy') : '-'}</td>
                                                    <td>{record?.tradeName ?? '-'}</td>
                                                    <td>{record?.comment ?? '-'}</td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.psurMarketAuthorizationInstitution === undefined || formik.values?.psurMarketAuthorizationInstitution === null || formik.values?.psurMarketAuthorizationInstitution?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(MarketAuthorization);
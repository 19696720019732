import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import { AddSOCModal } from './AddSOCModal';
import { EditSOCModal } from './EditSOCModal';
import { SOCService } from '../../../services/Master/SOCService';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import { DeleteModal } from '../../../components/Utils/Modal/DeleteModal';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import { Loader } from '../../../components/Utils/Loader/Loader';

import { withTranslation } from "react-i18next";

class SOC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            addModalShow: false,
            editModalShow: [],
            deleteModalShow: false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            loading: false
        }
    }

    componentDidMount() {
        this.refreshList();
    }

    deleteRecord(id) {

        const { t } = this.props;

        this.setState({ loading: true })

        SOCService.Delete(id).then(

            (result) => {
                this.setState({ loading: false })
                toastSuccess(t('success_delete_message'));
                this.refreshList();
                this.setState({ deleteModalShow: false })
            },
            (error) => {
                this.setState({ loading: false })
                toastError(error);
            }
        )
    }

    handlePageReset(){
        this.setState({page : 1}, () => {
            this.refreshList();
        })
    }

    refreshList() {
        this.setState({ loading: true })
        SOCService.Read(this.state.page, this.state.limit, this.state.query).then(result => {
            this.setState({
                maxPage: result.data.maxPage,
                records: result.data.records,
                editModalShow: new Array(result.data.length).fill(false),
                loading: false
            });
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.handlePageReset();
        event.preventDefault();
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    };
    handleDelete(id) {
        this.setState({
            record_id: id,
            deleteModalShow: true
        })
    }
    handleLoading(status) {
        this.setState({ loading: status })
    }

    render() {

        const { t } = this.props;

        const { records, id, name } = this.state;

        let addModalClose = () => {
            this.setState({
                addModalShow: false
            })
            this.refreshList()
        };
        let editModalClose = () => {
            this.setState({
                editModalShow: new Array(this.state.records.length).fill(false)
            })
            this.refreshList()
        };

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }
                    

                <AddSOCModal
                    show={this.state.addModalShow}
                    onHide={addModalClose} 
                    t={t}
                />
                <DeleteModal
                    show={this.state.deleteModalShow}
                    onHide={() => this.setState({ deleteModalShow: false })}
                    confirmdelete={() => this.deleteRecord(this.state.record_id)}
                    message={t('delete_confirmation')}
                    t={t}
                />
                    
                <div className="row">
                    <div className="col-md-3" style={{marginBottom : 15}}>
                        <ButtonWithIcon
                            text={t('add') + " SOC"}
                            icon="fa fa-plus"
                            onClick={() => this.setState({ addModalShow: true })} 
                        />
                    </div>
                    <div className="col-md-5">

                    </div>
                    <div className="col-md-4">
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div className="right">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.query}
                                            onChange={(event) => this.handleSearchChange(event)} 
                                            placeholder={t('keyword')}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search"/></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Table className="pb-5" hover>
                    <thead>
                        <tr>
                            <th width="5%">No</th>
                            <th>System Organ Class</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) =>
                            <tr key={record.id}>
                                <td>{index + 1 + (this.state.page-1) * this.state.limit}</td>
                                <td>{record.name}</td>
                                <td>
                                    <div class="btn-group">
                                        <button class="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" onClick={() => {
                                                this.state.editModalShow[index] = true
                                                this.setState({ id: record.id, name: record.name })
                                            }}>Edit</a>
                                            <a className="dropdown-item" onClick={() => this.handleDelete(record.id)}>
                                                Delete
                                            </a>

                                        </div>
                                    </div>

                                    <EditSOCModal
                                        show={this.state.editModalShow[index]}
                                        onHide={editModalClose}
                                        id={id}
                                        name={name} 
                                        t={t}
                                    />
                                </td>
                            </tr>
                        )}
                        {
                            records.length < 1 && (
                                <tr>
                                    <td colSpan="3" style={{textAlign:"center"}}>
                                        {t('data_not_found')}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <nav>
                    <ReactPaginate
                        previousLabel={(<i class="fas fa-arrow-left"></i>)}
                        previousLinkClassName={"page-link"}
                        nextLabel={(<i class="fas fa-arrow-right"></i>)}
                        nextLinkClassName={"page-link"}
                        pageCount={this.state.maxPage}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        breakClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        onPageChange={(event) => this.handlePageClick(event)}
                        forceSelected={(this.state.page)-1}
                        forcePage={(this.state.page)-1}
                        initialPage={(this.state.page)-1}
                    />
                </nav>

            </>
        )
    }

}

export default withTranslation()(SOC)

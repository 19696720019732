import { handleResponse, authHeader } from "../../helpers/RequestHelper";

export const DrugsService = {
  Create,
  Read,
  Update,
  Delete,
  PrepareForm,
  SyncProduct,
};

function Create(value) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(value),
  };

  return fetch(`api/v1/master-drugs/create`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function Read(page = 1, limit = 10, query) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(
    `api/v1/master-drugs/read?page=${page}&limit=${limit}&query=${query}`,
    requestOptions
  );
  data = await handleResponse(data);
  return data;
}

function Update(id, value) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(value),
  };

  return fetch(`api/v1/master-drugs/update/` + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function Delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`api/v1/master-drugs/delete/` + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function PrepareForm() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(`api/v1/master-drugs/form-data`, requestOptions);
  data = await handleResponse(data);
  return data;
}

async function SyncProduct() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(`/api/v1/biof-ws/sync-products`, requestOptions);
  data = await handleResponse(data);
  return data;
}

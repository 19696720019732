import React, { Component } from 'react';

export class ProgressBar extends Component {

    render() {
        return (
            <div style={{marginTop: 15, padding: 5}}>
                <div className="row">
                    <div className="col-6">
                        <div style={{float:"left"}}><p style={{fontSize: 14, color: "#ADB5BD"}}>{this.props.title}</p></div>
                    </div>
                    <div className="col-6">
                        <div style={{float:"right"}}><p style={{fontSize: 14, color: "#ADB5BD"}}>{this.props.value}</p></div>
                    </div>
                    <div className="col-12">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: `${this.props.percent ? this.props.percent : '100%' }`, background: `${this.props.color ? this.props.color : "#00ABBD" }`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
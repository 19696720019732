import { handleResponse, authHeader } from '../helpers/RequestHelper';

export const CaseCommentReportService = {
    Write,
    Read,
};

async function Write(type, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`/api/v1/comments/report-submission/${type}/create`, requestOptions);
    data = await handleResponse(data);
    return data;
}

async function Read(type, id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    let data = await fetch(`/api/v1/comments/report-submission/${type}/read/?id=` + id, requestOptions);
    data = await handleResponse(data);
    return data;
}

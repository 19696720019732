import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const AnnualAEFIService = {
    Read,
    Download,
    ReadGraph
};



async function Read(page = 1, limit = 10, query, yearType = "") {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/summary-report/annual-aefi?page=${page}&limit=${limit}&query=${query}&yearType=${yearType}`

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function Download(yearType = "") {
    fetch('/api/v1/summary-report/annual-aefi/xls?yearType=' + yearType, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        
    .then(response => {
        var filename = "";
        var disposition = response.headers.get("content-disposition");
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        response.blob().then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
    })
}

async function ReadGraph(yearType = "", caseCategory = "", regionType = "") {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/summary-report/annual-aefi-graph?yearType=${yearType}&caseCategory=${caseCategory}&regionType=${regionType}`

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}





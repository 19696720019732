import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../../components/Utils/Button/Button';
import ButtonWithIcon from '../../../../components/Utils/Button/ButtonWithIcon';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import Select2Multi from '../../../../components/Utils/Field/Select2Multi';
import * as Utils from '../../../../helpers/Utils';

class Classification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidMount(){
        this.setState({causalityRelation : this.props.list?.causalityAEFIComplete})
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

        if(prevProps.formik.values.causalityRelation !== this.props.formik.values.causalityRelation){
            this.props.formik.setFieldValue("causalities", [])
        }

    }

    render() {
        const { formik, typecase, history, data, list, causality, caseEntry, disabled, t, i18next, ...otherProps } = this.props;
        const { causalityRelation } = this.state;

        let classificationConcluded = history?.classificationConcluded === true ? "Yes" : "No";  

        let causalitiesHistory = [];

        if(history!== undefined && causality){

            if(history?.analysisCausalityUpv?.length > 0 || history?.trailAnalysisCausalityUpv){
                let causalityUpv = history?.analysisCausalityUpv ? history?.analysisCausalityUpv : history?.trailAnalysisCausalityUpv
                causalityUpv.map((record, index) => {
                    causalitiesHistory = [...causalitiesHistory, record.causality]
                })
            }
        }

        return (
            <>
                <div className="tab-pane" id="classification">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('causality_relation')}</label>
                                <Select2
                                    name="causalityRelation"
                                    options={causalityRelation?.map(item => { return { value: item.id, label: i18next.language === 'id' || i18next.language === 'ID' ? item.name : item.nameEnTranslate }; })}
                                    value={formik.values?.causalityRelation}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled={disabled}
                                    same={history?.causalityUpv ? history.causalityUpv === formik.values?.causalityRelation : true }
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                {
                                    formik.values.causalityRelation === "f3efe9ef-55da-451b-95da-0d57fabde2b2" && list?.causalityAEFIA.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length > 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )
                                }
                                {
                                    formik.values.causalityRelation === "1d3dfa41-ad04-4d96-8128-8171cf9ffffb" && list?.causalityAEFIB.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length > 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }> 
                                                            <input type="checkbox" className="form-check-input" 
                                                                name={"causalities"} 
                                                                value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )
                                }
                                {
                                    formik.values.causalityRelation === "0c1b9b34-fbf7-4aee-b29c-2c3a783c9918" && list?.causalityAEFIC.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length > 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{record.name}</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="form-label" style={{ fontSize: 16 }}>{t('conclusion_of_classification')}</label>
                            <div className="form-group">
                                <label className="form-label">{t('can_concluded')}</label>
                                <div className={history?.classificationConcluded ? ((classificationConcluded === formik.values?.classificationConcluded) === false ? "change-value-radio" : "" ) : "" }>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" 
                                            value="Yes" 
                                            name="classificationConcluded"
                                            checked={formik.values.classificationConcluded === "Yes" }
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('yes')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" 
                                            value="No" 
                                            name="classificationConcluded"
                                            checked={formik.values.classificationConcluded === "No"}
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('no')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                {
                                    formik.values.classificationConcluded === "Yes" ? (
                                        <label className="form-label">{t('concluded_classification')}</label>
                                    ):(
                                        <label className="form-label">{t('cannot_concluded_classification')}</label>  
                                    )
                                }
                                <Textarea
                                    rows="6"
                                    name="classificationConclusion"
                                    placeholder=""
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values?.classificationConclusion ? formik.values?.classificationConclusion : ""}
                                    disabled={disabled}
                                    {...formik.getFieldProps('classificationConclusion')} 
                                    same={history?.classificationConclusion ? history.classificationConclusion === formik.values?.classificationConclusion : true }
                                />
                            </div>
                            {
                                    formik.values.classificationConcluded === "Yes" && (
                                        <div className="form-group">
                                            <label className="form-label">{t('because_of')}</label>
                                            <Textarea
                                                rows="6"
                                                name="classificationReason"
                                                placeholder=""
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.classificationReason ? formik.values?.classificationReason : ""}
                                                disabled={disabled}
                                                {...formik.getFieldProps('classificationReason')} 
                                                same={history?.classificationReason ? history.classificationReason === formik.values?.classificationReason : true }
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('seriousness')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="seriousness"
                                    options={list?.seriousnessList?.map(item => { return { value: item.id, label: item.name };})}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.seriousness}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                               />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('expectedness')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="expectedness"
                                    options={list?.expectednessList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.expectedness}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('outcome')} <span style={{color: "red"}}>*</span></label>
                                <Select2
                                    name="outcome"
                                    options={list?.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={caseEntry?.outcome}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default Classification;
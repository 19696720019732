
export const Role = {
    Administrator: 'ADMINISTRATOR',
    Staff: 'STAFF',
    Unit: 'UNIT',
    Divisi: 'DIVISI',
    RegulasiMutuSistem: 'REGULASI MUTU SISTEM',
    RegulasiMutu: 'REGULASI MUTU',
    Distribusi: 'DISTRIBUSI',
    Manager: 'MANAGER'
}

export const Status = {
    Pending: 'Pending',
    Draft: 'Draft',
    WaitingApproval: 'Waiting Approval',
    NotOk: 'Not Ok',
    Reviewed: 'Reviewed',
    NotApproved: 'Not Approved',
    Approved: 'Approved',
    Bridging: 'Bridging',
    Closed: 'Closed',

}

export const RoleName = {
    Administrator: "Administrator",
    DIVISI: "Kepala Divisi SUK",
    UNIT: "Kepala Unit Farmakovigilan",
    STAFF: "Staff Farmakovigilan",
    REGULASI_MUTU_SISTEM: "Operator Bagian Regulasi Mutu Sistem",
    DISTRIBUSI: "Operator Bagian Distribusi"
}

export const CaseEntryType = {
    UNKNOWN: 0,
    AE: 1,
    AEFI: 2
}

export const seriousness = {
    nonserius: "12345677788899665"
}

export const AeCaseEntryMailPurpose = {
    additional: "untuk request data tambahan pada kasus KTD pada nomor kasus",
    distribution: "untuk request data distribusi pada kasus KTD pada nomor kasus",
    qualityReview: "untuk request data quality review pada kasus KTD pada nomor kasus"
}

export const AefiCaseEntryMailPurpose = {
    additional: "untuk request data tambahan pada kasus KIPI pada nomor kasus",
    distribution: "untuk request data distribusi pada kasus KIPI pada nomor kasus",
    qualityReview: "untuk request data quality review pada kasus KIPI pada nomor kasus"
}

export const SeriousnessLevel = {
    NonSerius: "Non-serious"
}

export const CaseEntryAnalysisDeadline = {
    CompleteDeadline: 30,
    InCompleteClose: 30,
    InCompleteDeadline: 14,
}

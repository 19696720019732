import React, { Component } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { PrivateRoute } from './components/Utils/PrivateRoute/PrivateRoute';
import { Role } from './helpers/Constants';

import { PublicLayout } from './layouts/PublicLayout';
import { AdminLayout } from './layouts/AdminLayout';

import { Login } from './pages/Login/Login';
import { NotFound } from './pages/ErrorPage/NotFound';
import { AccessDenied } from './pages/ErrorPage/AccessDenied';

import { withTranslation } from "react-i18next";
import i18next from 'i18next';

import Dashboard from './pages/Dashboard/Dashboard';
import CaseEntry from './pages/CaseEntry/CaseEntry';
import AddCaseEntry from './pages/CaseEntry/AddCaseEntry';
import DetailCaseEntry from './pages/CaseEntry/DetailCaseEntry';
import Analysis from './pages/QualityReview/Analysis/Analysis';
import AddAnalysis from './pages/QualityReview/Analysis/AddAnalysis';
import DetailAnalysis from './pages/QualityReview/Analysis/DetailAnalysis';
import Resume from './pages/QualityReview/Resume/Resume';
import AddResume from './pages/QualityReview/Resume/AddResume';
import DetailResume from './pages/QualityReview/Resume/DetailResume';
import Summary from './pages/QualityReview/Summary/Summary';
import AddSummary from './pages/QualityReview/Summary/AddSummary';
import DetailSummary from './pages/QualityReview/Summary/DetailSummary';
import MarketAut from './pages/MarketAut/MarketAut';
import AddMarketAut from './pages/MarketAut/AddMarketAut';
import DetailMarketAut from './pages/MarketAut/DetailMarketAut';
import QualityReview from './pages/QualityReview/QualityReview/QualityReview';
import AddQualityReview from './pages/QualityReview/QualityReview/AddQualityReview';
import DetailQualityReview from './pages/QualityReview/QualityReview/DetailQualityReview';
import BPOM from './pages/ReportSubmission/BPOM/BPOM';
import AddProgressReport from './pages/ReportSubmission/BPOM/AddProgressReport';
import DetailProgressReport from './pages/ReportSubmission/BPOM/DetailProgressReport';
import AddKIPIOverseas from './pages/ReportSubmission/BPOM/AddKIPIOverseas';
import DetailKIPIOverseas from './pages/ReportSubmission/BPOM/DetailKIPIOverseas';
import AddKIPINonSerious from './pages/ReportSubmission/BPOM/AddKIPINonSerious';
import DetailKIPINonSerious from './pages/ReportSubmission/BPOM/DetailKIPINonSerious';
import ManufactureReport from './pages/ReportSubmission/Manufacture/Manufacture';
import AddInitial from './pages/ReportSubmission/Manufacture/AddInitial';
import DetailInitial from './pages/ReportSubmission/Manufacture/DetailInitial';
import AddProgress from './pages/ReportSubmission/Manufacture/AddProgress';
import DetailProgress from './pages/ReportSubmission/Manufacture/DetailProgress';
import Reporter from './pages/ReportSubmission/Reporter/Reporter';
import AddReporter from './pages/ReportSubmission/Reporter/AddReporter';
import DetailReporter from './pages/ReportSubmission/Reporter/DetailReporter';
import PartnerReport from './pages/ReportSubmission/Partner/Partner';
import AddPartner from './pages/ReportSubmission/Partner/AddPartner';
import DetailPartner from './pages/ReportSubmission/Partner/DetailPartner';
import PSUR from './pages/ReportSubmission/PSUR/PSUR';
import AddPSUR from './pages/ReportSubmission/PSUR/AddPSUR';
import DetailPSUR from './pages/ReportSubmission/PSUR/DetailPSUR';
import PBRER from './pages/ReportSubmission/PBRER/PBRER';
import AddPBRER from './pages/ReportSubmission/PBRER/AddPBRER';
import DetailPBRER from './pages/ReportSubmission/PBRER/DetailPBRER';
import { QA } from './pages/Reconciliation/QA/QA';
import { MSD } from './pages/Reconciliation/MSD/MSD';
import { Sanofi } from './pages/Reconciliation/Sanofi/Sanofi';
import { Biovac } from './pages/Reconciliation/Biovac/Biovac';
import { KomnasKIPI } from './pages/Reconciliation/KomnasKIPI/KomnasKIPI';
import Allergic from './pages/DataMaster/Allergic/Allergic';
import Department from './pages/DataMaster/Department/Department';
import Drugs from './pages/DataMaster/Drugs/Drugs';
import Institution from './pages/DataMaster/Institution/Institution';
import Source from './pages/DataMaster/Source/Source';
import Symptomps from './pages/DataMaster/Symptomps/Symptomps';
import Vaccinator from './pages/DataMaster/Vaccinator/Vaccinator';
import Vaccine from './pages/DataMaster/Vaccine/Vaccine';
import Manufacture from './pages/DataMaster/Manufacture/Manufacture';
import Partner from './pages/DataMaster/Partner/Partner';
import MasterRoute from './pages/DataMaster/Route/Route';
import Organization from './pages/DataMaster/Organization/Organization';
import Letter from './pages/DataMaster/Letter/Letter';
import SOC from './pages/DataMaster/SOC/SOC';
import PT from './pages/DataMaster/PT/PT';
import AppExternal from './pages/AppExternal/AppExternal';
import Reminder from './pages/Reminder/Reminder';
import AddReminder from './pages/Reminder/AddReminder';
import DetailReminder from './pages/Reminder/DetailReminder';
import VirusVaccineAEFI from './pages/SummaryReport/VirusVaccineAEFI';
import BacteriaVaccineAEFI from './pages/SummaryReport/BacteriaVaccineAEFI';
import CombinationVaccineAEFI from './pages/SummaryReport/CombinationVaccineAEFI';
import ConcomitantVaccination from './pages/SummaryReport/ConcomitantVaccination';
import Causality from './pages/SummaryReport/Causality';
import CaseArchival from './pages/SummaryReport/CaseArchival';
import DetailCaseArchival from './pages/SummaryReport/DetailCaseArchival';
import AnnualAEFI from './pages/SummaryReport/AnnualAEFI';
import ReporterReport from './pages/SummaryReport/Reporter'
import ClinicalTrial from './pages/ClinicalTrial/ClinicalTrial';
import AddClinicalTrial from './pages/ClinicalTrial/AddClinicalTrial';
import DetailClinicalTrial from './pages/ClinicalTrial/DetailClinicalTrial';
import ProductDistribution from './pages/ProductDistribution/ProductDistribution';
import AddProductDistribution from './pages/ProductDistribution/AddProductDistribution';
import DetailProductDistribution from './pages/ProductDistribution/DetailProductDistribution';
import AuditTrail from './pages/AuditTrail/AuditTrail';
import { NotificationMenu } from './components/Header/Notification/NotificationMenu';
import Profile from './pages/Profile/Profile';
import { LoginCTI } from './pages/LoginCTI/LoginCTI';

import "./App.css";

const theme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#00ABBD",
            contrastText: "#FFFFFF",

            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
});


class App extends Component {
    static displayName = App.name;

    render() {

        return (

            <ThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        <PublicLayout path='/' exact>
                            <Route component={Login} />
                        </PublicLayout>
                        <PublicLayout path='/login' exact>
                            <Route component={Login} />
                        </PublicLayout>
                        <PublicLayout path='/loginCTI' exact>
                            <Route component={LoginCTI} />
                        </PublicLayout>
                        <AdminLayout path='/dashboard' clear={true} title="Dashboard" exact>
                            <PrivateRoute path='/dashboard' component={Dashboard} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry' title="Case Entry" exact>
                            <PrivateRoute component={CaseEntry} roles={[Role.RegulasiMutuSistem, Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/add-aefi/:id' title={i18next.language === 'id' ? "Tambah KIPI" : "Add AEFI"} exact>
                            <PrivateRoute path='/case-entry/:typecase/add-aefi/:id' component={AddCaseEntry} roles={[Role.Staff]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/add-ae/:id' title={i18next.language === 'id' ? "Tambah KTD" : "Add AE"} exact>
                            <PrivateRoute path='/case-entry/:typecase/add-ae/:id' component={AddCaseEntry} roles={[Role.Staff]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/detail-aefi/:id' title={i18next.language === 'id' ? "Detail KIPI" : "Detail AEFI"} exact>
                            <PrivateRoute path='/case-entry/:typecase/detail-aefi/:id' component={DetailCaseEntry} roles={[Role.RegulasiMutuSistem, Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/detail-ae/:id' title={i18next.language === 'id' ? "Detail KTD" : "Detail AE"} exact>
                            <PrivateRoute path='/case-entry/:typecase/detail-ae/:id' component={DetailCaseEntry} roles={[Role.RegulasiMutuSistem, Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/:id' title={i18next.language === 'id' ? "Follow Up KIPI" : "Follow Up AEFI"} exact>
                            <PrivateRoute path='/case-entry/:typecase/:id' component={DetailCaseEntry} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/case-entry/:typecase/:id' title={i18next.language === 'id' ? "Follow Up KTD" : "Follow Up AE"} exact>
                            <PrivateRoute path='/case-entry/:typecase/:id' component={DetailCaseEntry} roles={[Role.Staff]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/analysis' title="Analysis" exact>
                            <PrivateRoute path='/medical-review/analysis' component={Analysis} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/analysis/add-analysis' title={i18next.language === 'id' ? "Tambah Analisis" : "Add Analysis"} exact>
                            <PrivateRoute path='/medical-review/analysis/add-analysis' component={AddAnalysis} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/analysis/:typecase/detail/:id' title="Detail Analysis" exact>
                            <PrivateRoute path='/medical-review/analysis/:typecase/detail/:id' component={DetailAnalysis} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/analysis/:typecase/follow-up/:id' title={i18next.language === 'id' ? "Tambah Follow Up" : "Add Follow Up"} exact>
                            <PrivateRoute path='/medical-review/analysis/:typecase/follow-up/:id' component={DetailAnalysis} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/resume' title="Resume" exact>
                            <PrivateRoute path='/medical-review/resume' component={Resume} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/resume/add-resume' title={i18next.language === 'id' ? "Tambah Resume" : "Add Resume"} exact>
                            <PrivateRoute path='/medical-review/resume/add-resume' component={AddResume} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/resume/detail/:id' title="Detail Resume" exact>
                            <PrivateRoute path='/medical-review/resume/detail/:id' component={DetailResume} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/resume/:type/:id' title="Follow Up Resume" exact>
                            <PrivateRoute path='/medical-review/resume/:type/:id' component={DetailResume} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/summary' title="Summary" exact>
                            <PrivateRoute path='/medical-review/summary' component={Summary} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.RegulasiMutuSistem, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/summary/add-summary' title={i18next.language === 'id' ? "Tambah Summary" : "Add Summary"} exact>
                            <PrivateRoute path='/medical-review/summary/add-summary' component={AddSummary} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/summary/detail/:id' title="Detail Summary" exact>
                            <PrivateRoute path='/medical-review/summary/detail/:id' component={DetailSummary} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.RegulasiMutuSistem, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/medical-review/summary/:type/:id' title="Follow Up Summary" exact>
                            <PrivateRoute path='/medical-review/summary/:type/:id' component={DetailSummary} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/market-authorization' title="Market Authorization" exact>
                            <PrivateRoute path='/market-authorization' component={MarketAut} roles={[Role.RegulasiMutu]} />
                        </AdminLayout>
                        <AdminLayout path='/market-authorization/add' title={i18next.language === 'id' ? "Tambah Market Authorization" : "Add Market Authorization"} exact>
                            <PrivateRoute path='/market-authorization/add' component={AddMarketAut} roles={[Role.RegulasiMutu]} />
                        </AdminLayout>
                        <AdminLayout path='/market-authorization/detail/:id' title="Detail Market Authorization" exact>
                            <PrivateRoute path='/market-authorization/detail/:id' component={DetailMarketAut} />
                        </AdminLayout>
                        <AdminLayout path='/quality-review' title="Quality Review" exact>
                            <PrivateRoute path='/quality-review' component={QualityReview} roles={[Role.RegulasiMutuSistem, Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/quality-review/add-review' title={i18next.language === 'id' ? "Tambah Quality Review" : "Add Quality Review"} exact>
                            <PrivateRoute path='/quality-review/add-review' component={AddQualityReview} roles={[Role.RegulasiMutuSistem]} />
                        </AdminLayout>
                        <AdminLayout path='/quality-review/detail-review/:id' title="Detail Quality Review" exact>
                            <PrivateRoute path='/quality-review/detail-review/:id' component={DetailQualityReview} roles={[Role.RegulasiMutuSistem, Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom' title="BPOM" exact>
                            <PrivateRoute path='/report-submission/bpom' component={BPOM} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/progress/add-report' title={i18next.language === 'id' ? "Tambah Laporan Perkembangan" : "Add Progress Report"} exact>
                            <PrivateRoute path='/report-submission/bpom/progress/add-report' component={AddProgressReport} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/progress/detail/:id' title={i18next.language === 'id' ? "Detail Laporan Perkembangan" : "Detail Progress Report"} exact>
                            <PrivateRoute path='/report-submission/bpom/progress/detail/:id' component={DetailProgressReport} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/progress/:type/:id' title={i18next.language === 'id' ? "Follow Up Laporan Perkembangan" : "Follow Up Progress Report"} exact>
                            <PrivateRoute path='/report-submission/bpom/progress/:type/:id' component={DetailProgressReport} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/kipi-overseas/add' title={i18next.language === 'id' ? "Tambah KIPI Luar Negeri" : "Add Overseas AEFI"} exact>
                            <PrivateRoute path='/report-submission/bpom/kipi-overseas/add' component={AddKIPIOverseas} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/kipi-overseas/detail/:id' title={i18next.language === 'id' ? "Detail KIPI Luar Negeri" : "Detail Overseas AEFI"} exact>
                            <PrivateRoute path='/report-submission/bpom/kipi-overseas/detail/:id' component={DetailKIPIOverseas} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/kipi-nonserious/add' title={i18next.language === 'id' ? "Tambah KIPI Non Serius" : "Add Non Serious AEFI"} exact>
                            <PrivateRoute path='/report-submission/bpom/kipi-nonserious/add' component={AddKIPINonSerious} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/bpom/kipi-nonserious/detail/:id' title={i18next.language === 'id' ? "Detail KIPI Non Serius" : "Detail Non Serious AEFI"} exact>
                            <PrivateRoute path='/report-submission/bpom/kipi-nonserious/detail/:id' component={DetailKIPINonSerious} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture' title="Manufacturer" exact>
                            <PrivateRoute path='/report-submission/manufacture' component={ManufactureReport} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture/add-initial' title={i18next.language === 'id' ? "Tambah Inisial Manufacturer" : "Add Initial Manufacturer"} exact>
                            <PrivateRoute path='/report-submission/manufacture/add-initial' component={AddInitial} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture/initial/detail/:id' title={i18next.language === 'id' ? "Detail Inisial Manufacturer" : "Detail Initial Manufacturer"} exact>
                            <PrivateRoute path='/report-submission/manufacture/initial/detail/:id' component={DetailInitial} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture/add-progress' title={i18next.language === 'id' ? "Tambah Progress Manufacturer" : "Add Progress Manufacturer"} exact>
                            <PrivateRoute path='/report-submission/manufacture/add-progress' component={AddProgress} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture/progress/detail/:id' title={i18next.language === 'id' ? "Detail Progress Manufacturer" : "Detail Progress Manufacturer"} exact>
                            <PrivateRoute path='/report-submission/manufacture/progress/detail/:id' component={DetailProgress} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/manufacture/progress/:type/:id' title="Follow Up Laporan Perkembangan" exact>
                            <PrivateRoute path='/report-submission/manufacture/progress/:type/:id' component={DetailProgress} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/reporter' title="Reporter" exact>
                            <PrivateRoute path='/report-submission/reporter' component={Reporter} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/reporter/add-reporter' title={i18next.language === 'id' ? "Tambah Reporter" : "Add Reporter"} exact>
                            <PrivateRoute path='/report-submission/reporter/add-reporter' component={AddReporter} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/reporter/detail/:id' title="Detail Reporter" exact>
                            <PrivateRoute path='/report-submission/reporter/detail/:id' component={DetailReporter} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/reporter/:type/:id' title="Follow Up Reporter" exact>
                            <PrivateRoute path='/report-submission/reporter/:type/:id' component={DetailReporter} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/partner' title="Partner" exact>
                            <PrivateRoute path='/report-submission/partner' component={PartnerReport} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/partner/add-partner' title={i18next.language === 'id' ? "Tambah Partner" : "Add Partner"} exact>
                            <PrivateRoute path='/report-submission/partner/add-partner' component={AddPartner} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/partner/detail/:id' title="Detail Partner" exact>
                            <PrivateRoute path='/report-submission/partner/detail/:id' component={DetailPartner} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/psur' title="PSUR" exact>
                            <PrivateRoute path='/report-submission/psur' component={PSUR} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/psur/add' title={i18next.language === 'id' ? "Tambah PSUR" : "Add PSUR"} exact>
                            <PrivateRoute path='/report-submission/psur/add' component={AddPSUR} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/psur/detail/:id' title="Detail PSUR" exact>
                            <PrivateRoute path='/report-submission/psur/detail/:id' component={DetailPSUR} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/pbrer' title="PBRER" exact>
                            <PrivateRoute path='/report-submission/pbrer' component={PBRER} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/pbrer/add' title={i18next.language === 'id' ? "Tambah PBRER" : "Add PBRER"} exact>
                            <PrivateRoute path='/report-submission/pbrer/add' component={AddPBRER} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/report-submission/pbrer/detail/:id' title="Detail PBRER" exact>
                            <PrivateRoute path='/report-submission/pbrer/detail/:id' component={DetailPBRER} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reconciliation-data/qa' title="QA" exact>
                            <PrivateRoute path='/reconciliation-data/qa' component={QA} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reconciliation-data/msd' title="MSD" exact>
                            <PrivateRoute path='/reconciliation-data/msd' component={MSD} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reconciliation-data/sanofi' title="Sanofi" exact>
                            <PrivateRoute path='/reconciliation-data/sanofi' component={Sanofi} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reconciliation-data/biovac' title="Biovac" exact>
                            <PrivateRoute path='/reconciliation-data/biovac' component={Biovac} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reconciliation-data/komnas-kipi' title="Komnas KIPI" exact>
                            <PrivateRoute path='/reconciliation-data/komnas-kipi' component={KomnasKIPI} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/allergic' title="Allergic" exact>
                            <PrivateRoute component={Allergic} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/department' title="Departement" exact>
                            <PrivateRoute component={Department} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/drugs' title="Drugs" exact>
                            <PrivateRoute component={Drugs} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/institution' title="Institution" exact>
                            <PrivateRoute component={Institution} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/source' title="Source" exact>
                            <PrivateRoute component={Source} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/symptomps' title="Symptoms" exact>
                            <PrivateRoute component={Symptomps} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/vaccinator' title="Vaccinator" exact>
                            <PrivateRoute component={Vaccinator} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/vaccine' title="Vaccine" exact>
                            <PrivateRoute component={Vaccine} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/manufacture' title="Manufacturer" exact>
                            <PrivateRoute component={Manufacture} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/partner' title="Partner" exact>
                            <PrivateRoute component={Partner} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/route' title="Route" exact>
                            <PrivateRoute component={MasterRoute} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/organization' title="Organization" exact>
                            <PrivateRoute component={Organization} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/letter' title="Letter" exact>
                            <PrivateRoute component={Letter} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/soc' title="System Organ Class" exact>
                            <PrivateRoute component={SOC} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/data-master/preferred-term' title="Preferred Term" exact>
                            <PrivateRoute component={PT} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reminder' title="Reminder" exact>
                            <PrivateRoute component={Reminder} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reminder/add' title={i18next.language === 'id' ? "Tambah Reminder" : "Add Reminder"} exact>
                            <PrivateRoute component={AddReminder} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/reminder/detail/:id' title="Edit Reminder" exact>
                            <PrivateRoute path='/reminder/detail/:id' component={DetailReminder} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/notification' title="Notifikasi" exact>
                            <PrivateRoute path='/notification' component={NotificationMenu} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/virus-vaccine-aefi' title="Virus Vaccine AEFI" clear={true} exact>
                            <PrivateRoute path='/summary-report/virus-vaccine-aefi' component={VirusVaccineAEFI} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/bacteria-vaccine-aefi' title="Bacteria Vaccine AEFI" clear={true} exact>
                            <PrivateRoute path='/summary-report/bacteria-vaccine-aefi' component={BacteriaVaccineAEFI} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/combination-vaccine-aefi' title="Combination Vaccine AEFI" clear={true} exact>
                            <PrivateRoute path='/summary-report/combination-vaccine-aefi' component={CombinationVaccineAEFI} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/concomitant-vaccination' title="Concomitant Vaccination" clear={true} exact>
                            <PrivateRoute path='/summary-report/concomitant-vaccination' component={ConcomitantVaccination} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/causality' title="Causality" exact>
                            <PrivateRoute path='/summary-report/causality' component={Causality} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/annual-aefi' title="Annual AEFI" clear={true} exact>
                            <PrivateRoute path='/summary-report/annual-aefi' component={AnnualAEFI} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/case-archival' title="Case Archival" exact>
                            <PrivateRoute path='/summary-report/case-archival' component={CaseArchival} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/case-archival/detail/:id/:type' title="Detail Case Archival" exact>
                            <PrivateRoute path='/summary-report/case-archival/detail/:id/:type' component={DetailCaseArchival} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/summary-report/reporter' title="Reporter" clear={true} exact>
                            <PrivateRoute path='/summary-report/reporter' component={ReporterReport} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/clinical-trial' title="Clinical Trial" exact>
                            <PrivateRoute component={ClinicalTrial} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/clinical-trial/add' title={i18next.language === 'id' ? "Tambah Clinical Trial" : "Add Clinical Trial"} exact>
                            <PrivateRoute component={AddClinicalTrial} roles={[Role.Staff, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/clinical-trial/detail/:id' title="Edit Clinical Trial" exact>
                            <PrivateRoute path='/clinical-trial/detail/:id' component={DetailClinicalTrial} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/product-distribution' title={i18next.language === 'id' ? "Distribusi Produk" : "Product Distribution"} exact>
                            <PrivateRoute component={ProductDistribution} roles={[Role.Distribusi, Role.Administrator, Role.Staff, Role.Unit, Role.Divisi, Role.RegulasiMutuSistem, Role.RegulasiMutu, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/product-distribution/add' title={i18next.language === 'id' ? "Tambah Distribusi Produk" : "Add Product Distribution"} exact>
                            <PrivateRoute component={AddProductDistribution} roles={[Role.Distribusi]} />
                        </AdminLayout>
                        <AdminLayout path='/product-distribution/detail/:id' title={i18next.language === 'id' ? "Edit Distribusi Produk" : "Edit Product Distribution"} exact>
                            <PrivateRoute path='/product-distribution/detail/:id' component={DetailProductDistribution} roles={[Role.Distribusi]} />
                        </AdminLayout>
                        <AdminLayout path='/app-external' title="App External" exact>
                            <PrivateRoute component={AppExternal} roles={[Role.Administrator]} />
                        </AdminLayout>
                        <AdminLayout path='/audit-trail' title="Audit Trail" exact>
                            <PrivateRoute path='/audit-trail' component={AuditTrail} roles={[Role.Staff, Role.Unit, Role.Divisi, Role.Administrator, Role.Manager]} />
                        </AdminLayout>
                        <AdminLayout path='/profile' title={i18next.language === 'id' ? "Profil" : "Profile"} clear={true} exact>
                            <PrivateRoute path='/profile' component={Profile} />
                        </AdminLayout>
                        <PublicLayout path='/access-denied' exact>
                            <Route component={AccessDenied} />
                        </PublicLayout>
                        <PublicLayout>
                            <Route component={NotFound} />
                        </PublicLayout>
                    </Switch>
                </Router>
            </ThemeProvider>
        );
    }
}

export default withTranslation()(withRouter(App));
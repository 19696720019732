import React, { Component } from "react";
import ToggleDisplay from "react-toggle-display";
import Async, { useAsync } from 'react-select/async';
import AsyncSelect from 'react-select/async';

import * as Utils from "../../../../helpers/Utils";
import Button from "../../../../components/Utils/Button/Button";
import Input from "../../../../components/Utils/Field/Input";
import Textarea from "../../../../components/Utils/Field/Textarea";
import Select2 from "../../../../components/Utils/Field/Select2";
import Select2Multi from "../../../../components/Utils/Field/Select2Multi";
import DatePicker from "../../../../components/Utils/DateRange/DateRangePicker";

import { AEFIService } from "../../../../services/CaseEntry/AEFIService";
import { AEService } from "../../../../services/CaseEntry/AEService";
import { Role, Status } from "../../../../helpers/Constants";
import { isMoreThan30DaysAgo } from "../../../../helpers/Utils";

class Initial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      selectedData: [],
      isComplete: "",
      caseNumberList: [],
      relatedCaseNumberList: [],
    };
  }

  readAEFI = async (isAnalysisAvailable, searchInput) => {
    let data = [];
    await AEFIService.Read(
      "",
      1,
      10,
      searchInput || "",
      "",
      "",
      "",
      "",
      "",
      isAnalysisAvailable,
      "",
      "",
      "",
      "",
      "",
      "",
      true,
    ).then((result) => {
      data = result;
    });

    return data;
  }

  readAE = async (isAnalysisAvailable, searchInput) => {
    let data = [];
    await AEService.Read(
      "",
      1,
      10,
      searchInput || "",
      "",
      "",
      "",
      "",
      "",
      isAnalysisAvailable,
      "",
      "",
      "",
      "",
      "",
      "",
      true,
    ).then((result) => {
      data = result;
    });

    return data;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLastVersion !== this.props.isLastVersion) {
      this.setState({ isDisabled: !this.props.isLastVersion ? true : false });
    }

    if (
      prevProps.formik.values.caseType !== this.props.formik.values.caseType
    ) {
      if (this.props.formik.values.caseType === "KIPI") {
        this.readAEFI(true).then((result) => {
          this.setState({
            caseNumberList: result?.data?.records,
          });
        });
      } else {
        this.readAE(true).then((result) => {
          this.setState({
            caseNumberList: result?.data?.records,
          });
        });
      }
    }

    if (
      prevProps.formik.values.caseNumber !==
        this.props.formik.values.caseNumber ||
      prevProps.formik.values.caseEntry !== this.props.formik.values.caseEntry
    ) {
      if (this.props.formik.values.caseNumber !== "") {
        if (this.state.caseNumberList?.length > 0) {
          if (
            this.props.formType === undefined ||
            (this.props.formType !== "edit" &&
              this.props.isLastVersion === null) ||
            (this.props.formType !== "edit" && this.props.isLastVersion)
          ) {
            let index = this.state.caseNumberList
              .map(function (x) {
                return x.caseNumber;
              })
              .indexOf(this.props.formik.values.caseNumber);
            const data = this.state.caseNumberList[index];
            this.props.updatedata(data);
            this.setState({ selectedData: data });
            this.props.formik.setFieldValue(
              "completenessData",
              data.additionalRequest === true ? "No" : "Yes"
            );
            this.props.formik.setFieldValue(
              "chronology",
              data.resultChronology ? data.resultChronology : null
            );
            this.props.formik.setFieldValue(
              "reportDate",
              data.reportedDate ? data.reportedDate : undefined
            );
          }
        }
      }
    }

    if (prevProps.formik.values !== this.props.formik.values) {
      if (this.props.updatedisabled) {
        if (this.props.formik.values.caseNumber) {
          this.props.updatedisabled("#inisiasi", false);
        } else {
          this.props.updatedisabled("#inisiasi", true);
        }
      }
    }
  }

  handleSelectChange = (item, formik) => {         
    if (this.props.formik.values.caseNumber !== "") {
        if (this.state.caseNumberList?.length > 0) {
            if (
                this.props.formType === undefined ||
                (this.props.formType !== "edit" &&
                    this.props.isLastVersion === null) ||
                (this.props.formType !== "edit" && this.props.isLastVersion)
            ) {
                let index = this.state.caseNumberList
                    .map(function (x) {
                        return x.caseNumber;
                    })
                    .indexOf(item.value);
                const data = this.state.caseNumberList[index];  
                this.props.updatedata(data);
                this.setState({ selectedData: data });
                this.props.formik.setFieldValue(
                    "completenessData",
                    data.additionalRequest === true ? "No" : "Yes"
                );
                this.props.formik.setFieldValue(
                    "chronology",
                    data.resultChronology ? data.resultChronology : null
                );
                this.props.formik.setFieldValue(
                    "reportDate",
                    data.reportedDate ? data.reportedDate : undefined
                );
                this.props.formik.setFieldValue(
                    "caseNumber", item.value
                )

            }
        }
    }        
    if (this.props.updatedisabled) {        
        if (item.value) {            
            this.props.updatedisabled("#inisiasi", false);
        } else {
            this.props.updatedisabled("#inisiasi", true);
        }
    }      
  }

  componentDidMount() {
    if (this.props.formik.values.caseType === "KIPI") {
      this.readAEFI(true).then((result) => {
        this.setState({
          caseNumberList: result?.data?.records,
        });
      });
    } else {
      this.readAE(true).then((result) => {
        this.setState({
          caseNumberList: result?.data?.records,
        });
      });
    }

      Promise.all([AEFIService.Read(
        "",
        1,
        10,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true,
      ), AEService.Read(
        "",
        1,
        10,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        true,
      )]).then(
      ([kipi, ktd]) => {
        this.setState({
          relatedCaseNumberList: [...kipi.data?.records, ...ktd.data?.records],
        });
      }
    );
  }

  handleCancelClick(event) {
    this.props.history.push(`/medical-review/analysis`, {
      pages: this.props?.page,
    });
    event.preventDefault();
  }
  handleChangeCompleteness(e) {
    this.props.formik.setFieldValue("completenessData", e.target.value)
  }
  debounce = (func, delay) => {
       let inDebounce;
       return (...args) => {
           return new Promise(resolve => {
               clearTimeout(inDebounce);
               inDebounce = setTimeout(() => resolve(func(...args)), delay);
           });
       };
  };

  labelExpiredWithInfo = (data) => {
    let label = data.caseNumber;
    if ((data?.status !== Status.Approved && data?.status !== Status.Bridging && data?.status !== Status.Closed) && isMoreThan30DaysAgo(data?.reportedDate)) {
      return (
        <>
        <span>{label}</span>
        <span className="ml-1"><i className="fas fa-exclamation-circle" style={{ color:"red" }}></i></span>
        </>
      )
    }

    return label
  }

  mapDataCaseNumber = (list) => {
    return list.data.records.map((item) => ({
      value: item.caseNumber,
      label: this.labelExpiredWithInfo(item), id: item.id,
    }));     
  }

    promiseOptions = this.debounce(async (inputValue) => {
        try {
            if (this.props.formik.values.caseType === "KIPI") {
                const kipi = await this.readAEFI(true, inputValue);
                this.setState({ caseNumberList: kipi.data.records })
                const data = this.mapDataCaseNumber(kipi);            
                return data;
            } else {
                const ktd = await this.readAE(true, inputValue);
                this.setState({ caseNumberList: ktd.data.records })
                const data = this.mapDataCaseNumber(ktd);
                return data;
            }            
        } catch (error) {
            console.error("Error fetching data", error);
            return [];
        }
    }, 500);

    promiseRelatedOptions = this.debounce(async (inputValue) => {
      try {
          if (this.props.formik.values.caseType === "KIPI") {
              const kipi = await this.readAEFI("", inputValue);
              this.setState({ relatedCaseNumberList: kipi.data.records })
              const data = this.mapDataCaseNumber(kipi);            
              return data;
          } else {
              const ktd = await this.readAE("", inputValue);
              this.setState({ relatedCaseNumberList: ktd.data.records })
              const data = this.mapDataCaseNumber(ktd);
              return data;
          }            
      } catch (error) {
          console.error("Error fetching data", error);
          return [];
      }
  }, 500);

  render() {
    const { formik, typecase, historydata, formType, currentUser, t } =
      this.props;

    const role = currentUser ? currentUser.role : "";
    const isDisabledForm =
      (this.props.formType !== "edit" && this.props.isLastVersion) ||
      (this.props.formType !== "edit" &&
        this.props.isLastVersion &&
        this.props.caseStatus !== Status.WaitingApproval &&
        this.props.caseStatus !== Status.Reviewed &&
        this.props.caseStatus !== Status.Approved) ||
      (this.props.formType === "edit" &&
        this.props.isLastVersion &&
        this.props.caseStatus !== Status.Draft &&
        this.props.caseStatus !== Status.WaitingApproval &&
        this.props.caseStatus !== Status.Reviewed &&
        this.props.caseStatus !== Status.Approved) ||
      //(this.props.isLastVersion === undefined && !this.props.id) ||
      ((role === Role.Divisi && this.props.caseStatus === Status.Reviewed && this.props.isLastVersion) || 
       (role === Role.Unit && this.props.caseStatus === Status.WaitingApproval && this.props.isLastVersion) 
        ? false
        : this.props.isLastVersion === false ||
          this.props.caseStatus === Status.WaitingApproval ||
          this.props.caseStatus === Status.Reviewed ||
          this.props.caseStatus === Status.Approved)

    const disabled =
      this.props.isLastVersion === undefined
        ? false
        : this.props.formType === "edit" ||
          this.props.isLastVersion !== undefined;
    const isNew = this.props.isLastVersion === undefined ? true : false;

    let relatedCaseNumberHistory = historydata?.relatedCaseNumberJson
      ? historydata?.relatedCaseNumberJson
      : [historydata?.relatedCaseNumber];

    let caseNumber = this.state.caseNumberList;

    let filteredCaseNumber = caseNumber?.reduce(function (filtered, item) {
      if (item.caseNumber && item.analysisCreated === null) {
        var someNewValue = { value: item.caseNumber, label: item.caseNumber };
        filtered.push(someNewValue);
      }
      return filtered;
    }, []);

    let filteredRelatedCaseNumber = this.state.relatedCaseNumberList?.reduce(
      function (filtered, item) {
        if (item.caseNumber) {
          var someNewValue = { value: item.caseNumber, label: item.caseNumber };
          filtered.push(someNewValue);
        }
        return filtered;
      },
      []
    );

    return (
      <>
        <div className="tab-pane show active tab-pane-fixed" id="inisiasi">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("case_type")}</label>
                <Select2
                  name="caseType"
                  options={[
                    { value: "KIPI", label: "KIPI" },
                    { value: "KTD", label: "KTD" },
                  ]}
                  value={formik.values.caseType}
                  errors={formik.errors}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled={disabled}
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t("case_number")} <span style={{ color: "red" }}>*</span>
                </label>
                {this.props.formType === undefined ? (
                  <AsyncSelect
                    name="caseNumber"
                    cacheOptions={false}
                    defaultOptions={
                      isNew
                        ? filteredCaseNumber
                        : this.state.caseNumberList?.map((item) => ({
                            value: item.caseNumber,
                            label: this.labelExpiredWithInfo(item),
                          }))
                    }
                    loadOptions={this.promiseOptions}
                    errors={formik.errors}
                    touched={formik.touched}   
                    onChange={(selectedOption) => this.handleSelectChange(selectedOption, formik)}
                    onBlur={formik.setFieldTouched}
                    onFocus={() => this.promiseOptions('')}
                    disabled={disabled}
                  />
                ) : (
                  <Input
                    disabled={disabled}
                    value={formik.values?.caseNumber}
                    name="caseNumber"
                    type="text"
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                )}
                <div className="mt-1" style={{ fontSize: "12px" }}>
                  <span><i className="fas fa-exclamation-circle" style={{ color:"red" }}></i></span>
                  <span> : {t("expired_reported_date")}</span>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">{t("related_case_number")}</label>
                <AsyncSelect
                    isMulti
                    name="relatedCaseNumber"
                    cacheOptions={false}
                    defaultOptions={
                      isNew
                        ? filteredRelatedCaseNumber
                        : this.state.relatedCaseNumberList?.map((item) => ({
                            value: item.caseNumber,
                            label: this.labelExpiredWithInfo(item),
                          }))
                    }
                    loadOptions={this.promiseRelatedOptions}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values?.relatedCaseNumber}                  
                    onChange={(value) => {
                      formik.setFieldValue("relatedCaseNumber", value)
                    }}
                    onBlur={formik.setFieldTouched}
                    onFocus={() => this.promiseRelatedOptions('')}
                    isDisabled={disabled}
                  />
                  <div className="mt-1" style={{ fontSize: "12px" }}>
                    <span><i className="fas fa-exclamation-circle" style={{ color:"red" }}></i></span>
                    <span> : {t("expired_reported_date")}</span>
                  </div>
              </div>
              <div className="form-group">
                <label className="form-label">{t("reported_date")}</label>
                <DatePicker
                  name="reportDate"
                  selectionType="single"
                  maxdate={new Date()}
                  value={formik.values?.reportDate}
                  errors={formik.errors}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="form-label">{t("survey_date")}</label>
                <DatePicker
                  name="surveyDate"
                  selectionType="single"
                  value={formik.values?.surveyDate}
                  maxdate={new Date()}
                  errors={formik.errors}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled={isDisabledForm}
                  same={
                    historydata?.surveyDate
                      ? Utils.getDate(historydata.surveyDate) ===
                        formik.values?.surveyDate
                      : true
                  }
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">{t("analysis_date")}</label>
                <DatePicker
                  name="analysisDate"
                  selectionType="single"
                  maxdate={new Date()}
                  errors={formik.errors}
                  value={formik.values?.analysisDate}
                  touched={formik.touched}
                  onChange={formik.setFieldValue}
                  onBlur={formik.setFieldTouched}
                  disabled={isDisabledForm}
                  same={
                    historydata?.analysisDate
                      ? Utils.getDate(historydata.analysisDate) ===
                        formik.values?.analysisDate
                      : true
                  }
                />
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t("completeness_of_data")}
                </label>
                <div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="Yes"
                      name="completenessData"
                      checked={formik.values.completenessData === "Yes"}
                      onClick={(e) => this.handleChangeCompleteness(e)}
                    />
                    <label className="form-check-label">{t("complete")}</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="No"
                      name="completenessData"
                      checked={formik.values.completenessData === "No"}
                      onClick={(e) => this.handleChangeCompleteness(e)}
                    />
                    <label className="form-check-label">{t("no")}</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="form-label">{t("chronological")}</label>
                <Textarea
                  rows="6"
                  name="chronology"
                  placeholder={t("chronological")}
                  errors={formik.errors}
                  touched={formik.touched}
                  value={
                    formik.values?.chronology ? formik.values?.chronology : ""
                  }
                  {...formik.getFieldProps("chronology")}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="text-right">
            <ToggleDisplay>
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t("cancel")}
                onClick={(event) => this.handleCancelClick(event)}
              />
            </ToggleDisplay>
            <ToggleDisplay>
              <Button
                text={t("next")}
                onClick={this.props.handleNextClick}
                isSubmitting={this.state.isDisableNext}
              />
            </ToggleDisplay>
          </div>
        </div>
      </>
    );
  }
}

export default Initial;

import React, { Component } from 'react';

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';
import Select2 from '../../../components/Utils/Field/Select2';
import Select2Multi from '../../../components/Utils/Field/Select2Multi';
import Button from '../../../components/Utils/Button/Button';
import { Role, Status } from '../../../helpers/Constants';
import { withTranslation } from "react-i18next";

class Case extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {
        const { formik } = this.props;
        const { t } = this.props;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="case-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('immunization_date')}</label>
                                <Input
                                    disabled
                                    name="immunizationDate"
                                    type="text"
                                    placeholder={t('immunization_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('immunizationDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('onset_date_aefi')}</label>
                                <Input
                                    disabled
                                    name="onsetDate"
                                    type="text"
                                    placeholder={t('onset_date_aefi')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('onsetDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('treatment_date')}</label>
                                <Input
                                    disabled
                                    name="treatmentDate"
                                    type="text"
                                    placeholder={t('treatment_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('treatmentDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('complaint')}</label>
                                <Input
                                    disabled
                                    name="complaint"
                                    type="text"
                                    placeholder={t('complaint')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('complaint')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('patient_name')}</label>
                                <Input
                                    disabled
                                    name="patientName"
                                    type="text"
                                    placeholder="Nama Pasien"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('patientName')}
                                />
                            </div>
                            <div>
                                <label className="form-label">{t('age')}</label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    name="patientAgeOfYear"
                                                    type="number"
                                                    min={0}
                                                    placeholder={t('year')}
                                                    className="form-control"
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    value={formik.values?.patientAgeOfYear ? formik.values?.patientAgeOfYear : ""}
                                                    {...formik.getFieldProps('patientAgeOfYear')}
                                                    disabled
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{t('year')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <input
                                                    name="patientAgeOfMonth"
                                                    type="number"
                                                    min={0}
                                                    max={11}
                                                    placeholder={t('month')}
                                                    className="form-control"
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('patientAgeOfMonth')}
                                                    disabled
                                                />
                                                <div className="input-group-append">
                                                    <span className="input-group-text">{t('month')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('gender')}</label>
                                <Input
                                    disabled
                                    name="patientGender"
                                    type="text"
                                    placeholder={t('gender')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('patientGender')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('dosefor')}</label>
                                <Input
                                    disabled
                                    name="doseFor"
                                    type="text"
                                    placeholder={t('dosefor')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('doseFor')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('dose')}</label>
                                <Input
                                    disabled
                                    name="dose"
                                    type="text"
                                    placeholder={t('dose')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('dose')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('rute')}</label>
                                <Input
                                    disabled
                                    name="route"
                                    type="text"
                                    placeholder={t('rute')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('route')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('diagnosis')}</label>
                                <Input
                                    disabled
                                    name="diagnosis"
                                    type="text"
                                    placeholder={t('diagnosis')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('diagnosis')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('pt')}</label>
                                <Input
                                    disabled
                                    name="preferredTerm"
                                    type="text"
                                    placeholder={t('pt')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('preferredTerm')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('soc')} (SOC)</label>
                                <Input
                                    disabled
                                    name="systemOrganClass"
                                    type="text"
                                    placeholder={t('soc')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('systemOrganClass')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('seriousness_level')}</label>
                                <Input
                                    disabled
                                    name="seriousness"
                                    type="text"
                                    placeholder={t('seriousness_level')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('seriousness')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('expectedness')}</label>
                                <Input
                                    disabled
                                    name="expectedness"
                                    type="text"
                                    placeholder={t('expectedness')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('expectedness')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('outcome')}</label>
                                <Input
                                    disabled
                                    name="outcome"
                                    type="text"
                                    placeholder={t('outcome')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('outcome')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Case);
import React, { Component } from 'react';

export default class Modal extends Component {

    render() {
        return (
            <div className="page-breadcrumb">
                <div className="row">
                    <div className="col-7 align-self-center">
                        <h3 className="page-title text-truncate text-dark font-weight-medium mb-1">{this.props.title}</h3>
                    </div>
                </div>
            </div>
        );
    }
}

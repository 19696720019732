import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

import { ReminderService } from '../../services/Reminder/ReminderService';
import { DeleteModal } from '../../components/Utils/Modal/DeleteModal';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { withTranslation } from "react-i18next";
export class Reminder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            addModalShow: false,
            editModalShow: [],
            deleteModalShow: false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: ""
        }
    }

    componentDidMount() {
        this.refreshList();
    }

    deleteRecord(id) {

        ReminderService.Delete(id).then(
            (result) => {
                this.setState({ snackbaropen: true, snackbarmsg: "Success" });
                this.refreshList();
                this.setState({ deleteModalShow: false })
            },
            (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: "Error!" });
            })
    }

    handlePageReset() {
        this.setState({ page: 1 }, () => {
            this.refreshList();
        })
    }

    refreshList() {
        ReminderService.Read(this.state.page, this.state.limit, this.state.query).then(result => {
            this.setState({
                maxPage: result.data.maxPage,
                records: result.data.records,
                editModalShow: new Array(result.data.length).fill(false)
            });
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.handlePageReset();
        event.preventDefault();
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    };

    handleDelete(id) {
        this.setState({
            record_id: id,
            deleteModalShow: true
        })
    }

    handleDetail = (id) => {
        this.props.history.push(`/reminder/detail/${id}`);
    }

    add = () => {
        this.props.history.push(`/reminder/add`);
    }

    render() {

        const { records, id, name, email, destination } = this.state;
        const { t } = this.props;

        return (
            <>
                <DeleteModal
                    show={this.state.deleteModalShow}
                    onHide={() => this.setState({ deleteModalShow: false })}
                    confirmdelete={() => this.deleteRecord(this.state.record_id)}
                    message={t('delete_confirmation')}
                />
                <div className="row">
                    <div className="col-md-3" style={{ marginBottom: 15 }}>
                        <ButtonWithIcon
                            text={t('add_reminder')}
                            icon="fa fa-plus"
                            onClick={() => this.add()}
                        />
                    </div>
                    <div className="col-md-6">

                    </div>
                    <div className="col-md-3">
                        <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                            <div className="right">
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.query}
                                            onChange={(event) => this.handleSearchChange(event)}
                                            placeholder={t('keyword')}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                    <Table className="pb-5" hover>
                        <thead>
                            <tr>
                                <th width="5%">No</th>
                                <th>{t('instance')}</th>
                                <th>{t('case_category')}</th>
                                <th>{t('seriousness_level')}</th>
                                <th>{t('expectedness_level')}</th>
                                <th>{t('region')}</th>
                                <th>{t('product')}</th>
                                <th>{t('time_limit')}</th>
                                <th width="10%"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {records.map((record, index) =>
                                <tr key={record.id}>
                                    <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                    <td>{record.manufacture == null && record.organization == null ? "BPOM" : record.manufacture != null ? record.manufactureNavigation?.name : record.organizationNavigation?.name}</td>
                                    <td>{record.caseType == 1 ? "KTD" : "KIPI"}</td>
                                    <td>{record.seriousnessNavigation.name}</td>
                                    <td>{record.expectednessNavigation.name}</td>
                                    <td>{record.isOverseas == 1 ? "Luar Negeri" : "Dalam Negeri"}</td>
                                    {
                                        record.reminderDrugs.length > 0 ? (
                                            <td>{
                                                record.reminderDrugs?.map(item => { return item.drugNavigation?.name ?? item.drugNavigation?.name; }).join(", ")
                                            }
                                            </td>
                                        ) : (
                                            <td>{
                                                record.reminderVaccines?.map(item => { return item.vaccineNavigation?.name ?? item.vaccineNavigation?.name; }).join(", ")
                                            }
                                            </td>
                                        )
                                    }
                                    <td>{record.dueAmount ? (record.dueAmount + (record.dueType === "d" ? " Hari" : record.dueType === "m" ? " Bulan" : " Tahun")) : "-"}</td>
                                    <td>
                                        <div className="btn-group">
                                            <button className="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                            </button>
                                            <div className="dropdown-menu">
                                                <a className="dropdown-item" onClick={() => this.handleDetail(record.id)}
                                                >
                                                    Edit
                                                </a>
                                                <a className="dropdown-item" onClick={() => this.handleDelete(record.id)}>
                                                    Delete
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <nav>
                    <ReactPaginate
                        previousLabel={(<i class="fas fa-arrow-left"></i>)}
                        previousLinkClassName={"page-link"}
                        nextLabel={(<i class="fas fa-arrow-right"></i>)}
                        nextLinkClassName={"page-link"}
                        pageCount={this.state.maxPage}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        breakClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        onPageChange={(event) => this.handlePageClick(event)}
                        forceSelected={(this.state.page) - 1}
                        forcePage={(this.state.page) - 1}
                        initialPage={(this.state.page) - 1}
                    />
                </nav>

            </>
        )
    }

}

export default withTranslation()(Reminder);

export function getDateTime(date, time) {

    if (date && time) {
        return date.substring(0, 10) + 'T' + time;
    }
    if (date) {
        return date;
    }
}

export function getDate(datetime) {

    if (datetime) {
        return datetime.split("T")[0];
    }
}

export function getTime(datetime) {

    if (datetime) {
        return datetime.split("T")[1];
    }
}

export function formatDate(dateStr) {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const cdate = new Date(dateStr);
    return cdate.toLocaleDateString('id-ID', dateOptions);
}

export function formatDateTime(dateStr) {
    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const cdate = new Date(dateStr);
    return cdate.toLocaleDateString('id-ID', dateOptions);
}

export function formatMonth(dateStr) {
    const dateOptions = { year: 'numeric', month: 'long' };
    const cdate = new Date(dateStr);
    return cdate.toLocaleDateString('id-ID', dateOptions);
}

export function spellPeriod(period) {
    if (period === undefined || period === null || period === "")
        return "";

    const startDate = period.from.year + "-" + period.from.month;
    const endDate = period.to.year + "-" + period.to.month;
    return formatMonth(startDate) + " - " + formatMonth(endDate);
}

export function buildQueryParams(params) {
    return Object.keys(params)
        .filter((key) => params[key] !== null && params[key] !== undefined)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
}

export function isMoreThan30DaysAgo(date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const diffTime = Math.abs(currentDate - inputDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays > 30;
}

export function isMoreThanXDaysAgo(date, days) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    const diffTime = Math.abs(currentDate - inputDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays > days;
}

export function isMoreThanRangeXDaysAgo(firstDate, secondDate, days) {
    const currentDate = new Date(secondDate);
    const inputDate = new Date(firstDate);
    const diffTime = Math.abs(currentDate - inputDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return diffDays > days;
}

export const debounce = (func, delay) => {
    let inDebounce;
    return (...args) => {
        return new Promise(resolve => {
            clearTimeout(inDebounce);
            inDebounce = setTimeout(() => resolve(func(...args)), delay);
        });
    };
};
import React from "react";
import { string } from "prop-types";
import CustomTooltip from "../Tooltip/Tooltip";

const bgColor = {
  draft: "#00BD6B33",
  draftLabel: "#00BD6B",
  pending: "#70707033",
  pendingLabel: "#707070",
  notokay: "#E1575633",
  notokayLabel: "#E15756",
  notok: "#E1575633",
  notokLabel: "#E15756",
  notapproved: "#E1575633",
  notapprovedLabel: "#E15756",
  approved: "#00ABBD33",
  approvedLabel: "#00ABBD",
  reviewed: "#FFA42633",
  reviewedLabel: "#FFA426",
  bridging: "#E1E0FF",
  bridgingLabel: "#6C63FF",
  closed: "#003FBD33",
  closedLabel: "#003FBD",
  success: "#00ABBD33",
  successLabel: "#00ABBD",
  failed: "#E1575633",
  failedLabel: "#E15756",
  open: "#00BD6B33",
  openLabel: "#00BD6B",
  terkirim: "#00ABBD33",
  terkirimLabel: "#00ABBD",
  gagal: "#E1575633",
  gagalLabel: "#E15756",
  waitingapproval: "rgba(60, 91, 255, 0.15)",
  waitingapprovalLabel: "#3c5bff"
};

function TableStatus(props) {
  const { status, style, value, width, expired } = props;
  let statusCheck = status.replace(/\s/g, '');
  statusCheck = statusCheck.toLowerCase();

  if (status.includes("Bridging")) {
    statusCheck = status.slice(0, 8).replace(/\s/g, '');
    statusCheck = statusCheck.toLowerCase();
  }

  let widthRequest = width ? width : "100%";
  let isExpired = expired?.outcome ? true : false;

  return (
    <div
      style={{
        backgroundColor: bgColor[statusCheck],
        color: bgColor[statusCheck + "Label"],
        borderColor: "none",
        width: widthRequest,
        textAlign: "center",
        fontWeight: "600",
        paddingTop: 3,
        paddingBottom: 3,
        position: "relative",
        minWidth: "170px",
        ...style,
      }}
      className="status-label"
    >
      {status}
      {isExpired && (
        <CustomTooltip
          title="Expired"
          message={expired?.desc}
          icon="fas fa-exclamation-circle"
          >
          <i className="fas fa-exclamation-circle" style={{ fontSize: "16px", position: "absolute", right: "10px", top:"6px", color:"red" }}></i>
        </CustomTooltip>
      )}
    </div>
  );
}

TableStatus.propTypes = {
  name: string,
};


export default TableStatus;

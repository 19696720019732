import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const ADROnlineService = {
    Create,
    Read,
    Update,
    PrepareForm,
    ReadAe,
    ReadAefi,
    Delete,
    Resend,
    MassResend,
    MassResendStatus,
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/adr/create/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/adr/update/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Resend(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/report-submission/bpom/adr/resend/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/bpom/adr/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}


async function PrepareForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/adr/prepare-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/adr/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function ReadAe(page = 1, limit = 10, query = "", drugs = "", status = "", startDate = "", endDate = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/report-submission/bpom/adr/read-ae?page=${page}&limit=${limit}&query=${query}&drugs=${drugs}&status=${status}&startDate=${startDate}&endDate=${endDate}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ReadAefi(page = 1, limit = 10, query = "", drugs = "", status = "", startDate = "", endDate = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/report-submission/bpom/adr/read-aefi?page=${page}&limit=${limit}&query=${query}&drugs=${drugs}&status=${status}&startDate=${startDate}&endDate=${endDate}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function MassResend() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/bpom/adr/mass-resend`    

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

function MassResendStatus() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/report-submission/bpom/adr/mass-resend-status`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { PTService } from '../../../services/Master/PTService';
import { SOCService } from '../../../services/Master/SOCService';
import Input from '../../../components/Utils/Field/Input';
import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import Select2 from '../../../components/Utils/Field/Select2';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

export class AddPTModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            snackbaropen: false, 
            snackbarmsg: '',
            systemOrganClassList: []
        };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    componentDidMount(){
        SOCService.Read(1, 0, "").then(result => {
            this.setState({
                systemOrganClassList: result.data.records,
            });
        });
    }
    

    render() {

        const { t } = this.props;

        return (

            <div className="container">
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            name: '',
                            systemOrganClass: ''
                        }}
                        validationSchema={
                            Yup.object().shape({
                                name: Yup.string().required('Required'),
                                systemOrganClass : Yup.string().required('Required')
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();
                            PTService.Create(values).then(
                                (result) => {
                                    this.props.onHide();
                                    toastSuccess(t('success_add_message'));
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                })
                        }}
                    >
                        {props => (
                            <Form>
                                <ModalHeader>
                                    <div className="modal-title">{t('add')} Preferred Term</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="form-group">
                                        <label className="form-label">Preferred Term</label>
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder="Preferred Term"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('name')} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">System Organ Class</label>
                                        <Select2
                                            name="systemOrganClass"
                                            options={this.state.systemOrganClassList?.map(item => { return { value: item.id, label: item.name }; })}
                                            value={props.values.systemOrganClass}
                                            errors={props.errors}
                                            touched={props.touched}
                                            onChange={props.setFieldValue}
                                            onBlur={props.setFieldTouched}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div className='d-flex'>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={this.props.onHide} />
                                            <Button type="submit" text={t('save')} disabled={props.isSubmitting} />
                                            {
                                                props.status &&
                                                <div className={'alert alert-danger'}>{props.status}</div>
                                            }
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}




import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import { InstitutionService } from '../../../services/Master/InstitutionService';
import Input from '../../../components/Utils/Field/Input';
import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

export class EditInstitutionModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const { t } = this.props;

        return (

            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            name: this.props.name
                        }}
                        validationSchema={
                            Yup.object().shape({
                                name: Yup.string().required('Required')
                            })
                        }
                        onSubmit={({ name }, { setStatus, setSubmitting }) => {
                            setStatus();

                            InstitutionService.Update(this.props.id, name).then(
                                (result) => {
                                    this.props.onHide();
                                    toastSuccess(t('success_edit_message'));
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                })
                        }}
                    >
                        {props => (
                            <Form>
                                <ModalHeader>
                                    <div className="modal-title">Edit Institution</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="form-group">
                                        <label className="form-label">{t('name')}</label>
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder={t('name')}
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('name')} />
                                    </div>
                                    <div className="form-group">
                                        <div className='d-flex'>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={this.props.onHide} />
                                            <Button type="submit" text={t('save')} disabled={props.isSubmitting} />
                                            {
                                                props.status &&
                                                <div className={'alert alert-danger'}>{props.status}</div>
                                            }
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}




import React, { Component } from "react";
import ToggleDisplay from "react-toggle-display";
import moment from "moment";

import DatePicker from "../../../components/Utils/DateRange/DateRangePicker";
import TimePicker from "../../../components/Utils/DateRange/TimePicker";
import Input from "../../../components/Utils/Field/Input";
import Textarea from "../../../components/Utils/Field/Textarea";
import Select2 from "../../../components/Utils/Field/Select2";
import Button from "../../../components/Utils/Button/Button";
import { Role, Status } from "../../../helpers/Constants";

import { withTranslation } from "react-i18next";
import CreatableSelect2 from "../../../components/Utils/Field/CreatableSelect2";

class ReporterInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      caseNumberObj: null,
      disabled: true,
    };
  }

  // dipanggil dari parent component AddCaseEntry, untuk diupdate statusnya ketika save case entry
  getStateCaseNumberObj() {
    return this.state.caseNumberObj;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLastVersion !== this.props.isLastVersion) {
      this.setState({ isDisabled: !this.props.isLastVersion ? true : false });
    }

    if (prevProps.formik.values !== this.props.formik.values) {
      if (this.props.updatedisabled) {
        if (
          this.props.formik.values.caseCategory &&
          this.props.formik.values.reporterName
        ) {
          this.props.updatedisabled("#reporting-information", false);
        } else {
          this.props.updatedisabled("#reporting-information", true);
        }
      }
    }
  }

  handleCancelClick(event) {
    // if (this.state.caseNumberObj) {
    //     CaseNumberingService.Release(this.state.caseNumberObj.id);
    // }
    // this.props.history.push({pathname: `/case-entry`, state: {tab : this.props.typecase}});
    this.props.history.push(`/case-entry`, {
      tab: this.props.typecase,
      page: this.props.page,
    });
    
    event.preventDefault();
  }

  render() {
    const { formik, typecase, historydata, t, ...otherProps } = this.props;

    const role = this.props.currentUser ? this.props.currentUser.role : "";
    const isDisabledForm =
      (this.props.formType === "follow-up" && this.props.isLastVersion) ||
      (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) ||
      this.props.isLastVersion === undefined
        ? false
        : !this.props.isLastVersion ||
          this.props.caseStatus === Status.WaitingApproval ||
          this.props.caseStatus === Status.Reviewed ||
          this.props.caseStatus === Status.Approved ||
          role === Role.RegulasiMutuSistem;
    let reportedDate = historydata.reportedDate
      ? moment(historydata.reportedDate).format("YYYY-MM-DD")
      : "";
    let reportedTime = historydata.reportedDate
      ? moment(historydata.reportedDate).format("HH:mm:ss")
      : "";

    return (
      <>
        <div
          className="tab-pane show active"
          id="reporting-information"
        >
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    {typecase === "KIPI"
                      ? t("aefi_category")
                      : t("ae_category")}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <Select2
                    name="caseCategory"
                    options={this.props.caseCategoryList?.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.caseCategory}
                    onBlur={formik.setFieldTouched}
                    onChange={(name, value) => {
                      formik.setFieldValue(name, value);
                    }}
                    disabled={
                      this.props.formType !== undefined &&
                      this.props.formType !== null &&
                      this.props.caseStatus !== Status.Bridging
                    }
                  />
                </div>
                {this.props.formType !== undefined &&
                  formik.values?.caseNumber !== null && (
                    <div className="form-group">
                      <label className="form-label">
                        {t("case_number")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        disabled
                        name="caseNumber"
                        type="text"
                        placeholder={t("case_number")}
                        errors={formik.errors}
                        touched={formik.touched}
                        {...formik.getFieldProps("caseNumber")}
                      />
                    </div>
                  )}

                {(formik.values?.caseCategory?.toUpperCase() ==
                  "6B2E5829-9A00-4E02-A681-F780BE13D487" ||
                  formik.values?.caseCategory?.toUpperCase() ==
                    "30D1E6DA-1F81-4B72-B666-9C4A3F562332") && (
                  <>
                    <div className="form-group">
                      <label className="form-label">
                        {t("phase")} <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select2
                        name="ctPhase"
                        options={this.props.ctPhaseList?.map((item) => {
                          return { value: item.id, label: item.name };
                        })}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik.values.ctPhase}
                        onBlur={formik.setFieldTouched}
                        onChange={formik.setFieldValue}
                        disabled={isDisabledForm}
                        same={
                          historydata?.ctPhase
                            ? historydata.ctPhase === formik.values.ctPhase
                            : true
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label className="form-label">
                        {t("product_type")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <Select2
                        name="ctProductType"
                        options={this.props.ctProductTypeList?.map((item) => {
                          return { value: item.id, label: item.name };
                        })}
                        errors={formik.errors}
                        touched={formik.touched}
                        value={formik.values.ctProductType}
                        onBlur={formik.setFieldTouched}
                        onChange={formik.setFieldValue}
                        disabled={isDisabledForm}
                        same={
                          historydata?.ctProductType
                            ? historydata.ctProductType ===
                              formik.values.ctProductType
                            : true
                        }
                      />
                    </div>
                  </>
                )}

                <div className="form-group">
                  <label className="form-label">
                    {t("manufacture_case_number")}
                  </label>
                  <Input
                    name="manufactureCaseNumber"
                    type="text"
                    placeholder={t("manufacture_case_number")}
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("manufactureCaseNumber")}
                    disabled={isDisabledForm}
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    {t("sender")} <span style={{ color: "red" }}>*</span>
                  </label>
                  <CreatableSelect2 
                    name="reporterName"
                    options={this.props.reporterList?.map((item) => {
                      return { value: item, label: item };
                    })}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.reporterName}
                    onChange={(name, value) => {
                      if (!this.props.reporterList?.includes(value)){
                        this.props.reporterList.push(value)
                      }
                      formik.setFieldValue(name, value);
                    }}
                    onBlur={formik.setFieldTouched}
                    disabled={isDisabledForm}
                    same={
                      historydata?.reporterName
                        ? historydata.reporterName ===
                          formik.values.reporterName
                        : true
                    }
                  />
                </div>
                {typecase == "KIPI" && formik.values.extCaseNumber && (
                  <div className="form-group">
                    <label className="form-label">
                      {t("reporter_case_number")}
                    </label>
                    <Input
                      disabled
                      name="extCaseNumber"
                      type="text"
                      placeholder={t("reporter_case_number")}
                      errors={formik.errors}
                      touched={formik.touched}
                      {...formik.getFieldProps("extCaseNumber")}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">{t("data_source")}</label>
                  <Select2
                    name="datasource"
                    options={this.props.sourceList?.map((item) => {
                      return { value: item.id, label: item.name };
                    })}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={formik.values.datasource}
                    onChange={(name, value) => {
                      formik.setFieldValue(name, value);
                      formik.setFieldValue(
                        "solicitCategory",
                        this.props.sourceList.filter((x) => x.id == value)[0]
                          .solicitCategory
                      );
                      formik.setFieldValue(
                        "solicitCategoryName",
                        this.props.sourceList.filter((x) => x.id == value)[0]
                          .solicitCategoryNavigation?.name
                      );
                    }}
                    onBlur={formik.setFieldTouched}
                    disabled={isDisabledForm}
                    same={
                      historydata?.datasource
                        ? historydata.datasource === formik.values.datasource
                        : true
                    }
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">
                    {t("solicited_category")}
                  </label>
                  <Input
                    disabled
                    name="solicitCategoryName"
                    type="text"
                    placeholder={t("solicited_category")}
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps("solicitCategoryName")}
                  />
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label className="form-label">{t("reported_date")}</label>
                      <DatePicker
                        name="reportedDate"
                        selectionType="single"
                        maxdate={new Date()}
                        errors={formik.errors}
                        touched={formik.touched}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                        value={formik.values?.reportedDate}
                        disabled={isDisabledForm}
                        same={
                          historydata?.reportedDate
                            ? reportedDate === formik.values.reportedDate
                            : true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label">{t("time")}</label>
                      <TimePicker
                        name="reportedTime"
                        errors={formik.errors}
                        touched={formik.touched}
                        onChange={formik.setFieldValue}
                        onBlur={formik.setFieldTouched}
                        disabled={isDisabledForm}
                        value={formik.values?.reportedTime}
                        same={
                          historydata?.reportedDate
                            ? reportedTime === formik.values?.reportedTime
                            : true
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">{t("address")}</label>
                  <Textarea
                    rows="6"
                    name="reporterAddress"
                    placeholder={t("address")}
                    errors={formik.errors}
                    touched={formik.touched}
                    value={
                      formik.values.reporterAddress
                        ? formik.values.reporterAddress
                        : ""
                    }
                    {...formik.getFieldProps("reporterAddress")}
                    disabled={isDisabledForm}
                    same={
                      historydata?.reporterAddress
                        ? historydata.reporterAddress ===
                          formik.values.reporterAddress
                        : true
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <ToggleDisplay>
              <Button
                color="#495057"
                background="#FFFFFF 0% 0% no-repeat padding-box"
                border="1px solid #495057"
                text={t("cancel")}
                onClick={(event) => this.handleCancelClick(event)}
              />
            </ToggleDisplay>
            <ToggleDisplay>
              <Button text={t("next")} onClick={this.props.handleNextClick} />
            </ToggleDisplay>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(ReporterInformation);

import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import { Loader } from '../../components/Utils/Loader/Loader';

import { DeleteModal } from '../../components/Utils/Modal/DeleteModal';
import { MarketAutService } from '../../services/MarketAut/MarketAutService';
import { withTranslation } from "react-i18next";

export class MarketAut extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            deleteModalShow: false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            params: {},
            loading: false,
            timezone: moment(new Date()).format('Z'),
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.refreshList();
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    refreshList() {
        this.handleLoading(true);
        MarketAutService.Read(this.state.page, this.state.limit, this.state.query, this.state.params).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records,
            });
            this.handleLoading(false);
        });
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null ? start.replaceAll("/", "-") + "Z" + this.state.timezone : "";
        let endDate = end !== "" && end !== null ? end.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => {
            this.handlePageReset();
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.handlePageReset();
        event.preventDefault();
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    }

    handlePageReset() {
        this.setState({ page: 1 }, () => {
            this.refreshList();
        })
    }

    record(id) {
        this.props.history.push({ pathname: `/market-authorization/detail/${id}`, state: { formType: "edit" } });
    }

    add = () => {
        this.props.history.push(`/market-authorization/add`);
    }

    handleDelete(id) {
        this.setState({
            record_id: id,
            deleteModalShow: true
        })
    }

    deleteRecord(id) {
        const { t } = this.props;
        this.handleLoading(true);
        MarketAutService.Delete(id).then(
            (result) => {
                toastSuccess(t('success_delete_message'));
                this.refreshList();
                this.setState({ deleteModalShow: false })
                this.handleLoading(false);
            },
            (error) => {
                toastError(error);
            })
    }

    render() {

        const { records } = this.state;
        const { t } = this.props;

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }

                {/* Modal List */}

                <DeleteModal
                    show={this.state.deleteModalShow}
                    onHide={() => this.setState({ deleteModalShow: false })}
                    message={t('delete_confirmation')}
                    confirmdelete={() => this.deleteRecord(this.state.record_id)}
                />

                {/* Content */}

                <div className="tab-content">

                    <div className="row" style={{ marginTop: 15 }}>

                        <div className="col-xs-6 col-sm-6 col-md-4">
                            <div style={{ marginBottom: 15 }}>
                                <ButtonWithIcon
                                    text={t('add_market_authorization')}
                                    icon="fa fa-plus"
                                    onClick={() => this.add()}
                                />
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-4">

                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-4">
                            <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                <div className="form-group">
                                    <div className="input-group">
                                        <input type="text" className="form-control"
                                            value={this.state.query}
                                            onChange={(event) => this.handleSearchChange(event)}
                                            placeholder={t('keyword')}
                                        />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="tab-pane show active">
                        <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                            <Table className="pb-5" hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>{t('product')}</th>
                                        <th>{t('country')}</th>
                                        <th>{t("institution_label")}</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((record, index) =>
                                        <>
                                            <tr key={record.id}>
                                                <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                                <td>{record.productName}</td>
                                                <td>{record.marketAuthorizationCountry?.map(item => { return item.countryNavigation?.name }).join(", ")}</td>
                                                <td>{record.marketAuthorizationInstitution?.map(item => { return item.institutionNavigation?.name }).join(", ")}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button className="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                                        </button>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" onClick={() => this.record(record.id)}>Edit</a>

                                                            <a className="dropdown-item" onClick={() => this.handleDelete(record.id)}>
                                                                Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {
                                        records.length < 1 && (
                                            <tr>
                                                <td colSpan="6" style={{ textAlign: "center" }}>
                                                    {t('data_not_found')}
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <nav>
                            <ReactPaginate
                                previousLabel={(<i class="fas fa-arrow-left"></i>)}
                                previousLinkClassName={"page-link"}
                                nextLabel={(<i class="fas fa-arrow-right"></i>)}
                                nextLinkClassName={"page-link"}
                                pageCount={this.state.maxPage}
                                containerClassName={"pagination justify-content-end"}
                                pageClassName={"page-item"}
                                breakClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                onPageChange={(event) => this.handlePageClick(event)}
                                forceSelected={(this.state.page) - 1}
                                forcePage={(this.state.page) - 1}
                                initialPage={(this.state.page) - 1}
                            />
                        </nav>
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(MarketAut);
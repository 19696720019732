import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';
import { Table } from 'react-bootstrap';

import Button from '../../../components/Utils/Button/Button';
import { Loader } from '../../../components/Utils/Loader/Loader';
import Tab from '../../../components/Utils/Tab/Tab';

import { QualityReviewService } from '../../../services/QualityReview/QualityReview/QualityReviewService';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import CaseInformation from './Tab/CaseInformation';
import QualityInformation from './Tab/QualityInformation';

import { withTranslation } from "react-i18next";

class AddQualityReview extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            backTab: "",
            nextTab: "quality-information",
            currentTab: "case-information",
        };

        this.formikRef = React.createRef();
    }

    componentDidMount() {
        
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    handleNextClick(event) {
        document.getElementById("link-" + this.state.nextTab).click();
        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleCancelClick(event) {

        this.props.history.push('/quality-review');
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#case-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "case-information", backTab: "", nextTab: "quality-information"
                });
                break;
            case "#quality-information":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "quality-information", backTab: "case-information", nextTab: "",

                });
                break;
        }

        event.preventDefault();
    }

    render() {

        const {t} = this.props;

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }
                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed">
                        <ul className="nav nav-justified mb-3 menu-tab">
                            <Tab
                                number="1"
                                showNumber = {true}
                                title={t('case_information')}
                                href="#case-information"
                                onClick={(event) => this.handleTabChange(event, "#case-information")}
                                id="link-case-information"
                            />
                            <Tab
                                number= "2"
                                showNumber = {true}
                                title="Quality Review"
                                href="#quality-information"
                                onClick={(event) => this.handleTabChange(event, "#quality-information")}
                                id="link-quality-information"
                            />
                        </ul>
                    </div>
                </div>
                <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                        caseEntry: '',
                        caseEntryType: '',
                        caseNumber: '',
                        bprReview: '',
                        summaryBatchReview: '',
                        investigationReview: '',
                        qualityProduct: [],
                        originalRecapLetter: [],
                        bprReviewFile: [],
                        summaryBatchReviewFile: [],
                        investigationReviewFile : [],
                        deathSamplesReviewFile: [],
                        qualityProductFile: []
                    }}
                    validationSchema={
                        Yup.object().shape({
                            caseNumber: Yup.string().required("Required"),
                            bprReview: Yup.string().required("Required"),
                            summaryBatchReview: Yup.string().required("Required"),
                        })
                    }
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        values.caseEntryType = values?.caseNumber.toLowerCase().includes("aefi") ? "aefi" : "ae";
                        
                        QualityReviewService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push(`/quality-review`);
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            })
                    }}
                >
                    {formik => (
                        <Form>
                            <div className="tab-content">
                                <CaseInformation
                                    formik={formik}
                                    loading={(status) => this.handleLoading(status)}
                                />
                                <QualityInformation
                                    formik={formik}
                                />
                            </div>
                            {
                                this.state.backTab === "" && 
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={(event) => this.handleCancelClick(event)} />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            text={t('next')}
                                            onClick={(event) => this.handleNextClick(event)} />
                                    </ToggleDisplay>
                                </div>
                            }
                            {
                                this.state.nextTab === "" && 
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('back')}
                                            onClick={(event) => this.handleBackClick(event)} />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t('save')}
                                            disabled={formik.isSubmitting} />
                                    </ToggleDisplay>
                                </div>
                            }

                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddQualityReview)
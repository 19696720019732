import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import { AEFIService } from '../../services/CaseEntry/AEFIService';
import { AEService } from '../../services/CaseEntry/AEService';
import { DropdownService } from '../../services/Master/AllDropdownService';
import * as Utils from '../../helpers/Utils';

import Button from '../../components/Utils/Button/Button';
import Tab from '../../components/Utils/Tab/Tab';
import { Loader } from '../../components/Utils/Loader/Loader';
import { ConfirmationModal } from './ConfirmationModal';
import { EmailModal } from './EmailModal';

import ReporterInformation from './TabForm/ReporterInformation';
import PatientInformation from './TabForm/PatientInformation';
import Manifesto from './TabForm/Manifesto';
import Symptomps from './TabForm/Symptomps';
import ManifestationResult from './TabForm/ManifestationResult';
import Immunization from './TabForm/Immunization';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';

import { withTranslation } from "react-i18next";
import ConfirmationSubmitModal from './ConfirmationSubmitModal';

class AddCaseEntry extends Component {

    constructor(props) {
        super(props);

        const { id, typecase } = this.props.match.params;

        this.state = {
            id: id,
            typecase: typecase === 'aefi' ? "KIPI" : "KTD",
            snackbaropen: false,
            snackbarmsg: '',
            backTab: "",
            nextTab: "patient-information",
            currentTab: "reporting-information",
            saveShow: false,
            drugs: [],
            drugsConcomitantly: [],
            vaccines: [],
            symptomps: [],
            allergics: [],
            diagnosis: [],
            caseNumberObj: null,
            historyForm: [],
            formValues: {},
            disabledNext: true,
            reporterDisabled: true,
            patientDisabled: true,
            immunDisabled: true,
            manifestoDisabled: true,
            symptompsDisabled: true,
            manifestationResultDisabled: true,
            loading: false,
            caseNumber: "",
            confrimModalShow: false,
            isSubmitted: false,
            confirmSubmitModalShow: false,
        };

        this.formikRef = React.createRef();
        this.reportInfoRef = React.createRef();
        this.getCaseEntryData(id);
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    getCaseEntryData(id) {

        DropdownService.MasterList([
            "aeCategory", "aefiCategory", "source", "department", "institution", "soc", "pt", "productsErp",
            "childbearing", "vaccine", "vaccinator", "drugs", "symptomps", "allergic", "ctProductType", "ctPhase",
            "immunizationRoute", "immunizationRisk", "seriousness", "expectedness", "outcome", "seriousnessReason", "patientType",
            "reporterSource"]).then(
                (result) => {
                    this.setState({ list: result.data });
                })

        DropdownService.CountryList().then(
            (result) => {
                this.setState({ countryList: result.data });
            })

        if (this.state.typecase === "KIPI") {
            AEFIService.PrepareForm(id).then(
                (result) => {
                    if (result.data) {
                        this.setState({
                            data: result.data,
                        });
                        // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                        // Posiblity conflic with initialValues
                        this.setState({
                            vaccines: result.data.aefiVaccine.length ? result.data.aefiVaccine : [{ isNew: true }],
                            symptomps: result.data.aefiSymptomps.length ? result.data.aefiSymptomps : [{ isNew: true }],
                            allergics: result.data.aefiAllergic.length ? result.data.aefiAllergic : [{ isNew: true }],
                            diagnosis: result.data?.aefiDiagnosis?.length ? result.data.aefiDiagnosis : [{ isNew: true }],
                            historyForm: result.data.historyJson ? result.data.historyJson : []
                        });

                        this.provinceList("patient", result.data.patientCountry);
                        this.cityList("patient", result.data.patientProvince);

                        this.provinceList("institution", result.data.institutionCountry);
                        this.cityList("institution", result.data.institutionProvince);
                    }
                    else {
                        this.props.history.push({ pathname: `/case-entry`, state: { tab: this.state.typecase } });
                    }
                },
                (error) => {
                    this.props.history.push({ pathname: `/case-entry`, state: { tab: this.state.typecase } });
                })
        }

        if (this.state.typecase === "KTD") {
            AEService.PrepareForm(id).then(
                (result) => {
                    if (result.data) {
                        this.setState({
                            data: result.data,
                        });
                        // Dont merge setstate for dynamictable wih result.data, the initial value for dynamictable will not appear
                        // Posiblity conflic with initialValues
                        this.setState({
                            drugs: result.data.aeDrugs.length ? result.data.aeDrugs : [{ isNew: true }],
                            drugsConcomitantly: result.data.aeDrugsConcomitantly.length ? result.data.aeDrugsConcomitantly : [{ isNew: true }],
                            diagnosis: result.data?.aeDiagnosis?.length ? result.data.aeDiagnosis : [{ isNew: true }],
                            historyForm: result.data.historyJson ? result.data.historyJson : []
                        });

                        this.provinceList("patient", result.data.patientCountry);
                        this.cityList("patient", result.data.patientProvince);
                    }
                    else {
                        this.props.history.push({ pathname: `/case-entry`, state: { tab: this.state.typecase } });
                    }
                },
                (error) => {
                    this.props.history.push({ pathname: `/case-entry`, state: { tab: this.state.typecase } });
                })
        }
    }

    provinceList(field, country) {

        if (country) {
            DropdownService.ProvinceList(country).then(
                (result) => {
                    if (field === "patient") {
                        this.setState({
                            patientProvinceList: result.data,
                            patientCityList: []
                        });
                    }
                    if (field === "institution") {
                        this.setState({
                            institutionProvinceList: result.data,
                            institutionCityList: []
                        });
                    }
                })
        }
    }

    cityList(field, province) {

        if (province) {
            DropdownService.CityList(province).then(
                (result) => {
                    if (field === "patient") {
                        this.setState({ patientCityList: result.data });
                    }
                    if (field === "institution") {
                        this.setState({ institutionCityList: result.data });
                    }
                })
        }
    }

    handleTabChange(event, tab) {
        switch (tab) {
            case "#reporting-information":
                this.setState({
                    cancelShow: true, backShow: false, nextShow: true, saveShow: false,
                    currentTab: "reporting-information", backTab: "", nextTab: "patient-information"
                });
                break;
            case "#patient-information":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "patient-information", backTab: "reporting-information", nextTab: this.state.typecase === "KIPI" ? "immunization" : "manifesto",
                });
                break;
            case "#manifesto":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "manifesto", backTab: "patient-information", nextTab: "manifestation-result",
                    manifestoDisabled: false
                });
                break;
            case "#immunization":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "immunization", backTab: "patient-information", nextTab: "symptomps",
                    immunDisabled: false
                });
                break;
            case "#symptomps":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: true, saveShow: false,
                    currentTab: "symptomps", backTab: "immunization", nextTab: "manifestation-result",
                    symptompsDisabled: false
                });
                break;
            case "#manifestation-result":
                this.setState({
                    cancelShow: false, backShow: true, nextShow: false, saveShow: true,
                    currentTab: "manifestation-result", backTab: this.state.typecase === "KIPI" ? "symptomps" : "manifesto", nextTab: "",
                    manifestationResultDisabled: false
                });
                break;
        }

        event.preventDefault();
    }

    handleBackClick(event) {
        document.getElementById("link-" + this.state.backTab).click();
        event.preventDefault();
    }

    handleNextClick(event) {
        this.formikRef.current.validateForm().then(
            () => {
                if (this.formikRef.current.isValid) {
                    document.getElementById("link-" + this.state.nextTab).click();
                }
            }
        )
        event.preventDefault();
    }

    handleDisabledNext(tab, status) {
        switch (tab) {
            case "#reporting-information":
                this.setState({
                    reporterDisabled: status
                });
                break;
            case "#patient-information":
                this.setState({
                    patientDisabled: this.state.reporterDisabled ? true : status,
                    immunDisabled: status,
                    symptompsDisabled: status
                });
                break;
            case "#manifesto":
                this.setState({
                    manifestoDisabled: this.state.patientDisabled ? true : status
                });
                break;
            case "#immunization":
                this.setState({
                    immunDisabled: this.state.patientDisabled ? true : status
                });
                break;
            case "#symptomps":
                this.setState({
                    symptompsDisabled: this.state.immunDisabled ? true : status
                });
                break;
            case "#manifestation-result":
                this.setState({
                    manifestationResultDisabled: this.state.typecase === "KIPI" ? (this.state.symptompsDisabled ? true : status) : (this.state.manifestoDisabled ? true : status)
                });
                break;
        }
    }

    handleSuccessAdd = () => {

        this.setState({ confrimModalShow: false }, this.props.history.push({ pathname: `/case-entry`, state: { tab: this.state.typecase } }))

    }

    render() {

        const { t } = this.props;

        const caseEntry = this.state.data;
        const caseCategoryList = this.state.typecase === "KIPI" ? this.state.list?.aefiCategoryList : this.state.list?.aeCategoryList;
        const sourceList = this.state.list?.sourceList;
        const reporterList = this.state.list?.reporterSourceList;
        const socList = this.state.list?.socList;
        const ptList = this.state.list?.ptList;
        const erpList = this.state.list?.productsErpList;
        const ctPhaseList = this.state.list?.ctPhaseList;
        const ctProductTypeList = this.state.list?.ctProductTypeList;
        const outcomeList = this.state.list?.outcomeList;
        const allergicList = this.state.list?.allergicList;
        const symptompsList = this.state.list?.symptompsList;
        const departmentList = this.state.list?.departmentList;
        const seriousnessList = this.state.list?.seriousnessList;
        const patientTypeList = this.state.list?.patientTypeList;
        const expectednessList = this.state.list?.expectednessList;
        const childbearingList = this.state.list?.childbearingList;
        const seriousnessReasonList = this.state.list?.seriousnessReasonList;
        const immunizationRouteList = this.state.list?.immunizationRouteList;
        const countryList = this.state.countryList;
        const patientProvinceList = this.state.patientProvinceList;
        const patientCityList = this.state.patientCityList;
        const onsetDate = this.state.list?.onsetDate;
        let drugsList = [];
        let vaccineList = [];
        let vaccinatorList = [];
        let institutionList = [];
        let immunizationRiskList = [];
        let institutionProvinceList = [];
        let institutionCityList = [];

        if (this.state.typecase === "KIPI") {

            vaccineList = this.state.list?.vaccineList;
            vaccinatorList = this.state.list?.vaccinatorList;
            institutionList = this.state.list?.institutionList;
            immunizationRiskList = this.state.list?.immunizationRiskList;
            institutionProvinceList = this.state.institutionProvinceList;
            institutionCityList = this.state.institutionCityList;
        }
        if (this.state.typecase === "KTD") {

            drugsList = this.state.list?.drugsList;

        }

        const initial = this.state.typecase === "KIPI" ? {
            // 1. Repoting Information
            caseCategory: caseEntry?.caseCategory,
            caseNumber: caseEntry?.caseNumber,
            manufactureCaseNumber: caseEntry?.manufactureCaseNumber,
            reporterName: caseEntry?.reporterName,
            datasource: caseEntry?.datasource,
            reportedDate: Utils.getDate(caseEntry?.reportedDate),
            reportedTime: Utils.getTime(caseEntry?.reportedDate),
            reporterAddress: caseEntry?.reporterAddress,
            // 2 Patient Information
            patientName: caseEntry?.patientName,
            patientGender: caseEntry?.patientGender,
            parentName: caseEntry?.parentName,
            patientAddress: caseEntry?.patientAddress,
            patientCountry: caseEntry?.patientCountry,
            patientProvince: caseEntry?.patientProvince,
            patientCity: caseEntry?.patientCity,
            patientPhone: caseEntry?.patientPhone,
            patientAgeYear: caseEntry?.patientAgeYear,
            patientAgeMonth: caseEntry?.patientAgeMonth,
            patientChildbearing: caseEntry?.patientChildbearing,
            lastImmunizationDate: Utils.getDate(caseEntry?.lastImmunizationDate),
            historyAllergicOption: caseEntry?.historyAllergic === null || caseEntry?.historyAllergic === undefined ? "Unknown" : (caseEntry?.historyAllergic !== '#?#' ? "Yes" : "No"),
            historyAllergic: caseEntry?.historyAllergic === null || caseEntry?.historyAllergic === undefined ? "" : (caseEntry?.historyAllergic !== '#?#' ? caseEntry?.historyAllergic : ""),
            historyDiseaseOption: caseEntry?.historyDisease === null || caseEntry?.historyDisease === undefined ? "Unknown" : (caseEntry?.historyDisease !== '#?#' ? "Not Healthy" : "Healthy"),
            historyDisease: caseEntry?.historyDisease === null || caseEntry?.historyDisease === undefined ? "" : (caseEntry?.historyDisease !== '#?#' ? caseEntry?.historyDisease : ""),
            historyRiskOption: caseEntry?.historyRisk ? "Yes" : "No",
            historyRisk: caseEntry?.historyRisk,
            historyOther: caseEntry?.historyOther,
            // 3. Immunization
            onsetDate: Utils.getDate(caseEntry?.onsetDate),
            onsetTime: Utils.getTime(caseEntry?.onsetDate),
            vaccinator: caseEntry?.vaccinator,
            vaccinatorName: caseEntry?.vaccinatorName,
            institution: caseEntry?.institution,
            institutionName: caseEntry?.institutionName,
            institutionAddress: caseEntry?.institutionAddress,
            institutionCountry: caseEntry?.institutionCountry,
            institutionProvince: caseEntry?.institutionProvince,
            institutionCity: caseEntry?.institutionCity,
            vaccineStorage: caseEntry?.vaccineStorage,
            vaccineShakeBefore: caseEntry?.vaccineShakeBefore !== null && caseEntry?.vaccineShakeBefore !== undefined ? (caseEntry?.vaccineShakeBefore ? "true" : "false") : "Unknown",
            vaccineFirstMultiDose: caseEntry?.vaccineFirstMultiDose !== null && caseEntry?.vaccineFirstMultiDose !== undefined ? (caseEntry?.vaccineFirstMultiDose ? "true" : "false") : "Unknown",
            vaccineInjectionDevice: caseEntry?.vaccineInjectionDevice !== null && caseEntry?.vaccineInjectionDevice !== undefined ? (caseEntry?.vaccineInjectionDevice ? "true" : "false") : "Unknown",
            sameVialOption: caseEntry?.sameVial !== null && caseEntry?.sameVial !== undefined ? (caseEntry?.sameVial < 0 ? "No" : "Yes") : "Unknown",
            sameVial: caseEntry?.sameVial <= 0 ? '' : caseEntry?.sameVial,
            sameAdverseOption: caseEntry?.sameAdverse !== null && caseEntry?.sameAdverse !== undefined ? (caseEntry?.sameAdverse < 0 ? "No" : "Yes") : "Unknown",
            sameAdverse: caseEntry?.sameAdverse <= 0 ? '' : caseEntry?.sameAdverse,
            sameSymptomps: caseEntry?.sameSymptomps !== null && caseEntry?.sameSymptomps !== undefined ? (caseEntry?.sameSymptomps ? "true" : "false") : "Unknown",
            diluentMedicineOption: caseEntry?.diluentMedicine !== undefined && caseEntry?.diluentMedicine !== null ? (caseEntry?.diluentMedicine !== '#?#' ? "Yes" : "No") : "Unknown",
            diluentMedicine: caseEntry?.diluentMedicine !== undefined && caseEntry?.diluentMedicine != null ? (caseEntry?.diluentMedicine !== '#?#' ? caseEntry?.diluentMedicine : "") : "",
            // 5. Manifestation Result
            resultChronology: caseEntry?.resultChronology,
            resultPhysic: caseEntry?.resultPhysic,
            resultLaboratory: caseEntry?.resultLaboratory,
            resultTreatment: caseEntry?.resultTreatment,
            resultConclusion: caseEntry?.resultConclusion,
            adverseDate: Utils.getDate(caseEntry?.adverseDate),
            adverseTime: Utils.getTime(caseEntry?.adverseDate),
            adverseName: caseEntry?.adverseName,
            adverseDepartment: caseEntry?.adverseDepartment,
            adversePvDate: Utils.getDate(caseEntry?.adversePvDate),
            adversePvTime: Utils.getTime(caseEntry?.adversePvDate),
            adversePvName: caseEntry?.adversePvName,
            seriousness: caseEntry?.seriousness,
            expectedness: caseEntry?.expectedness,
            outcome: caseEntry?.outcome,
            deathDate: Utils.getDate(caseEntry?.deathDate),
            additionalRequest: caseEntry?.additionalRequest,
            isSubmitted: true,
            additionalRequestMethod: caseEntry?.additionalRequestMethod,
            distributionDataRequest: caseEntry?.distributionDataRequest || false,

        } : {

            // 1. Repoting Information
            caseCategory: caseEntry?.caseCategory,
            caseNumber: caseEntry?.caseNumber,
            manufactureCaseNumber: caseEntry?.manufactureCaseNumber,
            reporterName: caseEntry?.reporterName,
            datasource: caseEntry?.datasource,
            reportedDate: Utils.getDate(caseEntry?.reportedDate),
            reportedTime: Utils.getTime(caseEntry?.reportedDate),
            reporterAddress: caseEntry?.reporterAddress,
            // 2. Patient Information
            patientName: caseEntry?.patientName,
            patientGender: caseEntry?.patientGender,
            parentName: caseEntry?.parentName,
            patientAddress: caseEntry?.patientAddress,
            patientCountry: caseEntry?.patientCountry,
            patientProvince: caseEntry?.patientProvince,
            patientCity: caseEntry?.patientCity,
            patientPhone: caseEntry?.patientPhone,
            patientAgeYear: caseEntry?.patientAgeYear,
            patientAgeMonth: caseEntry?.patientAgeMonth,
            patientChildbearing: caseEntry?.patientChildbearing,
            productUseDate: Utils.getDate(caseEntry?.productUseDate),
            diseaseName: caseEntry?.diseaseName,
            diseaseRenal: caseEntry?.diseaseRenal,
            diseaseLiver: caseEntry?.diseaseLiver,
            diseaseAllergy: caseEntry?.diseaseAllergy,
            diseaseOtherOption: caseEntry?.diseaseOther ? true : false,
            diseaseOther: caseEntry?.diseaseOther,
            // 3. Manifesto
            reaction: caseEntry?.reaction,
            onsetDate: Utils.getDate(caseEntry?.onsetDate),
            onsetTime: Utils.getTime(caseEntry?.onsetDate),
            reactionDied: caseEntry?.reactionDied,
            reactionHospitalization: caseEntry?.reactionHospitalization,
            reactionDisability: caseEntry?.reactionDisability,
            reactionLife: caseEntry?.reactionLife,
            reactionEvent: caseEntry?.reactionEvent,
            reactionDefect: caseEntry?.reactionDefect,
            // 4. Manifestation Result
            resultChronology: caseEntry?.resultChronology,
            resultPhysic: caseEntry?.resultPhysic,
            resultLaboratory: caseEntry?.resultLaboratory,
            resultTreatment: caseEntry?.resultTreatment,
            resultConclusion: caseEntry?.resultConclusion,
            patientChildbearing: caseEntry?.patientChildbearing,
            adverseDate: Utils.getDate(caseEntry?.adverseDate),
            adverseTime: Utils.getTime(caseEntry?.adverseDate),
            adverseName: caseEntry?.adverseName,
            adverseDepartment: caseEntry?.adverseDepartment,
            adversePvDate: Utils.getDate(caseEntry?.adversePvDate),
            adversePvTime: Utils.getTime(caseEntry?.adversePvDate),
            adversePvName: caseEntry?.adversePvName,
            seriousness: caseEntry?.seriousness,
            expectedness: caseEntry?.expectedness,
            outcome: caseEntry?.outcome,
            deathDate: Utils.getDate(caseEntry?.deathDate),
            additionalRequest: caseEntry?.additionalRequest,
            isSubmitted: true,
            additionalRequestMethod: caseEntry?.additionalRequestMethod,
            distributionDataRequest: caseEntry?.distributionDataRequest || false,

        }

        const validationSchemaDict = this.state.typecase === "KIPI" ? {
            "reporting-information": Yup.object().shape({
                caseCategory: Yup.string().required("Required").nullable(),
                reporterName: Yup.string().required("Required").nullable(),
            }),
            "patient-information": Yup.object().shape({
                patientName: Yup.string().required("Required").nullable(),
                patientType: Yup.string().required("Required").nullable(),
            }),
        } : {
            "reporting-information": Yup.object().shape({
                caseCategory: Yup.string().required("Required").nullable(),
                reporterName: Yup.string().required("Required").nullable(),
            }),
            "patient-information": Yup.object().shape({
                patientName: Yup.string().required("Required").nullable(),
                patientType: Yup.string().required("Required").nullable(),
            }),
        }

        const initialTouched = this.state.typecase === "KIPI" ? {
            caseCategory: true,
            reporterName: true,
            patientName: true,
            historyAllergic: true,
            historyDisease: true,
            sameVial: true,
            sameAdverse: true,
            diluentMedicine: true,
            patientType: true
        } : {
            caseCategory: true,
            reporterName: true,
            patientName: true,
            onsetDate: true,
            onsetTime: true,
            patientType: true
        }

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }

                {/* Mail */}
                <EmailModal
                    show={this.state.mailModal}
                    onhide={() => this.setState({ mailModal: false })}
                    typecase={this.state.typecase}
                    id={this.state.id}
                    values={this.state.formValues}
                    isFollowUp={this.props.isFollowUp}
                    history={this.props.history}
                    t={t}
                />

                <ConfirmationModal
                    show={this.state.confrimModalShow}
                    onhide={() => this.handleSuccessAdd()}
                    confirm={() => this.handleSuccessAdd()}
                    title="Case Entry"
                    caseNumber={this.state.caseNumber}
                    t={t}
                />

                <ConfirmationSubmitModal
                    show={this.state.confirmSubmitModalShow}
                    onHide={() => this.setState({ confirmSubmitModalShow: false })}
                    confirmsubmit={() => this.formikRef.current.submitForm()}
                    t={t}
                />

                <div className="nav-fixed-wrapper">
                    <div className="nav-fixed w-100 h-auto" style={{ paddingRight: '25vw' }}>
                        <ul className="nav nav-justified mb-0">
                            <Tab
                                number="1"
                                showNumber={true}
                                title={t('report_information')}
                                href="#reporting-information"
                                onClick={(event) => this.handleTabChange(event, "#reporting-information")}
                                id="link-reporting-information"
                            />
                            <Tab
                                number="2"
                                showNumber={true}
                                title={t('patient_information')}
                                href="#patient-information"
                                onClick={(event) => this.handleTabChange(event, "#patient-information")}
                                id="link-patient-information"
                                disabled={this.state.reporterDisabled}
                            />
                            {
                                this.state.typecase === "KIPI" && (
                                    <>
                                        <Tab
                                            number="3"
                                            showNumber={true}
                                            title={t('immunization_implementation')}
                                            href="#immunization"
                                            onClick={(event) => this.handleTabChange(event, "#immunization")}
                                            id="link-immunization"
                                            disabled={this.state.patientDisabled}
                                        />
                                        <Tab
                                            number="4"
                                            showNumber={true}
                                            title={t('clinical_symptoms')}
                                            href="#symptomps"
                                            onClick={(event) => this.handleTabChange(event, "#symptomps")}
                                            id="link-symptomps"
                                            disabled={this.state.immunDisabled}
                                        />
                                    </>
                                )
                            }
                            {
                                this.state.typecase === "KTD" && (
                                    <>
                                        <Tab
                                            number="3"
                                            showNumber={true}
                                            title={t('ae_adr')}
                                            href="#manifesto"
                                            onClick={(event) => this.handleTabChange(event, "#manifesto")}
                                            id="link-manifesto"
                                            disabled={this.state.patientDisabled}
                                        />
                                    </>
                                )
                            }

                            <Tab
                                number={this.state.typecase === "KTD" ? "4" : "5"}
                                showNumber={true}
                                title={t('manifestation_result')}
                                href="#manifestation-result"
                                onClick={(event) => this.handleTabChange(event, "#manifestation-result")}
                                id="link-manifestation-result"
                                disabled={this.state.typecase === "KIPI" ? this.state.symptompsDisabled : this.state.manifestoDisabled}
                            />
                        </ul>
                    </div>
                </div>


                <Formik
                    innerRef={this.formikRef}
                    enableReinitialize
                    initialValues={initial}
                    initialTouched={initialTouched}
                    validationSchema={validationSchemaDict[this.state.currentTab]}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();
                        this.handleLoading(true);
                        // Merge date & time
                        values.caseNumber = null
                        values.reportedDate = Utils.getDateTime(values.reportedDate, values.reportedTime);
                        values.adverseDate = Utils.getDateTime(values.adverseDate, values.adverseTime);
                        values.adversePvDate = Utils.getDateTime(values.adversePvDate, values.adversePvTime);
                        values.onsetDate = Utils.getDateTime(values.onsetDate, values.onsetTime);

                        // Convert value
                        values.historyAllergic = values.historyAllergicOption === 'Unknown' ? null : values.historyAllergicOption === 'No' ? '#?#' : (values.historyAllergic ? values.historyAllergic : '');
                        values.historyDisease = values.historyDiseaseOption === 'Unknown' ? null : values.historyDiseaseOption === 'Healthy' ? '#?#' : (values.historyDisease ? values.historyDisease : '');
                        values.diluentMedicine = values.diluentMedicineOption === 'Unknown' ? null : values.diluentMedicineOption === 'No' ? '#?#' : (values.diluentMedicine ? values.diluentMedicine : '');

                        values.sameSymptomps = values.sameSymptomps === 'Unknown' ? null : values.sameSymptomps === "true" ? true : false;
                        values.vaccineShakeBefore = values.vaccineShakeBefore === 'Unknown' ? null : values.vaccineShakeBefore === "true" ? true : false;
                        values.vaccineFirstMultiDose = values.vaccineFirstMultiDose === 'Unknown' ? null : values.vaccineFirstMultiDose === "true" ? true : false;
                        values.vaccineInjectionDevice = values.vaccineInjectionDevice === 'Unknown' ? null : values.vaccineInjectionDevice === "true" ? true : false;


                        values.sameVial = values.sameVialOption === 'Unknown' ? null : values.sameVialOption === 'No' ? (-1) : (values.sameVial ? values.sameVial : 0);
                        values.sameAdverse = values.sameAdverseOption === 'Unknown' ? null : values.sameAdverseOption === 'No' ? (-1) : (values.sameAdverse ? values.sameAdverse : 0);

                        if (this.state.typecase === "KIPI") {
                            values.aefiDiagnosis = values.diagnosis ?? [];
                            AEFIService.SubmitForm(this.state.id, values).then(
                                (result) => {
                                    toastSuccess(t('success_add_message'));
                                    this.handleLoading(false);
                                    this.setState({ caseNumber: result?.data?.caseNumber }, this.setState({ confrimModalShow: true }))
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                    this.handleLoading(false);
                                })
                        }

                        if (this.state.typecase === "KTD") {
                            values.aeDiagnosis = values.diagnosis ?? []
                            AEService.SubmitForm(this.state.id, values).then(
                                (result) => {
                                    toastSuccess(t('success_add_message'));
                                    this.handleLoading(false);
                                    this.setState({ caseNumber: result?.data?.caseNumber }, this.setState({ confrimModalShow: true }))
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                    this.handleLoading(false);
                                })
                        }
                    }}
                >
                    {formik => (
                        <Form>
                            <div className="tab-content tab-content-fixed">

                                {/* Reporting Information Tab */}

                                <ReporterInformation
                                    formik={formik}
                                    typecase={this.state.typecase}
                                    handleNextClick={(event) => this.handleNextClick(event)}
                                    caseCategoryList={caseCategoryList}
                                    sourceList={sourceList}
                                    reporterList={reporterList}
                                    ctPhaseList={ctPhaseList}
                                    ctProductTypeList={ctProductTypeList}
                                    historydata={this.state.historyForm}
                                    history={this.props.history}
                                    onsetDate={onsetDate}
                                    ref={this.reportInfoRef}
                                    updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                    disabledNext={this.state.reporterDisabled}
                                />

                                {/* Patient Information Tab */}

                                <PatientInformation
                                    formik={formik}
                                    typecase={this.state.typecase}
                                    handleBackClick={(event) => this.handleBackClick(event)}
                                    handleNextClick={(event) => this.handleNextClick(event)}
                                    historydata={this.state.historyForm}
                                    vaccineList={vaccineList}
                                    drugsList={this.state.typecase === "KTD" ? drugsList : null}
                                    childbearingList={childbearingList}
                                    immunizationRiskList={immunizationRiskList}
                                    countryList={countryList}
                                    patientProvinceList={patientProvinceList}
                                    patientCityList={patientCityList}
                                    patientTypeList={patientTypeList}
                                    provinceList={(field, country) => this.provinceList(field, country)}
                                    cityList={(field, country) => this.cityList(field, country)}
                                    disabledNext={this.state.patientDisabled}
                                    updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                />

                                {/* Immunization Tab */}

                                {
                                    this.state.typecase === "KIPI" && (
                                        <Immunization
                                            formik={formik}
                                            typecase={this.state.typecase}
                                            handleBackClick={(event) => this.handleBackClick(event)}
                                            handleNextClick={(event) => this.handleNextClick(event)}
                                            historydata={this.state.historyForm}
                                            vaccines={this.state.vaccines}
                                            vaccineList={vaccineList}
                                            vaccinatorList={vaccinatorList}
                                            institutionList={institutionList}
                                            erpList={erpList}
                                            immunizationRouteList={immunizationRouteList}
                                            countryList={countryList}
                                            institutionProvinceList={institutionProvinceList}
                                            institutionCityList={institutionCityList}
                                            provinceList={(field, country) => this.provinceList(field, country)}
                                            cityList={(field, country) => this.cityList(field, country)}
                                            disabledNext={this.state.immunDisabled}
                                            updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}

                                        />
                                    )
                                }

                                {/* Symptomps Tab */}

                                {
                                    this.state.typecase === "KIPI" && (
                                        <Symptomps
                                            formik={formik}
                                            typecase={this.state.typecase}
                                            handleBackClick={(event) => this.handleBackClick(event)}
                                            handleNextClick={(event) => this.handleNextClick(event)}
                                            historydata={this.state.historyForm}
                                            symptomps={this.state.symptomps}
                                            symptompsList={symptompsList}
                                            socList={socList}
                                            ptList={ptList}
                                            allergics={this.state.allergics}
                                            allergicList={allergicList}
                                            disabledNext={this.state.symptompsDisabled}
                                            updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}

                                        />
                                    )
                                }

                                {/* Information AE/ADR Manifesto Tab */}

                                {
                                    this.state.typecase === "KTD" && (
                                        <Manifesto
                                            formik={formik}
                                            typecase={this.state.typecase}
                                            handleBackClick={(event) => this.handleBackClick(event)}
                                            handleNextClick={(event) => this.handleNextClick(event)}
                                            historydata={this.state.historyForm}
                                            drugs={this.state.drugs}
                                            drugsConcomitantly={this.state.drugsConcomitantly}
                                            drugsList={drugsList}
                                            erpList={erpList}
                                            immunizationRouteList={immunizationRouteList}
                                            disabledNext={this.state.manifestoDisabled}
                                            updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}

                                        />
                                    )
                                }

                                {/* Manifestation Result Tab*/}
                                <ManifestationResult
                                    formik={formik}
                                    typecase={this.state.typecase}
                                    handleBackClick={(event) => this.handleBackClick(event)}
                                    historydata={this.state.historyForm}
                                    socList={socList}
                                    ptList={ptList}
                                    diagnosis={this.state.diagnosis}
                                    outcomeList={outcomeList}
                                    expectednessList={expectednessList}
                                    seriousnessList={seriousnessList}
                                    departmentList={departmentList}
                                    seriousnessReasonList={seriousnessReasonList}
                                    disabledNext={this.state.manifestationResultDisabled}
                                    updatedisabled={(tab, status) => this.handleDisabledNext(tab, status)}
                                    canStaffEdit={true}
                                    caseStatus={caseEntry?.status}
                                    showMailForm={(mailPurpose) => {
                                        this.setState({
                                          mailModal: true,
                                          formValues: {
                                            ...formik.values,
                                            mailPurpose: mailPurpose
                                          }                    
                                        });
                                    }}
                                />
                                <input
                                    name="isSubmitted"
                                    type="hidden"
                                    {...formik.getFieldProps("isSubmitted")}
                                />

                                {
                                    this.state.nextTab == "" &&

                                    <div className='text-right my-2'>
                                        <ToggleDisplay>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('back')}
                                                onClick={(event) => this.handleBackClick(event)} />
                                        </ToggleDisplay>
                                        {/*Simpan sebagai Draft*/}
                                        <ToggleDisplay show={this.state.saveShow}>
                                            <Button
                                                text={t('save_as_draft')}
                                                onClick={()=> { 
                                                    formik.setFieldValue("isSubmitted", false);
                                                    formik.submitForm();
                                                }}
                                                disabled={formik.isSubmitting} />
                                        </ToggleDisplay>
                                        {/* Simpan */}
                                        <ToggleDisplay show={this.state.saveShow}>
                                            <Button
                                                type="button"
                                                text={"Submit"}
                                                disabled={formik.isSubmitting}
                                                onClick={() => this.setState({ confirmSubmitModalShow: true })}
                                            />
                                        </ToggleDisplay>
                                        {
                                            formik.status &&
                                            <div className={'alert alert-danger'}>{formik.status}</div>
                                        }
                                    </div>
                                }
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddCaseEntry)

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { PartnerService } from '../../../services/Master/PartnerService';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';
import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

export class AddPartnerModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const { t } = this.props;

        return (

            <div className="container">
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            name: '',
                            destination : '',
                        }}
                        validationSchema={
                            Yup.object().shape({
                                name: Yup.string().required('Required'),
                                destination: Yup.string().required('Required'),
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            PartnerService.Create(values).then(
                                (result) => {
                                    toastSuccess(t('success_add_message'));
                                    this.props.onHide();
                                    this.props.refresh();
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                })
                        }}
                    >
                        {props => (
                            <Form>
                                {
                                    props.status &&
                                    <div className={'form-alert alert alert-danger'}>{props.status}</div>
                                }
                                <ModalHeader>
                                    <div className="modal-title">{t('add')} Partner</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="form-group">
                                        <label className="form-label">Partner</label>
                                        <Input
                                            name="name"
                                            type="text"
                                            placeholder="Partner"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('name')} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">{t('letter_address')}</label>
                                        <Textarea
                                            rows="4"
                                            name="destination"
                                            placeholder={t('letter_address')}
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('destination')} 
                                         />
                                    </div>
                                    <div className="form-group">
                                        <div className='d-flex'>
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={t('cancel')}
                                                onClick={this.props.onHide} />
                                            <Button type="submit" text={t('save')} disabled={props.isSubmitting} />
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}




import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import moment from 'moment';

import Input from '../../../components/Utils/Field/Input';
import { withTranslation } from "react-i18next";


class Product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {
        const { formik } = this.props;
        const { t } = this.props;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="product-information">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="form-label">{t('product_table')}</label>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product')}</th>
                                            <th>{t('batch_number')}</th>
                                            <th>{t('expired_date')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values?.caseProduct?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{record?.vaccineNavigation?.name ?? record?.drugsNavigation?.name ?? '-'}</td>
                                                <td>{record?.batch}</td>
                                                <td>{record?.expiredDate ? moment(record?.expiredDate).format('DD/MM/yyyy') : '-'}</td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values?.caseProduct === undefined || formik.values?.caseProduct === null || formik.values?.caseProduct?.length === 0) && (
                                                <tr>
                                                    <td colSpan="4" style={{ textAlign: "center" }}>
                                                        Data tidak ditemukan
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default withTranslation()(Product);
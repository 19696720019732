import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../../components/Utils/Button/Button';
import Input from '../../../../components/Utils/Field/Input';
import MultipleChoiceTitle from '../../../../components/Utils/Form/MultipleChoiceTitle';
import MultipleChoice from '../../../../components/Utils/Form/MultipleChoice';


class AefiList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            openFirst : true,
            openSecond : false,
            openThird : false,
            openFour : false,
            openFive : false,
            openSix : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    render() {
        const { formik, typecase, history, disabled, t, ...otherProps } = this.props;

        let question1;
        let question2;
        let question3;
        let question4;
        let question5;
        let question6;
        let question7;
        let question8;
        let question9;
        let question10;
        let question11;
        let question12;
        let question13;
        let question14;
        let question15;
        let question16;
        let question17;
        let question18;
        let question19;
        let question20;
        
        let question1Sidelight;
        let question2Sidelight;
        let question3Sidelight;
        let question4Sidelight;
        let question5Sidelight;
        let question6Sidelight;
        let question7Sidelight;
        let question8Sidelight;
        let question9Sidelight;
        let question10Sidelight;
        let question11Sidelight;
        let question12Sidelight;
        let question13Sidelight;
        let question14Sidelight;
        let question15Sidelight;
        let question16Sidelight;
        let question17Sidelight;
        let question18Sidelight;
        let question19Sidelight;
        let question20Sidelight;

        if(history){
            if(history?.analysisAefiList?.length > 0 || history?.trailAnalysisAefiList?.length > 0){
                let aefiListcausality = history.analysisAefiList ? history.analysisAefiList : history.trailAnalysisAefiList ;
                aefiListcausality.map((record, index) => {
                    if (record.question === "question1"){
                        question1 = record.answer !== null ? record.answer : "";
                        question1Sidelight = record.sidelight ? record.sidelight : "";
                    }
        
                    if (record.question === "question2"){
                        question2 = record.answer !== null ? record.answer : "";
                        question2Sidelight = record.sidelight ? record.sidelight : "";
                    }
        
                    if (record.question === "question3"){
                        question3 = record.answer !== null ? record.answer : "";
                        question3Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question4"){
                        question4 = record.answer !== null ? record.answer : "";
                        question4Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question5"){
                        question5 = record.answer !== null ? record.answer : "";
                        question5Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question6"){
                        question6 = record.answer !== null ? record.answer : "";
                        question6Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question7"){
                        question7 = record.answer !== null ? record.answer : "";
                        question7Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question8"){
                        question8 = record.answer !== null ? record.answer : "";
                        question8Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question9"){
                        question9 = record.answer !== null ? record.answer : "";
                        question9Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question10"){
                        question10 = record.answer !== null ? record.answer : "";
                        question10Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question11"){
                        question11 = record.answer !== null ? record.answer : "";
                        question11Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question12"){
                        question12 = record.answer !== null ? record.answer : "";
                        question12Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question13"){
                        question13 = record.answer !== null ? record.answer : "";
                        question13Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question14"){
                        question14 = record.answer !== null ? record.answer : "";
                        question14Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question15"){
                        question15 = record.answer !== null ? record.answer : "";
                        question15Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question16"){
                        question16 = record.answer !== null ? record.answer : "";
                        question16Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question17"){
                        question17 = record.answer !== null ? record.answer : "";
                        question17Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question18"){
                        question18 = record.answer !== null ? record.answer : "";
                        question18Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question19"){
                        question19 = record.answer !== null ? record.answer : "";
                        question19Sidelight = record.sidelight ? record.sidelight : "";
                    }
                    if (record.question === "question20"){
                        question20 = record.answer !== null ? record.answer : "";
                        question20Sidelight = record.sidelight ? record.sidelight : "";
                    }
                })
            }
        }

        let first_group = formik ? (formik?.values.question1 ? (formik?.values.question1 !== "" ? 1 : 0) : 0) : 0;
        let second_group = 0;
            second_group = formik ? (formik?.values.question2 ? (formik?.values.question2 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question3 ? (formik?.values.question3 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question4 ? (formik?.values.question4 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question5 ? (formik?.values.question5 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question6 ? (formik?.values.question6 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question7 ? (formik?.values.question7 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question8 ? (formik?.values.question8 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question9 ? (formik?.values.question9 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question10 ? (formik?.values.question10 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question11 ? (formik?.values.question11 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
            second_group = formik ? (formik?.values.question12 ? (formik?.values.question12 !== "" ? (second_group + 1) : second_group) : second_group) : second_group;
        let second_a_group = formik ? (formik?.values.question13 ? (formik?.values.question13 !== "" ? 1 : 0) : 0) : 0;
        let third_group = formik ? (formik?.values.question14 ? (formik?.values.question14 !== "" ? 1 : 0) : 0) : 0;
        let four_group = 0;
            four_group = formik ? (formik?.values.question15 ? (formik?.values.question15 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            four_group = formik ? (formik?.values.question16 ? (formik?.values.question16 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            four_group = formik ? (formik?.values.question17 ? (formik?.values.question17 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            four_group = formik ? (formik?.values.question18 ? (formik?.values.question18 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            four_group = formik ? (formik?.values.question19 ? (formik?.values.question19 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            four_group = formik ? (formik?.values.question20 ? (formik?.values.question20 !== "" ? (four_group + 1) : four_group) : four_group) : four_group;
            

        return (
            <>
                <div className="tab-pane" id="aefi-list">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="multiple-choice-wrapper">
                                <div style={{padding: "2% 3%"}}>
                                    <div className="row">
                                        <div className="col-sm-7 col-md-10">
                                        
                                        </div>
                                        <div className="col-sm-5 col-md-2">
                                            <div style={{display:"flex"}}>
                                                <p className="form-label">Y</p>
                                                <p className="form-label">{t('t')}</p>
                                                <p className="form-label">{t('td')}</p>
                                                <p className="form-label">NA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="multiple-choice-wrapper">
                                <MultipleChoiceTitle
                                    title={"I. " + t('first_group')}
                                    number={first_group}
                                    total="1"
                                    open={this.state.openFirst}
                                    handlespan={() => this.setState({openFirst : !this.state.openFirst})}
                                />
                                {
                                    this.state.openFirst && (
                                        <MultipleChoice
                                            question={"1. " + t('question_1')}
                                            name="question1"
                                            value={formik.values?.question1}
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            disabled={disabled}
                                            same={history.length !== 0 ? question1 === formik.values?.question1 : true }
                                        >
                                            <Input
                                                name="question1Sidelight"
                                                type="text"
                                                placeholder={t('required_info')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('question1Sidelight')} 
                                                disabled={disabled || !formik.values?.question1}
                                                same={history.length !== 0 ? question1Sidelight === formik.values?.question1Sidelight : true }
                                            />
                                        </MultipleChoice>
                                    )
                                }
                            </div>
                            <div className="multiple-choice-wrapper">
                                <MultipleChoiceTitle
                                    title={"II. " + t('second_group')}
                                    number={second_group}
                                    total="11"
                                    open={this.state.openSecond}
                                    handlespan={() => this.setState({openSecond : !this.state.openSecond})}
                                />
                                {
                                    this.state.openSecond && (
                                        <>
                                            <MultipleChoice
                                                question={"1. " + t('question_2')}
                                                name="question2"
                                                title="Produk Vaksin (Vaccine Product(s))"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question2}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question2 === formik.values?.question2 : true }
                                            >
                                                <Input
                                                    name="question2Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question2Sidelight')} 
                                                    disabled={disabled || !formik.values?.question2}
                                                    same={history.length !== 0 ? question2Sidelight === formik.values?.question2Sidelight : true }
                                                />
                                            </MultipleChoice>                                            
                                            <MultipleChoice
                                                question={"2. " + t('question_3')}
                                                name="question3"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question3}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question3 === formik.values?.question3 : true }
                                            >
                                                <Input
                                                    name="question3Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question3Sidelight')} 
                                                    disabled={disabled || !formik.values?.question3}
                                                    same={history.length !== 0 ? question3Sidelight === formik.values?.question3Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"3. " + t('question_4')}
                                                name="question4"
                                                value={formik.values?.question4}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question4 === formik.values?.question4 : true }
                                            >
                                                <Input
                                                    name="question4Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question4Sidelight')} 
                                                    disabled={disabled || !formik.values?.question4}
                                                    same={history.length !== 0 ? question4Sidelight === formik.values?.question4Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"4. " + t('question_5')}
                                                name="question5"
                                                title="Kualitas Vaksin (Vaccine Quality)"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question5}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question5 === formik.values?.question5 : true }
                                            >
                                                <Input
                                                    name="question5Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question5Sidelight')} 
                                                    disabled={disabled || !formik.values?.question5}
                                                    same={history.length !== 0 ? question5Sidelight === formik.values?.question5Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"5. " + t('question_6')}
                                                name="question6"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question6}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question6 === formik.values?.question6 : true }
                                            >
                                                <Input
                                                    name="question6Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question6Sidelight')} 
                                                    disabled={disabled || !formik.values?.question6}
                                                    same={history.length !== 0 ? question6Sidelight === formik.values?.question6Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"6. " + t('question_7')}
                                                name="question7"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question7}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question7 === formik.values?.question7 : true }
                                            >
                                                <Input
                                                    name="question7Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question7Sidelight')} 
                                                    disabled={disabled || !formik.values?.question7}
                                                    same={history.length !== 0 ? question7Sidelight === formik.values?.question7Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"7. " + t('question_8')}
                                                name="question8"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question8}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question8 === formik.values?.question8 : true }
                                            >
                                                <Input
                                                    name="question8Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question8Sidelight')} 
                                                    disabled={disabled || !formik.values?.question8}
                                                    same={history.length !== 0 ? question8Sidelight === formik.values?.question8Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"8. " + t('question_9')}
                                                name="question9"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question9}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question9 === formik.values?.question9 : true }
                                            >
                                                <Input
                                                    name="question9Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question9Sidelight')} 
                                                    disabled={disabled || !formik.values?.question9}
                                                    same={history.length !== 0 ? question9Sidelight === formik.values?.question9Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"9. " + t('question_10')}
                                                name="question10"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question10}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question10 === formik.values?.question10 : true }
                                            >
                                                <Input
                                                    name="question10Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question10Sidelight')} 
                                                    disabled={disabled || !formik.values?.question10}
                                                    same={history.length !== 0 ? question10Sidelight === formik.values?.question10Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"10. " + t('question_11')}
                                                name="question11"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question11}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question11 === formik.values?.question11 : true }
                                            >
                                                <Input
                                                    name="question11Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question11Sidelight')} 
                                                    disabled={disabled || !formik.values?.question11}
                                                    same={history.length !== 0 ? question11Sidelight === formik.values?.question11Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"11. " + t('question_12')}
                                                name="question12"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question12}
                                                title="Kecemasan pasca Imunisasi (Immunization triggered Stress Response - ITSR)"
                                                disabled={disabled}
                                                same={history.length !== 0 ? question12 === formik.values?.question12 : true }
                                            >
                                                <Input
                                                    name="question12Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question12Sidelight')} 
                                                    disabled={disabled || !formik.values?.question12}
                                                    same={history.length !== 0 ? question12Sidelight === formik.values?.question12Sidelight : true }
                                                />
                                            </MultipleChoice>
                                        </>
                                    )
                                }
                            </div>
                            <div className="multiple-choice-wrapper">
                                <MultipleChoiceTitle
                                    title={"II. " + t('second_a_group')}
                                    number={second_a_group}
                                    total="1"
                                    open={this.state.openThird}
                                    handlespan={() => this.setState({openThird : !this.state.openThird})}
                                    
                                />
                                {
                                    this.state.openThird && (
                                        <MultipleChoice
                                            question={"1. " + t('question_13')}
                                            name="question13"
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={formik.values?.question13}
                                            disabled={disabled}
                                            same={history.length !== 0 ? question13 === formik.values?.question13 : true }
                                        >
                                            <Input
                                                name="question13Sidelight"
                                                type="text"
                                                placeholder={t('required_info')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('question13Sidelight')} 
                                                disabled={disabled || !formik.values?.question13}
                                                same={history.length !== 0 ? question13Sidelight === formik.values?.question13Sidelight : true }
                                            />
                                        </MultipleChoice>
                                    )
                                }
                            </div>
                            <div className="multiple-choice-wrapper">
                                <MultipleChoiceTitle
                                    title={"III. " + t('third_group')}
                                    number={third_group}
                                    total="1"
                                    open={this.state.openFour}
                                    handlespan={() => this.setState({openFour : !this.state.openFour})}
                                />
                                {
                                    this.state.openFour && (
                                        <MultipleChoice
                                            question={"1. " + t('question_14')}
                                            name="question14"
                                            onChange={formik.setFieldValue}
                                            onBlur={formik.setFieldTouched}
                                            value={formik.values?.question14}
                                            disabled={disabled}
                                            same={history.length !== 0 ? question14 === formik.values?.question14 : true }
                                        >
                                            <Input
                                                name="question14Sidelight"
                                                type="text"
                                                placeholder={t('required_info')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('question14Sidelight')} 
                                                disabled={disabled || !formik.values?.question14}
                                                same={history?.question14Sidelight ? question14Sidelight === formik.values?.question14Sidelight : true }
                                            />
                                        </MultipleChoice>
                                    )
                                }
                            </div>
                            <div className="multiple-choice-wrapper">
                                <MultipleChoiceTitle
                                    title={"IV. " + t('four_group')}
                                    number={four_group}
                                    total="6"
                                    open={this.state.openFive}
                                    handlespan={() => this.setState({openFive : !this.state.openFive})}
                                />
                                {
                                    this.state.openFive && (
                                        <>
                                            <MultipleChoice
                                                question={"1. " + t('question_15')}
                                                name="question15"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question15}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question15 === formik.values?.question15 : true }
                                            >
                                                <Input
                                                    name="question15Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question15Sidelight')} 
                                                    disabled={disabled || !formik.values?.question15}
                                                    same={history?.question15Sidelight ? question15Sidelight === formik.values?.question15Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"2. " + t('question_16')}
                                                name="question16"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question16}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question16 === formik.values?.question16 : true }
                                            >
                                                <Input
                                                    name="question16Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question16Sidelight')} 
                                                    disabled={disabled || !formik.values?.question16}
                                                    same={history?.question16Sidelight ? question16Sidelight === formik.values?.question16Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"3. " + t('question_17')}
                                                name="question17"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question17}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question17 === formik.values?.question17 : true }
                                            >
                                                <Input
                                                    name="question17Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question17Sidelight')} 
                                                    disabled={disabled || !formik.values?.question17}
                                                    same={history.length !== 0 ? question17Sidelight === formik.values?.question17Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"4. " + t('question_18')}
                                                name="question18"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question18}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question18 === formik.values?.question18 : true }
                                            >
                                                <Input
                                                    name="question18Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question18Sidelight')} 
                                                    disabled={disabled || !formik.values?.question18}
                                                    same={history.length !== 0 ? question18Sidelight === formik.values?.question18Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"5. " + t('question_19')}
                                                name="question19"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question19}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question19 === formik.values?.question19 : true }
                                            >
                                                <Input
                                                    name="question19Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question19Sidelight')} 
                                                    disabled={disabled || !formik.values?.question19}
                                                    same={history.length !== 0 ? question19Sidelight === formik.values?.question19Sidelight : true }
                                                />
                                            </MultipleChoice>
                                            <MultipleChoice
                                                question={"6. " + t('question_20')}
                                                name="question20"
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                                value={formik.values?.question20}
                                                disabled={disabled}
                                                same={history.length !== 0 ? question20 === formik.values?.question20 : true }
                                            >
                                                <Input
                                                    name="question20Sidelight"
                                                    type="text"
                                                    placeholder={t('required_info')}
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    {...formik.getFieldProps('question20Sidelight')} 
                                                    disabled={disabled || !formik.values?.question20}
                                                    same={history.length !== 0 ? question20Sidelight === formik.values?.question20Sidelight : true }
                                                />
                                            </MultipleChoice>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default AefiList;
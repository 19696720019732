import React, { Component } from 'react';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import Select2 from '../../../components/Utils/Field/Select2';


export class AddCausalityEksternalModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            snackbaropen: false,
            snackbarmsg: '',
            causalityRelation : [],
        };

        this.formikRef = React.createRef();
    }


    handleChangeCausality(name, value){
        this.formikRef.current.setFieldValue(name, value)
        this.formikRef.current.setFieldValue("causalities", [])
    }


    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {
        const { data_parent, history, list, causality, index, disabled, i18next, formType, t } = this.props;
        let fuseListKIPI = [];

        if(this.props.list?.causalityAEFIComplete && this.props.list?.causalityAEFIIncomplete){
            
            this.props.list.causalityAEFIComplete.map((element, i) => 
                fuseListKIPI = [...fuseListKIPI, element]
            )

            this.props.list.causalityAEFIIncomplete.map((element, i) => 
                fuseListKIPI = [...fuseListKIPI, element]
            )
        }
        
        const causalityRelation = this.props.data_parent.values.caseType === "KIPI" ? fuseListKIPI : this.props.list?.causalityAE ;

        let causal = causality ? causality[index] : [];

        let causalities = [];

        if(causal?.analysisCausalityExternal?.length > 0 || causal?.trailAnalysisCausalityExternal){
            let causalityExternal = causal?.analysisCausalityExternal ? causal?.analysisCausalityExternal : causal?.trailAnalysisCausalityExternal
            causalityExternal.map((record, index) => {
                causalities = [...causalities, record.causality]
            })
        }

        let causalHistory = [];

        let causalitiesHistory = [];

        if(history !== undefined && causal){
            history.map((record, index) => {
                if(record.productName === causal.productName){
                    causalHistory = history[index];
                }
            })

            if(causalHistory?.analysisCausalityExternal?.length > 0 || causalHistory?.trailAnalysisCausalityExternal){
                let causalityExternal = causalHistory?.analysisCausalityExternal ? causalHistory?.analysisCausalityExternal : causalHistory?.trailAnalysisCausalityExternal
                causalityExternal.map((record, index) => {
                    causalitiesHistory = [...causalitiesHistory, record.causality]
                })
            }
        }

        return (
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props} size="lg">
                    <ModalHeader>
                        <div className="modal-title">{t('add_causality')}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <Formik
                            innerRef={this.formikRef}
                            initialValues={{
                                causalityRelation: causal?.causalityExternal ? causal?.causalityExternal : "",
                                causalities : causalities
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    causalityRelation: Yup.string().required("Required"),
                                })
                            }
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                setStatus();

                                let causal = [...causality]
                                let i = causalityRelation?.map(function(x) {return x.id; }).indexOf(values.causalityRelation);

                                causal[this.props.index].causalityName = causalityRelation[i].name;
                                causal[this.props.index].causalityNameEn = causalityRelation[i].nameEnTranslate;
                                causal[this.props.index].causalityExternal = values.causalityRelation;
                                
                                let causalities = values?.causalities ? values?.causalities : [];
                                
                                if(values?.causalityRelation === "ac918a14-7812-4119-a955-71017c5c549d"){
                                    causalities = [...causalities, "a4bab67b-fd61-4f97-8167-e5b5816e05b7"]
                                }

                                let causalitiesExternal = [];
                                causalities.map((record, index) =>
                                {
                                    causalitiesExternal = [...causalitiesExternal, { causality : record }]; 
                                })
                                
                                causal[this.props.index].causalitiesExternal = causalitiesExternal;
                                causal[this.props.index].analysisCausalityExternal = causalitiesExternal;

                                this.props.handleCausality(causal)
                                this.props.onhide()
                            }}
                        >
                        {formik => (
                            <Form>
                                <div className="form-group">
                                    <label className="form-label">{t('causality_relation')}</label>
                                    <Select2
                                        name="causalityRelation"
                                        options={causalityRelation?.map(item => { return { value: item.id, label: i18next.language === 'id' || i18next.language === 'ID' ? item.name : item.nameEnTranslate }; })}
                                        value={formik.values.causalityRelation}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        onChange={(name, value) => this.handleChangeCausality(name, value)}
                                        onBlur={formik.setFieldTouched}
                                        disabled={disabled}
                                        same={causalHistory?.causalityExternal ? causalHistory.causalityExternal === formik.values?.causalityRelation : true }
                                    />
                                </div>
                                <div className="form-group">
                                    {
                                        formik.values.causalityRelation === "f3efe9ef-55da-451b-95da-0d57fabde2b2" && list?.causalityAEFIA.map((record, index) => 
                                            {
                                                return (
                                                    <>
                                                        <div>
                                                            <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                    onChange={formik.handleChange} 
                                                                    disabled={disabled}
                                                                    checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                                />
                                                                <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                    {
                                        formik.values.causalityRelation === "1d3dfa41-ad04-4d96-8128-8171cf9ffffb" && list?.causalityAEFIB.map((record, index) => 
                                            {
                                                return (
                                                    <>
                                                        <div>
                                                            <div className={causalitiesHistory.length !== 0 ? (((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1)) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }> 
                                                                <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                    onChange={formik.handleChange} 
                                                                    disabled={disabled}
                                                                    checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                                />
                                                                <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                    {
                                        formik.values.causalityRelation === "0c1b9b34-fbf7-4aee-b29c-2c3a783c9918" && list?.causalityAEFIC.map((record, index) => 
                                            {
                                                return (
                                                    <>
                                                        <div>
                                                            <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                                <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                    onChange={formik.handleChange} 
                                                                    disabled={disabled}
                                                                    checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                                />
                                                                <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    }
                                    {
                                        formik.values.causalityRelation === "ad15f06f-d666-4919-b731-0ad6e35919fd" && list?.causalityAECertain.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        formik.values.causalityRelation === "41ea1db6-00ce-4594-ad04-ee4970ed9cd5" && list?.causalityAEProbable.map((record, index) => 
                                        {   
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        formik.values.causalityRelation === "96d11646-1388-4cf7-8577-5097c0517379" && list?.causalityAEPossible.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        formik.values.causalityRelation === "6759a25d-aaf9-44cb-9056-b65541b71fa5" && list?.causalityAEUnlikely.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        formik.values.causalityRelation === "68dca9ce-4514-4485-b41a-9b724b5546c2" && list?.causalityAEConditional.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                    {
                                        formik.values.causalityRelation === "61181257-bf09-4284-986e-e7178c3f4041" && list?.causalityAEUnassessable.map((record, index) => 
                                        {
                                            return (
                                                <>
                                                    <div>
                                                        <div className={causalitiesHistory.length !== 0 ? ((causalitiesHistory?.indexOf(record.id) !== -1) !== (formik.values?.causalities?.indexOf(record.id) !== -1) ? "form-check form-check-inline change-value-radio" : "form-check form-check-inline") : "form-check form-check-inline" }>
                                                            <input type="checkbox" className="form-check-input" name={"causalities"} value={record.id}
                                                                onChange={formik.handleChange} 
                                                                disabled={disabled}
                                                                checked={formik.values?.causalities?.indexOf(record.id) !== -1}
                                                            />
                                                            <label className="form-check-label">{i18next.language === 'id' || i18next.language === 'ID'  ? record.name : record.nameEnTranslate }</label>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                                <hr/>
                                <div className='text-right'>
                                    <Button
                                        color="#495057"
                                        background="#FFFFFF 0% 0% no-repeat padding-box"
                                        border="1px solid #495057"
                                        text={t('cancel')}
                                        onClick={this.props.onhide} 
                                    />
                                    {
                                        disabled === false && (
                                            <Button 
                                                type="submit" 
                                                text={t('save')}
                                                
                                            />
                                        )
                                    }
                                </div>
                            </Form>
                        )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
import React, { Component } from 'react';

import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';
import Input from '../../../../components/Utils/Field/Input';

import { LetterService } from '../../../../services/Master/LetterService';
import { authenticationService } from '../../../../services/AuthService';

import { Role, Status } from '../../../../helpers/Constants';
import LetterUploadFile from '../../LetterUploadFile';

import { withTranslation } from "react-i18next";

class LetterInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            selectedData: [],
            isComplete: "",
            letterShow: false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: ""
        };
    }
    


    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        LetterService.Read(this.state.page, this.state.limit, this.state.query).then(result => {
            this.props.formik.setFieldValue('letterNumber1', result?.data.records[0].code );
            this.props.formik.setFieldValue('letterNumber3', result?.data.records[0].shortName );
        });
    }


    handleCancelClick(event) {

        this.props.history.push('/report-submission/reporter');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;
        const currentUser = authenticationService.currentUserValue;
        const disabled = currentUser.role === Role.Staff && formik.values.status === 'Approved' ? false : true;

        return (
            <>
                <div className="tab-pane show tab-pane-fixed" id="letter-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('letter_number')}</label>
                                <div style={{display:"flex"}}>
                                    <input
                                        disabled
                                        name="letterNumber1"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('letterNumber1')}
                                    />
                                    -
                                    <Input
                                        disabled={disabled}
                                        name="mailingOrder"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('mailingOrder')}
                                        same={historydata?.mailingOrder ? historydata.mailingOrder === formik.values?.mailingOrder : true }
                                    />
                                    /
                                    <Input
                                        disabled
                                        name="letterNumber3"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('letterNumber3')}
                                    />
                                    /
                                    <Input
                                        disabled={disabled}
                                        name="mailingMonth"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('mailingMonth')}
                                        same={historydata?.mailingMonth ? historydata.mailingMonth === formik.values?.mailingMonth : true }
                                    />
                                    /
                                    <Input
                                        disabled={disabled}
                                        name="mailingYear"
                                        type="text"
                                        className="form-control fit-content"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('mailingYear')}
                                        same={historydata?.mailingYear ? historydata.mailingYear === formik.values?.mailingYear : true }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('send_date')}</label>
                                <DatePicker
                                    disabled={disabled}
                                    name="mailingSentDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.mailingSentDate}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Komponen untuk upload file di tab surat */}
                    <LetterUploadFile 
                        formik = {formik}
                        folderName = "bpom_partner_attachment"
                        disabled={disabled}
                    />
                </div>
            </>
        )
    }

}

export default withTranslation()(LetterInformation);
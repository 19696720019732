import React, { Component } from 'react';
import { Link } from "react-router-dom";

import { authenticationService } from '../../../services/AuthService';

export class ProfileMenu extends Component {

    render() {

        const currentUser = authenticationService.currentUserValue;
        const imageUrl = currentUser?.photo ? `data:image/png;base64,${currentUser?.photo}`: '../assets/images/users/user.svg';

        return (
            <>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={imageUrl} alt="user" className="rounded-circle" width="30" style={{objectFit: 'cover', width: '30px', height: '30px'}} />
                    <span className="ml-2 d-none d-lg-inline-block"><span>Hello,</span> 
                        <span className="text-dark">{ currentUser?.name } </span> 
                        <i data-feather="chevron-down" className="svg-icon"/>
                    </span>
                </a>
                <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                    <Link className="dropdown-item" to="/profile">
                        <i data-feather="user" className="svg-icon mr-2 ml-1"></i>
                        Profile
                    </Link>
                    <Link className="dropdown-item" to="/login" onClick={() => authenticationService.logout()}>
                        <i data-feather="power" className="svg-icon mr-2 ml-1"></i>
                        Logout
                    </Link>
                </div>
            </li>
            </>
        );
    }
}
import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import { Table } from 'react-bootstrap';

import Button from '../../../../components/Utils/Button/Button';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import { LetterModal } from "./LetterModal";

import { withTranslation } from "react-i18next";

class AnalysisResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            selectedData : [],
            isComplete : "",
        };
    }
    

    handleCancelClick(event) {

        this.props.history.push('/report-submission/bpom');
        event.preventDefault();
    }

    render() {
        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        const { formik, typecase, historydata, t, ...otherProps } = this.props;

        return (
            <>
                <LetterModal
                  show={this.state.letterShow}
                  onHide={LetterModalClose}
                  formik={formik}
                />

                <div className="tab-pane tab-pane-fixed" id="analysis-result">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('province')}</label>
                                <Select2
                                    disabled
                                    name="analyzerProvince"
                                    options={this.props.provinceList?.map(item => { return { value: item.code, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.analyzerProvince}
                                    same={historydata?.analyzerProvince ? historydata.analyzerProvince === formik.values?.analyzerProvince : true }
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('diagnose')}</label>
                                <Input
                                    disabled
                                    name="diagnosis"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('diagnosis')}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">

                            <label className="form-label">{t('analysis_external_caps')}</label>

                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product_name')}</th>
                                            <th>{t('field_study')}</th>
                                            <th>{t('causality_study')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values.analysisResult?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`analysisResult[${index}][productName]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`analysisResult[${index}][productName]`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`analysisResult[${index}][fieldAnalysisExtText]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`analysisResult[${index}][fieldAnalysisExtText]`)}
                                                    />
                                                </td>
                                                <td>
                                                    {record.causalityAnalysisExternal?.map((record2, index2) =>
                                                        <Input
                                                            disabled
                                                            name={`analysisResult[${index}][causalityAnalysisExternal][${index2}][causalityAnalysisText]`}
                                                            type="text"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps(`analysisResult[${index}][causalityAnalysisExternal][${index2}][causalityAnalysisText]`)}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values.analysisResult === undefined || formik.values.analysisResult === null) && (
                                                <tr>
                                                    <td colSpan="4" style={{textAlign:"center"}}>
                                                        {t('data_not_found')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>

                            <label className="form-label">{t('analysis_pv_caps')}</label>

                            <div className="form-group">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>{t('product_name')}</th>
                                            <th>{t('field_study')}</th>
                                            <th>{t('causality_study')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formik.values.analysisResult?.map((record, index) =>
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`analysisResult[${index}][productName]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`analysisResult[${index}][productName]`)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        disabled
                                                        name={`analysisResult[${index}][fieldAnalysisUpvText]`}
                                                        type="text"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps(`analysisResult[${index}][fieldAnalysisUpvText]`)}
                                                    />
                                                </td>
                                                <td>
                                                    {record.causalityAnalysisUpv?.map((record2, index2) =>
                                                        <Input
                                                            disabled
                                                            name={`analysisResult[${index}][causalityAnalysisUpv][${index2}][causalityAnalysisText]`}
                                                            type="text"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps(`analysisResult[${index}][causalityAnalysisUpv][${index2}][causalityAnalysisText]`)}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                        {
                                            (formik.values.analysisResult === undefined || formik.values.analysisResult === null) && (
                                                <tr>
                                                    <td colSpan="4" style={{textAlign:"center"}}>
                                                        {t('data_not_found')}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('progress_report_letter_to_bpom')}</label>
                                <br/>
                                <Button
                                    text={t('data_preview')}
                                    onClick={() => this.setState({ letterShow: true })}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.props.nextTab != "" &&
                        <div className='text-right'>
                            <ToggleDisplay>
                                <Button
                                    color="#495057"
                                    background="#FFFFFF 0% 0% no-repeat padding-box"
                                    border="1px solid #495057"
                                    text={t('back')}
                                    onClick={this.props.handleBackClick}
                                />
                            </ToggleDisplay>
                            <ToggleDisplay>
                                <Button
                                    text={t('next')}
                                    onClick={this.props.handleNextClick}
                                    isSubmitting={this.state.isDisableNext}
                                />
                            </ToggleDisplay>
                        </div>
                    }
                </div>
            </>
        )
    }

}

export default withTranslation()(AnalysisResult);

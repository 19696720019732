import React from 'react';
import Select2 from '../../components/Utils/Field/Select2';
import Select2Multi from '../../components/Utils/Field/Select2Multi';
import { ManufactureService } from '../../services/Master/ManufactureService';
import { OrganizationService } from '../../services/Master/OrganizationService';
import { withTranslation } from "react-i18next";


class FormReminder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            manufacturList: [],
            organizationList: []
        };
    }

    componentDidMount() {
        ManufactureService.Read(1, 0, "").then(result => {
            this.setState({
                manufacturList: result.data.records
            });
        });

        OrganizationService.Read(1, 0, "").then(result => {
            this.setState({
                organizationList: result.data.records
            });
        });
    }

    render() {

        const { formik, typecase, historydata, list, ...otherProps } = this.props;
        const { manufacturList, organizationList } = this.state;
        const { t } = this.props;

        let manufactureDefined = (manufacturList.map(manufacture => manufacture.id))?.includes(formik?.values?.instance) ? formik?.values?.instance : null;
        let organizationDefined = (organizationList.map(organization => organization.id))?.includes(formik?.values?.instance) ? formik?.values?.instance : null;

        let disabledState = {
            'region': manufactureDefined !== null,
            'product': (manufactureDefined == null && organizationDefined == null) || (manufactureDefined == null && organizationDefined !== null),
        };

        let listIntance = [...manufacturList, ...organizationList, { id: "BPOM", name: "BPOM" }]

        let productList = formik?.values.caseType === "2" ? list?.vaccineList : list?.drugsList;

        return (
            <>
                <div className="row">
                    <div className="col-6">
                        <div className="form-group">
                            <label className="form-label">{t('instance')}</label>
                            <Select2
                                name="instance"
                                options={listIntance?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.instance}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('case_category')}</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input type="radio" className="form-check-input" value="2" name="caseType"
                                        checked={formik?.values?.caseType === "2"}
                                        onChange={formik.handleChange} />
                                    <label className="form-check-label">{t('aefi')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input type="radio" className="form-check-input" value="1" name="caseType"
                                        checked={formik?.values?.caseType === "1"}
                                        onChange={formik.handleChange} />
                                    <label className="form-check-label">{t('ae')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('seriousness_level')}</label>
                            <Select2
                                name="seriousness"
                                options={list?.seriousnessList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.seriousness}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('expectedness')}</label>
                            <Select2
                                name="expectedness"
                                options={list?.expectednessList?.map(item => { return { value: item.id, label: item.name }; })}
                                errors={formik.errors}
                                touched={formik.touched}
                                value={formik?.values?.expectedness}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="form-group">
                            <label className="form-label">{t('region')}</label>
                            <div>
                                <div className="form-check form-check-inline">
                                    <input type="radio" className="form-check-input" value="0" name="isOverseas"
                                        checked={formik?.values?.isOverseas === "0"}
                                        onChange={formik.handleChange}
                                        disabled={disabledState?.region}
                                    />
                                    <label className="form-check-label">{t('domestic')}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input type="radio" className="form-check-input" value="1" name="isOverseas"
                                        checked={formik?.values?.isOverseas === "1"}
                                        onChange={formik.handleChange}
                                        disabled={disabledState?.region}
                                    />
                                    <label className="form-check-label">{t('overseas')}</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('product')}</label>
                            <Select2Multi
                                options={productList?.map(item => { return { value: item.id, label: item.name }; })}
                                value={formik.values.products}
                                name="products"
                                errors={formik.errors}
                                touched={formik.touched}
                                onChange={formik.setFieldValue}
                                onBlur={formik.setFieldTouched}
                                disabled={disabledState?.product}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-label">{t('time_limit')}</label>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input
                                        name="dueAmount"
                                        type="number"
                                        min={0}
                                        placeholder="Batas Waktu"
                                        className="form-control"
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik?.values?.dueAmount ? formik.values?.dueAmount : ""}
                                        {...formik.getFieldProps('dueAmount')}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <Select2
                                        name="dueType"
                                        options={[{ value: "d", label: t('day') }, { value: "m", label: t('month') }, { value: "y", label: t('year') }]}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik?.values?.dueType}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(FormReminder);
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ToggleDisplay from "react-toggle-display";

import Button from "../../components/Utils/Button/Button";
import { toastSuccess, toastError } from "../../components/Utils/Toast/Toast";
import { ClinicalTrialService } from "../../services/ClinicalTrial/ClinicalTrialService";
import { DropdownService } from "../../services/Master/AllDropdownService";
import { authenticationService } from "../../services/AuthService";
import { Role } from "../../helpers/Constants";

import FormClinicalTrial from "./FormClinicalTrial";
import { withTranslation } from "react-i18next";

class DetailClinicalTrial extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      snackbaropen: false,
      snackbarmsg: "",
      clinicalTrialDetail: [],
      id: id,
    };

    this.getData(id);
  }

  getData(id) {
    this.state.clinicalTrialDetail = [
      {
        trialName: "",
        ageRange: "",
        purpose: "",
        totalMale: 0,
        totalFemale: 0,
        total: 0,
      },
    ];

    DropdownService.MasterList(["vaccine", "drugs"]).then((result) => {
      this.setState({
        productList: [
          ...result.data.vaccineList.map((item) => {
            return { value: item.id, label: item.name, type: "vaccine" };
          }),
          ...result.data.drugsList.map((item) => {
            return { value: item.id, label: item.name, type: "drugs" };
          }),
        ],
      });
    });

    ClinicalTrialService.PrepareForm(id).then((result) => {
      this.setState({
        data: result.data,
        clinicalTrialDetail: result.data.clinicalTrialDetail,
      });
    });
  }

  cancel = () => {
    this.props.history.push(`/clinical-trial`, {
      pages: this.props?.location?.state?.page,
    });
  };

  render() {
    const { t } = this.props;

    const clinical = this.state.data;

    const initial = {
      id: this.state.id,
      product: clinical?.product,
      clinicalTrialDetail: clinical?.clinicalTrialDetail,
      totalSubject: clinical?.totalSubject,
    };

    let currentUser = authenticationService.currentUserValue;

    return (
      <>
        <div>
          <Formik
            enableReinitialize
            initialValues={initial}
            validationSchema={Yup.object().shape({
              product: Yup.string().required("Required").nullable(),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus();

              var product = this.state.productList?.filter((obj) => {
                return obj.value === values.product;
              })[0];

              values.type = product.type;
              values.product = product.value;
              values.productName = product.label;

              values.clinicalTrialDetail = values.clinicalTrialDetail?.filter(
                (obj) => {
                  return obj != null;
                }
              );

              ClinicalTrialService.Update(values.id, values).then(
                (result) => {
                  toastSuccess("Berhasil mengubah data");
                  this.props.history.push(`/clinical-trial`);
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }}
          >
            {(formik) => (
              <Form>
                <div className="tab-content">
                  <FormClinicalTrial
                    formik={formik}
                    clinicalTrialDetail={this.state.clinicalTrialDetail}
                    productList={this.state.productList}
                  />
                </div>
                <div className="text-right">
                  <ToggleDisplay>
                    <Button
                      color="#495057"
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      border="1px solid #495057"
                      text={t("cancel")}
                      onClick={() => this.cancel()}
                    />
                  </ToggleDisplay>
                  {currentUser.role === Role.Staff && (
                    <ToggleDisplay>
                      <Button
                        type="submit"
                        text={t("save")}
                        disabled={formik.isSubmitting}
                      />
                    </ToggleDisplay>
                  )}
                  {formik.status && (
                    <div className={"alert alert-danger"}>{formik.status}</div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    );
  }
}

export default withTranslation()(DetailClinicalTrial);

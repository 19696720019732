import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import { Loader } from '../../../components/Utils/Loader/Loader';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';
import Select from 'react-select';

import { BiovacService } from '../../../services/Reconciliation/BiovacService';
import { DropdownService } from '../../../services/Master/AllDropdownService';


export class Biovac extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            params: {},
            loading: false,
            timezone: moment(new Date()).format('Z'),
        }

    }

    componentDidMount() {
        this.refreshList();

        DropdownService.MasterList(["seriousness", "expectedness"]).then(
            (result) => {
                this.setState({ list: result.data });
            })
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    refreshList() {
        this.handleLoading(true);

        BiovacService.Read(this.state.page, this.state.limit, this.state.query, this.state.params).then(result => {
            this.setState({
                maxPage: result?.data?.maxPage,
                records: result?.data?.records,
            });
            this.handleLoading(false);
        });
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null ? start.replaceAll("/", "-") + "Z" + this.state.timezone : "";
        let endDate = end !== "" && end !== null ? end.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }

    handleExpectednessChange(item) {

        let data = this.state.params;
        data = { ...data, expectedness: item?.value ?? "" };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }

    handleSeriousnessChange(item) {

        let data = this.state.params;
        data = { ...data, seriousness: item?.value ?? "" };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.refreshList();
        event.preventDefault();
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    }

    async download() {
        var download = BiovacService.Download(this.state.params)

        download.then((data) => {
            if (data?.status == 400) {
                toastError(data.message);
            }
        });
    }

    render() {

        const { records } = this.state;

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }

                {/* Modal List */}


                {/* Content */}

                <div className="tab-content">

                    <div className="row" style={{ marginTop: 15 }}>
                        <div className="col-xs-6 col-sm-6 col-md-2">
                            <div style={{ marginBottom: 15 }}>
                                <ButtonWithIcon
                                    text={`Download`}
                                    icon="fa fa-download"
                                    onClick={() => this.download()}
                                />
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-5">

                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-3">
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-2">
                            <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                                <ButtonWithIcon
                                    text="Filter"
                                    icon="fas fa-bars"
                                    onClick={() => this.setState({ filterShow: !this.state.filterShow })}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.filterShow && (
                            <div className="row filter-wrapper" style={{ marginTop: 10, marginBottom: 10 }}>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-md-3" style={{ marginBottom: 10 }}>
                                                <label className="form-label">Pencarian</label>
                                                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control"
                                                                value={this.state.query}
                                                                onChange={(event) => this.handleSearchChange(event)}
                                                                placeholder="Masukkan Nomor Kasus"
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3">
                                                <label className="form-label">Tingkat Keseriusan</label>
                                                <Select
                                                    placeholder='Semua Tingkat'
                                                    width='100%'
                                                    name="seriousnessFilter"
                                                    isClearable="true"
                                                    classNamePrefix="select"
                                                    className="basic-single"
                                                    options={this.state.list?.seriousnessList.map(x => { return { value: x.id, label: x.name }; })}
                                                    value={this.state.seriousness}
                                                    onChange={(item) => this.handleSeriousnessChange(item)} >
                                                </Select>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 col-md-3">
                                                <label className="form-label">Tingkat Expectedness</label>
                                                <Select
                                                    placeholder='Semua Tingkat'
                                                    width='100%'
                                                    name="expectednessFilter"
                                                    isClearable="true"
                                                    classNamePrefix="select"
                                                    className="basic-single"
                                                    options={this.state.list?.expectednessList.map(x => { return { value: x.id, label: x.name }; })}
                                                    value={this.state.expectedness}
                                                    onChange={(item) => this.handleExpectednessChange(item)} >
                                                </Select>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        )
                    }

                    <div className="tab-pane show active">
                        <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
                            <Table className="pb-5" hover>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Nomor Kasus</th>
                                        <th>ID Kasus Manufactur</th>
                                        <th>Data Vaksin</th>
                                        <th>Keluhan Pasien</th>
                                        <th>Informasi Pasien</th>
                                        <th>Tingkat Keseriusan</th>
                                        <th>Tingkat Expectedness</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {records.map((record, index) =>
                                        <>
                                            <tr key={record.id}>
                                                <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                                <td>{record.caseNumber}</td>
                                                <td>{record.manufactureCaseNumber}</td>
                                                <td>{(record.aefiVaccine?.length > 0 ? record.aefiVaccine.filter(x => x.vaccine != null).map(x => x.vaccineNavigation?.name).join(", ") : "") + "-"}</td>
                                                <td>
                                                    {(record.aefiSymptomps?.length > 0 ? record.aefiSymptomps.filter(x => x.symptomps != null).map(x => x.symptompsNavigation?.name).join(", ") : "") + " "}
                                                    {(record.aefiAllergic?.length > 0 ? record.aefiAllergic.filter(x => x.allergic != null).map(x => x.allergicNavigation?.name).join(", ") : "") + " "}
                                                </td>
                                                <td>{record.patientName + " - " + record.patientAgeYear + 'yo' + record.patientAgeMonth + 'mo' + " - " + record.patientGender}</td>
                                                <td>{record.seriousnessNavigation?.name ?? "-"}</td>
                                                <td>{record.expectednessNavigation?.name ?? "-"}</td>
                                            </tr>
                                        </>
                                    )}
                                    {
                                        records.length < 1 && (
                                            <tr>
                                                <td colSpan="12" style={{ textAlign: "center" }}>
                                                    Data tidak ditemukan
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <nav>
                            <ReactPaginate
                                previousLabel={(<i class="fas fa-arrow-left"></i>)}
                                previousLinkClassName={"page-link"}
                                nextLabel={(<i class="fas fa-arrow-right"></i>)}
                                nextLinkClassName={"page-link"}
                                pageCount={this.state.maxPage}
                                containerClassName={"pagination justify-content-end"}
                                pageClassName={"page-item"}
                                breakClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                onPageChange={(event) => this.handlePageClick(event)}
                            />
                        </nav>
                    </div>
                </div>
            </>
        );
    }
}
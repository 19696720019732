import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons'

import { FileService } from '../../services/FileService';
import Button from '../../components/Utils/Button/Button';

import { withTranslation } from "react-i18next";

class LetterUploadFile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            selectedData: [],
            isComplete: "",
            fileList: [],
        };
        this.fileRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.formik?.value?.originalRecapLetter !== undefined && prevProps.formik?.value?.originalRecapLetter !== this.props.formik?.value.originalRecapLetter) {
            if (this.props.formik?.value?.originalRecapLetter !== undefined && this.props.formik?.value?.originalRecapLetter !== null) {
                let file = []
                this.props.formik.value.originalRecapLetter.map((val, index) => {
                    file = [...file, { fileName: val }]
                })

                this.setState({ fileList: [...file] })
            }
        }
    }

    componentDidMount() {

    }

    openfileDialog = () => {
        this.fileRef.current.click();
    }

    handleChange(selectorFiles) {
        FileService.Upload(selectorFiles.files, this.props.folderName).then((success) => {
            if (success.status === 200) {
                this.setState({ fileList: [...this.state.fileList, success.data] })
                selectorFiles.value = null;

                /* Update value formik files */
                if (this.props.formik) {
                    this.props.formik.setFieldValue('originalRecapLetter', this.state.fileList.map(dt => dt.fileName))
                }
            }
        }, (error) => {
            console.log('error', error)
        })
    }

    handleFileRemove(index) {
        let data = this.state.fileList;
        if (data.length > 1) {
            data.splice(index, 1)
            this.setState({ fileList: data })
        } else {
            this.setState({ fileList: [] })
        }

        this.props.formik.setFieldValue('originalRecapLetter', this.state.fileList.map(dt => dt?.fileName))
    }

    render() {
        const { formik, t } = this.props;
        return (
            <>
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label">{t('attachment')}</label>
                        </div>
                        <input type="file" ref={this.fileRef} style={{ display: "none" }} onChange={(e) => this.handleChange(e.target)} accept="application/pdf,image/*" />
                        <Button
                            text="Upload File"
                            onClick={this.openfileDialog}
                            isSubmitting={this.props.disabled}
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <div className='col-md-12'>
                        <div className='card-lampiran' style={{ marginTop: 10 }}>
                            {
                                formik.values.originalRecapLetter && (
                                    <div style={{ fontSize: 12, opacity: "0.5", marginBottom: 30 }}>
                                        <i className="fas fa-paperclip" style={{ marginRight: 10 }} /> {formik.values.originalRecapLetter?.length} {t('attachment')}
                                    </div>
                                )
                            }
                            {formik.values.originalRecapLetter.map((file, index) => {
                                return ([
                                    <div class="input-group">
                                        <input
                                            className="form-control"
                                            value={file}
                                            style={{ marginBottom: 10, fontSize: 14, width: "80%" }}
                                            readOnly
                                        />
                                        <div class="input-group-prepend" style={{ marginBottom: 10, fontSize: 12 }}>
                                            <div class="input-group-text">
                                                {
                                                    this.props.disabled ? (
                                                        <>
                                                            <a download={file} href={`/api/v1/file/${this.props.folderName}/${file}`} title={file} style={{ marginRight: 15, color: "#4F5467" }}>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                            <div style={{ color: "#4F546733" }}>
                                                                <FontAwesomeIcon icon={faTimes} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <a download={file} href={`/api/v1/file/${this.props.folderName}/${file}`} title={file} style={{ marginRight: 15, color: "#4F5467" }}>
                                                                <FontAwesomeIcon icon={faDownload} />
                                                            </a>
                                                            <div style={{ cursor: "pointer" }}>
                                                                <FontAwesomeIcon icon={faTimes} onClick={() => this.handleFileRemove(index)} />
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ])
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(LetterUploadFile)
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { LetterService } from '../../../services/Master/LetterService';
import Input from '../../../components/Utils/Field/Input';
import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

export class EditLetterModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '', showMessage: false, };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const { t } = this.props;

        return (

            <div className="container">
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            code: this.props.letterCode,
                            shortName: this.props.shortName,
                        }}
                        validationSchema={
                            Yup.object().shape({
                                code: Yup.string().required('Required'),
                                shortName: Yup.string().required('Required')
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            LetterService.Update(values).then(
                                (result) => {
                                    toastSuccess(t('success_edit_message'));
                                    this.props.onHide();
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                })
                        }}
                    >
                        {props => (
                            <Form>
                                {
                                    props.status && this.state.showMessage &&
                                    <div className={'form-alert alert alert-danger'}>
                                        <div style={{float:"right", cursor:"pointer"}}>
                                            <i className="fas fa-times" onClick={() => this.setState({showMessage : false})}></i>
                                        </div>
                                        <div>
                                            {props.status}
                                        </div>
                                    </div>
                                }
                                <ModalHeader>
                                    <div className="modal-title">Edit {t('letter')}</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <Input
                                        hidden
                                        name="id"
                                        errors={props.errors}
                                        touched={props.touched}
                                        {...props.getFieldProps('id')} 
                                    />
                                    <div className="form-group">
                                        <label className="form-label">{t('letter_code')}</label>
                                        <Input
                                            name="code"
                                            type="text"
                                            placeholder={t('letter_code')}
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('code')} />
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">{t('institution_initial')}</label>
                                        <Input
                                            name="shortName"
                                            type="text"
                                            placeholder="Masukkan shortName"
                                            errors={props.errors}
                                            touched={props.touched}
                                            {...props.getFieldProps('shortName')} />
                                    </div>
                                    <div className="form-group">
                                        <div className="modal-button">
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text="Batal"
                                                onClick={this.props.onHide} />
                                            <Button type="submit" text="Simpan" disabled={props.isSubmitting} />
                                        </div>
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}




import React, { Component, Fragment } from 'react';
import { ToastContainer } from "react-toastify";
import { Header } from '../components/Header/Header';
import { Sidebar } from '../components/Sidebar/Sidebar';
import Title from '../components/Utils/Page/Title';

export class AdminLayout extends Component {

    constructor() {
        super();

        const rootElement = document.getElementById("main-wrapper");
        
        rootElement.setAttribute('data-theme', 'light');
        rootElement.setAttribute('data-layout', 'vertical');
        rootElement.setAttribute('data-navbarbg', 'skin6');
        rootElement.setAttribute('data-sidebartype', 'full');
        rootElement.setAttribute('data-sidebar-position', 'fixed');
        rootElement.setAttribute('data-header-position', 'fixed');
        rootElement.setAttribute('data-boxed-layout', 'full');
    }


    render() {
        return (
            <Fragment>
                <ToastContainer
                    autoClose={3000}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                <Header title={this.props.title}></Header>

                <Sidebar></Sidebar>

                <div className="page-wrapper">
                    <Title title={this.props.title}/>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {
                                    this.props.clear ? (
                                        <>
                                            {this.props.children}
                                        </>
                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                {this.props.children}
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        );
    }
}

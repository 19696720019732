import React, { Component } from 'react';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import * as Utils from '../../helpers/Utils';
import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';

export class HistoryModal extends Component {
    render() {
        let history = this.props?.historys;

        let dateListHistory = history?.map(data => Utils.getDate(data.createdDate))
        dateListHistory = [...new Set(dateListHistory)]
        dateListHistory = Array.from(dateListHistory)

        const HistoryList = ({ historys }) => (
            <>
            {historys.map(item => {
                return ([
                    <li className="history-item">
                        <FontAwesomeIcon color="#00ABBD" icon={faCircle}/>
                        <span className="item-left">
                            {parse(item.history)}
                        </span>
                    </li>
                ])
            })}
            </>
        );

        return (
            <div className="container">
                <Modal {...this.props} size="lg">
                    <ModalHeader>
                        <div className="modal-title">{this.props.title ?? 'Riwayat Kasus'}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        {dateListHistory.map(tanggal => {
                                let htmll = ([
                                <div className="history-wrapper">
                                    <div>
                                        <p className="title-small light-grey">{Utils.formatDate(tanggal)}</p>
                                        <hr/>
                                    </div>
                                    <div>
                                        <ul className="history-list">
                                            <HistoryList historys={history.filter(data => Utils.getDate(data.createdDate) === tanggal)} />
                                        </ul>
                                    </div>
                                </div>
                            ]);
                                
                            return htmll
                        })}
                    </ModalContent>
                </Modal>
            </div>
        )
    }
}
import React, { Component } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';

const InputStyled = styled.textarea.attrs(props => ({ ...props }))`
    background: white 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    height: 48px;
    text-align: left;
    font: normal normal normal 14px/21px Nunito Sans;
    letter-spacing: 0px;
    color: #222D17;
    padding-left: 12px;
`

export default class Textarea extends Component {

    render() {

        const isSame =  this.props.same;

        return (
            <div>
                <InputStyled
                    {...this.props}
                    value={this.props.value ?  this.props.value : ""}
                    className={'form-control ' + this.props.className + (this.props.errors[this.props.name] && this.props.touched[this.props.name] ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '')}
                />
                <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
            </div>
        );
    }
}
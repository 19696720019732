import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import { Role, Status } from "../../../helpers/Constants";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { PartnerService } from "../../../services/Partner/PartnerService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { HistoryService } from "../../../services/HistoryService";
import { authenticationService } from "../../../services/AuthService";

import ReportInformation from "./Tab/ReportInformation";
import ComplaintInformation from "./Tab/ComplaintInformation";
import AnalysisResult from "./Tab/AnalysisResult";
import LetterInformation from "./Tab/LetterInformation";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { withTranslation } from "react-i18next";

class DetailPartner extends Component {
  constructor(props) {
    super(props);

    const { id, typecase } = this.props.match.params;

    this.state = {
      id: id,
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      nextTab: "complaint-information",
      currentTab: "report-information",
      comments: [],
      historys: [],
      historydata: [],
      historysCaseEntry: [],
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      partnerDisabled: true,
      complaintDisabled: true,
      analysisDisabled: true,
    };

    this.commentModalRef = React.createRef();
    this.formikRef = React.createRef();
    this.getReporterData(id);
    this.historyList(id);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleTabChange(event, tab) {
    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "complaint-information",
        });
        break;
      case "#complaint-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: false,
          currentTab: "complaint-information",
          backTab: "report-information",
          nextTab: "analysis-result",
        });
        break;
      case "#analysis-result":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "analysis-result",
          backTab: "complaint-information",
          nextTab:
            (this.state.data?.status === Status.Approved ||
              this.state.data?.status === Status.Closed) &&
            this.props.location?.state?.formType === "edit"
              ? "letter-information"
              : "",
        });
        break;
      case "#letter-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "letter-information",
          backTab: "analysis-result",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleDisabledNext(tab, status) {
    switch (tab) {
      case "#report-information":
        this.setState({
          partnerDisabled: status,
        });
        break;
      case "#complaint-information":
        this.setState({
          complaintDisabled: this.state.partnerDisabled ? true : status,
        });
        break;
      case "#analysis-result":
        this.setState({
          analysisDisabled: this.state.partnerDisabled ? true : status,
        });
        break;
    }
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleGetData(data) {
    this.setState({ caseEntryData: data });
  }

  handleCausality(data) {
    this.setState({ causality_value: data });
  }

  getReporterData(id) {
    PartnerService.PrepareForm(id, this.state.typecase).then((result) => {
      if (result.data) {
        this.historyCaseEntryList(result.data.caseEntry)
        this.setState({
          data: result.data,
          caseEntryData:
            result.data.aefiCaseEntryNavigation !== null
              ? result.data.aefiCaseEntryNavigation
              : result.data.aeCaseEntryNavigation,
        });
      }
    });

    DropdownService.MasterList([
      "aeCategory",
      "aefiCategory",
      "vaccine",
      "vaccinator",
      "drugs",
      "partner",
      "seriousness",
      "expectedness",
      "outcome",
    ]).then((result) => {
      this.setState({ list: result.data });
    });
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  historyList(id) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {      
      HistoryService.Read(id, 'AEFI').then((result) => {
          this.setState({ historysCaseEntry: result.data });          
      });      
  }

  render() {
    const { t } = this.props;

    const partner = this.state.data;
    const { isLastVersion } = this.state;
    const historyData = this.state.historyData ? this.state.historyData : [];
    const historyCaseEntry = this.state.historyCaseEntryData
      ? this.state.historyCaseEntryData
      : [];

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (partner?.status === Status.Pending ||
        partner?.status === Status.Draft ||
        partner?.status === Status.NotOk ||
        partner?.status === Status.NotApproved ||
        this.props.isFollowUp ||
        partner?.status === Status.Approved);
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (partner?.status === Status.Draft ||
        partner?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (partner?.status === Status.Draft || partner?.status === Status.Reviewed);

    const formType = this.props.location?.state?.formType
      ? this.props.location?.state?.formType
      : "follow-up";
    const disabledLetter =
      partner?.status === Status.Approved || partner?.status === Status.Closed
        ? false
        : true;
    const initialValues = {
      id: partner?.id,
      caseEntry: partner?.aefiCaseEntry
        ? partner?.aefiCaseEntry
        : partner?.aeCaseEntry,
      caseNumber: partner?.caseNumber,
      mailingPlace: partner?.mailingPlace,
      mailingCover: partner?.mailingCover,
      mailingDate: partner?.mailingDate
        ? moment(partner?.mailingDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      mailingDestination: partner?.mailingDestination,
      mailingMonth: partner?.mailingMonth,
      referenceCaseNumber: partner?.referenceCaseNumber,
      conjunction: partner?.conjunction,
      totalAppendix: partner?.totalAppendix,
      complaintDate: partner?.complaintDate
        ? moment(partner?.complaintDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      complaint: partner?.complaint,
      status: partner?.status,
      mailingOrder: partner?.mailingOrder,
      mailingMonth: partner?.mailingMonth,
      mailingYear: partner?.mailingYear,
      analysisBpr: partner?.analysisBpr,
      analysisDistribution: partner?.analysisDistribution,
      analysisSameBatch: partner?.analysisSameBatch,
      analysisAefi: partner?.analysisAefi,
      partner: partner?.partner,
      originalRecapLetter: partner?.originalRecapLetterArr ?? [],
      mailingSentDate: partner?.mailingSentDate ? partner?.mailingSentDate : "",
    };

    const initialTouched = {
      partner: true,
      caseNumber: true,
      mailingSentDate: true,
    };

    const validationSchemaDict =
      partner?.status !== Status.Approved
        ? {
            "report-information": Yup.object().shape({
              caseNumber: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "report-information": Yup.object().shape({
              caseNumber: Yup.string().required("Required").nullable(),
            }),
            "letter-information": Yup.object().shape({
              mailingOrder: Yup.string().required("Required").nullable(),
              mailingMonth: Yup.string().required("Required").nullable(),
              mailingYear: Yup.string().required("Required").nullable(),
              mailingSentDate: Yup.string().required("Required").nullable(),
            }),
          };

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };

    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
          id={partner?.id}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          id={partner?.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />
        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          type="partner"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          type="partner"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("report_information")}
                  href="#report-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#report-information")
                  }
                  id="link-report-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("complaint_information")}
                  href="#complaint-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#complaint-information")
                  }
                  id="link-complaint-information"
                  disabled={this.state.partnerDisabled}
                />
                <Tab
                  number="3"
                  showNumber={true}
                  title={t("analysa_result")}
                  href="#analysis-result"
                  onClick={(event) =>
                    this.handleTabChange(event, "#analysis-result")
                  }
                  id="link-analysis-result"
                  disabled={this.state.partnerDisabled}
                />
                <Tab
                  number="4"
                  showNumber={true}
                  title={t("letter_information")}
                  href="#letter-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#letter-information")
                  }
                  id="link-letter-information"
                  disabled={disabledLetter || formType !== "edit"}
                  tooltip="Data Informasi Surat baru bisa diinputkan oleh Staff ketika sudah di approve"
                />
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          initialTouched={initialTouched}
          enableReinitialize
          validationSchema={validationSchemaDict[this.state.currentTab]}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            values.typeCaseEntry = values.caseType === "KIPI" ? "AEFI" : "AE";

            if (formType !== "edit" && isLastVersion) {
              values.isFollowup = true;

              PartnerService.FollowUp(values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/report-submission/partner`, {
                    pages: this.props?.location?.state?.page,
                  });
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            } else {
              PartnerService.Update(values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/report-submission/partner`, {
                    pages: this.props?.location?.state?.page,
                  });
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <ReportInformation
                  formik={formik}
                  page={this.props?.location?.state?.page}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedata={(data) => this.handleGetData(data)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.partnerDisabled}
                  caseEntry={this.state.caseEntryData}
                  isLastVersion={isLastVersion}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  history={this.props.history}
                  formType={formType}
                />

                <ComplaintInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                  isLastVersion={isLastVersion}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                />

                <AnalysisResult
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                  isLastVersion={isLastVersion}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                />

                <LetterInformation
                  formik={formik}
                  page={this.props?.location?.state?.page}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                />

                {this.state.nextTab !== "" && this.state.backTab !== "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab === "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    {/* Simpan */}
                    {canStaffEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          isSubmitting={formik.isSubmitting}
                        />
                      </ToggleDisplay>
                    )}

                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />

                    {/* Reject */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isApprove", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isReview", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            currentUser.role === Role.Unit
                              ? "Forward"
                              : "Approve"
                          }
                          onClick={() => {
                            formik.setFieldValue("isApprove", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={"Forward"}
                          onClick={() => {
                            formik.setFieldValue("isReview", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailPartner);

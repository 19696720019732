import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from '../../../services/AuthService';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        if (roles && roles.indexOf(currentUser.role) === -1) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/access-denied' }} />
        }

        // authorised so return component
        
        return <Component {...props} />
    }} />
)
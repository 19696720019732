import { handleResponse, authHeader } from '../helpers/RequestHelper';

export const FileService = {
    Upload,
    Show
};

async function Upload(file, foldername) {
    const formData = new FormData()
    formData.append('file', file[0])

    const requestOptions = {
        method: 'POST',
        headers: {
            ...authHeader()
        },
        body: formData,
        redirect: 'follow',
    };

    // Remove content-type to handle file sent as a string object
    delete requestOptions.headers['Content-Type'];
    
    let data = await fetch(`/api/v1/file/${foldername}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function Show(folder, filename ) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/file/${folder}/${filename}`, requestOptions);
    data = await handleResponse(data)
    return data;
}


import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const AEFINonSeriousService = {
    Create,
    Read,
    ReadAefi,
    Update,
    PrepareForm,
    submitForm,
    Delete,
    Download,
    DownloadData,
    DownloadDoc
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/kipi-non-serius/create/`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function submitForm(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-non-serius/submit-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(id, object) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`api/v1/report-submission/bpom/kipi-non-serius/update/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/bpom/kipi-non-serius/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function ReadAefi(page = 1, limit = 10, query = "", vaccine = "", status = "", startDate = "", endDate = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/report-submission/bpom/kipi-non-serius/read-aefi?page=${page}&limit=${limit}&query=${query}&vaccine=${vaccine}&status=${status}&startDate=${startDate}&endDate=${endDate}`, requestOptions);
    data = await handleResponse(data)
    return data;
}


async function PrepareForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-non-serius/prepare-form/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/bpom/kipi-non-serius/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Download(id, period) {
    fetch('/api/v1/report-submission/pdf/kipi-nonserius-pdf/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Surat Pelaporan KIPI Non Serius Periode ${period}`);
        document.body.appendChild(link);
        link.click();
    })
}

async function DownloadData(id, casenumber) {
    fetch('/api/v1/report-submission/bpom/kipi-non-serius/xls/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => {
        var filename = "";
        var disposition = response.headers.get("content-disposition");
        if (disposition && disposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }

        response.blob().then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        })
    })
}

async function DownloadDoc(id, period) {
    fetch('/api/v1/report-submission/doc/kipi-nonserius-doc/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Surat Pelaporan KIPI Non Serius Periode ${period}.docx`);
        document.body.appendChild(link);
        link.click();
    })
}


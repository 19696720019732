import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const AuditTrailService = {
    Read,
    Download,
    PrepareForm,
};

async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/audit-trails/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function Download(params) {
    let url = '/api/v1/audit-trails/pdf'

    Object.entries(params).forEach(([key, value], index) => {
        if(index === 0) {
            url += `?${key}=${value}`
        }else{
            url += `&${key}=${value}`
        }
    });

    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Audit Trail.pdf');
        document.body.appendChild(link);
        link.click();
    })
}

async function PrepareForm(id = "") {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `api/v1/audit-trails/prepare-form/${id}`

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

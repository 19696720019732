import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";


class DownloadTemplate extends Component {
    render() {
        const { t } = this.props;
        return (
            <div
                className="d-flex justify-content-between align-items-center"
                style={{
                    border: "1px solid #E5E5E5",
                    borderRadius: "6px",
                    padding: "27px 24px",
                    marginBottom: "3rem",
                }}
            >
                <span style={{ fontSize: "16px" }}>
                    {t("download_template")}
                </span>
                <a
                    href="../assets/file/TemplateImportCaseEntryKIPI.xlsx"
                    download
                    style={{
                        color: "#00ABBD",
                        fontSize: "16px",
                        fontWeight: 700,
                    }}
                >
                    {t("download")}
                </a>
            </div>
        )

    }
}

export default withTranslation()(withRouter(DownloadTemplate));

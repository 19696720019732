import React, { Component } from 'react';

import Input from '../../../components/Utils/Field/Input';

import { withTranslation } from "react-i18next";

class Analysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {
        const { formik } = this.props;
        const { t } = this.props;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="analysis-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('analysis_date')}</label>
                                <Input
                                    disabled
                                    name="analysisDate"
                                    type="text"
                                    placeholder={t('analysis_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('analysis_time')}</label>
                                <Input
                                    disabled
                                    name="analysisTime"
                                    type="text"
                                    placeholder={t('analysis_time')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisTime')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('unit_pv_analysis_result')}</label>
                                <Input
                                    disabled
                                    name="analysisPV"
                                    type="text"
                                    placeholder={t('unit_pv_analysis_result')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisPV')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('national_comitee_analysis_result')}</label>
                                <Input
                                    disabled
                                    name="analysisExternal"
                                    type="text"
                                    placeholder={t('national_comitee_analysis_result')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('analysisExternal')}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('case_closed_date')}</label>
                                <Input
                                    disabled
                                    name="caseClosedDate"
                                    type="text"
                                    placeholder={t('case_closed_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('caseClosedDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('summary_created_date')}</label>
                                <Input
                                    disabled
                                    name="summaryDate"
                                    type="text"
                                    placeholder={t('summary_created_date')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('summaryDate')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('summary_time')}</label>
                                <Input
                                    disabled
                                    name="summaryTime"
                                    type="text"
                                    placeholder={t('summary_time')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('summaryTime')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Status</label>
                                <Input
                                    disabled
                                    name="status"
                                    type="text"
                                    placeholder="Status"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('status')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(Analysis);
import React, { Component } from 'react';

export default class Tab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            windowSize: "",
            isMobile: false,
            title: ""
        };
    }
    
    isMobile = () => window.innerWidth <= 992;

    render() {

        const active = this.props.number === "1" ? "nav-link active" : "nav-link ";
        const type = this.props.type ? this.props.type : 2;
        const className = type === 1 ? active : active + ' tab-menu';
        const cardTab =  type === 1 ? 'rounded-0' : 'card-tab rounded-0 rounded-tab';
        const hideText = type === 1 ? '' :  'd-none d-lg-block';
        const classTooltip = this.props.tooltip ? "mini-tooltiptext show-tooltip" : "mini-tooltiptext" 

        return (
            <>
                {
                    this.props.disabled ? (
                        <li className="nav-item mini-tooltip disabled">
                            <a 
                                id={this.props.id} 
                                aria-expanded="false" className={className}
                            >
                                {
                                    this.props.showNumber && (
                                        <div className="number-tab">
                                            <span>{this.props.number}</span>
                                        </div>
                                    )
                                }
                                <div className={cardTab}>
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                    <span className={hideText}>{this.props.title}</span>
                                </div>
                            </a>
                            <span className={classTooltip}>{this.props.tooltip? this.props.tooltip : this.props.title}</span>
                        </li>
                    ) : (
                        <li className="nav-item mini-tooltip">
                            <a 
                                id={this.props.id} 
                                href={this.props.href} 
                                onClick={this.props.onClick} 
                                data-toggle={"tab"} 
                                aria-expanded="false" className={className}
                            >
                                {
                                    this.props.showNumber && (
                                        <div className="number-tab">
                                            <span>{this.props.number}</span>
                                        </div>
                                    )
                                }
                                <div className={cardTab}>
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"></i>
                                    <span className={hideText}>{this.props.title}</span>
                                </div>
                            </a> 
                            <span className={classTooltip}>{this.props.tooltip? this.props.tooltip : this.props.title}</span>
                        </li>
                    )
                }
            </>
        );
    }
}

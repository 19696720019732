import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';
import moment from "moment";

import DatePicker from '../../../components/Utils/DateRange/DateRangePicker';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';
import Select2 from '../../../components/Utils/Field/Select2';
import Button from '../../../components/Utils/Button/Button';
import { Role,Status } from '../../../helpers/Constants';

import { withTranslation } from "react-i18next";

class PatientInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidMount(){
        const { formik, typecase, ...otherProps } = this.props;

    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

        if(this.props.updatedisabled){

            if(prevProps.formik.values !== this.props.formik?.values || prevProps.formik.values.patientType !== this.props.formik?.values?.patientType){

                if(this.props.formik?.values?.patientType){

                    this.props.updatedisabled("#patient-information", false);
                }else{
                    this.props.updatedisabled("#patient-information", true);
                }
            }
        }

    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;

        const role = this.props.currentUser ? this.props.currentUser.role : "";
        const isDisabledForm = (this.props.formType === "follow-up" && this.props.isLastVersion) || (role === Role.Divisi && this.props.caseStatus === Status.Reviewed) || this.props.isLastVersion === undefined ? false : !this.props.isLastVersion ||  this.props.caseStatus === Status.WaitingApproval || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved || role === Role.RegulasiMutuSistem;

        let historyAllergicOption = historydata?.historyAllergic !== null && historydata?.historyAllergic !== undefined ? (historydata?.historyAllergic !== '#?#' ? "Yes" : "No") : "Unknown";
        let historyDiseaseOption = historydata?.historyDisease !== null && historydata?.historyDisease !== undefined ? (historydata?.historyDisease !== '#?#' ? "Not Healthy" : "Healthy") : "Unknown";

        return (
            <>
                <div className="tab-pane" id="patient-information">

                    {/* 2.1. Patient Information */}

                    <div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('patient_name')}</label>
                                    <Input
                                        disabled={isDisabledForm}
                                        name="patientName"
                                        type="text"
                                        placeholder={t('patient_name')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('patientName')} 
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('gender')}</label>
                                    <div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="U" name="patientGender"
                                                disabled={isDisabledForm}
                                                checked={formik.values.patientGender === "U"}
                                                onChange={formik.handleChange}
                                                onClick={() => formik.setFieldValue("patientChildbearing", "")} />
                                            <label className="form-check-label">{t('no_data')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="M" name="patientGender"
                                                disabled={isDisabledForm}
                                                checked={formik.values.patientGender === "M"}
                                                onChange={formik.handleChange} 
                                                onClick={() => formik.setFieldValue("patientChildbearing", "")} />
                                            <label className="form-check-label">{t('male')}</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input type="radio" className="form-check-input" value="F" name="patientGender"
                                                disabled={isDisabledForm}
                                                checked={formik.values.patientGender === "F"}
                                                onChange={formik.handleChange} />
                                            <label className="form-check-label">{t('female')}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('parent_name')}</label>
                                    <Input
                                        disabled={isDisabledForm}
                                        name="parentName"
                                        type="text"
                                        placeholder={t('parent_name')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('parentName')} />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('address')}</label>
                                    <Textarea
                                        rows="6"
                                        name="patientAddress"
                                        placeholder={t('address')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.patientAddress ? formik.values.patientAddress : ""}
                                        {...formik.getFieldProps('patientAddress')} 
                                        disabled={isDisabledForm} 
                                        same={historydata?.patientAddress ? historydata.patientAddress=== formik.values.patientAddress : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('patient_type')} <span style={{color: "red"}}>*</span></label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="patientType"
                                        options={this.props.patientTypeList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values?.patientType}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.patientType ? historydata.patientType === formik.values.patientType : true }
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label className="form-label">{t('country')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="patientCountry"
                                        options={this.props.countryList?.map(country => { return { value: country.code, label: country.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.patientCountry}
                                        onChange={(name, value) => {
                                            this.props.provinceList("patient", value);
                                            formik.setFieldValue("patientCountry", value)
                                            formik.setFieldValue("patientProvince", "");
                                            formik.setFieldValue("patientCity", "");
                                        }}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.patientCountry ? historydata.patientCountry=== formik.values.patientCountry : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('province')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="patientProvince"
                                        options={this.props.patientProvinceList?.map(province => { return { value: province.code, label: province.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.patientProvince}
                                        onChange={(name, value) => {
                                            this.props.cityList("patient", value);
                                            formik.setFieldValue("patientProvince", value);
                                            formik.setFieldValue("patientCity", "");
                                        }}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.patientProvince ? historydata.patientProvince=== formik.values.patientProvince : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('city')}</label>
                                    <Select2
                                        disabled={isDisabledForm}
                                        name="patientCity"
                                        options={this.props.patientCityList?.map(city => { return { value: city.code, label: city.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.patientCity}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.patientCity ? historydata.patientCity=== formik.values.patientCity : true }
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('phone_number')}</label>
                                    <Input
                                        name="patientPhone"
                                        type="text"
                                        placeholder={t('phone_number')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps('patientPhone')} 
                                        disabled={isDisabledForm}
                                        same={historydata?.patientPhone ? historydata.patientPhone=== formik.values.patientPhone : true }
                                    />
                                </div>
                                <div>
                                    <label className="form-label">{t('age')}</label>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className={historydata?.patientAgeYear ? "input-group " + ((historydata.patientAgeYear=== formik.values.patientAgeYear) === false ? "change-value-wrapper" : "" ) : "input-group" }>
                                                    <input
                                                        name="patientAgeYear"
                                                        type="number"
                                                        min={0}
                                                        placeholder={t('year')}
                                                        className="form-control"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        value={formik.values.patientAgeYear ? formik.values.patientAgeYear : "" }
                                                        {...formik.getFieldProps('patientAgeYear')}
                                                        disabled={isDisabledForm}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">{t('year')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className={historydata?.patientAgeMonth ? "input-group " + ((historydata.patientAgeMonth=== formik.values.patientAgeMonth) === false ? "change-value-wrapper" : "" ) : "input-group" }>
                                                    <input
                                                        name="patientAgeMonth"
                                                        type="number"
                                                        min={0}
                                                        max={11}
                                                        placeholder={t('month')}
                                                        className="form-control"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps('patientAgeMonth')}
                                                        disabled={isDisabledForm}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">{t('month')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <div className={historydata?.patientAgeDay ? "input-group " + ((historydata.patientAgeDay === formik.values.patientAgeDay) === false ? "change-value-wrapper" : "") : "input-group"}>
                                                    <input
                                                        name="patientAgeDay"
                                                        type="number"
                                                        min={0}
                                                        max={31}
                                                        placeholder={t('day')}
                                                        className="form-control"
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps('patientAgeDay')}
                                                        disabled={isDisabledForm}
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">{t('day')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label">{t('child_bearing_age_women')}</label>
                                    <Select2
                                        disabled={formik.values.patientGender === "M" ? "disabled" : "" || isDisabledForm}
                                        name="patientChildbearing"
                                        options={this.props.childbearingList?.map(item => { return { value: item.id, label: item.name }; })}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        value={formik.values.patientChildbearing}
                                        onChange={formik.setFieldValue}
                                        onBlur={formik.setFieldTouched}
                                        same={historydata?.patientChildbearing ? historydata.patientChildbearing=== formik.values.patientChildbearing : true }
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div> 
                        {
                            this.props.typecase === "KTD" && (
                                <>
                                     {/* 2.2. Product Use */}
                                    <div>
                                        <label className="form-label" style={{fontSize: 16}}>{t('product_use')}</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{t('product_use_date')}</label>
                                                    <DatePicker
                                                        name="productUseDate"
                                                        selectionType="single"
                                                        maxdate={new Date()}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        onChange={formik.setFieldValue}
                                                        onBlur={formik.setFieldTouched}
                                                        value={formik.values?.productUseDate}
                                                        disabled={isDisabledForm}
                                                        same={historydata?.productUseDate ? moment(historydata.productUseDate).format("YYYY-MM-DD") === formik.values.productUseDate : true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    <hr />
                                    </div>
                                    {/* 2.3. Primary Disease */}
                                    <div>
                                        <label className="form-label" style={{fontSize: 16}}>{t('primary_disease')}</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{t('name_of_disease')}</label>
                                                    <Input
                                                        name="diseaseName"
                                                        type="text"
                                                        placeholder={t('name_of_disease')}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps('diseaseName')} 
                                                        disabled={isDisabledForm}
                                                        same={historydata?.diseaseName ? historydata.diseaseName=== formik.values.diseaseName : true }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">{t('other_relevant_conditions')}</label>
                                                <div>
                                                    <div className={historydata?.diseaseRenal ? "form-check form-check-inline " + ((historydata.diseaseRenal === formik.values.diseaseRenal) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                                        <input type="checkbox" className="form-check-input" name="diseaseRenal" value="true"
                                                            checked={formik.values.diseaseRenal === true}
                                                            onChange={formik.handleChange}
                                                            disabled={isDisabledForm}
                                                        />
                                                        <label className="form-check-label">{t('renal_disorders')}</label>
                                                    </div>
                                                    <div className={historydata?.diseaseLiver ? "form-check form-check-inline " + ((historydata.diseaseLiver === formik.values.diseaseLiver) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                                        <input type="checkbox" className="form-check-input" name="diseaseLiver" value="true"
                                                            checked={formik.values.diseaseLiver === true}
                                                            onChange={formik.handleChange}
                                                            disabled={isDisabledForm}
                                                        />
                                                        <label className="form-check-label">{t('liver_disorders')}</label>
                                                    </div>
                                                    <div className={historydata?.diseaseAllergy ? "form-check form-check-inline " + ((historydata.diseaseAllergy === formik.values.diseaseAllergy) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                                        <input type="checkbox" className="form-check-input" name="diseaseAllergy" value="true"
                                                            checked={formik.values.diseaseAllergy === true}
                                                            onChange={formik.handleChange} 
                                                            disabled={isDisabledForm}
                                                        />
                                                        <label className="form-check-label">{t('allergy')}</label>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={historydata?.diseaseOther ? "form-check form-check-inline " + (((historydata.diseaseOther ? true : false) === formik.values.diseaseOtherOption) === false ? "change-value-radio" : "" ) : "form-check form-check-inline" }>
                                                    <input type="checkbox" className="form-check-input" name="diseaseOtherOption" value="true"
                                                        disabled={isDisabledForm}
                                                        checked={formik.values.diseaseOtherOption === true}
                                                        onChange={(event) => {
                                                            formik.setFieldValue("diseaseOtherOption", event.target.checked);

                                                            if (event.target.checked === false) {
                                                                formik.setFieldValue("diseaseOther", "");
                                                            }
                                                        }} />
                                                        <label className="form-check-label">{t('other_medical_condition')}:</label>
                                                    </div>
                                                </div>
                                                {
                                                    <div className="form-group" style={{marginBottom: 50}}>
                                                        <Input
                                                            name="diseaseOther"
                                                            type="text"
                                                            disabled={this.state.isDisabled || formik.values.diseaseOtherOption === false ? "disabled" : "" || this.props.caseStatus === Status.Approved}
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps('diseaseOther')} 
                                                            same={historydata?.diseaseOther ? historydata.diseaseOther=== formik.values.diseaseOther : true }
                                                        />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                        {
                            this.props.typecase === "KIPI" && (
                                <>

                                    {/* 2.3. Drug/Vaccine Allergy History */}

                                    <div>
                                        <label className="form-label" style={{fontSize: 16}}>{t('history_allergic')}</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{t('allergic_of_drug_or_vaccine')}</label>
                                                    <div className={historydata?.historyAllergic ? ((historyAllergicOption === formik.values.historyAllergicOption) === false ? "change-value-radio" : "" ) : "" }>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="Unknown" name="historyAllergicOption"
                                                                checked={formik.values.historyAllergicOption === "Unknown"}
                                                                onChange={formik.handleChange}
                                                                disabled={isDisabledForm}
                                                            />
                                                            <label className="form-check-label">{t('no_data')}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="Yes" name="historyAllergicOption"
                                                                checked={formik.values.historyAllergicOption === "Yes"}
                                                                onChange={formik.handleChange} 
                                                                disabled={isDisabledForm}
                                                            />
                                                            <label className="form-check-label">{t('yes_ever')}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="No" name="historyAllergicOption"
                                                                checked={formik.values.historyAllergicOption === "No"}
                                                                onClick={() => formik.setFieldValue("historyAllergic", "")}
                                                                onChange={formik.handleChange} 
                                                                disabled={isDisabledForm}
                                                            />
                                                            <label className="form-check-label">{t('no')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    formik.values.historyAllergicOption === "Yes" && (
                                                        <div className="form-group">
                                                            <label className="form-label">{t('name_of_drug_or_vaccine')}</label>
                                                            <Input
                                                                name="historyAllergic"
                                                                type="text"
                                                                placeholder={t('name_of_drug_or_vaccine')}
                                                                disabled={isDisabledForm}
                                                                errors={formik.errors}
                                                                touched={formik.touched}
                                                                {...formik.getFieldProps('historyAllergic')}
                                                                same={historydata?.historyAllergic ? (historydata.historyAllergic !== undefined && historydata.historyAllergic !== null ? (historydata?.historyAllergic !== '#?#' ? historydata?.historyAllergic : "") : "") === formik.values.historyAllergic : true }  
                                                            />
                                                        </div>
                                                    )
                                                }
                                                <div className="form-group">
                                                    <label className="form-label">{t('health_status_before_immunization')}</label>
                                                    <div className={historydata?.historyDisease ? ((historyDiseaseOption === formik.values.historyDiseaseOption) === false ? "change-value-radio" : "" ) : "" }>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="Unknown" name="historyDiseaseOption"
                                                                checked={formik.values.historyDiseaseOption === "Unknown"}
                                                                onChange={formik.handleChange}
                                                                disabled={isDisabledForm}
                                                            />
                                                            <label className="form-check-label">{t('no_data')}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="Healthy" name="historyDiseaseOption"
                                                                checked={formik.values.historyDiseaseOption === "Healthy"}
                                                                onChange={formik.handleChange}
                                                                disabled={isDisabledForm} />
                                                            <label className="form-check-label">{t('healthy')}</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input type="radio" className="form-check-input" value="Not Healthy" name="historyDiseaseOption"
                                                                checked={formik.values.historyDiseaseOption === "Not Healthy"}
                                                                onChange={formik.handleChange}
                                                                disabled={isDisabledForm} />
                                                            <label className="form-check-label">{t('not_healthy')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    formik.values.historyDiseaseOption === "Not Healthy" && (
                                                        <div className="form-group">
                                                            <label className="form-label">{t('name_of_disease')}</label>
                                                            <Input
                                                                name="historyDisease"
                                                                type="text"
                                                                placeholder={t('name_of_disease')}
                                                                disabled={isDisabledForm}
                                                                errors={formik.errors}
                                                                touched={formik.touched}
                                                                {...formik.getFieldProps('historyDisease')} 
                                                                same={historydata?.historyDisease ? (historydata.historyDisease !== undefined && historydata.historyDisease !== null ? (historydata?.historyDisease !== '#?#' ? historydata?.historyDisease : "") : "") === formik.values.historyDisease : true }  
                                                            />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">{t('risk_of_immunization')}</label>
                                                    <Select2
                                                        name="historyRisk"
                                                        options={this.props.immunizationRiskList?.map(item => { return { value: item.id, label: item.name }; })}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        value={formik.values.historyRisk} onChange={(name, value) => {

                                                            formik.setFieldValue(name, value)

                                                            if (this.props.immunizationRiskList?.filter(obj => { return obj.id === formik.values.historyRisk })[0]?.name != "Other") {
                                                                formik.setFieldValue("historyOther", "")
                                                            }
                                                        }}
                                                        onBlur={formik.setFieldTouched}
                                                        disabled={isDisabledForm}
                                                        same={historydata?.historyRisk ? historydata.historyRisk=== formik.values.historyRisk : true }
                                                    />
                                            </div>
                                            {
                                                this.props.immunizationRiskList?.filter(obj => { return obj.id === formik.values.historyRisk })[0]?.name === "Other" &&

                                                <div className="form-group">
                                                    <Input
                                                        name="historyOther"
                                                        type="text"
                                                        placeholder="{t('other_risk')}"
                                                        disabled={isDisabledForm}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                        {...formik.getFieldProps('historyOther')}
                                                        same={historydata?.historyOther ? historydata.historyOther=== formik.values.historyOther : true } 
                                                    />
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('back')}
                                onClick={this.props.handleBackClick} 
                            />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
                
            </>
        )
    }

}

export default withTranslation()(PatientInformation);
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../components/Utils/Button/Button';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ReminderService } from '../../services/Reminder/ReminderService';
import { DropdownService } from '../../services/Master/AllDropdownService';
import { withTranslation } from "react-i18next";

import FormReminder from './FormReminder';

export class AddReminder extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
        }
        this.getData();
    }

    getData() {

        DropdownService.MasterList([
            "aeCategory", "aefiCategory", "vaccine", "vaccinator", "drugs",
            "seriousness", "expectedness", "outcome",
        ]).then(
            (result) => {
                this.setState({ list: result.data });
            })
    }


    cancel = () => {
        this.props.history.push(`/reminder`);
    }

    render() {

        const { list } = this.state;
        const { t } = this.props;

        return (
            <>
                <div>
                    <Formik

                        initialValues={{
                            caseType: "",
                            isOverseas: "",
                        }}

                        validationSchema={
                            Yup.object().shape({
                                instance: Yup.string().required("Required").nullable(),
                                caseType: Yup.string().required("Required").nullable(),
                                seriousness: Yup.string().required("Required").nullable(),
                                expectedness: Yup.string().required("Required").nullable(),
                                dueAmount: Yup.string().required("Required").nullable(),
                                dueType: Yup.string().required("Required").nullable(),
                            })}

                        onSubmit={(values, { setStatus, setSubmitting }) => {

                            setStatus();

                            values.isOverseas = (values.instance !== "BPOM") ? 0 : values.isOverseas;
                            values.instance = (values.instance === "BPOM") ? null : values.instance

                            ReminderService.Create(values).then(

                                (result) => {
                                    toastSuccess('Berhasil menambahkan data');
                                    this.props.history.push(`/reminder`);
                                },
                                (error) => {
                                    setSubmitting(false);
                                    toastError(error);
                                }
                            )

                        }}
                    >
                        {formik =>

                        (
                            <Form>
                                <div className="tab-content">
                                    <FormReminder
                                        formik={formik}
                                        list={list}
                                    />
                                </div>
                                <div className='text-right'>
                                    <ToggleDisplay>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t("cancel")}
                                            onClick={() => this.cancel()}
                                        />
                                    </ToggleDisplay>
                                    <ToggleDisplay>
                                        <Button
                                            type="submit"
                                            text={t("save")}
                                            disabled={formik.isSubmitting}
                                        />
                                    </ToggleDisplay>
                                    {
                                        formik.status &&
                                        <div className={'alert alert-danger'}>{formik.status}</div>
                                    }
                                </div>
                            </Form>
                        )
                        }
                    </Formik>

                </div>
            </>
        )
    }
}

export default withTranslation()(AddReminder);

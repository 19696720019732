import { authenticationService } from '../services/AuthService';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = authenticationService.currentUserValue;
    if (currentUser && currentUser.token) {
        return { Authorization: `Bearer ${currentUser.token}`, 'Content-Type': 'application/json' };
    } else {
        return {};
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = JSON.parse(text);    
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    authenticationService.logout();
                    window.location.reload(true);
                }
    
                const error = (data && data.message);
                return Promise.reject(error);
            }
    
            return data;
        } catch (error) {
            return text;
        }
    });
}
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { EditLetterModal } from './EditLetterModal';
import { LetterService } from '../../../services/Master/LetterService';
import { Loader } from '../../../components/Utils/Loader/Loader';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import { withTranslation } from "react-i18next";

class Letter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            editModalShow: [],
            deleteModalShow : false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            loading: false
        }
    }

    componentDidMount() {
        this.refreshList();
    }


    refreshList() {
        this.setState({ loading: true })
        LetterService.Read(this.state.page, this.state.limit, this.state.query).then(result => {
            this.setState({
                maxPage: result.data.maxPage,
                records: result.data.records,
                editModalShow: new Array(result.data.length).fill(false),
                loading: false
            });
        });
    }
    handleLoading(status) {
        this.setState({ loading: status })
    }

    render() {

        const { t } = this.props;

        const { records, letterCode, shortName } = this.state;

        let editModalClose = () => {
            this.setState({
                editModalShow: new Array(this.state.records.length).fill(false)
            })
            this.refreshList()
        };

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD" />
                    )
                }

                <Table className="pb-5" hover>
                    <thead>
                        <tr>
                            <th width="5%">No</th>
                            <th>{t('letter_code')}</th>
                            <th>{t('institution_initial')}</th>
                            <th width="10%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) =>
                            <tr key={record.code}>
                                <td>{index + 1 + (this.state.page-1) * this.state.limit}</td>
                                <td>{record.code}</td>
                                <td>{record.shortName}</td>
                                <td>
                                    <div className="btn-group">
                                        <button className="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                            <a className="dropdown-item" onClick={() => {
                                                this.state.editModalShow[index] = true
                                                this.setState({ letterCode: record.code, shortName : record.shortName })}}
                                            >
                                                Edit
                                            </a>
                                        </div>
                                    </div>

                                    <EditLetterModal
                                        show={this.state.editModalShow[index]}
                                        onHide={editModalClose}
                                        letterCode={letterCode} 
                                        shortName={shortName}
                                        t={t}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </>
        )
    }

}

export default withTranslation()(Letter)

import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import { withTranslation } from "react-i18next";

class MarketAuthorization extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {

        const { formik, formType, t, ...otherProps } = this.props;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="market-authorization">
                    <div className="row">
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">COUNTRY</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Country</th>
                                                <th>Action Date</th>
                                                <th>Launch Date</th>
                                                <th>Trade Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.pbrerMarketAuthorizationCountry?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {record?.countryName}
                                                    </td>
                                                    <td>
                                                        {record?.actionDate ? moment(record?.actionDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.launchDate ? moment(record?.launchDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.tradeName}
                                                    </td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.pbrerMarketAuthorizationCountry === undefined || formik.values?.pbrerMarketAuthorizationCountry === null || formik.values?.pbrerMarketAuthorizationCountry?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">INSTITUTION</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Institution</th>
                                                <th>Country</th>
                                                <th>Action Date</th>
                                                <th>Launch Date</th>
                                                <th>Trade Name</th>
                                                <th>Comment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.pbrerMarketAuthorizationInstitution?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {record?.institutionName}
                                                    </td>
                                                    <td>
                                                        {record?.countryName}
                                                    </td>
                                                    <td>
                                                        {record?.actionDate ? moment(record?.actionDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.launchDate ? moment(record?.launchDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.tradeName}
                                                    </td>
                                                    <td>
                                                        {record?.comment}
                                                    </td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.pbrerMarketAuthorizationInstitution === undefined || formik.values?.pbrerMarketAuthorizationInstitution === null || formik.values?.pbrerMarketAuthorizationInstitution?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(MarketAuthorization);
import React, { Component } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Index = ({ information, total, success, failed, info, done, toggle, download, onHold, continueLabel }) => {

    return (
        <div
            className="upload-success status-wrapper"
            style={{
                display: "flex",
                padding: "16px",
                borderBottom: "1px solid #E5E5E5",
                marginTop: "1rem",
                alignItems: "center",
            }}
        >
            {
                success && (
                    <DoneIcon
                        className="icon-status"
                        style={{
                            width: "1.5em",
                            height: "1.5em",
                        }}
                    />
                )
            }
            {
                failed && (
                    <CloseIcon
                        className="icon-status"
                        style={{
                            width: "1.5em",
                            height: "1.5em",
                        }}
                    />
                )
            }
            {
                info && (
                    <ErrorOutlineIcon
                        className="icon-status"
                        style={{
                            width: "1.5em",
                            height: "1.5em",
                        }}
                    />
                )
            }
            <span
                className="sum-file"
                style={{
                    fontWeight: 700,
                    marginLeft: "1.5rem",
                    fontSize: "16px",
                }}
            >
                {total}
            </span>

            <p
                style={{
                    marginBottom: "-0.01rem",
                    marginLeft: "0.5rem",
                    fontSize: "16px",
                }}
            >
                {information}
            </p>

            {failed && done && total > 0 && (
                <div
                    onClick={() => toggle()}
                    style={{
                        color: "#00ABBD",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginLeft: "1rem",
                        cursor: "pointer",
                    }}
                >
                    {download}
                </div>
            )}
            {onHold && total > 0 && (
                <div
                    onClick={() => toggle()}
                    style={{
                        color: "#00ABBD",
                        fontSize: "16px",
                        fontWeight: 700,
                        marginLeft: "1rem",
                        cursor: "pointer",
                    }}
                >
                    {continueLabel}
                </div>
            )}
        </div>
    );
};

export default Index;

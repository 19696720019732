import React from "react";
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from "moment";
import { ErrorMessage } from 'formik';

class Example extends React.Component {
  constructor(props, context) {
    super(props, context);

    const now = moment(new Date(), 'hh:mma', 'en').format('HH:mm');

    this.state = {
      time: "00:00"
    };
  }

  componentDidMount() {
    this.setState({ time: this.props.value ? this.props.value : this.state.time });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ time: this.props.value });
    }
  }

  handleTimeChange = (newValue) => {
    const time = moment(newValue).format('HH:mm');
    this.setState({ time: time });

    if (this.props.onChange) {
      this.props.onChange(this.props.name, time);
    }
  };

  render() {
    const error = this.props.errors && this.props.name ? this.props.errors[this.props.name] : "";
    const touched = this.props.touched && this.props.name ? this.props.touched[this.props.name] : "";
    const isSame = this.props.same;

    return (
      <div className="form-group">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <MobileTimePicker
            disabled={this.props.disabled}
            value={this.state.time ? moment(this.state.time, 'HH:mm') : "00:00"}
            onChange={this.handleTimeChange}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                size="small"
                className={'form-control time-picker ' + this.props.className + (error && touched ? ' is-invalid' : '') + (isSame === false ? ' change-value' : '') + (this.props.disabled ? ' disabled' : '')}
                InputProps={{
                  endAdornment: <InputAdornment position="start">
                    <AccessTimeIcon fontSize="small" />
                  </InputAdornment>,
                }}
                error={Boolean(error && touched)}
              />
            )}
            ampm={false}
            inputFormat="HH:mm"
            componentsProps={{
              actionBar: {
                actions: ['cancel', 'accept'],
              },
            }}
          />
        </LocalizationProvider>
        {touched && error && (
          <ErrorMessage name={this.props.name} component="div" className="invalid-feedback" />
        )}
      </div>
    );
  }
}

export default Example;

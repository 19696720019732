import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const DigitalSignatureService = {
    CheckPassword,
    SetSignature,
    ApprovalSignature
};

function CheckPassword(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/auth/authorization-check`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function SetSignature(value) {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/auth/set-signature`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function ApprovalSignature(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/auth/approval`, requestOptions)
        .then(response => {
            return response.text().then(text => {
                try {
                    const data = JSON.parse(text); 
                    console.log(data)   
                    if (!response.ok) {
                        if ([401, 403].indexOf(response.status) !== -1) {
                            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                            // authenticationService.logout();
                            // window.location.reload(true);
                        }
            
                        // const error = (data && data.message);
                        // return Promise.reject(error);
                    }
            
                    return data;
                
                } catch (error) {

                    return text;
                }
            })
        })
}
import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const PSURService = {
    PrepareForm,
    Create,
    Read,
    Update,
    Delete,
    FormData,
    Download
};

function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/report-submission/psur/prepare-form/` + id , requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/psur/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(page = 1, limit = 10, query, params = {}) {


    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/psur/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

function Update(id, value) {

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/psur/update?id=` + id, requestOptions)
    .then(handleResponse)
    .then(response => {
        return response;
    });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/psur/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function FormData(product, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/psur/form-data?product=${product}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function Download(id, product, periode) {
    fetch('/api/v1/psur/doc/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `PSUR ${product} - ${periode}.docx`);
        document.body.appendChild(link);
        link.click();
    })
}


import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";

import ReportInformation from "./TabKIPINonSerious/ReporterInformation";
import LetterInformation from "./TabKIPINonSerious/LetterInformation";

import { Role, Status } from "../../../helpers/Constants";
import { authenticationService } from "../../../services/AuthService";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { AEFINonSeriousService } from "../../../services/BPOM/AEFINonSeriousService";
import { LetterService } from "../../../services/Master/LetterService";

import { CommentNonSeriousModal } from "./CommentNonSeriousModal";

import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";

import { withTranslation } from "react-i18next";

class DetailKIPINonSerious extends Component {
  constructor(props) {
    super(props);

    const { id } = this.props.match.params;

    this.state = {
      id: id,
      snackbaropen: false,
      snackbarmsg: "",
      historyForm: [],
      caseEntryData: [],
      currentTab: "report-information",
      backTab: "",
      nextTab: "letter-information",
      caseEntryData: [],
      CommentModalShow: false,
    };

    this.formikRef = React.createRef();
    this.commentModalRef = React.createRef();
    this.prepareForm(id);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  prepareForm(id) {
    Promise.all([
      LetterService.Read(1, 0, ""),
      AEFINonSeriousService.PrepareForm(id),
    ]).then(([letter, data]) => {
      this.setState({
        letter: letter.data,
        data: data.data,
      });

      if (
        data.data?.status === Status.Approved ||
        data.data?.status === Status.Closed
      ) {
        this.setState({ nextTab: "letter-information" });
      } else {
        this.setState({ nextTab: "" });
      }
    });
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  handleGetData(data) {
    this.setState({ caseEntryData: data });
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleTabChange(event, tab) {
    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "letter-information",
        });
        break;
      case "#letter-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "letter-information",
          backTab: "report-information",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleMonthRange(start, end) {
    let startDate = moment(start, "YYYY/MM/DD");
    let endDate = moment(end, "YYYY/MM/DD");

    return {
      from: { year: startDate.format("YYYY"), month: startDate.format("M") },
      to: { year: endDate.format("YYYY"), month: endDate.format("M") },
    };
  }

  handleCancelClick(event) {
    this.props.history.push(`/report-submission/bpom`, {
      tab: "KIPINonSerious",
      page: this.props?.location?.state?.page,
    });
    event.preventDefault();
  }

  render() {
    const { t } = this.props;

    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    const nonSerious = this.state.data;
    const letter = this.state.letter;
    const formType = this.props.location?.state?.formType
      ? this.props.location?.state?.formType
      : "follow-up";
    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (nonSerious?.status === Status.Pending ||
        nonSerious?.status === Status.Draft ||
        nonSerious?.status === Status.NotOk ||
        nonSerious?.status === Status.NotApproved ||
        nonSerious?.status === Status.Approved ||
        this.props.isFollowUp);
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (nonSerious?.status === Status.Draft ||
        nonSerious?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (nonSerious?.status === Status.Draft ||
        nonSerious?.status === Status.Reviewed);

    const l2 = nonSerious?.letterNumber?.split("/")[1];
    const l4 = nonSerious?.letterNumber?.split("/")[3];
    const l5 = nonSerious?.letterNumber?.split("/")[4];

    const caseEntry = nonSerious?.aefiNonSeriousData;

    const initialValues = {
      id: nonSerious?.id,
      letterPlace: nonSerious?.letterPlace,
      letterDate: nonSerious?.letterDate
        ? moment(nonSerious?.letterDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      letterAddress: nonSerious?.letterAddress,
      letterNumber: nonSerious?.letterNumber,
      periodBegin: moment(nonSerious?.periodBegin).format("YYYY-MM-DD"),
      periodEnd: moment(nonSerious?.periodEnd).format("YYYY-MM-DD"),
      period: this.handleMonthRange(
        nonSerious?.periodBegin,
        nonSerious?.periodEnd
      ),
      status: nonSerious?.status,
      mailingOrder: l2,
      mailingMonth: l4,
      mailingYear: l5,
      sendDate: nonSerious?.sendDate,
      letterNumber1: letter?.records[0].code,
      letterNumber3: letter?.records[0].shortName,
      originalRecapLetter: nonSerious?.originalRecapLetterArr ?? [],
      caseTotal: nonSerious?.aefiNonSeriousData?.length,
    };

    const initialTouched = {
      mailingOrder: true,
      mailingMonth: true,
      mailingYear: true,
      sendDate: true,
      period: true,
      letterPlace: true,
      letterDate: true,
      letterAddress: true,
    };

    const validationSchemaDict =
      nonSerious?.status !== Status.Approved
        ? {
            "report-information": Yup.object().shape({
              letterPlace: Yup.string().required("Required").nullable(),
              letterDate: Yup.string().required("Required").nullable(),
              letterAddress: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "report-information": Yup.object().shape({
              letterPlace: Yup.string().required("Required").nullable(),
              letterDate: Yup.string().required("Required").nullable(),
              letterAddress: Yup.string().required("Required").nullable(),
            }),
            "letter-information": Yup.object().shape({
              mailingOrder: Yup.string().required("Required").nullable(),
              mailingMonth: Yup.string().required("Required").nullable(),
              mailingYear: Yup.string().required("Required").nullable(),
              sendDate: Yup.string().required("Required").nullable(),
            }),
          };

    const disabledLetter =
      nonSerious?.status === Status.Approved ||
      nonSerious?.status === Status.Closed
        ? false
        : true;

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />

        <CommentNonSeriousModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          id={this.state.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />

        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          type="aefi-non-serious"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          type="aefi-non-serious"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("report_information")}
                  href="#report-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#report-information")
                  }
                  id="link-report-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("letter_information")}
                  href="#letter-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#letter-information")
                  }
                  id="link-letter-information"
                  tooltip={t("filled_by_staff")}
                  disabled={disabledLetter}
                />
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          initialTouched={initialTouched}
          initialValues={initialValues}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          enableReinitialize
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            if (
              values.mailingOrder &&
              values.mailingMonth &&
              values.mailingYear &&
              nonSerious?.status === Status.Approved
            ) {
              values.letterNumber =
                values.letterNumber1 +
                "/" +
                values.mailingOrder +
                "/" +
                values.letterNumber3 +
                "/" +
                values.mailingMonth +
                "/" +
                values.mailingYear;
            }

            AEFINonSeriousService.submitForm(values.id, values).then(
              (result) => {
                toastSuccess(t("success_edit_message"));
                this.props.history.push(`/report-submission/bpom`, {
                  tab: "KIPINonSerious",
                  page: this.props?.location?.state?.page,
                });
              },
              (error) => {
                setSubmitting(false);
                toastError(error);
              }
            );
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <ReportInformation
                  formik={formik}
                  type="detail"
                  formType={formType}
                  caseentry={caseEntry}
                />
                <LetterInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  list={this.state.list}
                />
                <div className="text-right">
                  {this.state.backTab === "" && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("cancel")}
                        onClick={(event) => this.handleCancelClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  {this.state.nextTab !== "" && (
                    <>
                      <ToggleDisplay>
                        <Button
                          text={t("next")}
                          onClick={(event) => this.handleNextClick(event)}
                          isSubmitting={this.state.isDisableNext}
                        />
                      </ToggleDisplay>
                    </>
                  )}
                  {this.state.nextTab === "" && this.state.backTab !== "" && (
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                  )}
                  {/* Simpan */}
                  {canStaffEdit && this.state.nextTab === "" && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        type="submit"
                        text={t("save")}
                        isSubmitting={formik.isSubmitting}
                      />
                    </ToggleDisplay>
                  )}

                  <input
                    name="isApprove"
                    type="hidden"
                    {...formik.getFieldProps("isApprove")}
                  />

                  {/* Reject */}
                  {canDivisiEdit && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        background="#e15756 0% 0% no-repeat padding-box"
                        text="Reject"
                        onClick={() => {
                          formik.setFieldValue("isApprove", false);
                          this.setState({ RejectedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {canUnitEdit && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        background="#e15756 0% 0% no-repeat padding-box"
                        text="Reject"
                        onClick={() => {
                          formik.setFieldValue("isReview", false);
                          this.setState({ RejectedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {/* Forward/Approve */}
                  {canDivisiEdit && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        text={
                          currentUser.role === Role.Unit ? "Forward" : "Approve"
                        }
                        onClick={() => {
                          formik.setFieldValue("isApprove", true);
                          this.setState({ ForwardedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {/* Forward/Approve */}
                  {canUnitEdit && (
                    <ToggleDisplay show={this.state.saveShow}>
                      <Button
                        text={"Forward"}
                        onClick={() => {
                          formik.setFieldValue("isReview", true);
                          this.setState({ ForwardedConfirmationModal: true });
                        }}
                      />
                    </ToggleDisplay>
                  )}
                  {formik.status && (
                    <div className={"alert alert-danger"}>{formik.status}</div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailKIPINonSerious);

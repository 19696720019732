import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const QAService = {
    Read,
    Download
};



async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/reconcilation-data/qa/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function Download(params = {}, lang) {

    let url = `/api/v1/reconcilation-data/qa/download-xls-${lang}?param=param`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => {
            if (response.ok) {
                var filename = "";
                var disposition = response.headers.get("content-disposition");
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                response.blob().then(result => {
                    const url = window.URL.createObjectURL(result);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                })
            } else {
                return response.json()
            }
        })
}





import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import TableStatus from "../../../components/Utils/Status/TableStatus";
import { Loader } from "../../../components/Utils/Loader/Loader";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { Status, Role } from "../../../helpers/Constants";

import { SummaryService } from "../../../services/QualityReview/Summary/SummaryService";
import { authenticationService } from "../../../services/AuthService";

import { DeleteModal } from "../../../components/Utils/Modal/DeleteModal";

import { withTranslation } from "react-i18next";

class Summary extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      isExpand: false,
      isElement: false,
      elementSpan: "",
      records: [],
      filterShow: false,
      addModalShow: false,
      deleteModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      loading: false,
      timezone: moment(new Date()).format("Z"),
      initPage: pages,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageClick(event) {
    if (this.state.initPage !== undefined) {
      this.setState({ page: this.state.initPage, initPage: undefined }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  handleStatusChange(item) {
    let data = this.state.params;
    data = { ...data, status: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleCategoryChange(item) {
    let data = this.state.params;
    data = { ...data, caseType: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  record(id) {
    this.props.history.push({
      pathname: `/medical-review/summary/detail/${id}`,
      state: { formType: "edit", page: this.state.page },
    });
  }

  followUp(id) {
    this.props.history.push(`/medical-review/summary/follow-up/${id}`);
  }

  add = () => {
    this.props.history.push(`/medical-review/summary/add-summary`);
  };

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  deleteRecord(id) {
    const { t } = this.props;
    this.handleLoading(true);
    SummaryService.Delete(id).then(
      (result) => {
        this.refreshList();
        toastSuccess(t("success_delete_message"));
        this.setState({ deleteModalShow: false });
        this.handleLoading(false);
      },
      (error) => {
        toastError(t("failed_delete_message"));
        this.handleLoading(false);
      }
    );
  }

  refreshList() {
    this.handleLoading(true);
    SummaryService.Read(
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState({
        maxPage: result?.data?.maxPage,
        records: result?.data?.records,
        editModalShow: new Array(result?.data?.length).fill(false),
      });
      this.handleLoading(false);
    });
  }

  render() {
    const { t } = this.props;
    let currentUser = authenticationService.currentUserValue;

    const statusOptions = [
      { value: "Pending", label: "Pending" },
      { value: "Draft", label: "Draft" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
    ];

    const categoryOptions = [
      { value: "AEFI", label: "KIPI" },
      { value: "AE", label: "KTD" },
    ];

    const { records } = this.state;

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          message={t("delete_confirmation")}
        />

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_summary")}
                    icon="fa fa-plus"
                    onClick={() => this.add()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                      <div className="form-group">
                        <label className="form-label">{t("search")}</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.query}
                            onChange={(event) => this.handleSearchChange(event)}
                            placeholder={t("keyword")}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="submit"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">{t("date")}</label>
                    <DateRange
                      handlechange={(startDate, endDate, type) =>
                        this.handleDateChange(startDate, endDate, type)
                      }
                      type="KIPI"
                      filterUntil = {true}
                    />
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">Status</label>
                    <Select
                      placeholder="Status"
                      width="100%"
                      name="statusFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={statusOptions}
                      value={this.state.status}
                      onChange={(item) => this.handleStatusChange(item)}
                    ></Select>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">{t("category")}</label>
                    <Select
                      placeholder={t("category")}
                      width="100%"
                      name="statusKategori"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={categoryOptions}
                      value={this.state.category}
                      onChange={(item) => this.handleCategoryChange(item)}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("summary_created_date")}</th>
                    <th width="20%">Status</th>
                    <th width="12%"></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.page - 1) * this.state.limit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {record.createdDate
                            ? moment(
                                record.followUpDate ?? record.createdDate
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) &&
                                currentUser.role !==
                                  Role.RegulasiMutuSistem && (
                                  <>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        SummaryService.Download(
                                          record.id,
                                          record.caseType === "AEFI"
                                            ? "KIPI"
                                            : "KTD",
                                          record.caseNumber
                                        )
                                      }
                                    >
                                      Download PDF
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        SummaryService.DownloadDoc(
                                          record.id,
                                          record.caseType === "AEFI"
                                            ? "KIPI"
                                            : "KTD",
                                          record.caseNumber
                                        )
                                      }
                                    >
                                      Download Doc
                                    </a>
                                  </>
                                )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="6">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>{t("report_type")}</th>
                                  <th>{t("summary_created_date")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailSummary
                                  ?.sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail, index) => (
                                    <tr>
                                      <td>
                                        {trail.followUp === 0
                                          ? "Inisial"
                                          : "Follow Up " + trail.followUp}
                                      </td>
                                      <td>
                                        {moment(
                                          trail.followUpDate ?? trail.createDate
                                        ).format("DD/MM/yyyy")}
                                      </td>
                                    </tr>
                                  ))}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {moment(
                                      record.followUpDate ?? record.createDate
                                    ).format("DD/MM/yyyy")}
                                  </td>
                                </tr>
                                {currentUser.role === Role.Staff && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text="Tambah Follow Up"
                                          margin="0"
                                          onClick={() =>
                                            this.followUp(record.id)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {records.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={(this.state.page)-1}
                // forcePage={(this.state.page)-1}
                initialPage={this.state.initPage ? this.state.initPage - 1 : 0}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Summary);

import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const DropdownService = {
    MasterList,
    MasterVaccineList,
    MasterDrugsList,
    CountryList,
    ProvinceList,
    CityList,
    PharmaceuticalList
};

async function MasterList(types) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master/all-list?types=` + types.join("&types="), requestOptions);
    data = await handleResponse(data)
    return data;
}

async function MasterVaccineList(isClinicalTrialCreated = "", isMarketAuthorizationCreated = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master-vaccine/read?isClinicalTrialCreated=${isClinicalTrialCreated}&isMarketAuthorizationCreated=${isMarketAuthorizationCreated}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function MasterDrugsList(isClinicalTrialCreated = "", isMarketAuthorizationCreated = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master-drugs/read??isClinicalTrialCreated=${isClinicalTrialCreated}&isMarketAuthorizationCreated=${isMarketAuthorizationCreated}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function CountryList() {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master/country`, requestOptions);
    data = await handleResponse(data)
    return data;
}


async function ProvinceList(country) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master/provincy/` + country, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function CityList(province) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`api/v1/master/city/` + province, requestOptions);
    data = await handleResponse(data)
    return data;
}

function PharmaceuticalList() {
    return [
        { value: 1, label: '(Unknown)' },
        { value: 2, label: 'Aerosol' },
        { value: 3, label: 'Alat Kontrasepsi' },
        { value: 4, label: 'Bolus' },
        { value: 5, label: 'Bulk' },
        { value: 6, label: 'Buscal Spray' },
        { value: 7, label: 'Cairan Diagnostik' },
        { value: 8, label: 'Cairan Inhalasi Oral' },
        { value: 9, label: 'Cairan Inhalasi Oral' },
        { value: 10, label: 'Cairan Injeksi/Suspensi Injeksi' },
        { value: 11, label: 'Cairan Mata' },
        { value: 12, label: 'Cairan Steril' },
        { value: 13, label: 'Cairan/Suspensi Obat Luar' },
        { value: 14, label: 'Cap Salut Enterik' },
        { value: 15, label: 'Capsul' },
        { value: 16, label: 'Capsul Lunak' },
        { value: 17, label: 'Capsul Pelepasan Lambat' },
        { value: 18, label: 'Captab' },
        { value: 19, label: 'Captab Kunyah Salut Selaput' },
        { value: 20, label: 'Captab Pelepasan Cepat' },
        { value: 21, label: 'Captab Pelepasan Lambat' },
        { value: 22, label: 'Captab Salut Enterik' },
        { value: 23, label: 'Captab Salut Gula' },
        { value: 24, label: 'Captab Salut Selaput' },
        { value: 25, label: 'Chains' },
        { value: 26, label: 'Chewing Gum' },
        { value: 27, label: 'Cream' },
        { value: 28, label: 'Drops' },
        { value: 29, label: 'Elixir' },
        { value: 30, label: 'Emulsi' },
        { value: 31, label: 'Enema' },
        { value: 32, label: 'Gas (Dl)' },
        { value: 33, label: 'Gel' },
        { value: 34, label: 'Gel Mata' },
        { value: 35, label: 'Granul Eff.' },
        { value: 36, label: 'Granula' },
        { value: 37, label: 'Infus' },
        { value: 38, label: 'Inhaler (Padat)' },
        { value: 39, label: 'Kombinasi Beberapa Bentuk' },
        { value: 40, label: 'Lain-Lain' },
        { value: 41, label: 'Larutan/Cairan' },
        { value: 42, label: 'Obat Kumur Dan Sejenis' },
        { value: 43, label: 'Orodispersable Tablet' },
        { value: 44, label: 'Ovula' },
        { value: 45, label: 'Padat Obat Luar' },
        { value: 46, label: 'Padat/Cone' },
        { value: 47, label: 'Pasta' },
        { value: 48, label: 'Pastilles' },
        { value: 49, label: 'Patch' },
        { value: 50, label: 'Pilulae' },
        { value: 51, label: 'Salep' },
        { value: 52, label: 'Salep Mata' },
        { value: 53, label: 'Sediaan Dalam Satu Kemasan' },
        { value: 54, label: 'Semprot Hidung' },
        { value: 55, label: 'Serbuk Infus' },
        { value: 56, label: 'Serbuk Inhaler' },
        { value: 57, label: 'Serbuk Injeksi/+Pelarut' },
        { value: 58, label: 'Serbuk Intra Okular' },
        { value: 59, label: 'Serbuk Obat Luar/Serbuk Tabur 23' },
        { value: 60, label: 'Serbuk Spray' },
        { value: 61, label: 'Serbuk Steril' },
        { value: 62, label: 'Serbuk/Serbuk Effervescent 21/3' },
        { value: 63, label: 'Shampo' },
        { value: 64, label: 'Sirup' },
        { value: 65, label: 'Sirup Kering' },
        { value: 66, label: 'Sirup Kering Plp Lmbt' },
        { value: 67, label: 'Spray Oral' },
        { value: 68, label: 'Spray Topikal' },
        { value: 69, label: 'Spray Transdermal' },
        { value: 70, label: 'Subdermal Implants' },
        { value: 71, label: 'Suppositoria' },
        { value: 72, label: 'Suspensi' },
        { value: 73, label: 'Tab Cepat Larut' },
        { value: 74, label: 'Tab Dispersibel' },
        { value: 75, label: 'Tablet' },
        { value: 76, label: 'Tablet Effervescent' },
        { value: 77, label: 'Tablet Hisap' },
        { value: 78, label: 'Tablet Kunyah/Kaptab Kunyah' },
        { value: 79, label: 'Tablet Pelepasan Cepat' },
        { value: 80, label: 'Tablet Pelepasan Lambat' },
        { value: 81, label: 'Tablet Salut Enterik' },
        { value: 82, label: 'Tablet Salut Gula' },
        { value: 83, label: 'Tablet Salut Selaput' },
        { value: 84, label: 'Tablet Sublingual' },
        { value: 85, label: 'Tablet Sublingual Pelepasan Lambat' },
        { value: 86, label: 'Tablet Vaginal' },
        { value: 87, label: 'Tetes Hidung' },
        { value: 88, label: 'Tetes Mata' },
        { value: 89, label: 'Tetes Mata Dab Telinga' },
        { value: 90, label: 'Tetes Telinga' },
        { value: 91, label: 'Transdermal' },
        { value: 92, label: 'Transdermal Urethral' },
        { value: 93, label: 'Tulle/Plester Obat' },
        { value: 94, label: 'Vaginal Cream/Gel' },
        { value: 95, label: 'Vaginal Douche' },
        { value: 96, label: 'Vaginal Ring' },
        { value: 97, label: 'Vaginal Tissue' },
    ]
}

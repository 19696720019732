import React, { Component } from 'react';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalBody';
import Button from '../Button/Button';

import { SignatureForm } from '../Signature/SignatureForm';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';


export class ConfirmationModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '', disabled: true };
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    disabledButton = (value) => {

        this.setState({disabled : value})
    }

    render() {

        const {t} = this.props;

        return (
            <div className="container">
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">{ t ? t('confirmation') : "Konfirmasi"}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>{this.props.text}</div>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                verified: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    verified: Yup.string().required("Required").nullable(),
                                })
                            }
                            onSubmit={(values, { setStatus, setSubmitting }) => {
                                this.props.confirm()
                            }}
                        >
                            {props => (
                                <Form>

                                    <div className="form-group">
                                        <label className="form-label">Digital Signature</label>
                                        <SignatureForm
                                            formik = {props}
                                            check = {true}
                                            id = {this.state.id}
                                            disabledButton = {() => this.disabledButton()}
                                            t={t}
                                        />
                                    </div>

                                    <div className='text-right'>
                                        {this.props.showhide &&
                                            <Button
                                                color="#495057"
                                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                                border="1px solid #495057"
                                                text={ t ? t('cancel') : "Batal"}
                                                onClick={this.props.hide} 
                                            />
                                        }
                                        <Button 
                                            background="#00ABBD"
                                            color="white"
                                            type="submit" 
                                            text={ t ? t('yes') : "Ya"}
                                            isSubmitting={this.state.disabled}
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
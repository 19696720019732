import { handleResponse, authHeader } from '../helpers/RequestHelper';

export const DivisionService = {
    Read,
};

async function Read() {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/auth/get-head-division`

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}
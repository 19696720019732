import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const NotificationService = {
    ReadNotification,
    Read,
    ReadAll,
};

function ReadNotification(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/notification/read`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(page = 1, limit = 10, query, isRead = "" ) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let data = await fetch(`/api/v1/notification/read?page=${page}&limit=${limit}&query=${query}&isRead=${isRead}`, requestOptions);
    data = await handleResponse(data)
    return data;
}

function ReadAll(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/notification/read-all`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

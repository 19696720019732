import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

import { withTranslation } from "react-i18next";

class ClinicalTrial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
        };
    }

    render() {

        const { formik, formType, t, ...otherProps } = this.props;

        let total = 0;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="clinical-trial">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">CLINICAL TRIAL</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Clinical Trial</th>
                                                <th>Age Range</th>
                                                <th>Tujuan</th>
                                                <th>Male</th>
                                                <th>Female</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.pbrerClinicalTrial?.map((record, index) => {
                                                    total = total + record?.total

                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{record?.name}</td>
                                                            <td>{record?.ageRange}</td>
                                                            <td>{record?.purpose}</td>
                                                            <td>{record?.totalMale}</td>
                                                            <td>{record?.totalFemale}</td>
                                                            <td>{record?.total}</td>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                            {
                                                (formik.values?.pbrerClinicalTrial === undefined || formik.values?.pbrerClinicalTrial === null || formik.values?.pbrerClinicalTrial?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                    <hr />
                                    <div style={{ width: "100%", marginBottom: 30, marginTop: 30 }}>
                                        <div style={{ float: "right" }}>
                                            <span style={{ marginRight: 20 }}><span style={{ fontWeight: "bolder", color: "black", marginRight: 20 }}>Total : </span>{total} vial</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ClinicalTrial);
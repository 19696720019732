import React, { Component } from 'react';

import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';
import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { Status } from '../../../../helpers/Constants';

import {LetterKIPIOverseasModal} from "../LetterKIPIOverseasModal"
import { withTranslation } from "react-i18next";

class PatientInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
            selectedData : [],
            isComplete : "",
        };
    }
    
    handleCancelClick(event) {

        this.props.history.push('/report-submission/bpom');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, historydata, list, t, ...otherProps } = this.props;
        const isDisabledForm = (this.props.formType !== "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || (this.props.formType === "edit" && this.props.isLastVersion && (this.props.caseStatus !== Status.Reviewed && this.props.caseStatus !== Status.Approved)) || this.props.isLastVersion === undefined  ? false : this.props.isLastVersion === false || this.props.caseStatus === Status.Reviewed || this.props.caseStatus === Status.Approved ;
        const vaccine = formik?.values?.vaccines ? formik?.values?.vaccines : [];

        let LetterKIPIOverseasModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        return (
            <>
                <LetterKIPIOverseasModal
                    show={this.state.letterShow}
                    onHide={LetterKIPIOverseasModalClose}
                    formik={formik}
                />
                <div className="tab-pane tab-pane-fixed" id="patient-information">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('initial_name')}</label>
                                <Input
                                    disabled
                                    name="patientName"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('patientName')}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('gender')}</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="M" name="patientGender"
                                            disabled
                                            checked={formik.values.patientGender === "M"}
                                            onChange={formik.handleChange} />
                                        <label className="form-check-label">{t('male')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="F" name="patientGender"
                                            disabled
                                            checked={formik.values.patientGender === "F"}
                                            onChange={formik.handleChange} />
                                        <label className="form-check-label">{t('female')}</label>
                                    </div>
                                </div>
                            </div>
                            <label className="form-label">{t('age')}</label>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <input
                                                name="patientAgeYear"
                                                type="number"
                                                min={0}
                                                placeholder={t('year')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values.patientAgeYear ? formik.values.patientAgeYear : "" }
                                                {...formik.getFieldProps('patientAgeYear')}
                                                disabled   
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('year')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input
                                                disabled
                                                name="patientAgeMonth"
                                                type="number"
                                                min={0}
                                                max={11}
                                                placeholder={t('month')}
                                                className="form-control"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('patientAgeMonth')}
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">{t('month')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                vaccine.map((record, index) =>
                                    <>
                                        <div className="row" key={index} style={{padding : "5px 1px"}}>
                                            <div className="col-1">
                                                    {
                                                        index === 0 && 
                                                        <>
                                                            <label className="form-label" style={{color:"white"}}>No</label>
                                                            <br/>
                                                        </>
                                                    }
                                                    <span style={{fontSize: 14, paddingTop: 5 }}>{index+1}</span>
                                            </div>
                                            <div className="col-6" style={{padding: "5px 1px"}}>
                                                    {
                                                        index === 0 && 
                                                        <label className="form-label">{t('product_name')}</label>
                                                    }
                                                    <Input
                                                        disabled
                                                        name="productSubmission"
                                                        value={record.name}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                    />
                                                
                                            </div>
                                            <div className="col-5" style={{padding : "5px 1px"}}>
                                                
                                                    {
                                                        index === 0 && 
                                                        <label className="form-label">{t('batch_number')}</label>
                                                    }
                                                    <Input
                                                        disabled
                                                        name="productBacth"
                                                        value={record.batch}
                                                        errors={formik.errors}
                                                        touched={formik.touched}
                                                    />
                                                
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('immunization_date')}</label>
                                <DatePicker
                                    disabled
                                    name="immunizationDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.immunizationDate}
                                />
                            </div>
                            <div  className="form-group">
                                <label className="form-label">{t('onset_date')}</label>
                                <DatePicker
                                    disabled
                                    name="onsetDate"
                                    selectionType="single"
                                    maxdate={new Date()}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                    value={formik.values.onsetDate}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('complaint')}</label>
                                <Textarea
                                    rows="6"
                                    name="keluhan"
                                    placeholder={t('complaint')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`keluhan`)}
                                    disabled={isDisabledForm}
                                    same={historydata?.complaint ? historydata.complaint === formik.values?.keluhan : true }
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('outcome')}</label>
                                <Select2
                                    disabled
                                    name="outcome"
                                    options={list?.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values.outcome}
                                    onChange={formik.setFieldValue}
                                    onBlur={formik.setFieldTouched}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">{t('progress_report_letter_to_bpom')}</label>
                                <br/>
                                <Button
                                    type="button"
                                    text={t('letter_preview')}
                                    onClick={()=>this.setState({ letterShow : true })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withTranslation()(PatientInformation);
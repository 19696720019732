import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';

import App from './App';
import { history } from "./helpers/history";

import { I18nextProvider } from 'react-i18next';

import "./index.css";
import i18n from './i18n.js';

console.log("Ver 20231211.1000");

const rootElement = document.getElementById('main-wrapper');

ReactDOM.render(
    <Router history={history}>
        <Suspense fallback="Loading...">
            <I18nextProvider i18n={i18n}>
                <App /> 
            </I18nextProvider>
        </Suspense>
    </Router>,
    rootElement
);


import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../helpers/RequestHelper';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('identity')));

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    loginCTI,
    get currentUserValue() { return currentUserSubject.value }
};

function login(username, password) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(`api/v1/auth/login`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('identity', JSON.stringify(response.data));
            currentUserSubject.next(response.data);

            return response;
        });
}

function loginCTI(token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token })
    };

    return fetch(`api/v1/auth/login-cti`, requestOptions)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('identity', JSON.stringify(response.data));
            currentUserSubject.next(response.data);
            //console.log("sup");
            return response;
        });
}

export function logout() {
    // CaseNumberingService.Clear();
    // remove user from local storage to log user out
    localStorage.removeItem('identity');
    currentUserSubject.next(null);
}

import React, { Component } from 'react';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import Button from '../../../components/Utils/Button/Button';


export class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = { snackbaropen: false, snackbarmsg: '' };

    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    render() {

        const {t} = this.props;

        return (
            <div className="container">
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">{t('confirmation')}</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        <div style={{marginTop: 15, marginBottom: 25, fontSize: 14, color:"#485867"}}>{t('delete_confirmation')}</div>
                        <div className='text-right'>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('cancel')}
                                onClick={this.props.onHide} 
                            />
                            <Button 
                                background="#E15756"
                                color="white"
                                type="submit" 
                                text={t('yes')}
                                onClick={this.props.confirmdelete}
                            />
                        </div>
                    </ModalContent>
                </Modal>
            </div>

        )
    }
}
import { handleResponse, authHeader } from '../../../helpers/RequestHelper';

export const ResumeService = {
    PrepareForm,
    Create,
    Read,
    Update,
    Delete,
    FollowUp,
    Download,
    DownloadDoc
};

function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/medical-review/resume/prepare-form/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/medical-review/resume/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function FollowUp(id, value) {
    value.id = id

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/medical-review/resume/create/follow-up`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/medical-review/resume/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

function Update(id, value) {
    value.id = id

    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/medical-review/resume/update`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/medical-review/resume/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function ReviewForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/resume/review/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ApproveForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/resume/approve/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function Download(id) {
    fetch('/api/v1/medical-review/resume/pdf/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Medical Review Resume.pdf');
        document.body.appendChild(link);
        link.click();
    })
}

async function DownloadDoc(id) {
    fetch('/api/v1/medical-review/resume/doc/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Medical Review Resume.doc');
        document.body.appendChild(link);
        link.click();
    })
}

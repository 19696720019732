import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const InitialService = {
    Create,
    Read,
    Update,
    PrepareForm,
    Delete,
    DownloadEn,
    DownloadId,
    DownloadDocEn,
    DownloadDocId,
    Preview
};

function Create(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/manufacture/submit-form/initial/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


function Update(id, object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    return fetch(`/api/v1/report-submission/manufacture/submit-form/initial/update`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}


async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/manufacture/read?page=${page}&limit=${limit}&query=${query}&type=INITIAL`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

async function PrepareForm(id) {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/manufacture/prepare-form/initial/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/manufacture/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function DownloadEn(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/manufacture-initial-en/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Initial Report for case number ${casenumber}.pdf`);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadId(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/manufacture-initial-id/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Laporan Inisial dengan nomor kasus ${casenumber}.pdf`);
            document.body.appendChild(link);
            link.click();
        })
}

function Preview(object) {
    return fetch('/api/v1/report-submission/pdf/export-manufacture-initial', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    })
        .then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                return url;
            })
        })
}

async function DownloadDocEn(id, casenumber) {
    fetch('/api/v1/report-submission/doc/manufacture-initial-en/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Initial Report for case number ${casenumber}.docx`);
            document.body.appendChild(link);
            link.click();
        })
}


async function DownloadDocId(id, casenumber) {
    fetch('/api/v1/report-submission/doc/manufacture-initial-id/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Laporan Inisial dengan nomor kasus ${casenumber}.docx`);
            document.body.appendChild(link);
            link.click();
        })
}
import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from "react-toggle-display";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faHistory } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import moment from "moment";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import { Role, Status } from "../../../helpers/Constants";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { RejectModal } from "../../../components/Utils/Modal/RejectModal";
import { ConfirmationModal } from "../../../components/Utils/Modal/ConfirmationModal";
import { ReporterService } from "../../../services/Reporter/ReporterService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { HistoryService } from "../../../services/HistoryService";
import { authenticationService } from "../../../services/AuthService";

import ReportInformation from "./Tab/ReportInformation";
import PatientInformation from "./Tab/PatientInformation";
import LetterInformation from "./Tab/LetterInformation";

import { HistoryModal } from "./HistoryModal";
import { CommentModal } from "./CommentModal";

import { withTranslation } from "react-i18next";

class DetailReporter extends Component {
  constructor(props) {
    super(props);

    const { id, typecase, type } = this.props.match.params;

    this.state = {
      id: id,
      type: type === "follow-up" ? true : false,
      snackbaropen: false,
      snackbarmsg: "",
      backTab: "",
      currentTab: "report-information",
      nextTab: "patient-information",
      comments: [],
      historys: [],
      historydata: [],
      historysCaseEntry: [],
      CommentModalShow: false,
      HistoryModalShow: false,
      ForwardedModal: false,
      ForwardedConfirmationModal: false,
      RejectedModal: false,
      RejectedConfirmationModal: false,
      versionOptions: [],
      isLastVersion: true,
      formtype: this.props.location?.state?.formType
        ? this.props.location?.state?.formType
        : "follow-up",
      reporterDisabled: false,
      patientDisabled: false,
    };

    this.commentModalRef = React.createRef();
    this.formikRef = React.createRef();
    this.getReporterData(id);
    this.historyList(id);
  }

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  handleTabChange(event, tab) {
    switch (tab) {
      case "#report-information":
        this.setState({
          cancelShow: true,
          backShow: false,
          nextShow: true,
          saveShow: false,
          currentTab: "report-information",
          backTab: "",
          nextTab: "patient-information",
        });
        break;
      case "#patient-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "patient-information",
          backTab: "report-information",
          nextTab:
            (this.state.data?.status === Status.Approved ||
              this.state.data?.status === Status.Closed) &&
            this.props.location?.state?.formType === "edit"
              ? "letter-information"
              : "",
        });
        break;
      case "#letter-information":
        this.setState({
          cancelShow: false,
          backShow: true,
          nextShow: true,
          saveShow: true,
          currentTab: "letter-information",
          backTab: "patient-information",
          nextTab: "",
        });
        break;
    }

    event.preventDefault();
  }

  handleDisabledNext(tab, status) {
    switch (tab) {
      case "#report-information":
        this.setState({
          reporterDisabled: status,
        });
        break;
      case "#patient-information":
        this.setState({
          patientDisabled: this.state.reporterDisabled ? true : status,
        });
        break;
    }
  }

  handleBackClick(event) {
    document.getElementById("link-" + this.state.backTab).click();
    event.preventDefault();
  }

  handleNextClick(event) {
    this.formikRef.current.validateForm().then(() => {
      if (this.formikRef.current.isValid) {
        document.getElementById("link-" + this.state.nextTab).click();
      }
    });
    event.preventDefault();
  }

  handleCancelClick(event) {
    this.props.history.push(`/report-submission/reporter`, {
      pages: this.props?.location?.state?.page,
    });
    event.preventDefault();
  }

  handleGetData(data) {
    this.setState({ caseEntryData: data });
  }

  handleCausality(data) {
    this.setState({ causality_value: data });
  }

  getReporterData(id) {
    ReporterService.PrepareForm(id, this.state.typecase).then((result) => {
      if (result.data) {
        this.setState({
          data: result.data,
          caseEntryData:
            result.data.aefiCaseEntryNavigation !== null
              ? result.data.aefiCaseEntryNavigation
              : result.data.aeCaseEntryNavigation,
        });        
        this.historyCaseEntryList(result.data.caseEntry)

        var versionOptions = result.data.trailReporterSubmission
          ?.sort((a, b) => (a.followUp > b.followUp ? 1 : -1))
          .map((trail) => {
            return {
              value: trail.followUp === null ? 0 : trail.followUp,
              label:
                trail.followUp === 0 || trail.followUp === null
                  ? "Inisial"
                  : "Follow Up " + trail.followUp,
            };
          });
        versionOptions.push({
          value: result.data.followUp,
          label:
            result.data.followUp === 0
              ? "Inisial"
              : "Follow Up " + result.data.followUp,
        });

        if (versionOptions) {
          this.setState({
            versionOptions: versionOptions,
            selectedVersion: versionOptions[versionOptions.length - 1],
            lastVersion: versionOptions[versionOptions.length - 1],
            isLastVersion: true,
          });
        }

        let beforeVersion =
          this.state.selectedVersion?.value === 0
            ? 0
            : this.state.selectedVersion?.value - 1;
        ReporterService.PrepareForm(id, beforeVersion).then((result) => {
          if (result.data) {
            this.setState({
              historyData: result.data,
              historyCaseEntryData:
                result.data.aefiCaseEntryNavigation !== null
                  ? result.data.aefiCaseEntryNavigation
                  : result.data.aeCaseEntryNavigation,
            });
          }
        });
      }
    });

    DropdownService.MasterList([
      "aeCategory",
      "aefiCategory",
      "vaccine",
      "vaccinator",
      "drugs",
      "seriousness",
      "expectedness",
      "outcome",
    ]).then((result) => {
      this.setState({ list: result.data });
    });
  }

  handleVersionChange(item) {
    this.setState(
      {
        selectedVersion: item,
        beforeVersion: item.value === 0 ? 0 : item.value - 1,
        isLastVersion: item === this.state.lastVersion ? true : false,
      },
      () => {
        const { id } = this.props.match.params;

        ReporterService.PrepareForm(id, this.state.beforeVersion).then(
          (result) => {
            if (result.data) {
              this.setState({
                historyCaseEntryData:
                  result.data.aefiCaseEntryNavigation !== null
                    ? result.data.aefiCaseEntryNavigation
                    : result.data.aeCaseEntryNavigation,
                historyData: result.data,
              });
            }
          }
        );

        ReporterService.PrepareForm(id, this.state.selectedVersion.value).then(
          (result) => {
            if (result.data) {
              this.setState({
                caseEntryData:
                  result.data.aefiCaseEntryNavigation !== null
                    ? result.data.aefiCaseEntryNavigation
                    : result.data.aeCaseEntryNavigation,
                data: result.data,
              });
            }
          }
        );
      }
    );
  }

  handleSubmitClick(event) {
    if (!this.formikRef.current.isSubmitting) {
      this.formikRef.current.submitForm();
    }

    event.preventDefault();
  }

  historyList(id) {
    HistoryService.ReadReportSubmission(id).then((result) => {
      this.setState({ historys: result.data });
    });
  }

  historyCaseEntryList(id) {      
      HistoryService.Read(id, 'AEFI').then((result) => {
          this.setState({ historysCaseEntry: result.data });          
      });      
  }

  render() {
    const { t } = this.props;

    const reporter = this.state.data;
    const lastCaseentry = this.state.caseEntryData;

    const formType = this.state.type ? "follow-up" : "edit";
    const { isLastVersion } = this.state;
    const historyData = this.state.historyData ? this.state.historyData : [];
    const historyCaseEntry = this.state.historyCaseEntryData
      ? this.state.historyCaseEntryData
      : [];

    const currentUser = authenticationService.currentUserValue;
    const canStaffEdit =
      currentUser.role === Role.Staff &&
      (reporter?.status === Status.Pending ||
        reporter?.status === Status.Draft ||
        reporter?.status === Status.NotOk ||
        reporter?.status === Status.NotApproved ||
        this.state.type ||
        reporter?.status === Status.Approved ||
        (formType !== "edit" &&
          reporter?.status === Status.Closed &&
          isLastVersion));
    const canUnitEdit =
      currentUser.role === Role.Unit &&
      (reporter?.status === Status.Draft ||
        reporter?.status === Status.NotApproved);
    const canDivisiEdit =
      currentUser.role === Role.Divisi &&
      (reporter?.status === Status.Draft ||
        reporter?.status === Status.Reviewed);

    const disabledLetter =
      reporter?.status === Status.Approved || reporter?.status === Status.Closed
        ? false
        : true;

    const initialValues = {
      id: reporter?.id,
      caseEntry: reporter?.aefiCaseEntry
        ? reporter?.aefiCaseEntry
        : reporter?.aeCaseEntry,
      caseNumber: reporter?.caseNumber,
      mailingPlace: reporter?.mailingPlace,
      mailingCover: reporter?.mailingCover,
      mailingDate: reporter?.mailingDate
        ? moment(reporter?.mailingDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      mailingDestination: reporter?.mailingDestination,
      mailingMonth: reporter?.mailingMonth,
      conjunction: reporter?.conjunction,
      totalAppendix: reporter?.totalAppendix,
      complaintDate: reporter?.complaintDate
        ? moment(reporter?.complaintDate, "YYYY-MM-DD").format("YYYY/MM/DD")
        : "",
      complaint: reporter?.complaint,
      status: reporter?.status,
      mailingOrder: reporter?.mailingOrder,
      mailingMonth: reporter?.mailingMonth,
      mailingYear: reporter?.mailingYear,
      originalRecapLetter: reporter?.originalRecapLetterArr ?? [],
      immunizationDate:
        lastCaseentry?.immunizationDate ?? lastCaseentry?.lastImmunizationDate,
      initialName: lastCaseentry?.initialName ?? lastCaseentry?.patientName,
      seriousness: lastCaseentry?.seriousness,
      gender: lastCaseentry?.patientGender ?? lastCaseentry?.gender,
      outcome: lastCaseentry?.outcome,
      mailingSentDate: reporter?.mailingSentDate
        ? reporter?.mailingSentDate
        : "",
    };

    const validationSchemaDict =
      reporter?.status !== Status.Approved
        ? {
            "report-information": Yup.object().shape({
              caseNumber: Yup.string().required("Required").nullable(),
            }),
          }
        : {
            "report-information": Yup.object().shape({
              caseNumber: Yup.string().required("Required").nullable(),
            }),
            "letter-information": Yup.object().shape({
              mailingOrder: Yup.string().required("Required").nullable(),
              mailingMonth: Yup.string().required("Required").nullable(),
              mailingYear: Yup.string().required("Required").nullable(),
              mailingSentDate: Yup.string().required("Required").nullable(),
            }),
          };

    const initialTouched = {
      mailingCover: true,
      mailingSentDate: true,
    };

    let HistoryModalShow = () => {
      this.setState({
        HistoryModalShow: true,
      });
    };

    let CommentModalShow = () => {
      this.commentModalRef.current.getComments(this.state.id);
      this.setState({
        CommentModalShow: true,
      });
    };

    let HistoryModalClose = () => {
      this.setState({
        HistoryModalShow: false,
      });
    };
    let CommentModalClose = () => {
      this.setState({
        CommentModalShow: false,
      });
    };

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />
        <HistoryModal
          show={this.state.HistoryModalShow}
          onHide={HistoryModalClose}
          onShow={HistoryModalShow}
          historys={this.state.historys}
          historysCaseEntry={this.state.historysCaseEntry}
          id={reporter?.id}
          title={t("cases_history")}
        />
        <CommentModal
          show={this.state.CommentModalShow}
          onHide={CommentModalClose}
          id={this.props.match?.params?.id}
          ref={this.commentModalRef}
          onShow={CommentModalShow}
          t={t}
        />
        {/* Forward Confirmation */}
        <ConfirmationModal
          show={this.state.ForwardedConfirmationModal}
          hide={() => {
            this.setState({ ForwardedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_unit")
              : t("confirmation_non_unit")
          }
          t={t}
        />

        {/* Forwarded */}
        <ConfirmationModal
          show={this.state.ForwardedModal}
          hide={() => {
            this.setState({ ForwardedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_success_unit")
              : t("confirmation_success_non_unit")
          }
          t={t}
        />

        {/* Rejected Confirmation */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedConfirmationModal}
          hide={() => {
            this.setState({ RejectedConfirmationModal: false });
          }}
          confirm={(event) => {
            this.handleSubmitClick(event);
          }}
          showhide={true}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_unit")
              : t("confirmation_reject_non_unit")
          }
          type="reporter"
          t={t}
        />

        {/* Rejected */}
        <RejectModal
          id={this.state.id}
          show={this.state.RejectedModal}
          hide={() => {
            this.setState({ RejectedModal: false });
          }}
          confirm={() => this.moveBack()}
          showhide={false}
          text={
            currentUser.role === Role.Unit
              ? t("confirmation_reject_success_unit")
              : t("confirmation_reject_success_non_unit")
          }
          type="reporter"
          t={t}
        />
        <div className="nav-fixed-wrapper">
          <div className="nav-fixed">
            <div style={{ display: "grid" }}>
              <div className="nav-fitur">
                <div className="row form-select" style={{ float: "right" }}>
                  <Select
                    name="version"
                    options={this.state.versionOptions}
                    value={this.state.selectedVersion}
                    onChange={(item) => this.handleVersionChange(item)}
                    classNamePrefix="select"
                    className="basic-single"
                  />

                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ HistoryModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                  <span
                    className="nav-icon-fitur"
                    onClick={() => this.setState({ CommentModalShow: true })}
                  >
                    <FontAwesomeIcon icon={faComments} />
                  </span>
                </div>
              </div>
              <ul className="nav nav-justified mb-3 menu-tab">
                <Tab
                  number="1"
                  showNumber={true}
                  title={t("report_information")}
                  href="#report-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#report-information")
                  }
                  id="link-report-information"
                />
                <Tab
                  number="2"
                  showNumber={true}
                  title={t("patient_information")}
                  href="#patient-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#patient-information")
                  }
                  id="link-patient-information"
                />
                <Tab
                  number="3"
                  showNumber={true}
                  title={t("letter_information")}
                  href="#letter-information"
                  onClick={(event) =>
                    this.handleTabChange(event, "#letter-information")
                  }
                  id="link-letter-information"
                  disabled={disabledLetter || formType !== "edit"}
                  tooltip={t("filled_by_staff")}
                />
              </ul>
            </div>
          </div>
        </div>
        <Formik
          innerRef={this.formikRef}
          initialValues={initialValues}
          initialTouched={initialTouched}
          validationSchema={validationSchemaDict[this.state.currentTab]}
          enableReinitialize
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus();

            values.typeCaseEntry = values.caseType === "KIPI" ? "AEFI" : "AE";

            if (formType !== "edit" && isLastVersion) {
              values.isFollowup = true;

              ReporterService.FollowUp(values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/report-submission/reporter`, {
                    pages: this.props?.location?.state?.page,
                  });
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            } else {
              ReporterService.Update(values).then(
                (result) => {
                  toastSuccess(t("success_edit_message"));
                  this.props.history.push(`/report-submission/reporter`, {
                    pages: this.props?.location?.state?.page,
                  });
                },
                (error) => {
                  setSubmitting(false);
                  toastError(error);
                }
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <div className="tab-content">
                <ReportInformation
                  formik={formik}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  cancel={(event) => this.handleCancelClick(event)}
                  updatedata={(data) => this.handleGetData(data)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.reporterDisabled}
                  caseEntry={this.state.caseEntryData}
                  formType={formType}
                  isLastVersion={isLastVersion}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  currentUser={currentUser}
                />

                <PatientInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                  isLastVersion={isLastVersion}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  currentUser={currentUser}
                />

                <LetterInformation
                  formik={formik}
                  handleBackClick={(event) => this.handleBackClick(event)}
                  handleNextClick={(event) => this.handleNextClick(event)}
                  updatedisabled={(tab, status) =>
                    this.handleDisabledNext(tab, status)
                  }
                  list={this.state.list}
                  disabled={this.state.patientDisabled}
                  caseEntry={this.state.caseEntryData}
                  historydata={historyData}
                  historyCaseEntry={historyCaseEntry}
                  currentUser={currentUser}
                />
                {this.state.nextTab !== "" && this.state.backTab !== "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    <ToggleDisplay>
                      <Button
                        text={t("next")}
                        onClick={(event) => this.handleNextClick(event)}
                      />
                    </ToggleDisplay>
                  </div>
                )}
                {this.state.nextTab == "" && (
                  <div className="text-right">
                    <ToggleDisplay>
                      <Button
                        color="#495057"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        border="1px solid #495057"
                        text={t("back")}
                        onClick={(event) => this.handleBackClick(event)}
                      />
                    </ToggleDisplay>
                    {/* Simpan */}
                    {canStaffEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          type="submit"
                          text={t("save")}
                          isSubmitting={
                            !this.state.isLastVersion || formik.isSubmitting
                          }
                        />
                      </ToggleDisplay>
                    )}

                    <input
                      name="isApprove"
                      type="hidden"
                      {...formik.getFieldProps("isApprove")}
                    />

                    {/* Reject */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isApprove", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          background="#e15756 0% 0% no-repeat padding-box"
                          text="Reject"
                          onClick={() => {
                            formik.setFieldValue("isReview", false);
                            this.setState({ RejectedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canDivisiEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={
                            currentUser.role === Role.Unit
                              ? "Forward"
                              : "Approve"
                          }
                          onClick={() => {
                            formik.setFieldValue("isApprove", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {/* Forward/Approve */}
                    {canUnitEdit && (
                      <ToggleDisplay show={this.state.saveShow}>
                        <Button
                          text={"Forward"}
                          onClick={() => {
                            formik.setFieldValue("isReview", true);
                            this.setState({ ForwardedConfirmationModal: true });
                          }}
                        />
                      </ToggleDisplay>
                    )}
                    {formik.status && (
                      <div className={"alert alert-danger"}>
                        {formik.status}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

export default withTranslation()(DetailReporter);

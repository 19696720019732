import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import { Loader } from "../../../components/Utils/Loader/Loader";

import { DeleteModal } from "./DeleteModal";
import { QualityReviewService } from "../../../services/QualityReview/QualityReview/QualityReviewService";

import { authenticationService } from "../../../services/AuthService";
import { Role } from "../../../helpers/Constants";

import { withTranslation } from "react-i18next";

class QualityReview extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      records: [],
      deleteModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      loading: false,
      timezone: moment(new Date()).format("Z"),
      pages: pages,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  refreshList() {
    this.handleLoading(true);
    QualityReviewService.Read(
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState({
        maxPage: result?.data?.maxPage,
        records: result?.data?.records,
      });
      this.handleLoading(false);
    });
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handlePageClick(event) {
    if (this.state.pages !== undefined) {
      this.setState({ page: this.state.pages, pages: undefined }, () => {
        this.refreshList();
      });
    } else if (isNaN(event.selected)) {
      this.setState({ page: 1 }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }
  record(id) {
    this.props.history.push(`/quality-review/detail-review/${id}`, {
      formType: "edit",
      page: this.state.page,
    });
  }

  add = () => {
    this.props.history.push(`/quality-review/add-review`);
  };

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  deleteRecord(id) {
    const { t } = this.props;

    this.handleLoading(true);
    QualityReviewService.Delete(id).then(
      (result) => {
        toastSuccess(t("success_delete_message"));
        this.refreshList();
        this.setState({ deleteModalShow: false });
        this.handleLoading(false);
      },
      (error) => {
        toastError(error);
      }
    );
  }

  render() {
    let currentUser = authenticationService.currentUserValue;

    const { records } = this.state;

    const { t } = this.props;

    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            <div className="col-xs-6 col-sm-6 col-md-3">
              {currentUser.role === Role.RegulasiMutuSistem && (
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add") + ` Quality Review`}
                    icon="fa fa-plus"
                    onClick={() => this.add()}
                  />
                </div>
              )}
            </div>

            <div className="col-xs-6 col-sm-6 col-md-3"></div>

            <div className="col-xs-6 col-sm-6 col-md-3">
              <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={this.state.query}
                      onChange={(event) => this.handleSearchChange(event)}
                      placeholder={t("keyword")}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="submit"
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xs-6 col-sm-6 col-md-3">
              <DateRange
                handlechange={(startDate, endDate, type) =>
                  this.handleDateChange(startDate, endDate, type)
                }
              />
            </div>
          </div>

          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("created_date")}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          {index + 1 + (this.state.page - 1) * this.state.limit}
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(record.createdDate).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>

                              {currentUser.role === Role.RegulasiMutuSistem && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  {records.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                initialPage={this.state.pages - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(QualityReview);

import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import TableStatus from "../../../components/Utils/Status/TableStatus";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import { Role, Status } from "../../../helpers/Constants";

import { authenticationService } from "../../../services/AuthService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { InitialService } from "../../../services/Manufacture/InitialService";
import { ProgressService } from "../../../services/Manufacture/ProgressService";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";
import { DeleteModal } from "./DeleteModal";
import { DownloadModal } from "../../../components/Utils/Modal/DownloadModal";

import { withTranslation } from "react-i18next";

class Manufacture extends Component {
  constructor(props) {
    super(props);

    const { page } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      case_type: "Initial",
      filterShow: false,
      elementSpan: "",
      deleteModalShow: false,
      downloadModalShow: false,

      detailManufactureInitialModal: false,
      ManufactureInitialPage: 1,
      ManufactureInitialMaxPage: 0,
      ManufactureInitialLimit: 10,
      ManufactureInitialQuery: "",
      ManufactureInitialParams: {},
      ManufactureInitialRecords: [],

      detailManufactureProgressModal: false,
      ManufactureProgressPage: 1,
      ManufactureProgressMaxPage: 0,
      ManufactureProgressLimit: 10,
      ManufactureProgressQuery: "",
      ManufactureProgressParams: {},
      ManufactureProgressRecords: [],

      initialPage: page,
    };
  }

  handleAddButton = () => {
    switch (this.state.case_type) {
      case "Initial":
        this.props.history.push(`/report-submission/manufacture/add-initial`);
        break;
      case "Progress":
        this.props.history.push(`/report-submission/manufacture/add-progress`);
        break;
    }
  };

  followUp(id) {
    this.props.history.push(
      `/report-submission/manufacture/progress/follow-up/${id}`
    );
  }

  handleTabClick(type) {
    this.setState({
      case_type: type,
    });
    this.refreshList(type);
  }

  handlePageClick(event) {
    console.log(event);
    if (this.state.case_type == "Initial") {
      if (this.state.initialPage !== undefined) {
        this.setState(
          {
            ManufactureInitialPage: this.state.initialPage,
            initialPage: undefined,
          },
          () => {
            this.refreshList("Initial");
          }
        );
      } else if (isNaN(event.selected)) {
        this.setState({ ManufactureInitialPage: 1 }, () => {
          this.refreshList("Initial");
        });
      } else {
        this.setState({ ManufactureInitialPage: event.selected + 1 }, () => {
          this.refreshList("Initial");
        });
      }
    }

    if (this.state.case_type == "Progress") {
      if (this.state.initialPage !== undefined) {
        this.setState(
          {
            ManufactureProgressPage: this.state.initialPage,
            initialPage: undefined,
          },
          () => {
            this.refreshList("Progress");
          }
        );
      } else if (isNaN(event.selected)) {
        this.setState({ ManufactureProgressPage: 1 }, () => {
          this.refreshList("Progress");
        });
      } else {
        this.setState({ ManufactureProgressPage: event.selected + 1 }, () => {
          this.refreshList("Progress");
        });
      }
    }
  }

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  deleteRecord(id) {
    const { t } = this.props;
    if (this.state.case_type == "Initial") {
      InitialService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("Initial");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }

    if (this.state.case_type == "Progress") {
      ProgressService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("Progress");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }
  }

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  handleVaccineChange(item) {
    if (this.state.case_type === "Initial") {
      let data = this.state.ManufactureInitialParams;
      data = { ...data, product: item?.value ?? "" };

      this.setState({ ManufactureInitialParams: data }, () => {
        this.handlePageReset();
      });
    }
    if (this.state.case_type === "Progress") {
      let data = this.state.ManufactureProgressParams;
      data = { ...data, product: item?.value ?? "" };

      this.setState({ ManufactureProgressParams: data }, () => {
        this.handlePageReset();
      });
    }
  }

  handlePageReset() {
    if (this.state.case_type == "Initial") {
      this.setState({ ManufactureInitialPage: 1 }, () => {
        this.refreshList("Initial");
      });
    }
    if (this.state.case_type == "Progress") {
      this.setState({ ManufactureProgressPage: 1 }, () => {
        this.refreshList("Progress");
      });
    }
  }

  handleSearchSubmit(event) {
    if (this.state.case_type == "Initial") {
      this.handlePageReset();
    }
    if (this.state.case_type == "Progress") {
      this.handlePageReset();
    }
    event.preventDefault();
  }

  handleSearchChange(event) {
    if (this.state.case_type == "Initial") {
      this.setState({ ManufactureInitialQuery: event.target.value });
    }

    if (this.state.case_type == "Progress") {
      this.setState({ ManufactureProgressQuery: event.target.value });
    }
  }

  handleStatusChange(event) {
    if (this.state.case_type === "Initial") {
      let data = this.state.ManufactureInitialParams;
      data = { ...data, status: event?.value ?? "" };

      this.setState({ ManufactureInitialParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "Progress") {
      let data = this.state.ManufactureProgressParams;
      data = { ...data, status: event?.value ?? "" };

      this.setState({ ManufactureProgressParams: data }, () => {
        this.handlePageReset();
      });
    }
  }

  refreshList(type) {
    if (type == null || type == "Initial") {
      InitialService.Read(
        this.state.ManufactureInitialPage,
        this.state.ManufactureInitialLimit,
        this.state.ManufactureInitialQuery,
        this.state.ManufactureInitialParams
      ).then((result) => {
        this.setState({
          ManufactureInitialMaxPage: result?.data?.maxPage,
          ManufactureInitialRecords: result?.data?.records,
        });
      });
    }

    if (type == "Progress") {
      ProgressService.Read(
        this.state.ManufactureProgressPage,
        this.state.ManufactureProgressLimit,
        this.state.ManufactureProgressQuery,
        this.state.ManufactureProgressParams
      ).then((result) => {
        this.setState({
          ManufactureProgressMaxPage: result?.data?.maxPage,
          ManufactureProgressRecords: result?.data?.records,
        });
      });
    }
  }

  handleRecord(type, id) {
    if (type === "initial") {
      this.props.history.push(
        `/report-submission/manufacture/${type}/detail/${id}`,
        { page: this.state.ManufactureInitialPage }
      );
    } else {
      this.props.history.push(
        `/report-submission/manufacture/${type}/detail/${id}`,
        { page: this.state.ManufactureProgressPage }
      );
    }
  }

  componentDidMount() {
    this.refreshList();

    DropdownService.MasterList(["vaccine"]).then((result) => {
      this.setState({ list: result.data });
    });

    ProgressService.Read(
      this.state.ManufactureProgressPage,
      this.state.ManufactureProgressLimit,
      this.state.ManufactureProgressQuery,
      this.state.ManufactureProgressParams
    ).then((result) => {
      this.setState({
        ManufactureProgressMaxPage: result?.data?.maxPage,
        ManufactureProgressRecords: result?.data?.records,
      });
    });

    if (this.props.location?.state?.tab) {
      document.getElementById(this.props.location?.state?.tab).click();
    }
  }

  render() {
    const { t } = this.props;

    let currentUser = authenticationService.currentUserValue;

    const { ManufactureInitialRecords, ManufactureProgressRecords } =
      this.state;

    const vaccineList = this.state.list?.vaccineList;
    const statusOptions = [
      { value: "Draft", label: "Draft" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not+Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
      { value: "Closed", label: "Closed" },
    ];

    return (
      <>
        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        <DownloadModal
          show={this.state.downloadModalShow}
          onHide={() => this.setState({ downloadModalShow: false })}
        >
          <div>
            {this.state.case_type === "Initial" && (
              <>
                <Button
                  text="Download PDF (ID)"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    InitialService.DownloadId(
                      this.state.idDownload,
                      this.state.caseNumber
                    )
                  }
                />
                <Button
                  text="Download PDF (EN)"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    InitialService.DownloadEn(
                      this.state.idDownload,
                      this.state.caseNumber
                    )
                  }
                />
                <Button
                  text="Download Doc (ID)"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    InitialService.DownloadDocId(
                      this.state.idDownload,
                      this.state.caseNumber
                    )
                  }
                />
                <Button
                  text="Download Doc (EN)"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    InitialService.DownloadDocEn(
                      this.state.idDownload,
                      this.state.caseNumber
                    )
                  }
                />
              </>
            )}
          </div>
        </DownloadModal>

        {/* Content */}

        <ul className="nav nav-pills bg-nav-pills nav-tabs nav-bordered mb-3">
          <Tab
            number="1"
            showNumber={false}
            title={t("initial_report")}
            href="#tab-initial"
            type={1}
            onClick={() => this.handleTabClick("Initial")}
            id="Initial"
          />
          <Tab
            number="2"
            showNumber={false}
            title={t("progress_report")}
            href="#tab-progress"
            type={1}
            onClick={() => this.handleTabClick("Progress")}
            id="Progress"
          />
        </ul>
        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_report")}
                    icon="fa fa-plus"
                    onClick={() => this.handleAddButton()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                  <div className="row">
                    {this.state.case_type === "Initial" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label">{t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ManufactureInitialQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label">{t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return {
                                value: vaccine.id,
                                label: vaccine?.name,
                              };
                            })}
                            value={this.state.ManufactureInitialVaccine}
                            onChange={(item) => this.handleVaccineChange(item)}
                          ></Select>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.ManufactureInitialStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}

                    {this.state.case_type === "Progress" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label">{t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ManufactureProgressQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label">{t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return {
                                value: vaccine.id,
                                label: vaccine?.name,
                              };
                            })}
                            value={this.state.ManufactureProgressVaccine}
                            onChange={(item) => this.handleVaccineChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {/* Initial TAB */}
          <div className="tab-pane show active" id="tab-initial">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("created_date")}</th>
                    <th>{t("vaccine")}</th>
                    <th>Manufacturer</th>
                    <th>{t("letter_number")}t</th>
                    <th>{t("send_date")}</th>
                    <th>Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {ManufactureInitialRecords?.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          {index +
                            1 +
                            (this.state.ManufactureInitialPage - 1) *
                              this.state.ManufactureInitialLimit}
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(record.createdDate).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.manufactureSubmissionProduct
                            .map((item) => {
                              return item.productName;
                            })
                            .join(", ")}
                        </td>
                        <td>{record.manufactureNavigation?.name}</td>
                        <td>{record.mailingNumber}</td>
                        <td>
                          {record.mailingSentDate
                            ? moment(record.mailingSentDate).format(
                                "DD/MM/yyyy"
                              )
                            : record.mailingSentDate}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.handleRecord("initial", record.id)
                                }
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      this.setState({
                                        idDownload: record.id,
                                        caseNumber: record.caseNumber,
                                        downloadModalShow: true,
                                      })
                                    }
                                  >
                                    Download
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  {ManufactureInitialRecords?.length < 1 && (
                    <tr>
                      <td colSpan="9" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.ManufactureInitialMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.ManufactureInitialPage - 1}
                // forcePage={this.state.ManufactureInitialPage - 1}
                initialPage={
                  this.state.initialPage ? this.state.initialPage - 1 : 0
                }
              />
            </nav>
          </div>

          {/* Progress TAB */}
          <div className="tab-pane" id="tab-progress">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("vaccine")}</th>
                    <th>Manufacturer</th>
                    <th>{t("send_date")}</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {ManufactureProgressRecords?.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.ManufactureProgressPage - 1) *
                                  this.state.ManufactureProgressLimit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {record.manufactureSubmissionProduct
                            .map((item) => {
                              return item.productName;
                            })
                            .join(", ")}
                        </td>
                        <td>{record.manufactureNavigation?.name}</td>
                        <td>
                          {record.mailingDate
                            ? moment(record.mailingDate).format("DD/MM/yyyy")
                            : record.mailingDate}
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.handleRecord("progress", record.id)
                                }
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="6">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>{t("report_type")}</th>
                                  <th>{t("created_date")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailManufactureSubmission
                                  .sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail, index) => (
                                    <tr>
                                      <td>
                                        {trail.followUp === 0
                                          ? "Inisial"
                                          : "Follow Up " + trail.followUp}
                                      </td>
                                      <td>
                                        {trail.mailingDate
                                          ? moment(trail.mailingDate).format(
                                              "DD/MM/yyyy"
                                            )
                                          : trail.mailingDate}
                                      </td>
                                    </tr>
                                  ))}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {record.mailingDate
                                      ? moment(record.mailingDate).format(
                                          "DD/MM/yyyy"
                                        )
                                      : record.mailingDate}
                                  </td>
                                </tr>
                                {currentUser.role === Role.Staff && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text={t("add") + " Follow Up"}
                                          margin="0"
                                          onClick={() =>
                                            this.followUp(record.id)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {ManufactureProgressRecords?.length < 1 && (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.ManufactureProgressMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.ManufactureProgressPage - 1}
                // forcePage={this.state.ManufactureProgressPage - 1}
                initialPage={this.state.initialPage - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Manufacture);

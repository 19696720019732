import { handleResponse, authHeader } from '../../helpers/RequestHelper';

export const PartnerService = {
    PrepareForm,
    Create,
    Read,
    Update,
    Delete,
    FollowUp,
    Download,
    DownloadEn,
    DownloadDoc,
    Preview
};

function PrepareForm(id, followUp = "") {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        }
    };

    return fetch(`/api/v1/report-submission/partner/prepare-form/` + id + "?followUp=" + followUp, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function Create(value) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/partner/submit-form/create`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function FollowUp(value) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/partner/submit-form/create-followup`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function Read(page = 1, limit = 10, query, params = {}) {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    let url = `/api/v1/report-submission/partner/read?page=${page}&limit=${limit}&query=${query}`

    Object.entries(params).forEach(([key, value]) => {
        url += `&${key}=${value}`
    });

    let data = await fetch(url, requestOptions);

    data = await handleResponse(data)

    return data;
}

function Update(value) {

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(value)
    };

    return fetch(`/api/v1/report-submission/partner/submit-form/update`, requestOptions)
    .then(handleResponse)
    .then(response => {
        return response;
    });
}

function Delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json',
            ...authHeader()
        },
    };

    return fetch(`/api/v1/report-submission/partner/delete/` + id, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

async function ReviewForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/report-submission/partner/review/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function ApproveForm(object) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    };

    let data = await fetch(`api/v1/report-submission/partner/approve/`, requestOptions);
    data = await handleResponse(data)
    return data;
}

async function Download(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/partner-pdf-id/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Surat Balasan Partner dengan nomor kasus ${casenumber}`);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadEn(id, casenumber) {
    fetch('/api/v1/report-submission/pdf/partner-pdf-en/' + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
        .then(response => { return response.blob() })
        .then(result => {
            const url = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Response Letter to Partner for case number ${casenumber}`);
            document.body.appendChild(link);
            link.click();
        })
}

async function DownloadDoc(id, casenumber, overseas) {
    if (overseas === "ID") {
        var url = '/api/v1/report-submission/doc/partner-doc-id/';
        var name = `Surat Balasan Partner dengan nomor kasus ${casenumber}.docx`;
    } else {
        var url = '/api/v1/report-submission/doc/partner-doc-en/';
        var name = `Response Letter to Partner for case number ${casenumber}.docx`;
    }
    fetch(url + id, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
    })
    .then(response => { return response.blob() })
    .then(result => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    })
}


function Preview(object, language) {
    if (language === "ID") {
        var url = '/api/v1/report-submission/pdf-preview/preview-partner-pdf-id';
        
    } else {
        var url = '/api/v1/report-submission/pdf-preview/preview-partner-pdf-en/';
        
    }

    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(object)
    })
        .then(response => {
            var filename = "";
            var disposition = response.headers.get("content-disposition");
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }
            }

            return response.blob().then(result => {
                const url = window.URL.createObjectURL(result);
                return url;
            })
        })
}



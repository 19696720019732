import React, { Component } from 'react';

import Button from '../../../../components/Utils/Button/Button';
import Textarea from '../../../../components/Utils/Field/Textarea';

import { LetterModal } from "../LetterModal";

import { withTranslation } from "react-i18next";

class AnalysisResult extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            selectedData: [],
            isComplete: "",
            letterShow: false,
        };
    }

    componentDidUpdate(prevProps) {



    }

    componentDidMount() {

    }


    handleCancelClick(event) {

        this.props.history.push('/report-submission/partner');
        event.preventDefault();
    }

    render() {
        const { formik, typecase, historydata, t, ...otherProps } = this.props;

        let LetterModalClose = () => {
            this.setState({
                letterShow: false
            })
        };

        return (
            <>
                <LetterModal
                    show={this.state.letterShow}
                    onHide={LetterModalClose}
                    formik={formik}
                    type="initial"
                    language={this.state.language}
                />
                <div className="tab-pane tab-pane-fixed" id="analysis-result">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">Review BPR</label>
                                <Textarea
                                    rows="6"
                                    name="text"
                                    placeholder="Review BPR"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`analysisBpr`)}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('distribution_note')}</label>
                                <Textarea
                                    rows="6"
                                    name="text"
                                    placeholder={t('distribution_note')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`analysisDistribution`)}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('report_with_the_same_batch_number')}</label>
                                <Textarea
                                    rows="6"
                                    name="text"
                                    placeholder={t('report_with_the_same_batch_number')}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`analysisSameBatch`)}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('aefi_analysis')}</label>
                                <Textarea
                                    rows="6"
                                    name="text"
                                    placeholder="Tulis Analisis"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps(`analysisAefi`)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">{t('response_letter_partner')}</label>
                                <br />
                                <Button
                                    text={t('preview_indo_letter')}
                                    onClick={() => this.setState({ letterShow: true, language : "ID" })}
                                />
                                <Button
                                    text={t('preview_eng_letter')}
                                    onClick={() => this.setState({ letterShow: true, language : "EN" })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default withTranslation()(AnalysisResult);
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../../components/Utils/DateRange/DateRangePicker";
import TableStatus from "../../../components/Utils/Status/TableStatus";
import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { DeleteModal } from "./DeleteModal";
import { DownloadModal } from "../../../components/Utils/Modal/DownloadModal";

import { DropdownService } from "../../../services/Master/AllDropdownService";
import { authenticationService } from "../../../services/AuthService";
import { Role, Status } from "../../../helpers/Constants";

import { PartnerService } from "../../../services/Partner/PartnerService";

import { withTranslation } from "react-i18next";

class Partner extends Component {
  constructor(props) {
    super(props);

    const { pages } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    this.state = {
      filterShow: false,
      records: [],
      addModalShow: false,
      deleteModalShow: false,
      downloadModalShow: false,
      page: 1,
      maxPage: 0,
      limit: 10,
      query: "",
      params: {},
      elementSpan: "",
      initPage: pages,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.refreshList();

    DropdownService.MasterList(["vaccine", "drugs"]).then((result) => {
      this.setState({ list: result.data });
    });
  }

  handlePageReset() {
    this.setState({ page: 1 }, () => {
      this.refreshList();
    });
  }

  refreshList() {
    PartnerService.Read(
      this.state.page,
      this.state.limit,
      this.state.query,
      this.state.params
    ).then((result) => {
      this.setState({
        maxPage: result?.data?.maxPage,
        records: result?.data?.records,
        editModalShow: new Array(result?.data?.length).fill(false),
      });
    });
  }

  handleSearchChange(event) {
    this.setState({ query: event.target.value });
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handleVaccineChange(item) {
    let data = this.state.params;
    data = { ...data, vaccine: item?.value ?? "" };

    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDrugsChange(item) {
    let data = this.state.params;
    data = { ...data, drugs: item?.value ?? "" };

    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handlePageClick(event) {
    if (this.state.initPage !== undefined) {
      this.setState({ page: this.state.initPage, initPage: undefined }, () => {
        this.refreshList();
      });
    } else {
      this.setState({ page: event.selected + 1 }, () => {
        this.refreshList();
      });
    }
  }

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  handleStatusChange(item) {
    let data = this.state.params;
    data = { ...data, status: item?.value ?? "" };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  handleDateChange(start, end) {
    let startDate =
      start !== "" && start !== null
        ? start.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";
    let endDate =
      end !== "" && end !== null
        ? end.replaceAll("/", "-") + "Z" + this.state.timezone
        : "";

    let data = this.state.params;
    data = { ...data, startDate, endDate };
    this.setState({ params: data }, () => {
      this.handlePageReset();
    });
  }

  record(id) {
    this.props.history.push(`/report-submission/partner/detail/${id}`, {
      formType: "edit",
      page: this.state.page,
    });
  }

  add = () => {
    this.props.history.push(`/report-submission/partner/add-partner`);
  };

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  deleteRecord(id) {
    const { t } = this.props;
    PartnerService.Delete(id).then(
      (result) => {
        toastSuccess(t("success_delete_message"));
        this.refreshList();
        this.setState({ deleteModalShow: false });
      },
      (error) => {
        toastError(error);
      }
    );
  }

  render() {
    let currentUser = authenticationService.currentUserValue;

    const { records } = this.state;

    const { t } = this.props;

    const statusOptions = [
      { value: "Draft", label: "Draft" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not+Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
      { value: "Closed", label: "Closed" },
    ];

    const drugsList = this.state.list?.drugsList;
    const vaccineList = this.state.list?.vaccineList;

    const styles = {
      fontFamily: "sans-serif",
      textAlign: "center",
    };

    return (
      <>
        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        <DownloadModal
          show={this.state.downloadModalShow}
          onHide={() => this.setState({ downloadModalShow: false })}
        >
          <div>
            <Button
              text="Download PDF (ID)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                PartnerService.Download(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "ID"
                )
              }
            />
            <Button
              text="Download PDF (EN)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                PartnerService.DownloadEn(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "EN"
                )
              }
            />
            <Button
              text="Download Doc (ID)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                PartnerService.DownloadDoc(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "ID"
                )
              }
            />
            <Button
              text="Download Doc (EN)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                PartnerService.DownloadDoc(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "EN"
                )
              }
            />
          </div>
        </DownloadModal>

        {/* Content */}

        <div className="tab-content">
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={t("add_response_letter")}
                    icon="fa fa-plus"
                    onClick={() => this.add()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            <div className="col-xs-6 col-sm-6 col-md-7"></div>
            <div className="col-xs-6 col-sm-6 col-md-2">
              <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <div className="row">
                  <div className="col-xs-12 col-sm-6 col-md-4">
                    <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                      <div className="form-group">
                        <label className="form-label"> {t("search")}</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.query}
                            onChange={(event) => this.handleSearchChange(event)}
                            placeholder={t("keyword")}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="submit"
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6 col-md-2"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="form-label"> {t("vaccine")}</label>
                    <Select
                      placeholder={t("vaccine")}
                      width="100%"
                      name="vaccineFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={vaccineList?.map((vaccine) => {
                        return { value: vaccine.id, label: vaccine.name };
                      })}
                      value={this.state.vaccine}
                      onChange={(item) => this.handleVaccineChange(item)}
                    ></Select>
                  </div>
                  <div
                    className="col-xs-12 col-sm-6 col-md-2"
                    style={{ marginBottom: 10 }}
                  >
                    <label className="form-label"> {t("drug")}</label>
                    <Select
                      placeholder={t("drug")}
                      width="100%"
                      name="drugsFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={drugsList?.map((drugs) => {
                        return { value: drugs.id, label: drugs.name };
                      })}
                      value={this.state.drugs}
                      onChange={(item) => this.handleDrugsChange(item)}
                    ></Select>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-3">
                    <label className="form-label">Status</label>
                    <Select
                      placeholder="Status"
                      width="100%"
                      name="statusFilter"
                      isClearable="true"
                      classNamePrefix="select"
                      className="basic-single"
                      options={statusOptions}
                      value={this.state.status}
                      onChange={(item) => this.handleStatusChange(item)}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="tab-pane show active">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th> {t("case_number")}</th>
                    <th> {t("created_date")}</th>
                    <th> {t("product_name")}</th>
                    <th>Partner</th>
                    <th> {t("letter_number")}</th>
                    <th> {t("send_date")}</th>
                    <th width="15%">Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          {index + 1 + (this.state.page - 1) * this.state.limit}
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(
                            record.modifiedDate ?? record.createDate
                          ).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.partnerSubmissionProduct
                            ?.map((item) => {
                              return (
                                item.vaccineNavigation?.name ??
                                item.DrugsNavigation?.name
                              );
                            })
                            .join(", ")}
                        </td>
                        <td>{record.partnerNavigation.name}</td>
                        <td>{record.mailingNumber}</td>
                        <td>
                          {record.mailingSentDate
                            ? moment(record.mailingSentDate).format(
                                "DD/MM/yyyy"
                              )
                            : "-"}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      this.setState({
                                        idDownload: record.id,
                                        caseNumber: record.caseNumber,
                                        downloadModalShow: true,
                                      })
                                    }
                                  >
                                    Download
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  {records.length < 1 && (
                    <tr>
                      <td colSpan="8" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.maxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.page - 1}
                // forcePage={this.state.page - 1}
                initialPage={this.state.initPage - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Partner);

import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Tab from "../../components/Utils/Tab/Tab";
import Button from "../../components/Utils/Button/Button";
import ButtonWithIcon from "../../components/Utils/Button/ButtonWithIcon";
import DateRange from "../../components/Utils/DateRange/DateRangePicker";
import TableStatus from "../../components/Utils/Status/TableStatus";
import { Loader } from "../../components/Utils/Loader/Loader";
import { DownloadModal } from "../../components/Utils/Modal/DownloadModal";

import BookInModal from "./BookInModal";
import ImportDataModal from "./ImportDataModal";
import DeleteModal from "./DeleteModal";
import { ConfirmationModal } from "./ConfirmationModal";
import ConfirmationImportDataModal from "./ConfirmationImportDataModal";

import { AEFIService } from "../../services/CaseEntry/AEFIService";
import { AEService } from "../../services/CaseEntry/AEService";
import { DropdownService } from "../../services/Master/AllDropdownService";
import { authenticationService } from "../../services/AuthService";
import { Role } from "../../helpers/Constants";
import { toastSuccess, toastError } from "../../components/Utils/Toast/Toast";

import { Status } from "../../helpers/Constants";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isMoreThanXDaysAgo, isMoreThanRangeXDaysAgo } from "../../helpers/Utils";

class CaseEntry extends Component {
  constructor(props) {
    super(props);

    const { page } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;
    const filterValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("filter")
      : null;
    const periodValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("period")
      : null;
    let status = "";
    let startdate = "";
    let enddate = "";
    if (filterValue != null){      
      const { status: newStatus, startdate: newStartDate, enddate: newEndDate } = this.periodFilter(periodValue, filterValue);
      status = newStatus;
      startdate = newStartDate;
      enddate = newEndDate;
    }
    this.state = {
      filter : filterValue,
      period: periodValue,
      isExpand: false,
      isElement: false,
      elementSpan: "",
      AEFIRecords: [],
      AEFIModalShow: false,
      AEFIPage: 1,
      AEFIMaxPage: 0,
      AEFILimit: 10,
      AEFIQuery: "",
      AEFIStatus: status,
      AEFIVaccine: "",
      AEFIStartDate: startdate,
      AEFIEndDate: enddate,
      AERecords: [],
      AEModalShow: false,
      AEPage: 1,
      AEMaxPage: 0,
      AELimit: 10,
      AEQuery: "",
      AEStatus: status,
      AEDrugs: "",
      AEStartDate: startdate,
      AEEndDate: enddate,
      lastVisited: 0,
      bookInModalShow: false,
      importDataModalShow: false,
      importConfirmationModalShow: false,
      deleteModalShow: false,
      confirmationModalShow: false,
      caseNumber: "",
      case_type: "KIPI",
      record_id: "",
      filterShow: false,
      timezone: moment(new Date()).format("Z"),
      loading: false,
      caseNumberGeneratedModal: { show: false, caseNumber: null },
      downloadModalShow: false,
      initPage: page,
    };

    this.refreshList = this.refreshList.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  record(id) {
    if (this.state.case_type === "KIPI") {
      this.props.history.push({
        pathname: `/case-entry/aefi/detail-aefi/${id}`,
        state: {
          page: this.state.AEFIPage,
        },
      });
    }
    if (this.state.case_type === "KTD") {
      this.props.history.push({
        pathname: `/case-entry/ae/detail-ae/${id}`,
        state: {
          page: this.state.AEPage,
        },
      });
    }
  }

  followUp(id) {
    if (this.state.case_type === "KIPI") {
      this.props.history.push({
        pathname: `/case-entry/follow-up-aefi/${id}`,
        state: { formType: "follow-up" },
      });
    }
    if (this.state.case_type === "KTD") {
      this.props.history.push({
        pathname: `/case-entry/follow-up-ae/${id}`,
        state: { formType: "follow-up" },
      });
    }
  }

  handleTabClick(type) {
    this.setState({
      case_type: type,
    });
  }

  handleBookInModal = () => {
    this.setState({ bookInModalShow: true });
  };

  handleImportDataModal = () => {
    this.setState({ importDataModalShow: true });
  };

  handleLoading = (status) => {
    this.setState({ loading: status });
  };

  handlePageReset() {
    if (this.state.case_type === "KIPI") {
      this.setState({ AEFIPage: 1 }, () => {
        this.refreshList(this.state.case_type);
      });
    } else {
      this.setState({ AEPage: 1 }, () => {
        this.refreshList(this.state.case_type);
      });
    }
  }

  handleClearFilters = () => {
    this.setState({filter : null})
    this.setState({AEFIStatus : ""})
    this.setState({AEFIStartDate : ""})
    this.setState({AEFIEndDate : ""})
    this.setState({AEStatus : ""})
    this.setState({AEStartDate : ""})
    this.setState({AEEndDate : ""})
    if (this.state.case_type == "KIPI"){
      setTimeout(() => {
        this.setState({case_type : "KTD"})
        this.handlePageReset()
        this.setState({case_type : "KIPI"})
      }, 500)
    }else{
      setTimeout(() => {
        this.setState({case_type : "KIPI"})
        this.handlePageReset()
        this.setState({case_type : "KTD"})
      }, 500)
    }
    this.handlePageReset()
    this.props.history.push(`/case-entry`)
  }

  periodFilter = (period,filterValue) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    let daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysUntilStartOfWeek = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const firstDateOfWeek = new Date(currentDate);
    firstDateOfWeek.setDate(currentDate.getDate() - daysUntilStartOfWeek);
    const lastDateOfWeek = new Date(firstDateOfWeek);
    lastDateOfWeek.setDate(firstDateOfWeek.getDate() + 6);
    const firstWeek = firstDateOfWeek.getDate()
    const endWeek = lastDateOfWeek.getDate()
    let status, startdate, enddate = "";
    if (period == "week") {
        status = { value: filterValue == "Open" ? "" : filterValue, label: filterValue == "Open" ? "" : filterValue }
        startdate = `${currentYear}-${currentMonth}-${firstWeek}Z+07:00`;
        enddate = `${currentYear}-${firstWeek > endWeek ? currentMonth+1 : currentMonth}-${endWeek}Z+07:00`
    }
    else if (period == "month") {
        status = { value: filterValue == "Open" ? "" : filterValue, label: filterValue == "Open" ? "" : filterValue }
        startdate = `${currentYear}-${currentMonth}-01Z+07:00`;
        enddate = `${currentYear}-${currentMonth}-${daysInMonth}Z+07:00`
    } else if (period == "year") {
        status = { value: filterValue == "Open" ? "" : filterValue, label: filterValue == "Open" ? "" : filterValue }
        startdate = `${currentYear}-01-01Z+07:00`;
        enddate = `${currentYear}-12-31Z+07:00`
    } else if (!period) {
      status = { value: filterValue == "Open" ? "" : filterValue, label: filterValue == "Open" ? "" : filterValue }
    }
    return { status, startdate, enddate }
  }

  componentDidMount() {
    this.handleLoading(true);
    Promise.all([
      this.refreshListPromise("KIPI"),
      this.refreshListPromise("KTD"),
      this.refreshListPromise("dropdown"),
    ]).then(([kipi, ktd, dropdown]) => {
      this.setState({
        AEFIMaxPage: kipi.data.maxPage,
        AEFIRecords: kipi.data.records,
        AEMaxPage: ktd.data.maxPage,
        AERecords: ktd.data.records,
        list: dropdown.data,
      });
      this.handleLoading(false);
    });

    if (this.props.location?.state?.tab) {
      document.getElementById(this.props.location?.state?.tab).click();
    }
  }

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

  deleteRecord(id) {
    const { t } = this.props;
    this.handleLoading(true);
    if (this.state.case_type === "KIPI") {
      AEFIService.Delete(id).then(
        (result) => {
          this.refreshList("KIPI");
          toastSuccess(t("success_delete_message"));
          this.handleLoading(false);
        },
        (error) => {
          toastError(t("failed_delete_message"));
          this.handleLoading(false);
        }
      );
    }
    if (this.state.case_type === "KTD") {
      AEService.Delete(id).then(
        (result) => {
          this.refreshList("KTD");
          toastSuccess(t("success_delete_message"));
          this.handleLoading(false);
        },
        (error) => {
          toastError(t("failed_delete_message"));
          this.handleLoading(false);
        }
      );
    }
    this.setState({
      deleteModalShow: false,
    });
  }

  refreshListPromise(type) {
    if (type === "KIPI") {
      return AEFIService.Read(        
        this.state.filter,
        this.state.AEFIPage,
        this.state.AEFILimit,
        this.state.AEFIQuery,
        this.state.AEFIVaccine?.value,
        this.state.AEFIStatus?.value,
        this.state.AEFIStartDate,
        this.state.AEFIEndDate,        
      );
    }
    if (type === "KTD") {
        return AEService.Read(
        this.state.filter,
        this.state.AEPage,
        this.state.AELimit,
        this.state.AEQuery,
        this.state.AEDrugs?.value,
        this.state.AEStatus?.value,
        this.state.AEStartDate,
        this.state.AEEndDate
      );
    }
    if (type === "dropdown") {
      return DropdownService.MasterList(["vaccine", "drugs"]);
    }
  }

  refreshList(type) {
    this.handleLoading(true);
    if (type === "KIPI") {
      AEFIService.Read(
        this.state.filter,
        this.state.AEFIPage,
        this.state.AEFILimit,
        this.state.AEFIQuery,
        this.state.AEFIVaccine?.value,
        this.state.AEFIStatus?.value,
        this.state.AEFIStartDate,
        this.state.AEFIEndDate
      ).then((result) => {
        this.setState({
          AEFIMaxPage: result.data.maxPage,
          AEFIRecords: result.data.records,
        });
        this.handleLoading(false);
      });
    }
    if (type === "KTD") {
        AEService.Read(
        this.state.filter,
        this.state.AEPage,          
        this.state.AELimit,
        this.state.AEQuery,
        this.state.AEDrugs?.value,
        this.state.AEStatus?.value,
        this.state.AEStartDate,
        this.state.AEEndDate
      ).then((result) => {
        this.setState({
          AEMaxPage: result.data.maxPage,
          AERecords: result.data.records,
        });
        this.handleLoading(false);
      });
    }
    if (type === "dropdown") {
      DropdownService.MasterList(["vaccine", "drugs"]).then((result) => {
        this.setState({ list: result.data });
      });
    }
  }

  handleProductChange(item) {
    if (this.state.case_type === "KIPI") {
      this.setState({ AEFIVaccine: item }, () => {
        this.handlePageReset();
      });
    }
    if (this.state.case_type === "KTD") {
      this.setState({ AEDrugs: item }, () => {
        this.handlePageReset();
      });
    }
  }

  handleStatusChange(item) {
    if (this.state.case_type === "KIPI") {
      this.setState({ AEFIStatus: item }, () => {
        this.handlePageReset();
      });
    }
    if (this.state.case_type === "KTD") {
      this.setState({ AEStatus: item }, () => {
        this.handlePageReset();
      });
    }
  }

  handleSearchChange(event) {
    if (this.state.case_type === "KIPI") {
      this.setState({ AEFIQuery: event.target.value });
    }
    if (this.state.case_type === "KTD") {
      this.setState({ AEQuery: event.target.value });
    }
  }

  handleDateChange(start, end, type) {
    let startDate =
      start !== null && start !== ""
        ? (start + "Z" + this.state.timezone).replaceAll("/", "-")
        : "";
    let endDate =
      end !== null && start !== ""
        ? (end + "Z" + this.state.timezone).replaceAll("/", "-")
        : "";
    if (type === "KIPI") {
      this.setState(
        {
          AEFIStartDate: startDate ? startDate : "",
          AEFIEndDate: endDate ? endDate : "",
        },
        () => this.handlePageReset()
      );
    }

    if (type === "KTD") {
      this.setState(
        {
          AEStartDate: startDate ? startDate : "",
          AEEndDate: endDate ? endDate : "",
        },
        () => this.handlePageReset()
      );
    }
  }

  handleSearchSubmit(event) {
    this.handlePageReset();
    event.preventDefault();
  }

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  handlePageClick(event) {
    if (this.state.case_type === "KIPI") {
      if (this.state.initPage !== undefined) {
        this.setState(
          { AEFIPage: this.state.initPage, initPage: undefined },
          () => {
            this.refreshList("KIPI");
          }
        );
      } else if (isNaN(event.selected)) {
        this.setState({ AEFIPage: 1 }, () => {
          this.refreshList("KIPI");
        });
      } else {
        this.setState({ AEFIPage: event.selected + 1 }, () => {
          this.refreshList("KIPI");
        });
      }
    } else {
      if (this.state.case_type === "KTD") {
        if (this.state.initPage !== undefined) {
          this.setState(
            { AEPage: this.state.initPage, initPage: undefined },
            () => {
              this.refreshList("KTD");
            }
          );
        } else if (isNaN(event.selected)) {
          this.setState({ AEPage: 1 }, () => {
            this.refreshList("KTD");
          });
        } else {
          this.setState({ AEPage: event.selected + 1 }, () => {
            this.refreshList("KTD");
          });
        }
      }
    }
  }

  handleCloseCaseNumberGeneratedModal() {
    // this.props.location.state.caseNumberGeneratedModal = {show: false, caseNumber: null};
    let state = { ...this.props.history.location.state };
    delete state.caseNumberGeneratedModal;
    this.props.history.replace({ ...this.props.history.location, state });
  }

  handleDownload = (caseType, lang, type) => {};

  handleCheckStatus(record) {
    const { t, i18n } = this.props;

    const createResult = (outcome, desc) => ({ outcome, desc });

    const checkDateRange = (startDate, endDate, days, desc) => {
        if (isMoreThanRangeXDaysAgo(startDate, endDate, days)) {
            return createResult(true, desc);
        }
        return createResult(false, "");
    };

    const checkSingleDate = (date, days, desc) => {
        if (isMoreThanXDaysAgo(date, days)) {
            return createResult(true, desc);
        }
        return createResult(false, "");
    };

    const handleSerious = () => {
        if (record?.additionalRequest) { // tidak lengkap
          if (record.approvedDate) {
            return checkDateRange(record.reportedDate, record.approvedDate, 37, t("expired_37_reported_date"))
          } else {
            const check37Days = checkSingleDate(record.reportedDate, 37, t("expired_37_reported_date"));
            const check30Days = checkSingleDate(record.reportedDate, 30, t("expired_30_reported_date"));

            return check37Days?.outcome ? check37Days : check30Days;
          }
        } else { // lengkap
          return record.approvedDate 
              ? checkDateRange(record.reportedDate, record.approvedDate, 15, t("expired_15_reported_date"))
              : checkSingleDate(record.reportedDate, 15, t("expired_15_reported_date"));
        }
    };

    const handleNonSerious = () => {
        if (!record.reviewDate) {
            return checkSingleDate(record.reportedDate, 15, t("expired_15_reported_date"));
        } else if (record.reviewDate && !record.approvedDate) {
          const isExpiredReportedDate = checkDateRange(record.reportedDate, record.reviewDate, 15, t("expired_15_reported_date"));
          const isExpiredReviewDate = checkSingleDate(record.reviewDate, 15, t("expired_15_reported_date"))

          return (isExpiredReportedDate?.outcome && isExpiredReportedDate) || 
                (isExpiredReviewDate?.outcome && isExpiredReviewDate);
        } else {
          const isExpiredReportedDate = checkDateRange(record.reportedDate, record.reviewDate, 15, t("expired_15_reported_date"));
          const isExpiredReviewDate = checkDateRange(record.reviewDate, record.approvedDate, 15, t("expired_15_review_date"));

          return (isExpiredReportedDate?.outcome && isExpiredReportedDate) || 
                (isExpiredReviewDate?.outcome && isExpiredReviewDate);
        }
    };

    return record?.seriousnessNavigation?.alias === "Serious" 
        ? handleSerious() 
        : handleNonSerious();
  }

  render() {
    const { AEFIRecords, AERecords } = this.state;
    const drugsList = this.state.list?.drugsList;
    const vaccineList = this.state.list?.vaccineList;

    const { t, i18n } = this.props;
      const statusOptions = [
         
          { value: "Draft", label: "Draft" },
          { value: "Waiting Approval", label: "Waiting Approval"
},
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
      { value: "Bridging", label: "Bridging" },
    ];

    let currentUser = authenticationService.currentUserValue;
    return (
      <>
        {this.state.loading && <Loader color="#00ABBD" />}

        {/* Modal List */}

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false }, () =>
            this.refreshList("KIPI")
          )}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        <BookInModal
          show={this.state.bookInModalShow}
          onHide={() => this.setState({ bookInModalShow: false })}
          type={this.state.case_type}
          t={t}
        />

        <ImportDataModal
          show={this.state.importDataModalShow}
          onHide={() => {
            this.setState({ importDataModalShow: false }, () =>
              this.refreshList("KIPI")
            );
          }}
          type={this.state.case_type}
          t={t}
          refresh={() => this.refreshList("KIPI")}
        />

        <ConfirmationImportDataModal
          show={this.state.importConfirmationModalShow}
          onHide={() => this.setState({ importConfirmationModalShow: false })}
          importNew={() => this.importNew()}
          continueProgress={() => this.continueProgress()}
          t={t}
        />

        <DownloadModal
          show={this.state.downloadModalShow}
          onHide={() => this.setState({ downloadModalShow: false })}
        >
          <div>
            <Button
              text="Download PDF (ID)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                this.state.case_type === "KIPI"
                  ? AEFIService.Download(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "ID"
                    )
                  : AEService.Download(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "ID"
                    )
              }
            />
            <Button
              text="Download PDF (EN)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                this.state.case_type === "KIPI"
                  ? AEFIService.Download(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "EN"
                    )
                  : AEService.Download(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "EN"
                    )
              }
            />
            <Button
              text="Download Doc (ID)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                this.state.case_type === "KIPI"
                  ? AEFIService.DownloadDoc(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "ID"
                    )
                  : AEService.DownloadDoc(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "ID"
                    )
              }
            />
            <Button
              text="Download Doc (EN)"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                this.state.case_type === "KIPI"
                  ? AEFIService.DownloadDoc(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "EN"
                    )
                  : AEService.DownloadDoc(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "EN"
                    )
              }
            />
            <Button
              text="Download PDF CIOMS"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                AEFIService.DownloadCIOMS(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "PDF"
                )
              }
            />
            <Button
              text="Download DOC CIOMS"
              width="100%"
              align="left"
              margin="10px 0px"
              background="#FFFFFF"
              shadow="0 4px 8px #60617029;"
              color="#495057"
              onClick={() =>
                AEFIService.DownloadCIOMS(
                  this.state.idDownload,
                  this.state.caseNumber,
                  "DOC"
                )
              }
            />
            {this.state.case_type === "KIPI" && (
              <>
                <Button
                  text="Download PDF AEFI Reporting WHO"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    AEFIService.DownloadWHO(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "PDF"
                    )
                  }
                />
                <Button
                  text="Download DOC AEFI Reporting WHO"
                  width="100%"
                  align="left"
                  margin="10px 0px"
                  background="#FFFFFF"
                  shadow="0 4px 8px #60617029;"
                  color="#495057"
                  onClick={() =>
                    AEFIService.DownloadWHO(
                      this.state.idDownload,
                      this.state.caseNumber,
                      "DOC"
                    )
                  }
                />
              </>
            )}
          </div>
        </DownloadModal>

        {/** Case number modal */}
        <ConfirmationModal
          show={this.props.location?.state?.caseNumberGeneratedModal?.show}
          onhide={() => this.handleCloseCaseNumberGeneratedModal()}
          confirm={() => this.handleCloseCaseNumberGeneratedModal()}
          title="Case Entry"
          caseNumber={
            this.props.location?.state?.caseNumberGeneratedModal?.caseNumber
          }
        />

        {/* Content */}

        <ul className="nav nav-pills bg-nav-pills nav-tabs nav-bordered mb-3">
          <Tab
            number="1"
            showNumber={false}
            title={t("aefi")}
            href="#tab-aefi"
            type={1}
            onClick={() => this.handleTabClick("KIPI")}
            id="KIPI"
          />
          <Tab
            number="2"
            showNumber={false}
            title={t("ae")}
            href="#tab-ae"
            type={1}
            onClick={() => this.handleTabClick("KTD")}
            id="KTD"
          />
        </ul>
        <div className="tab-content">
          {this.state.filter != null &&
            <div className="col-xs-6 col-sm-6 col-md-12">
              <div className="aler alert-warning" style={{ width: "100%", marginBottom: 15, border: "0.5px solid", borderRadius: "6px", padding: "8px 15px" }}>
                Ini adalah hasil filter dari Dashboard
                <button
                  type="button"
                  className="close"
                  onClick={this.handleClearFilters}
                >
                &times;
                </button>
              </div>
            </div>
          }
          <div className="row justify-content-start" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3">
                <div style={{ marginBottom: 15 }}>
                  <ButtonWithIcon
                    text={
                      this.state.case_type === "KIPI"
                        ? t("add_aefi")
                        : t("add_ae")
                    }
                    icon="fa fa-plus"
                    onClick={() => this.handleBookInModal()}
                  />
                </div>
              </div>
            )}
            {currentUser.role !== Role.Staff && (
              <div className="col-xs-6 col-sm-6 col-md-3"></div>
            )}
            {currentUser.role === Role.Staff &&
              (this.state.case_type === "KIPI" ? (
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div style={{ marginBottom: 15 }}>
                    <ButtonWithIcon
                      text={t("import_data")}
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      color="#495057"
                      border="1px solid #DFDFDF"
                      icon="fa fa-plus"
                      onClick={() => this.handleImportDataModal()}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              ))}
            {this.state.filter == null &&
            <div
              className="col-xs-6 col-sm-6 col-md-2"
              style={{ marginLeft: "auto" }}>
              <div style={{ width: "100%", marginBottom: 15 }}>
                <ButtonWithIcon
                  text="Filter"
                  icon="fas fa-bars"
                  onClick={() =>
                    this.setState({ filterShow: !this.state.filterShow })
                  }
                />
              </div>
            </div>
            }
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                  <div className="row">
                    {this.state.case_type === "KIPI" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label">{t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.AEFIQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <label className="form-label">
                            {t("reported_date")}
                          </label>
                          <DateRange
                            handlechange={(startDate, endDate, type) =>
                              this.handleDateChange(startDate, endDate, type)
                            }
                            type="KIPI"
                            filterUntil = {true}
                          />
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label">{t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return { value: vaccine.id, label: vaccine.name };
                            })}
                            value={this.state.AEFIVaccine}
                            onChange={(item) => this.handleProductChange(item)}
                          ></Select>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.AEFIStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}

                    {this.state.case_type === "KTD" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label">{t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.AEQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <label className="form-label">
                            {t("reported_date")}
                          </label>
                          <DateRange
                            handlechange={(startDate, endDate, type) =>
                              this.handleDateChange(startDate, endDate, type)
                            }
                            type="KTD"
                            filterUntil = {true}
                          />
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label">{t("drug")}</label>
                          <Select
                            placeholder={t("drug")}
                            width="244px"
                            name="drugsFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={drugsList?.map((drugs) => {
                              return { value: drugs.id, label: drugs.name };
                            })}
                            value={this.state.AEDrugs}
                            onChange={(item) => this.handleProductChange(item)}
                          ></Select>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.AEStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {/* AEFI TAB */}
          <div className="tab-pane show active" id="tab-aefi">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>{t("case_number")}</th>
                    <th>{t("reported_date")}</th>
                    <th>{t("vaccine")}</th>
                    <th>{t("completeness_of_data")}</th>
                    <th width="15%">Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {AEFIRecords.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.AEFIPage - 1) *
                                  this.state.AEFILimit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(
                            record.reportedDate ?? record.reportedDate
                          ).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.aefiVaccine
                            ?.filter(
                              (item, index) =>
                                item.vaccine != null &&
                                record.aefiVaccine.findIndex(
                                  (x) => x.vaccine === item.vaccine
                                ) === index
                            )
                            .map((item) => {
                              return item.vaccineNavigation.name;
                            })
                            .join(", ")}
                        </td>
                        <td>{record.additionalRequest ? t("no") : t("complete")}</td>
                        <td>
                          <TableStatus
                            status={
                              record.status == Status.Bridging
                                ? record.status + " " + record.extPartner
                                : record.status
                            }
                            expired={this.handleCheckStatus(record)}
                          />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {currentUser.role !== Role.RegulasiMutuSistem && (
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.setState({
                                      idDownload: record.id,
                                      caseNumber: record.caseNumber,
                                      downloadModalShow: true,
                                    })
                                  }
                                >
                                  Download
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="6">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>{t("report_type")}</th>
                                  <th>{t("reported_date")}</th>
                                  <th>{t("outcome")}</th>
                                  <th>{t("seriousness")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailAefiCaseEntry
                                  .sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail, index) => (
                                    <tr>
                                      <td>
                                        {trail.followUp === 0
                                          ? "Inisial"
                                          : "Follow Up " + trail.followUp}
                                      </td>
                                      <td>
                                        {trail.reportedDate
                                          ? moment(
                                              trail.reportedDate ??
                                                trail.reportedDate
                                            ).format("DD/MM/yyyy")
                                          : "-"}
                                      </td>
                                      <td>{trail.outcomeNavigation?.name}</td>
                                      <td>
                                        {trail.seriousnessNavigation?.name}
                                      </td>
                                    </tr>
                                  ))}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {record.reportedDate
                                      ? moment(
                                          record.reportedDate ??
                                            record.reportedDate
                                        ).format("DD/MM/yyyy")
                                      : "-"}
                                  </td>
                                  <td>{record.outcomeNavigation?.name}</td>
                                  <td>{record.seriousnessNavigation?.name}</td>
                                </tr>
                                {currentUser.role === Role.Staff && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text="Tambah Follow Up"
                                          margin="0"
                                          onClick={() =>
                                            this.followUp(record.id)
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {AEFIRecords.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.AEFIMaxPage}
                pageRangeDisplayed={3}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forcePage={this.state.AEFIPage ? this.state.AEFIPage - 1 : 0}
                initialPage={this.state.initPage - 1}
              />
            </nav>
          </div>

          {/* AE TAB */}
          <div className="tab-pane" id="tab-ae">
            <div className="tab-pane" id="tab-ae">
              <div
                style={{ width: "100%", overflow: "auto", marginBottom: 10 }}
              >
                <Table className="pb-5" hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>{t("case_number")}</th>
                      <th>{t("reported_date")}</th>
                      <th>{t("drug")}</th>
                      <th>{t("completeness_of_data")}</th>
                      <th width="15%">Status</th>
                      <th width="10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {AERecords.map((record, index) => (
                      <>
                        <tr key={record.id}>
                          <td>
                            <div
                              className="expand-table"
                              onClick={(e) =>
                                this.handleSpanClick(record.id, e)
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  this.state.elementSpan === record.id
                                    ? faChevronUp
                                    : faChevronDown
                                }
                              />
                              <span className="expand-number">
                                {index +
                                  1 +
                                  (this.state.AEPage - 1) * this.state.AELimit}
                              </span>
                            </div>
                          </td>
                          <td>{record.caseNumber}</td>
                          <td>
                            {record.reportedDate
                              ? moment(
                                  record.reportedDate ?? record.reportedDate
                                ).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td>
                            {record.aeDrugs
                              ?.filter(
                                (item, index) =>
                                  item.drugs != null &&
                                  record.aeDrugs.findIndex(
                                    (x) => x.drugs === item.drugs
                                  ) === index
                              )
                              .map((item) => {
                                return item.drugsNavigation.name;
                              })
                              .join(", ")}
                          </td>
                          <td>{record.additionalRequest ? t("no") : t("complete")}</td>
                          <td>
                            <TableStatus 
                              status={record.status} 
                              expired={this.handleCheckStatus(record)}
                            />
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-sm dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-h"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.record(record.id)}
                                >
                                  Detail
                                </a>
                                {currentUser.role === Role.Staff && (
                                  <a
                                    className="dropdown-item"
                                    onClick={() => this.handleDelete(record.id)}
                                  >
                                    Delete
                                  </a>
                                )}
                                {currentUser.role !==
                                  Role.RegulasiMutuSistem && (
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      this.setState({
                                        idDownload: record.id,
                                        caseNumber: record.caseNumber,
                                        downloadModalShow: true,
                                      })
                                    }
                                  >
                                    Download
                                  </a>
                                )}
                                {/* <a className="dropdown-item" onClick={() => AEService.Download(record.id, record.caseNumber)}>Download PDF</a>
                                                                <a className="dropdown-item" onClick={() => AEService.DownloadDoc(record.id, record.caseNumber)}>Download Doc</a> */}
                              </div>
                            </div>
                          </td>
                        </tr>
                        {this.state.elementSpan === record.id && (
                          <tr id={record.id} key={record.id}>
                            <td colSpan="6">
                              <Table className="pb-5" hover>
                                <thead className="span-table">
                                  <tr>
                                    <th>{t("report_type")}</th>
                                    <th>{t("reported_date")}</th>
                                    <th>{t("outcome")}</th>
                                    <th>{t("seriousness")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {record.trailAeCaseEntry
                                    .sort((a, b) =>
                                      a.followUp > b.followUp ? 1 : -1
                                    )
                                    .map((trail, index) => (
                                      <tr>
                                        <td>
                                          {trail.followUp === 0
                                            ? "Inisial"
                                            : "Follow Up " + trail.followUp}
                                        </td>
                                        <td>
                                          {trail.reportedDate
                                            ? moment(
                                                trail.reportedDate ??
                                                  trail.reportedDate
                                              ).format("DD/MM/yyyy")
                                            : "-"}
                                        </td>
                                        <td>{trail.outcomeNavigation?.name}</td>
                                        <td>
                                          {trail.seriousnessNavigation?.name}
                                        </td>
                                      </tr>
                                    ))}
                                  <tr>
                                    <td>
                                      {record.followUp === 0
                                        ? "Inisial"
                                        : "Follow Up " + record.followUp}
                                    </td>
                                    <td>
                                      {record.reportedDate
                                        ? moment(
                                            record.reportedDate ??
                                              record.reportedDate
                                          ).format("DD/MM/yyyy")
                                        : "-"}
                                    </td>
                                    <td>{record.outcomeNavigation?.name}</td>
                                    <td>
                                      {record.seriousnessNavigation?.name}
                                    </td>
                                  </tr>
                                  {currentUser.role === Role.Staff && (
                                    <tr>
                                      <td colSpan="4">
                                        <div style={{ float: "right" }}>
                                          <Button
                                            text="Tambah Follow Up"
                                            margin="0"
                                            onClick={() =>
                                              this.followUp(record.id)
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                    {AERecords.length < 1 && (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {t("data_not_found")}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <nav>
                <ReactPaginate
                  previousLabel={<i class="fas fa-arrow-left"></i>}
                  previousLinkClassName={"page-link"}
                  nextLabel={<i class="fas fa-arrow-right"></i>}
                  nextLinkClassName={"page-link"}
                  pageCount={this.state.AEMaxPage}
                  pageRangeDisplayed={3}
                  containerClassName={"pagination justify-content-end"}
                  pageClassName={"page-item"}
                  breakClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  onPageChange={(event) => this.handlePageClick(event)}
                  initialPage={this.state.initPage - 1}
                />
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(CaseEntry));

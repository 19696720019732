import React, { Component } from 'react';

export class NotFound extends Component {

    componentDidMount() {
        document.title = "Page Not Found"
    }

    render() {
        return (
            <div>
                404 Page Not Found
            </div>
        );
    }
}

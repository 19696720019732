import React, { Component } from 'react';

import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalContent from './ModalBody';

import Button from '../Button/Button';


export class DownloadModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 

        };
    }

    render() {

        return (
            <div className="container">
                <Modal {...this.props} size="md">
                    <ModalHeader>
                        <div className="modal-title">Download</div>
                        <hr/>
                    </ModalHeader>
                    <ModalContent>
                        {this.props.children}
                    </ModalContent>
                    <div className='text-right' style={{padding: "30px 35px"}}>
                        <Button
                            color="#495057"
                            background="#FFFFFF 0% 0% no-repeat padding-box"
                            border="1px solid #495057"
                            text="Batal"
                            onClick={this.props.onHide} 
                        />
                    </div>
                </Modal>
            </div>

        )
    }
}
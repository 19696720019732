import { handleResponse, authHeader } from "../../helpers/RequestHelper";

export const AEFIService = {
  Read,
  ReadForManufactureProgress,
  ReadForQualityReview,
  ReadForBpomProgress,
  PrepareBook,
  SubmitBook,
  PrepareForm,
  SubmitForm,
  SendMail,
  ReviewForm,
  ApproveForm,
  Delete,
  CheckDuplicate,
  CheckSimilar,
  Download,
  DownloadDoc,
  DownloadCIOMS,
  DownloadWHO,
  ImportData,
  abortImportData,
};

async function Read(
  filter = "",
  page = 1,
  limit = 10,
  query = "",
  vaccine = "",
  status = "",
  startDate = "",
  endDate = "",
  id = "",
  isAnalysisAvailable = "",
  isQualityReviewAvailable = "",
  isManufactureInitialAvailable = "",
  isManufactureGrowthAvailable = "",
  isPartnerAvailable = "",
  isReporterAvailable = "",
  isSummaryAvailable = "",
  readyToAnalise = false,   
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(
      `api/v1/case-entry/aefi/read?filter=${filter}&page=${page}&limit=${limit}&query=${query}&vaccine=${vaccine}&status=${status}&startDate=${startDate}&endDate=${endDate}&id=${id}&isAnalysisAvailable=${isAnalysisAvailable}&isQualityReviewAvailable=${isQualityReviewAvailable}&isManufactureInitialAvailable=${isManufactureInitialAvailable}&isManufactureGrowthAvailable=${isManufactureGrowthAvailable}&isPartnerAvailable=${isPartnerAvailable}&isReporterAvailable=${isReporterAvailable}&isSummaryAvailable=${isSummaryAvailable}&readyToAnalise=${readyToAnalise}`,
    requestOptions
  );
  data = await handleResponse(data);
  return data;
}

async function ReadForManufactureProgress(isManufactureGrowthAvailable = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(
    `api/v1/case-entry/aefi/read?isManufactureGrowthAvailable=${isManufactureGrowthAvailable}`,
    requestOptions
  );
  data = await handleResponse(data);
  return data;
}

async function ReadForQualityReview(isQualityReviewAvailable = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(
    `api/v1/case-entry/aefi/read?isQualityReviewAvailable=${isQualityReviewAvailable}`,
    requestOptions
  );
  data = await handleResponse(data);
  return data;
}

async function ReadForBpomProgress(isBpomProgressAvailable = "",caseNumber = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  let data = await fetch(
    `api/v1/case-entry/aefi/read?limit=10&page=1&isBpomProgressAvailable=${isBpomProgressAvailable}&query=${caseNumber}`,
    requestOptions
  );
  data = await handleResponse(data);
  return data;
}

function PrepareBook() {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`api/v1/case-entry/aefi/prepare-book`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function SubmitBook(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  return fetch(`api/v1/case-entry/aefi/submit-book`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function PrepareForm(id, followUp = "") {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(
    `api/v1/case-entry/aefi/prepare-form/` + id + "?followUp=" + followUp,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function SubmitForm(id, object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  return fetch(`api/v1/case-entry/aefi/submit-form/` + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function SendMail(id, object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  return fetch(`api/v1/case-entry/aefi/send-mail/` + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function ReviewForm(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  let data = await fetch(`api/v1/case-entry/aefi/review/`, requestOptions);
  data = await handleResponse(data);
  return data;
}

async function ApproveForm(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  let data = await fetch(`api/v1/case-entry/aefi/approve/`, requestOptions);
  data = await handleResponse(data);
  return data;
}

function Delete(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`api/v1/case-entry/aefi/delete/` + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function CheckDuplicate(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  return fetch(`api/v1/case-entry/aefi/check-duplicate-field/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function CheckSimilar(object) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };

  return fetch(`api/v1/case-entry/aefi/check-similar-field/`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function Download(id, casenumber, type) {
  let url =
    type === "ID"
      ? "/api/v1/case-entry/aefi/download/pdf/"
      : "/api/v1/case-entry/aefi/download/pdf-en/";
  let name =
    type === "ID"
      ? `KIPI dengan nomor kasus ${casenumber}.pdf`
      : `AEFI with case number ${casenumber}.pdf`;

  fetch(url + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      const url = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
}

async function DownloadDoc(id, casenumber, type) {
  let url =
    type === "ID"
      ? "/api/v1/case-entry/aefi/download/doc/"
      : "/api/v1/case-entry/aefi/download/doc-en/";
  let name =
    type === "ID"
      ? `KIPI dengan nomor kasus ${casenumber}.docx`
      : `AEFI with case number ${casenumber}.docx`;

  fetch(url + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      const url = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
}

async function DownloadCIOMS(id, casenumber, type) {
  let url = type === "DOC" ? "/api/v1/cioms/doc/" : "/api/v1/cioms/pdf/";
  let name =
    type === "DOC" ? `CIOMS ${casenumber}.docx` : `CIOMS ${casenumber}.pdf`;

  fetch(url + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      const url = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
}

async function DownloadWHO(id, casenumber, type) {
  let url = type === "DOC" ? "/api/v1/who/doc/" : "/api/v1/who/pdf/";
  let name =
    type === "DOC"
      ? `AEFI Reporting WHO ${casenumber}.docx`
      : `AEFI Reporting WHO ${casenumber}.pdf`;

  fetch(url + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((result) => {
      const url = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
}

//to abort api
const controller = new AbortController();
const signal = controller.signal;

async function ImportData(object) {
  const requestOptions = {
    method: "POST",
    signal: signal,
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(object),
  };
  let data = await fetch(`api/v1/case-entry/aefi/import-data`, requestOptions);
  data = await handleResponse(data);
  return data;
}

function abortImportData() {
  console.log("Now Aborting!");
  controller.abort();
}

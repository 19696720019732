import React from 'react';
import { ErrorMessage } from 'formik';

import { DrugsService } from '../../../services/Master/DrugsService';
import { ManufactureService } from '../../../services/Master/ManufactureService';
import { DropdownService } from '../../../services/Master/AllDropdownService';

import Input from '../../../components/Utils/Field/Input';
import Select2 from '../../../components/Utils/Field/Select2';
import ButtonWithIcon from '../../../components/Utils/Button/ButtonWithIcon';

class FormDrugs extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading_data : false };
        this.refreshList();
    }

    refreshList() {

        this.state.pharmaceuticalList = DropdownService.PharmaceuticalList();

        DropdownService.MasterList([
            "productType"
        ]).then(
            (result) => {
                this.setState({ productTypeList: result.data.productTypeList });
            })

        ManufactureService.Read(1, 0, "").then(result => {
            this.setState({
                manufactureList: result.data.records,
            });
        });

        DrugsService.PrepareForm().then(result => {
            this.setState({
                drugsList: result.data.masterDrugsRelated,
            });
        });
    }

    handleRefreshERP = () => {
        
        DrugsService.SyncProduct().then(result => {
            this.setState({
                drugsList: result.data,
                loading_data: false,
            });
        });
    }

    render() {

        const { t, formik } = this.props; 

        return (
            <div className="row">
                <div className="col-xs-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">{t('name')}</label>
                        <Input
                            name="name"
                            type="text"
                            placeholder="Name"
                            errors={formik.errors}
                            touched={formik.touched}
                            {...formik.getFieldProps('name')} />
                    </div>
                    <div className="form-group">
                        {this.createERPUI(formik)}
                        {this.initialERPUI(formik)}
                        {
                            this.state.loading_data && 
                            <div className="row">
                                <div className="col-12">
                                    <p data-text={t('retrieving_data')} className="loading-menu" style={{fontSize: 14, marginTop: 5}}>{t('retrieving_data')}</p>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-xs-12 col-md-12 col-xl-7" style={{marginBottom: 10}}>
                                <ButtonWithIcon
                                    text={t('add_erp_product')}
                                    icon="fa fa-plus"
                                    onClick={this.addERPUI.bind(this, formik)}
                                />
                            </div>
                            <div className="col-xs-12 col-md-12 col-xl-3" style={{padding: 0}}>
                                <ButtonWithIcon
                                    text={'Refresh'}
                                    icon="fa fa-sync-alt"
                                    onClick={() => this.setState({loading_data : true}, () => {this.handleRefreshERP()})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="form-group">
                        <label className="form-label">Manufacturer</label>
                        <Select2
                            name="manufacture"
                            options={this.state.manufactureList?.map(item => { return { value: item.id, label: item.name }; })}
                            value={formik.values.manufacture}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">{t('type')}</label>
                        <Select2
                            name="type"
                            options={this.state.productTypeList?.map(item => { return { value: item.id, label: item.name }; })}
                            value={formik.values.type}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Pharmaceutical Form</label>
                        <Select2
                            name="pharmaceutical"
                            options={this.state.pharmaceuticalList}
                            value={formik.values.pharmaceutical}
                            errors={formik.errors}
                            touched={formik.touched}
                            onChange={formik.setFieldValue}
                            onBlur={formik.setFieldTouched}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Indication</label>
                        <Input
                            name="indication"
                            type="text"
                            placeholder="Indication"
                            errors={formik.errors}
                            touched={formik.touched}
                            {...formik.getFieldProps('indication')} />
                    </div>
                </div>
            </div>
        )
    }

    // AefimasterDrugsRelated Dynamic Table

    initialERPUI(formik) {

        const masterDrugsRelated = this.props.masterDrugsRelated;

        if (masterDrugsRelated && masterDrugsRelated.length) {

            for (var i = 0; i < masterDrugsRelated.length; i++) {

                if (masterDrugsRelated[i] && !masterDrugsRelated[i].isNew && !masterDrugsRelated[i].previewed) {
                    formik.setFieldValue(`masterDrugsRelated[${i}][id]`, masterDrugsRelated[i].id)
                    formik.setFieldValue(`masterDrugsRelated[${i}][itemId]`, masterDrugsRelated[i].itemId)
                    formik.setFieldValue(`masterDrugsRelated[${i}][itemName]`, masterDrugsRelated[i].itemName)
                    formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupId]`, masterDrugsRelated[i].itemGroupId ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupName]`, masterDrugsRelated[i].itemGroupName ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][mainAccountNum]`, masterDrugsRelated[i].mainAccountNum ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][priceDate]`, masterDrugsRelated[i].priceDate ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][priceInvent]`, masterDrugsRelated[i].priceInvent ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][pricePurch]`, masterDrugsRelated[i].pricePurch ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][priceSales]`, masterDrugsRelated[i].priceSales ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][purchId]`, masterDrugsRelated[i].purchId ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][styleId]`, masterDrugsRelated[i].styleId ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][styleName]`, masterDrugsRelated[i].styleName ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][unitInvent]`, masterDrugsRelated[i].unitInvent ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][unitPurch]`, masterDrugsRelated[i].unitPurch ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][unitSales]`, masterDrugsRelated[i].unitSales ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][createdDate]`, masterDrugsRelated[i].createdDate ?? "");
                    formik.setFieldValue(`masterDrugsRelated[${i}][dose]`, masterDrugsRelated[i].dose)
                }

                this.props.masterDrugsRelated[i].previewed = true;
            }
        }
    }

    addERPUI(formik) {
        this.props.masterDrugsRelated.push({ isNew: true });
        formik.setFieldValue(`masterDrugsRelated[${this.props.masterDrugsRelated.length - 1}][id]`, null)
    }

    createERPUI(formik) {

        const {t} = this.props;

        return this.props.masterDrugsRelated?.map((element, i) =>

            <div key={i}>

                <Input
                    name={`masterDrugsRelated[${i}][id]`}
                    type="hidden"
                    errors={formik.errors}
                    touched={formik.touched}
                    {...formik.getFieldProps(`masterDrugsRelated[${i}][id]`)}
                    
                />
                <div className="row dynamic-form">
                    <div className="col-xs-12 col-sm-12 col-md-12 side-form">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-7">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('erp_product_name')}</label>}
                                    <Select2
                                        name={`masterDrugsRelated[${i}][itemId]`}
                                        options={this.state.drugsList?.map(item => { return { value: item.itemId, label: item.itemName }})}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`masterDrugsRelated[${i}][itemId]`)}
                                        onChange={(name, value) => {

                                            let drugs = [];

                                            if(this.state.drugsList){
                                                let index = this.state.drugsList?.map(function(x) {return (x.itemId) ; }).indexOf(value);
                                                drugs = this.state.drugsList ? [this.state.drugsList[index]] : [];
                                            }

                                            console.log(drugs[0])

                                            formik.setFieldValue(name, value);
                                            formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupId]`, drugs[0]?.itemGroupId ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupName]`, drugs[0]?.itemGroupName ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][itemName]`, drugs[0]?.itemName ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][mainAccountNum]`, drugs[0]?.mainAccountNum ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][priceDate]`, drugs[0]?.priceDate ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][priceInvent]`, drugs[0]?.priceInvent ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][pricePurch]`, drugs[0]?.pricePurch ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][priceSales]`, drugs[0]?.priceSales ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][purchId]`, drugs[0]?.purchId ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][styleId]`, drugs[0]?.styleId ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][styleName]`, drugs[0]?.styleName ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][unitInvent]`, drugs[0]?.unitInvent ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][unitPurch]`, drugs[0]?.unitPurch ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][unitSales]`, drugs[0]?.unitSales ?? "");
                                            formik.setFieldValue(`masterDrugsRelated[${i}][createdDate]`, drugs[0]?.createdDate ?? "");

                                        }}
                                        onBlur={formik.setFieldTouched}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-xl-4">
                                <div className="form-group">
                                    {i === 0 && <label className="form-label">{t('dose')}</label>}
                                    <Input
                                        name={`masterDrugsRelated[${i}][dose]`}
                                        type="number"
                                        step=".01"
                                        min={0} 
                                        placeholder={t('dose')}
                                        errors={formik.errors}
                                        touched={formik.touched}
                                        {...formik.getFieldProps(`masterDrugsRelated[${i}][dose]`)}
                                    />
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-1">
                                <div className="align-items-center button-close">
                                    {
                                        i !== 0 &&
                                        <i className="fa fa-times-circle fa text-danger mb-4" aria-hidden="true" onClick={this.removeERPUI.bind(this, formik, i)} style={{cursor:"pointer"}}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    removeERPUI(formik, index) {

        for (var i = index; i < this.props.masterDrugsRelated.length; i++) {

            const masterDrugsRelated = formik.values.masterDrugsRelated[i + 1];

            this.props.masterDrugsRelated[i] = this.props.masterDrugsRelated[i + 1];

            if (masterDrugsRelated) {
                formik.setFieldValue(`masterDrugsRelated[${i}][id]`, masterDrugsRelated.id)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemId]`, masterDrugsRelated[i].itemId)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemName]`, masterDrugsRelated[i].itemName)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupId]`, masterDrugsRelated[i].itemGroupId ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupName]`, masterDrugsRelated[i].itemGroupName ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][mainAccountNum]`, masterDrugsRelated[i].mainAccountNum ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][priceDate]`, masterDrugsRelated[i].priceDate ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][priceInvent]`, masterDrugsRelated[i].priceInvent ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][pricePurch]`, masterDrugsRelated[i].pricePurch ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][priceSales]`, masterDrugsRelated[i].priceSales ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][purchId]`, masterDrugsRelated[i].purchId ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][styleId]`, masterDrugsRelated[i].styleId ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][styleName]`, masterDrugsRelated[i].styleName ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][unitInvent]`, masterDrugsRelated[i].unitInvent ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][unitPurch]`, masterDrugsRelated[i].unitPurch ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][unitSales]`, masterDrugsRelated[i].unitSales ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][createdDate]`, masterDrugsRelated[i].createdDate ?? "");
                formik.setFieldValue(`masterDrugsRelated[${i}][dose]`, masterDrugsRelated[i].dose)
            }
            else {
                formik.setFieldValue(`masterDrugsRelated[${i}][id]`, null)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemId]`, null)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemName]`, null)
                formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupId]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][itemGroupName]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][mainAccountNum]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][priceDate]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][priceInvent]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][pricePurch]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][priceSales]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][purchId]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][styleId]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][styleName]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][unitInvent]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][unitPurch]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][unitSales]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][createdDate]`, null);
                formik.setFieldValue(`masterDrugsRelated[${i}][dose]`, null)
            }
        }

        formik.values.masterDrugsRelated.pop();
        this.props.masterDrugsRelated.pop();
    }

}

export default FormDrugs
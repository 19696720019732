import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import Select from 'react-select'
import moment from 'moment';

import TableStatus from "../../components/Utils/Status/TableStatus";
import ButtonWithIcon from '../../components/Utils/Button/ButtonWithIcon';
import DateRange from "../../components/Utils/DateRange/DateRangePicker";

import { AuditTrailService } from '../../services/AuditTrail/AuditTrailService';

import { DetailModal } from './DetailModal';

import { withTranslation } from "react-i18next";

class AuditTrail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            addModalShow: false,
            editModalShow: [],
            deleteModalShow: false,
            page: 1,
            maxPage: 0,
            limit: 10,
            query: "",
            params: {},
            filterShow: false,
            timezone: moment(new Date()).format('Z'),
            detailData: "",
            detailShow: false,
            id: ""
        }
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        AuditTrailService.Read(this.state.page, this.state.limit, this.state.query, this.state.params).then(result => {
            this.setState({
                maxPage: result.data.maxPage,
                records: result.data.records,
                editModalShow: new Array(result.data.length).fill(false)
            });
        });
    }

    handlePageReset() {
        this.setState({ page: 1 }, () => {
            this.refreshList();
        })
    }

    handleSearchChange(event) {
        this.setState({ query: event.target.value });
    }

    handleSearchSubmit(event) {
        this.handlePageReset();
        event.preventDefault();
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null ? start.replaceAll("/", "-") + "Z" + this.state.timezone : "";
        let endDate = end !== "" && end !== null ? end.replaceAll("/", "-") + "Z" + this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }

    download() {
        AuditTrailService.Download(this.state.params)
    }

    handleStatusChange(item) {

        let data = this.state.params;
        data = { ...data, status: item?.value ?? "" };
        this.setState({ params: data }, () => {
            this.refreshList();
        });
    }

    handlePageClick(event) {
        this.setState({ page: event.selected + 1 }, () => {
            this.refreshList();
        });
    };

    render() {

        const { records } = this.state;

        const { t } = this.props;

        const statusOptions = [
            { value: 200, label: 'Success' },
            { value: 400, label: 'Failed' },
        ]

        return (
            <>
                <DetailModal
                    show={this.state.detailShow}
                    id={this.state.id}
                    onHide={() =>
                        this.setState({ detailShow: false })
                    }
                    data={this.state.detailData}
                />
                <div className="row" style={{ marginTop: 15 }}>
                    <div className="col-md-3" style={{ marginBottom: 15 }}>
                        <ButtonWithIcon
                            text="Download"
                            icon="fa fa-download"
                            onClick={() => this.download()}
                            isSubmitting={this.state.disabledDownload}
                        />
                    </div>
                    <div className="col-md-7">

                    </div>
                    <div className="col-xs-2 col-sm-2">
                        <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                            <ButtonWithIcon
                                text="Filter"
                                icon="fas fa-bars"
                                onClick={() => this.setState({ filterShow: !this.state.filterShow })}
                            />
                        </div>
                    </div>
                </div>
                {
                    this.state.filterShow && (
                        <div className="row filter-wrapper" style={{ marginTop: 10, marginBottom: 10 }}>
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-3" />
                                        <div className="col-xs-12 col-sm-12 col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">{t('search')}</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control"
                                                        value={this.state.query}
                                                        onChange={(event) => this.handleSearchChange(event)}
                                                        placeholder={t('keyword')}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-secondary" type="submit"><i className="fa fa-search" /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-4" style={{ floar: "right" }}>
                                            <label className="form-label">{t('date')}</label>
                                            <DateRange
                                                handlechange={(startDate, endDate, type) => this.handleDateChange(startDate, endDate, type)}
                                                type="KTD"
                                            />
                                        </div>
                                        <div className="col-xs-12 col-sm-6 col-md-2">
                                            <label className="form-label">Status</label>
                                            <Select
                                                placeholder='Status'
                                                width='100%'
                                                name="statusFilter"
                                                isClearable="true"
                                                classNamePrefix="select"
                                                className="basic-single"
                                                options={statusOptions}
                                                value={this.state.AEStatus}
                                                onChange={(item) => this.handleStatusChange(item)} >
                                            </Select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )
                }
                <Table className="pb-5" hover>
                    <thead>
                        <tr>
                            <th width="5%">No</th>
                            <th>Time</th>
                            <th>Activity</th>
                            <th width="15%">Changed By</th>
                            <th width="15%">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {records.map((record, index) =>
                            <tr key={record.id} style={{ cursor: "pointer" }} onClick={() => this.setState({ detailShow: true, detailData: record, id: record?.id })}>
                                <td>{index + 1 + (this.state.page - 1) * this.state.limit}</td>
                                <td>{record.responseDate ? moment(record.responseDate).format('DD/MM/yyyy, HH:mm') : "-"}</td>
                                <td>{record.detailRoutingActivity ? record.detailRoutingActivity : "-"}</td>
                                <td>{record.username ? record.username : "-"}</td>
                                <td>{record.status ? <TableStatus status={record.status === 200 ? "SUCCESS" : "FAILED"} /> : "-"}</td>
                            </tr>
                        )}
                        {
                            records.length < 1 && (
                                <tr>
                                    <td colSpan="6" style={{ textAlign: "center" }}>
                                        {t('data_not_found')}
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <nav>
                    <ReactPaginate
                        previousLabel={(<i class="fas fa-arrow-left"></i>)}
                        previousLinkClassName={"page-link"}
                        nextLabel={(<i class="fas fa-arrow-right"></i>)}
                        nextLinkClassName={"page-link"}
                        pageCount={this.state.maxPage}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        breakClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        onPageChange={(event) => this.handlePageClick(event)}
                        forceSelected={(this.state.page) - 1}
                        forcePage={(this.state.page) - 1}
                        initialPage={(this.state.page) - 1}
                    />
                </nav>

            </>
        )
    }

}

export default withTranslation()(AuditTrail);

import React, {Component} from "react";
import moment from "moment";

import YearPicker from "./YearPicker/index";

class Example extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          value : props.value,
        };
    }

    componentDidMount() {
        this.setState({ value: this.props.value ? this.props.value : this.state.value })
    }

    handleChange = (date) => {

        this.props.handlechange(date)

    }

    render() {
        return (
            <div>
                <YearPicker
                    placeholder={this.props.placeholder}
                    value={this.state.value}
                    onChange={this.handleChange}
                    handleClearYear={this.props.handleClearYear}
                    className="form-control"
                />
            </div>
            );
        }
    }

export default Example;

import React, { Component } from 'react';
import ToggleDisplay from 'react-toggle-display';

import Button from '../../../../components/Utils/Button/Button';
import Input from '../../../../components/Utils/Field/Input';
import Select2Multi from '../../../../components/Utils/Field/Select2Multi';

class Appropriateness extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled : false,
        };
    }

    componentDidUpdate(prevProps) {

        if(prevProps.isLastVersion !== this.props.isLastVersion){
            this.setState({isDisabled : !this.props.isLastVersion ? true : false})
        }

    }

    handleCancelClick(event) {

        this.props.onhide()
    }

    render() {
        const { formik, typecase, history, causality, product, index, disabled, t, ...otherProps } = this.props;
        
        let diagnosisFulfills = history?.diagnosisFulfills === true ? "Yes" : "No";  
        
        return (
            <>
                <div className="tab-pane show active" id="appropriateness">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">Nama satu/lebih vaksin yang diberikan sebelum KIPI?</label>
                                <Select2Multi
                                    disabled
                                    name="relatedVaccine"
                                    options={product?.map(record => { return { value: record.vaccine, label: record.vaccineNavigation?.name  }})}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    value={formik.values?.relatedVaccine}
                                />
                            </div>
                            <div className="form-group">
                                <label className="form-label">{t('definite_diagnosis')}</label>
                                <Input
                                    name="diagnosis"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('diagnosis')} 
                                    disabled={disabled}
                                    same={history?.diagnosis ? history.diagnosis === formik?.values.diagnosis : true }
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('diagnosis_fullfils')}</label>
                                <div className={history?.diagnosisFulfills ? ((diagnosisFulfills === formik.values?.diagnosisFulfills) === false ? "change-value-radio" : "" ) : "" }>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="Yes" name="diagnosisFulfills"
                                            checked={formik.values?.diagnosisFulfills === "Yes"}
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('yes')}</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" className="form-check-input" value="No" name="diagnosisFulfills"
                                            checked={formik.values?.diagnosisFulfills === "No"}
                                            onChange={formik.handleChange} 
                                            disabled={disabled}
                                        />
                                        <label className="form-check-label">{t('no')}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-12">
                            <label className="form-label" style={{ fontSize: 16 }}>{t('causality_caps')}</label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('vaccine')}</label>
                                <Input
                                    disabled
                                    value={causality?.productName}
                                    name="VaccineComplete"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('causes')}</label>
                                <Input
                                    name="productCauses"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps('productCauses')} 
                                    disabled={disabled}
                                    same={history?.productCauses ? history.productCauses === formik?.values.productCauses : true }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='text-right'>
                        <ToggleDisplay>
                            <Button
                                color="#495057"
                                background="#FFFFFF 0% 0% no-repeat padding-box"
                                border="1px solid #495057"
                                text={t('cancel')}
                                onClick={() => this.handleCancelClick()} />
                        </ToggleDisplay>
                        <ToggleDisplay>
                            <Button
                                text={t('next')}
                                onClick={this.props.handleNextClick}
                                isSubmitting={this.state.isDisableNext}
                            />
                        </ToggleDisplay>
                    </div>
                </div>
            </>
        )
    }

}

export default Appropriateness;
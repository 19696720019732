import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { withTranslation } from "react-i18next";

class SummaryTabulations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            accordion: 'clinical-trial'
        };
    }

    handleAccordion = (value) => {

        if (this.state.accordion === value){
            this.setState({accordion : ''})
        }else{
            this.setState({accordion : value})
        }
    }

    render() {

        const { formik, formType, t, ...otherProps } = this.props;

        let total_ct1 = 0;
        let total_ct2 = 0;
        let total_ct2_ac = 0;
        let total_ct3_ip = 0;
        let total_booster = 0;
        let total_pms = 0;

        return (
            <>
                <div className="tab-pane tab-pane-fixed" id="summary-tabulation">
                    <div className="row">
                        <div className="col-12">
                            <div className="row" style={{cursor: "pointer"}} onClick= {() => this.handleAccordion('clinical-trial')}>
                                <div className="col-6">
                                    <div style={{float:"left"}}>
                                    <label className="form-label">SUMMARY TABULATION CLINICAL TRIAL</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div style={{float:"right"}}>
                                        <FontAwesomeIcon icon={this.state.accordion === 'clinical-trial' ? faChevronUp : faChevronDown} />
                                    </div>
                                </div>
                            </div>
                            {   
                                this.state.accordion !== 'clinical-trial' && 
                                <hr/>
                            }
                            <div className="form-group">
                                {
                                    this.state.accordion === 'clinical-trial' && 
                                    <div style={{ width: "100%", overflowX: "auto" }}>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>System Organ Class</th>
                                                    <th>Preferred Term</th>
                                                    <th>CT I (IP)</th>
                                                    <th>CT II (IP)</th>
                                                    <th>CT II (Active Comparator)</th>
                                                    <th>CT III (IP)</th>
                                                    <th>Booster (IP)</th>
                                                    <th>PMS (IP)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formik.values?.pbrerSummaryTabulationCt?.map((record, index) => {
                                                    
                                                    total_ct1 = total_ct1 + record?.ct1ip;
                                                    total_ct2 = total_ct2 + record?.ct2ip;
                                                    total_ct2_ac = total_ct2_ac + record?.ct2ac;
                                                    total_ct3_ip = total_ct3_ip + record?.ct3ip;
                                                    total_booster = total_booster + record?.boosterip;
                                                    total_pms = total_pms + record?.pms;

                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{record?.systemOrganClass}</td>
                                                            <td>{record?.preferredTerm}</td>
                                                            <td>{record?.ct1ip}</td>
                                                            <td>{record?.ct2ip}</td>
                                                            <td>{record?.ct2ac}</td>
                                                            <td>{record?.ct3ip}</td>
                                                            <td>{record?.boosterip}</td>
                                                            <td>{record?.pms}</td>
                                                        </tr>
                                                    )
                                                }                
                                                )}
                                                {
                                                    (formik.values?.pbrerSummaryTabulationCt === undefined || formik.values?.pbrerSummaryTabulationCt === null || formik.values?.pbrerSummaryTabulationCt?.length === 0) && (
                                                        <tr>
                                                            <td colSpan="13" style={{ textAlign: "center" }}>
                                                                {t('data_not_found')}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                            <tfoot style={{ fontFamily: "Montserrat-Bold" }}>
                                                <tr>
                                                    <th></th>
                                                    <th>Total</th>
                                                    <th></th>
                                                    <th>{total_ct1}</th>
                                                    <th>{total_ct2}</th>
                                                    <th>{total_ct2_ac}</th>
                                                    <th>{total_ct3_ip}</th>
                                                    <th>{total_booster}</th>
                                                    <th>{total_pms}</th>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row" style={{cursor: "pointer"}} onClick= {() => this.handleAccordion('data-resource')}>
                                <div className="col-6">
                                    <div style={{float:"left"}}>
                                        <label className="form-label" >SUMMARY TABULATION POST MARKETING DATA RESOURCE</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div style={{float:"right"}}>
                                        <FontAwesomeIcon icon={this.state.accordion === 'data-resource' ? faChevronUp : faChevronDown} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                {
                                    this.state.accordion === 'data-resource' && 
                                    <div style={{ width: "100%", overflowX: "auto" }}>
                                        <Table>
                                            <thead style={{ textAlign: "center", background: "#F4F5F7" }}>
                                                <tr>
                                                    <th rowSpan="2" style={{ verticalAlign: "middle" }}>No</th>
                                                    <th rowSpan="2" style={{ verticalAlign: "middle" }}>System Organ Class</th>
                                                    <th rowSpan="2" style={{ verticalAlign: "middle" }}>Preferred Term</th>
                                                    <th colSpan="2">Serious Unsolicited</th>
                                                    <th colSpan="2">Non-serious Unsolicited</th>
                                                    <th colSpan="1">Total Unsolicited</th>
                                                    <th colSpan="2">Serious Solicited</th>
                                                    <th rowSpan="2" style={{ verticalAlign: "middle" }}>Total</th>
                                                </tr>
                                                <tr>
                                                    <th>Interval</th>
                                                    <th>Cumulative</th>
                                                    <th>Interval</th>
                                                    <th>Cumulative</th>
                                                    <th>Cumulative</th>
                                                    <th>Interval</th>
                                                    <th>Cumulative</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {formik.values?.pbrerSummaryTabulationMarket?.map((record, index) => {

                                                    return (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{record?.systemOrganClass}</td>
                                                            <td>{record?.preferredTerm}</td>

                                                            <td>{record?.intervalSeriousUnsolicited}</td>
                                                            <td>{record?.cumulativeSeriousUnsolicited}</td>
                                                            <td>{record?.intervalNonSeriousUnsolicited}</td>
                                                            <td>{record?.cumulativeNonSeriousUnsolicited}</td>

                                                            <td>{record?.intervalSeriousUnsolicited
                                                                + record?.cumulativeSeriousUnsolicited
                                                                + record?.intervalNonSeriousUnsolicited
                                                                + record?.cumulativeNonSeriousUnsolicited}</td>

                                                            <td>{record?.intervalSeriousSolicited}</td>
                                                            <td>{record?.cumulativeSeriousSolicited}</td>

                                                            <td>{record?.intervalSeriousUnsolicited
                                                                + record?.cumulativeSeriousUnsolicited
                                                                + record?.intervalNonSeriousUnsolicited
                                                                + record?.cumulativeNonSeriousUnsolicited
                                                                + record?.intervalSeriousSolicited
                                                                + record?.cumulativeSeriousSolicited}</td>
                                                        </tr>
                                                    )
                                                }
                                                )}
                                                {
                                                    (formik.values?.pbrerSummaryTabulationMarket === undefined || formik.values?.pbrerSummaryTabulationMarket === null || formik.values?.pbrerSummaryTabulationMarket?.length === 0) && (
                                                        <tr>
                                                            <td colSpan="13" style={{ textAlign: "center" }}>
                                                                {t('data_not_found')}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(SummaryTabulations);
import React, { Component } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { withRouter } from 'react-router-dom';

import { AEFIService } from '../../services/CaseEntry/AEFIService';
import { AEService } from '../../services/CaseEntry/AEService';
import { DropdownService } from '../../services/Master/AllDropdownService';
import Input from '../../components/Utils/Field/Input';
import Select2 from '../../components/Utils/Field/Select2';
import Select2Multi from '../../components/Utils/Field/Select2Multi';
import TimePicker from '../../components/Utils/DateRange/TimePicker';
import Modal from '../../components/Utils/Modal/Modal';
import ModalHeader from '../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../components/Utils/Modal/ModalBody';
import Button from '../../components/Utils/Button/Button';
import DatePicker from '../../components/Utils/DateRange/DateRangePicker';
import * as Utils from '../../helpers/Utils';
import { toastSuccess, toastError } from '../../components/Utils/Toast/Toast';
import { ConfirmationModal } from './ConfirmationModal';

import { withTranslation } from "react-i18next";

class BookInModal extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            snackbaropen: false, 
            snackbarmsg: '',
            showMessage: false,
            confirmationModal: false
        };

        this.getCaseEntryData();
        this.formikRef = React.createRef();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    getCaseEntryData() {

        DropdownService.MasterList(["vaccine"]).then(
            (result) => {
                this.setState({ list: result.data });
            })

        DropdownService.MasterList(["drugs"]).then(
            (result) => {
                this.setState({ druglist: result.data });
            })

        DropdownService.CountryList().then(
            (result) => {
                this.setState({ countryList: result.data });
            })
    }

    record(id) {
        if (this.props.type == "KIPI") {
            this.props.history.push(`/case-entry/aefi/add-aefi/${id}`);
        } else {
            this.props.history.push(`/case-entry/ae/add-ae/${id}`);
        }

    }

    provinceList(country) {

        if (country) {
            DropdownService.ProvinceList(country).then(
                (result) => {
                    this.setState({
                        provinceList: result.data,
                        cityList: []
                    });
                })
        }
    }

    cityList(province) {

        if (province) {
            DropdownService.CityList(province).then(
                (result) => {
                    this.setState({ cityList: result.data });
                })
        }
    }

    confirmationSubmit() {
        const {t} = this.props;
        if (this.props.type === "KIPI") {
            AEFIService.SubmitBook(this.formikRef.current.values).then(
                (result) => {
                    toastSuccess(t('success_add_message'));
                    this.record(result.data.id);
                },
                (error) => {
                    toastError(error);
                })
        }
        else {
            AEService.SubmitBook(this.formikRef.current.values).then(
                (result) => {
                    toastSuccess(t('success_add_message'));
                    this.record(result.data.id);
                },
                (error) => {
                    toastError(error);
                })
        }
    }

    render() {

        const {t} = this.props;

        const vaccineList = this.state.list?.vaccineList;
        const drugsList = this.state.druglist?.drugsList;
        const countryList = this.state.countryList;
        const provinceList = this.state.provinceList;
        const cityList = this.state.cityList;
        const initialValuesFormik = this.props.type === "KIPI" ? 
            {
                vaccines: '',
                patientName: '',
                parentName: '',
                patientGender: 'U',
                patientCountry: '',
                patientProvince: '',
                patientCity: '',
                lastImmunizationDate: '',
                onsetDate: '',
            } :
            {
                drugs: '',
                patientName: '',
                parentName: '',
                patientGender: 'U',
                patientProvince: '',
                patientCity: '',
                patientCountry: '',
                onsetDate: '',
                onsetTime: '',
            }

        const validationSchema=
              Yup.object().shape({
                  patientName: Yup.string().required("Required"),
                  patientGender: Yup.string().required("Required"),
                  vaccines: this.props.type === "KIPI" ? Yup.string().required("Required") : null,
                  drugs: this.props.type === "KTD" ? Yup.string().required("Required") : null,
              });

        return (
             
            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />

                <ConfirmationModal
                    show={this.state.confirmationModal}
                    hide={() => { this.setState({ confirmationModal: false }) }}
                    confirm={(event) => { this.confirmationSubmit(event) }}
                    showhide={true}
                    title={t('confirmation')}
                    text={t('duplicate_confirmation') +"\n" + this.state.duplicateMessage}
                    t={t}
                />

                <Modal {...this.props} size="xl">
                    <Formik
                        innerRef={this.formikRef}
                        initialValues={initialValuesFormik}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();

                            // Merge date & time
                            values.onsetDate = Utils.getDateTime(values.onsetDate, values.onsetTime);

                            if (this.props.type === "KIPI") {
                                AEFIService.CheckDuplicate(values).then(
                                    (result) => {
                                        AEFIService.CheckSimilar(values).then(
                                            (result) => {
                                                AEFIService.SubmitBook(values).then(
                                                    (result) => {
                                                        toastSuccess(t('success_add_message'));
                                                        this.record(result.data.id);
                                                    },
                                                    (error) => {
                                                        setSubmitting(false);
                                                        toastError(error);
                                                    })
                                            },
                                            (error) => {
                                                setSubmitting(false);
                                                this.setState({
                                                    duplicateMessage: error,
                                                    confirmationModal: true
                                                })
                                            })
                                    },
                                    (error) => {
                                        setSubmitting(false);
                                        toastError(error);
                                    })
                            }else{
                                AEService.CheckDuplicate(values).then(
                                    (result) => {
                                        AEService.CheckSimilar(values).then(
                                            (result) => {
                                                AEService.SubmitBook(values).then(
                                                    (result) => {
                                                        toastSuccess(t('success_add_message'));
                                                        this.record(result.data.id);
                                                    },
                                                    (error) => {
                                                        setSubmitting(false);
                                                        toastError(error);
                                                    })
                                            },
                                            (error) => {
                                                setSubmitting(false);
                                                this.setState({
                                                    duplicateMessage: error,
                                                    confirmationModal: true
                                                })
                                            })
                                    },
                                    (error) => {
                                        setSubmitting(false);
                                        toastError(error);
                                    })
                            }
                        }}
                    >
                        {props => (
                            <Form>
                                {
                                    props.status && this.state.showMessage &&
                                    <div className={'form-alert alert alert-danger'}>
                                        <div style={{float:"right", cursor:"pointer"}}>
                                            <i className="fas fa-times" onClick={() => this.setState({showMessage : false})}></i>
                                        </div>
                                        <div>
                                            {props.status}
                                        </div>
                                    </div>
                                }
                                <ModalHeader>
                                    <div className="modal-title">Book In</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="row">

                                        {/* Left Column */}

                                        <div className="col-md-6">
                                            {
                                                this.props.type == "KIPI" && (
                                                    <div className="form-group">
                                                        <label className="form-label">{t('vaccine')}</label>
                                                        <Select2Multi
                                                            name="vaccines"
                                                            options={vaccineList?.map(vaccine => { return { value: vaccine.id, label: vaccine.name }; })}
                                                            errors={props.errors}
                                                            touched={props.touched}
                                                            value={props.values.vaccines}
                                                            onChange={props.setFieldValue}
                                                            onBlur={props.setFieldTouched}
                                                        >
                                                        </Select2Multi>
                                                    </div>
                                                )
                                            }
                                            {
                                                this.props.type == "KTD" && (
                                                    <div className="form-group">
                                                        <label className="form-label">{t('drug')}</label>
                                                        <Select2Multi
                                                            name="drugs"
                                                            options={drugsList?.map(drugs => { return { value: drugs.id, label: drugs.name }; })}
                                                            errors={props.errors}
                                                            touched={props.touched}
                                                            value={props.values.drugs}
                                                            onChange={props.setFieldValue}
                                                            onBlur={props.setFieldTouched}>
                                                        </Select2Multi>
                                                    </div>
                                                )
                                            }
                                            
                                            <div className="form-group">
                                                <label className="form-label">{t('patient_name')}</label>
                                                <Input
                                                    name="patientName"
                                                    type="text"
                                                    placeholder={t('patient_name')}
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    {...props.getFieldProps('patientName')} />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('parent_name')}</label>
                                                <Input
                                                    name="parentName"
                                                    type="text"
                                                    placeholder={t('parent_name')}
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    {...props.getFieldProps('parentName')} />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('gender')}</label>
                                                <div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input" value="U" name="patientGender"
                                                            checked={props.values.patientGender == "U"}
                                                            onChange={props.handleChange} />
                                                        <label className="form-check-label">{t('no_data')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input" value="M" name="patientGender"
                                                            checked={props.values.patientGender == "M"}
                                                            onChange={props.handleChange} />
                                                        <label className="form-check-label">{t('male')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input type="radio" className="form-check-input" value="F" name="patientGender"
                                                            checked={props.values.patientGender == "F"}
                                                            onChange={props.handleChange} />
                                                        <label className="form-check-label">{t('female')}</label>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="patientGender" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('country')}</label>
                                                <Select2
                                                    name="patientCountry"
                                                    options={countryList?.map(country => { return { value: country.code, label: country.name }; })}
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    value={props.values.patientCountry}
                                                    onChange={(name, value) => {
                                                        this.provinceList(value);
                                                        props.setFieldValue("patientCountry", value)
                                                        props.setFieldValue("patientProvince", "");
                                                        props.setFieldValue("patientCity", "");
                                                    }}
                                                    onBlur={props.setFieldTouched}>
                                                </Select2>
                                            </div>                                           
                                        </div>

                                        {/* Right Column */}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">{t('province')}</label>
                                                <Select2
                                                    name="patientProvince"
                                                    options={provinceList?.map(province => { return { value: province.code, label: province.name }; })}
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    value={props.values.patientProvince}
                                                    onChange={(name, value) => {
                                                        this.cityList(value);
                                                        props.setFieldValue("patientProvince", value);
                                                        props.setFieldValue("patientCity", "");
                                                    }}
                                                    disabled={props.values.patientCountry != '62'}
                                                    onBlur={props.setFieldTouched}>
                                                </Select2>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">{t('city')}</label>
                                                <Select2
                                                    name="patientCity"
                                                    options={cityList?.map(city => { return { value: city.code, label: city.name }; })}
                                                    errors={props.errors}
                                                    touched={props.touched}
                                                    value={props.values.patientCity}
                                                    onChange={props.setFieldValue}
                                                    disabled={props.values.patientCountry != '62'}
                                                    onBlur={props.setFieldTouched}>
                                                </Select2>
                                            </div>
                                            {
                                                this.props.type == "KIPI" && (
                                                    <>
                                                        <div className="form-group">
                                                            <label className="form-label">{t('immunization_date')}</label>
                                                            <DatePicker
                                                                name="lastImmunizationDate"
                                                                selectionType="single"
                                                                maxdate={new Date()}
                                                                placeholder="Immunization Date"
                                                                errors={props.errors}
                                                                touched={props.touched}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label">{t('onset_date_aefi')}</label>
                                                            <DatePicker
                                                                name="onsetDate"
                                                                selectionType="single"
                                                                maxdate={new Date()}
                                                                placeholder="Date Onset"
                                                                errors={props.errors}
                                                                touched={props.touched}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                            />
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                this.props.type == "KTD" && (
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <div className="form-group">
                                                                <label className="form-label">{t('onset_date_ae')}</label>
                                                                <DatePicker
                                                                    name="onsetDate"
                                                                    selectionType="single"
                                                                    maxdate={new Date()}
                                                                    placeholder="Date Onset"
                                                                    errors={props.errors}
                                                                    touched={props.touched}
                                                                    onChange={props.setFieldValue}
                                                                    onBlur={props.setFieldTouched}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className="form-label">{t('time')}</label>
                                                                <TimePicker
                                                                    name="onsetTime"
                                                                    placeholder="Time Onset"
                                                                    errors={props.errors}
                                                                    touched={props.touched}
                                                                    onChange={props.setFieldValue}
                                                                    onBlur={props.setFieldTouched}
                                                                    value={props.values?.onsetTime}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                    <div className='text-right'>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t('cancel')}
                                            onClick={this.props.onHide} />
                                        <Button type="submit" text={t('save')} disabled={props.isSubmitting} />
                                        
                                    </div>
                                </ModalContent>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}

export default withTranslation()(withRouter(BookInModal));

import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ToggleDisplay from 'react-toggle-display';
import { Table } from 'react-bootstrap';

import * as Utils from '../../../helpers/Utils';
import Button from '../../../components/Utils/Button/Button';
import Select2 from '../../../components/Utils/Field/Select2';
import Input from '../../../components/Utils/Field/Input';
import Textarea from '../../../components/Utils/Field/Textarea';

import { DropdownService } from '../../../services/Master/AllDropdownService';
import { AnalysisService } from '../../../services/QualityReview/Analysis/AnalysisService';
import { ResumeService } from '../../../services/QualityReview/Resume/ResumeService';
import { toastSuccess, toastError } from '../../../components/Utils/Toast/Toast';

import { withTranslation } from "react-i18next";

class AddResume extends Component {

    constructor(props) {
        super(props);

        this.state = {
            snackbaropen: false,
            snackbarmsg: '',
        };

        this.formikRef = React.createRef();
        this.prepareForm();
    }

    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    prepareForm() {
        Promise.all([AnalysisService.Read('',1, 0, "", { IsResumeNeeded: true }), DropdownService.MasterList(["outcome"])])
            .then(([kipi, dropdown]) => {
                this.setState({
                    caseNumberList: kipi.data.records,
                    outcomeList: dropdown.data.outcomeList
                });
            });
    }

    handleCancelClick(event) {

        this.props.history.push('/medical-review/resume');
    }

    render() {

        const { t } = this.props;

        return (
            <>
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Formik
                    innerRef={this.formikRef}
                    initialValues={{
                        caseNumber: '',
                        analysis: '',
                        caseEntry: '',

                        patientName: '',
                        patientAgeYear: '',
                        patientAgeMonth: '',
                        patientGender: '',

                        chronology: '',
                        resultLaboratory: '',
                        resultPhysic: '',
                        diagnosis: '',
                        outcome: '',
                        resultAnalysis:'',

                        resumeProduct: [],
                    }}
                    validationSchema={
                        Yup.object().shape({
                            resultAnalysis: Yup.string().required("Required"),
                        })
                    }
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                        setStatus();

                        ResumeService.Create(values).then(
                            (result) => {
                                toastSuccess(t('success_add_message'));
                                this.props.history.push(`/medical-review/resume`);
                            },
                            (error) => {
                                setSubmitting(false);
                                toastError(error);
                            })
                    }}
                >
                    {formik => (
                        <Form>
                            <Input
                                disabled
                                name="analysis"
                                type="hidden"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('analysis')}
                            />

                            <Input
                                disabled
                                name="caseEntry"
                                type="hidden"
                                errors={formik.errors}
                                touched={formik.touched}
                                {...formik.getFieldProps('caseEntry')}
                            />

                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('case_number')} <span style={{ color: "red" }}>*</span></label>
                                            <Select2
                                                name="caseNumber"
                                                options={this.state.caseNumberList?.map(item => { return { value: item.caseNumber, label: item.caseNumber }; })}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values?.caseNumber} onChange={(name, value) => {

                                                    var analysis = this.state.caseNumberList.find(obj => { return obj.caseNumber === value })

                                                    var caseEntry;

                                                    if (analysis.aeCaseEntry) {
                                                        caseEntry = analysis.aeCaseEntryNavigation
                                                    }
                                                    if (analysis.aefiCaseEntry) {
                                                        caseEntry = analysis.aefiCaseEntryNavigation
                                                    }

                                                    formik.setFieldValue("caseNumber", value);
                                                    formik.setFieldValue("analysis", analysis.id);
                                                    formik.setFieldValue("caseEntry", caseEntry.id);

                                                    formik.setFieldValue("patientName", caseEntry?.patientName ?? "");
                                                    formik.setFieldValue("patientAgeYear", caseEntry?.patientAgeYear ?? "");
                                                    formik.setFieldValue("patientAgeMonth", caseEntry?.patientAgeMonth ?? "");
                                                    formik.setFieldValue("patientGender", caseEntry?.patientGender ?? "");

                                                    formik.setFieldValue("chronology", caseEntry?.resultChronology ?? "");
                                                    formik.setFieldValue("resultLaboratory", caseEntry?.resultLaboratory ?? "");
                                                    formik.setFieldValue("resultPhysic", caseEntry?.resultPhysic ?? "");
                                                    formik.setFieldValue("diagnosis", caseEntry?.resultConclusion ?? "");
                                                    formik.setFieldValue("outcome", caseEntry?.outcome ?? "");

                                                    formik.setFieldValue("resultAnalysis", "");                                                    

                                                    formik.setFieldValue("resumeProduct", analysis.analysisProduct.map(item => { return { productName: item.productName, batch: item.batch }; }));

                                                }}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>{t('product_name')}</th>
                                                        <th>{t('batch_number')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {formik.values.resumeProduct?.map((record, index) =>
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <Input
                                                                    disabled
                                                                    name={`resumeProduct[${index}][productName]`}
                                                                    type="text"
                                                                    errors={formik.errors}
                                                                    touched={formik.touched}
                                                                    {...formik.getFieldProps(`resumeProduct[${index}][productName]`)} 
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    disabled
                                                                    name={`resumeProduct[${index}][batch]`}
                                                                    type="text"
                                                                    errors={formik.errors}
                                                                    touched={formik.touched}
                                                                    {...formik.getFieldProps(`resumeProduct[${index}][batch]`)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>

                            <div>
                                <label className="form-label" style={{ fontSize: 16 }}>IDENTITAS</label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('name')}</label>
                                            <Input
                                                disabled
                                                name="patientName"
                                                type="text"
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('patientName')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('gender')}</label>
                                            <div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input" value="U" name="patientGender"
                                                        disabled
                                                        checked={formik.values.patientGender === "U"}
                                                        onChange={formik.handleChange}
                                                        onClick={() => formik.setFieldValue("patientChildbearing", "")} />
                                                    <label className="form-check-label">{t('no_data')}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input" value="M" name="patientGender"
                                                        disabled
                                                        checked={formik.values.patientGender === "M"}
                                                        onChange={formik.handleChange} />
                                                    <label className="form-check-label">{t('male')}</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input type="radio" className="form-check-input" value="F" name="patientGender"
                                                        disabled
                                                        checked={formik.values.patientGender === "F"}
                                                        onChange={formik.handleChange} />
                                                    <label className="form-check-label">{t('female')}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">{t('age')}</label>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            disabled
                                                            name="patientAgeYear"
                                                            type="number"
                                                            min={0}
                                                            placeholder={t('year')}
                                                            className="form-control"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            value={formik.values.patientAgeYear ? formik.values.patientAgeYear : ""}
                                                            {...formik.getFieldProps('patientAgeYear')}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{t('year')}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <input
                                                            disabled
                                                            name="patientAgeMonth"
                                                            type="number"
                                                            min={0}
                                                            max={11}
                                                            placeholder={t('month')}
                                                            className="form-control"
                                                            errors={formik.errors}
                                                            touched={formik.touched}
                                                            {...formik.getFieldProps('patientAgeMonth')}
                                                        />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text">{t('month')}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div>
                                <label className="form-label" style={{ fontSize: 16 }}>{t('manifestation_result_caps')}</label>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('chronological')}</label>
                                            <Textarea
                                                disabled
                                                rows="6"
                                                name="chronology"
                                                placeholder={t('chronological')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('chronology')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('laboratory_result')}</label>
                                            <Textarea
                                                disabled
                                                rows="6"
                                                name="resultLaboratory"
                                                placeholder={t('laboratory_result')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('resultLaboratory')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('physical_examination')}</label>
                                            <Textarea
                                                disabled
                                                rows="6"
                                                name="resultPhysic"
                                                placeholder={t('physical_examination')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('resultPhysic')}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">{t('diagnose')}</label>
                                            <Textarea
                                                disabled
                                                rows="6"
                                                name="diagnosis"
                                                placeholder={t('diagnose')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('diagnosis')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">{t('outcome')}</label>
                                            <Select2
                                                disabled
                                                name="outcome"
                                                options={this.state.outcomeList?.map(item => { return { value: item.id, label: item.name }; })}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                value={formik.values.outcome}
                                                onChange={formik.setFieldValue}
                                                onBlur={formik.setFieldTouched}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">{t('analysis_result')} <span style={{ color: "red" }}>*</span></label>
                                            <Textarea
                                                rows="6"
                                                name="resultAnalysis"
                                                placeholder={t('analysis_result')}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                {...formik.getFieldProps('resultAnalysis')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='text-right'>
                                <ToggleDisplay>
                                    <Button
                                        color="#495057"
                                        background="#FFFFFF 0% 0% no-repeat padding-box"
                                        border="1px solid #495057"
                                        text={t('cancel')}
                                        onClick={() => this.handleCancelClick()} />
                                </ToggleDisplay>
                                <ToggleDisplay>
                                    <Button
                                        type="submit"
                                        text={t('save')}
                                        disabled={formik.isSubmitting} />
                                </ToggleDisplay>
                                {
                                    formik.status &&
                                    <div className={'alert alert-danger'}>{formik.status}</div>
                                }
                            </div>

                        </Form>
                    )}
                </Formik>
            </>
        )
    }
}

export default withTranslation()(AddResume);
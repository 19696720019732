import React, { Component } from 'react';
import { MenuItem } from './NavLink/MenuItem';
import { SidebarItem } from './NavLink/SidebarItem';
import { NavLink } from 'react-router-dom';
import { authenticationService } from '../../services/AuthService';
import { Role } from '../../helpers/Constants';

export class Sidebar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            elementSpan: "",
        }
    }

    handleSpanClick = (value) => {

        
        if (this.state.elementSpan === value) {
            //If collapsiable is already visible and clicked on same then this will hide it
            this.setState({ elementSpan: '' })
        } else {
            //To show collapsiable
            this.setState({ elementSpan: value })
        }
    }

    render() {

        const currentUser = authenticationService.currentUserValue;

        return (
            <aside className="left-sidebar" data-sidebarbg="skin6">
                <div className="" data-sidebarbg="skin6">
                    <nav className="sidebar-nav">
                        <ul id="sidebarnav">
                            <NavLink className="navlink-menu" to="/dashboard">
                                <MenuItem aria-expanded="false">
                                    <i className="fas fa-home icon-sidebar"></i><span className="hide-menu">Dashboard</span>
                                </MenuItem>
                            </NavLink>
                            {
                                (currentUser?.role == Role.RegulasiMutuSistem || currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager) &&
                                <NavLink className="navlink-menu" to="/case-entry">
                                    <MenuItem aria-expanded="false" >
                                        <i className="fas fa-case-entry icon-sidebar"></i><span className="hide-menu">Case Entry</span>
                                    </MenuItem>
                                </NavLink>
                            }
                            {
                                currentUser?.role == Role.RegulasiMutuSistem &&

                                <>
                                    <NavLink className="navlink-menu" to="/medical-review/summary">
                                        <MenuItem aria-expanded="false">
                                            <i className="fas fa-medical-review icon-sidebar"></i><span className="hide-menu">Summary</span>
                                        </MenuItem>
                                    </NavLink>
                                </>
                            }
                            {
                                (currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager) &&

                                <>
                                    <SidebarItem title="Medical Review" icon="fas fa-medical-review icon-sidebar"  onClick={() => this.handleSpanClick("MedicalReview")}>
                                        {
                                            this.state.elementSpan === "MedicalReview" && 
                                            <ul className="collapse first-level base-level-line show-dropdown">
                                                <NavLink className="navlink-menu" to="/medical-review/analysis">
                                                    <MenuItem>
                                                        <span className="hide-menu">Analysis</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/medical-review/resume">
                                                    <MenuItem>
                                                        <span className="hide-menu">Resume</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/medical-review/summary">
                                                    <MenuItem>
                                                        <span className="hide-menu">Summary</span>
                                                    </MenuItem>
                                                </NavLink>
                                            </ul>
                                        }
                                    </SidebarItem>
                                    <SidebarItem title="Report Submission" icon="fas fa-report-submission icon-sidebar" onClick={() => this.handleSpanClick("Report Submission")}>
                                        {
                                            this.state.elementSpan === "Report Submission" && 
                                            <ul className="collapse  first-level base-level-line show-dropdown">
                                                <NavLink className="navlink-menu" to="/report-submission/bpom">
                                                    <MenuItem>
                                                        <span className="hide-menu">BPOM</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/report-submission/manufacture">
                                                    <MenuItem>
                                                        <span className="hide-menu">Manufacturer</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/report-submission/reporter">
                                                    <MenuItem>
                                                        <span className="hide-menu">Reporter</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/report-submission/partner">
                                                    <MenuItem>
                                                        <span className="hide-menu">Partner</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/report-submission/psur">
                                                    <MenuItem>
                                                        <span className="hide-menu">PSUR</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/report-submission/pbrer">
                                                    <MenuItem>
                                                        <span className="hide-menu">PBRER</span>
                                                    </MenuItem>
                                                </NavLink>
                                            </ul>
                                        }
                                    </SidebarItem>
                                    {/* <SidebarItem title="Reconciliation Data" icon="fas fa-file icon-sidebar" onClick={() => this.handleSpanClick("Reconciliation Data")}>
                                        {
                                            this.state.elementSpan === "Reconciliation Data" &&
                                            <ul className="collapse  first-level base-level-line show-dropdown">
                                                <NavLink className="navlink-menu" to="/reconciliation-data/qa">
                                                    <MenuItem>
                                                        <span className="hide-menu">QA</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/reconciliation-data/msd">
                                                    <MenuItem>
                                                        <span className="hide-menu">MSD</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/reconciliation-data/sanofi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Sanofi</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/reconciliation-data/biovac">
                                                    <MenuItem>
                                                        <span className="hide-menu">Biovac</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/reconciliation-data/komnas-kipi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Komnas KIPI</span>
                                                    </MenuItem>
                                                </NavLink>
                                            </ul>
                                        }
                                    </SidebarItem> */}
                                    <SidebarItem title="Summary Report" icon="fas fa-summary-report icon-sidebar"  onClick={() => this.handleSpanClick("Summary Report")}>
                                        {
                                            this.state.elementSpan === "Summary Report" && 
                                            <ul className="collapse  first-level base-level-line show-dropdown">
                                                <NavLink className="navlink-menu" to="/summary-report/annual-aefi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Annual AEFI</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/virus-vaccine-aefi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Virus Vaccine AEFI</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/bacteria-vaccine-aefi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Bacteria Vaccine AEFI</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/combination-vaccine-aefi">
                                                    <MenuItem>
                                                        <span className="hide-menu">Combination Vaccine AEFI</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/concomitant-vaccination">
                                                    <MenuItem>
                                                        <span className="hide-menu">Concomitant Vaccination</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/causality">
                                                    <MenuItem>
                                                        <span className="hide-menu">Causality</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/case-archival">
                                                    <MenuItem>
                                                        <span className="hide-menu">Case Archival</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/summary-report/reporter">
                                                    <MenuItem>
                                                        <span className="hide-menu">Reporter</span>
                                                    </MenuItem>
                                                </NavLink>
                                            </ul>
                                        }
                                    </SidebarItem>
                                </>
                            }
                            { (currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager) &&
                                    <NavLink className="navlink-menu" to="/clinical-trial">
                                        <MenuItem aria-expanded="false" >
                                            <i className="fas fa-clinical-trial icon-sidebar"></i><span className="hide-menu">Clinical Trial</span>
                                        </MenuItem>
                                    </NavLink>
                            }
                            {
                                (currentUser?.role == Role.RegulasiMutuSistem || currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager) &&

                                <>
                                    <NavLink className="navlink-menu" to="/quality-review">
                                        <MenuItem aria-expanded="false">
                                            <i className="fas fa-quality-review icon-sidebar"></i><span className="hide-menu">Quality Review</span>
                                        </MenuItem>
                                    </NavLink>
                                </>
                            }
                            { (currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager) &&
                                    <NavLink className="navlink-menu" to="/audit-trail">
                                        <MenuItem aria-expanded="false" >
                                            <i className="fas fa-audit-trail icon-sidebar"></i><span className="hide-menu">Audit Trail</span>
                                        </MenuItem>
                                    </NavLink>
                            }
                            {
                                currentUser?.role === Role.Administrator &&

                                <>
                                    <NavLink className="navlink-menu" to="/app-external">
                                        <MenuItem aria-expanded="false" >
                                            <i className="fas fa-file-alt icon-sidebar"></i><span className="hide-menu">App External</span>
                                        </MenuItem>
                                    </NavLink>
                                    <NavLink className="navlink-menu" to="/audit-trail">
                                        <MenuItem aria-expanded="false" >
                                            <i className="fas fa-audit-trail icon-sidebar"></i><span className="hide-menu">Audit Trail</span>
                                        </MenuItem>
                                    </NavLink>
                                </>
                            }
                            {
                                (currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Administrator || currentUser?.role == Role.Manager ) &&
                                    <SidebarItem title="Data Master" icon="fas fa-data-master icon-sidebar" onClick={() => this.handleSpanClick("Data Master")}>
                                        {
                                            this.state.elementSpan === "Data Master" && 
                                            <ul className="collapse first-level base-level-line show-dropdown">
                                                <NavLink className="navlink-menu" to="/data-master/source">
                                                    <MenuItem>
                                                        <span className="hide-menu">Source</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/vaccinator">
                                                    <MenuItem>
                                                        <span className="hide-menu">Vaccinator</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/institution">
                                                    <MenuItem>
                                                        <span className="hide-menu">Institution</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/vaccine">
                                                    <MenuItem to="/data-master/vaccine">
                                                        <span className="hide-menu">Vaccine</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/symptomps">
                                                    <MenuItem>
                                                        <span className="hide-menu">Symptoms</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/allergic">
                                                    <MenuItem>
                                                        <span className="hide-menu">Allergic</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/drugs">
                                                    <MenuItem>
                                                        <span className="hide-menu">Drugs</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/manufacture">
                                                    <MenuItem>
                                                        <span className="hide-menu">Manufacturer</span>
                                                    </MenuItem>
                                                </NavLink>
                                                <NavLink className="navlink-menu" to="/data-master/partner">
                                                    <MenuItem>
                                                        <span className="hide-menu">Partner</span>
                                                    </MenuItem>
                                                </NavLink>

                                                <NavLink className="navlink-menu" to="/data-master/route">
                                                    <MenuItem>
                                                        <span className="hide-menu">Route</span>
                                                    </MenuItem>
                                                </NavLink>

                                                <NavLink className="navlink-menu" to="/data-master/organization">
                                                    <MenuItem>
                                                        <span className="hide-menu">Organization</span>
                                                    </MenuItem>
                                                </NavLink>

                                                <NavLink className="navlink-menu" to="/data-master/letter">
                                                    <MenuItem>
                                                        <span className="hide-menu">Letter</span>
                                                    </MenuItem>
                                                </NavLink>

                                                <NavLink className="navlink-menu" to="/data-master/soc">
                                                    <MenuItem>
                                                        <span className="hide-menu">SOC</span>
                                                    </MenuItem>
                                                </NavLink>

                                                <NavLink className="navlink-menu" to="/data-master/preferred-term">
                                                    <MenuItem>
                                                        <span className="hide-menu">Preferred Term</span>
                                                    </MenuItem>
                                                </NavLink>
                                        </ul>
                                    }
                                </SidebarItem>
                            }
                            {
                                (currentUser?.role == Role.Staff || currentUser?.role == Role.Unit || currentUser?.role == Role.Divisi || currentUser?.role == Role.Manager ) &&
                                <>
                                    
                                    <NavLink className="navlink-menu" to="/reminder">
                                        <MenuItem aria-expanded="false" >
                                            <i className="fas fa-reminder icon-sidebar"></i><span className="hide-menu">Reminder</span>
                                        </MenuItem>
                                    </NavLink>
                                </>
                            }
                            {
                                currentUser?.role == Role.RegulasiMutu &&

                                <>
                                    <NavLink className="navlink-menu" to="/market-authorization">
                                        <MenuItem aria-expanded="false">
                                            <i className="fas fa-file-alt icon-sidebar"></i><span className="hide-menu">Market Authorization</span>
                                        </MenuItem>
                                    </NavLink>
                                </>
                            }

                            <NavLink className="navlink-menu" to="/product-distribution">
                                <MenuItem aria-expanded="false" >
                                    <i className="fas fa-file-alt icon-sidebar"></i><span className="hide-menu">Product Distribution</span>
                                </MenuItem>
                            </NavLink>
                        </ul>
                    </nav>
                </div>
            </aside>
        );
    }
}
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "react-select";
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import Button from "../../../components/Utils/Button/Button";
import Tab from "../../../components/Utils/Tab/Tab";
import ButtonWithIcon from "../../../components/Utils/Button/ButtonWithIcon";
import Year from "../../../components/Utils/DateRange/YearPicker";
import TableStatus from "../../../components/Utils/Status/TableStatus";

import { ADROnlineService } from "../../../services/BPOM/ADROnlineService";
import { ProgressReportService } from "../../../services/BPOM/ProgressReportService";
import { AEFIOverseasService } from "../../../services/BPOM/AEFIOverseasService";
import { AEFINonSeriousService } from "../../../services/BPOM/AEFINonSeriousService";
import { authenticationService } from "../../../services/AuthService";
import { DropdownService } from "../../../services/Master/AllDropdownService";
import { Role, Status } from "../../../helpers/Constants";

import { DeleteModal } from "./DeleteModal";
import { AddADROnlineModal } from "./AddADROnlineModal";
import { DetailADROnlineModal } from "./DetailADROnlineModal";

import {
  toastSuccess,
  toastError,
} from "../../../components/Utils/Toast/Toast";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

class BPOM extends Component {
  constructor(props) {
    super(props);

    const { page } = this.props?.location?.state
      ? this.props?.location?.state
      : 1;

    const filterValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("filter")
      : null;
    const periodValue = this.props.location.search
      ? new URLSearchParams(this.props.location.search).get("period")
      : null;
    let case_type = "ADROnline"
    let progressParams = {}
    if(filterValue != null) {
      let startDate = ""
      let endDate = ""
      case_type = "ProgressReport"
      const {startDate : newStartDate, endDate: newEndDate} = this.periodFilter(periodValue)
      startDate = newStartDate;
      endDate = newEndDate;
      progressParams = {...progressParams, status : "Closed", startDate, endDate}
    }
    this.state = {
      CheckMassResend : true,
      filter : filterValue,
      period : periodValue,
      case_type: case_type,
      record: [],
      record_id: "",
      filterShow: false,
      addADROnlineModal: false,
      detailADROnlineModal: false,
      editADROnlineModal: [],
      ADROnlinePage: 1,
      ADROnlineMaxPage: 0,
      ADROnlineLimit: 10,
      ADROnlineQuery: "",
      ADROnlineParams: {},
      ADROnlineRecords: [],
      progressPage: 1,
      progressMaxPage: 0,
      progressLimit: 10,
      progressQuery: "",
      progressParams: progressParams,
      progressRecords: [],
      kipiOverseasPage: 1,
      kipiOverseasMaxPage: 0,
      kipiOverseasLimit: 10,
      kipiOverseasQuery: "",
      kipiOverseasParams: {},
      kipiOverseasRecords: [],
      kipiNonSeriousPage: 1,
      kipiNonSeriousMaxPage: 0,
      kipiNonSeriousLimit: 10,
      kipiNonSeriousQuery: "",
      kipiNonSeriousParams: {},
      kipiNonSeriousRecords: [],
      deleteModalShow: false,
      elementSpan: "",
      timezone: moment(new Date()).format("Z"),
      sendArray: [],
      initPage: page,
    };

    // this.handleTab();
  }

  componentDidMount() {
    this.massResendStatus();
    this.refreshList();    

    DropdownService.MasterList(["vaccine", "drugs"]).then((result) => {
      this.setState({ list: result.data });
    });

    if (this.props.location?.state?.tab) {
      document.getElementById(this.props.location?.state?.tab).click();
    }
  }

  refreshList(type) {
    if (type == null || type == "ADROnline") {
      ADROnlineService.Read(
        this.state.ADROnlinePage,
        this.state.ADROnlineLimit,
        this.state.ADROnlineQuery,
        this.state.ADROnlineParams
      ).then((result) => {
        this.setState({
          ADROnlineMaxPage: result?.data?.maxPage,
          ADROnlineRecords: result?.data?.records,
        });
      });
    }

    if (type == null || type == "ProgressReport") {
      ProgressReportService.Read(
        this.state.progressPage,
        this.state.progressLimit,
        this.state.progressQuery,
        this.state.progressParams
      ).then((result) => {
        this.setState({
          progressMaxPage: result?.data?.maxPage,
          progressRecords: result?.data?.records,
        });
      });
    }

    if (type == null || type == "KIPIOverseas") {
      AEFIOverseasService.Read(
        this.state.kipiOverseasPage,
        this.state.kipiOverseasLimit,
        this.state.kipiOverseasQuery,
        this.state.kipiOverseasParams
      ).then((result) => {
        this.setState({
          kipiOverseasMaxPage: result?.data?.maxPage,
          kipiOverseasRecords: result?.data?.records,
        });
      });
    }

    if (type == null || type == "KIPINonSerious") {
      AEFINonSeriousService.Read(
        this.state.kipiNonSeriousPage,
        this.state.kipiNonSeriousLimit,
        this.state.kipiNonSeriousQuery,
        this.state.kipiNonSeriousParams
      ).then((result) => {
        this.setState({
          kipiNonSeriousMaxPage: result?.data?.maxPage,
          kipiNonSeriousRecords: result?.data?.records,
        });
      });
    }
  }

  handleTab = () => {
    if (this.props.location?.state?.tab) {
      document.getElementById(this.props.location?.state?.tab).click();
    }
  };

  periodFilter = (period) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth()+1;
    const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    const daysUntilStartOfWeek = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
    const firstDateOfWeek = new Date(currentDate);
    firstDateOfWeek.setDate(currentDate.getDate() - daysUntilStartOfWeek);
    const lastDateOfWeek = new Date(firstDateOfWeek);
    lastDateOfWeek.setDate(firstDateOfWeek.getDate() + 6);
    const firstWeek = firstDateOfWeek.getDate()
    const endWeek = lastDateOfWeek.getDate()

    let startDate = ""
    let endDate = ""
    if(period == "week"){
      startDate = `${currentYear}-${currentMonth}-${firstWeek}Z+07:00`
      endDate = `${currentYear}-${firstWeek > endWeek ? currentMonth+1 : currentMonth}-${endWeek}Z+07:00`
    }
    else if(period == "month"){
      startDate = `${currentYear}-${currentMonth}-01Z+07:00`
      endDate = `${currentYear}-${currentMonth}-${daysInMonth}Z+07:00`
    }else if(period == "year"){
      startDate = `${currentYear}-01-01Z+07:00`
      endDate = `${currentYear}-12-31Z+07:00`
    }
    return {startDate, endDate} 
  }

  massResendStatus = () => {
    ADROnlineService.MassResendStatus().
        then((result) => {
            this.setState({ CheckMassResend: result?.data?.status })
        });
  }

  handleSelect = (event) => {
    let sendArray = this.state.sendArray;
    if (event.target.value === "all") {
      if (this.state.ADROnlineRecords) {
        const index = sendArray.indexOf(event.target.value);
        if (index > -1) {
          sendArray = [];
        } else {
          sendArray = ["all"];
          this.state.ADROnlineRecords.map((item) => {
            if (item?.bpomSent !== true) {
              sendArray = [...sendArray, item?.id];
            }
          });
        }
      }
    } else {
      if (event.target.value) {
        const indexAll = sendArray.indexOf("all");
        if (indexAll > -1) {
          sendArray.splice(indexAll, 1);
        }

        const index = sendArray.indexOf(event.target.value);
        if (index > -1) {
          sendArray.splice(index, 1);
        } else {
          sendArray = [...sendArray, event.target.value];
        }
      }
    }
    this.setState({ sendArray });
  };

  handleClearFilters = () => {
    this.setState({filter : null})
    this.handlePageReset();
    this.setState({ case_type : "ADROnline"})
    this.setState({ progressParams : {}})
    this.props.history.push(`/report-submission/bpom`)
  }

  deleteRecord(id) {
    const { t } = this.props;
    if (this.state.case_type == "ADROnline") {
      ADROnlineService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("ADROnline");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }

    if (this.state.case_type == "ProgressReport") {
      ProgressReportService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("ProgressReport");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }

    if (this.state.case_type == "KIPIOverseas") {
      AEFIOverseasService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("KIPIOverseas");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }

    if (this.state.case_type == "KIPINonSerious") {
      AEFINonSeriousService.Delete(id).then(
        (result) => {
          toastSuccess(t("success_delete_message"));
          this.refreshList("KIPINonSerious");
          this.setState({ deleteModalShow: false });
        },
        (error) => {
          toastError(error);
        }
      );
    }
  }

  handleDelete(id) {
    this.setState({
      record_id: id,
      deleteModalShow: true,
    });
  }

    handleSend(id) {
    let id_send = id;
    const { t } = this.props;
    ADROnlineService.Resend(id_send).then(
      (result) => {
        toastSuccess(t("success_resend_confirmation"));
        this.refreshList("ADROnline");
      },
      (error) => {
        toastError(error);
      }
    );
  }

  handleSendAll = () => {
    if (this.state.sendArray) {
      this.state.sendArray.map((item, index) => {
        if (item !== "all") {
          setTimeout(
             () => this.handleSend(item),
             ((index + 1) * 500)
          );
        }
        if (this.state.sendArray.length - 1 === index) {
          this.setState({ sendArray: [] });
        }
      });
    }
  };

  handleMassSend = () => {
      ADROnlineService.MassResend().then(
        (result) => {
          toastSuccess(t("success_resend_confirmation"));
          this.refreshList("ADROnline");
        },
        (error) => {
          toastError(error);
        }
      );
      window.location.reload()
  }

  handleSendAllDelay = () => {
    const items = this.state.sendArray;
    if (items.length == 0) {
      // stop when there's no more items to process
      this.setState({ sendArray: [] });
      console.log(items, "done");
      return;
    }
    if (items !== "all") {
      console.log(items[0], Date());
      this.handleSend(items);
    }
    setTimeout(() => this.handleSendAllDelay(items.slice(1), 2000), 2000);
  };

  handleYearChange(year) {
    let startDate = year + "-01-01" + "Z" + this.state.timezone;
    let endDate = year + "-12-31" + "Z" + this.state.timezone;
    let data = this.state.params;
    data = { ...data, startDate, endDate };

    this.setState({ kipiNonSeriousParams: data }, () => {
      this.handlePageReset();
    });
  }

  handlePageReset() {
    if (this.state.case_type === "ADROnline") {
      this.setState({ ADROnlinePage: 1 }, () => {
        this.refreshList("ADROnline");
      });
    }

    if (this.state.case_type == "ProgressReport") {
      this.setState({ progressPage: 1 }, () => {
        this.refreshList("ProgressReport");
      });
    }

    if (this.state.case_type == "KIPIOverseas") {
      this.setState({ kipiOverseasPage: 1 }, () => {
        this.refreshList("KIPIOverseas");
      });
    }

    if (this.state.case_type == "KIPINonSerious") {
      this.setState({ kipiNonSeriousPage: 1 }, () => {
        this.refreshList("KIPINonSerious");
      });
    }
  }

  handleSearchChange(event) {
    if (this.state.case_type === "ADROnline") {
      this.setState({ ADROnlineQuery: event.target.value }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type == "ProgressReport") {
      this.setState({ progressQuery: event.target.value }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type == "KIPIOverseas") {
      this.setState({ kipiOverseasQuery: event.target.value }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type == "KIPINonSerious") {
      this.setState({ kipiNonSeriousQuery: event.target.value }, () => {
        this.handlePageReset();
      });
    }
  }

  handleDownload(id) {
    if (this.state.case_type == "KIPIOverseas") {
      AEFIOverseasService.Download(id).then(
        (result) => {
          this.refreshList("KIPIOverseas");
        },
        (error) => {
          toastError(error);
        }
      );
    }

    if (this.state.case_type == "KIPINonSerious") {
      AEFINonSeriousService.Download(id).then(
        (result) => {
          this.refreshList("KIPINonSerious");
        },
        (error) => {
          toastError(error);
        }
      );
    }
  }

  handleDownloadData(id) {
    if (this.state.case_type == "KIPINonSerious") {
      AEFINonSeriousService.DownloadData(id).then(
        (result) => {
          this.refreshList("KIPINonSerious");
        },
        (error) => {
          toastError(error);
        }
      );
    }
  }

  handleSearchSubmit(event) {
    this.refreshList(this.state.case_type);
    event.preventDefault();
  }

  handleVaccineChange(item) {
    if (this.state.case_type === "ADROnline") {
      let data = this.state.ADROnlineParams;
      data = { ...data, vaccine: item?.value ?? "" };

      this.setState({ ADROnlineParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "ProgressReport") {
      let data = this.state.progressParams;
      data = { ...data, vaccine: item?.value ?? "" };

      this.setState({ progressParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "KIPIOverseas") {
      let data = this.state.kipiOverseasParams;
      data = { ...data, vaccine: item?.value ?? "" };

      this.setState({ kipiOverseasParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "KIPINonSerious") {
      let data = this.state.kipiNonSeriousParams;
      data = { ...data, vaccine: item?.value ?? "" };

      this.setState({ kipiNonSeriousParams: data }, () => {
        this.handlePageReset();
      });
    }
  }

  handleDrugsChange(item) {
    if (this.state.case_type === "ADROnline") {
      let data = this.state.ADROnlineParams;
      data = { ...data, drugs: item?.value ?? "" };

      this.setState({ ADROnlineParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "ProgressReport") {
      let data = this.state.progressParams;
      data = { ...data, drugs: item?.value ?? "" };

      this.setState({ progressParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "KIPIOverseas") {
      let data = this.state.kipiOverseasParams;
      data = { ...data, drugs: item?.value ?? "" };

      this.setState({ kipiOverseasParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "KIPINonSerious") {
      let data = this.state.kipiNonSeriousParams;
      data = { ...data, drugs: item?.value ?? "" };

      this.setState({ kipiNonSeriousParams: data }, () => {
        this.handlePageReset();
      });
    }
  }

  handleStatusChange(item) {
    if (this.state.case_type === "ProgressReport") {
      let data = this.state.progressParams;
      data = { ...data, status: item?.value ?? "" };

      this.setState({ progressParams: data }, () => {
        this.handlePageReset();
      });
    }
    if (this.state.case_type === "KIPIOverseas") {
      let data = this.state.kipiOverseasParams;
      data = { ...data, status: item?.value ?? "" };

      this.setState({ kipiOverseasParams: data }, () => {
        this.handlePageReset();
      });
    }

    if (this.state.case_type === "KIPINonSerious") {
      let data = this.state.kipiNonSeriousParams;
      data = { ...data, status: item?.value ?? "" };

      this.setState({ kipiNonSeriousParams: data }, () => {
        this.handlePageReset();
      });
    }
  }

  handlePageClick(event) {
    if (this.state.case_type == "ADROnline") {
      if (isNaN(event.selected)) {
        this.setState({ ADROnlinePage: 1 }, () => {
          this.refreshList("ADROnline");
        });
      } else {
        this.setState({ ADROnlinePage: event.selected + 1 }, () => {
          this.refreshList("ADROnline");
        });
      }
    } else if (this.state.case_type == "ProgressReport") {
      if (this.state.initPage !== undefined) {
        this.setState(
          { progressPage: this.state.initPage, initPage: undefined },
          () => {
            this.refreshList("ProgressReport");
          }
        );
      } else if (isNaN(event.selected)) {
        this.setState({ progressPage: 1 }, () => {
          this.refreshList("ProgressReport");
        });
      } else {
        this.setState({ progressPage: event.selected + 1 }, () => {
          this.refreshList("ProgressReport");
        });
      }
    } else if (this.state.case_type == "KIPIOverseas") {
      if (this.state.initPage !== undefined) {
        this.setState(
          { kipiOverseasPage: this.state.initPage, initPage: undefined },
          () => {
            this.refreshList("KIPIOverseas");
          }
        );
      } else if (isNaN(event.selected)) {
        this.setState({ kipiOverseasPage: 1 }, () => {
          this.refreshList("KIPIOverseas");
        });
      } else {
        this.setState({ kipiOverseasPage: event.selected + 1 }, () => {
          this.refreshList("KIPIOverseas");
        });
      }
    } else if (this.state.case_type == "KIPINonSerious") {
      this.setState({ kipiNonSeriousPage: event.selected + 1 }, () => {
        this.refreshList("KIPINonSerious");
      });
    }
  }

  handleTabClick(type) {
    this.setState({
      case_type: type,
    });
  }

  record(id) {
    this.props.history.push(`/report-submission/bpom/progress/detail/${id}`, {
      formType: "edit",
      page: this.state.progressPage,
    });
  }

  handleAddButton = () => {
    switch (this.state.case_type) {
      case "ADROnline":
        this.setState({ addADROnlineModal: true });
        break;
      case "ProgressReport":
        this.props.history.push(`/report-submission/bpom/progress/add-report`);
        break;
      case "KIPIOverseas":
        this.props.history.push(`/report-submission/bpom/kipi-overseas/add`);
        break;
      case "KIPINonSerious":
        this.props.history.push(`/report-submission/bpom/kipi-nonserious/add`);
        break;
    }
  };

  handleSpanClick = (id, event) => {
    if (this.state.elementSpan === id) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ elementSpan: "" });
    } else {
      //To show collapsiable
      this.setState({ elementSpan: id });
    }
  };

  render() {
    const { t } = this.props;

    let currentUser = authenticationService.currentUserValue;

    const {
      ADROnlineRecords,
      progressRecords,
      kipiOverseasRecords,
      kipiNonSeriousRecords,
    } = this.state;

    const drugsList = this.state.list?.drugsList;
    const vaccineList = this.state.list?.vaccineList;
    const statusOptions = [
      { value: "Draft", label: "Draft" },
      { value: "Not+Ok", label: "Not Ok" },
      { value: "Waiting Approval", label: "Waiting Approval"},
      { value: "Reviewed", label: "Reviewed" },
      { value: "Not+Approved", label: "Not Approved" },
      { value: "Approved", label: "Approved" },
      { value: "Closed", label: "Closed" },
    ];

    let checkButton = this.state.sendArray.indexOf("all") > -1 ? 1 : 0;

    return (
      <>
        <Snakbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id">{this.state.snackbarmsg}</span>}
          action={[
            <IconButton
              key="close"
              arial-label="Close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              {" "}
              X{" "}
            </IconButton>,
          ]}
        />

        {/* Modal List */}

        <AddADROnlineModal
          show={this.state.addADROnlineModal}
          onhide={() => {
            this.refreshList("ADROnline");
            this.setState({ addADROnlineModal: false });
          }}
        />

        <DetailADROnlineModal
          id={this.state.record_id}
          case_type={this.state.case_type}
          show={this.state.detailADROnlineModal}
          onhide={() => {
            this.refreshList("ADROnline");
            this.setState({ detailADROnlineModal: false });
          }}
        />

        <DeleteModal
          show={this.state.deleteModalShow}
          onHide={() => this.setState({ deleteModalShow: false })}
          confirmdelete={() => this.deleteRecord(this.state.record_id)}
          t={t}
        />

        {/* Content */}

        <ul className="nav nav-pills bg-nav-pills nav-tabs nav-bordered mb-3">
          <Tab
            number={this.state.filter == null ? "1" : "2"}
            showNumber={false}
            title="ADR Online"
            href="#tab-adronline"
            type={1}
            onClick={() => this.handleTabClick("ADROnline")}
            id="ADROnline"
          />
          <Tab
            number="3"
            showNumber={false}
            title={t("aefi_overseas")}
            href="#tab-kipi-overseas"
            type={1}
            onClick={() => this.handleTabClick("KIPIOverseas")}
            id="KIPIOverseas"
          />
          <Tab
            number={this.state.filter != null ? "1" : "2"}
            showNumber={false}
            title={t("progress_report")}
            href="#tab-report"
            type={1}
            onClick={() => this.handleTabClick("ProgressReport")}
            id="ProgressReport"
          />
          <Tab
            number="4"
            showNumber={false}
            title={t("aefi_non_serious")}
            href="#tab-kipi-nonserious"
            type={1}
            onClick={() => this.handleTabClick("KIPINonSerious")}
            id="KIPINonSerious"
          />
        </ul>
        <div className="tab-content">
          {this.state.filter != null &&
            <div className="col-xs-6 col-sm-6 col-md-12">
              <div className="aler alert-warning" style={{ width: "100%", marginBottom: 15, border: "0.5px solid", borderRadius: "6px", padding: "8px 15px" }}>
                Ini adalah hasil filter dari Dashboard
                <button
                  type="button"
                  className="close"
                  onClick={this.handleClearFilters}
                >
                &times;
                </button>
              </div>
            </div>
          }
          <div className="row" style={{ marginTop: 15 }}>
            {currentUser.role === Role.Staff &&
              this.state.case_type !== "ADROnline" && (
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div style={{ marginBottom: 15 }}>
                    <ButtonWithIcon
                      text={t("add_report")}
                      icon="fa fa-plus"
                      onClick={() => this.handleAddButton()}
                    />
                  </div>
                </div>
              )}
              {currentUser.role === Role.Staff &&
              this.state.case_type === "ADROnline" &&
              this.state.CheckMassResend === false && (
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div style={{ marginBottom: 15 }}>
                    <ButtonWithIcon
                      text={`Kirim Semua Data`}
                      icon="fa fa-paper-plane"
                      background="#FFFFFF 0% 0% no-repeat padding-box"
                      color="#495057"
                      border="1px solid #DFDFDF"
                      onClick={() => this.handleMassSend()}
                    />
                  </div>
                </div>
              )}
            {currentUser.role === Role.Staff &&
              this.state.case_type === "ADROnline" && (
                <div className="col-xs-6 col-sm-6 col-md-3">
                  <div style={{ marginBottom: 15 }}>
                    {this.state.sendArray?.length > checkButton && (
                      <ButtonWithIcon
                        text={`Kirim ${
                          this.state.sendArray.indexOf("all") > -1
                            ? this.state.sendArray?.length - 1
                            : this.state.sendArray?.length
                        } Data`}
                        icon="fa fa-check"
                        background="#FFFFFF 0% 0% no-repeat padding-box"
                        color="#495057"
                        border="1px solid #DFDFDF"
                        onClick={() => this.handleSendAll()}
                      />
                    )}
                  </div>
                </div>
              )}
            {currentUser.role !== Role.Staff &&
              this.state.case_type === "ADROnline" && (
                <div className="col-xs-6 col-sm-6 col-md-3"></div>
              )}            
            {this.state.filter == null &&
              <div className="col-xs-6 col-sm-6 col-md-2 ml-auto">
                <div style={{ width: "100%", marginBottom: 15, float: "right" }}>
                  <ButtonWithIcon
                    text="Filter"
                    icon="fas fa-bars"
                    onClick={() =>
                      this.setState({ filterShow: !this.state.filterShow })
                    }
                  />
                </div>
              </div>
            }
            {/*isi dengan ini adalah tampilan filter dari dashboard */}
          </div>
          {this.state.filterShow && (
            <div
              className="row filter-wrapper"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <div className="col-xs-12 col-sm-12 col-md-12">
                <form onSubmit={(event) => this.handleSearchSubmit(event)}>
                  <div className="row">
                    {this.state.case_type === "ADROnline" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label"> {t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.ADROnlineQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label"> {t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return { value: vaccine.id, label: vaccine.name };
                            })}
                            value={this.state.ADROnlineVaccine}
                            onChange={(item) => this.handleVaccineChange(item)}
                          ></Select>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label">{t("drug")}</label>
                          <Select
                            placeholder={t("drug")}
                            width="100%"
                            name="drugsFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={drugsList?.map((drugs) => {
                              return { value: drugs.id, label: drugs.name };
                            })}
                            value={this.state.ADROnlineDrugs}
                            onChange={(item) => this.handleDrugsChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}

                    {this.state.case_type === "ProgressReport" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label"> {t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.progressQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                                placeholder={t("keyword")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label"> {t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return { value: vaccine.id, label: vaccine.name };
                            })}
                            value={this.state.progressVaccine}
                            onChange={(item) => this.handleVaccineChange(item)}
                          ></Select>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">{t("drug")}</label>
                          <Select
                            placeholder={t("drug")}
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={drugsList?.map((drugs) => {
                              return { value: drugs.id, label: drugs.name };
                            })}
                            value={this.state.progressDrugs}
                            onChange={(item) => this.handleDrugsChange(item)}
                          ></Select>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.progressStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          ></Select>
                        </div>
                      </>
                    )}

                    {this.state.case_type === "KIPIOverseas" && (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="form-group">
                            <label className="form-label"> {t("search")}</label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder={t("keyword")}
                                value={this.state.kipiOverseasQuery}
                                onChange={(event) =>
                                  this.handleSearchChange(event)
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-outline-secondary"
                                  type="submit"
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <label className="form-label"> {t("vaccine")}</label>
                          <Select
                            placeholder={t("vaccine")}
                            width="100%"
                            name="vaccineFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={vaccineList?.map((vaccine) => {
                              return { value: vaccine.id, label: vaccine.name };
                            })}
                            value={this.state.kipiOverseasVaccine}
                            onChange={(item) => this.handleVaccineChange(item)}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.kipiOverseasStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          />
                        </div>
                      </>
                    )}

                    {this.state.case_type === "KIPINonSerious" && (
                      <>
                        <div
                          className="col-xs-12 col-sm-6 col-md-2"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="form-group">
                            <label className="form-label"> {t("year")}</label>
                            <Year
                              handlechange={(item) =>
                                this.handleYearChange(item)
                              }
                              year={2020}
                            />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-2">
                          <label className="form-label">Status</label>
                          <Select
                            placeholder="Status"
                            width="100%"
                            name="statusFilter"
                            isClearable="true"
                            classNamePrefix="select"
                            className="basic-single"
                            options={statusOptions}
                            value={this.state.kipiNonSeriousStatus}
                            onChange={(item) => this.handleStatusChange(item)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {/* ADR Online TAB */}
          <div className={`tab-pane ${this.state.filter === null ? 'show active' : ''}`} id="tab-adronline">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>
                      <div className="form-check form-check-inline">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name="checkListAll"
                          value="all"
                          checked={this.state.sendArray.indexOf("all") > -1}
                          onChange={(e) => this.handleSelect(e)}
                        />
                      </div>
                    </th>
                    <th>No</th>
                    <th> {t("case_number")}</th>
                    <th> {t("product_name")}</th>
                    <th> {t("adr_created_date")}</th>
                    <th>ADR Online</th>
                    <th>Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {ADROnlineRecords.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div className="form-check form-check-inline">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="checkListSend"
                              value={record?.id}
                              checked={
                                record.bpomSent
                                  ? true
                                  : this.state.sendArray.indexOf(record?.id) >
                                    -1
                              }
                              onChange={(e) => this.handleSelect(e)}
                              disabled={record.bpomSent}
                            />
                          </div>
                        </td>
                        <td>
                          {index +
                            1 +
                            (this.state.ADROnlinePage - 1) *
                              this.state.ADROnlineLimit}
                        </td>
                        <td>{record.caseNumber}</td>
                        {record.adrOnlineVaccine.length != 0 && (
                          <td>
                            {record?.adrOnlineVaccine
                              ?.map((item) => {
                                return item?.vaccineNavigation?.name;
                              })
                              .join(", ")}
                          </td>
                        )}
                        {record.adrOnlineDrugs.length != 0 && (
                          <td>
                            {record.adrOnlineDrugs
                              ?.map((item) => {
                                return item.drugsNavigation.name;
                              })
                              .join(", ")}
                          </td>
                        )}
                        {record.adrOnlineVaccine.length == 0 &&
                          record.adrOnlineDrugs.length == 0 && <td></td>}
                        <td>
                          {record.entryDate
                            ? moment(record.entryDate).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>{record.adronlineNumber}</td>
                        <td>
                          {
                            <TableStatus
                              status={record.bpomSent ? "Terkirim" : "Gagal"}
                            />
                          }
                        </td>
                        <td>
                          {!record.bpomSent ? (
                            <Button
                              type="button"
                              text={t("send")}
                              margin="0px"
                              padding="5px 0px"
                              onClick={() => this.handleSend(record.id)}
                            />
                          ) : (
                            <Button
                              color="#70707033"
                              background="#0000001A 0% 0% no-repeat padding-box"
                              border="1px solid #70707033"
                              type="button"
                              text={t("send")}
                              margin="0px"
                              padding="5px 0px"
                              isSubmitting={true}
                            />
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                  {ADROnlineRecords.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.ADROnlineMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                forceSelected={this.state.ADROnlinePage - 1}
                forcePage={this.state.ADROnlinePage - 1}
                initialPage={this.state.ADROnlinePage - 1}
              />
            </nav>
          </div>

          {/* Progress Report TAB */}
          <div className={`tab-pane ${this.state.filter !== null ? 'show active' : ''}`} id="tab-report">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10, minHeight: "200px"}}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th> {t("case_number")}</th>
                    <th> {t("created_date")}</th>
                    <th> {t("product_name")}</th>
                    <th> {t("letter_number")}</th>
                    <th> {t("send_date")}</th>
                    <th>Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {progressRecords.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div
                            className="expand-table"
                            onClick={(e) => this.handleSpanClick(record.id, e)}
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.elementSpan === record.id
                                  ? faChevronUp
                                  : faChevronDown
                              }
                            />
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.progressPage - 1) *
                                  this.state.progressLimit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {record.modifiedDate || record.createDate
                            ? moment(
                                record.modifiedDate ?? record.createDate
                              ).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        {record.bpomProgressVaccine.length != 0 && (
                          <td>
                            {record.bpomProgressVaccine
                              ?.map((item) => {
                                return item.vaccineNavigation.name;
                              })
                              .join(", ")}
                          </td>
                        )}
                        {record.bpomProgressDrugs.length != 0 && (
                          <td>
                            {record.bpomProgressDrugs
                              ?.map((item) => {
                                return item.drugsNavigation.name;
                              })
                              .join(", ")}
                          </td>
                        )}
                        {record.bpomProgressVaccine.length == 0 &&
                          record.bpomProgressDrugs.length == 0 && <td></td>}
                        <td>{record.letterNumber}</td>
                        <td>
                          {record.sendDate
                            ? moment(record.sendDate).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                // always running when mounted
                                onClick={() => this.record(record.id)}
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    ProgressReportService.Download(
                                      record.id,
                                      record.isOverseas,
                                      record.caseNumber
                                    )
                                  }
                                >
                                  Download PDF
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    ProgressReportService.DownloadDoc(
                                      record.id,
                                      record.caseNumber,
                                      record.isOverseas
                                    )
                                  }
                                >
                                  Download Doc
                                </a>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                      {this.state.elementSpan === record.id && (
                        <tr id={record.id} key={record.id}>
                          <td colSpan="6">
                            <Table className="pb-5" hover>
                              <thead className="span-table">
                                <tr>
                                  <th>Jenis Laporan</th>
                                  <th> {t("created_date")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {record.trailBpomProgress
                                  .sort((a, b) =>
                                    a.followUp > b.followUp ? 1 : -1
                                  )
                                  .map((trail, index) => (
                                    <tr>
                                      <td>
                                        {trail.followUp === 0
                                          ? "Inisial"
                                          : "Follow Up " + trail.followUp}
                                      </td>
                                      <td>
                                        {moment(
                                          trail.followUpDate ?? trail.createDate
                                        ).format("DD/MM/yyyy")}
                                      </td>
                                    </tr>
                                  ))}
                                <tr>
                                  <td>
                                    {record.followUp === 0
                                      ? "Inisial"
                                      : "Follow Up " + record.followUp}
                                  </td>
                                  <td>
                                    {moment(
                                      record.followUpDate ?? record.createDate
                                    ).format("DD/MM/yyyy")}
                                  </td>
                                </tr>
                                {currentUser.role === Role.Staff && (
                                  <tr>
                                    <td colSpan="4">
                                      <div style={{ float: "right" }}>
                                        <Button
                                          text="Tambah Follow Up"
                                          margin="0"
                                          onClick={() =>
                                            this.props.history.push(
                                              `/report-submission/bpom/progress/follow-up/` +
                                                record.id
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                  {progressRecords.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.progressMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.progressPage - 1}
                // forcePage={this.state.progressPage - 1}
                initialPage={this.state.initPage - 1}
              />
            </nav>
          </div>

          {/* KIPI Overseas TAB */}
          <div className="tab-pane" id="tab-kipi-overseas">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th> {t("case_number")}</th>
                    <th> {t("created_date")}</th>
                    <th> {t("product_name")}</th>
                    <th> {t("letter_number")}</th>
                    <th> {t("send_date")}</th>
                    <th width="15%">Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {kipiOverseasRecords.map((record, index) => (
                    <>
                      <tr key={record.id}>
                        <td>
                          <div className="expand-table">
                            <span className="expand-number">
                              {index +
                                1 +
                                (this.state.kipiOverseasPage - 1) *
                                  this.state.kipiOverseasLimit}
                            </span>
                          </div>
                        </td>
                        <td>{record.caseNumber}</td>
                        <td>
                          {moment(record.createDate).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          {record.aefiOverseasVaccine
                            ? record.aefiOverseasVaccine
                                ?.map((item) => {
                                  return item.vaccineNavigation?.name;
                                })
                                .join(", ")
                            : "-"}
                        </td>
                        <td>{record.letterNumber}</td>
                        <td>
                          {record.sendDate
                            ? moment(record.sendDate).format("DD/MM/yyyy")
                            : "-"}
                        </td>
                        <td>
                          <TableStatus status={record.status} />
                        </td>
                        <td>
                          <div className="btn-group">
                            <button
                              className="btn btn-sm dropdown-toggle"
                              type="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                className="fa fa-ellipsis-h"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div className="dropdown-menu">
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  this.props.history.push({
                                    pathname:
                                      `/report-submission/bpom/kipi-overseas/detail/` +
                                      record.id,
                                    state: {
                                      formType: "edit",
                                      page: this.state.kipiOverseasPage,
                                    },
                                  })
                                }
                              >
                                Detail
                              </a>
                              {currentUser.role === Role.Staff && (
                                <a
                                  className="dropdown-item"
                                  onClick={() => this.handleDelete(record.id)}
                                >
                                  Delete
                                </a>
                              )}
                              {(record.status === Status.Approved ||
                                record.status === Status.Closed) && (
                                <>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      AEFIOverseasService.Download(
                                        record.id,
                                        record.caseNumber
                                      )
                                    }
                                  >
                                    Download PDF
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>
                                      AEFIOverseasService.DownloadDoc(
                                        record.id,
                                        record.caseNumber
                                      )
                                    }
                                  >
                                    Download Doc
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
                  {kipiOverseasRecords.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.kipiOverseasMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.kipiOverseasPage - 1}
                // forcePage={this.state.kipiOverseasPage - 1}
                initialPage={this.state.initPage - 1}
              />
            </nav>
          </div>

          {/* KIPI Non Serious TAB */}
          <div className="tab-pane" id="tab-kipi-nonserious">
            <div style={{ width: "100%", overflow: "auto", marginBottom: 10 }}>
              <Table className="pb-5" hover>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Periode</th>
                    <th> {t("year")}</th>
                    <th> {t("letter_number")}</th>
                    <th> {t("send_date")}</th>
                    <th>Status</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  {kipiNonSeriousRecords.map((record, index) => {
                    let period =
                      (record.periodBegin
                        ? moment(record.periodBegin, "YYYY-MM-DD").format(
                            "MMMM"
                          )
                        : "") +
                      " - " +
                      (record.periodEnd
                        ? moment(record.periodEnd, "YYYY-MM-DD").format("MMMM")
                        : "");
                    let year = record.periodEnd
                      ? moment(record.periodEnd).format("yyyy")
                      : "-";
                    let joinPeriod = period + " " + year;
                    return (
                      <>
                        <tr key={record.id}>
                          <td>
                            <div className="expand-table">
                              <span className="expand-number">
                                {index +
                                  1 +
                                  (this.state.kipiNonSeriousPage - 1) *
                                    this.state.kipiNonSeriousLimit}
                              </span>
                            </div>
                          </td>
                          <td>{period}</td>
                          <td>{year}</td>
                          <td>
                            {record.letterNumber ? record.letterNumber : "-"}
                          </td>
                          <td>
                            {record.sendDate
                              ? moment(record.sendDate).format("DD/MM/yyyy")
                              : "-"}
                          </td>
                          <td>
                            <TableStatus status={record.status} />
                          </td>
                          <td>
                            <div className="btn-group">
                              <button
                                className="btn btn-sm dropdown-toggle"
                                type="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-h"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  // onClick={() =>
                                  //   this.props.history.push(
                                  //     `/report-submission/bpom/kipi-nonserious/detail/` +
                                  //       record.id,
                                  //     {
                                  //       formType: "edit",
                                  //       page: this.state.kipiNonSeriousPage,
                                  //     }
                                  //   )
                                  // }
                                >
                                  Detail
                                </a>
                                {currentUser.role === Role.Staff && (
                                  <a
                                    className="dropdown-item"
                                    onClick={() => this.handleDelete(record.id)}
                                  >
                                    Delete
                                  </a>
                                )}
                                {(record.status === Status.Approved ||
                                  record.status === Status.Closed) && (
                                  <>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        AEFINonSeriousService.Download(
                                          record.id,
                                          joinPeriod
                                        )
                                      }
                                    >
                                      Download PDF
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        AEFINonSeriousService.DownloadDoc(
                                          record.id,
                                          joinPeriod
                                        )
                                      }
                                    >
                                      Download Doc
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        AEFINonSeriousService.DownloadData(
                                          record.id,
                                          joinPeriod
                                        )
                                      }
                                    >
                                      Download Data
                                    </a>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {kipiNonSeriousRecords.length < 1 && (
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        {t("data_not_found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <nav>
              <ReactPaginate
                previousLabel={<i class="fas fa-arrow-left"></i>}
                previousLinkClassName={"page-link"}
                nextLabel={<i class="fas fa-arrow-right"></i>}
                nextLinkClassName={"page-link"}
                pageCount={this.state.kipiNonSeriousMaxPage}
                containerClassName={"pagination justify-content-end"}
                pageClassName={"page-item"}
                breakClassName={"page-item"}
                pageLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                onPageChange={(event) => this.handlePageClick(event)}
                // forceSelected={this.state.kipiNonSeriousPage - 1}
                // forcePage={this.state.kipiNonSeriousPage - 1}
                initialPage={this.state.initPage - 1}
              />
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(BPOM));

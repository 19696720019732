import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import { toastSuccess } from '../../../components/Utils/Toast/Toast';
import { history } from '../../../helpers/history';

import { NotificationService } from '../../../services/Notification/NotificationService';

export class Notification extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            page: 1,
            count: 0,
            limit: 0,
            query: ""
        }

        this.refreshList()
    }

    refreshList() {
        NotificationService.Read(this.state.page, this.state.limit, this.state.query, false ).then(result => {
            this.setState({
                records: result.data?.records,
                count: result.data?.count,
            });
        });
    }

    readNotification() {
        let allNotif = []

        this.state.records.map((item) => {
            if(item.isRead === false) {
                allNotif = [...allNotif, item.id]
            }
        })


        NotificationService.ReadNotification({"ids" : allNotif}).then(result => {
            this.setState({
                isEmpty: true,
            });
            this.refreshList()
        });
    }

    goToNotificationUrl(NotificationPurpose, caseNumber, purposeId) {        
        var purpose = NotificationPurpose.toLowerCase()
        let url = ''
        var type = this.checkCaseType(caseNumber)
        if (purpose.includes('caseentry')) {
            if (type == 'aefi') {
                url = `/case-entry/aefi/detail-aefi/` + purposeId;
            } else {
                url = `/case-entry/ae/detail-ae/` + purposeId;
            }
        } else if (purpose.includes('Analysis')) {
            if (type == 'aefi') {
                url = `/medical-review/analysis/aefi-complete/detail/` + purposeId;
            } else {
                url = `/medical-review/analysis/ae/detail/` + purposeId;
            }
        } else if (purpose.includes('resume')) {
            url = `/medical-review/resume/detail/` + purposeId;
        } else if (purpose.includes('summary')) {
            url = `/medical-review/summary/detail/` + purposeId;
        } else if (purpose.includes('aefioverseas')) {
            url = `/report-submission/bpom/kipi-overseas/detail/` + purposeId;
        } else if (purpose.includes('bpomprogress')) {
            url = `/report-submission/bpom/progress/detail/` + purposeId;
        } else if (purpose.includes('manufacture')) {
            url = `/report-submission/manufacture/initial/detail/` + purposeId;
        } else if (purpose.includes('reporter')) {
            url = `/report-submission/reporter/detail/` + purposeId;
        } else if (purpose.includes('partner')) {
            url = `/report-submission/partner/detail/` + purposeId;
        } else if (purpose.includes('distribution')){ 
            url = `/product-distribution`;
        } else {
            url = `/report-submission/bpom`;
        }

        if (url != '') {            
            history.push(url)
            location.reload()
            
        } else {
            toastSuccess('Tidak memiliki detail data.')
        }
    }

    checkCaseType(caseNumber) {
        var substring = caseNumber?.substring(0, 4).toLowerCase()
        if (substring == 'aefi') {
            return 'aefi'
        } else {
            return 'ae'
        }
    }

    handleIconColor(icon) {
        let iconColor = '#C3F6C4';
        switch (icon) {
            case "new":
                iconColor = '#C3F6C4';
                break;
            case "approved":
                iconColor = '#00ABBD33';
                break;
            case "rejected":
                iconColor = '#FF8080';
                break;
            case "reviewed":
                iconColor = '#FFE680';
                break;
            case "low":
                iconColor = '#FFE680';
                break;
            case "normal":
                iconColor = '#C3F6C4';
                break;
            case "critical":
                iconColor = '#FF8080';
                break;
        }

        return iconColor;
    }

    render() {
        return (
            <li className="nav-item dropdown">
                <a  className="nav-link dropdown-toggle pl-md-3 position-relative" 
                    id="bell" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    style={{marginTop: 10}}
                >
                    <span><i className="fas fa-bell" style={{color:"#00ABBD"}}></i></span>
                    <span className="badge notify-no rounded-circle" style={{background:"#FF0023", color:"white"}}>{this.state.count > 100 ? "99+" : this.state.count}</span>
                </a>
                <div className="dropdown-menu dropdown-menu-right mailbox animated bounceInDown" style={{right: 0, minWidth: 300}}>
                    <div style={{width:"100%", position:"relative"}}>
                        <div style={{display:"flex"}}>
                            <div style={{width:"30%", padding:"10px 15px"}}>
                                <p className="text-muted font-14">Notifikasi</p>
                            </div>
                            <div style={{width:"70%", padding:"10px 15px"}}>
                                <p className="font-14" style={{float:"right", color:"#00ABBD", cursor: "pointer"}} onClick={() => this.readNotification()}>Mark All as Read</p>
                            </div>
                        </div>
                    </div>
                    <div style={{maxHeight: 300, overflowY: "auto"}}>
                        <ul className="list-style-none">
                            <li>
                                <div className="message-center notifications position-relative">
                                    {
                                        this.state.records.map((item) =>
                                            <a 
                                                className="message-item d-flex align-items-center border-bottom px-3 py-2" key={item.id} onClick={() => this.goToNotificationUrl(item.purpose, item.caseNumber, item.purposeId)}
                                            >
                                                <div className="btn rounded-circle btn-circle" style={{marginRight: 10, background: this.handleIconColor(item.icon)}}>
                                                    <i className="fas fa-bell text-white"></i>
                                                </div>
                                                <div className="w-75 d-inline-block v-middle pl-2">
                                                    <span className="font-12 text-nowrap d-block text-muted">
                                                        Case Number {item.caseNumber ? item.caseNumber : "-" }
                                                    </span>
                                                    <h6 className="message-title mb-0 mt-1">{item.message ? item.message : "-" }</h6>
                                                </div>
                                            </a>
                                        )
                                    }
                                    {
                                        this.state.records.length === 0 && (
                                            <div style={{textAlign:"center"}}>
                                                <p
                                                    className="text-muted"
                                                >
                                                    Tidak ada notifikasi baru
                                                </p>
                                            </div>
                                        )
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                    <li>
                        <div className="nav-link pt-3 text-center text-dark font-14">
                            <Link to="/notification">
                                <strong>View All </strong>
                                <i className="fa fa-angle-right" style={{marginLeft: 10}}></i>
                            </Link>
                        </div>
                    </li>
                </div>
            </li>
        );
    }
}
export default withRouter(withTranslation()(Notification));
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Snakbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import moment from 'moment';
import localization from 'moment/locale/id';

import Modal from '../../../components/Utils/Modal/Modal';
import ModalHeader from '../../../components/Utils/Modal/ModalHeader';
import ModalContent from '../../../components/Utils/Modal/ModalBody';
import ModalFooter from '../../../components/Utils/Modal/ModalFooter';
import Button from '../../../components/Utils/Button/Button';
import { CaseCommentReportService } from '../../../services/CaseCommentReportService';
import { authenticationService } from '../../../services/AuthService';
import { RoleName } from '../../../helpers/Constants';

export class CommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resume: props.resume,
            snackbaropen: false,
            snackbarmsg: '',
            comments: [],
            commentToSend: ""
        };

        this.getComments()
    }

    getComments() {
        CaseCommentReportService.Read("reporter", this.props.id).then(
            (result) => {
                if (result.data) {
                    this.setState({ comments: result.data });
                }
            }
        );
    }
    snackbarClose = (event) => {
        this.setState({ snackbaropen: false });
    }

    handleCommentChange = (event) => {
        this.setState({ commentToSend: event.target.value });
    }

    sendComment = (event) => {
        const {t} = this.props;
        if (this.state.commentToSend == "") {
            alert(t ? t('empty_comment') : "Komentar tidak boleh kosong");
        } else {
            CaseCommentReportService.Write("reporter", { id: this.props.id, comment: this.state.commentToSend });
            this.addComment();
            this.setState({ commentToSend: "" });
        }
    }
    addComment() {
        let currentUser = authenticationService.currentUserValue;
        let comment = {
            comment: this.state.commentToSend,
            createdDate: new Date(),
            roleName: RoleName[currentUser.role],
            username: currentUser.username
        };
        this.setState(prevState => ({ comments: [...prevState.comments, comment] }));
    }
    render() {
        const {t} = this.props;
        let comments = this.state.comments;
        return (

            <div className="container">
                <Snakbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.snackbaropen}
                    autoHideDuration={3000}
                    onClose={this.snackbarClose}
                    message={<span id="message-id">{this.state.snackbarmsg}</span>}
                    action={[
                        <IconButton
                            key="close"
                            arial-label="Close"
                            color="inherit"
                            onClick={this.snackbarClose}
                        > X </IconButton>
                    ]}
                />
                <Modal {...this.props}>
                    <Formik
                        initialValues={{
                            message: ""
                        }}
                        validationSchema={
                            Yup.object().shape({
                                message: Yup.string().required("Required"),
                            })
                        }
                        onSubmit={(values, { setStatus, setSubmitting }) => {
                            setStatus();
                        }}
                    >
                        {props => (
                            <Form>
                                <ModalHeader>
                                    <div className="modal-title">Note</div>
                                    <hr></hr>
                                </ModalHeader>
                                <ModalContent>
                                    <div className="overflow-auto" style={{ height: "400px" }}>
                                        {comments.map((comment, idx) =>
                                            <>
                                                <div className="card" style={{ background: "#CCF4FF4D" }}>
                                                    <div className="card-body">
                                                        <p style={{ fontSize: 14, fontWeight: "bold", marginBottom: 0 }}>{comment.username}</p>
                                                        <p style={{ fontSize: 12, marginBottom: 15, color: "#AAAAAA" }}>{comment.roleName}</p>
                                                        <p style={{ marginBottom: "-2px", fontSize: 16, color: "#777777" }}>{comment.comment}</p>
                                                        <p style={{ fontSize: 12, color: "#AAAAAA" }}>{moment.utc(comment.createdDate).local().format('DD MMMM YYYY,  HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </ModalContent>
                                <ModalFooter>
                                    <div className="row justify-content-end">
                                        <textarea value={this.state.commentToSend} name="message" className="form-control col-12" rows="3" placeholder="Type your Message here..." onChange={this.handleCommentChange} />
                                        <div className="w-100"></div>
                                        <Button
                                            color="#495057"
                                            background="#FFFFFF 0% 0% no-repeat padding-box"
                                            border="1px solid #495057"
                                            text={t ? t('cancel') : "Batal"}
                                            width="100%"
                                            className="col-3"
                                            onClick={this.props.onHide} />

                                        <Button
                                            type="submit"
                                            text={t ? t('send') : "Kirim"}
                                            width="100%"
                                            className="col-3"
                                            onClick={this.sendComment}
                                            disabled={props.isSubmitting} />
                                        {
                                            props.status &&
                                            <div className={'alert alert-danger'}>{props.status}</div>
                                        }
                                    </div>
                                </ModalFooter>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </div>
        )
    }
}

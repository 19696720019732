import React, { Component } from 'react';
import moment from "moment";
import { Table } from 'react-bootstrap';

import Input from '../../../../components/Utils/Field/Input';
import Select2 from '../../../../components/Utils/Field/Select2';
import DatePicker from '../../../../components/Utils/DateRange/DateRangePicker';

import { Role, Status } from '../../../../helpers/Constants';

import { Loader } from '../../../../components/Utils/Loader/Loader';

import { PBRERService } from '../../../../services/PBRER/PBRERService';
import { DropdownService } from '../../../../services/Master/AllDropdownService';

import { withTranslation } from "react-i18next";

class ProductReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            productList: [],
            params: [],
            loading: false,
            timezone: moment(new Date()).format('Z'),
        };
    }

    componentDidMount() {

        DropdownService.MasterVaccineList("","").then(
            (result) => {
                this.setState({
                    productList: [
                        ...this.state.productList,
                        ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "vaccine" }; })
                        ]
                })
            })

        DropdownService.MasterDrugsList("","").then(
                (result) => {
                    this.setState({
                        productList: [
                            ...this.state.productList,
                            ...result.data.records.map(item => { return { value: item.id, label: item.name, type: "drugs" }; })
                            ]
                    })
                })
    }

    componentDidUpdate(prevProps){

        if(prevProps.formik.values?.product !== this.props.formik.values?.product || prevProps.formik.values?.startDate !== this.props.formik.values?.startDate || prevProps.formik.values?.endDate !== this.props.formik.values?.endDate){

            if(this.props.formik.values?.product && this.props.formik.values?.startDate && this.props.formik.values?.endDate && this.props.formType !== 'edit' && this.state.params){

                this.prepareAdd();
            }

        }
    }

    handleDateChange(start, end) {

        let startDate = start !== "" && start !== null  ? start.replaceAll("/", "-") +"Z"+ this.state.timezone : "";
        let endDate = end !== "" && end !== null  ? end.replaceAll("/", "-") +"Z"+ this.state.timezone : "";

        let data = this.state.params;
        data = { ...data, startDate, endDate };
        this.setState({ params: data }, () => { 
            this.props.formik.setFieldValue('startDate', start)
            this.props.formik.setFieldValue('endDate', end)
        });

        if(this.props.formType === 'edit'){
            if(this.props.formik.values?.product && this.props.formik.values?.startDate && this.props.formik.values?.endDate && this.state.params){

                this.prepareAdd();
            }
        }
        
    }

    handleLoading = (status) => {
        this.setState({ loading: status })
    }

    prepareAdd = () => {

        const {formik} = this.props;

        let data = [];

        if(this.props.formik?.values?.type){

            data.type = this.props.formik?.values?.type;

        }else{

            data = this.state.productList
            .filter(obj => obj.value === this.props.formik.values?.product)
            .map(item => { return { product: item.value, productName: item.label, type: item.type }; })[0];
        
            if (data) {
                formik.setFieldValue("productName", data.productName)
                formik.setFieldValue("type", data.type)
            }
        }

            this.handleLoading(true)

            PBRERService.PrepareAdd(this.props.formik.values?.product, data.type, this.state.params).then(
                (result) => {
                    // Tab 1
                    var pbrerCaseEntry = result.data.caseEntry?.map(item => {
                        return {
                            caseNumber: item.caseNumber,
                            country: item.country,
                            countryName: item.countryName,
                            datasource: item.datasource,
                            datasourceName: item.datasourceName,
                            ageOfYear: item.ageOfYear,
                            ageOfMonth: item.ageOfMonth,
                            gender: item.gender,
                            dose: item.dose,
                            route: item.route,
                            routeName: item.routeName,
                            immunizationDate: item.immunizationDate,
                            onsetDate: item.onsetDate,
                            treatmentDate: item.treatmentDate,
                            diagnosis: item.diagnosis,
                            preferedTerm: item.preferedTerm,
                        };
                    });
                    formik.setFieldValue("pbrerCaseEntry", pbrerCaseEntry)
                    formik.setFieldValue("total", result.data.caseEntry?.length)

                    // Tab 2
                    var pbrerMarketAuthorizationCountry = result.data.marketAuthorization?.marketAuthorizationCountry?.map(item => {
                        return {
                            country: item.country,
                            countryName: item.countryNavigation?.name,
                            actionDate: item.actionDate,
                            launchDate: item.launchDate,
                            tradeName: item.tradeName,
                        };
                    });
                    var pbrerMarketAuthorizationInstitution = result.data.marketAuthorization?.marketAuthorizationInstitution?.map(item => {
                        return {
                            institution: item.institution,
                            institutionName: item.institutionNavigation.name,
                            country: item.country,
                            countryName: item.countryNavigation?.name,
                            actionDate: item.actionDate,
                            launchDate: item.launchDate,
                            tradeName: item.tradeName,
                            comment: item.comment,
                        };
                    });
                    formik.setFieldValue("pbrerMarketAuthorizationCountry", pbrerMarketAuthorizationCountry ?? [])
                    formik.setFieldValue("pbrerMarketAuthorizationInstitution", pbrerMarketAuthorizationInstitution ?? [])

                    // Tab 3
                    var pbrerClinicalTrial = result.data.clinicalTrial?.clinicalTrialDetail?.map(item => {
                        return {
                            name: item.trialName,
                            ageRange: item.ageRange,
                            purpose: item.purpose,
                            purposeEn: item.purposeEn,
                            totalMale: item.totalMale,
                            totalFemale: item.totalFemale,
                            total: item.total,
                        };
                    });
                    formik.setFieldValue("pbrerClinicalTrial", pbrerClinicalTrial ?? [])

                    // Tab 4
                    var pbrerProductDistribution = result.data.productDistribution?.productDistributionRecords?.map(item => {
                        return {
                            year: item.year,
                            dose: item.dose,
                            domesticVial: item.domesticVial,
                            exportVial: item.exportVial,
                            totalVial: item.totalVial,
                        };
                    });
                    formik.setFieldValue("pbrerProductDistribution", pbrerProductDistribution ?? [])

                    // Tab 5
                    formik.setFieldValue("pbrerSummaryTabulationCt", result.data.summaryTabulationCt)
                    formik.setFieldValue("pbrerSummaryTabulationMarket", result.data.summaryTabulationMarket)
                    this.handleLoading(false)
                })
    }

    render() {

        const { formik, formType, t, ...otherProps } = this.props;

        return (
            <>
                {
                    this.state.loading && (
                        <Loader color="#00ABBD"/>
                    )
                }
                <div className="tab-pane show active tab-pane-fixed" id="product-report">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('product_name')}</label>
                                {
                                    formType === "edit" ? (
                                        <Input
                                            name="productName"
                                            type="text"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            {...formik.getFieldProps("productName")}
                                            disabled
                                        />
                                    ) : (
                                        <Select2
                                            name="product"
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            value={formik?.values?.product}
                                            onChange={formik?.setFieldValue}
                                            onBlur={formik?.setFieldTouched}
                                            options={this.state.productList}
                                        />
                                    )
                                }
                            </div>
                            <div className="form-group">
                                <label className="form-label">Periode</label>
                                <DatePicker
                                    name="period"
                                    type="period"
                                    handlechange={(startDate, endDate, type) => this.handleDateChange(startDate, endDate, type)}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    change={formik.setFieldValue}
                                    value={formik.values.period ?? ""}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="form-label">{t('case_total')}</label>
                                <Input
                                    name="total"
                                    type="text"
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    {...formik.getFieldProps("total")}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <hr/>
                            <div className="form-group">
                                <label className="form-label">CASE LISTING</label>
                                <div style={{width: "100%", overflowX:"auto"}}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>{t('case_number')}</th>
                                                <th>{t('country')}</th>
                                                <th>{t('data_source')}</th>
                                                <th>{t('age')}</th>
                                                <th>{t('gender')}</th>
                                                <th>{t('dose')}</th>
                                                <th>{t('rute')}</th>
                                                <th>{t('immunization_date')}</th>
                                                <th>{t('onset_date_aefi')}</th>
                                                <th>{t('treatment_date')}</th>
                                                <th>{t('diagnose')}</th>
                                                <th>Preferred Term</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values?.pbrerCaseEntry?.map((record, index) =>
                                                <tr>
                                                    <td>{index + 1}</td>                                                    
                                                    <td>
                                                        {record?.caseNumber}
                                                    </td>
                                                    <td>
                                                        {record?.countryName}
                                                    </td>
                                                    <td>
                                                        {record?.datasourceName}
                                                    </td>
                                                    <td>
                                                        {(record?.ageOfYear ? record?.ageOfYear + " y " : "") + (record?.ageOfMonth ? record?.ageOfMonth + " m " : "")}
                                                    </td>
                                                    <td>
                                                        {record?.gender}
                                                    </td>
                                                    <td>
                                                        {record?.dose}
                                                    </td>
                                                    <td>
                                                        {record?.routeName}
                                                    </td>
                                                    <td>
                                                        {record?.immunizationDate ? moment(record?.immunizationDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.onsetDate ? moment(record?.onsetDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.treatmentDate ? moment(record?.treatmentDate).format('DD/MM/yyyy') : '-'}
                                                    </td>
                                                    <td>
                                                        {record?.diagnosis}
                                                    </td>
                                                    <td>
                                                        {record?.preferedTerm}
                                                    </td>
                                                </tr>
                                            )}
                                            {
                                                (formik.values?.pbrerCaseEntry === undefined || formik.values?.pbrerCaseEntry === null || formik.values?.pbrerCaseEntry?.length === 0) && (
                                                    <tr>
                                                        <td colSpan="13" style={{textAlign:"center"}}>
                                                            {t('data_not_found')}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withTranslation()(ProductReport);